import { View, Text } from '@react-pdf/renderer';
import { paragraphstyles } from './Paragraph.style';

interface IProps {
  paragraph: string;
  width?: number | string;
}

const Paragraph = ({ paragraph, width }: IProps) => {
  return (
    <View
      style={{ ...paragraphstyles.container, width: width ? width : '100%' }}
    >
      <Text>{paragraph}</Text>
    </View>
  );
};

export default Paragraph;
