import { Fragment } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { AiOutlineSetting } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import SideMenuLink from '../../SideMenuDesktop/SideMenuLink/SideMenuLink';
import useLogout from '../../../../CustomHooks/useLogout';
import { frontendConstants } from '../../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllAuthValues } from '../../../../features/auth/authSlice';
import { MdLanguage } from 'react-icons/md';
import NavbarManuContainer from '../../NavbarMenuContainer/NavbarManuContainer';
import { AppDispatch } from '../../../../app/store';
import { setShowLanguageMenu } from '../../../../features/UI/uiSlice';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSelect: (e: React.MouseEvent) => void;
}

const UserBadgeMenu = ({ onSelect }: IProps) => {
  const { user, isAuthenticated } = useSelector(selectAllAuthValues);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [logout] = useLogout();
  const iconProps = { size: 23 };
  let userName = t('translations:Guest_Settings');
  let menuLinks: React.ReactNode | null = null;

  if (user && isAuthenticated && user.name && user.surname) {
    userName = `${user.name} ${user.surname}`;
  }

  const languageWindowHandler = () => {
    dispatch(setShowLanguageMenu());
  };

  if (user && isAuthenticated) {
    menuLinks = (
      <Fragment>
        <SideMenuLink
          Icon={<FaRegUserCircle />}
          id='usersettings'
          label={t('translations:User')}
          to={frontendConstants.pages.user}
          iconProps={iconProps}
          isExpanded={true}
          title={t('translations:User_Settings')}
        />
        <SideMenuLink
          Icon={<AiOutlineSetting />}
          id='settings'
          label={t('translations:Settings')}
          to={frontendConstants.pages.settings}
          iconProps={iconProps}
          isExpanded={true}
          title={t('translations:Settings')}
        />
        <SideMenuLink
          Icon={<MdLanguage />}
          label={t('translations:Language')}
          isExpanded={true}
          iconProps={iconProps}
          title={t('translations:Language')}
          id='language'
          onClick={languageWindowHandler}
        />

        <SideMenuLink
          Icon={<FiLogOut />}
          id='logout'
          label={t('translations:Logout')}
          iconProps={iconProps}
          isExpanded={true}
          onClick={logout}
          title={t('translations:Logout')}
        />
      </Fragment>
    );
  }

  return (
    <NavbarManuContainer heading={userName} onSelect={onSelect}>
      <Fragment>
        {/* <SelectLanguage /> */}
        {menuLinks}
      </Fragment>
    </NavbarManuContainer>
  );
};

export default UserBadgeMenu;
