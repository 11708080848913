/**
 * Divides an input array into smaller, manageable chunks based on specific size criteria.
 *
 * @param {Array} arr - The input array to be divided into chunks.
 * @returns {Array[]} An array of arrays, each representing a chunk of items from the input array.
 *
 * @example
 * const originalArray = [/* Your array of items here * /];
 * const chunkedArrays = divideArrayIntoChunks(originalArray);
 * console.log(chunkedArrays);
 */
const divideArrayIntoChunks = <T>(
  arr: T[],
  initChunkSize?: number,
  sizeOfRemainingChunks?: number
): T[][] => {
  const chunkedArrays: T[][] = [];
  let currentIndex = 0;

  const firstChunkSize = initChunkSize || 3;
  const nextChuckSize = sizeOfRemainingChunks || 5;

  // First, handle the chunk with at most 3 items
  const firstChunk = arr.slice(currentIndex, currentIndex + firstChunkSize);
  currentIndex += firstChunkSize;
  chunkedArrays.push(firstChunk);

  // Next, create chunks of 5 items each
  while (currentIndex < arr.length) {
    const chunk = arr.slice(currentIndex, currentIndex + nextChuckSize);
    currentIndex += nextChuckSize;
    chunkedArrays.push(chunk);
  }

  return chunkedArrays;
};

export default divideArrayIntoChunks;
