import { useSearchParams } from 'react-router-dom';
import { Fragment } from 'react';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import generateTextForNotification from '../../../../utils/NotificationDetails/generateTextForNotification';
import { notificationSubject } from '../../../../tsTypes/types';
import NotificationIncidentCard from '../NotificationDetails/NotificationIncidentCard/NotificationIncidentCard';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import { useSelector } from 'react-redux';
import { periopsisApi } from '../../../../features/api/apiSlice';
import classes from './NotificationDetailsMobile.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  notificationId: string;
  contractId: string;
}

const NotificationDetailsMobile = ({ notificationId, contractId }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedNotification = searchParams.get('notification');
  let content: React.ReactNode | null = null;
  const { t } = useTranslation();

  const notificationDetailsState =
    periopsisApi.endpoints.getNotificationDetails.select({
      contractId,
      notificationId: selectedNotification || '',
    });

  const {
    data: notificationDetails,
    isLoading,
    isError,
  } = useSelector(notificationDetailsState);

  const closeSelectedDetailsHandler = () => {
    setSearchParams({});
  };

  if (notificationId !== selectedNotification) {
    return null;
  }

  if (isLoading) {
    content = (
      <FetchingReports message={t('notifications:preparing_details')} />
    );
  }

  if (isError) {
    content = (
      <NoDataFound
        message={t('notifications:notifications_fetch_error_mobile')}
      />
    );
  }

  if (
    notificationDetails &&
    typeof notificationDetails === 'object' &&
    'id' in notificationDetails &&
    notificationDetails.id &&
    'contractId' in notificationDetails &&
    notificationDetails.contractId &&
    'notificationDetails' in notificationDetails &&
    notificationDetails.notificationDetails &&
    Array.isArray(notificationDetails.notificationDetails) &&
    !isLoading &&
    !isError
  ) {
    const message =
      notificationDetails.message && notificationDetails.message.length > 0
        ? notificationDetails.message
        : notificationDetails.notificationDetails.length > 0 &&
          notificationDetails.message.length === 0
        ? generateTextForNotification({
            subject: notificationDetails.subject as notificationSubject,
            notificationDate: notificationDetails.date,
            numberOfIncidents: notificationDetails.notificationDetails.length,
            detectionDate:
              notificationDetails.notificationDetails[0].gridDetails
                .detectionDate,
            incidentType:
              notificationDetails.notificationDetails[0].gridDetails.type,
          })
        : '';

    content = (
      <Fragment>
        <div className={classes.DetailsContent}>
          <h1>{notificationDetails.subject}</h1>
          <p>{message}</p>
          <div className={classes.IncidentCardsContainar}>
            {notificationDetails.notificationDetails.map((details) => (
              <NotificationIncidentCard
                type={details.gridDetails.type}
                detectionDate={details.gridDetails.detectionDate}
                gridId={details.incidentData.gridId}
                gridObjectId={details.incidentData.gridObjectId}
                contractId={notificationDetails.contractId}
                isCleaned={details.gridDetails.isCleaned}
                incidentActions={details.incidentData.incidentActions}
                key={details.incidentData.id}
              />
            ))}
          </div>

          <ActionContainer>
            <StandardButton
              type='button'
              btnType='orange'
              label={t('translations:Close')}
              onClick={closeSelectedDetailsHandler}
            />
          </ActionContainer>
        </div>
      </Fragment>
    );
  }

  return <div className={classes.NotificationDetailsMobile}>{content}</div>;
};

export default NotificationDetailsMobile;
