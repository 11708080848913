import { IContractUser } from '../../tsTypes/interfaces';

const getActionMessage = (data: {
  currentUserId: string;
  isOnlyAdminUser: boolean;
  isOnlyUser: boolean;
  isOnlyActionUser: boolean;
  user: IContractUser;
  isActionAuthProcess: boolean;
  isAdminAuthProcess: boolean;
}) => {
  const {
    currentUserId,
    isOnlyUser,
    isOnlyAdminUser,
    isOnlyActionUser,
    user,
    isActionAuthProcess,
    isAdminAuthProcess,
  } = data;

  if (isActionAuthProcess && currentUserId === user.userId && isOnlyUser) {
    return {
      message:
        'You are the sole user and, consequently, the only action user for the contract. Therefore, before proceeding with this action, you must assign another user as an action user for the contract. Currently, you are not permitted to remove actions authorizations from the contract without assigning a replacement action user.',
      isAllowed: false,
    };
  } else if (
    isActionAuthProcess &&
    currentUserId === user.userId &&
    isOnlyActionUser
  ) {
    return {
      message:
        'You are the sole action user for the contract. Therefore, before proceeding with this action, you must assign another user as an action user for the contract. Currently, you are not permitted to remove actions authorizations from the contract without assigning a replacement action user.',
      isAllowed: false,
    };
  } else if (
    isActionAuthProcess &&
    currentUserId !== user.userId &&
    isOnlyActionUser
  ) {
    return {
      message: `${user.name} ${user.surname} is the only action action user of the contract. Therefore, before proceeding with this action, you must assign another user as an action user for the contract. Currently, you are not premitted to remove actions authorization of ${user.name} ${user.surname} from the contract wşthout assigning a replacement action user.`,
      isAllowed: false,
    };
  } else if (
    isActionAuthProcess &&
    currentUserId === user.userId &&
    !isOnlyActionUser
  ) {
    return {
      message:
        'You are on the verge of revoking your actions authorization. Upon removing this authorization, you will lose the ability to perform any report-related actions, including reporting an incident, assigning an incident as visited, or assigning an incident as cleaned. Are you certain you want to proceed with this action?',
      isAllowed: true,
    };
  } else if (
    isActionAuthProcess &&
    currentUserId !== user.userId &&
    !isOnlyActionUser
  ) {
    return {
      message: `You are about to revoke the actions authorization of ${user.name} ${user.surname}. Once this authorization is removed, ${user.name} ${user.surname} will no longer be able to perform any report-related actions, such as reporting an incident, assigning an incident as visited, or assigning an incident as cleaned. Are you sure you want to proceed with this action?`,
      isAllowed: true,
    };
  } else if (
    isAdminAuthProcess &&
    currentUserId === user.userId &&
    isOnlyUser
  ) {
    return {
      message:
        'You are the sole user and, consequently, the only admin user for the contract. Therefore, before proceeding with this action, you must assign another user as an admin user for the contract. Currently, you are not permitted to remove admin authorizations from the contract without assigning a replacement admin user.',
      isAllowed: false,
    };
  } else if (
    isAdminAuthProcess &&
    currentUserId === user.userId &&
    isOnlyAdminUser
  ) {
    return {
      message:
        'You are the sole admin user of this contract, and therefore, it is not permitted to disable admin authorization. To proceed, you must first assign another user as an admin user.',
      isAllowed: false,
    };
  } else if (
    isAdminAuthProcess &&
    currentUserId !== user.userId &&
    !isOnlyAdminUser
  ) {
    return {
      message: `You are on the verge of removing the admin authorization of ${user.name} ${user.surname}. By doing so, ${user.name} ${user.surname} will no longer be able to perform any administrator processes for this contract. Are you sure you want to proceed with this action?`,
      isAllowed: true,
    };
  } else if (
    isAdminAuthProcess &&
    currentUserId === user.userId &&
    !isOnlyAdminUser
  ) {
    return {
      message:
        'You are about to revoke your admin authorization. Upon doing so, you will lose the authorization to access this page and review the process again. Are you sure you want to proceed with this action?',
      isAllowed: true,
    };
  } else {
    return {
      message: '',
      isAllowed: false,
    };
  }
};

export default getActionMessage;
