import { BiEditAlt, BiLock } from 'react-icons/bi';
import { AiFillSave } from 'react-icons/ai';
import EditableRowDataContainer from '../EditableRowDataContainer/EditableRowDataContainer';
import { FaUndo } from 'react-icons/fa';
import Input from '../FormElements/Input/Input';
import classes from './EditableRowData.module.scss';

interface IProps {
  rowIcon: React.ReactNode;
  label: string;
  rowData: string | number;
  isEditable?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (name: string | undefined) => void;
  edit?: boolean;
  name?: string;
  optionalText?: string;
  closeIcon?: React.ReactNode;
  onUndo?: () => void;
  type?: string;
  invalid?: boolean;
  invalidMessage?: string;
}

const EditableRowData = ({
  rowIcon,
  label,
  rowData,
  isEditable,
  onChange,
  onClick,
  edit,
  name,
  optionalText,
  closeIcon,
  onUndo,
  type,
  invalid,
  invalidMessage,
}: IProps) => {
  let hasUndoProcess = false;

  const editIcon = closeIcon ? (
    closeIcon
  ) : (
    <AiFillSave color='rgb(35, 104, 241)' style={{ cursor: 'pointer' }} />
  );

  if (onUndo && typeof onUndo === 'function') {
    hasUndoProcess = true;
  }

  if (isEditable && edit) {
    return (
      <div className={classes.InputContainer}>
        <div className={classes.InputBlock}>
          <Input
            type={type || 'text'}
            value={rowData.toString()}
            onChange={onChange}
            onWheel={(e) => e.currentTarget.blur()}
            name={name || label}
            label={label}
          />
          {hasUndoProcess ? (
            <FaUndo
              style={{ cursor: 'pointer' }}
              color='#d95c2d'
              onClick={onUndo}
              title='Undo'
              size={12}
            />
          ) : null}
          {editIcon}
        </div>
        {invalid ? <p>{invalidMessage}</p> : null}
      </div>
    );
  } else if (isEditable && !edit) {
    return (
      <EditableRowDataContainer
        rowIcon={rowIcon}
        label={label}
        optionalInfoText={optionalText}
        hasUndoProcess={hasUndoProcess && edit}
      >
        <p>{rowData}</p>
        <BiEditAlt
          onClick={() => (onClick ? onClick(name) : () => {})}
          style={{ cursor: 'pointer' }}
          color='#004c6c'
        />
      </EditableRowDataContainer>
    );
  } else {
    return (
      <EditableRowDataContainer
        rowIcon={rowIcon}
        label={label}
        optionalInfoText={optionalText}
        hasUndoProcess={hasUndoProcess && edit}
      >
        <p>{rowData}</p>
        <BiLock style={{ cursor: 'not-allowed' }} color='#d95c2d' />
      </EditableRowDataContainer>
    );
  }
};

export default EditableRowData;
