import { TiDelete } from 'react-icons/ti';
import classes from './Chips.module.scss';

interface IProps {
  text: string;
  onClick: () => void;
}

const Chips = ({ onClick, text }: IProps) => {
  return (
    <li className={classes.Chips}>
      {text}
      <TiDelete onClick={onClick} />
    </li>
  );
};

export default Chips;
