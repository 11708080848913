export const colorConstants = {
  periopsisMainColor: '#004c6c',
  lightGray: '#8aa8b2',
  periopsisOrange: '#d95c2d',
};

export const styleConstants = {
  sideHeadingTopMargin: '3.5mm',
  rightFullWidth: '138mm',
};
