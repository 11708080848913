import { ChangeEvent, Fragment, useState } from 'react';
import { useGetMonthlyIncidentsByTypeQuery } from '../../../../../features/statistics/statisticsSlice';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import TableContainer from '../../../../UI/Table/TableContainer/TableContainer';
import TableRow from '../../../../UI/Table/TableRow/TableRow';
import FetchingReports from '../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import TableItemContainer from '../../ActionStatistics/TableItemContainer/TableItemContainer';
import { IActionStatProps } from '../../Statistics';
import classes from './IncidentTableStats.module.scss';
import RadioButton from '../../../../UI/FormElements/RadioButton/RadioButton';
import ErrorControlComponent from '../../../../UI/ErrorControlComponent/ErrorControlComponent';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

const IncidentTableStats = ({
  isSelected,
  contractId,
  currentYear,
  isYearsReady,
}: IActionStatProps) => {
  const { isLoading, isError, data, error } = useGetMonthlyIncidentsByTypeQuery(
    {
      contractId,
      year: currentYear,
    },
    { skip: !isSelected || !contractId }
  );
  const [selectedTable, setSelectedTable] = useState(0);
  let content: React.ReactNode | null = null;
  const { t } = useTranslation();

  if (!isSelected || !isYearsReady) {
    return null;
  }

  if (isLoading) {
    content = (
      <FetchingReports message={t('statistics:fetching_monthly_statistics')} />
    );
  }

  if (isError) {
    content = (
      <ErrorControlComponent error={error}>
        <NoDataFound message={t('statistics:monthly_statistics_fetch_error')} />
      </ErrorControlComponent>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'data' in data &&
    data.data
  ) {
    const monthlyStatistics: {
      type: string;
      distribution: { month: string; total: number }[];
    }[] = data.data.monthlyStatistics;
    const tableHeadings = [
      t('statistics:month'),
      t('statistics:total'),
      t('statistics:total_incidents'),
      t('statistics:incident_type_percentage'),
    ];

    const tableSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
      const selected = e.target.value;

      setSelectedTable(Number(selected));
    };

    const monthlyIncidentTotals = data.data.monthlyIncidentTotals;

    content = (
      <div className={classes.TablesList}>
        <div className={classes.TableOptions}>
          {monthlyStatistics.map((stat, index) => (
            <RadioButton
              name='table'
              label={t(`dashboard:${getTranslationKey(stat.type)}`)}
              id={index.toString()}
              onChange={tableSelectHandler}
              value={index.toString()}
              checked={selectedTable === index}
              style={{ maxWidth: 'max-content' }}
              key={stat.type}
            />
          ))}
        </div>
        {monthlyStatistics.map((stat, index) => (
          <TableItemContainer
            heading={t(`dashboard:${getTranslationKey(stat.type)}`)}
            key={stat.type}
            isVisible={selectedTable === index}
          >
            <TableContainer headings={tableHeadings}>
              {stat.distribution.map((data, index) => (
                <TableRow
                  key={data.month}
                  rowData={[
                    t(`statistics:${getTranslationKey(data.month)}`),
                    data.total.toString(),
                    monthlyIncidentTotals[index],
                    monthlyIncidentTotals[index]
                      ? `${(
                          (data.total / monthlyIncidentTotals[index]) *
                          100
                        ).toFixed(2)}%`
                      : '0.00%',
                  ]}
                />
              ))}
            </TableContainer>
          </TableItemContainer>
        ))}
      </div>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default IncidentTableStats;
