import { ICheckoutProforma } from '../../../../../../../tsTypes/checkout.interfaces';
import PricingItem from './PricingItem/PricingItem';
import { paymentType } from '../../../../../../../tsTypes/types.components';
import classes from './CheckoutOrderDetails.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  proformaInvoice: ICheckoutProforma;
  paymentType: paymentType;
}

const CheckoutOrderDetails = ({ proformaInvoice, paymentType }: IProps) => {
  let subservicesTotal = 0;
  let polygonPrice = 0;
  let currency = '';
  let monthlyPrice = 0;
  let priceUnit = '';
  const { t } = useTranslation();

  if (!proformaInvoice) {
    return null;
  }

  if (
    proformaInvoice &&
    typeof proformaInvoice === 'object' &&
    'selectedPackage' in proformaInvoice
  ) {
    subservicesTotal = proformaInvoice.selectedPackage.subTotal;
    polygonPrice =
      paymentType === 'monthly-payment'
        ? proformaInvoice.totalAmount - subservicesTotal
        : (proformaInvoice.selectedPackage.duration / 30) *
          (proformaInvoice.totalAmount - subservicesTotal);
    currency = proformaInvoice.selectedPackage.currency;
    monthlyPrice = proformaInvoice.selectedPackage.monthlyPrice;
    priceUnit = proformaInvoice.selectedPackage.priceUnit;
  }

  const fullPaymentDiscountAmount =
    proformaInvoice.totalAmount *
    (proformaInvoice.selectedPackage.duration / 30) *
    (proformaInvoice.selectedPackage.discounts.discountAmountForFullPayment /
      100);

  const totalVatAmount =
    paymentType === 'monthly-payment'
      ? proformaInvoice.VATAmount
      : (proformaInvoice.totalAmount *
          (proformaInvoice.selectedPackage.duration / 30) -
          fullPaymentDiscountAmount) *
        (proformaInvoice.VATRate / 100);

  const overallTotal =
    paymentType === 'monthly-payment'
      ? proformaInvoice.overallTotal
      : proformaInvoice.totalAmount *
          (proformaInvoice.selectedPackage.duration / 30) -
        fullPaymentDiscountAmount +
        totalVatAmount;

  const subserviceQuantity =
    paymentType === 'monthly-payment'
      ? 1
      : proformaInvoice.selectedPackage.duration / 30;

  const totalAmount =
    paymentType === 'monthly-payment'
      ? proformaInvoice.totalAmount
      : (proformaInvoice.selectedPackage.duration / 30) *
        proformaInvoice.totalAmount;

  const discountAmount =
    paymentType === 'monthly-payment'
      ? proformaInvoice.discountAmount
      : (proformaInvoice.selectedPackage.duration / 30) *
        proformaInvoice.discountAmount;

  return (
    <div className={classes.CheckoutOrderDetailsContainer}>
      <div className={classes.ServiceHeadingContainer}>
        <div className={classes.SelectedPackage}>
          <h3>{proformaInvoice.selectedPackage.packageName} Package</h3>
          <span>
            ({proformaInvoice.selectedPackage.duration}{' '}
            {proformaInvoice.selectedPackage.durationUnit})
          </span>
        </div>
        <span>{proformaInvoice.serviceType}</span>
        <span>
          {paymentType === 'monthly-payment'
            ? t('checkout:monthly_payment')
            : t('checkout:full_payment')}
        </span>
      </div>
      <div className={classes.PricingPerServiceContent}>
        <PricingItem
          label={t('checkout:polygon_price')}
          itemQuantity={proformaInvoice.totalArea}
          quantityUnit={proformaInvoice.areaUnit}
          currency={currency}
          unitPrice={monthlyPrice}
          priceUnit={priceUnit}
          totalMonthlyPrice={polygonPrice}
          removePerMonth={paymentType !== 'monthly-payment'}
        />
        {proformaInvoice.selectedPackage.subServices.map((subservice) => (
          <PricingItem
            key={subservice._id}
            label={subservice.subServiceName}
            priceUnit=''
            totalMonthlyPrice={
              subservice.quantity * subserviceQuantity * subservice.unitPrice
            }
            itemQuantity={subservice.quantity * subserviceQuantity}
            currency={subservice.currency}
            unitPrice={subservice.unitPrice}
            removePerMonth={paymentType !== 'monthly-payment'}
          />
        ))}
      </div>
      <div
        className={`${classes.PricingPerServiceContent} ${classes.removeBorder}`}
      >
        <PricingItem
          label={t('checkout:total_price')}
          totalMonthlyPrice={totalAmount}
          currency={currency}
          removeCalculationPresentation
          removePerMonth={paymentType !== 'monthly-payment'}
        />
        <PricingItem
          label={t('checkout:discount')}
          totalMonthlyPrice={discountAmount}
          currency={currency}
          removeCalculationPresentation
          removePerMonth={paymentType !== 'monthly-payment'}
        />
        {paymentType !== 'monthly-payment' ? (
          <PricingItem
            label={t('checkout:full_payment_discount')}
            currency={currency}
            removeCalculationPresentation
            totalMonthlyPrice={fullPaymentDiscountAmount}
            removePerMonth={paymentType !== ('monthly-payment' as paymentType)}
          />
        ) : null}
        <PricingItem
          label={t('checkout:vat')}
          totalMonthlyPrice={totalVatAmount}
          currency={currency}
          removeCalculationPresentation
          removePerMonth={paymentType !== 'monthly-payment'}
        />
        <PricingItem
          label={t('checkout:overall_total')}
          totalMonthlyPrice={overallTotal}
          currency={currency}
          removeCalculationPresentation
          removePerMonth={paymentType !== 'monthly-payment'}
        />
      </div>
    </div>
  );
};

export default CheckoutOrderDetails;
