import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import classes from './BorderedSectionContainer.module.scss';

interface IProps {
  children?: React.ReactNode;
  heading?: string;
  information?: string;
  onAction?: () => void;
  isActionStart?: boolean;
  actionStartLabel?: string;
  hasActionAuth?: boolean;
  isLoading?: boolean;
}

const BorderedSectionContainer = ({
  children,
  heading,
  information,
  onAction,
  isActionStart,
  actionStartLabel,
  hasActionAuth,
  isLoading,
}: IProps) => {
  return (
    <div className={classes.BorderedSectionContainer}>
      {heading ? (
        <div
          className={`${classes.Heading} ${
            onAction ? classes.CloseButton : ''
          }`}
        >
          <h3>
            {heading}
            {onAction && actionStartLabel && hasActionAuth ? (
              <StandardButton
                label={isActionStart ? 'Close' : actionStartLabel}
                onClick={onAction}
                btnType={isActionStart ? 'orange' : 'primary'}
                type='button'
                fakeButton={isLoading}
              />
            ) : null}
          </h3>
        </div>
      ) : null}
      {information ? (
        <div className={classes.InformationSection}>
          <p>{information}</p>
        </div>
      ) : null}
      <div className={classes.SectionContent}>{children}</div>
    </div>
  );
};

export default BorderedSectionContainer;
