import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../components/UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { getHeaderByCode } from './componentUtilFns';

const queryErrorCatch = (error: unknown) => {
  if (
    error &&
    typeof error === 'object' &&
    'data' in error &&
    'status' in error &&
    error.status &&
    typeof error.status === 'number' &&
    error.data &&
    typeof error.data === 'object' &&
    'message' in error.data &&
    typeof error.data.message === 'string'
  ) {
    const heading = getHeaderByCode(error.status);

    return toast.error(
      <ToastifyStyledMessage
        singleMessage={error.data.message}
        heading={heading}
      />
    );
  }

  return toast.error(
    <ToastifyStyledMessage
      singleMessage="It seems like there's an unexpected server error. No need to worry; please give it another shot later. If the issue persists, feel free to reach out for assistance."
      heading='Server Error'
    />
  );
};

export default queryErrorCatch;
