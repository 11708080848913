import classes from './SuccessSign.module.scss';

interface IProps {
  size?: number;
}

const SuccessSign = ({ size }: IProps) => {
  /**
   * @TODO : add size prop to the div style
   */

  return (
    <div>
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 130.2 130.2'
        className={classes.SvgContainer}
      >
        <circle
          className={`${classes.Path} ${classes.Circle}`}
          cx='65.1'
          cy='65.1'
          r='62.1'
        />
        <polyline
          className={`${classes.Path} ${classes.Check}`}
          strokeLinecap='round'
          points='100.2,40.2 51.5,88.8 29.8,67.5 '
        />
      </svg>
    </div>
  );
};

export default SuccessSign;
