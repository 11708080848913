import { FaFileContract } from 'react-icons/fa6';
import { IUserAdminContract } from '../../../../../../tsTypes/interfaces';
import { useSearchParams } from 'react-router-dom';
import StandardButton from '../../../../../UI/FormElements/StandardButton/StandardButton';
import classes from './ContractListItem.module.scss';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

interface IProps {
  contract: IUserAdminContract;
}

const ContractListItem = ({ contract }: IProps) => {
  const [pageQuery, setPageQuery] = useSearchParams();
  const isSelected = contract.contractId === pageQuery.get('contract');
  const page = pageQuery.get('page');
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const selectContractHandler = () => {
    if (!isSelected && contract.contractId && page) {
      setPageQuery({
        page,
        contract: contract.contractId,
      });
    }
  };

  return (
    <div
      className={`${classes.ContractListItemContainer} ${
        isSelected ? classes.Selected : ''
      }`}
    >
      <div className={classes.HeadingSection}>
        <div className={classes.LeftSection}>
          <FaFileContract />
          <div>
            <h2>
              {t(`dashboard:${getTranslationKey(contract.serviceTypeName)}`)}{' '}
              {t('admin:contract')}
            </h2>
            <p>
              {new Date(contract.createdAt).toLocaleDateString(language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            </p>
          </div>
        </div>

        <StandardButton
          label={t('admin:select')}
          type='button'
          btnType={isSelected ? 'primary-light' : 'orange'}
          onClick={selectContractHandler}
        />
      </div>

      <div className={classes.Status}>
        <div className={classes.StatusItem}>
          <h5>{t('admin:status')} </h5>
          <p>{t(`admin:${getTranslationKey(contract.status)}`)}</p>
        </div>
        <div className={classes.StatusItem}>
          <h5>{t('admin:expiry_date')}</h5>
          <p>
            {new Date(contract.endDate).toLocaleDateString(language, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContractListItem;
