import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../style.constants';

export const paragraphstyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2mm 0',
    fontSize: '3.5mm',
    color: colorConstants.periopsisMainColor,
    lineHeight: '1.3pt',
    textAlign: 'justify',
  },
});
