import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../../../../../../pdfComponents/UI/style.constants';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '172mm',
    padding: '1mm 0',
  },
  text: {
    fontSize: '9pt',
    color: colorConstants.periopsisMainColor,
    width: '39mm',
    paddingRight: '0.5mm',
  },
  longText: {
    fontSize: '9pt',
    color: colorConstants.periopsisMainColor,
    width: '43mm',
    paddingRight: '0.5mm',
  },
  listNumber: {
    fontSize: '10pt',
    color: colorConstants.periopsisMainColor,
    width: '5mm',
  },
});
