import { useEffect, useState } from 'react';
import NoImage from '../../../assets/no_image.png';
import blurImage from '../../../assets/blur_image.png';
import SkeletonLoading from '../SkeletonLoading/SkeletonLoading';
import { useLazyGetIncidentImageQuery } from '../../../features/incidents/incidentsSlice';
import classes from './Image.module.scss';

interface IProps {
  imagePath: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  loaderClass?: string;
  title?: string;
  onClick?: () => void;
  loading?: 'lazy' | 'eager';
}

const Image = ({
  imagePath,
  alt,
  width,
  height,
  className,
  loaderClass,
  title,
  onClick,
  loading,
}: IProps) => {
  const [image, setImage] = useState(blurImage);

  const [getIncidentImage, { isLoading, isSuccess }] =
    useLazyGetIncidentImageQuery();

  useEffect(() => {
    const getImage = async (imagePath: string) => {
      try {
        const result = await getIncidentImage(imagePath);

        if (result && result.isSuccess) {
          const targetImage = result.data.data.image.url;
          setImage(targetImage);
        }
      } catch (error) {
        setImage(NoImage);
      }
    };

    if (imagePath && alt) {
      getImage(imagePath);
    }
    // eslint-disable-next-line
  }, [imagePath, alt]);

  if (isLoading && image !== blurImage) {
    return (
      <div
        className={`${classes.LoaderContainer} ${
          loaderClass ? loaderClass : ''
        }`}
      >
        <SkeletonLoading width={width || 394} height={height || 470} />
      </div>
    );
  }

  return (
    <img
      src={image}
      alt={alt}
      onError={() => {
        if (!isSuccess) {
          setImage(NoImage);
        }
      }}
      loading={loading}
      className={className ? className : ''}
      title={title}
      onClick={onClick}
      width={394}
      height={470}
    />
  );
};

export default Image;
