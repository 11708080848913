import { StyleSheet } from '@react-pdf/renderer';

export const itemStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2mm 3mm',
  },
  heading: {
    fontSize: '4mm',
    fontWeight: 'extrabold',
  },
});
