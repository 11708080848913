import SimpleLoading from '../../../../../UI/SimpleLoading/SimpleLoading';
import classes from './FetchingReport.module.scss';

interface IProps {
  message?: string;
}

const FetchingReports = ({ message }: IProps) => {
  return (
    <div className={classes.FetchingEmailReports}>
      {message ? <p>{message}</p> : null}
      <SimpleLoading />
    </div>
  );
};

export default FetchingReports;
