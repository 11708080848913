import React, { Fragment } from 'react';
import CenterDiv from '../CenterDiv/CenterDiv';
import SimpleLoading from '../SimpleLoading/SimpleLoading';
import classes from './PresentationWrapper.module.scss';

interface IProps {
  isLoading: boolean;
  isError: boolean;
  error: { status: number; message: string };
  children: React.ReactNode | JSX.Element[] | JSX.Element;
  message: string;
  loadingSize?: number;
}

const PresentationWrapper = ({
  isLoading,
  isError,
  error,
  children,
  message,
  loadingSize,
}: IProps) => {
  if (isLoading) {
    return (
      <CenterDiv>
        <SimpleLoading size={loadingSize} />
      </CenterDiv>
    );
  }

  if (isError) {
    return (
      <CenterDiv>
        <p className={classes.ErrorMessage}>{error.message || message}</p>
      </CenterDiv>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default PresentationWrapper;
