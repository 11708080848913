import classes from './ActionRowItem.module.scss';

interface IProps {
  label: string;
  color: string;
  count: number;
  onClick?: () => void;
  title?: string;
}

const ActionRowItem = ({ label, color, count, onClick, title }: IProps) => {
  const hasBorder = color === '#fff';

  return (
    <div
      className={`${classes.ActionRowItem} ${
        hasBorder ? classes.ColorBorder : ''
      }`}
      onClick={onClick}
      title={title}
    >
      <span
        style={{
          backgroundColor: color,
        }}
      ></span>
      <p>{label}</p>
      <data>{count}</data>
    </div>
  );
};

export default ActionRowItem;
