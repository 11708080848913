import { frontendConstants } from '../../constants/constants';
import { AddressType } from '../../tsTypes/types.components';
import { IAddress, IAddressStructure } from '../../tsTypes/types.model';

export class AddressValidation {
  private readonly addressStructure: IAddressStructure | undefined;
  private readonly userAddress: IAddress;

  constructor(
    addressStructure: IAddressStructure | undefined,
    userAddress: IAddress
  ) {
    this.addressStructure = addressStructure;
    this.userAddress = userAddress;
  }

  getAddressStructure() {
    return this.addressStructure;
  }

  get postalCode(): boolean {
    if (!this.addressStructure) {
      return true;
    }

    if (
      this.addressStructure &&
      this.addressStructure.hasPostalCode &&
      this.userAddress.postalCode &&
      this.userAddress.postalCode.name.trim().length > 0 &&
      this.userAddress.postalCode.name.trim().length <=
        this.addressStructure.postalCodeMaxLength
    ) {
      return true;
    }

    if (this.addressStructure && !this.addressStructure.hasPostalCode) {
      return true;
    }

    return false;
  }

  get division(): boolean {
    if (
      this.addressStructure &&
      this.addressStructure.hasDivisions &&
      this.userAddress.division &&
      this.userAddress.division.name.trim().length > 0
    ) {
      return true;
    }

    if (
      this.addressStructure &&
      !this.addressStructure.hasDivisions &&
      this.userAddress.division &&
      this.userAddress.division.name.trim().length === 0
    ) {
      return true;
    }

    return false;
  }

  get subDivision(): boolean {
    if (
      this.addressStructure &&
      this.addressStructure.hasSubDivision &&
      this.userAddress.subDivision &&
      this.userAddress.subDivision.name.trim().length > 0
    ) {
      return true;
    }

    if (
      this.addressStructure &&
      !this.addressStructure.hasSubDivision &&
      this.userAddress.subDivision &&
      this.userAddress.subDivision.name.trim().length === 0
    ) {
      return true;
    }

    return false;
  }

  get settlement(): boolean {
    if (
      this.addressStructure &&
      this.addressStructure.hasCityField &&
      this.userAddress.settlement &&
      this.userAddress.settlement.name.trim().length > 0
    ) {
      return true;
    }

    if (
      this.addressStructure &&
      !this.addressStructure.hasCityField &&
      this.userAddress.settlement &&
      this.userAddress.settlement.name.trim().length === 0
    ) {
      return true;
    }

    return false;
  }

  get streetAddress(): boolean {
    if (
      this.userAddress &&
      this.userAddress.streetAddress &&
      this.userAddress.streetAddress.name &&
      this.userAddress.streetAddress.name.trim().length > 0
    ) {
      return true;
    }

    return false;
  }

  get addressType(): boolean {
    let selectedAddressType: AddressType = { id: '', name: '' };
    if (this.userAddress && this.userAddress.addressType) {
      selectedAddressType = this.userAddress.addressType;
    }

    const validAddressTypes = frontendConstants.addressTypeOptionList.map(
      (addressType) => addressType.id
    );

    return validAddressTypes.includes(selectedAddressType.id);
  }
}
