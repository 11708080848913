import { paymentMethod } from '../../../../../../../tsTypes/checkout.interfaces';
import RadioButtonDot from '../../../../../../UI/RadioButtonDot/RadioButtonDot';
import classes from './PaymentOption.module.scss';

interface IProps {
  onSelect: (optionId: paymentMethod) => void;
  children: React.ReactNode | JSX.Element[] | JSX.Element;
  optionId: paymentMethod;
  selected: paymentMethod | '';
  label: string | React.ReactNode | JSX.Element | JSX.Element[];
}

const PaymentOption = ({
  onSelect,
  children,
  optionId,
  selected,
  label,
}: IProps) => {
  const selectPaymentMethodHandler = () => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(optionId);
    }
  };

  const isSelected = selected === optionId;

  let labelContent:
    | string
    | React.ReactNode
    | JSX.Element
    | JSX.Element[]
    | null = null;

  if (typeof label === 'string') {
    labelContent = <p>{label}</p>;
  } else {
    labelContent = label;
  }

  return (
    <div
      className={classes.OptionContainer}
      onClick={selectPaymentMethodHandler}
    >
      <div className={classes.LabelContentSection}>
        <RadioButtonDot isSelected={isSelected} />
        {labelContent}
      </div>
      {isSelected ? <div className={classes.Content}>{children}</div> : null}
    </div>
  );
};

export default PaymentOption;
