import { useTranslation } from 'react-i18next';
import { receiptInvoiceOption } from '../../../../../../../tsTypes/checkout.interfaces';
import PillOptionButton from '../../../../../../UI/PillOptionButton/PillOptionButton';
import classes from './ReceiptInvoiceOptionSection.module.scss';

interface IProps {
  onSelect: (optionId: receiptInvoiceOption) => void;
  selected: receiptInvoiceOption;
}

const ReceiptInvoiceOptionSection = ({ onSelect, selected }: IProps) => {
  const { t } = useTranslation();

  const selectOptionHandler = (optionId: string) => {
    if (onSelect && typeof onSelect === 'function') {
      const option = optionId as receiptInvoiceOption;

      onSelect(option);
    }
  };

  return (
    <div className={classes.ReceiptInvoiceOptionContainer}>
      <p>{t('checkout:invoice_receipt_select_desc')}</p>
      <PillOptionButton
        options={{
          leftOption: t('checkout:only_receipt'),
          rightOption: t('checkout:invoice_receipt'),
        }}
        optionIds={{
          leftOptionId: 'only-receipt' as receiptInvoiceOption,
          rightOptionId: 'invoice-receipt' as receiptInvoiceOption,
        }}
        selected={selected}
        onSelect={selectOptionHandler}
      />
    </div>
  );
};

export default ReceiptInvoiceOptionSection;
