import { useRef, useEffect } from 'react';
import { ITimelineItem } from '../../../../../tsTypes/interfaces';
import TimelineItem from '../TimelineItem/TimelineItem';
import classes from './TimelineList.module.scss';
import FullScreenImageView from './FullScreenImageView/FullScreenImageView';
import NavigationButtons from './NavigationButtons/NavigationButtons';

interface IProps {
  timelineList: ITimelineItem[];
  currentIncident: string;
  onIncidentSelect: (id: string) => void;
  onFullScreenClose: () => void;
  showFullScreen: boolean;
  incidentType?: string;
  address?: string;
}

const TimelineList = ({
  timelineList,
  currentIncident,
  onIncidentSelect,
  onFullScreenClose,
  showFullScreen,
  incidentType,
  address,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const imageWidth = 15 * 16;

  useEffect(() => {
    // Find the index of the current incident image in the images array
    const currentIndex = timelineList.findIndex(
      (image) =>
        image.isDetectionDateImage && image.gridObjectId === currentIncident
    );

    if (containerRef.current && currentIndex !== -1) {
      // Calculate the scroll position to center the current incident image
      const centerScrollPosition =
        currentIndex * imageWidth -
        containerRef.current.clientWidth / 2 +
        imageWidth / 2;

      // Scroll to the calculated position
      containerRef.current.scrollTo({
        left: centerScrollPosition,
        behavior: 'smooth',
      });
    }
  }, [currentIncident, timelineList, imageWidth]);

  const scrollTimeline = (direction: 'left' | 'right') => {
    const container = containerRef.current;

    if (container) {
      const scrollAmount = direction === 'left' ? -imageWidth : imageWidth;
      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  if (
    !timelineList ||
    !Array.isArray(timelineList) ||
    timelineList.length === 0
  ) {
    return null;
  }

  const isScrollable = timelineList.length >= 4;

  return (
    <div className={classes.TimelineListWrapper}>
      <div
        className={`${classes.TimelineListContainer} ${
          !isScrollable ? classes.RemoveScroll : ''
        }`}
        ref={containerRef}
      >
        {isScrollable ? <NavigationButtons onScroll={scrollTimeline} /> : null}
        {timelineList.map((item, index) => (
          <TimelineItem
            key={index}
            timelineItem={item}
            onClick={onIncidentSelect}
            isCurrentIncident={item.gridObjectId === currentIncident}
          />
        ))}
      </div>
      <FullScreenImageView
        timelineList={timelineList}
        currentIncident={currentIncident}
        onClose={onFullScreenClose}
        show={showFullScreen}
        incidentType={incidentType}
        address={address}
      />
    </div>
  );
};

export default TimelineList;
