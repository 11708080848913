import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../app/store';
import { successRegistrationState } from '../../../../../features/registration/registrationSlice';
import { useParams } from 'react-router-dom';
import Button from '../../../../UI/FormElements/Button/Button';
import ErrorPage from '../../../Error/ErrorPage';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import useQuery from '../../../../../CustomHooks/useQuery';
import SiteEntryContainer from '../../../../UI/EnterSite/SiteEntryContainer';
import useFlowData from '../../../../../CustomHooks/useFlowData';
import FlowBar from '../../../../UI/FlowBar/FlowBar';
import RegistrationSuccessSection from './RegistrationSuccessSection/RegistrationSuccessSection';
import {
  ICheckoutContract,
  ICheckoutPageSettings,
  ICheckoutProforma,
  ICheckoutSectionSettings,
  ICheckoutUserData,
  IProformaSettings,
  IRegistrationSuccessSettings,
} from '../../../../../tsTypes/checkout.interfaces';
import OrderDetailsSection from './OrderDetailsSection/OrderDetailsSection';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import CheckoutSection from './CheckoutSection/CheckoutSection';
import classes from './RegistrationCheckout.module.scss';
import {
  useCompleteOrderMutation,
  useCompletePaymentMutation,
  useGetCheckoutDataQuery,
  useGetCheckoutSettingsQuery,
} from '../../../../../features/checkout/checkoutSlice';
import { selectAllUIValues } from '../../../../../features/UI/uiSlice';
import { useTranslation } from 'react-i18next';

const RegistrationCheckout = () => {
  const { checkoutToken, contractId, registrationCode } = useParams();
  const [showNavButtons, setShowNavButtons] = useState(false);
  const query = useQuery();
  const proformaInvoice = query.get('proformaInvoice');
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const { isOrderCompleteSuccess } = useSelector(selectAllUIValues);
  const flowData = useFlowData();
  let currentFlowPage = 1;
  let checkOutSettings: ICheckoutPageSettings | null = null;
  let registrationSuccessSettings: IRegistrationSuccessSettings | null = null;
  let proformaSettings: IProformaSettings | null = null;
  let checkoutSectionSettings: ICheckoutSectionSettings | null = null;
  const { t } = useTranslation();
  const { isLoading, isError, isSuccess, data, refetch } =
    useGetCheckoutDataQuery({
      proformaInvoice: proformaInvoice || '',
      contractId: contractId || '',
      registrationCode: registrationCode || '',
      checkoutToken: checkoutToken || '',
    });

  const [, { isLoading: isProcessingPayment }] = useCompletePaymentMutation({
    fixedCacheKey: 'payment-processing',
  });

  const [, { isLoading: isCompletingOrder }] = useCompleteOrderMutation({
    fixedCacheKey: 'complete-order-processing',
  });

  const { data: settings, isSuccess: isSettingsSuccess } =
    useGetCheckoutSettingsQuery(
      {
        registrationCode: registrationCode || '',
        checkoutToken: checkoutToken || '',
      },
      { skip: !registrationCode && !checkoutToken }
    );

  useEffect(() => {
    if (isSettingsSuccess) {
      const checkoutSettings = settings.data.checkoutSettings;
      const showNextPrevButtons = checkoutSettings.showNextPrevButtons;
      setShowNavButtons(showNextPrevButtons);
    }

    // eslint-disable-next-line
  }, [isSettingsSuccess]);

  const userData =
    data && typeof data === 'object' && 'user' in data && data.user
      ? data.user
      : null;

  const contractData =
    data && typeof data === 'object' && 'contract' in data && data.contract
      ? data.contract
      : null;

  let user: ICheckoutUserData | null = useMemo(() => {
    if (userData && typeof userData === 'object') {
      return userData;
    }
    return null;
  }, [userData]);

  let proformaInvoiceDetails: ICheckoutProforma | null = null;

  let contract: ICheckoutContract | null = useMemo(() => {
    if (contractData && typeof contractData === 'object') {
      return contractData;
    }

    return null;
  }, [contractData]);
  let content: React.ReactNode | null = null;
  const upperBound = 3;

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(successRegistrationState());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localStorage.removeItem('selected-area-of-interest');
    localStorage.removeItem('selected-filter-letter');
    localStorage.removeItem('more-billing-address-details');
  }, []);

  if (flowData && Array.isArray(flowData)) {
    currentFlowPage = flowData.length;
  }

  const scrollToNextCardHandler = () => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollLeft += wrapperRef.current.clientWidth;

      if (currentPage < upperBound) {
        setCurrentPage((prevState) => prevState + 1);
      }
    }
  };

  const scrollToPrevCardHandler = () => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollLeft -= wrapperRef.current.clientWidth;

      if (currentPage > 1) {
        setCurrentPage((prevState) => prevState - 1);
      }
    }
  };

  if (isLoading) {
    content = (
      <div className={classes.LoadingWrapper}>
        <SimpleLoading />
      </div>
    );
  }

  if (
    !checkoutToken ||
    !registrationCode ||
    !contractId ||
    !proformaInvoice ||
    isError
  ) {
    return <ErrorPage />;
  }

  if (
    settings &&
    typeof settings === 'object' &&
    'isSuccess' in settings &&
    settings.isSuccess
  ) {
    checkOutSettings = settings.data.checkoutSettings;
    if (checkOutSettings) {
      registrationSuccessSettings = checkOutSettings.registrationSuccess;
      proformaSettings = checkOutSettings.proforma;
      checkoutSectionSettings = checkOutSettings.checkoutSection;
    }
  }

  if (
    data &&
    typeof data === 'object' &&
    'proformaInvoice' in data &&
    data.proformaInvoice &&
    'contract' in data &&
    data.contract
  ) {
    proformaInvoiceDetails = data.proformaInvoice;
    contract = data.contract;
  }

  const pageSelectHandler = (pageNumber: number) => {
    if (isProcessingPayment) {
      return;
    }

    if (wrapperRef && wrapperRef.current) {
      setCurrentPage(pageNumber);

      wrapperRef.current.scrollLeft =
        wrapperRef.current.clientWidth * (pageNumber - 1);
    }
  };

  const updateAddressHandler = async () => {
    await refetch();
  };

  if (isSuccess && data && !isLoading) {
    content = (
      <div className={classes.ContentWrapper}>
        {user && currentPage === 1 ? (
          <RegistrationSuccessSection
            user={user}
            settings={registrationSuccessSettings}
          />
        ) : null}
        {contract && proformaInvoiceDetails && user && currentPage === 2 ? (
          <OrderDetailsSection
            proformaInvoice={proformaInvoiceDetails}
            contract={contract}
            user={user}
            onAddressUpdate={updateAddressHandler}
            settings={proformaSettings}
          />
        ) : null}

        {user && proformaInvoiceDetails && contract && currentPage === 3 ? (
          <CheckoutSection
            user={user}
            contract={contract}
            proformaInvoice={proformaInvoiceDetails}
            onAddressUpdate={updateAddressHandler}
            settings={checkoutSectionSettings}
          />
        ) : null}
      </div>
    );
  }

  if (isOrderCompleteSuccess && user && contract && proformaInvoiceDetails) {
    content = (
      <CheckoutSection
        user={user}
        contract={contract}
        proformaInvoice={proformaInvoiceDetails}
        onAddressUpdate={updateAddressHandler}
        settings={checkoutSectionSettings}
      />
    );
  }

  return (
    <SiteEntryContainer>
      <div className={classes.FlowBarContainer}>
        <FlowBar flowData={flowData} currentPageNumber={currentFlowPage} />

        {!isOrderCompleteSuccess ? (
          <p>{t('checkout:you_are_almost_there')}</p>
        ) : (
          <p>{t('checkout:order_success')}</p>
        )}
      </div>
      <div className={classes.CheckoutPageContainer}>
        <div
          className={`${classes.ContentContainer} ${
            currentPage === upperBound ? classes.TransBackground : ''
          }`}
        >
          {!isOrderCompleteSuccess ? (
            <div className={classes.SectionBars}>
              {user ? (
                <button
                  className={currentPage === 1 ? classes.Selected : ''}
                  onClick={() => pageSelectHandler(1)}
                  disabled={isProcessingPayment || isCompletingOrder}
                >
                  {t('checkout:registration_success')}
                </button>
              ) : null}
              {proformaInvoiceDetails && contract ? (
                <button
                  className={currentPage === 2 ? classes.Selected : ''}
                  onClick={() => pageSelectHandler(2)}
                  disabled={isProcessingPayment || isCompletingOrder}
                >
                  {t('checkout:order_details_proforma')}
                </button>
              ) : null}
              {proformaInvoiceDetails && contract ? (
                <button
                  className={currentPage === upperBound ? classes.Selected : ''}
                  onClick={() => pageSelectHandler(upperBound)}
                  disabled={isProcessingPayment || isCompletingOrder}
                >
                  {t('checkout:checkout')}
                </button>
              ) : null}
            </div>
          ) : null}

          <div className={classes.MovableContentContainer} ref={wrapperRef}>
            {content}
          </div>
          {!isLoading && showNavButtons && !isOrderCompleteSuccess ? (
            <ActionContainer className={classes.PageActions}>
              <Button
                btnClass={classes.Button}
                onClick={scrollToPrevCardHandler}
                isFake={
                  currentPage === 1 || isProcessingPayment || isCompletingOrder
                }
              >
                {t('checkout:previous_page')}
              </Button>
              <Button
                btnClass={classes.Button}
                onClick={scrollToNextCardHandler}
                isFake={
                  currentPage === upperBound ||
                  isProcessingPayment ||
                  isCompletingOrder
                }
              >
                {t('checkout:next_page')}
              </Button>
            </ActionContainer>
          ) : null}
        </div>
      </div>
    </SiteEntryContainer>
  );
};

export default RegistrationCheckout;
