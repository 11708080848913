import React, { Fragment, useEffect } from 'react';
import { useGetAdminUserContractDetailsQuery } from '../../../../../../features/userAdmin/userAdminSlice';
import { useSearchParams } from 'react-router-dom';
import useSessionLogout from '../../../../../../CustomHooks/useSessionLogout';
import FetchingReports from '../../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../../UI/NoDataFound/NoDataFound';
import ActionContainer from '../../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../../UI/FormElements/StandardButton/StandardButton';

import classes from './ContractDetails.module.scss';
import DataRow from '../../../../../UI/DataRow/DataRow';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

const ContractDetails = () => {
  const [pageQuery, setPageQuery] = useSearchParams();
  const { t, i18n } = useTranslation();
  const page = pageQuery.get('page');
  const currentContractId = pageQuery.get('contract');
  const section = pageQuery.get('section');
  const packageId = pageQuery.get('package');
  const language = i18n.language || 'en';

  const {
    isLoading,
    isError,
    data: contractDetails,
    error,
  } = useGetAdminUserContractDetailsQuery(
    {
      contractId: currentContractId || '',
      contractPackageId: packageId || '',
    },
    {
      skip:
        !packageId || !currentContractId || !section || Number(section) !== 1,
    }
  );

  useSessionLogout({ isError, error });

  useEffect(() => {
    if (isError && page && currentContractId) {
      const timer = setTimeout(() => {
        setPageQuery({ page, contract: currentContractId });
      }, 18000);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line
  }, [isError, section]);

  if (Number(section) === 1 && isLoading && !contractDetails) {
    return <FetchingReports message={t('admin:fetching_contract_details')} />;
  }

  const closeDetailsHanlder = () => {
    if (page && currentContractId && section) {
      setPageQuery({ page, contract: currentContractId });
    }
  };

  const openProformaInvoiceHandler = (proformaInvoice: string) => {
    if (page && currentContractId && section) {
      setPageQuery({
        page: 'invoices',
        contract: currentContractId,
        document: proformaInvoice,
        docType: 'proforma',
      });
    }
  };

  const errorContent = (
    <Fragment>
      <NoDataFound message={t('admin:contract_details_fetch_error')} />
      <ActionContainer
        setup={{ justifyContent: 'flex-end' }}
        className={classes.ErrorActions}
      >
        <StandardButton
          type='button'
          btnType='orange'
          label={t('translations:Close')}
          onClick={closeDetailsHanlder}
        />
      </ActionContainer>
    </Fragment>
  );

  if (Number(section) === 1 && isError && !contractDetails && error) {
    return <Fragment>{errorContent}</Fragment>;
  }

  if (
    !contractDetails ||
    typeof contractDetails !== 'object' ||
    !('contract' in contractDetails) ||
    !contractDetails.contract ||
    typeof contractDetails.contract !== 'object' ||
    !('contractPackage' in contractDetails) ||
    !contractDetails.contractPackage
  ) {
    return <Fragment>{errorContent}</Fragment>;
  }

  const division =
    contractDetails.contract.address.division &&
    'name' in contractDetails.contract.address.division &&
    contractDetails.contract.address.division.name
      ? contractDetails.contract.address.division.name
      : '';

  const subdivision =
    contractDetails.contract.address.subDivision &&
    'name' in contractDetails.contract.address.subDivision &&
    contractDetails.contract.address.subDivision.name
      ? contractDetails.contract.address.subDivision.name
      : '';

  const settlement =
    contractDetails.contract.address.settlement &&
    'name' in contractDetails.contract.address.settlement &&
    contractDetails.contract.address.settlement.name
      ? contractDetails.contract.address.settlement.name
      : '';

  const location = settlement || subdivision || division;
  const district = location && location !== division ? division : '';

  return (
    <div className={classes.Wrapper}>
      <div className={classes.ButtonSection}>
        {/* <StandardButton
          type='button'
          btnType='orange'
          label='Download Contract Details'
          onClick={() => {}}
        /> */}
        <StandardButton
          type='button'
          btnType='orange'
          label={t('translations:Close')}
          onClick={closeDetailsHanlder}
        />
      </div>
      <div className={classes.ContractDetailsContainer}>
        <div className={classes.HeadingSection}>
          <h1>
            {language === 'en'
              ? `Service Contract for ${contractDetails.contract.serviceTypeName}`
              : language === 'el'
              ? `Σύμβαση Παροχής Υπηρεσιών για ${t(
                  `dashboard:${getTranslationKey(
                    contractDetails.contract.serviceTypeName
                  )}`
                )}`
              : `Service Contract for ${contractDetails.contract.serviceTypeName}`}
          </h1>
          <div className={classes.ContractDate}>
            <p>
              {new Date(
                contractDetails.contract.renewalDate
              ).toLocaleDateString(language, {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              })}
            </p>
          </div>
        </div>
        <div className={classes.ContractDetailsSection}>
          <p className={classes.EntryText}>
            This Service Agreement (the "Agreement" or the "Contract") is
            entered into{' '}
            {new Date(contractDetails.contract.renewalDate).toLocaleDateString(
              'en',
              { year: 'numeric', month: 'long', day: '2-digit' }
            )}{' '}
            by and between {contractDetails.contract.organization.name} (the
            "Customer") located at {location} {district},
            {contractDetails.contract.address.countryDetails.country} and
            Periopsis Ltd (the "Service Provider") located at Nicosia, Cyprus,
            also individually referred to as the "Party", and collectively the
            "Parties".
          </p>
          <div className={classes.Section}>
            <h2>1. Services</h2>
            <p>
              The Service Provider shall perform the services listed in this
              Section 1 (the "Services").
            </p>
            <p>
              The primary {contractDetails.contract.serviceTypeName} Service
              shall be deployed in specifically designated areas, encompassing a
              cumulative area of {contractDetails.contract.totalArea.toFixed(2)}{' '}
              {contractDetails.contract.areaUnit}. Throughout the entirety of
              the {contractDetails.contractPackage.duration}{' '}
              {contractDetails.contractPackage.durationUnit} contractual period,
              the Service Provider is committed to delivering comprehensive
              {contractDetails.contract.serviceTypeName} services to the
              Customer within the selected zones (the "Areas of Interest").
            </p>
            <div className={classes.ListSection}>
              <h3>{t('admin:areas_of_interests')}</h3>
              <ul className={classes.AreasContainer}>
                {contractDetails.contract.areaOfInterests.map((area) => (
                  <li key={area._id}>
                    {area.polygonName ? (
                      <DataRow
                        heading={t('admin:polygon_name')}
                        data={area.polygonName}
                        removeSeperator
                      />
                    ) : null}
                    <DataRow
                      heading={t('auth:district')}
                      data={t(
                        `translations:${getTranslationKey(area.district)}`
                      )}
                      removeSeperator
                    />
                    <DataRow
                      heading={t('auth:country')}
                      data={t(`auth:${getTranslationKey(area.country)}`)}
                      removeSeperator
                    />
                    <DataRow
                      heading={t('auth:area')}
                      data={`${area.area.toFixed(2)} ${area.areaUnit}`}
                      removeSeperator
                    />{' '}
                  </li>
                ))}
              </ul>
            </div>
            <div className={classes.ListSection}>
              <h3>{t('admin:selected_package_services')}</h3>
              <p className={classes.ListSectionText}>
                In accordance with the chosen primary{' '}
                {contractDetails.contract.serviceTypeName} service package, the
                subsequent services and conditions shall remain applicable
                throughout the {contractDetails.contractPackage.duration}{' '}
                {contractDetails.contractPackage.durationUnit} service period
                stipulated in the contract.
              </p>
              <ul className={classes.ServicesList}>
                {contractDetails.contractPackage.packageServices.map(
                  (service) => (
                    <li key={service}>{service}</li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className={classes.Section}>
            <h2>2. {t('admin:selected_package_details')}</h2>
            <div>
              <h4>{t('admin:description_of_package')}</h4>
              <p>{contractDetails.contractPackage.description}</p>
            </div>
            <DataRow
              heading={t('admin:package_name')}
              data={contractDetails.contractPackage.packageName}
              removeSeperator
            />
            <DataRow
              heading={t('admin:duration_of_package')}
              data={`${contractDetails.contractPackage.duration} ${t(
                `auth:${getTranslationKey(
                  contractDetails.contractPackage.durationUnit
                )}`
              )}`}
              removeSeperator
            />
            <DataRow
              heading={t('admin:user_limit_of_package')}
              data={`${contractDetails.contractPackage.userLimit} ${t(
                'admin:users'
              )}`}
              removeSeperator
            />
            <DataRow
              heading={t('admin:monthly_price')}
              data={`${contractDetails.contractPackage.currency}${contractDetails.contractPackage.monthlyPrice} ${contractDetails.contractPackage.priceUnit}`}
              removeSeperator
            />
            <div className={classes.ListSection}>
              <h3>{t('admin:package_services')}</h3>
              <ul className={classes.ServicesList}>
                {contractDetails.contractPackage.packageServices.map(
                  (service) => (
                    <li key={service}>{service}</li>
                  )
                )}
              </ul>
            </div>
            {contractDetails.contractPackage.selectedSubservices.length > 0 ? (
              <div className={classes.ListSection}>
                <h3>Subservices of Package</h3>
                <ul className={classes.ServicesList}>
                  {contractDetails.contractPackage.selectedSubservices.map(
                    (subservice) => (
                      <li key={subservice.subserviceId}>
                        <DataRow
                          heading='Subservice Name'
                          data={subservice.subserviceName}
                        />
                        <DataRow
                          heading='Subservice Monthly Price'
                          data={`${subservice.currency}${subservice.subserviceMonthlyPrice}`}
                        />
                      </li>
                    )
                  )}
                </ul>
              </div>
            ) : null}
          </div>
          <div className={classes.Section}>
            <h2>3. {t('admin:compensation')}</h2>
            <p>
              The Customer hereby undertakes to remit the agreed-upon amount to
              the Service Provider as calculated and outlined in Proforma Number{' '}
              <span
                className={classes.ProformaInvoiceLink}
                onClick={() =>
                  openProformaInvoiceHandler(
                    contractDetails.contractPackage.proformaInvoiceNumber
                  )
                }
              >
                {contractDetails.contractPackage.proformaInvoiceNumber}
              </span>
              . This payment is to be fulfilled within 15 days from the
              commencement of the contract, which is on the date of{' '}
              {new Date(
                contractDetails.contract.renewalDate
              ).toLocaleDateString('en', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              })}
              .
            </p>
            <div className={classes.ProformaInvoiceLinkSection}>
              <p>Open Proforma Invoice : </p>
              <button
                className={classes.ProformaInvoiceLink}
                type='button'
                onClick={() =>
                  openProformaInvoiceHandler(
                    contractDetails.contractPackage.proformaInvoiceNumber
                  )
                }
              >
                {contractDetails.contractPackage.proformaInvoiceNumber}
              </button>
            </div>
          </div>

          <div className={classes.Section}>
            <h2>4. {t('admin:expenses')}</h2>
            <p>{t('admin:expenses_desc')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
