import React from 'react';
import classes from './ReportCommonContent.module.scss';
import StandardButton from '../../../../../UI/FormElements/StandardButton/StandardButton';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import IncidentDetailsSection from '../../IncidentDetailsSection/IncidentDetailsSection';
import { IContractIncident } from '../../../../../../tsTypes/interfaces';
import ActionContainer from '../../../../../UI/FormElements/ActionContainer/ActionContainer';
import { useTranslation } from 'react-i18next';

interface IProps {
  numberOfReports: number;
  onStartNewReport: () => void;
  onNavigation: (direction: 'left' | 'right') => void;
  currentReportIndex: number;
  children: React.ReactNode;
  selectedIncident: IContractIncident;
  isReadyToSend?: boolean;
  isNewReport?: boolean;
  onSend: () => void;
}

const ReportCommonContent = ({
  numberOfReports,
  onStartNewReport,
  onNavigation,
  currentReportIndex,
  children,
  selectedIncident,
  isNewReport,
  isReadyToSend,
  onSend,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.ReportWrapper}>
      {numberOfReports > 0 ? (
        <div className={classes.ReportNavigator}>
          <StandardButton
            label={t('forms:New_Report')}
            type='button'
            btnType='orange'
            onClick={onStartNewReport}
          />
          {numberOfReports > 1 ? (
            <div className={classes.NavigationButtons}>
              <button
                onClick={() => onNavigation('left')}
                disabled={currentReportIndex === 0}
              >
                <FaChevronLeft />
              </button>
              <button disabled={currentReportIndex === numberOfReports - 1}>
                <FaChevronRight onClick={() => onNavigation('right')} />
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
      <IncidentDetailsSection
        selectedIncident={selectedIncident}
        information={t('forms:Include_Details_Desc')}
      />
      {children}
      <ActionContainer className={classes.SendActions}>
        <StandardButton
          label={
            isNewReport ? t('forms:Send_Report') : t('forms:Resend_Report')
          }
          type='button'
          btnType='primary'
          title='Click to send report'
          fakeButton={!isReadyToSend}
          style={{ padding: '0.6rem 1.2rem' }}
          onClick={onSend}
        />
      </ActionContainer>
    </div>
  );
};

export default ReportCommonContent;
