import { useTranslation } from 'react-i18next';
import classes from './IncidentAddress.module.scss';

interface IProps {
  address: string;
  removeStreetText?: boolean;
  style?: React.CSSProperties;
}

const IncidentAddress = ({ address, removeStreetText, style }: IProps) => {
  const { t } = useTranslation();

  if (!address || typeof address !== 'string' || address.length === 0) {
    return null;
  }

  return (
    <div className={classes.IncidentAddressContainer} style={style}>
      {!removeStreetText ? <p>{t('incidentDashboard:Street')} : </p> : null}
      <address>{address}</address>
    </div>
  );
};

export default IncidentAddress;
