import { useSearchParams } from 'react-router-dom';
import { useRemoveUserFromOrganizationUsersMutation } from '../../../../../features/userAdmin/userAdminSlice';
import { IOrganizationUser } from '../../../../../tsTypes/interfaces';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import OrganizationUserItem from '../OrganizationUserItem/OrganizationUserItem';
import { ChangeEvent } from 'react';
import CenterDiv from '../../../../UI/CenterDiv/CenterDiv';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import classes from './OrganizationUsersList.module.scss';

interface IProps {
  users: IOrganizationUser[];
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
}

const OrganizationUsersList = ({ users, onSelect }: IProps) => {
  const [pageQuery] = useSearchParams();
  const contractId = pageQuery.get('contract');
  const [removeUserFromList, { isLoading }] =
    useRemoveUserFromOrganizationUsersMutation();

  const hasUsersFromOtherContracts = users.some(
    (user) => !user.isUserOfContract
  );

  if (!hasUsersFromOtherContracts) {
    return null;
  }

  const removeUserFromOrganizationUsersListHandler = async (userId: string) => {
    try {
      if (userId && contractId) {
        await removeUserFromList({ userId, contractId }).unwrap();
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  if (isLoading) {
    return (
      <CenterDiv>
        <SimpleLoading />
      </CenterDiv>
    );
  }

  return (
    <div className={classes.MainContainer}>
      <h3>Organization Users List</h3>
      <div className={classes.OrganizationUsersListWrapper}>
        <table className={classes.OrganizationUsersTable}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Email</th>
              <th>Contracts Assigned</th>
              <th>Select</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <OrganizationUserItem
                user={user}
                key={user.user.userId}
                onRemove={removeUserFromOrganizationUsersListHandler}
                onSelect={onSelect}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrganizationUsersList;
