import { Currency } from '../../../../../../../../tsTypes/types.model';
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import { MdWarningAmber } from 'react-icons/md';
import CheckBox from '../../../../../../../UI/FormElements/CheckBox/CheckBox';
import classes from './SubserviceOption.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  subserviceName: string;
  description: string;
  monthlyPrice: number;
  currency: Currency;
  onSelect: () => void;
  showDescription: boolean;
  onShowDescription: () => void;
  onShowTerms: () => void;
  isSelected: boolean;
}

const SubserviceOption = ({
  subserviceName,
  description,
  monthlyPrice,
  currency,
  onSelect,
  showDescription,
  onShowDescription,
  onShowTerms,
  isSelected,
}: IProps) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  let termsAndConditionsComponent = null;

  switch (language) {
    case 'en':
      termsAndConditionsComponent = (
        <p className={classes.TermsAndConditionsNote}>
          Please read <span onClick={onShowTerms}>terms and conditions</span> of
          service by clicking on the icon (
          <MdWarningAmber onClick={onShowTerms} style={{ cursor: 'pointer' }} />
          ) on the right of information icon before selecting the service
        </p>
      );
      break;
    case 'el':
      termsAndConditionsComponent = (
        <p>
          Παρακαλώ διαβάστε τους{' '}
          <span onClick={onShowTerms}>όρους και προϋποθέσεις</span> της
          υπηρεσίας κάνοντας κλικ στο εικονίδιο (
          <MdWarningAmber onClick={onShowTerms} style={{ cursor: 'pointer' }} />
          ) δεξιά του εικονιδίου πληροφοριών πριν επιλέξετε την υπηρεσία.
        </p>
      );
      break;

    default:
      termsAndConditionsComponent = (
        <p className={classes.TermsAndConditionsNote}>
          Please read <span onClick={onShowTerms}>terms and conditions</span> of
          service by clicking on the icon (
          <MdWarningAmber onClick={onShowTerms} style={{ cursor: 'pointer' }} />
          ) on the right of information icon before selecting the service
        </p>
      );
      break;
  }

  return (
    <div className={classes.Wrapper}>
      <div className={classes.SubserviceOptionContainer}>
        <CheckBox
          label={subserviceName}
          isSelected={isSelected}
          onSelect={onSelect}
        />
        <div className={classes.SubserviceInfoContainer}>
          <data>
            {currency}
            {monthlyPrice}/{t('auth:month')}
          </data>
          <button
            onClick={onShowDescription}
            title={
              !showDescription
                ? t('auth:read_about_service')
                : t('auth:close_description_window')
            }
          >
            {!showDescription ? (
              <AiOutlineInfoCircle />
            ) : (
              <AiOutlineCloseCircle />
            )}
          </button>
          <button
            onClick={onShowTerms}
            title={t('auth:terms_and_conditions_of_the_service')}
          >
            <MdWarningAmber />
          </button>
        </div>
      </div>
      <div
        className={`${classes.ServiceDescription} ${
          showDescription ? classes.Show : ''
        }`}
      >
        <p>{description}</p>
        {termsAndConditionsComponent}
      </div>
    </div>
  );
};

export default SubserviceOption;
