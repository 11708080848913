import { IVisitor } from '../tsTypes/interfaces';

const isSameVisitorsList = (visitosData: {
  existingVisitorsList: IVisitor[];
  updatedVisitorsList: IVisitor[];
}) => {
  const { existingVisitorsList, updatedVisitorsList } = visitosData;
  let isSame = true;

  //To prevent map operation on empty arrays.
  if (existingVisitorsList.length === 0 && updatedVisitorsList.length === 0) {
    return isSame;
  }

  if (existingVisitorsList.length !== updatedVisitorsList.length) {
    return !isSame;
  }

  let existingIds: { [key: string]: string } = {};

  //Create ids hash map to compare ids with the ids at updated list
  existingVisitorsList.forEach((visitor) => {
    existingIds[visitor.id] = visitor.id;
  });

  updatedVisitorsList.forEach((visitor) => {
    if (!(visitor.id in existingIds)) {
      isSame = false;
    }
  });

  return isSame;
};

export default isSameVisitorsList;
