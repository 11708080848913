import { useLayoutEffect, useState } from 'react';
import useDirections from '../../../../CustomHooks/useDirections';
import {
  ILocation,
  INullableLocation,
} from '../../../../tsTypes/types.components';
import IncidentMarker from '../../../MapSWComponents/IncidentMarker/IncidentMarker';
import MapDirectionLayer from '../../../MapSWComponents/MapDirectionLayer/MapDirectionLayer';
import MapDirectionsBoard from '../../../MapSWComponents/MapDirectionsBoard/MapDirectionsBoard';
import MapSW from '../../../MapSWComponents/MapSW';
import NoData from '../../../UI/NoData/NoData';
import { IContractIncident } from '../../../../tsTypes/interfaces';
import { Maps } from '../../../../tsTypes/enums';
import getMapHeight from '../../../../utils/getMapHeight';
import { Coordinates } from '../../../../tsTypes/types';
import FlyTo from '../../../UI/FlyTo/FlyTo';

interface IProps {
  selectedIncident: IContractIncident | null;
  showDirectionsBoard?: boolean;
  onShowBoard?: () => void;
}

const IncidentMap = ({
  selectedIncident,
  showDirectionsBoard,
  onShowBoard,
}: IProps) => {
  let targetIncident: {
    Coordinates: { latitude: number; longitude: number };
    Type: string;
  } | null = null;
  const [startLocation, setStartLocation] = useState<INullableLocation>({
    latitude: null,
    longitude: null,
  });

  const [mapHeight, setMapHeight] = useState(520);

  useLayoutEffect(() => {
    const updatedViewportWidth =
      window.innerWidth || document.documentElement.clientWidth;
    // const updatedViewportHeight =
    //   window.innerHeight || document.documentElement.clientHeight;

    const mapHeightResult = getMapHeight(updatedViewportWidth);

    setMapHeight(mapHeightResult);
  }, []);

  const noIncidentFound =
    !selectedIncident ||
    (selectedIncident &&
      typeof selectedIncident !== 'object' &&
      !('coordinates' in selectedIncident));

  const { latitude: latitudeStart, longitude: longitudeStart } = startLocation
    ? startLocation
    : { latitude: null, longitude: null };
  const { latitude: latitudeEnd, longitude: longitudeEnd } =
    selectedIncident &&
    typeof selectedIncident === 'object' &&
    'coordinates' in selectedIncident &&
    selectedIncident.coordinates &&
    Array.isArray(selectedIncident.coordinates) &&
    selectedIncident.coordinates.length === 2
      ? {
          latitude: selectedIncident.coordinates[0],
          longitude: selectedIncident.coordinates[1],
        }
      : { latitude: null, longitude: null };

  let incidentCoords: Coordinates | [] = [];

  const { steps, directions, restartFetch } = useDirections({
    latitudeStart,
    longitudeStart,
    latitudeEnd,
    longitudeEnd,
  });

  if (noIncidentFound) {
    return <NoData message='No incident data found.' />;
  }

  if (
    selectedIncident &&
    typeof selectedIncident === 'object' &&
    'coordinates' in selectedIncident &&
    'type' in selectedIncident &&
    selectedIncident.coordinates &&
    selectedIncident.type
  ) {
    targetIncident = {
      Coordinates: {
        latitude: selectedIncident.coordinates[0],
        longitude: selectedIncident.coordinates[1],
      },
      Type: selectedIncident.type,
    };
  }

  const getUserCurrentLocationHandler = (userLocation: ILocation) => {
    setStartLocation(userLocation);
    restartFetch();
  };

  const getUserCurrentOrganizationLocationHandler = (
    organizationLocation: ILocation
  ) => {
    setStartLocation(organizationLocation);
    restartFetch();
  };

  if (
    selectedIncident &&
    typeof selectedIncident === 'object' &&
    'coordinates' in selectedIncident
  ) {
    incidentCoords = selectedIncident.coordinates;
  }

  return (
    <MapSW
      hideCoordinatesBar
      mapId='incidentMap'
      initMap={Maps.satelliteMap}
      height={mapHeight}
    >
      <IncidentMarker incidentData={selectedIncident} hidePopup />
      {showDirectionsBoard && (
        <MapDirectionLayer
          startLocation={startLocation}
          endLocation={
            targetIncident
              ? targetIncident.Coordinates
              : { latitude: null, longitude: null }
          }
          directions={directions}
        />
      )}
      <MapDirectionsBoard
        selectedIncident={targetIncident}
        stepsData={steps}
        show={showDirectionsBoard}
        onClick={onShowBoard}
        onGetUserLocation={getUserCurrentLocationHandler}
        onGetCurrentOrganizationLocation={
          getUserCurrentOrganizationLocationHandler
        }
      />
      {incidentCoords.length === 2 ? (
        <FlyTo coordinates={incidentCoords} zoomLevel={18} />
      ) : null}
    </MapSW>
  );
};

export default IncidentMap;
