import { AiFillHome } from 'react-icons/ai';
import Logo from '../Logo/Logo';
import classes from './SiteEntryContainer.module.scss';

interface IProps {
  children: React.ReactNode;
}

const SiteEntryContainer = ({ children }: IProps) => {
  return (
    <div className={classes.MainContainer}>
      <div className={classes.LeftContent}>
        <Logo className={classes.PeriopsisLogo} />
        <div className={classes.HomePageRedirectSection}>
          <a href='https://www.periopsis.com'>
            <AiFillHome />
          </a>
        </div>
      </div>

      <div className={classes.FormContainer}>{children}</div>
    </div>
  );
};

export default SiteEntryContainer;
