import { periopsisApi } from '../api/apiSlice';
import { IAddress, IResponseData } from '../../tsTypes/types.model';
import {
  IBankDetails,
  ICardPaymentRequest,
  ICheckoutContract,
  ICheckoutProforma,
  ICheckoutUserData,
  ICompleteOrderRequest,
} from '../../tsTypes/checkout.interfaces';

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getCheckoutData: builder.query({
      query: (checkoutParams: {
        proformaInvoice: string;
        contractId: string;
        registrationCode: string;
        checkoutToken: string;
      }) => {
        const { proformaInvoice, contractId, checkoutToken, registrationCode } =
          checkoutParams;

        return {
          url: `/checkout?proformaInvoice=${proformaInvoice}&contractId=${contractId}`,
          method: 'GET',
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          contract: ICheckoutContract;
          proformaInvoice: ICheckoutProforma;
          user: ICheckoutUserData;
        }>
      ) => {
        const { contract, proformaInvoice, user } = responseData.data;

        return { contract, proformaInvoice, user };
      },
      providesTags: ['CheckoutProformaData'],
      keepUnusedDataFor: 20,
    }),
    getBankDetails: builder.query({
      query: (bankDetailsData: {
        registrationCode: string;
        checkoutToken: string;
      }) => {
        const { registrationCode, checkoutToken } = bankDetailsData;

        return {
          url: '/checkout/bank-details',
          method: 'GET',
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          bankDetails: IBankDetails[];
        }>
      ) => {
        const { bankDetails } = responseData.data;
        const isSuccess = responseData.isSuccess;

        return { bankDetails, isSuccess };
      },
    }),
    getCheckoutSettings: builder.query({
      query: (tokens: { registrationCode: string; checkoutToken: string }) => {
        const { registrationCode, checkoutToken } = tokens;

        return {
          url: '/checkout/settings',
          method: 'GET',
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
    }),
    clearRegistrationSuccessData: builder.mutation({
      query: (tokens: { registrationCode: string; checkoutToken: string }) => {
        const { registrationCode, checkoutToken } = tokens;

        return {
          url: '/checkout/clear-registration-code',
          method: 'GET',
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
    }),
    updateBillingAddress: builder.mutation({
      query: (billingAddressUpdateData: {
        updatedAddress: IAddress;
        registrationCode: string;
        checkoutToken: string;
        proformaInvoiceNumber: string;
      }) => {
        const {
          updatedAddress,
          registrationCode,
          checkoutToken,
          proformaInvoiceNumber,
        } = billingAddressUpdateData;

        return {
          url: `/checkout`,
          method: 'PATCH',
          body: {
            updatedAddress,
            proformaInvoiceNumber,
          },
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          contract: ICheckoutContract;
          proformaInvoice: ICheckoutProforma;
          user: ICheckoutUserData;
        }>
      ) => {
        const { proformaInvoice, contract, user } = responseData.data;

        return { proformaInvoice, contract, user };
      },
      invalidatesTags: ['CheckoutProformaData'],
    }),

    addNewAddress: builder.mutation({
      query: (addAddressData: {
        newAddress: IAddress;
        registrationCode: string;
        checkoutToken: string;
        proformaInvoiceNumber: string;
      }) => {
        const {
          newAddress,
          registrationCode,
          checkoutToken,
          proformaInvoiceNumber,
        } = addAddressData;

        return {
          url: `/checkout`,
          method: 'POST',
          body: {
            newAddress,
            proformaInvoiceNumber,
          },
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          proformaInvoice: ICheckoutProforma;
          user: ICheckoutUserData;
        }>
      ) => {
        const { proformaInvoice, user } = responseData.data;

        return { proformaInvoice, user };
      },
      invalidatesTags: ['CheckoutProformaData'],
    }),
    deleteAddress: builder.mutation({
      query: (deleteAddressData: {
        addressId: string;
        registrationCode: string;
        checkoutToken: string;
      }) => {
        const { addressId, registrationCode, checkoutToken } =
          deleteAddressData;

        return {
          url: `/checkout/${addressId}`,
          method: 'DELETE',
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          user: ICheckoutUserData;
        }>
      ) => {
        const { user } = responseData.data;

        return { user };
      },
      invalidatesTags: ['CheckoutProformaData'],
    }),

    completePayment: builder.mutation({
      query: (paymentData: ICardPaymentRequest) => {
        const {
          checkoutToken,
          registrationCode,
          cardData,
          selectedOptions,
          proformaInvoiceNumber,
        } = paymentData;

        return {
          url: '/checkout/payment',
          method: 'POST',
          body: {
            cardData,
            selectedOptions,
            proformaInvoiceNumber,
          },
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
    }),
    completeOrder: builder.mutation({
      query: (orderData: ICompleteOrderRequest) => {
        const {
          checkoutToken,
          registrationCode,
          selectedOptions,
          proformaInvoiceNumber,
        } = orderData;

        return {
          url: '/checkout/complete-order',
          method: 'POST',
          body: {
            selectedOptions,
            proformaInvoiceNumber,
          },
          headers: {
            'X-Checkout-Token': checkoutToken,
            'X-Reg-Code': registrationCode,
          },
        };
      },
    }),
  }),
});

export const {
  useGetCheckoutDataQuery,
  useGetBankDetailsQuery,
  useGetCheckoutSettingsQuery,
  useUpdateBillingAddressMutation,
  useAddNewAddressMutation,
  useDeleteAddressMutation,
  useCompletePaymentMutation,
  useCompleteOrderMutation,
  useClearRegistrationSuccessDataMutation,
} = extendedApiSlice;
