import { ChangeEvent, useEffect, useState } from 'react';

interface IProps {
  existingNote: string;
  isLoading: boolean;
}

const useUpdateNote = ({ existingNote, isLoading }: IProps) => {
  const [edit, setEdit] = useState(false);
  const [updated, setUpdated] = useState('');

  useEffect(() => {
    if (existingNote) {
      setUpdated(existingNote);
    }
  }, [existingNote]);

  const willUpdateHandler = () => {
    if (isLoading) {
      return;
    }
    setEdit(true);
  };

  const closeUpdateHandler = () => {
    setEdit(false);
  };

  const undoEditedHandler = () => {
    if (isLoading) {
      return;
    }

    if (existingNote) {
      setUpdated(existingNote);
    }
  };

  const addOrChangeNoteHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (isLoading) {
      return;
    }

    setUpdated(e.target.value);
  };

  return {
    edit,
    updated,
    closeUpdateHandler,
    undoEditedHandler,
    addOrChangeNoteHandler,
    willUpdateHandler,
  };
};

export default useUpdateNote;
