import {
  useGetAdminUserInvoicesAndReceiptsQuery,
  useGetDocumentDetailsQuery,
} from '../../../../features/userAdmin/userAdminSlice';
import { useSearchParams } from 'react-router-dom';
import ContentContainer from '../../LoginRegister/SignUp/RegistrationCheckout/ContentContainer/ContentContainer';
import DocumentCard from './DocumentCard/DocumentCard';
import { Currency } from '../../../../tsTypes/types.model';
import DocumentListContainer from './DocumentListContainer/DocumentListContainer';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import ErrorControlComponent from '../../../UI/ErrorControlComponent/ErrorControlComponent';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import { paymentType } from '../../../../tsTypes/types.components';
import { Status } from '../../../../tsTypes/enums';
import { FaFileInvoice, FaFilePdf } from 'react-icons/fa';
import {
  IInvoiceCardResponse,
  IProformaCardResponse,
  IReceiptCardResponse,
} from '../../../../tsTypes/interfaces';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import { documentType } from '../../../../tsTypes/types';
import {
  ICheckoutContract,
  ICheckoutProforma,
  ICheckoutUserData,
} from '../../../../tsTypes/checkout.interfaces';
import ProformaInvoicePDF from '../../../pdfComponents/ProformaInvoice/ProformaInvoicePDF';
import { usePDF } from '@react-pdf/renderer';
import { Fragment, useEffect, useState } from 'react';
import classes from './InvoicesAndReceipts.module.scss';
import AdminPageContainer from '../AdminPageContainer/AdminPageContainer';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import ProformaAndContract from '../../ProformaInvoiceView/ProformaAndContract/ProformaAndContract';
import DataRow from '../../../UI/DataRow/DataRow';
import OrderDataCard from '../../LoginRegister/SignUp/RegistrationCheckout/OrderDetailsSection/OrderDataCard/OrderDataCard';
import ListContainer from '../../LoginRegister/SignUp/RegistrationCheckout/OrderDetailsSection/ListContainer/ListContainer';
import getTotalPolygonPrice from '../../../../utils/getTotalPolygonPrice';
import CustomerDetails from '../../LoginRegister/SignUp/RegistrationCheckout/CustomerDetails/CustomerDetails';
import AddressContent from '../../LoginRegister/SignUp/RegistrationCheckout/AddressContent/AddressContent';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../utils/getTranslationKey';

const InvoicesAndReceipts = () => {
  const [pageQuery, setQuery] = useSearchParams();
  const page = pageQuery.get('page');
  const contractId = pageQuery.get('contract') || '';
  const document = pageQuery.get('doc');
  const docType = pageQuery.get('type');
  const { t } = useTranslation();

  const {
    isLoading: isLoadingInvoices,
    isError: isInvoicesFetchError,
    error,
    data: documents,
  } = useGetAdminUserInvoicesAndReceiptsQuery(contractId, {
    skip: !contractId || !page || page !== 'invoices',
  });

  const {
    isLoading: isLoadingDocument,
    isError: isDocumentError,
    isSuccess: isDocumentDetailsSuccess,
    data: documentDetails,
  } = useGetDocumentDetailsQuery(
    {
      contractId: contractId || '',
      documentId: document || '',
      documentType: docType as documentType,
    },
    { skip: !contractId || !document || !docType }
  );

  let invoiceList: IInvoiceCardResponse[] = [];
  let receiptList: IReceiptCardResponse[] = [];
  let proformaList: IProformaCardResponse[] = [];
  const [showSubservices, setShowSubservices] = useState(false);
  const [showPolygons, setShowPolygons] = useState(false);

  let invoiceListComponent: React.ReactNode = null;
  let receiptListComponent: React.ReactNode = null;
  let proformaListComponent: React.ReactNode = null;
  let pdfDoc: React.ReactNode | null = null;

  let documentData: ICheckoutProforma | null =
    documentDetails !== undefined && typeof documentDetails === 'object'
      ? documentDetails.document
      : null;
  let contract: ICheckoutContract | null =
    documentDetails !== undefined && typeof documentDetails === 'object'
      ? documentDetails.contract
      : null;
  let userData: ICheckoutUserData | null =
    documentDetails !== undefined && typeof documentDetails === 'object'
      ? documentDetails.user
      : null;

  const doc = (
    <ProformaInvoicePDF
      proformaInvoice={documentData}
      contract={contract}
      user={userData}
    />
  );

  const [instance, update] = usePDF({ document: doc });

  useEffect(() => {
    if (isDocumentDetailsSuccess) {
      update(doc);
    }

    // eslint-disable-next-line
  }, [isDocumentDetailsSuccess]);

  if (isLoadingInvoices) {
    return <FetchingReports message={t('admin:fetching_invoices_receipts')} />;
  }

  if (isInvoicesFetchError) {
    return (
      <ErrorControlComponent error={error}>
        <NoDataFound message={t('admin:invoices_fetch_error')} />
      </ErrorControlComponent>
    );
  }

  const showSelectedDocumentDetails = (
    docNumber: string,
    docType: documentType
  ) => {
    if (page && contractId) {
      setQuery({ page, contract: contractId, doc: docNumber, type: docType });
    }
  };

  const closeDocumentDetails = () => {
    if (page && contractId) {
      setQuery({ page, contract: contractId });
    }
  };

  if (
    !isLoadingInvoices &&
    !isInvoicesFetchError &&
    documents &&
    typeof documents === 'object' &&
    'invoices' in documents &&
    documents.invoices &&
    Array.isArray(documents.invoices) &&
    'receipts' in documents &&
    documents.receipts &&
    Array.isArray(documents.receipts) &&
    'proformaInvoices' in documents &&
    documents.proformaInvoices &&
    Array.isArray(documents.proformaInvoices)
  ) {
    invoiceList = documents.invoices;
    receiptList = documents.receipts;
    proformaList = documents.proformaInvoices;
  }

  if (invoiceList.length > 0) {
    invoiceListComponent = (
      <DocumentListContainer heading={t('admin:invoices')}>
        {invoiceList.map((doc) => (
          <DocumentCard
            documentNumber={doc.invoiceNumber}
            documentDate={new Date(doc.invoiceDate)}
            documentType='invoice'
            paymentType={doc.paymentType as paymentType}
            serviceType={doc.serviceType}
            overallTotal={doc.overallTotal}
            currency={doc.selectedPackage.currency as Currency}
            status={doc.status as Status}
            dueDate={new Date(doc.dueDate)}
            billingPeriod={{
              startDate: new Date(doc.billingPeriod.startDate),
              endDate: new Date(doc.billingPeriod.endDate),
            }}
            key={doc.invoiceNumber}
            onSelect={showSelectedDocumentDetails}
          />
        ))}
      </DocumentListContainer>
    );
  }

  if (receiptList.length > 0) {
    receiptListComponent = (
      <DocumentListContainer heading={t('admin:receipts')}>
        {receiptList.map((doc) => (
          <DocumentCard
            documentNumber={doc.receiptNumber}
            documentDate={new Date(doc.receiptDate)}
            documentType='receipt'
            paymentType={doc.paymentType as paymentType}
            serviceType={doc.serviceType}
            overallTotal={doc.overallTotal}
            currency={doc.selectedPackage.currency as Currency}
            billingPeriod={{
              startDate: new Date(doc.billingPeriod.startDate),
              endDate: new Date(doc.billingPeriod.endDate),
            }}
            datePaid={new Date(doc.datePaid)}
            key={doc.receiptNumber}
            onSelect={showSelectedDocumentDetails}
          />
        ))}
      </DocumentListContainer>
    );
  }

  if (proformaList.length > 0) {
    proformaListComponent = (
      <DocumentListContainer heading={t('admin:proforma_invoices')}>
        {proformaList.map((doc) => (
          <DocumentCard
            documentNumber={doc.proformaInvoiceNumber}
            documentDate={new Date(doc.invoiceDate)}
            documentType='proforma'
            paymentType={doc.paymentType as paymentType}
            serviceType={doc.serviceType}
            overallTotal={doc.overallTotal}
            currency={doc.selectedPackage.currency as Currency}
            status={doc.status as Status}
            dueDate={new Date(doc.dueDate)}
            key={doc.proformaInvoiceNumber}
            onSelect={showSelectedDocumentDetails}
          />
        ))}
      </DocumentListContainer>
    );
  }

  if (
    proformaList.length === 0 &&
    receiptList.length === 0 &&
    invoiceList.length === 0 &&
    !isLoadingInvoices &&
    !isInvoicesFetchError
  ) {
    return (
      <NoDataFound
        icon={<FaFileInvoice />}
        message={t('admin:no_purhcase_document')}
      />
    );
  }

  if (isLoadingDocument) {
    return <FetchingReports message={t('admin:preparing_document_details')} />;
  }

  if (isDocumentError) {
    return (
      <AdminPageContainer>
        <ActionContainer setup={{ justifyContent: 'space-between' }}>
          <StandardButton
            type='button'
            btnType='orange'
            label={t('translations:Close')}
            onClick={closeDocumentDetails}
          />
        </ActionContainer>
        <NoDataFound message={t('admin:document_fetch_error')} />
      </AdminPageContainer>
    );
  }

  if (instance && instance.url && documentData && contract) {
    pdfDoc = (
      <a
        href={instance.url}
        download={`Periopsis_${
          documentData.proformaInvoiceNumber
        }_${new Date().getTime()}.pdf`}
        title={t('admin:download_proforma_invoice')}
        className={classes.DownloadLink}
      >
        <FaFilePdf /> {t('admin:download_proforma_invoice')}
      </a>
    );
  }

  if (
    document &&
    docType &&
    isDocumentDetailsSuccess &&
    documentDetails &&
    contract &&
    documentData &&
    userData
  ) {
    let hasSubservices = documentData.selectedPackage.subServices.length > 0;
    const subServices = documentData.selectedPackage.subServices;
    const subservicesHeading = showSubservices
      ? t('admin:selected_subservices')
      : t('admin:subservices');

    const polygons = contract.polygons;

    const showSubServicesHandler = () => {
      setShowSubservices((prevState) => !prevState);
    };

    const showPolygonsHandler = () => {
      setShowPolygons((prevState) => !prevState);
    };

    return (
      <AdminPageContainer>
        <ActionContainer setup={{ justifyContent: 'space-between' }}>
          <StandardButton
            type='button'
            btnType='orange'
            label={t('translations:Close')}
            onClick={closeDocumentDetails}
          />
          {pdfDoc}
        </ActionContainer>

        <ProformaAndContract
          proformaInvoice={documentData}
          contract={contract}
        />
        <OrderDataCard heading={t('admin:selection_details')}>
          <DataRow
            heading={t('admin:selected_service_type')}
            data={t(`dashboard:${getTranslationKey(documentData.serviceType)}`)}
            removeSeperator
          />
          <DataRow
            heading={t('admin:selected_package')}
            data={documentData.selectedPackage.packageName}
            removeSeperator
          />
          <DataRow
            heading={t('admin:monthly_price')}
            data={`${documentData.selectedPackage.currency}${documentData.selectedPackage.monthlyPrice}${documentData.selectedPackage.priceUnit}`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:duration')}
            data={`${documentData.selectedPackage.duration} ${documentData.selectedPackage.durationUnit}`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:package_area_limit')}
            data={`${documentData.selectedPackage.areaLimit} ${documentData.selectedPackage.areaLimitUnit}`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:selected_total_area')}
            data={`${documentData.totalArea} ${documentData.areaUnit}`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:user_limit')}
            data={documentData.selectedPackage.userLimit.toString()}
            removeSeperator
          />

          {hasSubservices ? (
            <ListContainer
              heading={`${subservicesHeading} (${subServices.length} subservices selected)`}
              onExpand={showSubServicesHandler}
              isExpanded={showSubservices}
              expandMessage={t('admin:subservices_message')}
            >
              <ul
                className={`${classes.SubServiceListWrapper} ${
                  showSubservices ? classes.Show : ''
                }`}
              >
                {subServices.map((subservice) => (
                  <li key={subservice._id}>
                    <DataRow
                      heading={t('admin:subservice')}
                      data={subservice.subServiceName}
                      removeSeperator
                    />
                    <DataRow
                      heading={t('admin:price')}
                      data={`${subservice.currency}${subservice.unitPrice}/month`}
                      removeSeperator
                    />
                  </li>
                ))}
              </ul>
            </ListContainer>
          ) : null}
          <ListContainer
            heading={`${t('admin:selected_polygons')} (${polygons.length} ${t(
              'admin:polygons_selected'
            )})`}
            onExpand={showPolygonsHandler}
            isExpanded={showPolygons}
            expandMessage={t(`admin:selected_polygons_message`)}
          >
            <ul
              className={`${classes.SubServiceListWrapper} ${
                showPolygons ? classes.Show : ''
              }`}
            >
              {polygons.map((polygon) => (
                <li key={polygon.mid}>
                  <DataRow
                    heading={t('admin:polygon_id')}
                    data={polygon.mid}
                    removeSeperator
                  />

                  {polygon.polygonName ? (
                    <DataRow
                      heading={t('admin:polygon_name')}
                      data={polygon.polygonName}
                      removeSeperator
                    />
                  ) : null}
                  <DataRow
                    heading={t('admin:area')}
                    data={`${polygon.area} ${polygon.areaUnit}`}
                    removeSeperator
                  />
                  {documentData ? (
                    <DataRow
                      heading={t('admin:polygon_price')}
                      data={`${
                        documentData.selectedPackage.currency
                      }${getTotalPolygonPrice(
                        polygon.area,
                        documentData.selectedPackage.monthlyPrice
                      )}/${t('auth:month')}`}
                    />
                  ) : null}
                  <DataRow
                    heading={t('auth:district')}
                    data={t(
                      `translations:${getTranslationKey(polygon.district)}`
                    )}
                    removeSeperator
                  />
                  {polygon.province ? (
                    <DataRow
                      heading={t(`auth:province`)}
                      data={polygon.province}
                      removeSeperator
                    />
                  ) : null}
                  <DataRow
                    heading={t('auth:country')}
                    data={t(`auth:${getTranslationKey(polygon.country)}`)}
                    removeSeperator
                  />
                </li>
              ))}
            </ul>
          </ListContainer>
        </OrderDataCard>
        <OrderDataCard heading={t('admin:customer_details')}>
          <Fragment>
            <CustomerDetails
              customerEmail={documentData.customerEmail}
              customerName={documentData.customerName}
              userPhone={userData ? userData.userPhone : ''}
            />
            <AddressContent
              proformaInvoice={documentData}
              user={userData}
              onAddressUpdate={() => {}}
              isAddressUpdatable={false}
            />
          </Fragment>
        </OrderDataCard>
        <OrderDataCard heading={t('admin:pricing_details')}>
          <DataRow
            heading={t('admin:polygon_total_area_price')}
            data={`${documentData.selectedPackage.currency}${(
              documentData.totalAmount - documentData.selectedPackage.subTotal
            ).toFixed(2)}`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:subservices_price')}
            data={`${
              documentData.selectedPackage.currency
            }${documentData.selectedPackage.subTotal.toFixed(2)}`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:discount_rate')}
            data={`${documentData.discountRate.toFixed(2)}%`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:discount_amount')}
            data={`${
              documentData.selectedPackage.currency
            }${documentData.discountAmount.toFixed(2)}`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:vat_rate')}
            data={`${documentData.VATRate.toFixed(2)}%`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:vat_amount')}
            data={`${
              documentData.selectedPackage.currency
            }${documentData.VATAmount.toFixed(2)}`}
            removeSeperator
          />
          <DataRow
            heading={t('admin:overall_total')}
            data={`${
              documentData.selectedPackage.currency
            }${documentData.overallTotal.toFixed(2)} / ${t('auth:month')}`}
            removeSeperator
            className={classes.OverallTotal}
          />
          <DataRow
            heading={t('admin:service_total_amount')}
            data={`${documentData.selectedPackage.currency}${(
              (documentData.selectedPackage.duration / 30) *
              documentData.overallTotal
            ).toFixed(2)}`}
            className={`${classes.OverallTotal} ${classes.NoBorder}`}
            removeSeperator
          />
        </OrderDataCard>

        <ActionContainer setup={{ justifyContent: 'space-between' }}>
          <StandardButton
            type='button'
            btnType='orange'
            label={t('translations:Close')}
            onClick={closeDocumentDetails}
          />
          {pdfDoc}
        </ActionContainer>
      </AdminPageContainer>
    );
  }

  return (
    <ContentContainer gap='2.3rem'>
      {proformaListComponent}
      {invoiceListComponent}
      {receiptListComponent}
    </ContentContainer>
  );
};

export default InvoicesAndReceipts;
