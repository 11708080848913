import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorSign from './ErrorSign/ErrorSign';
import SuccessSign from './SuccessSign/SuccessSign';
import { frontendConstants } from '../../../constants/constants';
import Button from '../FormElements/Button/Button';
import periopsis from '../../../assets/images/HomePageImages/periopsislogodark.png';
import classes from './ConfirmResult.module.scss';

interface IProps {
  isSuccess: boolean;
  message?: string;
  timer?: number;
}

const ConfirmResult = ({ isSuccess, message, timer }: IProps) => {
  const [time, setTime] = useState(timer ? timer / 1000 : 8);
  let messageSection = null;
  const isTimerOn = useRef(false);
  const navigate = useNavigate();
  let timeOut = useRef<NodeJS.Timeout | null>(null);
  let timeOutTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isTimerOn.current && timer) {
      timeOut.current = setTimeout(() => {
        isTimerOn.current = true;
        localStorage.clear();
        navigate(frontendConstants.pages.login);
      }, timer);
    }

    return () => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }
    };
  }, [timer, navigate]);

  useEffect(() => {
    if (time !== 1 && timer) {
      timeOutTimer.current = setTimeout(() => {
        setTime((prevState) => prevState - 1);
      }, 1000);
    }

    return () => {
      if (timeOutTimer && timeOutTimer.current) {
        clearTimeout(timeOutTimer.current);
      }
    };
  }, [time, timer]);

  if (message) {
    messageSection = (
      <div className={classes.ConfirmationMessageSection}>
        <p>{message}</p>
      </div>
    );
  }

  const navigateToLoginHandler = () => {
    localStorage.clear();
    navigate(frontendConstants.pages.login);
    if (timeOut && timeOut.current) {
      clearTimeout(timeOut.current);
    }

    if (timeOutTimer && timeOutTimer.current) {
      clearTimeout(timeOutTimer.current);
    }
  };

  return (
    <div className={classes.ViewPortContainer}>
      <div className={classes.ConfirmationContainer}>
        <div className={classes.ImageContainer}>
          <img src={periopsis} alt='Periopsis' />
        </div>
        {isSuccess ? <SuccessSign /> : <ErrorSign />}
        {messageSection}

        <div className={classes.ConfirmationActions}>
          <Button type='button' onClick={navigateToLoginHandler}>
            OK
          </Button>
        </div>

        <p className={classes.Timer}>
          You will be redirected to login page within <span>{time}</span>{' '}
          seconds
        </p>
      </div>
    </div>
  );
};

export default ConfirmResult;
