import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  clearForgetPasswordData,
  useForgotAccountMutation,
  useResendConfirmationCodeMutation,
} from '../../../../features/auth/authSlice';

import useRecaptcha from '../../../../CustomHooks/useRecaptcha';
import { AppDispatch } from '../../../../app/store';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import Input from '../../../UI/FormElements/Input/Input';
import PeriopsisCaptcha from '../../../UI/PeriopsisCaptcha/PeriopsisCaptcha';
import useTimer from '../../../../CustomHooks/useTimer';
import { validateEmail } from '../../../../utils/componentUtilFns';
import { IForgotAccountQuery } from '../../../../tsTypes/types.components';
import queryErrorCatch from '../../../../utils/queryErrorCatch';
import ResendProcess from '../ResendProcess/ResendProcess';
import AuthCodeFormDescriptionSection from '../SignIn/AuthCodeFormDescriptionSection/AuthCodeFormDescriptionSection';
import forgotAccount from '../../../../assets/images/ForgotAccount.png';
import accountUpdateCode from '../../../../assets/images/OneTimeLoginKey.png';
import Form from '../../../UI/FormElements/Form/Form';
import classes from './ForgotPassword.module.scss';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../UI/FormElements/Button/Button';
import SimpleLoading from '../../../UI/SimpleLoading/SimpleLoading';
import useFormEntry from '../../../../CustomHooks/useFormEntry';
import { useTranslation } from 'react-i18next';
import LanguageSelection from '../LanguageSelection/LanguageSelection';

interface IProps {
  onFormClose: () => void;
}

const ForgotPassword = ({ onFormClose }: IProps) => {
  const [code, setCode] = useState('');
  const [isCodeForm, setIsCodeForm] = useState(false);
  const { t } = useTranslation();
  const [forgetAccountProcessing, { data: forgotPasswordData, isLoading }] =
    useForgotAccountMutation();

  const [resendConfirmationCode, { isLoading: isResending }] =
    useResendConfirmationCodeMutation();

  const {
    value: email,
    entryHandler: emailEntryHandler,
    isValid: isValidEmail,
    clearEntry: clearEmail,
  } = useFormEntry(validateEmail);

  let description = t('forgotpass:reset_password_desc');
  let heading = t('forgotpass:password_renewal');
  let btnText = t('forgotpass:next');

  const {
    isValidRecaptcha,
    recaptchaChangeHandler,
    recaptchaExpiredHandler,
    recaptchaCode,
    recaptchaRef,
    resetRecaptcha,
  } = useRecaptcha();

  const { timerFormat, startTimer, resetTimer, isTimerStarted } = useTimer({
    duration: 180,
  });

  const dispatch = useDispatch<AppDispatch>();

  const fakeButton = !isValidEmail || email.trim().length < 3;

  const confirmationCodeEntryHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setCode(value.trim());
  };

  const resendConfirmationCodeHandler = async () => {
    try {
      setCode('');

      const res = await resendConfirmationCode(email).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        startTimer();
        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('forgotpass:account_update_code_success')}
            heading={t('forgotpass:account_update_code')}
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const emailSubmitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let data: IForgotAccountQuery | null = null;

      if (!isValidEmail) {
        return;
      }

      if (
        email &&
        isValidEmail &&
        !code &&
        isValidRecaptcha &&
        recaptchaCode &&
        recaptchaCode.current
      ) {
        data = {
          email,
          recaptcha: recaptchaCode.current,
        };
      } else if (
        email &&
        isValidEmail &&
        code.trim().length > 0 &&
        isValidRecaptcha &&
        recaptchaCode &&
        recaptchaCode.current
      ) {
        data = {
          email,
          validationCode: code,
          recaptcha: recaptchaCode.current,
        };
      } else {
        const message = !isValidEmail
          ? t('forgotpass:validate_recaptcha')
          : t('forgotpass:enter_confirmation_code');
        const heading = !isValidEmail
          ? t('forgotpass:email_error')
          : t('forgotpass:code_is_required');
        toast.error(
          <ToastifyStyledMessage singleMessage={message} heading={heading} />,
          { autoClose: 2500 }
        );
        return;
      }

      const res = await forgetAccountProcessing(data).unwrap();

      if (res && 'verificationCode' in res && res.verificationCode) {
        resetRecaptcha(true);
        setIsCodeForm(true);
        startTimer();
      }
    } catch (error) {
      resetRecaptcha(true);
      setCode('');
      return queryErrorCatch(error);
    }
  };

  const formCloseHandler = () => {
    clearEmail();
    setCode('');
    resetTimer();

    dispatch(clearForgetPasswordData());

    if (onFormClose && typeof onFormClose === 'function') {
      onFormClose();
    }
  };

  if (
    forgotPasswordData &&
    forgotPasswordData.token &&
    forgotPasswordData.code &&
    forgotPasswordData.email
  ) {
    const { token, code, email } = forgotPasswordData;

    return (
      <Navigate
        to={`/user/passwordrenewal/${token}/${code}/updatepassword?email=${email}`}
      />
    );
  }

  let inputComponent = (
    <Input
      name='email'
      label={t('forgotpass:enter_email_address')}
      value={email}
      onChange={emailEntryHandler}
      isInvalid={!isValidEmail}
      disabled={isLoading}
    />
  );

  if (isCodeForm) {
    heading = t('forgotpass:account_update_code');
    description = t('forgotpass:kindly_check_your_email');
    btnText = t('forgotpass:next');
    inputComponent = (
      <Fragment>
        <Input
          name='confirmCode'
          label={t('forgotpass:enter_account_update_code')}
          value={code}
          onChange={confirmationCodeEntryHandler}
        />
        <ResendProcess
          onResend={resendConfirmationCodeHandler}
          isResending={isResending}
          isTimerStarted={isTimerStarted}
          timer={timerFormat}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <AuthCodeFormDescriptionSection
        description={description}
        heading={heading}
        image={isCodeForm ? accountUpdateCode : forgotAccount}
      />
      <Form onSubmit={emailSubmitHandler} className={classes.LoginForm}>
        {inputComponent}
        <div className={classes.CaptchaContainer}>
          <PeriopsisCaptcha
            ref={recaptchaRef}
            onChange={recaptchaChangeHandler}
            onExpired={recaptchaExpiredHandler}
            show={!fakeButton && !isLoading && !isResending}
          />
        </div>
        {!isLoading && !isResending ? (
          <ActionContainer className={classes.Actions}>
            <Button
              type='button'
              btnClass={classes.Button}
              onClick={formCloseHandler}
            >
              {t('translations:cancel')}
            </Button>

            <Button
              btnClass={classes.Button}
              type={!email || !isValidEmail ? 'button' : 'submit'}
              isFake={!email || !isValidEmail}
            >
              {btnText}
            </Button>
          </ActionContainer>
        ) : (
          <ActionContainer className={classes.Actions}>
            <SimpleLoading />
          </ActionContainer>
        )}
      </Form>
      <div className={classes.LanguageSelectionPart}>
        <LanguageSelection />
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
