import { Fragment } from 'react';
import HeadingBar from '../../UI/HeadingBar/HeadingBar';
import NotificationList from './NotificationList/NotificationList';
import NotificationWindowContainer from './NotificationWindowContainer/NotificationWindowContainer';
import classes from './Notifications.module.scss';
import NotificationDetails from './NotificationDetails/NotificationDetails';
import NoDataFound from '../../UI/NoDataFound/NoDataFound';
import { BsEnvelopeExclamationFill } from 'react-icons/bs';
import FetchingReports from '../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import useCurrentContract from '../../../CustomHooks/useCurrentContract';
import { useGetContractNotificationsQuery } from '../../../features/notifications/notificationsSlice';
import { Navigate } from 'react-router-dom';
import { frontendConstants } from '../../../constants/constants';
import { useGetContractsQuery } from '../../../features/incidents/incidentsSlice';
import { useGetPageSettingsQuery } from '../../../features/Settings/settingsSlice';
import { Settings } from '../../../tsTypes/enums';
import ErrorControlComponent from '../../UI/ErrorControlComponent/ErrorControlComponent';
import getUserContractsResult from '../../../utils/ContractUsersUtils/getUserContractsResult';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const contractId = useCurrentContract();

  const {
    isLoading,
    isError,
    isSuccess,
    data: userContractsData,
  } = useGetContractsQuery('contracts');
  const { t } = useTranslation();

  const { data: settings, isLoading: isLoadingSettings } =
    useGetPageSettingsQuery(Settings.sidedrawer, {
      skip: isLoading,
    });

  const { hasContracts } = getUserContractsResult(userContractsData);

  const {
    isLoading: isLoadingNotifications,
    isError: isNotificationsError,
    data,
    error,
  } = useGetContractNotificationsQuery(contractId, {
    skip: !contractId || isLoading || isLoadingSettings || !hasContracts,
    pollingInterval: frontendConstants.pollInterval,
  });

  let notificationsListContent: React.ReactNode | null = null;

  let hasNotifications = false;
  let hasNotificationsLink = true;

  if (isLoading || isLoadingSettings) {
    return (
      <div className={classes.NotificationProcessContainer}>
        <FetchingReports
          message={t('notifications:initializing_notification_data')}
        />
      </div>
    );
  }

  if (isLoadingNotifications || isLoading) {
    notificationsListContent = (
      <div className={classes.NotificationProcessContainer}>
        <FetchingReports message={t('notifications:fetching_notifications')} />
      </div>
    );
  }

  if (isNotificationsError || isError) {
    notificationsListContent = (
      <div className={classes.NotificationProcessContainer}>
        <ErrorControlComponent error={error}>
          <NoDataFound message={t('notifications:notifications_fetch_error')} />
        </ErrorControlComponent>
      </div>
    );
  }

  if (
    settings &&
    typeof settings === 'object' &&
    'hasNotificationsLink' in settings
  ) {
    hasNotificationsLink = settings.hasNotificationsLink;
  }

  if ((isSuccess && !hasContracts) || !hasNotificationsLink) {
    return <Navigate to='/' />;
  }

  if (
    data &&
    typeof data === 'object' &&
    'unreadNotifications' in data &&
    'readNotifications' in data &&
    data.readNotifications &&
    data.unreadNotifications
  ) {
    hasNotifications =
      data.readNotifications.length > 0 || data.unreadNotifications.length > 0;
  }

  if (
    !isLoadingNotifications &&
    !isNotificationsError &&
    data &&
    typeof data === 'object' &&
    'unreadNotifications' in data &&
    'readNotifications' in data &&
    data.readNotifications &&
    data.unreadNotifications &&
    Array.isArray(data.readNotifications) &&
    Array.isArray(data.unreadNotifications) &&
    hasContracts
  ) {
    const unreadNotifications = [...data.unreadNotifications];
    const readNotifications = [...data.readNotifications];

    notificationsListContent = (
      <Fragment>
        <NotificationList
          notifications={unreadNotifications}
          heading={
            unreadNotifications.length > 1
              ? `${unreadNotifications.length} ${t(
                  'notifications:unread_notifications'
                )}`
              : `${unreadNotifications.length} ${t(
                  'notifications:unread_notification'
                )}`
          }
        />
        <NotificationList
          notifications={readNotifications}
          heading={
            readNotifications.length > 1
              ? `${readNotifications.length} ${t(
                  'notifications:read_notifications'
                )}`
              : `${readNotifications.length} ${t(
                  'notifications:read_notification'
                )}`
          }
        />
      </Fragment>
    );
  } else if (
    !isLoadingNotifications &&
    !isNotificationsError &&
    data &&
    typeof data === 'object' &&
    'readNotifications' in data &&
    data.readNotifications &&
    Array.isArray(data.readNotifications) &&
    data.readNotifications.length === 0 &&
    'unreadNotifications' in data &&
    Array.isArray(data.unreadNotifications) &&
    data.unreadNotifications.length === 0 &&
    hasContracts
  ) {
    notificationsListContent = (
      <div className={classes.NotificationProcessContainer}>
        <NoDataFound
          message={t('notifications:no_notification')}
          icon={<BsEnvelopeExclamationFill />}
        />
      </div>
    );
  }

  return (
    <div className={classes.NotificationsContainer}>
      <HeadingBar
        heading={t('notifications:notifications')}
        headingType='primary'
      />
      <div className={classes.ContentContainer}>
        <NotificationWindowContainer className={classes.ListContainer}>
          {notificationsListContent}
        </NotificationWindowContainer>
        <NotificationWindowContainer>
          <NotificationDetails hasNotifications={hasNotifications} />
          <div></div>
        </NotificationWindowContainer>
      </div>
      <div className={classes.ContentContainerMobile}>
        <NotificationWindowContainer className={classes.ListContainer}>
          {notificationsListContent}
        </NotificationWindowContainer>
      </div>
    </div>
  );
};

export default Notifications;
