import { useEffect, useRef } from 'react';
import { MdNotificationsNone } from 'react-icons/md';
import { useSelector } from 'react-redux';
import useToken from '../../../../CustomHooks/useToken';
import { selectAllUIValues } from '../../../../features/UI/uiSlice';
import classes from './NavbarNotification.module.scss';
import { useGetContractNotificationsQuery } from '../../../../features/notifications/notificationsSlice';
import useCurrentContract from '../../../../CustomHooks/useCurrentContract';
import { IContractNotification } from '../../../../tsTypes/interfaces';
import { frontendConstants } from '../../../../constants/constants';
import { useGetContractsQuery } from '../../../../features/incidents/incidentsSlice';

interface IProps {
  onClick: () => void;
}

const NavbarNotification = ({ onClick }: IProps) => {
  const { showNavNotificationsMenu } = useSelector(selectAllUIValues);
  const contractId = useCurrentContract();
  let timer = useRef<NodeJS.Timeout | null>(null);
  let unreadNotifications: IContractNotification[] = [];
  const { token, pToken, userId } = useToken({ giveUserId: true });

  const { isLoading, data: userContracts } = useGetContractsQuery('contracts');

  const { data } = useGetContractNotificationsQuery(contractId, {
    skip: !contractId || isLoading,
    pollingInterval: frontendConstants.pollInterval,
  });

  const hasContracts =
    userContracts !== undefined &&
    Array.isArray(userContracts) &&
    userContracts.length > 0;

  useEffect(() => {
    if (!showNavNotificationsMenu && timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [showNavNotificationsMenu]);

  if (!userId || !token || !pToken) {
    return null;
  }

  if (
    data &&
    typeof data === 'object' &&
    'unreadNotifications' in data &&
    data.unreadNotifications &&
    hasContracts
  ) {
    unreadNotifications = data.unreadNotifications;
  }

  if (isLoading || !hasContracts) {
    return null;
  }

  return (
    <div
      className={classes.NavNotificationIcon}
      onClick={() => {
        onClick();
      }}
    >
      {unreadNotifications.length > 0 ? (
        <span className={classes.NotificationNumber}>
          {unreadNotifications.length}
        </span>
      ) : null}
      <MdNotificationsNone />
    </div>
  );
};

export default NavbarNotification;
