import {
  IUserAdminContract,
  IUserAdminContractPackage,
} from '../../../../../tsTypes/interfaces';
import ContractListItem from './ContractListItem/ContractListItem';
import classes from './ContractsList.module.scss';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import { Settings } from '../../../../../tsTypes/enums';
import { useSearchParams } from 'react-router-dom';
import FetchingReports from '../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import { useGetPageSettingsQuery } from '../../../../../features/Settings/settingsSlice';
import ContractDetails from './ContractDetails/ContractDetails';
import SelectedContractSummary from '../SelectedContractSummary/SelectedContractSummary';
import { useTranslation } from 'react-i18next';

interface IProps {
  contracts: IUserAdminContract[];
}

const ContractsList = ({ contracts }: IProps) => {
  let currentPackage: IUserAdminContractPackage | undefined;
  const [pageQuery, setPageQuery] = useSearchParams();
  const { t } = useTranslation();

  const page = pageQuery.get('page');
  const currentContractId = pageQuery.get('contract');
  const section = pageQuery.get('section');

  const { data: settings, isLoading: isLoadingSettings } =
    useGetPageSettingsQuery(Settings.userAdminPage, {
      skip: !contracts,
    });

  let hasCancelButton = false;
  let hasCompletePaymentButton = false;
  let hasContractDetailsButton = false;
  let hasExtendButton = false;
  let hasTerminateButton = false;
  let hasUpgradeButton = false;

  if (contracts.length === 0) {
    return <NoDataFound message={t('admin:no_contracts_authorized')} />;
  }

  if (isLoadingSettings) {
    return <FetchingReports message={t('admin:initializing_contracts')} />;
  }

  if (
    settings &&
    typeof settings === 'object' &&
    'hasCancelButton' in settings &&
    'hasCompletePaymentButton' in settings &&
    'hasContractDetailsButton' in settings &&
    'hasExtendButton' in settings
  ) {
    hasCancelButton = settings.hasCancelButton;
    hasCompletePaymentButton = settings.hasCompletePaymentButton;
    hasContractDetailsButton = settings.hasContractDetailsButton;
    hasExtendButton = settings.hasExtendButton;
    hasTerminateButton = settings.hasTerminateButton;
    hasUpgradeButton = settings.hasUpgradeButton;
  }

  let summary: React.ReactNode | null = null;
  const selectedContract = contracts.find(
    (contract) => contract.contractId === currentContractId
  );

  if (selectedContract) {
    currentPackage = selectedContract.contractPackagesData.find(
      (pack) => pack.id === selectedContract.currentPackageId
    );
  }

  const contractDetailsHandler = () => {
    if (page && currentContractId && !section && currentPackage) {
      setPageQuery({
        page,
        contract: currentContractId,
        section: '1',
        package: currentPackage.id,
      });
    }
  };

  if (selectedContract && currentPackage) {
    const contractStatus = selectedContract.contractStatus;

    summary = (
      <SelectedContractSummary
        selectedContract={selectedContract}
        currentPackage={currentPackage}
      >
        <ActionContainer className={classes.ContractActions}>
          {hasContractDetailsButton ? (
            <StandardButton
              label={t('admin:contract_details')}
              btnType='orange'
              type='button'
              className={classes.ContractActionBtn}
              onClick={contractDetailsHandler}
            />
          ) : null}
          {hasUpgradeButton ? (
            <StandardButton
              label={t('admin:upgrade')}
              btnType='orange'
              type='button'
              fakeButton={!contractStatus.hasUpgradeButton}
              className={classes.ContractActionBtn}
            />
          ) : null}

          {contractStatus.hasCancelButton && hasCancelButton ? (
            <StandardButton
              label={t('admin:cancel_contract')}
              btnType='orange'
              type='button'
              className={classes.ContractActionBtn}
            />
          ) : null}

          {!contractStatus.hasCancelButton && hasExtendButton ? (
            <StandardButton
              label={t('admin:extend')}
              btnType='orange'
              type='button'
              className={classes.ContractActionBtn}
              fakeButton={!contractStatus.hasActiveExtendButton}
            />
          ) : null}

          {contractStatus.hasCompletePaymentButton &&
          hasCompletePaymentButton ? (
            <StandardButton
              label={t('admin:complete_payment')}
              btnType='orange'
              type='button'
              className={classes.ContractActionBtn}
            />
          ) : null}

          {contractStatus.hasTerminateButton && hasTerminateButton ? (
            <StandardButton
              label={t('admin:terminate')}
              btnType='orange'
              type='button'
              className={classes.ContractActionBtn}
            />
          ) : null}
        </ActionContainer>
        {contractStatus.message !== '' ? (
          <div className={classes.WarningMessageSection}>
            <p>{contractStatus.message}</p>
          </div>
        ) : null}
      </SelectedContractSummary>
    );
  }

  if (Number(section) === 1) {
    return <ContractDetails />;
  }

  return (
    <div>
      {summary}
      <div
        className={`${classes.ContractsList} ${
          contracts.length > 1 ? classes.Grid : ''
        }`}
      >
        {contracts.map((contract) => (
          <ContractListItem contract={contract} key={contract.contractId} />
        ))}
      </div>
    </div>
  );
};

export default ContractsList;
