import classes from './IncidentLocationLink.module.scss';

interface IProps {
  latitude: number;
  longitude: number;
  label: string;
}

const IncidentLocationLink = ({ latitude, longitude, label }: IProps) => {
  if (!latitude || !longitude) {
    return null;
  }

  return (
    <div className={classes.LocationLinkSection}>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}&basemap=satellite&zoom=18&center=34.81467%2C33.476112&map_action=map`}
        target='_blank'
        rel='noreferrer'
      >
        {label}
      </a>
    </div>
  );
};

export default IncidentLocationLink;
