import { useTranslation } from 'react-i18next';
import { IAreaOfInterest } from '../../../../../../../../../tsTypes/types.components';
import getTotalPolygonPrice from '../../../../../../../../../utils/getTotalPolygonPrice';
import classes from './SelectedPolygonDataList.module.scss';

interface IProps {
  selectedPolygons: IAreaOfInterest[];
  pricePerSQM?: number;
  currency?: string;
  onClose: () => void;
}

const SelectedPolygonDataList = ({
  selectedPolygons,
  pricePerSQM,
  currency,
  onClose,
}: IProps) => {
  const { t } = useTranslation();

  if (
    !selectedPolygons ||
    (selectedPolygons && !Array.isArray(selectedPolygons)) ||
    selectedPolygons.length === 0
  ) {
    return <div>{t('auth:no_polygon_selected')}</div>;
  }

  let price = 0;
  let priceCurrency = '';
  let showPricing = false;

  if (pricePerSQM && currency) {
    price = pricePerSQM;
    priceCurrency = currency;
    showPricing = true;
  }

  return (
    <div className={classes.Wrapper}>
      <div className={classes.CloseBtnSection}>
        <button type='button' onClick={onClose}>
          {t('auth:close_list')}
        </button>
      </div>
      <table className={classes.SelectedPolygonsContainer}>
        <thead>
          <tr>
            <th>{t('auth:polygon_name')}</th>
            <th>{t('auth:division')}</th>
            <th>{t('auth:country')}</th>
            <th>{t('auth:area_with_unit')}</th>
            {showPricing ? <th>{t('auth:total_price')}</th> : null}
          </tr>
        </thead>
        <tbody>
          {selectedPolygons.map((polygon) => (
            <tr key={polygon.mid}>
              <td>{polygon.polygonName}</td>
              <td>{polygon.district}</td>
              <td>{polygon.country}</td>
              <td>{polygon.area.toFixed(2)}</td>

              {showPricing ? (
                <td>
                  {priceCurrency}
                  {getTotalPolygonPrice(polygon.area, price)}
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SelectedPolygonDataList;
