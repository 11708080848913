import { periopsisApi } from '../api/apiSlice';
import getHeaders from '../../utils/getHeaders';
import { IResponseData, IUser } from '../../tsTypes/types.model';
import {
  IContractDetails,
  IContractUser,
  IContractUsersResponse,
  IInvoiceCardResponse,
  IProformaCardResponse,
  IReceiptCardResponse,
  IUData,
  IUserAdminContract,
} from '../../tsTypes/interfaces';
import { documentType } from '../../tsTypes/types';
import {
  ICheckoutContract,
  ICheckoutProforma,
  ICheckoutUserData,
} from '../../tsTypes/checkout.interfaces';

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminUserContracts: builder.query({
      query: () => {
        const headers = getHeaders();

        return {
          url: '/user-admin/contracts',
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          contracts: IUserAdminContract[];
          initContractId: string;
          uData: IUData;
          pToken: string;
        }>
      ) => {
        const { contracts, initContractId, uData, pToken } = responseData.data;
        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return { contracts, initContractId };
      },
    }),
    getAdminUserContractDetails: builder.query({
      query: (requestData: {
        contractId: string;
        contractPackageId: string;
      }) => {
        const { contractId, contractPackageId } = requestData;
        const headers = getHeaders();

        return {
          url: `/user-admin/contract/${contractId}?cPackage=${contractPackageId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (responseData: IResponseData<IContractDetails>) => {
        const contract = responseData.data.contract;
        const contractPackage = responseData.data.contractPackage;

        return {
          contract,
          contractPackage,
        };
      },
    }),
    getAdminUserInvoicesAndReceipts: builder.query({
      query: (contractId: string) => {
        const headers = getHeaders();
        return {
          url: `/user-admin/invoices-receipts/${contractId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          invoices: IInvoiceCardResponse[];
          proformaInvoices: IProformaCardResponse[];
          receipts: IReceiptCardResponse[];
          uData: IUData;
          pToken: string;
        }>
      ) => {
        const { invoices, receipts, proformaInvoices, uData, pToken } =
          responseData.data;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return { invoices, receipts, proformaInvoices };
      },
    }),
    getDocumentDetails: builder.query({
      query: (requestData: {
        documentId: string;
        documentType: documentType;
        contractId: string;
      }) => {
        const { documentId, contractId, documentType } = requestData;

        const headers = getHeaders();

        return {
          url: `/user-admin/document/${contractId}?document=${documentId}&docType=${documentType}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          contract: ICheckoutContract;
          document: ICheckoutProforma;
          user: ICheckoutUserData | null;
        }>
      ) => {
        const { contract, document, user } = responseData.data;

        return { contract, document, user };
      },
    }),
    getUsersOfContract: builder.query({
      query: (requestData: { contractId: string; packageId: string }) => {
        const headers = getHeaders();
        const { contractId, packageId } = requestData;

        return {
          url: `/user-admin/users/${contractId}?package=${packageId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<IContractUsersResponse>
      ) => {
        return responseData.data;
      },
      providesTags: ['contract-users'],
    }),
    addNewUserToOrganizationUsers: builder.mutation({
      query: (requestData: {
        user: Pick<
          IUser,
          | 'name'
          | 'surname'
          | 'email'
          | 'password'
          | 'confirmPassword'
          | 'userPhones'
        >;
        contractId: string;
      }) => {
        const headers = getHeaders();
        const { user, contractId } = requestData;

        return {
          url: `/user-admin/users/${contractId}`,
          method: 'POST',
          headers,
          body: {
            newUser: user,
          },
        };
      },
      invalidatesTags: ['contract-users'],
    }),
    removeUserFromOrganizationUsers: builder.mutation({
      query: (requestData: { userId: string; contractId: string }) => {
        const headers = getHeaders();
        const { userId, contractId } = requestData;

        return {
          url: `/user-admin/users/${contractId}?userId=${userId}`,
          method: 'DELETE',
          headers,
        };
      },
      invalidatesTags: ['contract-users'],
    }),
    assignUsersToContract: builder.mutation({
      query: (requestData: { users: IContractUser[]; contractId: string }) => {
        const headers = getHeaders();
        const { users, contractId } = requestData;

        return {
          url: `/user-admin/users/${contractId}`,
          method: 'PATCH',
          headers,
          body: { users },
        };
      },
      invalidatesTags: ['contract-users'],
    }),
    blockUnblockContractUser: builder.mutation({
      query: (requestData: { userId: string; contractId: string }) => {
        const headers = getHeaders();
        const { userId, contractId } = requestData;

        return {
          url: `/user-admin/block-unblock/user/${contractId}`,
          method: 'PATCH',
          headers,
          body: { userId },
        };
      },
      invalidatesTags: ['contract-users'],
    }),
  }),
});

/**
 * router.get(
  '/invoices-receipts/:contractId',
 */

export const {
  useGetAdminUserContractsQuery,
  useGetAdminUserContractDetailsQuery,
  useGetAdminUserInvoicesAndReceiptsQuery,
  useGetUsersOfContractQuery,
  useGetDocumentDetailsQuery,
  useAddNewUserToOrganizationUsersMutation,
  useRemoveUserFromOrganizationUsersMutation,
  useAssignUsersToContractMutation,
  useBlockUnblockContractUserMutation,
} = extendedApiSlice;
