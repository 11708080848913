import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../style.constants';

export const reportStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '297mm',
    backgroundColor: '#fff',
    padding: '19mm 14.32mm',
  },
  containerMain: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '297mm',
    backgroundColor: colorConstants.periopsisMainColor,
    padding: '19mm 14.32mm',
  },
  logo: {
    width: '40mm',
  },

  reportHeading: {
    fontSize: '12pt',
    textTransform: 'uppercase',
    color: colorConstants.periopsisMainColor,
    paddingBottom: '0.5mm',
    marginBottom: '1.5mm',
    borderBottom: `0.3mm solid ${colorConstants.periopsisMainColor}`,
    marginTop: '4mm',
  },
});
