import { useTranslation } from 'react-i18next';
import {
  FieldInformationWrapper,
  FormInformationContainer,
} from '../../FormInformationContainer/FormInformationContainer';
import ReportIcons from '../../ReportIcons/ReportIcons';

interface IProps {
  onClose: () => void;
}

const VisitFormInformation = ({ onClose }: IProps) => {
  const { t } = useTranslation();

  return (
    <FormInformationContainer onClose={onClose}>
      <p>{t('visitFormInfo:info_desc_001')}</p>
      <p>{t('visitFormInfo:info_desc_002')}</p>
      <ReportIcons heading={t('visitFormInfo:icons_heading')} />
      <FieldInformationWrapper>
        <h4>{t('visitFormInfo:Observed_Incident_Type')}</h4>
        <p>{t('visitFormInfo:Observed_Incident_Type_desc')}</p>
      </FieldInformationWrapper>
      <FieldInformationWrapper>
        <h4>{t('visitFormInfo:Notes_About_Visit_and_Observation')}</h4>
        <p>{t('visitFormInfo:Notes_About_Visit_desc')}</p>
      </FieldInformationWrapper>
      <FieldInformationWrapper>
        <h4>{t('visitFormInfo:Add_Visitors')}</h4>
        <p>{t('visitFormInfo:Add_Visitors_Desc_01')}</p>
        <p>{t('visitFormInfo:Add_Visitors_Desc_02')}</p>
      </FieldInformationWrapper>
    </FormInformationContainer>
  );
};

export default VisitFormInformation;
