import { FormEvent } from 'react';
import useAddress from '../../../../../../CustomHooks/useAddress';
import { catchError } from '../../../../../../utils/fetches';
import Address from '../../../../../UI/Address/Address';
import ActionContainer from '../../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../../UI/FormElements/Button/Button';
import Form from '../../../../../UI/FormElements/Form/Form';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import useQuery from '../../../../../../CustomHooks/useQuery';
import { useParams } from 'react-router-dom';
import CenterDiv from '../../../../../UI/CenterDiv/CenterDiv';
import SimpleLoading from '../../../../../UI/SimpleLoading/SimpleLoading';
import { nanoid } from '@reduxjs/toolkit';
import { getHeaderByCode } from '../../../../../../utils/componentUtilFns';
import classes from './BillingAddressForm.module.scss';
import { IAddress } from '../../../../../../tsTypes/types.model';
import checkoutUnAuthorized from '../../../../../../utils/checkoutUnauthorized';
import { useAddNewAddressMutation } from '../../../../../../features/checkout/checkoutSlice';
import { useTranslation } from 'react-i18next';

interface IProps {
  shouldSkip: boolean;
  onShowForm: () => void;
  addressIdUpdater: (addressId: string) => void;
  onUserAddressesUpdate: (newAddress: IAddress) => void;
}

const BillingAddressForm = ({
  shouldSkip,
  onShowForm,
  addressIdUpdater,
  onUserAddressesUpdate,
}: IProps) => {
  const { checkoutToken, registrationCode } = useParams();
  const query = useQuery();
  const proformaInvoiceNumber = query.get('proformaInvoice');

  const [addNewAddress, { isLoading }] = useAddNewAddressMutation();
  const { t } = useTranslation();

  const { variableData, addressFunctions } = useAddress({
    userAddressLocalStorageName: 'new-billing-address',
    moreAddressDetailsLocalStorageName: 'more-billing-address-details',
    shouldSkip: shouldSkip,
  });

  const {
    countries,
    isFetchingCountries,
    isCountryFetchError,
    countryFetchError,
    userAddress,
    addressStructure,
    moreAddressDetails,
    isValidAddress,
    isFetchingAddressStructure,
    isAddressStructureFetchError,
    addressStructureError,
    activateButton,
    isValidAddressType,
  } = variableData;

  const {
    addressCountrySelectHandler,
    userAddressEntryHandler,
    moreAddressDetailsHandler,
    clearAddress,
    addressTypeSelectHandler,
  } = addressFunctions;

  const showFormHandler = () => {
    clearAddress();

    const timer = setTimeout(() => {
      onShowForm();
      clearTimeout(timer);
    }, 400);
  };

  const formSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();

    if (
      !activateButton.isValidDivision ||
      !activateButton.isValidPostalCode ||
      !activateButton.isValidSettlement ||
      !activateButton.isValidStreetAddress ||
      !activateButton.isValidSubdivision ||
      !isValidAddressType
    ) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('checkout:review_address_message')}
        />
      );
    }

    if (!registrationCode || !checkoutToken || !proformaInvoiceNumber) {
      return checkoutUnAuthorized();
    }

    try {
      const addressId = nanoid(36);
      const newAddress = { ...userAddress, addressId };

      const response = await addNewAddress({
        newAddress,
        registrationCode,
        checkoutToken,
        proformaInvoiceNumber,
      }).unwrap();

      if (
        response &&
        typeof response === 'object' &&
        'proformaInvoice' in response &&
        response.proformaInvoice &&
        typeof response.proformaInvoice === 'object'
      ) {
        addressIdUpdater(addressId);
        onUserAddressesUpdate(newAddress);
        clearAddress();
        const timer = setTimeout(() => {
          onShowForm();
          clearTimeout(timer);
        }, 1600);
        toast.success(
          <ToastifyStyledMessage
            singleMessage={t('checkout:billing_address_added')}
            heading={t('checkout:update_success')}
          />,
          { autoClose: 1600 }
        );
      }
    } catch (error) {
      const err = catchError(error);

      toast.error(
        <ToastifyStyledMessage
          singleMessage={`${err.message}`}
          heading={getHeaderByCode(err.status)}
        />
      );
    }
  };

  let actions = (
    <ActionContainer className={classes.NewAddressActions}>
      <Button
        onClick={showFormHandler}
        type='button'
        title={t('checkout:click_to_close')}
        btnClass={classes.ActionButton}
      >
        {t('checkout:close_form')}
      </Button>
      <Button
        title={t('checkout:click_to_submit')}
        type='submit'
        isFake={
          !activateButton.isValidDivision ||
          !activateButton.isValidPostalCode ||
          !activateButton.isValidSettlement ||
          !activateButton.isValidStreetAddress ||
          !activateButton.isValidSubdivision
        }
        btnClass={classes.ActionButton}
      >
        {t('checkout:add_address')}
      </Button>
    </ActionContainer>
  );

  if (isLoading) {
    actions = (
      <CenterDiv>
        <SimpleLoading />
      </CenterDiv>
    );
  }

  return (
    <Form className={classes.NewAddressWrapper} onSubmit={formSubmitHandler}>
      <div>
        <PresentationWrapper
          isLoading={isFetchingAddressStructure}
          isError={isAddressStructureFetchError}
          error={catchError(addressStructureError)}
          message={t('checkout:address_structure_fetch_error')}
        >
          <Address
            isRequired
            selectedCountry={userAddress.countryDetails}
            onAddressCountrySelect={addressCountrySelectHandler}
            addressStructure={addressStructure}
            onAddressEntry={userAddressEntryHandler}
            address={userAddress}
            onMoreAddressDetails={moreAddressDetailsHandler}
            onAddressTypeSelect={addressTypeSelectHandler}
            activateMoreAddressDetails={moreAddressDetails}
            validations={isValidAddress}
            disabled={false}
            countries={countries}
            isLoading={isFetchingCountries}
            isError={isCountryFetchError}
            error={countryFetchError}
          />
        </PresentationWrapper>
        {actions}
      </div>
    </Form>
  );
};

export default BillingAddressForm;
