import { useRef, useState, useCallback, useEffect } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../components/UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { item } from '../tsTypes/types.components';
import {
  capitalizeAllWords,
  isSameName,
  NameSurnameValidator,
  validateText,
  validateEmail,
} from '../utils/componentUtilFns';
import isDifferentList from '../utils/isDifferentList';

interface IProps {
  maxListContent?: number;
  isFullNameValidation?: boolean;
  isEmailValidation?: boolean;
  allLowerCaseWords?: boolean;
  checkOnTab?: boolean;
  checkAndAddToListOnBlur?: boolean;
  existingList?: item[] | null;
}

const useAddToList = ({
  maxListContent,
  isFullNameValidation,
  isEmailValidation,
  allLowerCaseWords,
  checkOnTab,
  checkAndAddToListOnBlur,
  existingList,
}: IProps) => {
  /**
   * @TODO : convert this to useReducer
   */
  const [list, setList] = useState<item[]>([]);
  const [listItemName, setListItemName] = useState('');
  const [invalidItemName, setInvalidItemName] = useState(false);
  const [disable, setDisable] = useState(false);
  const [pressedKey, setPressedKey] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [confirmationInProgress, setConfirmationInProgress] = useState(false);
  const itemNameInputRef = useRef<any>(null);
  let checkAndAddToListOnBlurHandler: () => void = () => {};

  const hasExistingList = existingList !== null && Array.isArray(existingList);

  const isDifferent = existingList
    ? isDifferentList({ currentList: list, newList: existingList })
    : true;

  useEffect(() => {
    if (hasExistingList && isDifferent && !isUpdating) {
      setList(existingList);
    }

    // eslint-disable-next-line
  }, [isDifferent]);

  useEffect(() => {
    if (listItemName === '') {
      const timer = setTimeout(() => {
        setIsUpdating(false);
      }, 600);

      return () => clearTimeout(timer);
    }
  }, [listItemName]);

  const validateAndAddTo = (list: item[], name: string) => {
    const isValidName = isFullNameValidation
      ? NameSurnameValidator(name, 4, 50)
      : isEmailValidation
      ? validateEmail(name)
      : validateText(name, 3, 50);
    const maxList = maxListContent ? maxListContent : 10;
    const message = isFullNameValidation
      ? {
          message:
            'Please enter Name, Surname and Name or Surname must not contain special characters. Minimum length should be 4 and maximum should be at most 50 characters',
          heading: 'Name Error',
        }
      : isEmailValidation
      ? {
          message: 'Please enter a valid email address',
          heading: 'Email Error',
        }
      : {
          message:
            'Entered text must not contain special characters. Minimum length of item name should be 3 and maximum should be at most 50 characters.',
          heading: 'Text Error',
        };

    // if (!isValidName && invalidItemName) {
    //   return;
    // }

    if (!isValidName) {
      setInvalidItemName(true);
      toast.error(
        <ToastifyStyledMessage
          singleMessage={message.message}
          heading={message.heading}
        />
      );
      return;
    } else {
      if (list.length < maxList) {
        const hasSameName = isFullNameValidation
          ? list.filter((v) => isSameName(v.name, name, 0.81))
          : list.filter((v) => v.name.toLowerCase() === name.toLowerCase());

        const message = isFullNameValidation
          ? {
              message:
                'Please enter Name, Surname and Name or Surname must not contain special characters. Minimum length should be 4 and maximum should be at most 50 characters',
              heading: 'Name Error',
            }
          : isEmailValidation
          ? {
              message: 'Please enter a valid email address',
              heading: 'Email Error',
            }
          : {
              message:
                'Entered text must not contain special characters. Minimum length of item name should be 3 and maximum should be at most 50 characters.',
              heading: 'Text Error',
            };

        //Refactor this code, we write same setters structure in two place
        if (hasSameName.length > 0) {
          setConfirmationInProgress(true);
          return confirmAlert({
            title: message.heading,
            message: message.message,
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  const visitor = {
                    _id: nanoid(),
                    name: allLowerCaseWords
                      ? name.toLowerCase()
                      : capitalizeAllWords(name),
                  };
                  setIsUpdating(true);
                  setList((prevState) => [...prevState, visitor]);
                  setListItemName('');
                  setConfirmationInProgress(false);
                },
              },
              {
                label: 'No',
                onClick: () => {
                  setListItemName('');
                  setConfirmationInProgress(false);
                },
              },
            ],
          });
        } else {
          const visitor = {
            _id: nanoid(),
            name: allLowerCaseWords
              ? name.toLowerCase()
              : capitalizeAllWords(name),
          };

          setIsUpdating(true);

          setList((prevState) => [...prevState, visitor]);
          setListItemName('');
        }
      }
    }
  };

  if (checkAndAddToListOnBlur) {
    checkAndAddToListOnBlurHandler = () => {
      if (
        pressedKey !== 'Tab' &&
        listItemName.trim().length > 0 &&
        !confirmationInProgress
      ) {
        validateAndAddTo(list, listItemName);
      }
    };
  }

  const addNameToList = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;
    const keyCode = e.code;
    setPressedKey(keyCode);
    const control = checkOnTab
      ? (key === 'Enter' || keyCode === 'Enter' || keyCode === 'Tab') &&
        !invalidItemName
      : (key === 'Enter' || keyCode === 'Enter') && !invalidItemName;

    if (control) {
      const value = listItemName;

      if (value.trim().length > 0) {
        validateAndAddTo(list, value);
      }
    }
  };

  const itemNameHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxList = maxListContent ? maxListContent : 10;
    const visited = e.target.value;
    setInvalidItemName(false);

    if (visited.includes(',') && list.length < maxList) {
      const atVisitors = visited.split(',')[0];

      validateAndAddTo(list, atVisitors);
    } else {
      if (list.length < maxList) {
        setListItemName(visited);
        setPressedKey('');
      } else {
        setDisable(true);
      }
    }
  };

  const removeFromListHandler = (listMemberId: string | number) => {
    setDisable(false);
    setIsUpdating(true);
    setList((prevState) => prevState.filter((v) => v._id !== listMemberId));
  };

  const cleanUpState = useCallback(() => {
    setList([]);
    setListItemName('');
    setInvalidItemName(false);
    setDisable(false);
    itemNameInputRef.current = null;
  }, []);

  if (checkAndAddToListOnBlur) {
    return {
      list,
      listItemName,
      invalidItemName,
      disable,
      itemNameInputRef,
      addNameToList,
      itemNameHandler,
      removeFromListHandler,
      cleanUpState,
      checkAndAddToListOnBlurHandler,
    };
  }

  return {
    list,
    listItemName,
    invalidItemName,
    disable,
    itemNameInputRef,
    addNameToList,
    itemNameHandler,
    removeFromListHandler,
    cleanUpState,
  };
};

export default useAddToList;
