import classes from './CircleImage.module.scss';

interface IProps {
  src: string;
  alt: string;
  className?: string;
}

const CircleImage = ({ alt, src, className }: IProps) => {
  return (
    <div className={`${classes.ImageContainer} ${className ? className : ''}`}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default CircleImage;
