import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants, styleConstants } from '../style.constants';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '1.3mm 0',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '3.4mm',
    width: styleConstants.rightFullWidth,
  },
  heading: {
    color: colorConstants.periopsisMainColor,
    fontWeight: 'extrabold',
  },
  label: {
    color: colorConstants.lightGray,
  },
});
