import { useEffect, useState } from 'react';
import HeadingBar from '../../../UI/HeadingBar/HeadingBar';
import classes from './SendPageHeader.module.scss';
import { GrClose } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';

const SendPageHeader = () => {
  const [closeMessage, setCloseMessage] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const messageStatus = localStorage.getItem('remove-message');

    if (messageStatus) {
      const status = JSON.parse(messageStatus);
      setCloseMessage(status.isMessageRemoved);
    }
  }, []);

  const closeMessageHandler = () => {
    localStorage.setItem(
      'remove-message',
      JSON.stringify({ isMessageRemoved: true })
    );
    setCloseMessage(true);
  };

  return (
    <header className={classes.Header}>
      <HeadingBar
        heading={t('reporting:reporting_and_assignments')}
        headingType='primary'
        className={classes.Heading}
      />
      {!closeMessage ? (
        <div className={classes.ClosableNote}>
          <p>{t('reporting:older_reports_message')}</p>
          <button onClick={closeMessageHandler}>
            <GrClose />
          </button>
        </div>
      ) : null}
    </header>
  );
};

export default SendPageHeader;
