import classes from './ToastifyStyledMessage.module.scss';

interface IProps {
  messageList?: readonly string[];
  singleMessage?: string;
  heading?: string;
}

const ToastifyStyledMessage = ({
  messageList,
  singleMessage,
  heading,
}: IProps) => {
  return (
    <div className={classes.ToastStyledMessageContainer}>
      {heading ? <h4>{heading}</h4> : null}
      {singleMessage ? <p>{singleMessage}</p> : null}
      {messageList && messageList.length > 0 ? (
        <ul>
          {messageList.map((msg) => (
            <li key={`${msg}`}>{msg}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default ToastifyStyledMessage;
