export const thunkCatchBlock = (
  error: any,
  rejectWithValue: (value: unknown) => unknown
) => {
  let heading = '';
  let specialCode = 0;

  if (
    typeof error === 'object' &&
    'response' in error &&
    error.response &&
    'data' in error.response &&
    error.response.data &&
    typeof error.response.data === 'object' &&
    'specialCode' in error.response.data &&
    error.response.data.specialCode &&
    typeof error.response.data.specialCode === 'object'
  ) {
    heading = error.response.data.specialCode.heading;
    specialCode = error.response.data.specialCode.code;
  }

  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();

  return rejectWithValue({
    code: error.response.status,
    message,
    heading,
    specialCode,
  });
};

export const catchError = (error: any) => {
  if (
    error &&
    typeof error === 'object' &&
    'status' in error &&
    error.status &&
    typeof error.status === 'number' &&
    'data' in error &&
    error.data &&
    typeof error.data === 'object' &&
    'isSuccess' in error.data &&
    typeof error.data.isSuccess === 'boolean' &&
    'message' in error.data &&
    error.data.message &&
    typeof error.data.message === 'string'
  ) {
    return {
      message: error.data.message as string,
      status: error.status as number,
    };
  }

  return {
    message: '',
    status: 500,
  };
};
