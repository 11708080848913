import classes from './SimpleLoading.module.scss';
//'lds-ellipsis'

interface IProps {
  size?: number;
}

const SimpleLoading = ({ size }: IProps) => {
  let sizeToUse = 1;

  if (size && size < 0.5) {
    sizeToUse = 0.5;
  } else if (size && size > 1.5) {
    sizeToUse = 1.5;
  } else if (size) {
    sizeToUse = size;
  }

  return (
    <div>
      <div
        className={classes.SimpleLoading}
        style={{ transform: `Scale(${sizeToUse || 1})` }}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default SimpleLoading;
