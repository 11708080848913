import { ITimelineItem } from '../../../../../tsTypes/interfaces';
import classes from './TimelineItem.module.scss';
import Image from '../../../../UI/Image/Image';

interface IProps {
  timelineItem: ITimelineItem;
  onClick: (girdObjectId: string) => void;
  isCurrentIncident: boolean;
}

const TimelineItem = ({ timelineItem, onClick, isCurrentIncident }: IProps) => {
  if (
    !timelineItem ||
    typeof timelineItem !== 'object' ||
    !('imageId' in timelineItem) ||
    !('gridObjectId' in timelineItem)
  ) {
    return null;
  }

  return (
    <button
      className={`${classes.TimelineItemContainer} ${
        timelineItem.isDetectionDateImage && isCurrentIncident
          ? classes.TimelineTargetBorader
          : ''
      }`}
      onClick={() => onClick(timelineItem.gridObjectId)}
    >
      <Image
        imagePath={`${timelineItem.path}`}
        alt={`${timelineItem.type}`}
        title={`${timelineItem.type} | ${new Date(
          timelineItem.imageDate
        ).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        })}`}
        className={`${isCurrentIncident ? classes.CurrentIncidentImage : ''}`}
        loading='lazy'
      />
      <p
        className={`${classes.DateContainer} ${
          timelineItem.isDetectionDateImage ? classes.DetectedDateImage : ''
        }`}
      >
        {new Date(timelineItem.imageDate).toLocaleDateString('en-GB', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        })}
      </p>
    </button>
  );
};

export default TimelineItem;
