import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../UI/style.constants';

export const leftStyles = StyleSheet.create({
  left: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colorConstants.periopsisMainColor,
    width: '60mm',
    minHeight: '297mm',
    padding: '4mm 3mm',
  },

  packageName: {
    maxWidth: '50mm',
    paddingBottom: '1mm',
    color: '#fff',
    fontSize: '4.5mm',
    borderBottom: '1px solid yellowgreen',
    fontWeight: 'heavy',
  },

  packagePrice: {
    display: 'flex',
    width: '100%',
    color: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4mm 0',
  },
  Price: {
    fontSize: '12mm',
  },
  Currency: {
    fontSize: '3mm',
  },

  Duration: {
    padding: '4mm 0',
    fontSize: '3mm',
  },

  DateText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '6.5mm',
    marginBottom: '5mm',
  },

  Heading: {
    marginTop: '61mm',
    fontWeight: 'extrabold',
    fontSize: '14mm',
    transform: 'rotate(-90deg)',
    width: '132mm',
  },

  Branding: {
    bottom: '10mm',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '54mm',
    marginTop: '122mm',
  },
  logo: {
    width: '35mm',
    marginBottom: '5mm',
  },
  qr: {
    width: '45mm',
  },
});
