import classes from './DashboardHeading.module.scss';

interface IProps {
  heading: string;
  children?: React.ReactNode | JSX.Element | JSX.Element[];
}

const DashboardHeading = ({ heading, children }: IProps) => {
  return (
    <div className={classes.HeadingSection}>
      <h1>{heading}</h1>
      {children}
    </div>
  );
};

export default DashboardHeading;
