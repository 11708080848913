import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../../../../../pdfComponents/UI/style.constants';

export const styles = StyleSheet.create({
  text: {
    padding: '2mm 0',
    color: colorConstants.periopsisMainColor,
    fontSize: '10pt',
  },
});
