import { useTranslation } from 'react-i18next';
import Flag from '../../../UI/flag/Flag';
import classes from './LanguageSelection.module.scss';

const LanguageSelection = () => {
  const { i18n } = useTranslation();
  const flagSize = 1.25;
  const language = i18n.language;

  const languageSelectionHandler = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className={classes.LanguageSelectionContainer}>
      <button
        onClick={() => languageSelectionHandler('en')}
        className={language === 'en' ? classes.Selected : ''}
      >
        <Flag alpha2Code='en' size={flagSize} />
      </button>
      <button
        onClick={() => languageSelectionHandler('el')}
        className={language === 'el' ? classes.Selected : ''}
      >
        {' '}
        <Flag alpha2Code='el' size={flagSize} />
      </button>
    </div>
  );
};

export default LanguageSelection;
