import { ChangeEvent, Fragment } from 'react';
import CheckboxItem from './CheckboxItem/CheckboxItem';
import { useGetMethodologiesQuery } from '../../../../../../features/report/reportsSlice';
import FetchingReports from '../../IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../../UI/NoDataFound/NoDataFound';

interface IProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selected: string[];
}

const CleanupMethodology = ({ onChange, selected }: IProps) => {
  const { isLoading, isError, data } = useGetMethodologiesQuery('');
  let content: React.ReactNode | null = null;

  if (isLoading) {
    content = <FetchingReports message='Fetching Methodologies...' />;
  }

  if (isError) {
    content = (
      <NoDataFound message='An error occurred while retrieving the methodologies. Please try again later.' />
    );
  }

  if (
    data &&
    !isLoading &&
    !isError &&
    Array.isArray(data) &&
    data.length > 0
  ) {
    content = (
      <Fragment>
        {data.map((methodology) => (
          <CheckboxItem
            label={methodology.name}
            key={methodology.id}
            onChange={onChange}
            isSelected={selected.includes(methodology.name)}
          >
            {methodology.description.length > 0 ? (
              <Fragment>
                {methodology.description.map((desc) => (
                  <p key={desc.id}>{desc.text}</p>
                ))}
              </Fragment>
            ) : null}
          </CheckboxItem>
        ))}
      </Fragment>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default CleanupMethodology;
