import { item } from '../tsTypes/types.components';

const isDifferentList = (lists: { currentList: item[]; newList: item[] }) => {
  const { currentList, newList } = lists;

  if (currentList.length !== newList.length) {
    return true;
  }

  const currentIdMap: { [key: string]: string } = {};

  currentList.forEach((elem) => {
    currentIdMap[elem._id] = elem._id;
  });

  let isDifferent = false;

  newList.forEach((elem) => {
    if (!(elem._id in currentIdMap)) {
      isDifferent = true;
    }
  });

  return isDifferent;
};

export default isDifferentList;
