import { ChangeEvent, FormEvent, useEffect, useState, Fragment } from 'react';
import FormModal from '../../../../UI/FormModal/FormModal';
import {
  IContractIncident,
  IUserAnswer,
  IVisitor,
} from '../../../../../tsTypes/interfaces';
import IncidentDetailsSection from '../IncidentDetailsSection/IncidentDetailsSection';
import Form from '../../../../UI/FormElements/Form/Form';
import Input from '../../../../UI/FormElements/Input/Input';
import {
  formatDateForInputFormState,
  getDateFromInputEnteredDate,
  validateNameSurname,
  validateText,
} from '../../../../../utils/componentUtilFns';
import useFormEntry from '../../../../../CustomHooks/useFormEntry';
import AddToList from '../AddToList/AddToList';
import { item } from '../../../../../tsTypes/types.components';
import { confirmAlert } from 'react-confirm-alert';
import isSimilarEntry from '../../../../../utils/checkEntrySimilarity';
import TextArea from '../../../../UI/FormElements/TextArea/TextArea';
import CleanupMethodology from './CleanupMethodologySection/CleanupMethodology';
import classes from './IncidentCleanedForm.module.scss';
import QuestionItem from './QuestionItem/QuestionItem';
import { useGetCleanReportQuestionsQuery } from '../../../../../features/report/reportsSlice';
import FetchingReports from '../IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import AnswerItem from './QuestionItem/AnswerItem/AnswerItem';
import BorderedSectionContainer from '../BorderedSectionContainer/BorderedSectionContainer';
import AddVisitorsForm from '../AddVisitorsForm/AddVisitorsForm';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { toast } from 'react-toastify';
import { nanoid } from '@reduxjs/toolkit';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import ObservedIncidentType from '../ObservedIncidentType/ObservedIncidentType';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import { useMarkIncidentCleanedMutation } from '../../../../../features/incidents/incidentsSlice';
import { useGetPageSettingsQuery } from '../../../../../features/Settings/settingsSlice';
import { Settings } from '../../../../../tsTypes/enums';
import IncidentCleanFormInformation from './IncidentCleanFormInformation/IncidentCleanFormInformation';
import isLoremIpsum from '../../../../../utils/isLoremIpsumText';
import { periopsisApi } from '../../../../../features/api/apiSlice';
import RadioButtonList from '../../../../UI/RadioButtonList/RadioButtonList';
import CenterDiv from '../../../../UI/CenterDiv/CenterDiv';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../app/store';
import { useTranslation } from 'react-i18next';

interface IProps {
  showCleanedForm: boolean;
  onCloseForm: () => void;
  selectedIncident: IContractIncident;
}

type durationOption = 'hours' | 'days';

const validateDate = (date: string) => {
  const dateResult = getDateFromInputEnteredDate(date);

  if (isNaN(Number(dateResult.inTime))) {
    return false;
  }

  if (dateResult.isFutureDate) {
    return false;
  }

  return true;
};

const validateDepartment = (department: string) => {
  const isValid = validateText(department, 3, 120) && isNaN(Number(department));

  return isValid;
};

const validateEquipment = (equipment: string) => {
  const isValid = validateText(equipment, 0, 100) && isNaN(Number(equipment));

  return isValid;
};

const validateNumberOfWorkers = (workers: string) => {
  if (isNaN(Number(workers))) {
    return false;
  }

  if (Number(workers) > 1000) {
    return false;
  }

  return true;
};

const validateCleanupHours = (hours: string) => {
  if (isNaN(Number(hours))) {
    return false;
  }

  if (Number(hours) > 24) {
    return false;
  }

  return true;
};

const validateCleanupDays = (days: string) => {
  if (isNaN(Number(days))) {
    return false;
  }

  if (Number(days) > 365) {
    return false;
  }

  return true;
};

const validateEstimatedCost = (cost: string) => {
  if (isNaN(Number(cost))) {
    return false;
  }

  if (Number(cost) > 999999999) {
    return false;
  }

  return true;
};

const validateExactLocation = (location: string) => {
  const isValid = validateText(location, 0, 120);

  if (location.length > 0 && isNaN(Number(location))) {
    return true;
  }

  return isValid;
};

const validateUserEntry = (text: string) => validateText(text, 0, 1000);
const validateEnteredText = (text: string) => validateText(text, 3, 100);

const IncidentCleanedForm = ({
  showCleanedForm,
  onCloseForm,
  selectedIncident,
}: IProps) => {
  const [showFormInfo, setShowFormInfo] = useState(false);
  const [involvedDepartments, setInvolvedDepartments] = useState<item[]>([]);
  const [utilizedEquipments, setUtilizedEquipments] = useState<item[]>([]);
  const [selectedMethodologies, setSelectedMethodologies] = useState<string[]>(
    []
  );

  const [userAnswers, setUserAnswers] = useState<IUserAnswer[]>([]);
  const [durationOption, setDurationOption] = useState<durationOption>('hours');
  const [employees, setEmployees] = useState<IVisitor[]>([]);
  const [markAsVisited, setMarkAsVisited] = useState<'Yes' | 'No'>('No');
  const [markCleaned, { isLoading: isMarkingAsCleaned }] =
    useMarkIncidentCleanedMutation();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const {
    data: settings,
    isError: isSettingsError,
    isLoading: isLoadingSettings,
  } = useGetPageSettingsQuery(Settings.IncidentDashboardCleanedForm, {
    skip: !showCleanedForm,
  });

  let hasCleanupDurationField = false;
  let hasAddEmployeesField = false;
  let hasAddInvolvedDepartmentsField = false;
  let hasAddUtilizedEquipmentsField = false;
  let hasLessonsLearnedField = false;
  let hasCollaborationDetailsField = false;
  let hasAdditionalNotesField = false;
  let hasCleanupMethodologyField = false;
  let hasEnvironmentalImpactAssessmentField = false;

  const {
    isLoading: isLoadingQuestions,
    isError: isQuestionsFetchError,
    data: questions,
  } = useGetCleanReportQuestionsQuery('', {
    skip:
      !settings ||
      (settings &&
        typeof settings === 'object' &&
        'hasEnvironmentalImpactAssessmentField' in settings &&
        !settings.hasEnvironmentalImpactAssessmentField),
  });
  let environmentalAssessmentContent: React.ReactNode | null = null;

  const {
    value: cleanDate,
    entryHandler: cleanDateEntryHandler,
    isValid: isValidDate,
    clearEntry: clearCleanDateEntry,
    setter: setCleanDate,
  } = useFormEntry(validateDate, 'clean-date', '');

  const {
    value: involvedDepartment,
    entryHandler: involvedDepartmentEntry,
    isValid: isValidDepartment,
    clearEntry: clearDepartmentEntry,
  } = useFormEntry(validateDepartment, 'clean-department', '', true);

  const {
    value: involvedDepartmentWorkers,
    entryHandler: involvedDepartmentWorkerNumberEntry,
    clearEntry: clearInvolvedDepartmentWorkers,
  } = useFormEntry(validateNumberOfWorkers, 'department-workers', '');

  const {
    value: utilizedEquipmentNumber,
    entryHandler: utilizedEquipmentNumberEntry,
    clearEntry: clearUtilizedEquipmentNumber,
  } = useFormEntry(validateNumberOfWorkers, 'department-workers', '');

  const {
    value: utilizedEquipment,
    entryHandler: utilizedEqupimentEntry,
    isValid: isValidEquipment,
    clearEntry: clearEquipmentEntry,
  } = useFormEntry(validateEquipment, 'clean-equipment', '', true);

  const {
    value: numberOfWorkers,
    // entryHandler: numberOfWorkersEntry,
    isValid: isValidNumberOfWorkers,
    clearEntry: clearNumberOfWorkers,
  } = useFormEntry(validateNumberOfWorkers, 'workers', '');

  const {
    value: costEstimate,
    // entryHandler: costEstimateEntry,
    isValid: isValidCostEstimate,
    clearEntry: clearCostEstimate,
  } = useFormEntry(validateEstimatedCost, 'estimated-cost', '');

  const {
    value: exactLocation,
    // entryHandler: exactLocationEntry,
    isValid: isValidExactLocation,
    clearEntry: clearExactLocation,
  } = useFormEntry(validateExactLocation, 'exact-location', '', true);

  const {
    value: cleanUpHours,
    entryHandler: hoursEntryHandler,
    isValid: isValidHours,
    clearEntry: clearHours,
    setter: setHours,
  } = useFormEntry(validateCleanupHours, 'duration-hours');

  const {
    value: cleanUpDays,
    entryHandler: daysEntryHandler,
    isValid: isValidDays,
    clearEntry: clearDays,
    setter: setDays,
  } = useFormEntry(validateCleanupDays, 'duration-days');

  const {
    value: name,
    entryHandler: nameEntryHandler,
    isValid: isValidName,
    clearEntry: clearName,
  } = useFormEntry(validateNameSurname, 'employee-name', '', true);

  const {
    value: surname,
    entryHandler: surnameEntryHandler,
    isValid: isValidSurname,
    clearEntry: clearSurname,
  } = useFormEntry(validateNameSurname, 'employee-surname', '', true);

  const {
    value: department,
    entryHandler: departmentEntryHandler,
    isValid: isValidEmployeeDepartment,
    clearEntry: clearDepartment,
  } = useFormEntry(validateDepartment, 'employee-department', '', true);

  const {
    value: duty,
    entryHandler: dutyEntryHandler,
    isValid: isValidDuty,
    clearEntry: clearDuty,
  } = useFormEntry(validateDepartment, 'employee-duty', '', true);

  const {
    value: lessonsLearned,
    entryHandler: lessonsEntry,
    isValid: isValidLessonsText,
    clearEntry: clearLessonsEntry,
  } = useFormEntry(validateUserEntry, 'lessons-learned', '');

  const {
    value: collaborationDetails,
    entryHandler: collaborationEntry,
    isValid: isValidCollaborationText,
    clearEntry: clearCollaborationEntry,
  } = useFormEntry(validateUserEntry, 'collaboration-details', '');

  const {
    value: additionalNotes,
    entryHandler: additionalNotesEntry,
    isValid: isValidNotesText,
    clearEntry: clearAdditionalNotesEntry,
  } = useFormEntry(validateUserEntry, 'collaboration-details', '');

  const {
    value: observedType,
    isValid: isValidObservedType,
    entryHandler: observedTypeEntryHandler,
    clearEntry: clearObservedType,
    setter: addObserveredType,
  } = useFormEntry(validateEnteredText, 'clean-observed-type', '', true);

  useEffect(() => {
    if (!cleanDate) {
      setCleanDate(formatDateForInputFormState(new Date()));
    }
    // eslint-disable-next-line
  }, [cleanDate]);

  useEffect(() => {
    if (durationOption === 'hours' && Number(cleanUpHours) > 24) {
      let days = Number(cleanUpHours) / 8;

      if (days % 1 >= 0.5) {
        days = Math.ceil(days);
      } else {
        days = Math.floor(days);
      }

      setDurationOption('days');
      setDays(days.toString());
      setHours('');
    }
    // eslint-disable-next-line
  }, [durationOption, cleanUpHours]);

  useEffect(() => {
    if (
      numberOfWorkers &&
      employees &&
      Number(numberOfWorkers) < employees.length
    ) {
      const updatedEmployees = employees.slice(0, Number(numberOfWorkers));
      setEmployees(updatedEmployees);
    }

    // eslint-disable-next-line
  }, [numberOfWorkers]);

  const showCleanFormInformationHandler = () => {
    setShowFormInfo((prevState) => !prevState);
  };

  const addInvolvedDepartmentHandler = (department: item) => {
    //check if it is an existing department
    let isSameDepartment = false;

    if (involvedDepartments.length === 50) {
      return toast.error(
        <ToastifyStyledMessage
          heading={t('incidentCleanedForm:Limit_Reached')}
          singleMessage={t(
            'incidentCleanedForm:Limit_Reached_Message_Department'
          )}
        />
      );
    }

    if (isSimilarEntry(department.name, involvedDepartments, 80)) {
      isSameDepartment = true;
    }

    if (isSameDepartment) {
      return confirmAlert({
        title: t('incidentCleanedForm:Similar_Department'),
        message: t('incidentCleanedForm:Similar_Department_Message'),
        buttons: [
          {
            label: t('translations:Yes'),
            onClick: () => {
              setInvolvedDepartments((prevState) => [department, ...prevState]);
              clearDepartmentEntry();
            },
          },
          {
            label: t('translations:No'),
            onClick: () => clearDepartmentEntry(),
          },
        ],
      });
    }

    setInvolvedDepartments((prevState) => [department, ...prevState]);
    clearDepartmentEntry();
    clearInvolvedDepartmentWorkers();
  };

  const addUtilizedEquipmentHandler = (equipment: item) => {
    let isSameEquipment = false;

    if (utilizedEquipments.length === 100) {
      return toast.error(
        <ToastifyStyledMessage
          heading={t('incidentCleanedForm:Limit_Reached')}
          singleMessage={t(
            'incidentCleanedForm:Limit_Reached_Message_Equipment'
          )}
        />
      );
    }

    if (isSimilarEntry(equipment.name, utilizedEquipments, 80)) {
      isSameEquipment = true;
    }

    if (isSameEquipment) {
      return confirmAlert({
        title: t('incidentCleanedForm:Similar_Equipment'),
        message: t('incidentCleanedForm:Similar_Equipment_Message'),
        buttons: [
          {
            label: t('translations:Yes'),
            onClick: () => {
              setUtilizedEquipments((prevState) => [equipment, ...prevState]);
              clearEquipmentEntry();
            },
          },
          {
            label: t('translations:No'),
            onClick: () => clearEquipmentEntry(),
          },
        ],
      });
    }

    setUtilizedEquipments((prevState) => [equipment, ...prevState]);
    clearEquipmentEntry();
    clearUtilizedEquipmentNumber();
  };

  const removeInvolvedDepartmentFromList = (id: string) => {
    const updatedDepartmentsList = involvedDepartments.filter(
      (dept) => dept._id !== id
    );
    setInvolvedDepartments(updatedDepartmentsList);
  };

  const removeUtilizedEquipmentFromList = (id: string) => {
    const updatedList = utilizedEquipments.filter((equip) => equip._id !== id);

    setUtilizedEquipments(updatedList);
  };

  const selectCleanupMethodologyHandler = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const selected = e.target.value;

    if (selectedMethodologies.includes(selected)) {
      const updated = selectedMethodologies.filter(
        (methodology) => methodology.toLowerCase() !== selected.toLowerCase()
      );
      setSelectedMethodologies(updated);
    } else {
      setSelectedMethodologies((prevState) => [...prevState, selected]);
    }
  };

  const assessmentAnswersHandler = (answer: IUserAnswer) => {
    const isExistingAnswer = userAnswers.find((ans) => ans.id === answer.id);

    if (isExistingAnswer) {
      //This means user changed the answer of the question

      setUserAnswers((prevState) => {
        const otherAnswers = prevState.filter((ans) => ans.id !== answer.id);
        return [...otherAnswers, answer];
      });
    } else {
      setUserAnswers((prevState) => [...prevState, answer]);
    }
  };
  const resetEmployeeForm = () => {
    clearName();
    clearSurname();
    clearDepartment();
    clearDuty();
  };

  const clearFormHandler = () => {
    setInvolvedDepartments([]);
    setUtilizedEquipments([]);
    setUserAnswers([]);
    clearCleanDateEntry();
    clearNumberOfWorkers();
    clearDepartmentEntry();
    clearEquipmentEntry();
    clearCostEstimate();
    clearExactLocation();
    clearHours();
    clearDays();
    clearLessonsEntry();
    clearCollaborationEntry();
    clearAdditionalNotesEntry();
    setDurationOption('hours');
    resetEmployeeForm();
    clearObservedType();
    clearInvolvedDepartmentWorkers();
    clearUtilizedEquipmentNumber();
    setEmployees([]);
    setMarkAsVisited('No');
  };

  const closeFormHandler = () => {
    if (isMarkingAsCleaned) {
      return;
    }

    if (
      numberOfWorkers ||
      exactLocation ||
      involvedDepartments.length > 0 ||
      employees.length > 0 ||
      lessonsLearned ||
      additionalNotes ||
      collaborationDetails
    ) {
      return confirmAlert({
        title: t('incidentCleanedForm:Confirm_Form_Closure'),
        message: t('incidentCleanedForm:Confirm_Form_Closure_Message'),
        buttons: [
          {
            label: t('translations:Yes'),
            onClick: () => {
              clearFormHandler();
              onCloseForm();
            },
          },
          { label: t('translations:No') },
        ],
      });
    }

    clearFormHandler();
    onCloseForm();
  };

  const addEmployeesHandler = () => {
    if (!name && !surname && !department && !duty) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:visitors_details_error')}
          heading={t('forms:visitors_error_heading')}
        />
      );
    }

    if (!name) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:name_error_message')}
          heading={t('forms:name_error_heading')}
        />
      );
    }

    if (!surname) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:surname_error_message')}
          heading={t('forms:surname_error_heading')}
        />
      );
    }

    if (!department) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:department_error_message')}
          heading={t('forms:department_error_heading')}
        />
      );
    }

    if (!duty) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:duty_error_message')}
          heading={t('forms:duty_error_heading')}
        />
      );
    }

    if (numberOfWorkers && employees.length === Number(numberOfWorkers)) {
      return toast.error(
        <ToastifyStyledMessage
          heading={t('incidentCleanedForm:Limit_Reached')}
          singleMessage={`${t(
            'incidentCleanedForm:Number_Of_Workers_Message_Part_1'
          )} ${numberOfWorkers} ${t(
            'incidentCleanedForm:Number_Of_Workers_Message_Part_2'
          )} ${numberOfWorkers} ${t(
            'incidentCleanedForm:Number_Of_Workers_Message_Part_3'
          )}`}
        />
      );
    }

    if (employees.length > 1000) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t(
            'incidentCleanedForm:Limit_Reached_Message_Employees'
          )}
          heading={t('incidentCleanedForm:Limit_Reached')}
        />
      );
    }

    if (name && surname && department && duty) {
      const existingEmployee = employees.find(
        (employee) =>
          employee.name.toLowerCase() === name.toLowerCase() &&
          employee.surname.toLowerCase() === surname.toLowerCase()
      );

      if (existingEmployee) {
        return confirmAlert({
          title: t('incidentCleanedForm:Existing_Employee'),
          message: `${t('incidentCleanedForm:Exsiting_Employee_Message_1')} ${
            existingEmployee.name
          } ${existingEmployee.surname}. ${t(
            'incidentCleanedForm:Exsiting_Employee_Message_2'
          )}`,
          buttons: [
            {
              label: t('translations:Yes'),
              onClick: () => {
                const newEmployee = {
                  name,
                  surname,
                  department,
                  duty,
                  id: nanoid(),
                };
                const storedData = [newEmployee, ...employees];
                localStorage.setItem(
                  'employees-list',
                  JSON.stringify(storedData)
                );
                setEmployees((prevState) => [newEmployee, ...prevState]);
                resetEmployeeForm();
              },
            },
            {
              label: t('translations:No'),
              onClick: () => resetEmployeeForm(),
            },
          ],
        });
      }

      const newEmployee = { name, surname, department, duty, id: nanoid() };
      const storedData = [newEmployee, ...employees];
      localStorage.setItem('employees-list', JSON.stringify(storedData));
      setEmployees((prevState) => [newEmployee, ...prevState]);
      resetEmployeeForm();
    }
  };

  const removeEmployeesHandler = (id: string) => {
    const newList = employees.filter((employee) => employee.id !== id);

    setEmployees(newList);
  };

  const durationOptionSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const selected = e.target.id.toLowerCase() as durationOption;
    setHours('');
    setDays('');
    setDurationOption(selected);
  };

  const submitCleanFormHandler = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const enteredDateInMilliseconds =
        getDateFromInputEnteredDate(cleanDate).inTime;
      const incidentDetectionDate = getDateFromInputEnteredDate(
        selectedIncident.detectionDate
      ).inTime;

      const isLessonsLearnedLorem = isLoremIpsum(lessonsLearned);
      const isCollaborationLorem = isLoremIpsum(collaborationDetails);
      const isAdditionalNotesLorem = isLoremIpsum(additionalNotes);

      if (isLessonsLearnedLorem) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Lorem_Detected')}
            singleMessage={t('incidentCleanedForm:Lorem_Detected_Message')}
          />
        );
      }

      if (isCollaborationLorem) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Lorem_Detected')}
            singleMessage={t(
              'incidentCleanedForm:Lorem_Detected_Message_Collaboration'
            )}
          />
        );
      }

      if (isAdditionalNotesLorem) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Lorem_Detected')}
            singleMessage={t(
              'incidentCleanedForm:Lorem_Detected_Message_Additional_Notes'
            )}
          />
        );
      }

      if (!isValidDate || enteredDateInMilliseconds < incidentDetectionDate) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Invalid_Date')}
            singleMessage={t('incidentCleanedForm:Invalid_Date_Message')}
          />
        );
      }

      if (involvedDepartments.length > 50) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Limit_Exceeded')}
            singleMessage={t('incidentCleanedForm:Limit_Exceeded_Department')}
          />
        );
      }

      if (utilizedEquipments.length > 100) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Limit_Exceeded')}
            singleMessage={t('incidentCleanedForm:Limite_Exceeded_Equipment')}
          />
        );
      }

      // if (durationOption !== 'days' && durationOption !== 'hours') {
      //   return toast.error(
      //     <ToastifyStyledMessage
      //       heading='Duration Option Type Error'
      //       singleMessage='Please choose either "DAYS" or "HOURS" as the duration unit, and then enter the total duration of the cleanup process.'
      //     />
      //   );
      // }

      // if (durationOption === 'hours' && !cleanUpHours) {
      //   return toast.error(
      //     <ToastifyStyledMessage
      //       singleMessage='Please input the duration of the cleanup process. You selected hours but did not specify a duration value.'
      //       heading='Duration Error'
      //     />
      //   );
      // }

      // if (durationOption === 'days' && !cleanUpDays) {
      //   return toast.error(
      //     <ToastifyStyledMessage
      //       heading='Duration Error'
      //       singleMessage='Please input the duration of the cleanup process. You selected days but not specify a duration value.'
      //     />
      //   );
      // }

      // if (durationOption === 'days' && Number(cleanUpDays) > 365) {
      //   return toast.error(
      //     <ToastifyStyledMessage
      //       heading='Days Error'
      //       singleMessage='The duration entered for the cleanup process seems quite lengthy in days. Please consider entering a more reasonable duration. Keep in mind that the cleanup process duration cannot exceed 365 days.'
      //     />
      //   );
      // }

      if (Number(numberOfWorkers) > 1000) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Limit_Exceeded')}
            singleMessage={t('incidentCleanedForm:Limite_Exceeded_Workers')}
          />
        );
      }

      if (!isValidNumberOfWorkers) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Invalid_Entry')}
            singleMessage={t('incidentCleanedForm:Invalid_Entry_Workers')}
          />
        );
      }

      if (!isValidCostEstimate) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Cost_Error')}
            singleMessage={t('incidentCleanedForm:Cost_Error_Message')}
          />
        );
      }

      if (!isValidExactLocation) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Location_Error')}
            singleMessage={t('incidentCleanedForm:Location_Error_Message')}
          />
        );
      }

      if (!isValidLessonsText) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Invalid_Text')}
            singleMessage={t(
              'incidentCleanedForm:Invalid_Text_Message_Lessons'
            )}
          />
        );
      }

      if (!isValidCollaborationText) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Invalid_Text')}
            singleMessage={t(
              'incidentCleanedForm:Invalid_Text_Message_Collaboration'
            )}
          />
        );
      }

      if (!isValidNotesText) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Invalid_Text')}
            singleMessage={t(
              'incidentCleanedForm:Invalid_Text_Message_Additional_Notes'
            )}
          />
        );
      }

      if (!isValidObservedType) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Invalid_Type')}
            singleMessage={t('incidentCleanedForm:Invalid_Type_Message')}
          />
        );
      }

      const res = await markCleaned({
        incidentId: selectedIncident._id,
        contractId: selectedIncident.contractId,
        gridObjectId: selectedIncident.gridObjectId,
        cleanDate: getDateFromInputEnteredDate(cleanDate).dateValue,
        numberOfWorkers: Number(numberOfWorkers),
        costEstimate: Number(costEstimate),
        exactLocation,
        durationOption,
        cleanUpDays: Number(cleanUpDays),
        cleanUpHours: Number(cleanUpHours),
        employees,
        involvedDepartments,
        utilizedEquipments,
        lessonsLearned,
        collaborationDetails,
        additionalNotes,
        selectedMethodologies,
        environmentalImpactAssessment: userAnswers,
        observedType,
        incidentType: selectedIncident.type,
        detectedAt: selectedIncident.detectionDate,
        coordinates: selectedIncident.coordinates,
        markAsVisited,
      }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        clearFormHandler();
        dispatch(periopsisApi.util.invalidateTags(['action-statistics']));

        const timer = setTimeout(() => {
          clearTimeout(timer);
          onCloseForm();
        }, 4500);

        return toast.success(
          <ToastifyStyledMessage
            heading={t('incidentCleanedForm:Report_Created')}
            singleMessage={t('incidentCleanedForm:Report_Created_Message')}
          />,
          { autoClose: 5200 }
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }

    //Validate Date
  };

  if (isLoadingQuestions) {
    environmentalAssessmentContent = (
      <FetchingReports message={t('incidentCleanedForm:Fetching_Questions')} />
    );
  }

  if (isQuestionsFetchError) {
    environmentalAssessmentContent = (
      <NoDataFound message={t('incidentCleanedForm:Questions_Fetch_Error')} />
    );
  }

  if (
    settings &&
    typeof settings === 'object' &&
    'hasCleanupDurationField' in settings &&
    'hasAddEmployeesField' in settings &&
    'hasAddInvolvedDepartmentsField' in settings &&
    'hasAddUtilizedEquipmentsField' in settings &&
    'hasLessonsLearnedField' in settings &&
    'hasCollaborationDetailsField' in settings &&
    'hasAdditionalNotesField' in settings &&
    'hasCleanupMethodologyField' in settings &&
    'hasEnvironmentalImpactAssessmentField' in settings
  ) {
    hasCleanupDurationField = settings.hasCleanupDurationField;
    hasAddEmployeesField = settings.hasAddEmployeesField;
    hasAddInvolvedDepartmentsField = settings.hasAddInvolvedDepartmentsField;
    hasAddUtilizedEquipmentsField = settings.hasAddUtilizedEquipmentsField;
    hasLessonsLearnedField = settings.hasLessonsLearnedField;
    hasCollaborationDetailsField = settings.hasCollaborationDetailsField;
    hasAdditionalNotesField = settings.hasAdditionalNotesField;
    hasCleanupMethodologyField = settings.hasCleanupMethodologyField;
    hasEnvironmentalImpactAssessmentField =
      settings.hasEnvironmentalImpactAssessmentField;
  }

  if (
    !isLoadingQuestions &&
    questions &&
    Array.isArray(questions) &&
    questions.length > 0
  ) {
    environmentalAssessmentContent = questions.map((question, index) => {
      let selectedAnswer = '';
      const questionAnswer = userAnswers.find((ans) => ans.id === question.id);

      if (questionAnswer) {
        selectedAnswer = questionAnswer.answerId;
      }

      return (
        <QuestionItem
          key={question.id}
          question={question}
          index={index}
          onQuestionAnswer={assessmentAnswersHandler}
          selectedAnswerId={selectedAnswer}
          hasAuthToActions
        />
      );
    });
  } else {
    environmentalAssessmentContent = (
      <NoDataFound message={t('incidentCleanedForm:No_Questions')} />
    );
  }

  const isFakeButton =
    name.length === 0 ||
    surname.length === 0 ||
    department.length === 0 ||
    duty.length === 0 ||
    !isValidName ||
    !isValidSurname ||
    !isValidEmployeeDepartment ||
    !isValidDuty;

  // const isFakeSubmitButton =
  //   cleanDate.length === 0 ||
  //   numberOfWorkers.length === 0 ||
  //   exactLocation.length === 0 ||
  //   !isValidDate ||
  //   !isValidNumberOfWorkers ||
  //   !isValidExactLocation;

  const isFakeSubmitButton = cleanDate.length === 0 || !isValidDate;

  let showMarkAsVisitedSection = false;

  if (
    selectedIncident &&
    typeof selectedIncident === 'object' &&
    'incidentActions' in selectedIncident &&
    selectedIncident.incidentActions &&
    !selectedIncident.incidentActions.visit
  ) {
    showMarkAsVisitedSection = true;
  }

  const markVisitedSelectHandler = (value: string) => {
    if (value === 'Yes' || value === 'No') {
      setMarkAsVisited(value);
    }
  };

  let formContent = (
    <Fragment>
      <IncidentDetailsSection
        selectedIncident={selectedIncident}
        information={t('incidentCleanedForm:Incident_Details_Desc')}
      />
      <Form className={classes.CleanForm} onSubmit={submitCleanFormHandler}>
        <BorderedSectionContainer
          heading={t('incidentCleanedForm:General_Info')}
        >
          <Input
            type='date'
            label={t('incidentCleanedForm:Date_of_Clean')}
            name='date-of-clean'
            value={cleanDate}
            isInvalid={!isValidDate}
            onChange={cleanDateEntryHandler}
          />
          {/* <Input
            type='number'
            label='Number of Workers Deployed'
            name='number-workers'
            value={numberOfWorkers}
            isInvalid={!isValidNumberOfWorkers}
            onChange={numberOfWorkersEntry}
            onWheel={(e) => e.currentTarget.blur()}
          /> */}
          {/* <Input
            type='number'
            label='Estimated Cost of Operation (€)'
            name='cost-operation'
            value={costEstimate}
            isInvalid={!isValidCostEstimate}
            onChange={costEstimateEntry}
            onWheel={(e) => e.currentTarget.blur()}
          />
          <Input
            type='text'
            label='Exact Location of Incident'
            name='exact-location'
            value={exactLocation}
            isInvalid={!isValidExactLocation}
            onChange={exactLocationEntry}
          /> */}
          <ObservedIncidentType
            value={observedType}
            onChange={observedTypeEntryHandler}
            onSelect={addObserveredType}
            isValid={isValidObservedType}
          />
        </BorderedSectionContainer>
        {hasCleanupDurationField ? (
          <BorderedSectionContainer
            heading={t('incidentCleanedForm:Cleanup_Duration')}
            information={t('incidentCleanedForm:Cleanup_Duration_Message')}
          >
            <div className={classes.Options}>
              <AnswerItem
                questionId='clean-duration'
                answer={t('incidentCleanedForm:HOURS')}
                onSelect={durationOptionSelectHandler}
                isSelected={durationOption === 'hours'}
                id='HOURS'
              />
              <AnswerItem
                questionId='clean-duration'
                answer={t('incidentCleanedForm:DAYS')}
                onSelect={durationOptionSelectHandler}
                isSelected={durationOption === 'days'}
                id='DAYS'
              />
            </div>
            <div>
              {durationOption === 'hours' ? (
                <Input
                  label={t('incidentCleanedForm:Hours_Label')}
                  name='hours'
                  type='number'
                  value={cleanUpHours}
                  onChange={hoursEntryHandler}
                  isInvalid={!isValidHours}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              ) : (
                <Input
                  label={t('incidentCleanedForm:Days_Label')}
                  name='days'
                  type='number'
                  value={cleanUpDays}
                  onChange={daysEntryHandler}
                  isInvalid={!isValidDays}
                  onWheel={(e) => e.currentTarget.blur()}
                />
              )}
            </div>
          </BorderedSectionContainer>
        ) : null}
        {hasAddEmployeesField ? (
          <AddVisitorsForm
            value={{ name, surname, department, duty }}
            validators={{
              isValidName,
              isValidSurname,
              isValidDepartment: isValidEmployeeDepartment,
              isValidDuty,
              isFakeAddButton: isFakeButton,
            }}
            handler={{
              nameEntryHandler,
              surnameEntryHandler,
              departmentEntryHandler,
              dutyEntryHandler,
              removeVisitorHandler: removeEmployeesHandler,
              addVisitorHandler: addEmployeesHandler,
              resetVisitorForm: resetEmployeeForm,
            }}
            visitors={employees}
            heading={t('incidentCleanedForm:Add_Employee')}
            listHeading={t('incidentCleanedForm:Added_Employees')}
            information={t('incidentCleanedForm:Add_Employees_Desc')}
          />
        ) : null}

        {hasAddInvolvedDepartmentsField ? (
          <AddToList
            name='add-department'
            value={involvedDepartment}
            numberValue={involvedDepartmentWorkers}
            numberName='number-of-workers'
            label={t('incidentCleanedForm:Involved_Department')}
            numberLabel={t('incidentCleanedForm:Involved_Department_Label')}
            valueList={involvedDepartments}
            onAdd={addInvolvedDepartmentHandler}
            onRemove={removeInvolvedDepartmentFromList}
            onEntry={involvedDepartmentEntry}
            onNumberEntry={involvedDepartmentWorkerNumberEntry}
            isInvalid={!isValidDepartment}
            entryHeading={t('incidentCleanedForm:Add_Involved_Department')}
            listHeading={t('incidentCleanedForm:Added_Involved_Departments')}
            listItemSuffix='employees'
          />
        ) : null}
        {hasAddUtilizedEquipmentsField ? (
          <AddToList
            name='add-equipment'
            value={utilizedEquipment}
            valueList={utilizedEquipments}
            numberLabel={t('incidentCleanedForm:Add_Equipments_Label')}
            numberName='number-of-equipment'
            numberValue={utilizedEquipmentNumber}
            onNumberEntry={utilizedEquipmentNumberEntry}
            label={t('incidentCleanedForm:Equipment_Utilized')}
            onAdd={addUtilizedEquipmentHandler}
            onRemove={removeUtilizedEquipmentFromList}
            onEntry={utilizedEqupimentEntry}
            isInvalid={!isValidEquipment}
            entryHeading={t('incidentCleanedForm:Add_Utilized_Equipment')}
            listHeading={t('incidentCleanedForm:Added_Utilized_Equipments')}
          />
        ) : null}
        {hasLessonsLearnedField ? (
          <BorderedSectionContainer
            heading={t('incidentCleanedForm:Lessons_Learned')}
            information={t('incidentCleanedForm:Lessons_Learned_Desc')}
          >
            <TextArea
              name='lessons-learned'
              value={lessonsLearned}
              label={t('incidentCleanedForm:Lessons_Learned')}
              maxLength={1000}
              title={t('incidentCleanedForm:Lessons_Learned_Title')}
              errorMessage={t('incidentCleanedForm:Text_Error_Message')}
              invalid={!isValidLessonsText}
              onChange={lessonsEntry}
              showRemaining
            />
          </BorderedSectionContainer>
        ) : null}
        {hasCollaborationDetailsField ? (
          <BorderedSectionContainer
            heading={t('incidentCleanedForm:Collaboration_Details')}
            information={t('incidentCleanedForm:Collaboration_Details_Desc')}
          >
            <TextArea
              name='collaboration-details'
              value={collaborationDetails}
              label={t('incidentCleanedForm:Collaboration_Details')}
              maxLength={1000}
              title={t('incidentCleanedForm:Collaboration_Details_Title')}
              errorMessage={t('incidentCleanedForm:Text_Error_Message')}
              invalid={!isValidCollaborationText}
              onChange={collaborationEntry}
              showRemaining
            />
          </BorderedSectionContainer>
        ) : null}
        {hasAdditionalNotesField ? (
          <BorderedSectionContainer
            heading={t(
              'incidentCleanedForm:Additional_Notes_Cleanup_Operation'
            )}
            information={t('incidentCleanedForm:Additional_Notes_Cleanup_Desc')}
          >
            <TextArea
              name='additional-notes'
              value={additionalNotes}
              label={t('incidentCleanedForm:Additional_Notes_Heading')}
              maxLength={1000}
              title={t('incidentCleanedForm:Additional_Notes_Title')}
              errorMessage={t('incidentCleanedForm:Text_Error_Message')}
              invalid={!isValidNotesText}
              onChange={additionalNotesEntry}
              showRemaining
            />
          </BorderedSectionContainer>
        ) : null}

        {hasCleanupMethodologyField ? (
          <BorderedSectionContainer
            heading={t('incidentCleanedForm:Cleanup_Methodology')}
            information={t('incidentCleanedForm:Cleanup_Methodology_Desc')}
          >
            <CleanupMethodology
              onChange={selectCleanupMethodologyHandler}
              selected={selectedMethodologies}
            />
          </BorderedSectionContainer>
        ) : null}

        {hasEnvironmentalImpactAssessmentField ? (
          <BorderedSectionContainer heading='Environmental Impact Assessment'>
            {environmentalAssessmentContent}
          </BorderedSectionContainer>
        ) : null}
        {showMarkAsVisitedSection ? (
          <BorderedSectionContainer
            heading={t('incidentCleanedForm:Mark_As_Visited')}
            information={t('incidentCleanedForm:Mark_As_Visited_Desc')}
          >
            <CenterDiv>
              <RadioButtonList
                radioButtonListSetup={{
                  name: 'mark-as-visited',
                  listElements: [
                    {
                      id: 'yes-00001',
                      value: 'Yes',
                      label: t('translations:Yes'),
                      checked: markAsVisited === 'Yes',
                    },
                    {
                      id: 'no-00002',
                      value: 'No',
                      label: t('translations:No'),
                      checked: markAsVisited === 'No',
                    },
                  ],
                  onChange: markVisitedSelectHandler,
                  direction: 'row',
                }}
              />
            </CenterDiv>
          </BorderedSectionContainer>
        ) : null}
        <ActionContainer>
          <StandardButton
            label={t('translations:Submit')}
            btnType='orange'
            type='submit'
            className={classes.SubmitButton}
            fakeButton={isFakeSubmitButton}
          />
        </ActionContainer>
      </Form>
    </Fragment>
  );

  if (isMarkingAsCleaned) {
    formContent = (
      <FetchingReports message={t('incidentCleanedForm:Processing_Report')} />
    );
  }

  if (isSettingsError) {
    formContent = (
      <NoDataFound
        message={t('incidentCleanedForm:Page_Settings_Retrieve_Error')}
      />
    );
  }

  if (isLoadingSettings) {
    formContent = (
      <FetchingReports
        message={t('incidentCleanedForm:Loading_Page_Setting')}
      />
    );
  }

  if (showFormInfo) {
    formContent = (
      <IncidentCleanFormInformation
        onClose={showCleanFormInformationHandler}
        settings={settings}
      />
    );
  }

  return (
    <FormModal
      heading={t('incidentCleanedForm:Incident_Cleaned_Form')}
      show={showCleanedForm}
      onClick={closeFormHandler}
      onShowInformation={showCleanFormInformationHandler}
    >
      {formContent}
    </FormModal>
  );
};

export default IncidentCleanedForm;
