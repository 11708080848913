import { useTranslation } from 'react-i18next';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import classes from './SendPageTabs.module.scss';

interface IProps {
  onSelect: (selected: 'send' | 'archive') => void;
  selected: 'send' | 'archive';
  hasArchivedReports: boolean;
}

const SendPageTabs = ({ onSelect, selected, hasArchivedReports }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.TabsContainer}>
      <StandardButton
        label={t('reporting:active_reports')}
        btnType={selected === 'send' ? 'primary' : 'primary-light'}
        type='button'
        isTabButton
        style={{
          fontWeight: 'bolder',
          padding: '0.6rem 1.2rem',
          width: '160px',
        }}
        onClick={() => onSelect('send')}
        className={classes.Tab}
      />
      {hasArchivedReports ? (
        <StandardButton
          label={t('reporting:archived_reports')}
          btnType={selected === 'archive' ? 'primary' : 'primary-light'}
          type='button'
          isTabButton
          style={{
            fontWeight: 'bolder',
            padding: '0.6rem 1.2rem',
            width: '160px',
          }}
          onClick={() => onSelect('archive')}
          className={classes.Tab}
        />
      ) : null}
    </div>
  );
};

export default SendPageTabs;
