import { useEffect, useState } from 'react';

const useStatisticsYear = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const storedYear = localStorage.getItem('statistics-current-year');

    if (storedYear) {
      setCurrentYear(Number(storedYear));
    }
  }, []);

  const selectCurrentYear = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    localStorage.setItem('statistics-current-year', e.target.value);
    setCurrentYear(Number(e.target.value));
  };

  return { currentYear, selectCurrentYear };
};

export default useStatisticsYear;
