import { View, Text, Image } from '@react-pdf/renderer';
import { leftStyles } from './LeftContent.style';
import { Currency } from '../../../tsTypes/types.model';
import convertCurrencySignToWord from '../../../utils/convertCurrencySignToWord';

interface IProps {
  name: string;
  currency?: Currency;
  monthlyPrice?: number;
  duration?: number;
  durationUnit?: string;
  heading?: string;
  date?: string;
  priceUnit?: string;
}

const LeftContent = ({
  name,
  currency,
  monthlyPrice,
  duration,
  durationUnit,
  heading,
  date,
  priceUnit,
}: IProps) => {
  const periopsislogo = '/images/pdf-assets/periopsisLogov1.png';
  const periopsisQR = '/images/pdf-assets/PeriopsisQR.png';

  return (
    <View style={leftStyles.left}>
      <View style={leftStyles.packageName}>
        <Text>{name}</Text>
      </View>
      <View style={leftStyles.packagePrice}>
        {date ? (
          <Text
            style={{
              ...leftStyles.DateText,
              fontSize: currency && monthlyPrice ? '3.2mm' : '6.5mm',
            }}
          >
            {date}
          </Text>
        ) : null}
        {currency && monthlyPrice ? (
          <Text style={leftStyles.Price}>
            {currency}
            {monthlyPrice}
          </Text>
        ) : null}
        {currency && monthlyPrice ? (
          <Text style={leftStyles.Currency}>
            {convertCurrencySignToWord(
              currency,
              monthlyPrice === 1
            ).toLowerCase()}
            {priceUnit}
          </Text>
        ) : null}
        {duration && durationUnit ? (
          <Text style={leftStyles.Duration}>
            Duration : {duration} {durationUnit}
          </Text>
        ) : null}
        <Text style={leftStyles.Heading}>{heading}</Text>
      </View>
      <View style={leftStyles.Branding}>
        <Image src={periopsislogo} style={leftStyles.logo} />
        <Image src={periopsisQR} style={leftStyles.qr} />
      </View>
    </View>
  );
};

export default LeftContent;
