import { IContractIncident } from '../tsTypes/interfaces';
import {
  compareTwoDate,
  getDateFromInputEnteredDate,
} from './componentUtilFns';

const getTimeFilteredIncidents = (data: {
  incidents: IContractIncident[];
  fromDate: string;
  toDate: string;
}) => {
  const { fromDate, toDate, incidents } = data;

  // compareTwoDate,

  const fromDateValues = getDateFromInputEnteredDate(fromDate);
  const toDateValues = getDateFromInputEnteredDate(toDate);

  const comparisonResult = compareTwoDate(
    fromDateValues.parts,
    toDateValues.parts
  );

  if (
    comparisonResult.isValid &&
    !isNaN(toDateValues.inTime) &&
    !isNaN(fromDateValues.inTime)
  ) {
    return incidents.filter(
      (incident) =>
        new Date(incident.detectionDate).getTime() >= fromDateValues.inTime &&
        new Date(incident.detectionDate).getTime() <= toDateValues.inTime
    );
  }

  if (!comparisonResult.isValid) {
    return [];
  }

  return incidents;
};

export default getTimeFilteredIncidents;
