import { useEffect, useState } from 'react';
import EmailChips from '../../../../../UI/EmailChips/EmailChips';
import { item } from '../../../../../../tsTypes/types.components';
import classes from './ReportByMail.module.scss';
import Input from '../../../../../UI/FormElements/Input/Input';

import {
  IContractIncident,
  IExistingReport,
} from '../../../../../../tsTypes/interfaces';
import TextArea from '../../../../../UI/FormElements/TextArea/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllAuthValues } from '../../../../../../features/auth/authSlice';

import useFormEntry from '../../../../../../CustomHooks/useFormEntry';
import {
  formatDateForInputFormState,
  validateNameSurname,
  validateText,
} from '../../../../../../utils/componentUtilFns';
import { reportType } from '../../../../../../tsTypes/types';
import getIncidentLocationLink from '../../../../../../utils/getIncidentLocationLink';
import {
  useGetReportsOfIncidentQuery,
  useSendReportOfIncidentMutation,
} from '../../../../../../features/incidents/incidentsSlice';
import FetchingReports from '../FetchingReports/FetchingReports';
import NoDataFound from '../../../../../UI/NoDataFound/NoDataFound';
import queryErrorCatch from '../../../../../../utils/queryErrorCatch';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import useSessionLogout from '../../../../../../CustomHooks/useSessionLogout';
import ReportCommonContent from '../ReportCommonContent/ReportCommonContent';
import BorderedSectionContainer from '../../BorderedSectionContainer/BorderedSectionContainer';
import Form from '../../../../../UI/FormElements/Form/Form';
import SelectionContainer from '../../../../../UI/SelectionContainer/SelectionContainer';
import { periopsisApi } from '../../../../../../features/api/apiSlice';
import { AppDispatch } from '../../../../../../app/store';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

interface IProps {
  isSelected: boolean;
  selectedIncident: IContractIncident;
  reportType: reportType;
  closeForm: () => void;
}

const validateDepartment = (text: string) => validateText(text, 3, 120);
const validateMessage = (text: string) => validateText(text, 0, 3000);

const ReportByMail = ({
  isSelected,
  selectedIncident,
  reportType,
  closeForm,
}: IProps) => {
  const { user } = useSelector(selectAllAuthValues);
  const {
    isLoading,
    isError,
    error,
    data: reportData,
  } = useGetReportsOfIncidentQuery(
    {
      contractId: selectedIncident.contractId,
      contractIncidentId: selectedIncident._id,
      filter: 'user',
      withArchived: 'no',
      reportType,
    },
    { skip: !isSelected }
  );

  useSessionLogout({ isError, error });

  const [sendIncidentReport, { isLoading: isSendLoading }] =
    useSendReportOfIncidentMutation();

  const [exitingReports, setExistingReports] = useState<IExistingReport[]>([]);
  const [selectedReport, setSelectedReport] = useState<IExistingReport | null>(
    null
  );
  const [isNewReport, setIsNewReport] = useState(false);
  const [toList, setToList] = useState<item[]>([]);
  const [sendCopy, setSendCopy] = useState(true);
  const [ccList, setCCList] = useState<item[]>([]);
  const [bccList, setBCCList] = useState<item[]>([]);
  const [showCCBCC, setShowCCBCC] = useState(false);
  const [reportDate, setReportDate] = useState(
    formatDateForInputFormState(new Date())
  );
  const [rerenderToList, setRerenderToList] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [showReportToInfo, setShowReportToInfo] = useState(false);
  const [showDepartmentInfo, setShowDepartmentInfo] = useState(false);
  let toListExisting: item[] | null = null;
  let ccListExisting: item[] | null = null;
  let bccListExisting: item[] | null = null;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  // const [logout] = useLogout();

  let senderName = '';
  let senderEmail = '';

  const {
    value: reportToName,
    entryHandler: reportToEntryHandler,
    isValid: isValidNameSurname,
    clearEntry: clearReportToEntry,
    setter: reportToSetter,
  } = useFormEntry(validateNameSurname, 'reportToName', 'report', true);

  const {
    value: department,
    entryHandler: departmentEntryHandler,
    isValid: isValidDepartment,
    clearEntry: clearDepartmentEntry,
    setter: departmentSetter,
  } = useFormEntry(validateDepartment, 'reportToDepartment', 'report', true);

  const {
    value: message,
    entryHandler: messageEntryHandler,
    isValid: isValidMessage,
    clearEntry: clearMessage,
    setter: messageSetter,
  } = useFormEntry(validateMessage, 'report-message', 'report');

  useEffect(() => {
    if (
      reportData &&
      Array.isArray(reportData) &&
      reportData.length > 0 &&
      !isNewReport &&
      !isLoading &&
      !isError
    ) {
      setExistingReports(reportData);
      setSelectedReport(reportData[currentMessage]);

      departmentSetter(reportData[currentMessage].department);
      reportToSetter(reportData[currentMessage].reportTo);
      setSendCopy(reportData[currentMessage].sendCopy);
      messageSetter(reportData[currentMessage].message);
      setToList(reportData[currentMessage].toList);
      setCCList(reportData[currentMessage].ccList);
      setBCCList(reportData[currentMessage].bccList);
      setReportDate(reportData[currentMessage].reportDate);

      if (
        reportData[currentMessage].ccList.length > 0 ||
        reportData[currentMessage].bccList.length > 0
      ) {
        setShowCCBCC(true);
      }
    }

    if (reportData && reportData.length === 0) {
      setIsNewReport(true);
    }
    // eslint-disable-next-line
  }, [currentMessage, isLoading]);

  if (
    user &&
    typeof user === 'object' &&
    'name' in user &&
    'surname' in user &&
    user.name &&
    user.surname &&
    'email' in user &&
    user.email
  ) {
    senderName = `${user.name} ${user.surname}`;
    senderEmail = user.email;
  }

  const listOfToEmailHandler = (emails: item[]) => {
    setToList(emails);
  };

  const listOfCCEmailHandler = (emails: item[]) => {
    if (showCCBCC) {
      setCCList(emails);
    }
  };

  const listOfBCCEmailHandler = (emails: item[]) => {
    if (showCCBCC) {
      setBCCList(emails);
    }
  };

  const sendCopySelectHandler = () => {
    setSendCopy((prevState) => !prevState);
  };

  const ccBccShowHandler = () => {
    setShowCCBCC((prevState) => !prevState);
  };

  const currentMessageHandler = (direction: 'left' | 'right') => {
    if (direction === 'left' && currentMessage !== 0) {
      setCurrentMessage((prevState) => prevState - 1);
    } else if (
      direction === 'right' &&
      currentMessage !== exitingReports.length - 1
    ) {
      setCurrentMessage((prevState) => prevState + 1);
    }
  };

  const startNewReportHandler = () => {
    setIsNewReport(true);
    setExistingReports([]);
    setSelectedReport(null);
    setToList([]);
    setCCList([]);
    setBCCList([]);
    reportToSetter('');
    departmentSetter('');
    messageSetter('');
    setShowCCBCC(false);
    setSendCopy(true);
    setCurrentMessage(0);
    setReportDate(formatDateForInputFormState(new Date()));

    setRerenderToList(true);
    const timer = setTimeout(() => {
      setRerenderToList(false);

      clearTimeout(timer);
    }, 50);
  };

  if (selectedReport) {
    toListExisting = [...toList];
    ccListExisting = [...ccList];
    bccListExisting = [...bccList];
  } else {
    toListExisting = [];
    ccListExisting = [];
    bccListExisting = [];
  }

  const isReadyToSend =
    toList.length > 0 &&
    isValidNameSurname &&
    isValidDepartment &&
    reportToName.length > 0 &&
    department.length > 0;

  const sendReportHandler = async () => {
    try {
      const hasToEmails = toList.length > 0;

      if (!hasToEmails) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('forms:email_required_message')}
            heading={t('forms:email_required_heading')}
          />
        );
      }

      if (!reportToName) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('forms:report_to_name_error')}
            heading={t('forms:report_to_name_error_heading')}
          />
        );
      }

      if (!department) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('forms:report_to_department_error')}
            heading={t('forms:department_required')}
          />
        );
      }

      if (message && message.length > 3000) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('forms:message_limit_error')}
            heading={t('forms:message_limit_error_heading')}
          />
        );
      }

      const res = await sendIncidentReport({
        report: {
          reportDate,
          contractIncidentId: selectedIncident._id,
          gridId: selectedIncident.gridId,
          gridObjectId: selectedIncident.gridObjectId,
          contractId: selectedIncident.contractId,
          toList,
          ccList,
          bccList,
          incidentLocationLink: getIncidentLocationLink(
            selectedIncident.coordinates[0],
            selectedIncident.coordinates[1]
          ),
          reportTo: reportToName,
          department,
          userId: user?.id || '',
          sendCopy,
          message,
          reportType,
          incidentType: selectedIncident.type,
          detectedAt: selectedIncident.detectionDate,
          isArchived: false,
        },
      }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess &&
        'data' in res &&
        res.data
      ) {
        const responseData = res.data;
        clearDepartmentEntry();
        clearMessage();
        clearReportToEntry();
        clearDepartmentEntry();
        setToList([]);
        setCCList([]);
        setBCCList([]);
        setShowCCBCC(false);
        setSendCopy(true);
        localStorage.removeItem('periopsis-report-reportToName');
        localStorage.removeItem('periopsis-report-reportToDepartment');
        localStorage.removeItem('periopsis-report-report-message');
        setRerenderToList(true);
        dispatch(periopsisApi.util.invalidateTags(['action-statistics']));
        const timer = setTimeout(() => {
          setRerenderToList(false);
          clearTimeout(timer);
        }, 50);

        const closeTimer = setTimeout(() => {
          closeForm();
          clearTimeout(closeTimer);
        }, 3000);

        return toast.success(
          <ToastifyStyledMessage
            singleMessage={`Dear ${responseData.createdBy},
              
              We are pleased to inform you that the incident report dated ${new Date(
                responseData.date
              ).toLocaleDateString('en', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              })} with an ID of ${
              responseData.reportId
            } has been successfully transmitted.`}
            heading='Send Success'
          />,
          { autoClose: 2200 }
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  if (isLoading) {
    return (
      <SelectionContainer isSelected={isSelected}>
        <FetchingReports message={t('forms:fetching_email_reports')} />
      </SelectionContainer>
    );
  }

  if (isSendLoading) {
    return (
      <SelectionContainer isSelected={isSelected}>
        <FetchingReports message={t('forms:sending_report')} />
      </SelectionContainer>
    );
  }

  if (isError) {
    return (
      <SelectionContainer isSelected={isSelected}>
        <NoDataFound message={t('forms:reports_retrieve_error')} />
      </SelectionContainer>
    );
  }

  return (
    <SelectionContainer isSelected={isSelected}>
      <ReportCommonContent
        onNavigation={currentMessageHandler}
        onStartNewReport={startNewReportHandler}
        numberOfReports={exitingReports.length}
        currentReportIndex={currentMessage}
        selectedIncident={selectedIncident}
        onSend={sendReportHandler}
        isNewReport={isNewReport}
        isReadyToSend={isReadyToSend}
      >
        <BorderedSectionContainer heading={t('forms:email_report_details')}>
          <Form className={classes.MessagingDetails}>
            <Input
              type='date'
              label={t('forms:message_date')}
              name='date'
              value={reportDate}
              readOnly
            />
            <div className={classes.ReportToField}>
              <Input
                type='text'
                label={t('forms:Sender')}
                name='sender'
                value={senderName}
                readOnly
              />
              <Input
                type='text'
                label={t('forms:Sender_Email')}
                name='sender'
                value={senderEmail}
                readOnly
              />
              <Input
                label={t('forms:Report_To')}
                name='report-to'
                value={reportToName}
                isInvalid={!isValidNameSurname}
                onChange={reportToEntryHandler}
                isRequired
                title={t('forms:report_to_title')}
                showInformationBoard={showReportToInfo}
                onFocus={() => setShowReportToInfo(true)}
                onBlur={() => setShowReportToInfo(false)}
              >
                <p>{t('forms:report_to_desc')}</p>
              </Input>
              <Input
                label={t('forms:Department')}
                name='report-to'
                value={department}
                isInvalid={!isValidDepartment}
                onChange={departmentEntryHandler}
                isRequired
                title={t('forms:report_to_department_title')}
                showInformationBoard={showDepartmentInfo}
                onFocus={() => setShowDepartmentInfo(true)}
                onBlur={() => setShowDepartmentInfo(false)}
              >
                <p>{t('forms:report_to_department_desc')}</p>
              </Input>
            </div>
            <div className={classes.EmailsField}>
              <div className={classes.SendCopyWrapper}>
                <label htmlFor='send-copy' className={classes.SendCopy}>
                  <input
                    type='checkbox'
                    id='send-copy'
                    onChange={sendCopySelectHandler}
                    checked={sendCopy}
                  />{' '}
                  {t('forms:Send_Copy')}
                </label>
              </div>
              {!rerenderToList ? (
                <EmailChips
                  emailFormHeading={t('forms:To')}
                  setListOnBlur={listOfToEmailHandler}
                  existingList={toListExisting ? toListExisting : null}
                  shouldFocusOnExistingList
                  title={t('forms:report_to_emails_title')}
                  placeholder={t('forms:report_to_emails_placeholder')}
                >
                  <span
                    className={`${classes.CCBCC} ${
                      showCCBCC ? classes.ShowCCBCC : ''
                    }`}
                    onClick={ccBccShowHandler}
                  >
                    CC/BCC
                  </span>
                </EmailChips>
              ) : null}
              {showCCBCC && (
                <EmailChips
                  emailFormHeading='CC'
                  setListOnBlur={listOfCCEmailHandler}
                  existingList={ccListExisting ? ccListExisting : null}
                />
              )}
              {showCCBCC && (
                <EmailChips
                  emailFormHeading='BCC'
                  setListOnBlur={listOfBCCEmailHandler}
                  existingList={bccListExisting ? bccListExisting : null}
                />
              )}
            </div>
            <div className={classes.MessageField}>
              <Input
                type='text'
                value={t(
                  `dashboard:${getTranslationKey(selectedIncident.type)}`
                )}
                readOnly
                label={t('forms:Subject')}
                name='subject'
              />
              <TextArea
                label={t('forms:Write_Your_Message')}
                name='report message'
                maxLength={3000}
                value={message}
                onChange={messageEntryHandler}
                invalid={!isValidMessage}
                title={t('forms:Report_Message')}
                errorMessage={t('forms:Report_Message_error')}
                showRemaining
              />
            </div>
          </Form>
        </BorderedSectionContainer>
      </ReportCommonContent>
    </SelectionContainer>
  );
};

export default ReportByMail;
