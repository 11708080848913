import { Link } from 'react-router-dom';
import getTargetIncidentPageLink from '../../../../../utils/NotificationDetails/getTargetIncidentPageLink';
import CircleImage from '../../../../UI/CircleImage/CircleImage';
import garbageBag from '../../../../../assets/PeriopsisGarbageBagIcon.png';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import classes from './NotificationIncidentCard.module.scss';
import IncidentActions from '../../../../UI/IncidentActions/IncidentActions';
import { IIncidentActions } from '../../../../../tsTypes/interfaces';
import { useTranslation } from 'react-i18next';

interface IProps {
  type: string;
  detectionDate: string;
  isCleaned: boolean;
  incidentActions: IIncidentActions;
  gridId: string;
  gridObjectId: string;
  contractId: string;
}

const NotificationIncidentCard = ({
  type,
  detectionDate,
  isCleaned,
  incidentActions,
  gridId,
  gridObjectId,
  contractId,
}: IProps) => {
  const incidentLink = getTargetIncidentPageLink({
    gridId,
    gridObjectId,
    contractId,
  });
  const { i18n } = useTranslation();
  const language = i18n.language || 'en';

  return (
    <Link to={incidentLink} className={classes.CardContainer}>
      <div className={classes.Content}>
        <div className={classes.ContentLeft}>
          <CircleImage
            src={garbageBag}
            alt={`a blue garbage bag`}
            className={classes.CircleImage}
          />
          <div>
            <h3>{type}</h3>
            <data>
              {new Date(detectionDate).toLocaleDateString(language, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            </data>
          </div>
        </div>
        <MdOutlineKeyboardArrowRight />
      </div>

      <IncidentActions
        incidentActions={incidentActions}
        isCleaned={isCleaned}
      />
    </Link>
  );
};

export default NotificationIncidentCard;
