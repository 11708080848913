import { Fragment, useEffect, useState } from 'react';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Dashboard from './components/pages/Dashboard/Dashboard';
import RootLayout from './components/pages/RootLayout';
import Settings from './components/pages/Settings/Settings';
import ErrorPage from './components/pages/Error/ErrorPage';
import Statistics from './components/pages/Statistics/Statistics';
import EmailConfirmed from './components/pages/EmailConfirmed/EmailConfirmed';
import LoginRegister from './components/pages/LoginRegister/LoginRegister';
import IncidentDashboard from './components/pages/Dashboard/IncidentDashBoard/IncidentDashboard';

import Notifications from './components/pages/Notifications/Notifications';
import User from './components/pages/User/User';
import ConfirmError from './components/pages/EmailConfirmed/ConfirmError/ConfirmError';
import PasswordUpdate from './components/pages/LoginRegister/PasswordUpdate/PasswordUpdate';
import { frontendConstants } from './constants/constants';
import RegistrationCheckout from './components/pages/LoginRegister/SignUp/RegistrationCheckout/RegistrationCheckout';
//import Delete from './components/pages/Delete/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './app/store';
import {
  selectAllAuthValues,
  setUserAuthorization,
  setUserCurrentAuthorization,
} from './features/auth/authSlice';
import SendPage from './components/pages/Send/SendPage';
import useValidateAuth from './CustomHooks/useValidateAuth';
import PageRefreshLoading from './components/UI/PageRefreshLoading/PageRefreshLoading';
import UserAdminPage from './components/pages/UserAdminPage/UserAdminPage';
import ServiceRegistration from './components/pages/ServiceRegistration/ServiceRegistration';

/**
 *
 * @TODO : 22/01/2024
 *
 * 1- Complete Add User to Contract Section at User Admin Page and Test added Users.
 *    >>> Complete addNewUserToOrganizationUsers and addUsersToContract mutations and API
 *    >>> Do not forget to update organizationAssignData at User Collection for the added users!! (we will need 5 APIs)
 *        a- Add User to Organization Users List
 *        b- Remove User from Organization Users (Remove user from all contracts, remove user created reports, then remove user from users collection)
 *        c- Add User to Contract Users | Update User/Users of Contract , this will be an contract update, we will check existing users and update and we will add additional users to the contract
 *        d- Block User at Contract Basis
 *        e- Remove User from Current Contract (Remove user from contract and also remove user reports at current contract)
 *
 * !! Send email to the user when s/he has been added to the organization users list,
 *
 * 2- Complete Invoices section
 * 3- Complete Customer Details at Contract Details
 * 4- Add Organization Details/Customer Details Page to User Admin, For organization details, show users of the organization and by who they have been added and when they have been added to the system
 * 5- Complete Invoice Details page at Invoices Section
 * 6- Add PDF to Contract Details
 * 7- Add PDF to Invoice Details
 * 8- Add PDF to all Reports
 * 9- Test Receipt Cards, Invoice Cards, and Proforma Invoice Cards with Mock Data
 * 10- Create Email Templates for all emails
 * 11- Start Whatsapp API at Report To Section
 * 12- Clear all Deprecated Components, Interfaces and APIs
 */

const App = () => {
  const { userCurrentContract, user, userAuthorizations } =
    useSelector(selectAllAuthValues);
  const { isValidating } = useValidateAuth();
  const dispatch = useDispatch<AppDispatch>();
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    const authCurrent = localStorage.getItem('current-contract');

    if (authCurrent) {
      dispatch(setUserCurrentAuthorization(authCurrent));
    } else {
      setIsAuthLoading(false);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && userCurrentContract) {
      const currentContractAuthorizations = user.userAuthByContract.find(
        (contract) => contract.contractId === userCurrentContract
      );

      if (currentContractAuthorizations) {
        dispatch(
          setUserAuthorization(currentContractAuthorizations.authorizations)
        );

        let timer = setTimeout(() => {
          setIsAuthLoading(false);
        }, 600);

        return () => clearTimeout(timer);
      }
    }
    // eslint-disable-next-line
  }, [userCurrentContract, user]);

  if (isAuthLoading || isValidating) {
    return <PageRefreshLoading />;
  }

  const children =
    userAuthorizations &&
    userAuthorizations.hasAuthtoAdmin &&
    userAuthorizations.hasAuthToActions
      ? [
          { path: '/', element: <Dashboard /> },
          { path: frontendConstants.pages.settings, element: <Settings /> },
          { path: frontendConstants.pages.statistics, element: <Statistics /> },
          { path: frontendConstants.pages.user, element: <User /> },
          {
            path: frontendConstants.pages.incidentPage,
            element: <IncidentDashboard />,
          },
          {
            path: frontendConstants.pages.notifications,
            element: <Notifications />,
          },
          {
            path: frontendConstants.pages.outbox,
            element: <SendPage />,
          },

          {
            path: frontendConstants.pages.userAdmin,
            element: <UserAdminPage />,
          },
          {
            path: frontendConstants.pages.serviceRegistration,
            element: <ServiceRegistration />,
          },
        ]
      : userAuthorizations && userAuthorizations.hasAuthtoAdmin
      ? [
          { path: '/', element: <Dashboard /> },
          { path: frontendConstants.pages.settings, element: <Settings /> },
          { path: frontendConstants.pages.statistics, element: <Statistics /> },
          { path: frontendConstants.pages.user, element: <User /> },
          {
            path: frontendConstants.pages.incidentPage,
            element: <IncidentDashboard />,
          },
          {
            path: frontendConstants.pages.notifications,
            element: <Notifications />,
          },

          {
            path: frontendConstants.pages.userAdmin,
            element: <UserAdminPage />,
          },
          {
            path: frontendConstants.pages.serviceRegistration,
            element: <ServiceRegistration />,
          },
        ]
      : userAuthorizations && userAuthorizations.hasAuthToActions
      ? [
          { path: '/', element: <Dashboard /> },
          { path: frontendConstants.pages.settings, element: <Settings /> },
          { path: frontendConstants.pages.statistics, element: <Statistics /> },
          { path: frontendConstants.pages.user, element: <User /> },
          {
            path: frontendConstants.pages.incidentPage,
            element: <IncidentDashboard />,
          },
          {
            path: frontendConstants.pages.notifications,
            element: <Notifications />,
          },

          {
            path: frontendConstants.pages.outbox,
            element: <SendPage />,
          },
        ]
      : !userCurrentContract
      ? [
          { path: '/', element: <Dashboard /> },
          { path: frontendConstants.pages.settings, element: <Settings /> },
          { path: frontendConstants.pages.statistics, element: <Statistics /> },
          { path: frontendConstants.pages.user, element: <User /> },
          {
            path: frontendConstants.pages.incidentPage,
            element: <IncidentDashboard />,
          },
          {
            path: frontendConstants.pages.notifications,
            element: <Notifications />,
          },
          {
            path: frontendConstants.pages.serviceRegistration,
            element: <ServiceRegistration />,
          },
        ]
      : [
          { path: '/', element: <Dashboard /> },
          { path: frontendConstants.pages.settings, element: <Settings /> },
          { path: frontendConstants.pages.statistics, element: <Statistics /> },
          { path: frontendConstants.pages.user, element: <User /> },
          {
            path: frontendConstants.pages.incidentPage,
            element: <IncidentDashboard />,
          },
          {
            path: frontendConstants.pages.notifications,
            element: <Notifications />,
          },
        ];

  const router = createBrowserRouter([
    {
      path: frontendConstants.pages.dashboard,
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: children,
    },
    {
      path: frontendConstants.pages.login,
      element: <LoginRegister />,
    },
    {
      path: frontendConstants.pages.emailConfirmed,
      element: <EmailConfirmed />,
    },
    {
      path: frontendConstants.pages.emailConfirmError,
      element: <ConfirmError />,
    },
    {
      path: frontendConstants.pages.userPasswordRenewal,
      element: <PasswordUpdate />,
    },
    {
      path: frontendConstants.pages.registrationCheckoutPage,
      element: <RegistrationCheckout />,
    },
  ]);

  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
};

export default App;
