interface IProps {
  itemHeading: string;
  itemData: string | number;
  hasSeparator?: boolean;
  className?: string;
}

const SelectionItem = ({
  itemHeading,
  itemData,
  hasSeparator,
  className,
}: IProps) => {
  if (!itemData) {
    return null;
  }

  return (
    <li className={className ? className : ''}>
      <span>{itemHeading}</span>
      {hasSeparator ? <span>:</span> : null}
      <data>{itemData}</data>
    </li>
  );
};

export default SelectionItem;
