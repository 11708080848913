export function validateItalyVATNumber(vatNumber: string): boolean {
  // Remove any spaces from the entered VAT number
  const cleanedVATNumber = vatNumber.replace(/\s/g, '');

  // Check if the VAT number has the correct length
  if (cleanedVATNumber.length !== 11) {
    return false;
  }

  // Extract the components of the VAT number
  const progressiveNumber = cleanedVATNumber.substring(0, 7);
  const provinceCode = cleanedVATNumber.substring(7, 10);
  const checkDigit = cleanedVATNumber.substring(10);

  // Check if each part is a numeric value
  if (
    !/^\d+$/.test(progressiveNumber) ||
    !/^\d+$/.test(provinceCode) ||
    !/^\d+$/.test(checkDigit)
  ) {
    return false;
  }

  // Check if the province code is within the valid range (001 to 110)
  const validProvinceCode = parseInt(provinceCode, 10);
  if (validProvinceCode < 1 || validProvinceCode > 110) {
    return false;
  }

  // Calculate the check digit using Luhn's Algorithm
  const luhnResult = calculateLuhnDigit(progressiveNumber + provinceCode);
  const calculatedCheckDigit = luhnResult === 10 ? 0 : luhnResult;

  // Compare the calculated check digit with the one provided
  return parseInt(checkDigit, 10) === calculatedCheckDigit;
}

function calculateLuhnDigit(input: string): number {
  let sum = 0;
  let alternate = false;

  for (let i = input.length - 1; i >= 0; i--) {
    let digit = parseInt(input.charAt(i), 10);

    if (alternate) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    alternate = !alternate;
  }

  return sum % 10;
}
