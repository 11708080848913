import { useRef } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { AiFillSetting } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllAuthValues } from '../../../features/auth/authSlice';
import { MdLanguage } from 'react-icons/md';
import classes from './UserBadge.module.scss';
import { AppDispatch } from '../../../app/store';
import { setShowLanguageMenu } from '../../../features/UI/uiSlice';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClick: () => void;
}

const UserBadge = ({ onClick }: IProps) => {
  const { user, isAuthenticated } = useSelector(selectAllAuthValues);
  const menuElement = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  if (!user || !isAuthenticated) {
    return (
      <AiFillSetting className={classes.NavBarHomeSettings} onClick={onClick} />
    );
  }

  return (
    <div className={classes.Wrapper}>
      <div
        className={classes.UserBadgeContainer}
        onClick={onClick}
        ref={menuElement}
        id='userBadgeMenu'
      >
        <FaUserCircle id='userIcon' />
        <span id='userName'>{`${user.name} ${user.surname}`}</span>
      </div>
      <button
        className={classes.Language}
        onClick={() => dispatch(setShowLanguageMenu())}
      >
        <MdLanguage />
        <span>{t('translations:Select_Language')}</span>
      </button>
    </div>
  );
};

export default UserBadge;
