import { StyleSheet } from '@react-pdf/renderer';

export const bulletStyles = StyleSheet.create({
  circleBullet: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: '#004c6c',
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bulletText: {
    color: 'white',
    fontSize: 8,
    fontWeight: 'bold',
  },
});
