import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  alpha2Code,
  currentForm,
  IForgotAccountQuery,
  ILoginRequest,
  IRegistrationRequest,
  IUpdateGeneralSettings,
  IUpdatePasswordQuery,
  IUpdateUsernameQuery,
  IUsernameCheckQuery,
  IUserQuery,
  Session,
} from '../../tsTypes/types.components';
import {
  IResend,
  IResponseData,
  IUser,
  IUserRegistrationData,
} from '../../tsTypes/types.model';
import { thunkCatchBlock } from '../../utils/fetches';
import authService from './authService';
import { periopsisApi } from '../api/apiSlice';
import { ILoggedInUser, IUserAuth } from '../../tsTypes/interfaces';
import getHeaders from '../../utils/getHeaders';

export interface IAuthState {
  user: ILoggedInUser | null;
  userRegData: IUserRegistrationData | null;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  message: string;
  httpCode: number | null;
  errorHeading: string | '';
  errorSpecialCode: number | 0;
  hasToken: boolean;
  userData: Pick<IUser, 'name' | 'surname' | 'email'> | null; //this is another user which has been authorized by current user to take required incident actions
  //ex: mark cleaned | mark visited | report actions
  isConfirmationCodeProcess: boolean;
  is2FAProcess: boolean;
  isResendVerification: boolean;
  isForgotPasswordProcess: boolean;
  shouldSendLoginReport: boolean | null;
  forgotPasswordData: {
    token: string;
    code: string;
    email: string;
  } | null;
  currentForm: currentForm;
  hideNavigation: boolean;

  isResendLoading: boolean;
  userCurrentContract: string;
  userAuthorizations: IUserAuth | null;
}

//Get user from localStorage || if user is a logged in user, s/he shouldn't see registration and login pages!!!
// const userAtStoreage = localStorage.getItem('user');
// const user: Omit<IUser, 'password'> = userAtStoreage
//   ? JSON.parse(userAtStoreage)
//   : null;

const initialState: IAuthState = {
  user: null,
  userRegData: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  isAuthenticated: false,
  message: '',
  httpCode: null,
  hasToken: false,
  userData: null,
  isConfirmationCodeProcess: false,
  is2FAProcess: false,
  isForgotPasswordProcess: false,
  isResendVerification: false,
  shouldSendLoginReport: null,
  forgotPasswordData: null,
  currentForm: 'login',
  hideNavigation: false,
  errorHeading: '',
  errorSpecialCode: 0,
  isResendLoading: false,
  userCurrentContract: '',
  userAuthorizations: null,
};

export const checkUsername = createAsyncThunk(
  'auth/checkUsernameExistance',
  async (usernameCheckData: IUsernameCheckQuery, { rejectWithValue }) => {
    try {
      return await authService.checkUsernameExistance(usernameCheckData);
    } catch (error) {
      return thunkCatchBlock(error, rejectWithValue);
    }
  }
);

//To keep current user Logged In
export const loggedInUser = createAsyncThunk(
  'auth/session',
  async (session: Session, { rejectWithValue }) => {
    try {
      return await authService.getLoggedInUser(session);
    } catch (error: any) {
      return thunkCatchBlock(error, rejectWithValue);
    }
  }
);

//we will get userData with this thunk fn, this is the another user, which has been assign by current user (future functionality)
export const getUserByUserId = createAsyncThunk(
  'auth/getuserbyid',
  async (userQuery: IUserQuery, { rejectWithValue }) => {
    try {
      return await authService.getUserById(userQuery);
    } catch (error: any) {
      return thunkCatchBlock(error, rejectWithValue);
    }
  }
);

//Updates general settings (like two factor authentication, logout reminder, logout reminder duration, send login report)

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserIP: builder.query({
      query: () => `https://api.ipify.org/?format=json`,
      transformResponse: (responseData: { ip: string }) => {
        return responseData.ip;
      },
    }),
    checkEmail: builder.mutation({
      query: (email: string) => {
        const token = localStorage.getItem('tregt');
        const guestUserId = localStorage.getItem('tuid');

        return {
          url: '/users/checkemail',
          method: 'POST',
          body: { email, token, guestUserId },
        };
      },
    }),
    registerUser: builder.mutation({
      query: (registrationData: IRegistrationRequest) => {
        return {
          url: '/users',
          method: 'POST',
          body: {
            userData: registrationData.user,
            recaptcha: registrationData.recaptcha,
            serviceDetails: registrationData.serviceDetails,
            emailVerificationId: registrationData.emailVerificationId,
          },
        };
      },
    }),
    forgotAccount: builder.mutation({
      query: (forgotAccountQuery: IForgotAccountQuery) => {
        return {
          url: '/users/forgotpassword',
          method: 'POST',
          body: {
            email: forgotAccountQuery.email,
            validationCode: forgotAccountQuery?.validationCode,
            password: forgotAccountQuery?.password,
            confirmPassword: forgotAccountQuery?.confirmPassword,
            token: forgotAccountQuery?.token,
            recaptcha: forgotAccountQuery.recaptcha,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          forgotPasswordData: {
            token: string | null;
            code: string | null;
            email: string | null;
            verificationCode: boolean;
            isPasswordUpdated: boolean;
          } | null;
        }>
      ) => {
        if ('forgotPasswordData' in responseData.data) {
          const forgotPasswordData = responseData.data.forgotPasswordData;

          return forgotPasswordData;
        }

        return null;
      },
    }),
    addCurrentCoordinatesToUser: builder.mutation({
      query: (requestData: { latitude: number; longitude: number }) => {
        const { latitude, longitude } = requestData;
        const headers = getHeaders();

        return {
          url: `/users/coordinates`,
          method: 'PATCH',
          body: {
            latitude,
            longitude,
          },
          headers,
        };
      },
    }),
    sendVerificationCode: builder.mutation({
      query: (requestData: { userId: string; password: string }) => {
        const headers = getHeaders();

        return {
          url: `users/validationCode/${requestData.userId}`,
          method: 'POST',
          body: { password: requestData.password },
          headers,
        };
      },
      transformResponse: (responseData: IResponseData<{ pToken: string }>) => {
        const pToken = responseData.data.pToken;

        localStorage.setItem('pToken', pToken);

        return responseData;
      },
    }),
    updateUserPassword: builder.mutation({
      query: (updatePasswordQuery: IUpdatePasswordQuery) => {
        const {
          userId,
          oldPassword,
          password,
          confirmPassword,
          validationCode,
        } = updatePasswordQuery;
        const headers = getHeaders();

        return {
          url: `/users/newpassword`,
          method: 'PATCH',
          body: {
            userId,
            oldPassword,
            password,
            confirmPassword,
            validationCode,
          },
          headers,
        };
      },
    }),
    updateUsername: builder.mutation({
      query: (updateUsernameQuery: IUpdateUsernameQuery) => {
        const headers = getHeaders();
        const { userId, username, password, validationCode } =
          updateUsernameQuery;

        return {
          url: '/users/newusername',
          method: 'PATCH',
          body: { userId, username, password, code: validationCode },
          headers,
        };
      },
    }),
    resend2FA: builder.mutation({
      query: (credentials: IResend) => {
        return {
          url: '/users/twofa-resend',
          method: 'POST',
          body: {
            username: credentials.username,
            password: credentials.password,
          },
        };
      },
    }),
    login: builder.mutation({
      query: (credentials: ILoginRequest) => {
        return {
          url: '/users/login',
          method: 'POST',
          body: { credentials: credentials.credentials },
        };
      },
      transformResponse(
        responseData: IResponseData<{
          is2FASend: boolean;
          isConfirmationCodeProcess: boolean;
          isResendVerification: boolean;
          loggedInUser: ILoggedInUser | null;
        }>
      ) {
        const {
          is2FASend,
          isConfirmationCodeProcess,
          loggedInUser,
          isResendVerification,
        } = responseData.data;

        if (
          !is2FASend &&
          !isConfirmationCodeProcess &&
          !isResendVerification &&
          loggedInUser
        ) {
          const token = loggedInUser.token;
          const pToken = loggedInUser.pToken;
          const userId = loggedInUser.id;
          const location = loggedInUser.location;
          const lastLoginTime = loggedInUser.lastLoggedInAt;
          const userLoginId = loggedInUser.userLoginId;

          localStorage.setItem(
            'uData',
            JSON.stringify({ token, id: userId, location, lastLoginTime })
          );
          localStorage.setItem('pToken', pToken);
          localStorage.setItem('loginId', userLoginId);

          const tUToken = localStorage.getItem('tuid');
          const tRegToken = localStorage.getItem('tregt');

          if (tUToken || tRegToken) {
            localStorage.removeItem('tregt');
            localStorage.removeItem('tuid');
          }
        }

        return responseData.data;
      },
    }),
    resendConfirmationLink: builder.mutation({
      query: (credentials: IResend) => {
        return {
          url: '/users/confirmationResend',
          method: 'POST',
          body: {
            username: credentials.username,
            password: credentials.password,
          },
        };
      },
    }),
    resendConfirmationCode: builder.mutation({
      query: (email: string) => {
        return {
          url: '/users/confirmation-code-resend',
          method: 'POST',
          body: {
            email,
          },
        };
      },
    }),
    getUserSettings: builder.query({
      query: () => {
        const headers = getHeaders();

        return {
          url: '/settings/user',
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        response: IResponseData<{
          shouldRemindLogout: boolean;
          remindLogoutDuration: number;
          shouldSendLoginReport: boolean;
          language: string;
          need2FA: boolean;
          showIncidentImages: boolean;
        }>
      ) => {
        return response.data;
      },
      providesTags: ['user-settings'],
    }),
    updateGeneralSettings: builder.mutation({
      query: (settings: IUpdateGeneralSettings) => {
        const headers = getHeaders();
        const {
          twoFAuthentication,
          logoutReminder,
          logoutReminderDuration,
          sendLoginReport,
          preferredLanguage,
          showIncidentImages,
          userId,
        } = settings;

        return {
          url: '/settings/update',
          method: 'PATCH',
          body: {
            twoFAuthentication,
            logoutReminder,
            logoutReminderDuration,
            sendLoginReport,
            preferredLanguage,
            showIncidentImages,
            userId,
          },
          headers,
        };
      },
      invalidatesTags: ['user-settings'],
    }),
  }),
});

//Authentication Slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    //reducers will not contain any thunk functions
    resetAtAuth: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.httpCode = null;
      state.errorHeading = '';
      state.errorSpecialCode = 0;
    },

    setUserRegData: (state, action: PayloadAction<IUserRegistrationData>) => {
      state.userRegData = action.payload;
    },

    clearUserRegData: (state) => {
      state.userRegData = null;
    },

    clearAuthData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.httpCode = null;
      state.user = null;
      state.isAuthenticated = false;
      state.hasToken = false;
      state.isConfirmationCodeProcess = false;
      state.is2FAProcess = false;
      state.isForgotPasswordProcess = false;
      state.isResendVerification = false;
      state.hideNavigation = false;
    },

    setHasToken: (state) => {
      state.hasToken = true;
    },
    clearForgetPasswordData: (state) => {
      state.forgotPasswordData = null;
      state.isForgotPasswordProcess = false;
    },

    setUserCurrentLanguage: (state, action: PayloadAction<alpha2Code>) => {
      if (state.user && state.user.language) {
        state.user.language = action.payload;
      }
    },

    changeAuthForm: (state, action: PayloadAction<currentForm>) => {
      state.currentForm = action.payload;
      localStorage.setItem('auth-form', action.payload);
    },

    setHideNavigation: (state, action: PayloadAction<boolean>) => {
      state.hideNavigation = action.payload;
    },

    accountVerified: (state) => {
      state.isResendVerification = false;
      state.isResendLoading = false;
    },

    setLoggedInUser: (
      state,
      action: PayloadAction<{
        user: ILoggedInUser;
        isAuthenticated: boolean;
        hasToken: boolean;
      }>
    ) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.hasToken = action.payload.hasToken;
      state.user = action.payload.user;
      state.is2FAProcess = false;
      state.isConfirmationCodeProcess = false;
    },

    setUserCurrentAuthorization: (state, action: PayloadAction<string>) => {
      const currentContractId = action.payload;

      state.userCurrentContract = currentContractId;

      //userCurrentContractAuthorization
    },

    setUserAuthorization: (state, action: PayloadAction<IUserAuth>) => {
      state.userAuthorizations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loggedInUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loggedInUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isAuthenticated = true;
        state.user = action.payload.data;
        state.hasToken = true;
      })
      .addCase(loggedInUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isAuthenticated = false;
        state.hasToken = false;
        state.isError = true;
        localStorage.removeItem('uData');
        localStorage.removeItem('pToken');

        if (typeof action.payload === 'string') {
          state.message = action.payload;
        } else {
          const errorResponse: { code: number; message: string } = {
            ...(action.payload as { code: number; message: string }),
          };

          state.message = errorResponse.message;
          state.httpCode = errorResponse.code;
        }
        state.user = null;
      })
      .addCase(getUserByUserId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserByUserId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userData = action.payload.data.user;
      })
      .addCase(getUserByUserId.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;

        if (typeof action.payload === 'string') {
          state.message = action.payload;
        } else {
          const errorResponse: { code: number; message: string } = {
            ...(action.payload as { code: number; message: string }),
          };

          state.message = errorResponse.message;
          state.httpCode = errorResponse.code;
        }
        state.userData = null;
      })
      .addCase(checkUsername.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkUsername.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(checkUsername.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        if (typeof action.payload === 'string') {
          state.message = action.payload;
        } else {
          const errorResponse: { code: number; message: string } = {
            ...(action.payload as { code: number; message: string }),
          };

          state.message = errorResponse.message;
          state.httpCode = errorResponse.code;
        }
      });
  },
});

export const {
  useGetUserIPQuery,
  useGetUserSettingsQuery,
  useCheckEmailMutation,
  useRegisterUserMutation,
  useForgotAccountMutation,
  useAddCurrentCoordinatesToUserMutation,
  useSendVerificationCodeMutation,
  useUpdateUserPasswordMutation,
  useUpdateUsernameMutation,
  useResend2FAMutation,
  useLoginMutation,
  useResendConfirmationLinkMutation,
  useResendConfirmationCodeMutation,
  useUpdateGeneralSettingsMutation,
} = extendedApiSlice;

export const selectAllAuthValues = (state: RootState) => state.authentication;
export const {
  resetAtAuth,
  clearAuthData,
  setHasToken,
  clearUserRegData,
  setUserCurrentLanguage,
  clearForgetPasswordData,
  changeAuthForm,
  setHideNavigation,
  accountVerified,
  setUserRegData,
  setLoggedInUser,
  setUserCurrentAuthorization,
  setUserAuthorization,
} = authSlice.actions;
export default authSlice.reducer;
