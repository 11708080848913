import { IUserContract } from '../../tsTypes/interfaces';

const getUserContractsResult = (userContractsData: {
  contracts: IUserContract[];
  contractsTerminated: boolean;
}) => {
  let contracts: IUserContract[] = [];
  let contractsTerminated = false;
  let hasContracts = false;

  if (
    userContractsData &&
    typeof userContractsData === 'object' &&
    'contracts' in userContractsData &&
    userContractsData.contracts &&
    Array.isArray(userContractsData.contracts) &&
    'contractsTerminated' in userContractsData
  ) {
    contracts = userContractsData.contracts;
    contractsTerminated = userContractsData.contractsTerminated;
    hasContracts = contracts.length > 0;
  }

  return { contracts, contractsTerminated, hasContracts };
};

export default getUserContractsResult;
