import { useState, CSSProperties, Fragment } from 'react';
import Button from '../../../../../../UI/FormElements/Button/Button';
import PackageDescription from './PackageDescription/PackageDescription';
import PackageTypeSection from './PackageTypeSection/PackageTypeSection';
import { IPackage } from '../../../../../../../tsTypes/types.model';
import PackagePricing from '../../../../../../pdfComponents/PackagePricing/PackagePricing';
import { usePDF } from '@react-pdf/renderer';
import CenterDiv from '../../../../../../UI/CenterDiv/CenterDiv';
import PDFError from '../../../../../../UI/PDFError/PDFError';
import classes from './PackageCard.module.scss';
import convertCurrencySignToWord from '../../../../../../../utils/convertCurrencySignToWord';
import { useTranslation } from 'react-i18next';

interface IProps {
  style?: CSSProperties;
  onGetPlan: () => void;
  isSelected: boolean;
  onTermsOpen: () => void;
  packageData: Omit<IPackage, 'isActivePackage' | 'serviceTypeId'>;
  isThreePackView?: boolean;
  isOnLeft?: boolean;
  keepAtCenter?: boolean;
  hasSubservices?: boolean;
  isOpac?: boolean;
  isVisible?: boolean;
  isHidden?: boolean;
}

const PackageCard = ({
  packageData,
  style,
  onGetPlan,
  onTermsOpen,
  isSelected,
  isThreePackView,
  isOnLeft,
  keepAtCenter,
  hasSubservices,
  isOpac,
  isVisible,
  isHidden,
}: IProps) => {
  const [showDescription, setShowDescription] = useState(false);
  const doc = <PackagePricing packageData={packageData} />;
  const [instance, update] = usePDF({ document: doc });
  let pdfDoc: React.ReactNode | null = null;
  const { t } = useTranslation();

  const buttonText = !isSelected ? t('auth:GET_THIS_PLAN') : t('auth:DESELECT');

  const packageDescriptionHandler = () => {
    setShowDescription((prevState) => !prevState);
  };

  if (!packageData) {
    return null;
  }

  if (instance && instance.loading) {
    pdfDoc = <CenterDiv>{/* <SimpleLoading size={0.5} />; */}</CenterDiv>;
  }

  if (instance && instance.error) {
    pdfDoc = <PDFError />;
  }

  const updatePDFHandler = () => {
    update(doc);
  };

  const brochureName = packageData.packageDetails.heading.split(' ').join('_');

  if (instance && instance.url) {
    pdfDoc = (
      <a
        href={instance.url}
        download={`Periopsis_${brochureName}_${new Date().getTime()}.pdf`}
        className={classes.BrochureLink}
        onClick={updatePDFHandler}
        title={t('auth:Download_Package_Brochure')}
      >
        {t('auth:download_brochure')}
      </a>
    );
  }

  return (
    <Fragment>
      <div
        className={`${classes.PackageCardContainer} ${
          isThreePackView ? classes.ThreePackView : ''
        } ${isOnLeft ? classes.OnLeftPosition : ''} ${
          keepAtCenter ? classes.KeepAtCenter : ''
        } ${hasSubservices ? classes.SubservicesPosition : ''} ${
          isOpac ? classes.Opac : ''
        } ${isHidden ? classes.Hidden : ''}`}
        style={style}
        title={`${packageData.packageName} | ${t('auth:Price')} : ${
          packageData.monthlyPrice === 0
            ? `Free for ${packageData.duration} ${packageData.durationUnit}`
            : `${packageData.currency}${packageData.monthlyPrice}${packageData.priceUnit}`
        }`}
      >
        <div className={classes.PackageInfo}>
          <PackageTypeSection
            packageName={packageData.packageName}
            isCloseButton={false}
            onClick={packageDescriptionHandler}
          />
          <div className={classes.PriceSection}>
            <h4>
              {packageData.currency}
              {packageData.monthlyPrice}
            </h4>
            <h6>
              {convertCurrencySignToWord(
                packageData.currency,
                packageData.monthlyPrice === 1
              )}
              {packageData.priceUnit}
            </h6>
          </div>
          <div className={classes.HeadingSection}>
            <h4>{packageData.packageDetails.heading}</h4>
          </div>
        </div>
        <ul className={classes.ServicesList}>
          {packageData.packageDetails.services.map((service) => (
            <li key={service}>{service}</li>
          ))}
        </ul>
        <div className={classes.TermsAndConditions}>
          {pdfDoc}
          <button onClick={onTermsOpen}>
            {t('auth:Terms_and_Conditions')}
          </button>
        </div>
        <div className={classes.Actions}>
          <Button btnClass={classes.CardAction} onClick={onGetPlan}>
            {buttonText}
          </Button>
        </div>
        <PackageDescription
          packageName={packageData.packageName}
          description={packageData.packageDetails.description}
          isCloseButton={true}
          onClick={packageDescriptionHandler}
          show={showDescription}
        />
      </div>
    </Fragment>
  );
};

export default PackageCard;
