import { Fragment } from 'react';
import { item } from '../../../../../../tsTypes/types.components';
import ListItemContainer from '../../../../../UI/ListItemContainer/ListItemContainer';
import RemoveButton from '../../../../../UI/RemoveButton/RemoveButton';
import classes from './ListItem.module.scss';

interface IProps {
  item: item;
  isAuthorized: boolean;
  onRemove: (id: string) => void;
  title?: string;
  index: number;
  suffix?: string;
}

const ListItem = ({
  item,
  isAuthorized,
  onRemove,
  title,
  index,
  suffix,
}: IProps) => {
  return (
    <Fragment>
      <ListItemContainer sections={2}>
        <p>{index + 1}</p>
        <p>{item.name}</p>
        {item.number ? (
          <p>
            {item.number} {suffix}
          </p>
        ) : (
          <p></p>
        )}
        <RemoveButton
          onRemove={onRemove}
          id={item._id}
          isAuthorized={isAuthorized}
          title={title}
        />
      </ListItemContainer>
      <div className={classes.ListItemContainerMobile}>
        <div className={classes.DataContainer}>
          <p>{item.name}</p>
          {item.number ? (
            <p>
              {item.number} {suffix}
            </p>
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ListItem;
