import { Link } from 'react-router-dom';
import { getColorClass } from '../../../../utils/componentUtilFns';
import { ColorClassType } from '../../../../tsTypes/types.components';
import classes from './StandardButton.module.scss';
import React from 'react';

interface IProps {
  type?: 'reset' | 'button' | 'submit';
  btnType: ColorClassType;
  label: string | JSX.Element;
  onClick?: (e: any | null) => void;
  style?: React.CSSProperties;
  fakeButton?: boolean;
  title?: string;
  href?: string;
  icon?: React.ReactNode;
  hideLabelOnMobile?: boolean;
  isTabButton?: boolean;
  isDraggable?: boolean;
  onDragStart?: (e: React.DragEvent<HTMLButtonElement>) => void;
  onDragEnd?: (e: React.DragEvent<HTMLButtonElement>) => void;
  onDragEnter?: (e: React.DragEvent<HTMLButtonElement>) => void;
  className?: string;
}

const StandardButton = ({
  type,
  btnType,
  onClick,
  label,
  style,
  fakeButton,
  title,
  href,
  icon,
  hideLabelOnMobile,
  isTabButton,
  isDraggable,
  onDragStart,
  onDragEnd,
  onDragEnter,
  className,
}: IProps) => {
  const selectedColorClass = getColorClass(btnType);

  if (fakeButton) {
    return (
      <div
        className={`${classes.StandardButton} ${classes[selectedColorClass]} ${
          classes.Fake
        } ${isTabButton ? classes.TabButton : ''} ${
          className ? className : ''
        }`}
        style={{ ...style }}
      >
        {icon && (
          <div className={hideLabelOnMobile ? classes.ButtonIcon : ''}>
            {' '}
            <span>{icon}</span>
            <span className={classes.MobileLabel}>{label}</span>
          </div>
        )}
        <span className={hideLabelOnMobile ? classes.ButtonLabel : ''}>
          {label}
        </span>
      </div>
    );
  }

  if (href && typeof href === 'string') {
    return (
      <Link
        to={href}
        title={title}
        className={`${classes.StandardButton} ${classes[selectedColorClass]} ${
          classes.LinkBtn
        } ${className ? className : ''}`}
        style={{ ...style }}
      >
        {icon && (
          <div className={hideLabelOnMobile ? classes.ButtonIcon : ''}>
            {' '}
            {icon}
            <span className={classes.MobileLabel}>{label}</span>
          </div>
        )}
        <span className={hideLabelOnMobile ? classes.ButtonLabel : ''}>
          {label}
        </span>
      </Link>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${classes.StandardButton} ${classes[selectedColorClass]} ${
        isTabButton ? classes.TabButton : ''
      } ${className ? className : ''}`}
      style={{ ...style }}
      title={title}
      draggable={isDraggable}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragEnter={onDragEnter}
    >
      {icon && (
        <div className={hideLabelOnMobile ? classes.ButtonIcon : ''}>
          {' '}
          {icon}
          <span className={classes.MobileLabel}>{label}</span>
        </div>
      )}
      <span className={hideLabelOnMobile ? classes.ButtonLabel : ''}>
        {label}
      </span>
    </button>
  );
};

export default StandardButton;
