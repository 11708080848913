import { Link } from 'react-router-dom';
import garbageBag from '../../../assets/PeriopsisGarbageBagIcon.png';
import { IContractIncident } from '../../../tsTypes/interfaces';
import IncidentActions from '../../UI/IncidentActions/IncidentActions';
import classes from './IncidentPopUpInfo.module.scss';
import Image from '../../UI/Image/Image';

interface IProps {
  incident: IContractIncident;
}

const IncidentPopUpInfo = ({ incident }: IProps) => {
  return (
    <Link
      to={`/incident/${incident.gridId}?latestIncident=${incident.gridObjectId}&contractId=${incident.contractId}`}
      className={classes.PopUpContainer}
      title='Click to visit dashboard of this incident'
    >
      <div className={classes.ContentContainer}>
        <div className={classes.UpperContent}>
          <div className={classes.InfoContainer}>
            <h2>{incident.type}</h2>
            <p>
              {new Date(incident.detectionDate).toLocaleDateString('en', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            </p>
          </div>
          <div className={classes.IconContainer}>
            <img src={garbageBag} alt={incident.type} title={incident.type} />
          </div>
        </div>
        <div className={classes.ImageContainer}>
          <Image
            imagePath={incident.detectionImage[0].path}
            alt={incident.type}
            title={incident.observedType.typeName || incident.type}
          />
        </div>
        <IncidentActions
          incidentActions={incident.incidentActions}
          isCleaned={incident.isCleaned}
        />
      </div>
    </Link>
  );
};

export default IncidentPopUpInfo;
