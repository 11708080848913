import { IListObject } from '../../../../tsTypes/types.model';
import classes from './Dropdown.module.scss';

interface IProps {
  optionsList: IListObject[];
  defaultValue: string;

  isRequired?: boolean;
  style?: React.CSSProperties;
  name: string;
  onSelect: (
    e:
      | { target: { name: string; value: string } }
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  value: string;
  title?: string;
}

const Dropdown = ({
  optionsList,
  isRequired,
  defaultValue,
  name,
  onSelect,
  value,
  title,
}: IProps) => {
  return (
    <div className={classes.DropDownWrapper} title={title}>
      <select
        className={`${classes.GaeaDropDownSelect} ${
          !value ? classes.DefaultValueStyle : ''
        }`}
        onChange={onSelect}
        value={value}
        name={name}
      >
        {defaultValue ? <option>{defaultValue}</option> : null}
        {optionsList.map((listItem) => (
          <option value={listItem.id} key={listItem.id}>
            {listItem.title}
          </option>
        ))}
      </select>
      {isRequired && !value ? <span>*</span> : null}
    </div>
  );
};

export default Dropdown;
