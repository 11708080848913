const getTargetIncidentPageLink = (data: {
  gridId: string;
  gridObjectId: string;
  contractId: string;
}) => {
  const { gridId, gridObjectId, contractId } = data;

  return `/incident/${gridId}?latestIncident=${gridObjectId}&contractId=${contractId}`;
};

export default getTargetIncidentPageLink;
