import periopsis from '../../../assets/images/HomePageImages/periopsisLogov1.png';
import classes from './PageRefreshLoading.module.scss';

const PageRefreshLoading = () => {
  return (
    <div className={classes.MainContainer}>
      <div className={classes.ContentContainer}>
        <div className={classes.PeriopsisLogoContainer}>
          <img src={periopsis} alt='Periopsis' />
        </div>
      </div>
    </div>
  );
};

export default PageRefreshLoading;
