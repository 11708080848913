import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import satallite from '../../../assets/images/satallite.png';
import notFound from '../../../assets/images/PageNotFound.png';
import Button from '../../UI/FormElements/Button/Button';
import classes from './Errror.module.scss';
import { frontendConstants } from '../../../constants/constants';

const Error = () => {
  const navigate = useNavigate();

  const goBackHandler = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(frontendConstants.pages.dashboard, { replace: true });
    }
  };

  const messageToUse = 'There is something wrong with the given coordinates';

  return (
    <div className={classes.MainContainer}>
      <div className={classes.ErrorContainer}>
        <div className={classes.LeftImageContainer}>
          <img
            src={satallite}
            alt='Periopsis Page Not Found'
            title='Periopsis Ltd, Cyprus'
          />
        </div>
        <div className={classes.RightImageContainer}>
          <img
            src={notFound}
            alt='Periopsis Page Not Found'
            title='Periopsis Ltd, Cyprus'
          />
        </div>
      </div>
      <p>{messageToUse}</p>
      <div className={classes.Actions}>
        <Button href='https://www.periopsis.com'>Home</Button>
        <Button onClick={goBackHandler}>Back</Button>
      </div>
    </div>
  );
};

const ErrorPage = () => {
  return createPortal(<Error />, document.getElementById('error_root')!);
};

export default ErrorPage;
