import React, { Fragment } from 'react';
import { term } from '../../../tsTypes/types.model';
import classes from './TermsAndConditions.module.scss';
import Backdrop from '../Backdrop/Backdrop';
import { AiOutlineCloseCircle } from 'react-icons/ai';

interface IProps {
  terms: term[];
  show: boolean;
  onClose: () => void;
}

const TermsAndConditions = ({ terms, show, onClose }: IProps) => {
  return (
    <Fragment>
      <div
        className={`${classes.TermsAndConditionsContainer} ${
          show ? classes.Show : ''
        }`}
      >
        <div className={classes.ContentContainer}>
          <div className={classes.HeadingSection}>
            <h1>Terms And Conditions</h1>
            <button className={classes.CloseWindow} onClick={onClose}>
              <AiOutlineCloseCircle />
            </button>
          </div>
          <div className={classes.Terms}>
            <ul>
              {terms.map((term, index) => (
                <li key={term.id}>
                  <h3>
                    {index + 1}. {term.heading}
                  </h3>
                  <p>{term.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Backdrop show={show} onClick={onClose} />
    </Fragment>
  );
};

export default TermsAndConditions;
