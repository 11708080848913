import { Text } from '@react-pdf/renderer';

interface IProps {
  content: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | undefined;
  size?: number;
}

const FixedText = ({ content, textAlign, size }: IProps) => {
  return (
    <Text style={{ flex: size || 1, textAlign: textAlign }}>{content}</Text>
  );
};

export default FixedText;
