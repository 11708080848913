import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionContainer from '../../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../../UI/FormElements/Button/Button';
import Form from '../../../../../UI/FormElements/Form/Form';
import Input from '../../../../../UI/FormElements/Input/Input';

import {
  ICountryPhoneData,
  IRegistrationSelectionProps,
  IServiceDetails,
} from '../../../../../../tsTypes/types.components';

import { IUser } from '../../../../../../tsTypes/types.model';

import SelectionContainer from '../SelectionContainer/SelectionContainer';
import { selectAllRegistrationFormValues } from '../../../../../../features/registration/registrationSlice';
import {
  // register,
  selectAllAuthValues,
  setHideNavigation,
  useGetUserIPQuery,
  useCheckEmailMutation,
  useRegisterUserMutation,
  setUserRegData,
} from '../../../../../../features/auth/authSlice';
import {
  capitalizeAllWords,
  getHeaderByCode,
  validateEmail,
  validateNameSurname,
  validatePassword,
} from '../../../../../../utils/componentUtilFns';
import { AppDispatch } from '../../../../../../app/store';
import { toast } from 'react-toastify';
import { resetAtAuth } from '../../../../../../features/auth/authSlice';
import SelectionForm from '../SelectionForm/SelectionForm';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import useFormEntry from '../../../../../../CustomHooks/useFormEntry';
import PasswordStrengthTest from '../PasswordStrengthTest/PasswordStrengthTest';
import SimpleLoading from '../../../../../UI/SimpleLoading/SimpleLoading';
import PeriopsisCaptcha from '../../../../../UI/PeriopsisCaptcha/PeriopsisCaptcha';
import useRecaptcha from '../../../../../../CustomHooks/useRecaptcha';
import { catchError } from '../../../../../../utils/fetches';
import CenterDiv from '../../../../../UI/CenterDiv/CenterDiv';
import useClearRegistration from '../../../../../../CustomHooks/useClearRegistration';
import Phone from '../../../../../UI/Phone/Phone';
import { decodePattern } from '../../../../../../utils/phoneUtilityFunctions/createPattern';
import usePhoneEntry from '../../../../../../CustomHooks/usePhoneEntry';
import Address from '../../../../../UI/Address/Address';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import useAddress from '../../../../../../CustomHooks/useAddress';
import PasswordConditions from '../../../../../UI/PasswordConditions/PasswordConditions';
import queryErrorCatch from '../../../../../../utils/queryErrorCatch';
import classes from './RegistrationForm.module.scss';
import { useTranslation } from 'react-i18next';

const validatePasswordEntry = (password: string) =>
  validatePassword(password, 6, 25);

const RegistrationForm = ({ pageNumber }: IRegistrationSelectionProps) => {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [showConditionsBoard, setShowConditionsBoard] = useState(false);
  const { t } = useTranslation();

  const [
    register,
    {
      isLoading: isRegistrationLoading,
      isError: isRegistrationError,
      isSuccess: isRegistrationSuccess,
      data: registrationResponse,
      reset,
    },
  ] = useRegisterUserMutation({});

  const {
    phoneNumber,
    countryData,
    countryCodeSelectHandler,
    phoneEntryHandler,
    clearPhoneData,
  } = usePhoneEntry({ localStorageName: 'registration-phone' });

  const navigate = useNavigate();

  const {
    isValidRecaptcha,
    recaptchaChangeHandler,
    recaptchaExpiredHandler,
    recaptchaRef,
    recaptchaCode,
    resetRecaptcha,
  } = useRecaptcha();

  const { clearForm } = useClearRegistration();

  const {
    value: name,
    isValid: isValidName,
    entryHandler: nameEntryHandler,
    clearEntry: clearNameEntry,
  } = useFormEntry(validateNameSurname, 'name', 'registration', true);
  const {
    value: surname,
    isValid: isValidSurname,
    entryHandler: surnameEntryHandler,
    clearEntry: clearSurnameEntry,
  } = useFormEntry(validateNameSurname, 'surname', 'registration', true);
  const {
    value: email,
    isValid: isValidEmail,
    entryHandler: emailEntryHandler,
    clearEntry: clearEmailEntry,
  } = useFormEntry(validateEmail, 'email', 'registration');
  const {
    value: password,
    isValid: isValidPassword,
    entryHandler: passwordEntryHandler,
    clearEntry: clearPasswordEntry,
  } = useFormEntry(validatePasswordEntry);

  const validateConfirmPassword = (confirmPasswordEntry: string) =>
    validatePasswordEntry(confirmPasswordEntry) &&
    confirmPasswordEntry === password;

  const {
    value: confirmPassword,
    isValid: isValidConfirmPassword,
    entryHandler: confirmPasswordEntryHandler,
    clearEntry: clearConfirmPasswordEntry,
  } = useFormEntry(validateConfirmPassword);

  const dispatch = useDispatch<AppDispatch>();

  const emailInputRef = useRef<any>();
  const nameInputRef = useRef<any>();
  const surnameInputRef = useRef<any>();
  const passwordInputRef = useRef<any>();
  const confirmPasswordInputRef = useRef<any>();

  const {
    currentPageNumber,
    selectedOrganizationType,
    selectedCountry,
    selectedDistrict,
    selectedOrganization,
    selectedServiceType,
    selectedPackage,
    selectedSubservices,
    selectedProvince,
    userLocation,
    isInvoiceDetailsApproved,
    areasOfInterest,
  } = useSelector(selectAllRegistrationFormValues);

  const shouldSkip = currentPageNumber !== 8;
  const { data: userIp } = useGetUserIPQuery('', {
    skip: shouldSkip,
  });

  const [checkEmail, { isLoading: isEmailCheckLoading, data }] =
    useCheckEmailMutation();

  const { message, isLoading, isError, httpCode } =
    useSelector(selectAllAuthValues);

  const { variableData, addressFunctions } = useAddress({
    userAddressLocalStorageName: 'user-address',
    moreAddressDetailsLocalStorageName: 'more-details',
    shouldSkip,
  });

  const {
    countries,
    isFetchingCountries,
    isCountryFetchError,
    countryFetchError,
    userAddress,
    addressStructure,
    moreAddressDetails,
    isValidAddress,
    isFetchingAddressStructure,
    isAddressStructureFetchError,
    addressStructureError,
    activateButton,
    isValidAddressType,
  } = variableData;

  const {
    addressCountrySelectHandler,
    userAddressEntryHandler,
    moreAddressDetailsHandler,
    clearAddress,
    addressTypeSelectHandler,
  } = addressFunctions;

  //Fetch Errors
  useEffect(() => {
    if (isError) {
      const heading = httpCode ? getHeaderByCode(httpCode) : '';
      const messageToUse =
        httpCode === 429
          ? t('registration:too_many_registration_request')
          : message;

      toast.error(
        <ToastifyStyledMessage singleMessage={messageToUse} heading={heading} />
      );
      dispatch(resetAtAuth());
    }
    // eslint-disable-next-line
  }, [dispatch, message, httpCode, isError, userIp]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isRegistrationError && !isRegistrationLoading) {
      resetRecaptcha(true);
    }
    //should be !isRegistrationLoading
    if (isRegistrationSuccess && !isRegistrationLoading) {
      timer = setTimeout(() => {
        dispatch(setHideNavigation(true));
        clearForm();
        reset();

        if (
          registrationResponse &&
          typeof registrationResponse === 'object' &&
          'data' in registrationResponse &&
          registrationResponse.data &&
          typeof registrationResponse.data === 'object' &&
          'isSuccess' in registrationResponse &&
          registrationResponse.isSuccess &&
          'user' in registrationResponse.data &&
          typeof registrationResponse.data.user === 'object' &&
          'checkoutToken' in registrationResponse.data &&
          registrationResponse.data.checkoutToken &&
          typeof registrationResponse.data.checkoutToken === 'string' &&
          'registrationSuccessData' in registrationResponse.data &&
          typeof registrationResponse.data.registrationSuccessData ===
            'object' &&
          'code' in registrationResponse.data.registrationSuccessData &&
          registrationResponse.data.registrationSuccessData.code &&
          typeof registrationResponse.data.registrationSuccessData.code ===
            'string' &&
          'datetime' in registrationResponse.data.registrationSuccessData &&
          registrationResponse.data.registrationSuccessData.datetime &&
          typeof registrationResponse.data.registrationSuccessData.datetime ===
            'number' &&
          'proformaInvoiceNumber' in registrationResponse.data &&
          typeof registrationResponse.data.proformaInvoiceNumber === 'string' &&
          'serviceContractId' in registrationResponse.data &&
          registrationResponse.data.serviceContractId &&
          typeof registrationResponse.data.serviceContractId === 'string'
        ) {
          const navigateToCheckout = () => {
            return navigate(
              {
                pathname: `/check-out/${registrationResponse.data.checkoutToken}/${registrationResponse.data.serviceContractId}/${registrationResponse.data.registrationSuccessData.code}`,
                search: `?proformaInvoice=${registrationResponse.data.proformaInvoiceNumber}`,
              },
              { replace: true }
            );
          };

          navigateToCheckout();
        }
      }, 2500);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line
  }, [
    isRegistrationSuccess,
    isRegistrationError,
    isRegistrationLoading,
    navigate,
  ]);

  const checkEmailExistanceHandler = async () => {
    if (isValidEmail) {
      try {
        await checkEmail(email).unwrap();

        if (
          data &&
          typeof data === 'object' &&
          'isSuccess' in data &&
          data.isSuccess
        ) {
          setInvalidEmail(false);
        }
      } catch (error) {
        const { status, message } = catchError(error);
        const heading = status
          ? getHeaderByCode(status, {
              409: t('registration:email_already_exists'),
              400: t('registration:not_a_valid_email'),
            })
          : '';

        toast.error(
          <ToastifyStyledMessage singleMessage={message} heading={heading} />
        );
        if (status === 400 || status === 409) {
          setInvalidEmail(true);
          emailInputRef.current.focus();
        }
      }
    }
  };

  const clearRegistrationFormHandler = () => {
    clearForm({
      additionalClearProcessHandler: () => {
        clearNameEntry();
        clearSurnameEntry();
        clearEmailEntry();
        clearPasswordEntry();
        clearConfirmPasswordEntry();
        clearPhoneData();
        setInvalidEmail(false);
        clearAddress();
        reset();
      },
      shouldResetState: true,
    });
  };

  const userRegistrationHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('tregt');
      const guestUserId = localStorage.getItem('tuid');
      const emailVerificationId = localStorage.getItem(
        'organization-email-verification-id'
      );

      let phoneData: ICountryPhoneData | null = null;
      let servicePackage = '';
      let subservices: string[] = [];

      //Validate email
      const isValidEmailAddress = validateEmail(email);

      if (!isValidEmailAddress) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('registration:invalid_email_address')}
            singleMessage={t('registration:invalid_email_address_message')}
          />
        );
      }

      const isValidUserPassword = validatePassword(password, 6, 25);

      if (!isValidUserPassword) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('registration:invalid_password')}
            singleMessage={t('registration:invalid_password_message')}
          />
        );
      }

      const isValidConfirmPassword = password === confirmPassword;

      if (!isValidConfirmPassword) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('registration:match_error')}
            singleMessage={t('registration:match_error_message')}
          />
        );
      }

      if (selectedPackage) {
        servicePackage = selectedPackage._id;
      }

      if (selectedSubservices) {
        subservices = selectedSubservices.map((subser) => subser.subserviceId);
      }

      if (!servicePackage) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('registration:no_package')}
            singleMessage={t('registration:no_package_message')}
          />,
          {
            autoClose: 15000,
          }
        );
      }

      if (!isInvoiceDetailsApproved) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('registration:approval_required')}
            singleMessage={t('registration:approval_required_message')}
          />
        );
      }

      if (phoneNumber && countryData) {
        phoneData = {
          code: countryData.countryCode,
          country: countryData.name,
          phoneNumber: decodePattern(phoneNumber),
        };
      }

      if (
        name &&
        surname &&
        email &&
        password &&
        confirmPassword &&
        isValidName &&
        isValidSurname &&
        isValidEmail &&
        isValidPassword &&
        isValidConfirmPassword &&
        isValidAddressType &&
        selectedCountry &&
        selectedDistrict &&
        selectedServiceType &&
        selectedOrganization &&
        selectedOrganizationType &&
        phoneNumber &&
        phoneData &&
        servicePackage &&
        token &&
        recaptchaCode &&
        recaptchaCode.current &&
        guestUserId &&
        userAddress &&
        userAddress.countryDetails &&
        userAddress.countryDetails.country &&
        userAddress.countryDetails.countryCode &&
        userAddress.streetAddress &&
        userAddress.streetAddress.label &&
        userAddress.streetAddress.name
      ) {
        const userData: Omit<IUser, 'username' | 'id' | 'userAuthByContract'> =
          {
            name,
            surname,
            email,
            password,
            confirmPassword,
            token,
            guestUserId,
            location: {
              location: userLocation,
            },

            userPhones: [phoneData],
            userIP: userIp,
            language: 'en',
            address: userAddress,
          };

        //We need to add Areas Of Interest to Service Details
        //District can be Multiple Districts, therefore, we need to change it to districts and it should be stored as district array
        //we should add isIndividualUser flag and we will check if selectedOrganization.id.startsWith("Individual")
        //we may add country alpha 2 code
        //we will need the validation id  localStorage.getItem('organization-email-verification-id');
        const serviceDetails: IServiceDetails = {
          servicePackage,
          subservices,
          invoiceApprovalData: {
            approvedBy: `${name} ${surname}`,
            approvedAt: new Date(),
            isApproved: isInvoiceDetailsApproved,
          },
          selectedOrganizationType: {
            _id: selectedOrganizationType.id,
            name: selectedOrganizationType.title,
          },
          serviceType: {
            _id: selectedServiceType.id,
            name: selectedServiceType.title,
          },
          selectedDistrict,
          selectedProvince,
          selectedOrganization:
            selectedOrganization &&
            'id' in selectedOrganization &&
            selectedOrganization.id
              ? selectedOrganization.id
              : '',
          selectedCountry: {
            _id: selectedCountry._id,
            name: selectedCountry.name,
          },
          areasOfInterest,
        };

        const userName = `${name} ${surname}`;
        const emailAddress = email;
        const selectedOrganizationEmail = selectedOrganization.email;

        const isOrganizationRegMailSame =
          selectedOrganizationEmail === emailAddress;

        const response = await register({
          user: userData,
          recaptcha: recaptchaCode.current,
          serviceDetails,
          emailVerificationId: emailVerificationId ? emailVerificationId : '',
        }).unwrap();

        if (
          response &&
          typeof response === 'object' &&
          'isSuccess' in response &&
          response.isSuccess &&
          'data' in response &&
          typeof response.data === 'object' &&
          'user' in response.data &&
          response.data.user &&
          typeof response.data.user === 'object' &&
          'id' in response.data.user
        ) {
          const message = isOrganizationRegMailSame
            ? `${t('registration:dear')} ${userName}, ${t(
                'registration:registration_complete'
              )}`
            : `${t('registration:dear')} ${userName}, ${t(
                'registration:registration_complete_confirm_email'
              )}`;
          dispatch(setUserRegData(response.data.user));
          localStorage.setItem(
            'registered-user-data',
            JSON.stringify(response.data.user)
          );
          toast.success(
            <ToastifyStyledMessage
              singleMessage={message}
              heading={t('registration:registration_success')}
            />,
            { autoClose: 6000 }
          );

          clearNameEntry();
          clearSurnameEntry();
          clearEmailEntry();
          clearPasswordEntry();
          clearConfirmPasswordEntry();
          clearPhoneData();
          setInvalidEmail(false);
          clearAddress();
        }
      } else {
        toast.error(
          <ToastifyStyledMessage
            singleMessage={t('registration:all_required_message')}
            heading={t('registration:all_required')}
          />,
          { autoClose: 4000 }
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const showPasswordConditionsBoardHandler = () => {
    setShowConditionsBoard(true);
  };

  const hidePasswordConditionsBoardHandler = () => {
    setShowConditionsBoard(false);
  };

  const allValid =
    isValidName &&
    isValidSurname &&
    isValidEmail &&
    isValidPassword &&
    isValidConfirmPassword &&
    activateButton.isValidPostalCode &&
    activateButton.isValidDivision &&
    activateButton.isValidSettlement &&
    activateButton.isValidStreetAddress &&
    activateButton.isValidSubdivision &&
    name.length >= 2 &&
    surname.length >= 2 &&
    email.length !== 0 &&
    password.length >= 6 &&
    confirmPassword.length >= 6 &&
    isValidAddressType &&
    password === confirmPassword;
  //organizationListPlaceHolderName

  return (
    <SelectionContainer
      isInQueue={pageNumber ? pageNumber < currentPageNumber : false}
      isCompleted={pageNumber ? pageNumber > currentPageNumber : false}
      isScrollable
    >
      <div className={classes.FormExplanation}>
        <p>{t('registration:registration_form_desc')}</p>
      </div>
      <SelectionForm showCheckMark={false} style={{ flexDirection: 'column' }}>
        <Form
          className={`${classes.SignUpForm} ${
            !selectedOrganization ? classes.HideForm : ''
          }`}
          onSubmit={userRegistrationHandler}
        >
          <Input
            type='text'
            name='name'
            label={t('translations:Name')}
            value={name}
            isRequired
            onChange={nameEntryHandler}
            isInvalid={!isValidName}
            ref={nameInputRef}
            autoComplete='given-name'
            disabled={isRegistrationLoading}
          />
          <Input
            type='text'
            name='surname'
            label={t('translations:Surname')}
            value={surname}
            isRequired
            onChange={surnameEntryHandler}
            isInvalid={!isValidSurname}
            ref={surnameInputRef}
            autoComplete='family-name'
            disabled={isRegistrationLoading}
          />

          <Input
            type='email'
            name='email'
            label={t('auth:email')}
            value={email}
            isRequired
            onChange={emailEntryHandler}
            isInvalid={!isValidEmail || invalidEmail}
            ref={emailInputRef}
            onBlur={checkEmailExistanceHandler}
            autoComplete='off'
            disabled={isRegistrationLoading}
          />
          <Phone
            onPhoneEntry={phoneEntryHandler}
            phoneNumber={phoneNumber}
            onCodeSelect={countryCodeSelectHandler}
            countryData={countryData}
            fetchSkipCondition={shouldSkip}
            disabled={isRegistrationLoading}
          />
          <PresentationWrapper
            isLoading={isFetchingAddressStructure}
            isError={isAddressStructureFetchError}
            error={catchError(addressStructureError)}
            message={t('registration:address_structure_fetch_error')}
          >
            <Address
              isRequired
              selectedCountry={userAddress.countryDetails}
              onAddressCountrySelect={addressCountrySelectHandler}
              addressStructure={addressStructure}
              onAddressEntry={userAddressEntryHandler}
              address={userAddress}
              onMoreAddressDetails={moreAddressDetailsHandler}
              activateMoreAddressDetails={moreAddressDetails}
              validations={isValidAddress}
              disabled={isRegistrationLoading}
              countries={countries}
              isLoading={isFetchingCountries}
              isError={isCountryFetchError}
              error={countryFetchError}
              onAddressTypeSelect={addressTypeSelectHandler}
            />
          </PresentationWrapper>

          <Input
            type='password'
            name='password'
            label={t('auth:password')}
            value={password}
            isRequired
            onChange={passwordEntryHandler}
            hasShowPasswordButton
            isInvalid={!isValidPassword}
            ref={passwordInputRef}
            showInformationBoard={showConditionsBoard}
            onFocus={showPasswordConditionsBoardHandler}
            onBlur={hidePasswordConditionsBoardHandler}
            autoComplete='new-password'
            disabled={isRegistrationLoading}
          >
            <PasswordConditions />
          </Input>
          <Input
            type='password'
            name='confirmPassword'
            label={t('registration:confirm_password')}
            isRequired
            value={confirmPassword}
            onChange={confirmPasswordEntryHandler}
            hasShowPasswordButton
            isInvalid={!isValidConfirmPassword}
            ref={confirmPasswordInputRef}
            autoComplete='new-password'
            disabled={isRegistrationLoading}
          />
          <PasswordStrengthTest password={password} />
          {allValid && (
            <PeriopsisCaptcha
              show={allValid}
              onExpired={recaptchaExpiredHandler}
              onChange={recaptchaChangeHandler}
              ref={recaptchaRef}
            />
          )}

          {!isEmailCheckLoading ? (
            <ActionContainer
              className={`${classes.FormActions} ${
                isLoading ? classes.Centered : ''
              }`}
            >
              {name && surname && email && !isRegistrationLoading ? (
                <Button type='reset' onClick={clearRegistrationFormHandler}>
                  {t('translations:clear')}
                </Button>
              ) : null}
              {!isRegistrationLoading ? (
                <Button
                  btnClass={classes.LoginButton}
                  isFake={!allValid || !isValidRecaptcha}
                  style={{
                    marginLeft: '1.2rem',
                    width: '100%',
                    marginRight: '1.2rem',
                  }}
                >
                  {capitalizeAllWords(t('auth:signup'))}
                </Button>
              ) : (
                <CenterDiv>
                  <SimpleLoading size={1.2} />
                </CenterDiv>
              )}
            </ActionContainer>
          ) : (
            <CenterDiv>
              <SimpleLoading />
            </CenterDiv>
          )}
        </Form>
      </SelectionForm>
    </SelectionContainer>
  );
};

export default RegistrationForm;
