import { useTranslation } from 'react-i18next';
import { frontendConstants } from '../../../constants/constants';
import classes from './PasswordConditions.module.scss';
import getTranslationKey from '../../../utils/getTranslationKey';

const PasswordConditions = () => {
  const passwordMessageList: readonly string[] =
    frontendConstants.passwordConditions;

  const { t } = useTranslation();

  return (
    <div className={classes.PasswordConditionsContainer}>
      <h3>{t('registration:password_conditions')}</h3>
      <ul className={classes.PasswordConditions}>
        {passwordMessageList.map((condition) => (
          <li key={condition}>
            {t(`registration:${getTranslationKey(condition)}`)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PasswordConditions;
