import { useTranslation } from 'react-i18next';
import classes from './IncidentCount.module.scss';

interface IProps {
  incidentCount: number;
  viewType?: 'plain' | 'circle';
}

const IncidentCount = ({ incidentCount, viewType }: IProps) => {
  const { t } = useTranslation();

  if (!incidentCount) {
    return null;
  }

  const text =
    incidentCount > 1 ? t('dashboard:incidents') : t('dashboard:incident');

  if (viewType === 'plain') {
    return (
      <div className={classes.PlainCountWrapper}>
        <p>
          {t('dashboard:total')} : {incidentCount} {text}
        </p>
      </div>
    );
  }

  return (
    <div className={classes.CountWrapper}>
      <div className={classes.IncidentsCount}>
        <p>{incidentCount}</p>
      </div>
      <p>{text}</p>
    </div>
  );
};

export default IncidentCount;
