import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ConfirmResult from '../../UI/ConfirmResult/ConfirmResult';
import ErrorPage from '../../pages/Error/ErrorPage';
import Loading from '../../UI/Loading/Loading';
import { frontendConstants } from '../../../constants/constants';
import useLanguage from '../../../CustomHooks/useLanguage';

const EmailConfirmed = () => {
  const [loading, setLoading] = useState(true);
  const { language } = useLanguage();
  const [validateResult, setValidateResult] = useState<{
    isSuccess: boolean;
    message: string;
    data: { id: string; name: string } | null;
  } | null>(null);
  const isValidated = useRef(false);
  const { token } = useParams();
  let name;

  useEffect(() => {
    const validateToken = async (token: string) => {
      try {
        const res = await axios.post(
          `${frontendConstants.backendHost}/api/v1/users/validate/${token}`
        );

        setValidateResult(res.data);
        setLoading(false);
      } catch (error: Error | any | unknown) {
        if (error.response.status === 404) {
          setValidateResult(error.response.data);
        } else {
          setValidateResult({
            isSuccess: false,
            message: frontendConstants[language].messages.serverError.message,
            data: null,
          });
        }

        setLoading(false);
      }
    };

    if (!isValidated.current && token) {
      validateToken(token);
    }
  }, [token, language]);

  /**
   * @TODO : If there is a problem with the token @ the url, it should return false and
   * the confirmResult should render the false. To do so, we will need to have token confirmation
   * endpoint (api) and it should check the token @url before rendering a result
   */

  if (loading) {
    return (
      <Loading
        text={frontendConstants[language].labels.loading}
        showBackDrop={true}
      />
    );
  }

  if (!validateResult?.isSuccess) {
    return <ErrorPage />;
  }

  if (validateResult && validateResult !== null) {
    name = validateResult.data?.name;
  } else {
    name = 'Guest';
  }

  return (
    <ConfirmResult
      isSuccess={validateResult.isSuccess}
      message={`${frontendConstants[language].labels.dear} ${name}, ${frontendConstants[language].descriptions.youHaveSuccessfullyVerifiedAccountEmail}`}
      timer={8000}
    />
  );
};

export default EmailConfirmed;
