import { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useGetAdminUserContractsQuery,
  useGetUsersOfContractQuery,
} from '../../../../features/userAdmin/userAdminSlice';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import { Status } from '../../../../tsTypes/enums';
import SelectedContractSummary from '../Contracts/SelectedContractSummary/SelectedContractSummary';
import {
  IContractUser,
  IOrganizationUser,
} from '../../../../tsTypes/interfaces';
import { confirmAlert } from 'react-confirm-alert';
import getActionMessage from '../../../../utils/ContractUsersUtils/getActionMessage';
import HeadingBar from '../../../UI/HeadingBar/HeadingBar';
import { IoClose } from 'react-icons/io5';
import OrganizationUsersList from './OrganizationUsersList/OrganizationUsersList';
import AddNewUserSection from './AddNewUserSection/AddNewUserSection';
import AddNewUserForm from './AddNewUserForm/AddNewUserForm';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import classes from './ContractUsers.module.scss';
import AdminPageContainer from '../AdminPageContainer/AdminPageContainer';
import CurrentUsersListUpdated from './CurrentUsersListUpdated/CurrentUsersListUpdated';
import { useTranslation } from 'react-i18next';

const ContractUsers = () => {
  const [pageQuery] = useSearchParams();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [manageUsers, setManageUsers] = useState(false);
  const [addNewUser, setAddNewUser] = useState(false);
  const [contractUsers, setContractUsers] = useState<IContractUser[]>([]);
  const [organizationUsers, setOrganizationUsers] = useState<
    IOrganizationUser[]
  >([]);
  const [remainingUsers, setRemainingUsers] = useState(0);

  const contractId = pageQuery.get('contract') || '';
  const page = pageQuery.get('page') || '';

  const {
    isLoading: isLoadingContracts,
    isError: isContractsFetchError,
    data: Contracts,
    isSuccess,
  } = useGetAdminUserContractsQuery('');

  const currentContract =
    Contracts !== undefined &&
    isSuccess &&
    typeof Contracts === 'object' &&
    'contracts' in Contracts &&
    Contracts.contracts &&
    Array.isArray(Contracts.contracts) &&
    Contracts.contracts.length > 0
      ? Contracts.contracts.find(
          (contract) => contract.contractId === contractId
        )
      : '';

  const packageId =
    currentContract !== undefined &&
    currentContract !== '' &&
    typeof currentContract === 'object'
      ? currentContract.currentPackageId
      : '';

  const {
    isLoading: isLoadingUsers,
    isError: isUsersFetchError,
    data: usersOfContractData,
    isSuccess: isUsersFetchSuccess,
  } = useGetUsersOfContractQuery(
    { contractId: contractId, packageId: packageId },
    {
      skip:
        !contractId ||
        !page ||
        page !== 'users' ||
        isLoadingContracts ||
        isContractsFetchError ||
        packageId === '',
    }
  );

  useEffect(() => {
    const managingUsers = localStorage.getItem('manage-users');
    const addNewUser = localStorage.getItem('adding-new-user');

    if (managingUsers) {
      setManageUsers(true);
    }

    if (addNewUser) {
      setAddNewUser(true);
    }
  }, []);

  useEffect(() => {
    if (
      isUsersFetchSuccess &&
      usersOfContractData &&
      'contractUsers' in usersOfContractData &&
      usersOfContractData.contractUsers &&
      usersOfContractData.organizationUsers
    ) {
      setContractUsers(usersOfContractData.contractUsers);
      setOrganizationUsers(usersOfContractData.organizationUsers);
      setRemainingUsers(usersOfContractData.remainingUsers);
    }
    // eslint-disable-next-line
  }, [
    isUsersFetchSuccess,
    usersOfContractData?.contractUsers,
    usersOfContractData?.organizationUsers,
  ]);

  let contractUsersContent: React.ReactNode | null = null;

  if (isLoadingUsers || isLoadingContracts) {
    return <FetchingReports message={t('admin:fetching_users_of_current')} />;
  }

  if (isContractsFetchError || isUsersFetchError) {
    return <NoDataFound message={t('admin:users_fetch_error')} />;
  }

  const manageUsersHandler = () => {
    setManageUsers(true);
    localStorage.setItem('manage-users', 'true');
  };

  const closeUserManagement = () => {
    setManageUsers(false);
    localStorage.removeItem('manage-users');
  };

  const currentUsersAuthUpdateHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const uData = localStorage.getItem('uData');
    let loggedInUserId = '';
    const updatedUsers = [...contractUsers];
    const selectedAuthoriazation = e.target.id;

    const updatedUserIndex = contractUsers.findIndex(
      (user) => user.userId === e.target.value
    );

    if (uData) {
      const userData = JSON.parse(uData);
      loggedInUserId = userData.id;
    }

    if (selectedAuthoriazation === 'action-auth' && updatedUserIndex >= 0) {
      updatedUsers[updatedUserIndex] = {
        ...updatedUsers[updatedUserIndex],
        authorizations: {
          hasAuthtoAdmin:
            updatedUsers[updatedUserIndex].authorizations.hasAuthtoAdmin,
          hasAuthToActions:
            !updatedUsers[updatedUserIndex].authorizations.hasAuthToActions,
        },
      };
    }

    if (selectedAuthoriazation === 'admin-auth' && updatedUserIndex >= 0) {
      updatedUsers[updatedUserIndex] = {
        ...updatedUsers[updatedUserIndex],
        authorizations: {
          hasAuthtoAdmin:
            !updatedUsers[updatedUserIndex].authorizations.hasAuthtoAdmin,
          hasAuthToActions:
            updatedUsers[updatedUserIndex].authorizations.hasAuthToActions,
        },
      };
    }

    const adminUsers = contractUsers.map(
      (user) => user.authorizations.hasAuthtoAdmin
    );

    const onlyActionsUser = contractUsers.map(
      (user) => user.authorizations.hasAuthToActions
    );

    const isOnlyAdminUser = adminUsers.length === 1;
    const isOnlyActionUser = onlyActionsUser.length === 1;
    const isOnlyUser = contractUsers.length === 1;

    const updatedUser = updatedUsers[updatedUserIndex];
    // const updatedUserId = updatedUsers[updatedUserIndex].userId;

    const messageAction = getActionMessage({
      user: updatedUser,
      currentUserId: loggedInUserId,
      isActionAuthProcess: true,
      isAdminAuthProcess: false,
      isOnlyActionUser,
      isOnlyAdminUser,
      isOnlyUser,
    });

    const messageAdmin = getActionMessage({
      user: updatedUser,
      currentUserId: loggedInUserId,
      isActionAuthProcess: false,
      isAdminAuthProcess: true,
      isOnlyActionUser,
      isOnlyAdminUser,
      isOnlyUser,
    });

    if (
      selectedAuthoriazation === 'action-auth' &&
      !updatedUser.authorizations.hasAuthToActions
    ) {
      return confirmAlert({
        title: t('admin:action_authorization_warning'),
        message: messageAction.message,
        buttons: messageAction.isAllowed
          ? [
              {
                label: t('admin:proceed'),
                onClick: () => setContractUsers(updatedUsers),
              },
              { label: t('translations:cancel') },
            ]
          : [{ label: t('admin:ok') }],
      });
    }

    if (
      selectedAuthoriazation === 'admin-auth' &&
      !updatedUser.authorizations.hasAuthtoAdmin
    ) {
      return confirmAlert({
        title: t('admin:admin_authorization_warning'),
        message: messageAdmin.message,
        buttons: messageAdmin.isAllowed
          ? [
              {
                label: t('admin:proceed'),
                onClick: () => setContractUsers(updatedUsers),
              },
              { label: t('translations:cancel') },
            ]
          : [{ label: t('admin:ok') }],
      });
    }

    setContractUsers(updatedUsers);
  };

  const removeUserHandler = (userId: string) => {
    const updatedOrganizationUsers = organizationUsers.map((user) => {
      if (user.user.userId === userId) {
        return { ...user, isUserOfContract: false };
      }

      return user;
    });

    setOrganizationUsers(updatedOrganizationUsers);

    const updatedContractUsers = contractUsers.filter(
      (user) => user.userId !== userId
    );

    setContractUsers(updatedContractUsers);
    setRemainingUsers((prevState) => prevState + 1);
  };

  const blockUserHandler = async (userId: string) => {
    const updateContractUsers = contractUsers.map((user) => {
      if (user.userId === userId) {
        return { ...user, isBlockedUser: true };
      }

      return user;
    });

    setContractUsers(updateContractUsers);
  };

  const unblockUserHandler = async (userId: string) => {
    const updateContractUsers = contractUsers.map((user) => {
      if (user.userId === userId) {
        return { ...user, isBlockedUser: false };
      }

      return user;
    });

    setContractUsers(updateContractUsers);
  };

  const showAddNewUserFormHandler = () => {
    localStorage.setItem('adding-new-user', 'true');
    setAddNewUser(true);
  };

  const closeNewUserFormHandler = () => {
    localStorage.removeItem('adding-new-user');
    setAddNewUser(false);
  };

  const selectUserForContractAssignmentHandler = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const selectedUserId = e.target.value;

    const selectedUser = organizationUsers.find(
      (user) => user.user.userId === selectedUserId
    );

    if (selectedUser && usersOfContractData) {
      const limitMessage =
        language === 'en'
          ? `You've hit the maximum user limit for this contract package (${usersOfContractData.userLimit}). Unfortunately, you can't add more users until you remove one. To add ${selectedUser.user.name} ${selectedUser.user.surname}, please remove an existing user from the contract. If you need assistance, contact our support team.`
          : language === 'el'
          ? `Έχετε φτάσει το μέγιστο όριο χρηστών για αυτό το πακέτο σύμβασης (${usersOfContractData.userLimit}). Δυστυχώς, δεν μπορείτε να προσθέσετε περισσότερους χρήστες μέχρι να αφαιρέσετε έναν. Για να προσθέσετε τον/την ${selectedUser.user.name} ${selectedUser.user.surname}, παρακαλούμε αφαιρέστε έναν υπάρχοντα χρήστη από τη σύμβαση. Αν χρειάζεστε βοήθεια, επικοινωνήστε με την ομάδα υποστήριξής μας.`
          : `You've hit the maximum user limit for this contract package (${usersOfContractData.userLimit}). Unfortunately, you can't add more users until you remove one. To add ${selectedUser.user.name} ${selectedUser.user.surname}, please remove an existing user from the contract. If you need assistance, contact our support team.`;
      if (remainingUsers === 0) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('admin:limit_reached')}
            singleMessage={limitMessage}
          />
        );
      }

      const newContractUser: IContractUser = {
        name: selectedUser.user.name,
        surname: selectedUser.user.surname,
        userId: selectedUser.user.userId,
        emailAddress: selectedUser.user.emailAddress,
        authorizations: { hasAuthToActions: false, hasAuthtoAdmin: false },
        isBlockedUser: false,
      };

      setContractUsers((prevState) => [...prevState, newContractUser]);

      const updatedOrganizationUsers = organizationUsers.map((user) => {
        if (user.user.userId === selectedUserId) {
          return { ...user, isUserOfContract: true };
        }

        return user;
      });

      setOrganizationUsers(updatedOrganizationUsers);
      setRemainingUsers((prevState) => prevState - 1);
    }
  };

  if (
    usersOfContractData &&
    typeof usersOfContractData === 'object' &&
    'contractUsers' in usersOfContractData &&
    Array.isArray(usersOfContractData.contractUsers) &&
    usersOfContractData.contractUsers.length > 0
  ) {
    let loggedInUserId = '';

    const uData = localStorage.getItem('uData');

    if (uData) {
      const userData = JSON.parse(uData);
      loggedInUserId = userData.id;
    }

    const selectedPackageMessage =
      language === 'en'
        ? `The selected package for the current contract has a limit of
          ${usersOfContractData.userLimit} users, and currently, 
          ${contractUsers.length} users have been added to the contract. You may
          add an additional ${remainingUsers} users to the contract with
          different authorizations.`
        : language === 'el'
        ? `Το επιλεγμένο πακέτο για την τρέχουσα σύμβαση έχει όριο ${usersOfContractData.userLimit} χρηστών, και αυτή τη στιγμή, ${contractUsers.length} χρήστες έχουν προστεθεί στη σύμβαση. Μπορείτε να προσθέσετε επιπλέον ${remainingUsers} χρήστες στη σύμβαση με διαφορετικές εξουσιοδοτήσεις.`
        : `The selected package for the current contract has a limit of
          ${usersOfContractData.userLimit} users, and currently,
          ${contractUsers.length} users have been added to the contract. You may
          add an additional ${remainingUsers} users to the contract with
          different authorizations.`;

    contractUsersContent = (
      <div className={classes.CurrentUsersWrapper}>
        <p>{selectedPackageMessage}</p>

        <CurrentUsersListUpdated
          contractUsers={contractUsers}
          currentUserId={loggedInUserId}
          onUpdate={currentUsersAuthUpdateHandler}
          storedContractUsers={usersOfContractData.contractUsers}
          onRemove={removeUserHandler}
          onBlock={blockUserHandler}
          onUnblock={unblockUserHandler}
          hasMessage
          remainingUsers={remainingUsers}
          userLimit={usersOfContractData.userLimit}
        />

        <ActionContainer className={classes.UserManagementAction}>
          <StandardButton
            label={t('admin:manage_users')}
            btnType='orange'
            type='button'
            onClick={manageUsersHandler}
          />
        </ActionContainer>
      </div>
    );
  }

  if (
    usersOfContractData &&
    typeof usersOfContractData === 'object' &&
    'contractUsers' in usersOfContractData &&
    Array.isArray(usersOfContractData.contractUsers) &&
    manageUsers &&
    'organizationUsers' in usersOfContractData &&
    usersOfContractData.organizationUsers &&
    Contracts &&
    typeof Contracts === 'object' &&
    'contracts' in Contracts &&
    Contracts.contracts &&
    Array.isArray(Contracts.contracts) &&
    Contracts.contracts.length > 0 &&
    contractId
  ) {
    const contracts = Contracts.contracts;
    const currentContract = contracts.find(
      (contract) => contract.contractId === contractId
    );

    if (!currentContract) {
      contractUsersContent = (
        <div>
          <NoDataFound message={t('admin:contract_initialization_error')} />
          <ActionContainer className={classes.UserManagementAction}>
            <StandardButton
              label={t('translations:Close')}
              btnType='orange'
              type='button'
              onClick={closeUserManagement}
            />
          </ActionContainer>
        </div>
      );
    } else if (
      (currentContract && currentContract.status !== Status.Paid) ||
      (currentContract && currentContract.expired)
    ) {
      const contractStatusMessage =
        language === 'en'
          ? `Your contract is currently in a ${currentContract.status} status. To add or remove users from the contract, the contract must be in a "Paid" status.`
          : `Το συμβόλαιό σας είναι αυτή τη στιγμή σε κατάσταση ${currentContract.status}. Για να προσθέσετε ή να αφαιρέσετε χρήστες από το συμβόλαιο, το συμβόλαιο πρέπει να είναι σε κατάσταση "Πληρωμένο".`;

      const message =
        currentContract.status !== Status.Paid
          ? contractStatusMessage
          : t('admin:contract_expired');

      contractUsersContent = (
        <div>
          <NoDataFound message={message} />
          <ActionContainer className={classes.UserManagementAction}>
            <StandardButton
              label={t('translations:Close')}
              btnType='orange'
              type='button'
              onClick={closeUserManagement}
            />
          </ActionContainer>
        </div>
      );
    } else {
      const currentPackageId = currentContract.currentPackageId;
      const currentPackage = currentContract.contractPackagesData.find(
        (pack) => pack.id === currentPackageId
      );

      let loggedInUserId = '';

      const uData = localStorage.getItem('uData');

      if (uData) {
        const userData = JSON.parse(uData);
        loggedInUserId = userData.id;
      }

      contractUsersContent = (
        <div className={classes.ManageUsersWrapper}>
          <HeadingBar
            heading={
              addNewUser ? t('admin:add_new_user') : t('admin:manage_users')
            }
            headingSize={2}
            headingType={addNewUser ? 'primary' : 'orange'}
            hasButton
            buttonIcon={<IoClose />}
            onClick={addNewUser ? closeNewUserFormHandler : closeUserManagement}
          />
          {!addNewUser ? (
            <div className={classes.ContractSummary}>
              <SelectedContractSummary
                selectedContract={currentContract}
                currentPackage={currentPackage}
                remainingUsers={remainingUsers}
              />
            </div>
          ) : null}

          <CurrentUsersListUpdated
            contractUsers={contractUsers}
            currentUserId={loggedInUserId}
            onUpdate={currentUsersAuthUpdateHandler}
            storedContractUsers={usersOfContractData.contractUsers}
            onRemove={removeUserHandler}
            onBlock={blockUserHandler}
            onUnblock={unblockUserHandler}
            isUpdateable
            remainingUsers={remainingUsers}
            userLimit={usersOfContractData.userLimit}
          />

          <OrganizationUsersList
            users={organizationUsers}
            onSelect={selectUserForContractAssignmentHandler}
          />

          <AddNewUserSection
            onAdd={showAddNewUserFormHandler}
            isAdding={addNewUser}
          />

          {addNewUser ? <AddNewUserForm /> : null}

          <ActionContainer className={classes.UserManagementAction}>
            <StandardButton
              label={t('translations:Close')}
              btnType={addNewUser ? 'primary' : 'orange'}
              type='button'
              onClick={
                addNewUser ? closeNewUserFormHandler : closeUserManagement
              }
            />
          </ActionContainer>
        </div>
      );
    }
  }

  return <AdminPageContainer>{contractUsersContent}</AdminPageContainer>;
};

export default ContractUsers;
