import React from 'react';
import { View } from '@react-pdf/renderer';
import { ulStyles } from './UL.style';

interface IProps {
  children: React.ReactNode;
}

const UL = ({ children }: IProps) => {
  return <View style={ulStyles.listContainer}>{children}</View>;
};

export default UL;
