import React, { useEffect, useState } from 'react';
import { FiLayers } from 'react-icons/fi';
import RadioButton from '../../UI/FormElements/RadioButton/RadioButton';

import classes from './MapStyleLayer.module.scss';
import { Maps } from '../../../tsTypes/enums';
import { useTranslation } from 'react-i18next';

interface IProps {
  onMapSelect: (map: Maps) => void;
  selected: Maps;
}

const MapStyleLayer = ({ onMapSelect, selected }: IProps) => {
  const [showSelectOptions, setShowSelectOptions] = useState(false);
  const [street, setStreet] = useState(false);
  const [satellite, setSatellite] = useState(false);
  const [navigation, setNavigation] = useState(true);
  const streetMap = Maps.streetMap;
  const satelliteMap = Maps.satelliteMap;
  const navigationMap = Maps.navigationMap;
  const { t } = useTranslation();

  useEffect(() => {
    switch (selected) {
      case Maps.navigationMap:
        setNavigation(true);
        setSatellite(false);
        setStreet(false);

        break;

      case Maps.satelliteMap:
        setNavigation(false);
        setSatellite(true);
        setStreet(false);
        break;

      case Maps.streetMap:
        setNavigation(false);
        setSatellite(false);
        setStreet(true);

        break;

      default:
        setNavigation(true);
        setSatellite(false);
        setStreet(false);
        break;
    }
  }, [selected]);

  const selectOptionsHandler = () => {
    setShowSelectOptions((prevState) => !prevState);
  };

  const mapChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedMap = e.target.value;

    switch (selectedMap) {
      case 'street':
        setStreet(true);
        setSatellite(false);
        setNavigation(false);
        onMapSelect(streetMap);

        break;

      case 'satellite':
        setStreet(false);
        setSatellite(true);
        setNavigation(false);
        onMapSelect(satelliteMap);
        break;

      case 'navigation':
        setStreet(false);
        setSatellite(false);
        setNavigation(true);
        onMapSelect(navigationMap);
        break;

      default:
        setStreet(true);
        setSatellite(false);
        setNavigation(false);
        onMapSelect(streetMap);
        break;
    }
  };

  return (
    <div className={classes.MapStyleLayers}>
      <button
        className={classes.MapStyleLayer_ControlButton}
        onClick={selectOptionsHandler}
      >
        <FiLayers />
      </button>
      <div
        className={`${classes.ControlButton_LayerOptionsContainer} ${
          showSelectOptions ? classes.Show : ''
        }`}
      >
        <RadioButton
          name='map-layer'
          label={t('translations:Street')}
          title='Street Map'
          id='street'
          value='street'
          onChange={mapChangeHandler}
          checked={street}
        />
        <RadioButton
          name='map-layer'
          label='Satellite'
          title='Satellite Map'
          id='satellite'
          value='satellite'
          onChange={mapChangeHandler}
          checked={satellite}
        />
        <RadioButton
          name='map-layer'
          label='Navigation'
          title='Navigation Map'
          id='navigation'
          value='navigation'
          onChange={mapChangeHandler}
          checked={navigation}
        />
      </div>
    </div>
  );
};

export default MapStyleLayer;
