import { Text, View } from '@react-pdf/renderer';
import { bulletStyles } from './CircleBullet.style';

interface IProps {
  color?: string;
  size?: number;
}

const CircleBullet = ({ color, size }: IProps) => {
  return (
    <View
      style={{
        ...bulletStyles.circleBullet,
        backgroundColor: color ? color : '#004c6c',
        height: size ? size : 4,
        width: size ? size : 4,
        borderRadius: size ? size / 2 : 2,
      }}
    >
      <Text style={bulletStyles.bulletText}>•</Text>
    </View>
  );
};

export default CircleBullet;
