import { useTranslation } from 'react-i18next';
import classes from './ProcessingPayment.module.scss';

interface IProps {
  small?: boolean;
}

const ProcessingPayment = ({ small }: IProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${classes.PaymentProcessingContainer} ${
        small ? classes.Small : ''
      }`}
    >
      <div className={`${classes.Processing} ${small ? classes.Small : ''}`}>
        <span>€</span>
      </div>
      {!small ? (
        <div className={classes.PleaseWaitContainer}>
          <h3>{t('checkout:please_wait')}</h3>
          <div className={classes.FirstDot}></div>
          <div className={classes.SecondDot}></div>
          <div className={classes.ThirdDot}></div>
        </div>
      ) : null}
    </div>
  );
};

export default ProcessingPayment;
