// import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { periopsisApi } from '../api/apiSlice';
import getHeaders from '../../utils/getHeaders';
import { IResponseData } from '../../tsTypes/types.model';
import {
  IExistingReport,
  IMethodology,
  IQuestion,
  IUData,
} from '../../tsTypes/interfaces';

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getSendReportsOfUser: builder.query({
      query: (requestData: {
        contractId: string;
        filter: 'archive' | 'send' | 'count';
      }) => {
        const { contractId, filter } = requestData;
        const headers = getHeaders();

        return {
          url: `/report/${contractId}?filter=${filter}`,
          method: 'GET',
          headers,
        };
      },

      providesTags: ['send-reports'],
    }),
    getReportById: builder.query({
      query: (requestData: { reportId: string; contractId: string }) => {
        const headers = getHeaders();
        const { reportId, contractId } = requestData;

        return {
          url: `/report/selected?id=${reportId}&contractId=${contractId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          report: IExistingReport;
          uData: IUData;
          pToken: string;
        }>
      ) => {
        const report = responseData.data.report;
        const uData = responseData.data.uData;
        const pToken = responseData.data.pToken;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return report;
      },
      providesTags: ['selected-report'],
    }),
    updateReportArchiveStatus: builder.mutation({
      query: (requestData: {
        reportId: string;
        contractId: string;
        process: 'move-to-archive' | 'restore-from-archive';
      }) => {
        const { reportId, contractId, process } = requestData;
        const headers = getHeaders();

        return {
          url: `/report/selected?id=${reportId}&contractId=${contractId}&process=${process}`,
          method: 'PATCH',
          headers,
        };
      },
      invalidatesTags: ['send-reports', 'selected-report'],
    }),
    deleteReport: builder.mutation({
      query: (requestData: { reportId: string; contractId: string }) => {
        const { reportId, contractId } = requestData;
        const headers = getHeaders();

        return {
          url: `/report/selected?id=${reportId}&contractId=${contractId}`,
          method: 'DELETE',
          headers,
        };
      },
      invalidatesTags: ['send-reports', 'selected-report'],
    }),
    getMethodologies: builder.query({
      query: () => {
        const headers = getHeaders();

        return {
          url: '/report/methodologies',
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{ methodologies: IMethodology[] }>
      ) => {
        const methodologies = responseData.data.methodologies;

        return methodologies;
      },
    }),
    getCleanReportQuestions: builder.query({
      query: () => {
        const headers = getHeaders();

        return {
          url: '/report/clean-questions',
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{ questions: IQuestion[] }>
      ) => {
        const questions = responseData.data.questions;

        return questions;
      },
    }),
  }),
});

export const {
  useGetSendReportsOfUserQuery,
  useGetReportByIdQuery,
  useUpdateReportArchiveStatusMutation,
  useDeleteReportMutation,
  useGetMethodologiesQuery,
  useGetCleanReportQuestionsQuery,
} = extendedApiSlice;

//Incident Page:
//http://localhost:3000/incident/[gridId]?latestIncident=[gridObjectId]&contractId=[contractId]
