import { useState, useEffect, useCallback } from 'react';
import { capitalizeAllWords } from '../utils/componentUtilFns';
import DOMPurify from 'dompurify';

const useFormEntry = (
  validation: (value: string) => boolean,

  localStorageName?: string,
  storagePrefix?: string,
  shouldCapitalizeEntry?: boolean,
  allUppercase?: boolean
) => {
  const [value, setValue] = useState('');
  const [hasEntry, setHasEntery] = useState(false);
  const isValid = hasEntry && validation ? validation(value) : true;
  const prefix = storagePrefix ? storagePrefix : 'reg';

  useEffect(() => {
    if (localStorageName) {
      const storedValue = localStorage.getItem(
        `periopsis-${prefix}-${localStorageName}`
      );

      if (storedValue) {
        setValue(DOMPurify.sanitize(storedValue));
      }
    }
  }, [localStorageName, prefix]);

  useEffect(() => {
    if (localStorageName && value) {
      const purifiedValue = DOMPurify.sanitize(value);
      localStorage.setItem(
        `periopsis-${prefix}-${localStorageName}`,
        purifiedValue
      );
    }
  }, [localStorageName, value, prefix]);

  const entryHandler = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const val = e.target.value;
      const purifiedValue = DOMPurify.sanitize(val);

      if (shouldCapitalizeEntry) {
        setValue(capitalizeAllWords(purifiedValue));
      } else if (allUppercase) {
        setValue(purifiedValue.toUpperCase());
      } else {
        setValue(purifiedValue);
      }

      if (value && value.length > 1 && !hasEntry) {
        setHasEntery(true);
      }

      if (purifiedValue.length === 0) {
        localStorage.removeItem(`periopsis-${prefix}-${localStorageName}`);
      }
    },
    [
      value,
      hasEntry,
      prefix,
      localStorageName,
      shouldCapitalizeEntry,
      allUppercase,
    ]
  );

  const clearEntry = useCallback(() => {
    setValue('');
    setHasEntery(false);
    localStorage.removeItem(`periopsis-${prefix}-${localStorageName}`);
  }, [prefix, localStorageName]);

  return { value, isValid, entryHandler, clearEntry, setter: setValue };
};

export default useFormEntry;
