import { useSelector } from 'react-redux';
import { selectAllUIValues } from '../../../features/UI/uiSlice';
import classes from './MainWindow.module.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const MainWindow = ({ children, style }: IProps) => {
  const { isLoginPage } = useSelector(selectAllUIValues);

  return (
    <main
      className={isLoginPage ? '' : classes.MainWindow}
      style={{ ...style }}
    >
      {children}
    </main>
  );
};

export default MainWindow;
