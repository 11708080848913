import React from 'react';
import classes from './NoIncidentMessageContainer.module.scss';

interface IProps {
  children?: React.ReactNode;
}

const NoIncidentMessageContainer = ({ children }: IProps) => {
  return <div className={classes.NoIncidentMessage}>{children}</div>;
};

export default NoIncidentMessageContainer;
