import { IAreaOfInterest, itemWithPrice } from '../tsTypes/types.components';
import { IPackageSubService } from '../tsTypes/types.model';
import getTotalPolygonPrice from './getTotalPolygonPrice';

export const invoiceCalculator = (invoiceData: {
  selectedPackage: itemWithPrice | null;
  subservices: IPackageSubService[];
  selectedPolygons: IAreaOfInterest[];
  discount: number;
}) => {
  const { selectedPackage, subservices, selectedPolygons, discount } =
    invoiceData;

  let totalPrice = 0;
  let discountAmount = 0;
  let totalVat = 0;
  let overallTotal = 0;
  let totalAreaOfPolygons = 0; //total area in square kilometers
  let totalPolygonPrice = 0;

  if (
    selectedPolygons &&
    Array.isArray(selectedPolygons) &&
    selectedPolygons.length > 0
  ) {
    totalAreaOfPolygons = selectedPolygons.reduce(
      (total, polygon) => total + polygon.area,
      0
    );
  }

  if (selectedPackage) {
    const monthlyPackagePrice = selectedPackage.price; //price per sqm per month;
    const vatRate = selectedPackage.vatRate;

    totalPolygonPrice = Number(
      getTotalPolygonPrice(totalAreaOfPolygons, monthlyPackagePrice)
    );

    let totalSubservicePrice: number = 0;

    if (subservices && Array.isArray(subservices) && subservices.length > 0) {
      totalSubservicePrice = subservices.reduce(
        (total, subservice) => total + subservice.subserviceMonthlyPrice,
        0
      );
    }

    totalPrice = totalSubservicePrice + totalPolygonPrice;
    discountAmount = (totalPrice * discount) / 100;
    totalVat = ((totalPrice - discountAmount) * vatRate) / 100;
    overallTotal = totalPrice - discountAmount + totalVat;
  }

  const decimalFixedTo = 2;

  return {
    totalArea: totalAreaOfPolygons.toFixed(decimalFixedTo),
    totalPrice: totalPrice.toFixed(decimalFixedTo),
    discountAmount: discountAmount.toFixed(decimalFixedTo),
    totalVat: totalVat.toFixed(decimalFixedTo),
    overallTotal: overallTotal.toFixed(decimalFixedTo),
    discountRate: discount,
    totalAreaPrice: totalPolygonPrice,
  };
};
