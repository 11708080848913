import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants, styleConstants } from '../style.constants';

export const sideHeadingStyles = StyleSheet.create({
  SideHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1mm',
    width: styleConstants.rightFullWidth,
    fontSize: '5mm',
    paddingBottom: '1mm',
    color: colorConstants.periopsisMainColor,
    borderBottom: `1pt solid ${colorConstants.periopsisMainColor}`,
  },
});
