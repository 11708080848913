import { ICheckoutUserData } from '../../../tsTypes/checkout.interfaces';
import PageLayout from '../UI/PageLayout/PageLayout';
import { Document, View, Text } from '@react-pdf/renderer';
import SideHeading from '../UI/SideHeading/SideHeading';
import Paragraph from '../UI/Paragraph/Paragraph';
import DataContainer from '../UI/DataContainer/DataContainer';
import { RegistrationSuccessPageStyle } from './RegistrationSuccessPDF.style';

interface IProps {
  user: ICheckoutUserData;
}

const RegistrationSuccessPDF = ({ user }: IProps) => {
  if (!user) {
    return (
      <Document>
        <PageLayout name='No Package'>
          <View>
            <Text>Oops! It looks like we couldn't find any user data.</Text>
          </View>
        </PageLayout>
      </Document>
    );
  }

  const accountCreationDate = new Date(user.createdAt).toLocaleDateString(
    'en',
    {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }
  );

  const message = user.isEmailConfirmed
    ? `Congratulations! Your account was successfully created on ${accountCreationDate}. Your account details are listed below. Feel free to personalize your experience by changing your username in the user settings once you're logged into your account dashboard.`
    : `Congratulations! Your account was successfully created on ${accountCreationDate}. Your account details are listed below. Please do not forget to confirm your account by clicking the link in the email we sent to you during the registration process. You can also personalize your experience by changing your username in the user settings once you're logged into your account dashboard.`;

  return (
    <Document
      title='Registration Success'
      author='Periopsis Ltd'
      subject='Periopsis Registration Success'
      creator='Periopsis Ltd'
    >
      <PageLayout
        name={`${user.name} ${user.surname}`}
        heading={'Registration Details  '}
      >
        <View>
          <SideHeading heading='Periopsis Registration Success' />
          <Paragraph paragraph={`Dear ${user.name} ${user.surname}`} />
          <Paragraph paragraph={`${message}`} width={'131mm'} />
        </View>
        <View>
          <DataContainer label='Name' data={user.name} />
          <DataContainer label='Surname' data={user.surname} />
          <DataContainer label='Email' data={user.email} />
          <DataContainer label='Username' data={user.username} />
        </View>
        <View>
          <View style={RegistrationSuccessPageStyle.accountRecoveryText}>
            <Text>
              These are your account recovery codes. If you ever forget your
              password and also can't access your email, these codes will be
              your lifeline. Make sure to store them safely. In the event you
              lose access to your email, you'll need these codes to recover and
              secure your account.
            </Text>
          </View>

          <DataContainer label='Pin One' data={''} />
          <DataContainer label={''} data={`${user.userPins.userPinOne}`} />
          <DataContainer label='Pin Two' data={''} />
          <DataContainer label={''} data={`${user.userPins.userPinTwo}`} />
        </View>
      </PageLayout>
    </Document>
  );
};

export default RegistrationSuccessPDF;
