import { FormEvent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppDispatch } from '../../../../app/store';
import useToken from '../../../../CustomHooks/useToken';
import {
  checkUsername,
  resetAtAuth,
  selectAllAuthValues,
} from '../../../../features/auth/authSlice';
import {
  IUpdateUsername,
  IUsernameCheckQuery,
} from '../../../../tsTypes/types.components';
import { validatePassword } from '../../../../utils/componentUtilFns';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import Input from '../../../UI/FormElements/Input/Input';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import HeadingBar from '../../../UI/HeadingBar/HeadingBar';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import classes from './NewUsernameForm.module.scss';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSubmit: (formData: IUpdateUsername) => void;
  isVerificationLoading: boolean;
  shouldReset: boolean;
}

const NewUsernameForm = ({
  onSubmit,
  isVerificationLoading,
  shouldReset,
}: IProps) => {
  const { token, pToken } = useToken();
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [isUsernameAvailableToUse, setIsUsernameAvailableToUse] =
    useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { t } = useTranslation();

  const { isSuccess, isError, message, httpCode, isLoading } =
    useSelector(selectAllAuthValues);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (isError && message && httpCode && httpCode === 409) {
      toast.error(
        <ToastifyStyledMessage
          singleMessage={message}
          heading={t('profilesettings:already_exists')}
        />
      );
      setInvalidUsername(true);
      setIsClicked(false);
    }

    if (isSuccess && !isUsernameAvailableToUse) {
      setIsUsernameAvailableToUse(true);
      onSubmit({ username, password });
    }

    if (isError || isSuccess) {
      dispatch(resetAtAuth());
    }
    // eslint-disable-next-line
  }, [
    isError,
    message,
    httpCode,
    isSuccess,
    dispatch,
    isUsernameAvailableToUse,
    password,
    username,
    onSubmit,
  ]);

  useEffect(() => {
    if (shouldReset) {
      setPassword('');
      setUsername('');
      setInvalidPassword(false);
      setInvalidUsername(false);
      setIsUsernameAvailableToUse(false);
      setIsClicked(false);
    }
  }, [shouldReset]);

  const passwordEntryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const isValidPassword = validatePassword(password, 6, 25);

    if (isValidPassword) {
      setInvalidPassword(false);
    }

    setPassword(value);
  };
  const usernameEntryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const isValidUsername = username.trim().length > 3;

    if (isValidUsername) {
      setInvalidUsername(false);
    }

    setUsername(value);
  };

  const formSubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    const isValidPassword = validatePassword(password, 6, 25);
    const isValidUsername = username.trim().length >= 5;

    if (!isValidPassword && !isValidUsername) {
      setInvalidPassword(true);
      setInvalidUsername(true);
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('profilesettings:invalid_credentials_message')}
          heading={t('profilesettings:invalid_credentials')}
        />,
        { autoClose: 3000 }
      );
      return;
    }

    if (!isValidPassword) {
      setInvalidPassword(true);
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('profilesettings:invalid_password_message')}
          heading={t('profilesettings:invalid_password')}
        />,
        { autoClose: 3000 }
      );
      return;
    }

    if (!isValidUsername) {
      setInvalidUsername(true);
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('profilesettings:invalid_username_message')}
          heading={t('profilesettings:invalid_username')}
        />,
        { autoClose: 3000 }
      );
      return;
    }

    if (
      username &&
      username.trim().length >= 5 &&
      token &&
      pToken &&
      !isUsernameAvailableToUse &&
      isValidPassword &&
      isValidUsername
    ) {
      const checkUsernameExistanceData: IUsernameCheckQuery = {
        username,
        token,
        pToken,
      };

      dispatch(checkUsername(checkUsernameExistanceData));
      setIsClicked(true);
      return;
    }

    // if (isValidPassword && isValidUsername) {
    //   onSubmit({ username, password });
    // }
  };

  if (isVerificationLoading) {
    return (
      <FetchingReports
        message={t('profilesettings:sending_verification_code')}
      />
    );
  }

  /**
   * @TODO  : Complete handlers
   * @TODO  : Create an endpoint which will check if the entered user name is used or not
   * @TODO  : in case of all completed successfully, data will be send to User Component
   * @TODO  : It will again run the validation code process and hence it will open the validation form (again check if the password is correct)
   * @TODO  : Create a patch endpoint which updates username (updateUsername endpoint)
   * @TODO  : When user enters correct validation code, it will send validation code, userId, password and new username to updateUsername endpoint
   *          and after all validation processes (password validation, session validation and code validation), if all passes, the username will be updated
   */

  return (
    <div className={classes.Wrapper}>
      <HeadingBar
        heading={t('profilesettings:change_username')}
        headingSize={3}
      />

      <Form
        className={classes.UsernameFormContainer}
        onSubmit={formSubmitHandler}
      >
        <Input
          label={t('auth:password')}
          name='password'
          value={password}
          type='password'
          isRequired
          hasShowPasswordButton
          onChange={passwordEntryHandler}
          isInvalid={invalidPassword}
          maxLength={25}
          title={t('profilesettings:old_password_title')}
        />

        <Input
          label={t('profilesettings:username')}
          name='username'
          value={username}
          type='text'
          isRequired
          onChange={usernameEntryHandler}
          isInvalid={invalidUsername}
          maxLength={25}
        />
        <ActionContainer>
          <StandardButton
            type='submit'
            btnType='primary'
            label={t('profilesettings:save')}
            style={{ width: '100%' }}
            fakeButton={
              isLoading ||
              invalidPassword ||
              invalidUsername ||
              username.trim().length < 5 ||
              password.trim().length < 6 ||
              isClicked
            }
          />
        </ActionContainer>
      </Form>
    </div>
  );
};

export default NewUsernameForm;
