import classes from './FlowBarArrowStep.module.scss';

interface IProps {
  isCompleted?: boolean;
  BackArrow?: boolean;
  FrontArrow?: boolean;
  label: string;
  title?: string;
  number?: number;
}

const FlowBarArrowStep = ({
  isCompleted,
  BackArrow,
  FrontArrow,
  label,
  title,
  number,
}: IProps) => {
  return (
    <div
      className={`${classes.FlowShape} ${isCompleted ? classes.Current : ''} ${
        BackArrow ? classes.BackArrow : ''
      } ${FrontArrow ? classes.FrontArrow : ''}`}
      title={title}
    >
      <span className={classes.ArrowLabel}>{label}</span>
      {number ? <span className={classes.ArrowNumber}>{number}</span> : null}
    </div>
  );
};

export default FlowBarArrowStep;
