import { IUserAuth } from './interfaces';
import {
  AddressType,
  ICountryPhoneData,
  IIncidentReport,
  ILocation,
  item,
  selectStatisticsPage,
} from './types.components';

export enum CountryAlfa2Code {
  Afghanistan = 'AF',
  'Aland Islands' = 'AX',
  Albania = 'AL',
  Algeria = 'DZ',
  'American Samoa' = 'AS',
  Andorra = 'AD',
  Angola = 'AO',
  'Anguilla' = 'AI',
  'Antigua and Barbuda' = 'AG',
  Argentina = 'AR',
  Armenia = 'AM',
  Aruba = 'AW',
  Australia = 'AU',
  Austria = 'AT',
  Azerbaijan = 'AZ',
  Bahamas = 'BS',
  Bahrain = 'BH',
  Bangladesh = 'BD',
  Barbados = 'BB',
  Belarus = 'BY',
  Belgium = 'BE',
  Belize = 'BZ',
  Benin = 'BJ',
  Bermuda = 'BM',
  Bhutan = 'BT',
  'Bolivia (Plurinational State of)' = 'BO',
  'Bonaire, Sint Eustatius and Saba' = 'BQ',
  'Bosnia and Herzegovina' = 'BA',
  Botswana = 'BW',
  'Bouvet Island' = 'BV',
  Brazil = 'BR',
  'British Indian Ocean Territory' = 'IO',
  'Brunei Darussalam' = 'BN',
  Bulgaria = 'BG',
  'Burkina Faso' = 'BF',
  Burundi = 'BI',
  'Cabo Verde' = 'CV',
  Cambodia = 'KH',
  Cameroon = 'CM',
  Canada = 'CA',
  'Cayman Islands' = 'KY',
  'Central African Republic' = 'CF',
  Chad = 'TD',
  Chile = 'CL',
  China = 'CN',
  'Christmas Island' = 'CX',
  'Cocos (Keeling) Islands' = 'CC',
  Colombia = 'CO',
  Comoros = 'KM',
  Congo = 'CG',
  'Congo (Democratic Republic of the)' = 'CD',
  'Cook Islands' = 'CK',
  'Costa Rica' = 'CR',
  Croatia = 'HR',
  Cuba = 'CU',
  Curacao = 'CW',
  Cyprus = 'CY',
  Czechia = 'CZ',
  "Cote d'Ivoire" = 'CI',
  Denmark = 'DK',
  Djibouti = 'DJ',
  Dominica = 'DM',
  'Dominican Republic' = 'DO',
  Ecuador = 'EC',
  Egypt = 'EG',
  'El Salvador' = 'SV',
  'Equatorial Guinea' = 'GQ',
  Eritrea = 'ER',
  Estonia = 'EE',
  Ethiopia = 'ET',
  'Falkland Islands (Malvinas)' = 'FK',
  'Faroe Islands' = 'FO',
  Fiji = 'FJ',
  Finland = 'FI',
  France = 'FR',
  'French Guiana' = 'GF',
  'French Polynesia' = 'PF',
  'French Southern Territories' = 'TF',
  Gabon = 'GA',
  Gambia = 'GM',
  Georgia = 'GE',
  Germany = 'DE',
  Ghana = 'GH',
  Gibraltar = 'GI',
  Greece = 'GR',
  Greenland = 'GL',
  Grenada = 'GD',
  Guadeloupe = 'GP',
  Guam = 'GU',
  Guatemala = 'GT',
  Guernsey = 'GG',
  Guinea = 'GN',
  'Guinea-Bissau' = 'GW',
  Guyana = 'GY',
  Haiti = 'HT',
  'Heard Island and McDonald Islands' = 'HM',
  'Holy See' = 'VA',
  Honduras = 'HN',
  'Hong Kong' = 'HK',
  Hungary = 'HU',
  Iceland = 'IS',
  India = 'IN',
  Indonesia = 'ID',
  'Iran (Islamic Republic of)' = 'IR',
  Iraq = 'IQ',
  Ireland = 'IE',
  'Isle of Man' = 'IM',
  Israel = 'IL',
  Italy = 'IT',
  Jamaica = 'JM',
  Japan = 'JP',
  Jersey = 'JE',
  Jordan = 'JO',
  Kazakhstan = 'KZ',
  Kenya = 'KE',
  Kiribati = 'KI',
  "Korea (Democratic People's Republic of)" = 'KP',
  'Korea (Republic of)' = 'KR',
  Kuwait = 'KW',
  Kyrgyzstan = 'KG',
  "Lao People's Democratic Republic" = 'LA',
  Latvia = 'LV',
  Lebanon = 'LB',
  Lesotho = 'LS',
  Liberia = 'LR',
  Libya = 'LY',
  Liechtenstein = 'LI',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Macao = 'MO',
  'North Macedonia' = 'MK',
  Madagascar = 'MG',
  Malawi = 'MW',
  Malaysia = 'MY',
  Maldives = 'MV',
  Mali = 'ML',
  Malta = 'MT',
  'Marshall Islands' = 'MH',
  Martinique = 'MQ',
  Mauritania = 'MR',
  Mauritius = 'MU',
  Mayotte = 'YT',
  Mexico = 'MX',
  'Micronesia (Federated States of)' = 'FM',
  'Moldova (Republic of)' = 'MD',
  Monaco = 'MC',
  Mongolia = 'MN',
  Montenegro = 'ME',
  Montserrat = 'MS',
  Morocco = 'MA',
  Mozambique = 'MZ',
  Myanmar = 'MM',
  Namibia = 'NA',
  Nauru = 'NR',
  Nepal = 'NP',
  Netherlands = 'NL',
  'New Caledonia' = 'NC',
  'New Zealand' = 'NZ',
  Nicaragua = 'NI',
  Niger = 'NE',
  Nigeria = 'NG',
  Niue = 'NU',
  'Norfolk Island' = 'NF',
  'Northern Mariana Islands' = 'MP',
  Norway = 'NO',
  Oman = 'OM',
  Pakistan = 'PK',
  Palau = 'PW',
  'Palestine, State of' = 'PS',
  Panama = 'PA',
  'Papua New Guinea' = 'PG',
  Paraguay = 'PY',
  Peru = 'PE',
  Philippines = 'PH',
  Poland = 'PL',
  Portugal = 'PT',
  'Puerto Rico' = 'PR',
  Qatar = 'QA',
  'Réunion' = 'RE',
  Romania = 'RO',
  Russia = 'RU',
  Rwanda = 'RW',
  'Saint Barthelemy' = 'BL',
  'Saint Kitts and Nevis' = 'KN',
  'Saint Lucia' = 'LC',
  'Saint Vincent and the Grenadines' = 'VC',
  Samoa = 'WS',
  'San Marino' = 'SM',
  'Saudi Arabia' = 'SA',
  Senegal = 'SN',
  Serbia = 'RS',
  Seychelles = 'SC',
  'Sierra Leone' = 'SL',
  Singapore = 'SG',
  'Sint Maarten' = 'SX',
  Slovakia = 'SK',
  Slovenia = 'SI',
  'Solomon Islands' = 'SB',
  Somalia = 'SO',
  'South Africa' = 'ZA',
  'South Sudan' = 'SS',
  Spain = 'ES',
  'Sri Lanka' = 'LK',
  Sudan = 'SD',
  Suriname = 'SR',
  'Svalbard and Jan Mayen' = 'SJ',
  'Kingdom of Eswatini' = 'SZ',
  Sweden = 'SE',
  Switzerland = 'CH',
  Syria = 'SY',
  Taiwan = 'TW',
  Tajikistan = 'TJ',
  Tanzania = 'TZ',
  Thailand = 'TH',
  'Timor Leste' = 'TL',
  Togo = 'TG',
  Tonga = 'TO',
  'Trinidad and Tobago' = 'TT',
  Tunisia = 'TN',
  Turkey = 'TR',
  Turkmenistan = 'TM',
  'Turks and Caicos Islands' = 'TC',
  Tuvalu = 'TV',
  Uganda = 'UG',
  Ukraine = 'UA',
  'United Arab Emirates' = 'AE',
  'United Kingdom' = 'GB',
  'United States' = 'US',
  Uruguay = 'UY',
  Uzbekistan = 'UZ',
  Vanuatu = 'VU',
  Venezuela = 'VE',
  Vietnam = 'VN',
  'Virgin Islands, British' = 'VG',
  'Virgin Islands, U.S.' = 'VI',
  'Wallis and Futuna' = 'WF',
  Yemen = 'YE',
  Zambia = 'ZM',
  Zimbabwe = 'ZW',
}

export enum PostalCode {
  ZIPCode = 'ZIP Code',
  PINCode = 'PIN Code',
  PostalCode = 'Postal Code',
  CEPCode = 'CEP Code',
  CAPCode = 'CAP Code',
}

export enum Division {
  State = 'State',
  District = 'District',
  Province = 'Province',
  Region = 'Region',
  Parish = 'Parish',
  Canton = 'Canton',
  Division = 'Division',
  County = 'County',
  Department = 'Department',
  Governorate = 'Governorate',
  Commune = 'Commune',
  IslandGroup = 'Island Group',
  Island = 'Island',
  FederalSubject = 'Federal Subject',
  Atoll = 'Atoll',
  Prefacture = 'Prefacture',
  Municipality = 'Municipality',
  Neighborhood = 'Neighborhood',
  Settlement = 'Settlement',
  SubDistrict = 'Sub-District',
  Territory = 'Territory',
  Camp = 'Camp',
  Regency = 'Regency',
  Sector = 'Sector',
}

export type AddressLabel = {
  label: Division | PostalCode | string;
  name: string;
};

export interface IAddress {
  addressId?: string;
  addressType: AddressType;
  countryDetails: { country: string; countryCode: CountryAlfa2Code };
  streetAddress: AddressLabel;
  division?: AddressLabel;
  subDivision?: AddressLabel;
  postalCode?: AddressLabel;
  addressDetails?: AddressLabel;
  settlement?: AddressLabel; //This can have types like city/town, town, settlement, village and name part will be the name of city like Nicosia, Paphos etc.
  isActiveAddress?: boolean;
}

export interface IPerson {
  id: string;
  name: string;
  surname: string;
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
  registrationSuccessData?: {
    code: string;
    datetime: number;
  };
}

export interface IUser extends IPerson {
  token: any;
  guestUserId: string;
  location: {
    location: ILocation | null;
    distanceToSelectedCountryCenter?: string;
  };
  userIP?: string;
  createdAt?: string;
  lastLoggedInAt?: string;
  language?: string;
  oneTimeLoginKey?: string;
  need2FA?: boolean;
  twoFACode?: string | null;
  shouldRemindLogout?: boolean;
  userPhones: ICountryPhoneData[];
  remindLogoutDuration?: number;
  shouldSendLoginReport?: boolean;
  shouldResendAuthCode?: boolean;
  recentStatisticsPlace?: selectStatisticsPage;
  showIncidentImages?: boolean;
  address: IAddress;
  userAuthByContract: { contractId: string; authorizations: IUserAuth }[];
}

export interface IUserRegistrationData {
  id: string;
  name: string;
  surname: string;
  username: string;
  userPins: { userPinOne: string; userPinTwo: string };
  createdAt: string;
}

//const { name, surname, email, registrationPassword, password, birthday }
export interface IAdmin extends IPerson {
  registrationPassword: string;
  birthday: string;
  adminId?: string;
  linkId?: string;
  twoFACode?: string;
  shouldResendAuthCode?: boolean;
  userIp?: { ip: string } | null;
}

export interface IRegisterResponse {
  isSuccess: boolean;
  message: string;
  data: { id: string; name: string; createdAt: string } | null;
}

export interface IListObject {
  id: string;
  title: string;
  value?: string | number;
}

export type PointImage = {
  id: string;
  date: Date;
  originalImageLink: string;
  thumbnailImageLink: string;
};

export type IncidentImageData = {
  id: string;
  incidentType: string;
  incidentDate: string;
  incidentSrc: string;
};

export interface IPoint {
  _id: string;
  Point: string;
  Layer: string;
  Coordinates: ILocation;
  Date: {
    time: Date;
    timeString: string;
  };
  Type: string;
  SatelliteInfo: string;
  ImageLinks: {
    commonId: string;
    images: PointImage[];
  };
  isReported: boolean;
  cleanData: {
    isCleaned: boolean;
    startedAt: Date | null;
    cleanedAt: Date | null;
    cleanedBy: {
      teamMembers: item[];
      companyName?: string;
    };
    notes?: string;
    equipmentUsed?: item[];
    cost?: number;
    createdAt: Date;
    createdBy: string;
  };
  visitData: {
    isVisited: boolean;
    visitedBy: {
      visitors: item[];
    };
    visitedAt: Date | null;
    createdBy?: string;
    createdAt?: Date;
    notes: string;
  };
  falsePositive?: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IPointInfoSummary {
  points: string[];
  organizationId: string;
  code: string;
}

export interface IReport {
  _id: string;
  reportedBy: string;
  reportedTo: string;
  emailTo: string[] | null;
  emailCC: string[] | null;
  emailBCC: string[] | null;
  subject: string;
  description: string;
  message: string | '';
  pointId: string;
  reportDate: string;
  isArchived?: boolean;
}

export interface IReportState {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string;
  httpCode: number | null;
  reports: IOutbox[] | null;
  // reportsOfIncident: IReport[] | null;
  reportsOfIncident: IIncidentReport[] | null;

  isArchiveFetched?: boolean;
  isOutBoxFetched?: boolean;
}

export type ListArray = string[] | number[] | IListObject[];

export interface IOutbox
  extends Pick<
    IPoint,
    '_id' | 'Coordinates' | 'Date' | 'Type' | 'SatelliteInfo'
  > {
  reports: IIncidentReport[];
}

export type IAdminRole = 'DBM' | 'Full' | 'Editor' | 'Standard';

export interface INotifications {
  id: string;
  date: Date;
  hasRead: boolean;
  hasSeen: boolean;
  incidentDetails: IPoint[];
  numberOfIncidents: number;
}

export interface IIncidentType {
  dumpId: string;
  dumpName: string;
  dumpDescription?: string;
  riskLevel?: string;
  internationalCode?: string;
  typeCode: string;
  createdBy: string | null;
}

export interface IResend {
  username: string;
  password: string;
  recaptcha?: string;
}

export interface IServiceType {
  serviceTypeId: string;
  serviceType: string;
}

export interface IGetOrganizations {
  country: string | null;
  district: string | null;
  organizationType?: string | null;
}

export type Currency = '€' | '£' | '$';

export type Image = {
  imageId: string;
  imageUrl: string;
  alt: string;
  title: string;
};

export type term = {
  id: string;
  orderNumber: number;
  heading: string;
  description: string;
};

export interface IPackageSubService {
  subserviceId: string;
  subserviceName: string;
  subserviceDescription: string;
  subserviceMonthlyPrice: number;
  currency: Currency;
  termsAndConditions: term[];
  images: Image[];
  isActive: boolean;
}

export interface IPackage {
  packageId: string;
  packageName: string;
  duration: number; //30 days, 60 days, 90 days, 365 days
  durationUnit: 'days' | 'month' | 'months' | 'year' | 'years'; //Currently, we are using days, but in future versions, the duration may be converted to months or years.
  monthlyPrice: number; //the package may have option to pay in monthly basis or user may choose to pay total amount of total duration, for that case, there can be a discount for fullPayment
  priceUnit: '/sqm/month' | '/sq km/month'; //the total price will be calculated from the area of selected polygon (ex : polygon area: 55.21 sq km, package price: €0.025 /sqm/month, total price: 55.21x1000x0.025 = €1380.25 Euros )
  areaLimit: number; //this limits the total area (in sqm) for total selected polygons. We have different limit for different packages.
  areaLimitUnit: 'sqm';
  currency: Currency;
  vatRate: number;
  discounts: {
    customDiscount: number;
    discountAmountForFullPayment: number;
  }; //this will be applied to calculate totalPrice
  isActivePackage: boolean;
  serviceTypeId: string;
  packageImage: Image;
  isFeatured: boolean; //Indicate whether a package is featured or highlighted in application
  userLimit: number;
  packageDetails: {
    heading: string;
    services: string[];
    description: string; //a detailed description or notes about the package, which can be displayed to users.
    subservices: IPackageSubService[];
    termsAndConditions: term[];
  };
}

export interface IResponseData<T> {
  isSuccess: boolean;
  message: string;
  data: T;
}

export interface ICountryPhone {
  code: CountryAlfa2Code;
  name: string;
  countryCode: string;
  NSN: number; //National Significant Number
}

export interface IAddressStructure {
  hasPostalCode: boolean;
  hasDivisions: boolean; //this check if the country has districts or provinces or states or regions etc. if true, the input field will take one of the following dividedTo label otherwise ""
  divisionName: Division | '';
  hasSubDivision: boolean;
  subDivisionName: Division | '';
  postalCodeMaxLength: number;
  postCodeLabel: PostalCode | '';
  hasCityField: boolean;
  labelForCityField: string; //A country may not have city but instead town or village
}

export interface IPredefinedPolygon {
  _id: string;
  polygonId: string;
  polygonName: string;
  country: string;
  district: string;
  polygonType: string;
  isInService: boolean;
  area: number;
  areaUnit: string;
}
