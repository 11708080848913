import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import classes from './FormTransitionSection.module.scss';
import {
  changeAuthForm,
  setHideNavigation,
} from '../../../../../features/auth/authSlice';
import { useTranslation } from 'react-i18next';

interface IProps {
  onForgotPassword: () => void;
  disabled?: boolean;
  isHidden?: boolean;
}

const FormTransitionSection = ({
  onForgotPassword,
  disabled,
  isHidden,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  const formChangeHandler = () => {
    if (disabled) {
      return;
    }
    dispatch(setHideNavigation(false));
    dispatch(changeAuthForm('register'));
  };

  return (
    <Fragment>
      <div className={classes.ForgotPass}>
        <button
          className={classes.ForgotPasswordButton}
          onClick={onForgotPassword}
        >
          {t('auth:forgot_pass')}
        </button>
      </div>
      <div className={classes.Register}>
        <p>
          {t('auth:need_account')}
          <button onClick={formChangeHandler}>{t('auth:signup')}</button>
        </p>
      </div>
    </Fragment>
  );
};

export default FormTransitionSection;
