import classes from './Form.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const Form = ({ children, className, onSubmit }: IProps) => {
  const formClass = `${classes.Form} ${className ? className : ''}`;

  if (!onSubmit) {
    return <div className={formClass}>{children}</div>;
  }

  return (
    <form className={formClass} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default Form;
