import { useEffect } from 'react';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../components/UI/ToastifyStyledMessage/ToastifyStyledMessage';
import useLogout from './useLogout';

interface IProps {
  isError: boolean;
  error: unknown;
}

const useSessionLogout = ({ isError, error }: IProps) => {
  const [logout] = useLogout();

  useEffect(() => {
    if (
      isError &&
      error &&
      typeof error === 'object' &&
      'status' in error &&
      error.status === 401 &&
      'data' in error &&
      error.data &&
      typeof error.data === 'object' &&
      'isSuccess' in error.data &&
      !error.data.isSuccess &&
      'isTokenExpiry' in error.data &&
      error.data.isTokenExpiry &&
      'message' in error.data &&
      error.data.message &&
      typeof error.data.message === 'string'
    ) {
      toast.error(
        <ToastifyStyledMessage
          singleMessage={error.data.message}
          heading='Session End!'
        />,
        { autoClose: 5000 }
      );

      const timer = setTimeout(() => {
        logout();
      }, 6000);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line
  }, [isError]);
};

export default useSessionLogout;
