import { IContractIncident } from '../tsTypes/interfaces';

const getFilteredIncidents = (data: {
  status: string;
  incidents: IContractIncident[];
}) => {
  const { status, incidents } = data;

  if (!incidents || !Array.isArray(incidents) || incidents.length === 0) {
    return [];
  }

  if (status === 'cleaned') {
    return incidents.filter(
      (incident) =>
        incident.isCleaned || incident.incidentActions.clean !== null
    );
  } else if (status === 'reported') {
    return incidents.filter(
      (incident) => incident.incidentActions.report.length > 0
    );
  } else if (status === 'visited') {
    return incidents.filter((incident) => incident.incidentActions.visit);
  } else if (status === 'noaction') {
    return incidents.filter(
      (incident) =>
        (incident.isCleaned === false ||
          incident.incidentActions.clean === null) &&
        incident.incidentActions.report.length === 0 &&
        incident.incidentActions.visit === null
    );
  }

  return incidents;
};

export default getFilteredIncidents;
