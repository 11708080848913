import classes from './ReportContainer.module.scss';

interface IProps {
  children?: React.ReactNode;
}

const ReportContainer = ({ children }: IProps) => {
  return <div className={classes.ReportContainer}>{children}</div>;
};

export default ReportContainer;
