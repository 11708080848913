import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const useRecaptcha = () => {
  const [isValidRecaptcha, setIsValidRecaptcha] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const recaptchaCode = useRef<string | null>(null);

  const recaptchaChangeHandler = (captchCode: string | null) => {
    if (!captchCode) {
      return;
    }

    recaptchaCode.current = captchCode;
    setIsValidRecaptcha(true);
  };

  const recaptchaExpiredHandler = () => {
    setIsValidRecaptcha(false);
  };

  const resetRecaptcha = (shouldResetValue: boolean) => {
    setIsValidRecaptcha(false);

    if (shouldResetValue && recaptchaRef.current) {
      recaptchaRef.current.reset();

      localStorage.removeItem('_grecaptcha');
    }
  };

  return {
    isValidRecaptcha,
    recaptchaChangeHandler,
    recaptchaExpiredHandler,
    recaptchaRef,
    recaptchaCode,
    resetRecaptcha,
  };
};

export default useRecaptcha;
