import React from 'react';
import classes from './EditableRowDataContainer.module.scss';

interface IEditableRowDataContainerProps {
  rowIcon: React.ReactNode;
  label: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  optionalInfoText?: string;
  hasUndoProcess?: boolean;
}

const EditableRowDataContainer = ({
  rowIcon,
  label,
  children,
  style,
  className,
  optionalInfoText,
  hasUndoProcess,
}: IEditableRowDataContainerProps) => {
  return (
    <div className={classes.RoWMainContainer}>
      <div
        className={`${classes.EditableRowDataContainer} ${
          className ? className : ''
        } ${hasUndoProcess ? classes.Undoable : ''}`}
        style={style}
      >
        {rowIcon}
        <span>{label}</span>
        {children}
      </div>
      {optionalInfoText && (
        <p className={classes.OptionalInfoSection}>{optionalInfoText}</p>
      )}
    </div>
  );
};

export default EditableRowDataContainer;
