import { Link } from 'react-router-dom';
import logo from '../../../assets/images/HomePageImages/periopsisLogov1.png';
import classes from './Logo.module.scss';

interface IProps {
  size?: number;
  className?: string;
}

const Logo = ({ size, className }: IProps) => {
  return (
    <Link to='/' className={classes.Logo}>
      <img
        src={logo}
        alt='Periosis Excellence is our habit'
        title='Excellence is our habit'
        className={`${classes.ImageResponsive} ${className ? className : ''}`}
        style={{ maxWidth: `${size}rem` }}
      />
    </Link>
  );
};

export default Logo;
