import { View, Text } from '@react-pdf/renderer';
import { styles } from './DataContainer.style';

interface IProps {
  label: string;
  data: string | number;
}

const DataContainer = ({ label, data }: IProps) => {
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{label}</Text>
      <Text style={styles.label}>{data}</Text>
    </View>
  );
};

export default DataContainer;
