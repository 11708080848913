import timeZones from '../timezones/timeZones.json';
import { IZone } from '../tsTypes/types.components';
import { ICountryPhone } from '../tsTypes/types.model';

/**
 * Detects the user's country based on their time zone and a provided list of country phone data.
 *
 * @param {Object} data - An object containing country phone data in the form { countryPhones: ICountryPhone[] }.
 *
 * @returns {ICountryPhone|null} - An object representing the user's country information, including 'code', 'name', and 'countryCode',
 *   or null if the user's country cannot be determined.
 */
const detectUserCountry = (data: { countryPhones: ICountryPhone[] }) => {
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countryPhones = data.countryPhones;

  if (
    zone &&
    typeof zone === 'string' &&
    timeZones &&
    typeof timeZones === 'object' &&
    countryPhones
  ) {
    //Query timezone from DB, to complete the function, currently we will import timezone data from data folder, which is not a correct approach.
    //we can combine timezone data with country phones data in DB, instead of having seperate collections
    const zones: IZone = timeZones.zones;

    if (zone in zones) {
      const zoneData = zones[zone];
      const countries = zoneData.countries;
      const targetCountry =
        Array.isArray(countries) && countries.length > 0 ? countries[0] : 'CY';

      const userCountryData = countryPhones.find(
        (country) => country.code === targetCountry
      );

      if (
        userCountryData &&
        typeof userCountryData === 'object' &&
        'code' in userCountryData &&
        'name' in userCountryData &&
        'countryCode' in userCountryData &&
        userCountryData.countryCode &&
        typeof userCountryData.countryCode === 'string'
      ) {
        return userCountryData;
      }
    }
  }

  return null;
};

export default detectUserCountry;
