import classes from './Subject.module.scss';

interface IProps {
  heading: string;
  subheading?: string;
}

const Subject = ({ heading, subheading }: IProps) => {
  return (
    <div className={classes.MessageSubject}>
      <h3>{heading}</h3>
      {subheading ? <span>{subheading}</span> : null}
    </div>
  );
};

export default Subject;
