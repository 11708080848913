import classes from './TableItemContainer.module.scss';

interface IProps {
  heading: string;
  children?: React.ReactNode;
  isVisible?: boolean;
}

const TableItemContainer = ({ heading, children, isVisible }: IProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={classes.Table}>
      <h3>{heading}</h3>
      {children}
    </div>
  );
};

export default TableItemContainer;
