import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  item,
  ICountry,
  IListObjectExtended,
  IOrganization,
  ILocation,
  IProvince,
  itemWithPrice,
  IFormTypesResponse,
  IAreaOfInterest,
} from '../../tsTypes/types.components';
import { periopsisApi } from '../api/apiSlice';
import {
  CountryAlfa2Code,
  IAddressStructure,
  ICountryPhone,
  IGetOrganizations,
  IPackage,
  IPackageSubService,
  IPredefinedPolygon,
  IResponseData,
} from '../../tsTypes/types.model';
import { objectsAreEqual } from '../../utils/objectsAreEqual';

export interface IRegistrationForm {
  selectedCountry: ICountry | null;
  selectedProvince: item | null;
  selectedDistrict: item | null;
  selectedOrganization: Pick<
    IOrganization,
    'id' | 'organizationName' | 'organizationType' | 'email'
  > | null;
  organizationHasExistingUser: boolean;
  selectedOrganizationType: { id: string; title: string; value: string } | null;
  selectedServiceType: { id: string; title: string; value: string } | null;
  selectedPackage: itemWithPrice | null;
  selectedSubservices: IPackageSubService[] | [];
  areasOfInterest: IAreaOfInterest[];
  hasProvince: boolean;
  userLocation: ILocation | null;
  currentPageNumber: number;
  isInvoiceDetailsApproved: boolean;
}

const initialState: IRegistrationForm = {
  selectedCountry: null,
  selectedProvince: null,
  selectedDistrict: null,
  selectedOrganizationType: null,
  selectedOrganization: null,
  organizationHasExistingUser: false,
  selectedServiceType: null,
  selectedPackage: null,
  selectedSubservices: [],
  areasOfInterest: [],
  hasProvince: false,
  userLocation: null,
  currentPageNumber: 1,
  isInvoiceDetailsApproved: false,
};

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationTypes: builder.query({
      query: () => '/organization/types',
      transformResponse: (
        responseData: IResponseData<{
          tuid: string;
          tregt: string;
          organizationTypes: IFormTypesResponse[];
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);
        return responseData.data.organizationTypes;
      },
    }),
    getCountries: builder.query({
      query: (type: 'All' | 'Active') => {
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }
        return {
          url: `/organization/countries?type=${type}`,
          method: 'GET',
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          countries: ICountry[];
          tuid: string;
          tregt: string;
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);
        return responseData.data.countries;
      },
    }),
    getDistricts: builder.query({
      query: (selectedCountryId: string) => {
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }

        return {
          url: `/organization/countries/${selectedCountryId}`,
          method: 'GET',
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          provinces: IProvince[];
          tuid: string;
          tregt: string;
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);
        return responseData.data.provinces;
      },
    }),
    getCountryPhones: builder.query({
      query: () => '/organization/country-phones',
      transformResponse: (
        responseData: IResponseData<{
          countryPhones: ICountryPhone[];
          tuid: string;
          tregt: string;
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);
        return responseData.data.countryPhones;
      },
    }),
    getPolygons: builder.query({
      query: (requestData: IGetOrganizations) => {
        const { country, district, organizationType } = requestData;
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }

        return {
          url: `/organization?country=${country}&district=${district}&organizationType=${organizationType}`,
          method: 'GET',
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          tuid: string;
          tregt: string;
          polygons: IPredefinedPolygon[];
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);

        return {
          polygons: responseData.data.polygons,
        };
      },

      providesTags: ['Polygons'],
    }),
    sendNewOrganizationEmailVerification: builder.mutation({
      query: (requestData: {
        email: string;
        organizationName: string;
        isCommunity: boolean;
      }) => {
        const { email, organizationName, isCommunity } = requestData;
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }

        return {
          url: '/organization/organization-validation',
          method: 'POST',
          body: {
            email,
            organizationName,
            isCommunity,
          },
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          tuid: string;
          tregt: string;
          isEmailSend: boolean;
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);

        const returnVal = {
          isEmailSend: responseData.data.isEmailSend,
        };

        return returnVal;
      },
    }),
    verifyNewOrganizationEmailCode: builder.mutation({
      query: (requestData: { email: string; code: string }) => {
        const { email, code } = requestData;
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }
        return {
          url: '/organization/organization-code-verification',
          method: 'POST',
          body: {
            email,
            code,
            userId: uid,
          },
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          isValidCode: boolean;
          verificationId: string;
        }>
      ) => {
        return responseData.data;
      },
    }),
    addNewOrganization: builder.mutation({
      query: (requestData: {
        organization: IOrganization;
        verificationId: string;
        selectedPackageId: string;
      }) => {
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');
        const { organization, verificationId, selectedPackageId } = requestData;

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }
        return {
          url: '/organization',
          method: 'POST',
          body: {
            organizationData: organization,
            validationId: verificationId,
            selectedPackageId,
          },
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
    }),
    getOrganizationById: builder.query({
      query: (organizationId: string) => {
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }

        return {
          url: `/organization/selected/${organizationId}`,
          method: 'GET',
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          tuid: string;
          tregt: string;
          organization: IOrganization;
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);

        return responseData.data.organization;
      },
      providesTags: ['Organization'],
    }),
    updateOrganizationById: builder.mutation({
      query: (requestData: {
        organization: IOrganization;
        organizationId: string;
      }) => {
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');
        const { organization, organizationId } = requestData;

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }

        return {
          url: `/organization/selected/${organizationId}`,
          method: 'PATCH',
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
          body: {
            organizationData: organization,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          tuid: string;
          tregt: string;
          organization: IOrganization;
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);

        return responseData.data.organization;
      },
      invalidatesTags: ['Organization'],
    }),
    getServiceTypes: builder.query({
      query: () => {
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }

        return {
          url: '/organization/service-types',
          method: 'GET',
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          tuid: string;
          tregt: string;
          serviceTypes: IFormTypesResponse[];
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);
        return responseData.data.serviceTypes;
      },
    }),
    getServiceTypePackages: builder.query({
      query: (packageData: {
        serviceTypeId: string;
        organizationTypeId: string;
        country: CountryAlfa2Code;
      }) =>
        `/organization/service-packages?serviceTypeId=${packageData.serviceTypeId}&organizationTypeId=${packageData.organizationTypeId}&country=${packageData.country}`,
      transformResponse: (
        responseData: IResponseData<{
          packages: Omit<IPackage, 'isActivePackage' | 'serviceTypeId'>[];
        }>
      ) => {
        return responseData.data.packages;
      },
    }),
    getAddressStructure: builder.query({
      query: (countryCode: CountryAlfa2Code) => {
        let token = localStorage.getItem('tregt');
        let uid = localStorage.getItem('tuid');

        if (!token) {
          token = '';
        }

        if (!uid) {
          uid = '';
        }
        return {
          url: `/organization/address?code=${countryCode}`,
          method: 'GET',
          headers: {
            'X-Reg-Token': token,
            'X-Guest': uid,
          },
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          addressStructure: IAddressStructure;
          tregt: string;
          tuid: string;
        }>
      ) => {
        localStorage.setItem('tuid', responseData.data.tuid);
        localStorage.setItem('tregt', responseData.data.tregt);

        return responseData.data.addressStructure;
      },
    }),
  }),
});

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    nextPage: (state) => {
      localStorage.setItem(
        'currentPageNumber',
        (state.currentPageNumber + 1).toString()
      );
      state.currentPageNumber += 1;
    },
    prevPage: (state) => {
      localStorage.setItem(
        'currentPageNumber',
        (state.currentPageNumber - 1).toString()
      );
      state.currentPageNumber -= 1;
    },
    setCurrentPageNumber: (state, action) => {
      state.currentPageNumber = action.payload;
    },
    setOrganizationType: (
      state,
      action: PayloadAction<IListObjectExtended>
    ) => {
      state.selectedOrganizationType = action.payload;
    },

    setSelectedCountry: (state, action: PayloadAction<ICountry>) => {
      if (state.selectedCountry) {
        state.selectedProvince = null;
        state.selectedDistrict = null;
      }
      state.selectedCountry = action.payload;
    },

    setSelectedServiceType: (state, action) => {
      if (state.selectedServiceType) {
        state.selectedOrganization = null;
      }

      state.selectedServiceType = action.payload;
    },

    setSelectedProvince: (state, action: PayloadAction<item | null>) => {
      if (state.selectedProvince) {
        state.selectedDistrict = null;
      }
      state.selectedProvince = action.payload;
    },

    //We may need to revise it in the shape of setSelectedCountry
    setSelectedDistrict: (state, action: PayloadAction<item | null>) => {
      if (
        state.selectedDistrict &&
        action.payload &&
        !objectsAreEqual(state.selectedDistrict, action.payload)
      ) {
        state.selectedOrganization = null;
      }
      state.selectedDistrict = action.payload;
    },

    setSelectedOrganization: (
      state,
      action: PayloadAction<Pick<
        IOrganization,
        'id' | 'organizationName' | 'organizationType' | 'email'
      > | null>
    ) => {
      state.selectedOrganization = action.payload;
    },

    setOrganizationHasExistingUser: (state, action: PayloadAction<boolean>) => {
      state.organizationHasExistingUser = action.payload;
    },

    setSelectedPackageType: (
      state,
      action: PayloadAction<itemWithPrice | null>
    ) => {
      state.selectedPackage = action.payload;
    },

    setSelectedSubservices: (
      state,
      action: PayloadAction<IPackageSubService[]>
    ) => {
      state.selectedSubservices = action.payload;
    },

    setAreasOfInterestStored: (
      state,
      action: PayloadAction<IAreaOfInterest[]>
    ) => {
      state.areasOfInterest = action.payload;
    },

    setAreasOfInterest: (state, action: PayloadAction<IAreaOfInterest>) => {
      const { district, mid, province, country, area, areaUnit, polygonName } =
        action.payload;
      const isCommunity =
        state.selectedOrganizationType &&
        'title' in state.selectedOrganizationType &&
        state.selectedOrganizationType.title === 'Community'
          ? true
          : false;

      const isMunicipality =
        state.selectedOrganizationType &&
        'title' in state.selectedOrganizationType &&
        state.selectedOrganizationType.title === 'Municipality'
          ? true
          : false;

      if (isCommunity || isMunicipality) {
        state.areasOfInterest = [action.payload];
      } else {
        const mids = state.areasOfInterest.map((item) => item.mid);
        let updatedData: IAreaOfInterest[] = [];

        if (mids.includes(mid)) {
          updatedData = state.areasOfInterest.filter(
            (item) => item.mid !== mid
          ) as IAreaOfInterest[];

          state.areasOfInterest = updatedData;
        } else {
          updatedData = [
            ...state.areasOfInterest,
            { district, mid, province, country, area, areaUnit, polygonName },
          ] as IAreaOfInterest[];

          state.areasOfInterest = updatedData;
        }
      }

      localStorage.setItem(
        'areas-of-interest-stored',
        JSON.stringify(state.areasOfInterest)
      );
    },

    setProvinceExistance: (state, action) => {
      state.hasProvince = action.payload;
    },

    approveInvoiceDetails: (state, action: PayloadAction<boolean>) => {
      state.isInvoiceDetailsApproved = action.payload;
    },

    successRegistrationState: (state) => {
      state.selectedCountry = null;
      state.selectedProvince = null;
      state.selectedDistrict = null;
      state.selectedOrganizationType = null;
      state.selectedOrganization = null;
      state.hasProvince = false;
      state.userLocation = null;
    },
    setUserLocationData: (state, action) => {
      state.userLocation = action.payload;
    },

    resetRegistrationForm: (state) => {
      state.selectedCountry = null;
      state.selectedProvince = null;
      state.selectedDistrict = null;
      state.selectedOrganizationType = null;
      state.selectedOrganization = null;
      state.hasProvince = false;
      state.currentPageNumber = 1;
      state.selectedPackage = null;
      state.selectedServiceType = null;
      state.isInvoiceDetailsApproved = false;
      state.selectedSubservices = [];
      state.organizationHasExistingUser = false;
      state.areasOfInterest = [];
    },
  },
});

export const selectAllRegistrationFormValues = (state: RootState) =>
  state.registrationForm;
export const {
  nextPage,
  prevPage,
  setCurrentPageNumber,
  setOrganizationType,
  setSelectedCountry,
  setProvinceExistance,
  setSelectedProvince,
  setSelectedDistrict,
  setSelectedPackageType,
  setSelectedSubservices,
  setSelectedOrganization,
  setUserLocationData,
  setAreasOfInterest,
  setSelectedServiceType,
  approveInvoiceDetails,
  successRegistrationState,
  resetRegistrationForm,
  setOrganizationHasExistingUser,
  setAreasOfInterestStored,
} = registerSlice.actions;
export default registerSlice.reducer;

export const {
  useGetCountriesQuery,
  useGetOrganizationTypesQuery,
  useGetServiceTypesQuery,
  useGetPolygonsQuery,
  useGetDistrictsQuery,
  useGetServiceTypePackagesQuery,
  useGetCountryPhonesQuery,
  useGetAddressStructureQuery,
  useSendNewOrganizationEmailVerificationMutation,
  useVerifyNewOrganizationEmailCodeMutation,
  useAddNewOrganizationMutation,
  useGetOrganizationByIdQuery,
  useUpdateOrganizationByIdMutation,
} = extendedApiSlice;
