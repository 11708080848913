import { BsPatchCheck } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import classes from './ApprovedBadge.module.scss';

interface IProps {
  text?: string;
}

const ApprovedBadge = ({ text }: IProps) => {
  const { t } = useTranslation();

  const textToUse = text || t('auth:approved');

  return (
    <div className={classes.Approved}>
      <BsPatchCheck />
      <span>{textToUse}</span>
    </div>
  );
};

export default ApprovedBadge;
