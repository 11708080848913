import { ChangeEvent, Fragment } from 'react';
import {
  CountryAlfa2Code,
  IAddress,
  IAddressStructure,
  ICountryPhone,
  IListObject,
} from '../../../tsTypes/types.model';
import PresentationWrapper from '../PresentationWrapper/PresentationWrapper';

import { catchError } from '../../../utils/fetches';
import Input from '../FormElements/Input/Input';
import CountryDropDown from './CountryDropDown/CountryDropDown';

import CheckBox from '../FormElements/CheckBox/CheckBox';
import TextArea from '../FormElements/TextArea/TextArea';
import { capitalizeAllWords } from '../../../utils/componentUtilFns';
import DOMPurify from 'dompurify';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import Dropdown from '../FormElements/Dropdown/Dropdown';
import { frontendConstants } from '../../../constants/constants';
import { AddressType } from '../../../tsTypes/types.components';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../utils/getTranslationKey';
import classes from './Address.module.scss';

interface IProps {
  isRequired?: boolean;
  selectedCountry: { country: string; countryCode: CountryAlfa2Code };
  onAddressCountrySelect: (
    country: string,
    countryCode: CountryAlfa2Code
  ) => void;
  addressStructure?: IAddressStructure;
  onAddressEntry: (data: {
    label: string;
    name: string;
    dataInputType: string;
  }) => void;
  onMoreAddressDetails: () => void;
  onAddressTypeSelect: (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | ChangeEvent<HTMLSelectElement>
  ) => void;
  address: IAddress;
  activateMoreAddressDetails: boolean;
  validations: {
    isValidPostalCode: boolean;
    isValidStreetAddress: boolean;
    isValidDivision: boolean;
    isValidSubdivision: boolean;
    isValidSettlement: boolean;
  };
  disabled?: boolean;
  countries: ICountryPhone[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

const Address = ({
  isRequired,
  selectedCountry,
  addressStructure,
  address,
  activateMoreAddressDetails,
  onAddressCountrySelect,
  onAddressTypeSelect,
  onMoreAddressDetails,
  onAddressEntry,
  validations,
  disabled,
  countries,
  isLoading,
  isError,
  error,
}: IProps) => {
  let addressForm: React.ReactNode | null = null;

  const { t } = useTranslation();

  const {
    isValidPostalCode,
    isValidDivision,
    isValidSettlement,
    isValidSubdivision,
    isValidStreetAddress,
  } = validations;

  let division = '';
  let subDivision = '';
  let streetAddress = '';
  let postalCode = '';
  let addressDetails = '';
  let settlement = '';
  let addressType: AddressType = { id: '', name: '' };

  const addressTypeOptionList: IListObject[] =
    frontendConstants.addressTypeOptionList;

  if (isLoading || isError) {
    return (
      <PresentationWrapper
        isError={isError}
        isLoading={isLoading}
        error={catchError(error)}
        message={t('auth:countries_fetch_error')}
        loadingSize={0.72}
      >
        <Fragment></Fragment>
      </PresentationWrapper>
    );
  }

  if (!countries) {
    return null;
  }

  if (address && 'division' in address && address.division) {
    division = capitalizeAllWords(DOMPurify.sanitize(address.division.name));
  }

  if (address && 'subDivision' in address && address.subDivision) {
    subDivision = capitalizeAllWords(
      DOMPurify.sanitize(address.subDivision.name)
    );
  }

  if (address && 'streetAddress' in address && address.streetAddress) {
    streetAddress = capitalizeAllWords(
      DOMPurify.sanitize(address.streetAddress.name)
    );
  }

  if (address && 'postalCode' in address && address.postalCode) {
    postalCode = capitalizeAllWords(
      DOMPurify.sanitize(address.postalCode.name)
    );
  }

  if (address && 'settlement' in address && address.settlement) {
    settlement = capitalizeAllWords(
      DOMPurify.sanitize(address.settlement.name)
    );
  }

  if (address && 'addressDetails' in address && address.addressDetails) {
    addressDetails = capitalizeAllWords(
      DOMPurify.sanitize(address.addressDetails.name)
    );
  }

  if (address && 'addressType' in address && address.addressType) {
    addressType = {
      id: DOMPurify.sanitize(address.addressType.id),
      name: DOMPurify.sanitize(address.addressType.name),
    };
  }

  const countriesList: IListObject[] = countries.map((country) => ({
    title: country.name,
    value: country.code,
    id: country.code,
  }));

  const addressEntryHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    const dataInputType = e.target.getAttribute('data-input-type');

    if (disabled) {
      return;
    }

    if (name.trim().length >= 0 && value.trim().length >= 0 && dataInputType) {
      onAddressEntry({ label: name, name: value, dataInputType });
    }
  };

  if (
    addressStructure &&
    addressStructure.hasDivisions &&
    addressStructure.hasSubDivision
  ) {
    addressForm = (
      <Fragment>
        <div className={classes.TwoEntry}>
          <Input
            label={t(
              `auth:${getTranslationKey(addressStructure.divisionName)}`
            )}
            name={addressStructure.divisionName}
            isRequired={isRequired}
            onChange={addressEntryHandler}
            data-input-type='division'
            value={division}
            isInvalid={!isValidDivision}
            disabled={disabled}
          />
          <Input
            label={t(
              `auth:${getTranslationKey(addressStructure.subDivisionName)}`
            )}
            name={addressStructure.subDivisionName}
            isRequired={isRequired}
            onChange={addressEntryHandler}
            data-input-type='subDivision'
            value={subDivision}
            isInvalid={!isValidSubdivision}
            disabled={disabled}
          />
        </div>
        <Input
          label={t(
            `auth:${getTranslationKey(addressStructure.labelForCityField)}`
          )}
          name={addressStructure.labelForCityField}
          isRequired={isRequired}
          onChange={addressEntryHandler}
          data-input-type='settlement'
          value={settlement}
          isInvalid={!isValidSettlement}
          disabled={disabled}
        />
      </Fragment>
    );
  } else if (
    addressStructure &&
    addressStructure.hasDivisions &&
    !addressStructure.hasSubDivision &&
    addressStructure.hasCityField
  ) {
    addressForm = (
      <div className={classes.TwoEntry}>
        <Input
          label={t(`auth:${getTranslationKey(addressStructure.divisionName)}`)}
          name={addressStructure.divisionName}
          isRequired={isRequired}
          onChange={addressEntryHandler}
          data-input-type='division'
          value={division}
          isInvalid={!isValidDivision}
          disabled={disabled}
        />
        <Input
          label={t(
            `auth:${getTranslationKey(addressStructure.labelForCityField)}`
          )}
          name={addressStructure.labelForCityField}
          isRequired={isRequired}
          onChange={addressEntryHandler}
          data-input-type='settlement'
          value={settlement}
          isInvalid={!isValidSettlement}
          disabled={disabled}
        />
      </div>
    );
  } else if (
    addressStructure &&
    !addressStructure.hasDivisions &&
    !addressStructure.hasSubDivision &&
    addressStructure.hasCityField
  ) {
    addressForm = (
      <div className={classes.TwoEntry}>
        <Input
          label={t(
            `auth:${getTranslationKey(addressStructure.labelForCityField)}`
          )}
          name={addressStructure.labelForCityField}
          isRequired={isRequired}
          onChange={addressEntryHandler}
          data-input-type='settlement'
          value={settlement}
          isInvalid={!isValidSettlement}
          disabled={disabled}
        />
      </div>
    );
  }

  const translatedAdresTypes = addressTypeOptionList.map((addressType) => ({
    id: addressType.id,
    title: t(`translations:${getTranslationKey(addressType.title)}`),
    value: addressType.value,
  }));

  return (
    <div className={classes.AddressContainer}>
      <h3>{t('auth:Address_Details')}</h3>
      {selectedCountry ? (
        <p className={classes.AddressTypeSelectionNote}>
          {t('auth:Address_Details_Description')}
        </p>
      ) : null}
      <Dropdown
        optionsList={translatedAdresTypes}
        defaultValue={t('auth:Address_Type_Selection')}
        name='address-type'
        onSelect={onAddressTypeSelect}
        value={addressType.id}
        isRequired
      />
      {selectedCountry ? (
        <Fragment>
          <div
            className={`${classes.TwoEntry} ${
              addressStructure && addressStructure.hasPostalCode
                ? classes.SmallerRight
                : ''
            }`}
          >
            {addressStructure ? (
              <Input
                label={t('auth:Street_Address')}
                name='Street Address'
                isRequired={isRequired}
                onChange={addressEntryHandler}
                data-input-type='streetAddress'
                value={streetAddress}
                isInvalid={!isValidStreetAddress}
                disabled={disabled}
                autoComplete='street-address'
              />
            ) : null}
            {addressStructure && addressStructure.hasPostalCode ? (
              <Input
                label={t(
                  `auth:${getTranslationKey(addressStructure.postCodeLabel)}`
                )}
                name={addressStructure.postCodeLabel}
                isRequired={isRequired}
                onChange={addressEntryHandler}
                data-input-type='postalCode'
                value={postalCode}
                isInvalid={!isValidPostalCode}
                maxLength={addressStructure.postalCodeMaxLength}
                disabled={disabled}
                autoComplete='postal-code'
              />
            ) : null}
          </div>
          {addressForm}
        </Fragment>
      ) : null}

      <CountryDropDown
        countries={countriesList}
        onSelect={onAddressCountrySelect}
        selectedCountryData={selectedCountry}
        disabled={disabled}
      />

      {activateMoreAddressDetails ? (
        <TextArea
          onChange={addressEntryHandler}
          value={addressDetails}
          label={t('auth:Additional_Address_Details')}
          name='Address Details'
          maxLength={200}
          invalid={false}
          title={t('auth:Additional_Address_Details_title')}
          errorMessage={t('auth:Additional_Address_Details_Error')}
          dataInputType='addressDetails'
          disabled={disabled}
        />
      ) : null}
      {addressStructure ? (
        <CheckBox
          label={t('auth:Additional_Address_Details_Checkbox')}
          isSelected={activateMoreAddressDetails}
          onSelect={onMoreAddressDetails}
          className={classes.MoreAddress}
          disabled={disabled}
        />
      ) : null}
    </div>
  );
};

export default Address;
