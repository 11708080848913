import { useTranslation } from 'react-i18next';
import { IBankDetails } from '../../../../../../tsTypes/checkout.interfaces';
import DataRow from '../../../../../UI/DataRow/DataRow';
import classes from './BankDetailsItem.module.scss';

interface IProps {
  bankDetails: IBankDetails;
}

const BankDetailsItem = ({ bankDetails }: IProps) => {
  let bankImageData = {
    imageURL: '',
    imageAlt: '',
    title: '',
  };

  const { t } = useTranslation();

  if (
    !bankDetails ||
    typeof bankDetails !== 'object' ||
    !('bankId' in bankDetails)
  ) {
    return null;
  }

  if (
    bankDetails &&
    typeof bankDetails === 'object' &&
    'bankLogoImage' in bankDetails &&
    bankDetails.bankLogoImage
  ) {
    bankImageData = bankDetails.bankLogoImage;
  }

  return (
    <div className={classes.BankDetailsContainer}>
      {bankImageData.imageURL ? (
        <div className={classes.BankLogoContainer}>
          <img
            src={bankImageData.imageURL}
            alt={bankImageData.imageAlt}
            title={bankImageData.title}
          />
        </div>
      ) : null}
      <DataRow
        heading={t('checkout:bank_name')}
        data={bankDetails.bankName}
        removeSeperator
        className={classes.DataRow}
      />
      {bankDetails.bankBranch ? (
        <DataRow
          heading={t('checkout:branch_name')}
          data={bankDetails.bankBranch}
          removeSeperator
          className={classes.DataRow}
        />
      ) : null}

      {bankDetails.swiftCode ? (
        <DataRow
          heading={t('checkout:swift_code')}
          data={bankDetails.swiftCode}
          removeSeperator
          className={classes.DataRow}
        />
      ) : null}
      <DataRow
        heading={t('checkout:iban')}
        data={bankDetails.iban}
        removeSeperator
        className={classes.DataRow}
      />
      {bankDetails.bankAddress ? (
        <div className={classes.BankAddressSection}>
          <label>{t('checkout:bank_address')}</label>
          <address>{bankDetails.bankAddress}</address>
        </div>
      ) : null}

      <DataRow
        heading={t('checkout:company_name')}
        data={bankDetails.companyName}
        className={classes.DataRow}
        removeSeperator
      />
      <DataRow
        heading={t('checkout:company_phone_number')}
        data={bankDetails.companyPhoneNumber}
        className={classes.DataRow}
        removeSeperator
      />
    </div>
  );
};

export default BankDetailsItem;
