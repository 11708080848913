import { ChangeEvent, Fragment, useLayoutEffect, useState } from 'react';

import { useGetMonthlyActionStatisticsQuery } from '../../../../../features/statistics/statisticsSlice';
import FetchingReports from '../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import TableContainer from '../../../../UI/Table/TableContainer/TableContainer';
import TableRow from '../../../../UI/Table/TableRow/TableRow';
import TableItemContainer from '../TableItemContainer/TableItemContainer';
import ActionOptions from '../ActionOptions/ActionOptions';
import classes from './MonthlyActionPercentages.module.scss';
import { IActionStatProps } from '../../Statistics';
import ErrorControlComponent from '../../../../UI/ErrorControlComponent/ErrorControlComponent';

const MonthlyActionPercentages = ({
  isSelected,
  contractId,
  currentYear,
  isYearsReady,
  mainHeadings,
}: IActionStatProps) => {
  const { isLoading, isError, data, error } =
    useGetMonthlyActionStatisticsQuery(
      {
        contractId,
        year: currentYear,
      },
      { skip: !isSelected }
    );
  let content: React.ReactNode | null = null;
  const [selectedTable, setSelectedTable] = useState('visited');

  const isVisitedTable = selectedTable === 'visited';
  const isReportedTable = selectedTable === 'reported';
  const isCleanedTable = selectedTable === 'cleaned';

  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }, []);

  if (!isSelected || !isYearsReady) {
    return null;
  }

  if (isLoading) {
    content = (
      <FetchingReports message='Fetching Monhtly Statistics. Please wait...' />
    );
  }

  if (isError) {
    content = (
      <ErrorControlComponent error={error}>
        <NoDataFound message='An unexpected error occurred while retrieving monthly action statistics. We apologize for the inconvenience. Please try again later. If the issue persists, feel free to contact support for assistance.Unexpected error occured while fetching monthly action statistics. Please try again later...' />
      </ErrorControlComponent>
    );
  }

  if (
    !isError &&
    !isLoading &&
    data &&
    typeof data === 'object' &&
    'data' in data &&
    data.data &&
    typeof data.data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'chartData' in data.data &&
    data.data.chartData &&
    typeof data.data.chartData === 'object' &&
    'results' in data.data &&
    data.data.results &&
    typeof data.data.results === 'object'
  ) {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: 'Monthly Actions',
        },
      },
    };

    const visitPercent = data.data.results.visitPercentages as {
      month: string;
      percentageString: string;
    }[];

    const reportPercent = data.data.results.reportPercentages as {
      month: string;
      percentageString: string;
    }[];

    const cleanPercent = data.data.results.cleanPercentages as {
      month: string;
      percentageString: string;
    }[];

    const incidentCounts = data.data.results.incidentCounts as {
      month: string;
      total: number;
    }[];

    const tableSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedTable(e.target.value);
    };

    content = (
      <div className={classes.StatisticsWrapper}>
        <div className={`${classes.ChartSection}`}>
          <Line data={data.data.percentChart} options={options} />
        </div>

        <div className={classes.DataTableSection}>
          <ActionOptions
            onSelect={tableSelectHandler}
            selected={{ isCleanedTable, isReportedTable, isVisitedTable }}
          />
          <TableItemContainer
            heading={mainHeadings ? mainHeadings.visited : ''}
            isVisible={isVisitedTable}
          >
            <TableContainer
              headings={['Month', 'Detected Incidents', 'Visit Percentage']}
            >
              {visitPercent.map((res, i) => (
                <TableRow
                  key={res.month}
                  rowData={[
                    res.month,
                    incidentCounts[i].total.toString(),
                    res.percentageString,
                  ]}
                />
              ))}
            </TableContainer>
          </TableItemContainer>
          <TableItemContainer
            heading={mainHeadings ? mainHeadings.reported : ''}
            isVisible={isReportedTable}
          >
            <TableContainer
              headings={['Month', 'Total Detected', 'Report Percentage']}
            >
              {reportPercent.map((res, i) => (
                <TableRow
                  key={res.month}
                  rowData={[
                    res.month,
                    incidentCounts[i].total.toString(),
                    res.percentageString,
                  ]}
                />
              ))}
            </TableContainer>
          </TableItemContainer>
          <TableItemContainer
            heading={mainHeadings ? mainHeadings.cleaned : ''}
            isVisible={isCleanedTable}
          >
            <TableContainer
              headings={['Month', 'Total Detected', 'Cleaned Percentage']}
            >
              {cleanPercent.map((res, i) => (
                <TableRow
                  key={res.month}
                  rowData={[
                    res.month,
                    incidentCounts[i].total.toString(),
                    res.percentageString,
                  ]}
                />
              ))}
            </TableContainer>
          </TableItemContainer>
        </div>
      </div>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default MonthlyActionPercentages;
