import SubserviceOption from './SubserviceOption/SubserviceOption';
import classes from './Subservices.module.scss';
import {
  IPackageSubService,
  term,
} from '../../../../../../../tsTypes/types.model';
import Button from '../../../../../../UI/FormElements/Button/Button';
import { useTranslation } from 'react-i18next';

interface IProps {
  subservices: IPackageSubService[];
  onShowTerms: (terms: term[]) => void;
  onSubserviceSelect: (subservice: IPackageSubService) => void;
  selectedSubservices: IPackageSubService[];
  onSaveAndContinue: () => void;
  onShowDescription: (id: string) => void;
  descriptionId: string | null;
}

const Subservices = ({
  subservices,
  onShowTerms,
  onSubserviceSelect,
  onShowDescription,
  selectedSubservices,
  descriptionId,
  onSaveAndContinue,
}: IProps) => {
  let selectedIds: string[] = [];
  const { t } = useTranslation();

  const showTermsHandler = (terms: term[]) => {
    if (onShowTerms) {
      onShowTerms(terms);
    }
  };

  if (selectedSubservices) {
    selectedIds = selectedSubservices.map(
      (selectedSubservice) => selectedSubservice.subserviceId
    );
  }

  return (
    <div className={classes.SubservicesContainer}>
      <div className={classes.HeadingSection}>
        <h3>{t('auth:subservice_options')}</h3>
      </div>
      {subservices.map((subservice) => (
        <SubserviceOption
          key={subservice.subserviceId}
          subserviceName={subservice.subserviceName}
          description={subservice.subserviceDescription}
          monthlyPrice={subservice.subserviceMonthlyPrice}
          currency={subservice.currency}
          onSelect={() => onSubserviceSelect(subservice)}
          onShowDescription={() => onShowDescription(subservice.subserviceId)}
          showDescription={descriptionId === subservice.subserviceId}
          onShowTerms={() => showTermsHandler(subservice.termsAndConditions)}
          isSelected={selectedIds.includes(subservice.subserviceId)}
        />
      ))}
      <div className={classes.SaveAndContinue}>
        <Button onClick={onSaveAndContinue}>Continue</Button>
      </div>
    </div>
  );
};

export default Subservices;
