import { useEffect } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import classes from './ModalContainer.module.scss';

interface IProps {
  children: React.ReactNode | JSX.Element[] | JSX.Element;
  show: boolean;
  onClick?: () => void;
}

const ModalContainer = ({ children, show, onClick }: IProps) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add('stop-scrolling-on-modal');
    } else {
      document.body.classList.remove('stop-scrolling-on-modal');
    }

    return () => {
      document.body.classList.remove('stop-scrolling-on-modal');
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className={classes.ModalContainer}>
      {children}
      <Backdrop show={show} onClick={onClick} />
    </div>
  );
};

export default ModalContainer;
