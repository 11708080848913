import { useTranslation } from 'react-i18next';
import classes from './PricingItem.module.scss';

interface IProps {
  itemQuantity?: number;
  quantityUnit?: string;
  currency: string;
  unitPrice?: number;
  priceUnit?: string;
  totalMonthlyPrice: number;
  label: string;
  removeCalculationPresentation?: boolean;
  removePerMonth?: boolean;
}

const PricingItem = ({
  itemQuantity,
  quantityUnit,
  currency,
  unitPrice,
  priceUnit,
  totalMonthlyPrice,
  label,
  removeCalculationPresentation,
  removePerMonth,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.PricingItem}>
      <label>{label}</label>
      {!removeCalculationPresentation ? (
        <div className={classes.CalculationPresentation}>
          {itemQuantity ? <span>{itemQuantity}</span> : null}
          {quantityUnit ? <span>{quantityUnit}</span> : null}x
          <span>{currency}</span>
          {unitPrice ? <span>{unitPrice}</span> : null}
          {priceUnit ? <span>{priceUnit}</span> : null}
        </div>
      ) : null}
      <span>
        {currency}
        {totalMonthlyPrice.toFixed(2)}
        {removePerMonth ? '' : t('auth:month')}
      </span>
    </div>
  );
};

export default PricingItem;
