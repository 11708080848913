import classes from './TableContainer.module.scss';

interface ITableProps {
  headings?: string[];
  children?: React.ReactNode;
}

const TableContainer = ({ headings, children }: ITableProps) => {
  return (
    <table className={classes.TableContainer}>
      {headings && Array.isArray(headings) && headings.length > 0 ? (
        <thead>
          <tr>
            {headings.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
          </tr>
        </thead>
      ) : null}
      <tbody>{children}</tbody>
    </table>
  );
};

export default TableContainer;
