import { Text, View } from '@react-pdf/renderer';
import { sideHeadingStyles } from './SideHeading.style';
import CircleBullet from '../CircleBullet/CircleBullet';

interface IProps {
  heading: string;
  marginTop?: string | number;
}

const SideHeading = ({ heading, marginTop }: IProps) => {
  return (
    <View
      style={{
        ...sideHeadingStyles.SideHeading,
        marginTop: marginTop ? marginTop : 0,
      }}
    >
      <CircleBullet size={5} />
      <Text>{heading}</Text>
    </View>
  );
};

export default SideHeading;
