import { useRef } from 'react';
import useAddToList from '../../../CustomHooks/useAddToList';

import classes from './EmailChips.module.scss';

import EmailChipsList from './EmailChipsList/EmailChipsList';
import { item } from '../../../tsTypes/types.components';
import React, { useEffect } from 'react';

interface IProps {
  emailFormHeading: 'To' | 'CC' | 'BCC';
  setListOnBlur: (list: item[]) => void;
  children?: React.ReactNode;
  isInvalid?: boolean;
  existingList?: item[] | null;
  shouldFocusOnExistingList?: boolean;
  title?: string;
  placeholder?: string;
}

const EmailChips = ({
  emailFormHeading,
  setListOnBlur,
  children,
  existingList,
  shouldFocusOnExistingList,
  title,
  placeholder,
}: IProps) => {
  let {
    list,
    listItemName,
    invalidItemName,
    disable,
    itemNameInputRef,
    addNameToList,
    itemNameHandler,
    removeFromListHandler,
    checkAndAddToListOnBlurHandler,
  } = useAddToList({
    isEmailValidation: true,
    maxListContent: 20,
    allLowerCaseWords: true,
    checkOnTab: true,
    checkAndAddToListOnBlur: true,
    existingList,
  });

  const isFocused = useRef(false);

  useEffect(() => {
    if (
      existingList &&
      existingList.length > 0 &&
      shouldFocusOnExistingList &&
      itemNameInputRef &&
      itemNameInputRef.current &&
      !isFocused.current
    ) {
      itemNameInputRef.current.focus();
      isFocused.current = true;
    }
  }, [existingList, itemNameInputRef, shouldFocusOnExistingList]);

  const lengthOfList = list.length;

  useEffect(() => {
    if (lengthOfList >= 0) {
      setListOnBlur(list);
    }
    // eslint-disable-next-line
  }, [lengthOfList]);

  const submitListHandler = () => {
    if (list.length > 0) {
      setListOnBlur(list);
    }

    if (list.length === 0) {
      setListOnBlur([]);
    }
  };

  return (
    <div
      className={`${classes.EmailEntryField} ${
        invalidItemName ? classes.Invalid : ''
      }`}
    >
      <span>{emailFormHeading}</span>
      <EmailChipsList list={list} onRemove={removeFromListHandler}>
        <input
          type='email'
          value={listItemName}
          onChange={itemNameHandler}
          ref={itemNameInputRef}
          disabled={disable}
          onKeyDown={addNameToList}
          onBlur={() => {
            submitListHandler();
            if (checkAndAddToListOnBlurHandler) {
              checkAndAddToListOnBlurHandler();
            }
          }}
          title={title}
        />
      </EmailChipsList>
      {children}
      {list.length < 2 ? (
        <div className={classes.InfoMessage}>{placeholder}</div>
      ) : null}
    </div>
  );
};

export default EmailChips;
