import { cloneElement } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './SideMenuLink.module.scss';

interface ILinkProps {
  Icon?: JSX.Element;
  id: string;
  label?: string;
  to?: string;
  iconProps?: {
    size?: string | number;
    color?: string;
    margin?: string | number;
    marginTop?: string | number;
    marginRight?: string | number;
    marginBottom?: string | number;
    marginLeft?: string | number;
  };
  onClick?: () => void;
  isExpanded?: boolean;
  title?: string;
  onSelect?: () => void;
}

const SideMenuLink = ({
  Icon,
  id,
  label,
  to,
  iconProps,
  onClick,
  isExpanded,
  title,
  onSelect,
}: ILinkProps) => {
  const iconElement = Icon && cloneElement(Icon, { id, ...iconProps });

  if (!to && onClick) {
    return (
      <div className={classes.LinkContent} title={title}>
        <div
          className={
            isExpanded
              ? `${classes.ButtonIcon}  ${classes.Expanded}`
              : classes.ButtonIcon
          }
        >
          <button onClick={onClick}>
            {iconElement}
            <span>{label}</span>
          </button>
        </div>
      </div>
    );
  }

  if (to) {
    return (
      <NavLink
        to={to}
        className={classes.LinkContent}
        title={title}
        onClick={onSelect}
        end
      >
        {({ isActive }) => (
          <div
            className={
              isActive && !isExpanded
                ? `${classes.LabelIcon} ${classes.Active}`
                : isActive && isExpanded
                ? `${classes.LabelIcon} ${classes.Active} ${classes.Expanded}`
                : !isActive && isExpanded
                ? `${classes.LabelIcon} ${classes.Expanded}`
                : classes.LabelIcon
            }
          >
            {iconElement}
            <span className={classes.LinkLabel}>{label}</span>
          </div>
        )}
      </NavLink>
    );
  }

  return null;
};

export default SideMenuLink;
