import React, { useEffect } from 'react';
import { useGetAdminUserContractsQuery } from '../../../../features/userAdmin/userAdminSlice';
import { useSearchParams } from 'react-router-dom';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';

import ContractsList from './ContractsList/ContractsList';
import { useTranslation } from 'react-i18next';

const Contracts = () => {
  const { isLoading, isError, data } = useGetAdminUserContractsQuery('');
  const [pageQuery, setPageQuery] = useSearchParams();
  let content: React.ReactNode | null = null;
  const { t } = useTranslation();

  /**
   * @TODO : Now from backend we are getting contractStatus for buttons, remainingDaysToExpire as proper day, on the other hand, we are getting contractPackageData and currentPackageId which we will need
   * for the extension process. Check extension process notes at Periopsis Folder
   * 
   * AT BACKEND : 
   * @TODO : If get Contract Actions returns a expiry message, we should send notification to the user about the expiry of the contract,
   * check if the notifications structure is good for this process. Revise Notifications page for Contract Expiry Message, currently notifications
   * has message field which we can add additional message and also have "Contract Expiry" subject and this time incidents will be an empty
   * array which means, we will not have any incidents list item on the Notifications page and 0 Contract Exiry Heading should be revised
   * if incidents.length === 0, it should not show and number in front of heading!!!
         
   */

  const initContract =
    data !== undefined &&
    typeof data === 'object' &&
    'initContractId' in data &&
    data.initContractId !== undefined &&
    typeof data.initContractId === 'string'
      ? data.initContractId
      : '';
  const page = pageQuery.get('page');

  useEffect(() => {
    if (initContract && page) {
      setPageQuery({
        page,
        contract: initContract,
      });
    }

    // eslint-disable-next-line
  }, [initContract, page]);

  if (isLoading) {
    content = <FetchingReports message={t('admin:fetching_contracts')} />;
  }

  if (isError) {
    content = <NoDataFound message={t('admin:contracts_retrieve_error')} />;
  }

  if (
    data &&
    typeof data === 'object' &&
    'initContractId' in data &&
    data.initContractId &&
    'contracts' in data &&
    data.contracts &&
    Array.isArray(data.contracts) &&
    data.contracts.length > 0 &&
    !isLoading &&
    !isError
  ) {
    content = <ContractsList contracts={data.contracts} />;
  }

  return <div>{content}</div>;
};

export default Contracts;
