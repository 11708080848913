import { IContractUser } from '../../tsTypes/interfaces';

const compareUsersLists = (data: {
  currentList: IContractUser[];
  storedList: IContractUser[];
}) => {
  const { currentList, storedList } = data;

  //if updated return true
  if (currentList !== storedList) {
    return true;
  }

  for (let i = 0; i < currentList.length; i++) {
    const user1 = currentList[i];
    const user2 = storedList[i];

    // Check if any property is different
    if (
      user1.emailAddress !== user2.emailAddress ||
      user1.isBlockedUser !== user2.isBlockedUser ||
      user1.name !== user2.name ||
      user1.surname !== user2.surname ||
      user1.userId !== user2.userId ||
      user1.authorizations.hasAuthToActions !==
        user2.authorizations.hasAuthToActions ||
      user1.authorizations.hasAuthtoAdmin !==
        user2.authorizations.hasAuthtoAdmin
    ) {
      return true; // Found a difference
    }
  }

  return false; // No differences found
};

export default compareUsersLists;
