import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../UI/FormElements/Button/Button';
import verifyEmail from '../../../../../assets/images/verifyemail.png';
import classes from './ResendVerificationLinkForm.module.scss';
import { useResendConfirmationLinkMutation } from '../../../../../features/auth/authSlice';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { useTranslation } from 'react-i18next';

interface IProps {
  onAccountApproved: () => void;
  password: string;
  username: string;
}

const ResendVerificationLinkForm = ({
  onAccountApproved,
  password,
  username,
}: IProps) => {
  const { t } = useTranslation();
  const [resendConfirmationLink, { isLoading }] =
    useResendConfirmationLinkMutation();

  const resendVerificationHandler = async () => {
    if (isLoading) {
      return;
    }

    try {
      const res = await resendConfirmationLink({ password, username }).unwrap();

      if (res && 'isSuccess' in res && res.isSuccess) {
        onAccountApproved();
        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('auth:confirmation_email_success_message')}
          />,
          { autoClose: 9000 }
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.ResendVerificationEmailContainer}>
        <div className={classes.HeadingSection}>
          <div className={classes.ImageContainer}>
            <img
              src={verifyEmail}
              alt='an envelope for email'
              title={t('auth:verify_email')}
              width={100}
              height={100}
            />
          </div>
          <h3>{t('auth:verify_your_email')}</h3>
        </div>
        <p>{t('auth:verify_your_email_desc')}</p>
        <ActionContainer className={classes.ResendActions}>
          {!isLoading ? (
            <Button onClick={resendVerificationHandler}>
              {t('translations:resend')}
            </Button>
          ) : (
            <SimpleLoading />
          )}
        </ActionContainer>
      </div>
    </div>
  );
};

export default ResendVerificationLinkForm;
