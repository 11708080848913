import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: '50mm',
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20mm 0 10mm 0',
  },
  satallite: {
    width: '80mm',
  },
  headingText: {
    fontSize: '24pt',
    color: '#fff',
    paddingTop: '10mm',
    textTransform: 'uppercase',
  },
  text: {
    fontSize: '12pt',
    textAlign: 'center',
    color: '#fff',
    padding: '5mm 2mm',
  },
});
