import { Fragment, useState } from 'react';
import SelectionContainer from '../../../UI/SelectionContainer/SelectionContainer';
import StatisticsMainContainer from '../StatisticsMainContainer/StatisticsMainContainer';
import {
  useGetContractYearsQuery,
  useGetIncidentsGeoDataQuery,
} from '../../../../features/statistics/statisticsSlice';
import { IListObject } from '../../../../tsTypes/types.model';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import MapSW from '../../../MapSWComponents/MapSW';
import MapHeatMapLayer from '../../../MapSWComponents/MapHeatMapLayer/MapHeatMapLayer';
import TableItemContainer from '../ActionStatistics/TableItemContainer/TableItemContainer';
import TableContainer from '../../../UI/Table/TableContainer/TableContainer';
import TableRow from '../../../UI/Table/TableRow/TableRow';
import { formatDistance } from 'date-fns';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import { IStatisticsProps } from '../Statistics';
import ErrorControlComponent from '../../../UI/ErrorControlComponent/ErrorControlComponent';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import { Coordinates } from '../../../../tsTypes/types';
import FlyTo from '../../../UI/FlyTo/FlyTo';
import Button from '../../../UI/FormElements/Button/Button';
import { useTranslation } from 'react-i18next';
import classes from './DensityHeatmap.module.scss';

const DensityHeatmap = ({
  isSelected,
  contractId,
  currentYear,
  selectYear,
}: IStatisticsProps) => {
  const [showList, setShowList] = useState(false);
  const [flyToCoords, setFlyToCoords] = useState<Coordinates | null>(null);
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en';

  let years: IListObject[] = [];
  let content: React.ReactNode | null = null;
  const { isLoading, isError, data, isSuccess } = useGetContractYearsQuery(
    contractId,
    {
      skip: !isSelected || !contractId,
    }
  );
  const {
    isLoading: isLoadingGeoData,
    isError: isGeoDataError,
    data: geoData,
    error: geoError,
  } = useGetIncidentsGeoDataQuery(
    { contractId, year: currentYear },
    { skip: !isSelected || !contractId || !data || !isSuccess }
  );

  let hasIncidents = false;

  if (isLoading || isLoadingGeoData) {
    content = (
      <FetchingReports message={t('statistics:fetching_statistics_data')} />
    );
  }

  if (isError || isGeoDataError) {
    content = (
      <ErrorControlComponent error={geoError}>
        <NoDataFound message={t('statistics:contract_years_fetch_error')} />
      </ErrorControlComponent>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'years' in data &&
    data.years &&
    Array.isArray(data.years) &&
    'hasIncidents' in data
  ) {
    years = [...data.years];
    hasIncidents = data.hasIncidents;
  }

  if (!hasIncidents) {
    content = (
      <NoDataFound
        icon={<FaFilterCircleXmark />}
        message={t('statistics:no_reported_incidents_no_statistics')}
      />
    );
  }

  if (years.length === 0 && !isLoading && hasIncidents) {
    content = (
      <NoDataFound message={t('statistics:contract_years_fetch_error')} />
    );
  }

  const showIncidentsListHandler = () => {
    setShowList((prevState) => !prevState);
  };

  const flyToCoordinatesHandler = (coordinates: number[]) => {
    const coords: Coordinates = [coordinates[0], coordinates[1]];

    setFlyToCoords(coords);
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Smooth scrolling animation
    });
  };

  if (
    geoData &&
    typeof geoData === 'object' &&
    'isSuccess' in geoData &&
    geoData.isSuccess &&
    !isLoading &&
    !isLoadingGeoData &&
    hasIncidents
  ) {
    const heatMapData = geoData.data.geoData;
    const incidents: {
      gridObjectId: string;
      detectionDate: string;
      coordinates: number[];
      type: string;
    }[] = geoData.data.incidents;
    const centerCoords: Coordinates = geoData.data.centerCoordinates;

    const hasCenterCoords =
      centerCoords && Array.isArray(centerCoords) && centerCoords.length === 2;

    content = (
      <Fragment>
        <MapSW hideCoordinatesBar mapId='incidentHeatMap'>
          <MapHeatMapLayer geometryData={heatMapData} />
          {hasCenterCoords ? <FlyTo coordinates={centerCoords} /> : null}
          {flyToCoords ? (
            <FlyTo coordinates={flyToCoords} zoomLevel={20} />
          ) : null}
        </MapSW>
        <ActionContainer setup={{ justifyContent: 'flex-end' }}>
          <StandardButton
            label={
              showList
                ? t('statistics:hide_list_of_incidents')
                : t('statistics:show_list_of_incidents')
            }
            btnType={showList ? 'primary' : 'orange'}
            type='button'
            onClick={showIncidentsListHandler}
          />
        </ActionContainer>
        {showList ? (
          <TableItemContainer
            heading={t('statistics:list_of_incidents')}
            isVisible
          >
            <TableContainer
              headings={[
                t('statistics:incident_type'),
                t('statistics:detection_date'),
                t('translations:Latitude'),
                t('translations:Longitude'),
                t('statistics:detected'),
                t('statistics:show_on_map'),
              ]}
            >
              {incidents.map((incident) => (
                <TableRow
                  rowData={[
                    <span className={classes.row}>{incident.type}</span>,
                    <span className={`${classes.row} ${classes.centered}`}>
                      {new Date(incident.detectionDate).toLocaleDateString(
                        language,
                        {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        }
                      )}
                    </span>,
                    <span className={`${classes.row} ${classes.centered}`}>
                      {incident.coordinates[0].toFixed(5)}
                    </span>,
                    <span className={`${classes.row} ${classes.centered}`}>
                      {incident.coordinates[1].toFixed(5)}
                    </span>,
                    <span className={`${classes.row} ${classes.centered}`}>
                      {formatDistance(
                        new Date(incident.detectionDate),
                        new Date(),
                        {
                          addSuffix: true,
                        }
                      )}
                    </span>,
                    <span className={`${classes.row} ${classes.centered}`}>
                      <Button
                        btnClass={classes.ShowBtn}
                        onClick={() =>
                          flyToCoordinatesHandler(incident.coordinates)
                        }
                      >
                        {t('statistics:show')}
                      </Button>
                    </span>,
                  ]}
                  key={incident.gridObjectId}
                  alignFirstToLeft
                  alignLastToRight
                />
              ))}
            </TableContainer>
          </TableItemContainer>
        ) : null}
      </Fragment>
    );
  }

  return (
    <SelectionContainer isSelected={isSelected}>
      <StatisticsMainContainer
        heading={t('statistics:heatmap')}
        years={years}
        onSelect={selectYear}
        selected={currentYear.toString()}
        isLoading={isLoading}
      >
        {content}
      </StatisticsMainContainer>
    </SelectionContainer>
  );
};

export default DensityHeatmap;
