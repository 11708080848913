import { notificationSubject } from '../../tsTypes/types';

const generateTextForNotification = (data: {
  subject: notificationSubject;
  notificationDate: Date;
  incidentType?: string;
  detectionDate?: string;
  numberOfIncidents: number;
}) => {
  const {
    subject,
    notificationDate,
    incidentType,
    detectionDate,
    numberOfIncidents,
  } = data;

  if (subject === 'New Incidents') {
    return `A total of ${numberOfIncidents} ${
      numberOfIncidents > 1 ? 'incidents' : 'incident'
    } have been detected and added to the incidents collection for the contract on ${new Date(
      notificationDate
    ).toLocaleDateString('en', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })}. ${
      numberOfIncidents > 1
        ? 'Details for all detected incidents are listed below.'
        : 'Details for the detected incident are listed below.'
    } You can click on the incident card to open the related incident page for more information.`;
  }

  if (detectionDate && incidentType) {
    return `An entry has been generated for a ${subject} in connection with a ${incidentType} incident, identified on ${new Date(
      detectionDate
    ).toLocaleDateString('en', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })}, accompanied by the corresponding notification issued on ${new Date(
      notificationDate
    ).toLocaleDateString('en', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })}. To view the details of both the incident and ${subject}, please navigate to the incident page. Kindly click on the incident card below to access the incident's dedicated page.`;
  }

  return '';
};

export default generateTextForNotification;
