import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useGetCountryPhonesQuery } from '../../../features/registration/registrationSlice';
import Input from '../FormElements/Input/Input';
import PhoneCodesDropDown from './PhoneCodesDropDown/PhoneCodesDropDown';
import detectUserCountry from '../../../utils/detectUserCountry';
import { ICountryPhone } from '../../../tsTypes/types.model';
import { catchError } from '../../../utils/fetches';
import PresentationWrapper from '../PresentationWrapper/PresentationWrapper';
import classes from './Phone.module.scss';
import {
  createPattern,
  reviseMaxLengthForPattern,
} from '../../../utils/phoneUtilityFunctions/createPattern';
import { useTranslation } from 'react-i18next';

interface IProps {
  fetchSkipCondition?: boolean;
  onPhoneEntry: (e: ChangeEvent<HTMLInputElement>) => void;
  phoneNumber: string;
  onCodeSelect: (codeData: ICountryPhone) => void;
  countryData: ICountryPhone | null;
  disabled?: boolean;
}

const Phone = ({
  fetchSkipCondition,
  onPhoneEntry,
  phoneNumber,
  onCodeSelect,
  countryData,
  disabled,
}: IProps) => {
  const {
    isLoading,
    isError,
    error,
    data: countryPhones,
  } = useGetCountryPhonesQuery('', { skip: fetchSkipCondition });
  const [userCountryData, setUserCountryData] = useState<ICountryPhone | null>(
    null
  );

  const [NSN, setNSN] = useState(20);
  const { t } = useTranslation();

  const hasCountryPhones =
    countryPhones !== undefined && countryPhones.length > 0;

  const countryDataCountryName = countryData !== null ? countryData.name : '';

  useEffect(() => {
    if (hasCountryPhones) {
      const userCountry = detectUserCountry({ countryPhones });

      if (userCountry && !countryDataCountryName) {
        setUserCountryData(userCountry);
        onCodeSelect(userCountry);
        setNSN(userCountry.NSN);
      }

      if (countryDataCountryName && countryData) {
        setUserCountryData(countryData);
        setNSN(countryData.NSN);
      }
    }
    // eslint-disable-next-line
  }, [hasCountryPhones, countryDataCountryName]);

  if (isLoading || isError) {
    return (
      <PresentationWrapper
        isLoading={isLoading}
        isError={isError}
        error={catchError(error)}
        message={t('translations:phone_data_error')}
        loadingSize={0.6}
      >
        <Fragment></Fragment>
      </PresentationWrapper>
    );
  }

  return (
    <div className={classes.PhoneNumberEntryContainer}>
      <PhoneCodesDropDown
        countryPhones={countryPhones}
        userCountry={userCountryData}
        optionSelectClass={classes.OptionSelectFocus}
        onCodeSelect={onCodeSelect}
        disabled={disabled}
      />
      <Input
        type='text'
        name='Phone'
        label={t('translations:phone_number')}
        isRequired
        maxLength={reviseMaxLengthForPattern(NSN)}
        value={createPattern(phoneNumber)}
        onChange={onPhoneEntry}
        autoComplete='tel-national'
        disabled={disabled}
      />
    </div>
  );
};

export default Phone;
