export const fullPaymentCalculator = (data: {
  totalPricePerMonth: number;
  discountRateForFullPayment: number;
  VatRate: number;
  packageDuration: number;
}) => {
  const {
    totalPricePerMonth,
    discountRateForFullPayment,
    VatRate,
    packageDuration,
  } = data;

  const fullPaymentTotal = totalPricePerMonth * (packageDuration / 30);
  const totalDiscountAmount =
    fullPaymentTotal * (discountRateForFullPayment / 100);
  const totalPriceAfterDiscount = fullPaymentTotal - totalDiscountAmount;
  const overallTotal = totalPriceAfterDiscount * (1 + VatRate / 100);
  const VATAmount = totalDiscountAmount * (VatRate / 100);

  return { totalDiscountAmount, fullPaymentTotal, overallTotal, VATAmount };
};
