import {
  FieldInformationWrapper,
  FormInformationContainer,
  SectionWrapper,
} from '../../FormInformationContainer/FormInformationContainer';

import ReportIcons from '../../ReportIcons/ReportIcons';

interface IProps {
  onClose?: () => void;
  settings?: { [key: string]: boolean };
}

const IncidentCleanedReportInformation = ({ onClose, settings }: IProps) => {
  return (
    <FormInformationContainer onClose={onClose}>
      <p>
        The Cleaned Incident Report is generated from the entered data in the
        Cleaned Incident Form. This comprehensive form enables users to provide
        crucial details about the cleanup process related to a specific
        incident. By sharing information about the steps taken, resources
        utilized, and the workforce involved, contributors contribute valuable
        insights and experiences. This not only assists in addressing the
        current situation but also enhances the collective ability to plan and
        respond more effectively to similar incidents in the future.
      </p>
      <p>
        A more detailed input leads to a better understanding gained from past
        experiences, potentially making future cleanup efforts more efficient
        and cost-effective. The contributions made through this report play a
        pivotal role in improving overall preparedness and response strategies,
        contributing to a more resilient and responsive approach to incidents.
      </p>
      <ReportIcons />
      <SectionWrapper isVisible>
        <h1>Incident Details</h1>
        <p>
          This report section encompasses general information, providing details
          about the incident's location where the cleanup process took place.
          This information is automatically included in the report from the
          selected incident on the incident dashboard. This automated inclusion
          enhances efficiency and accuracy in the comprehensive documentation of
          the incident report.
        </p>
      </SectionWrapper>
      <SectionWrapper isVisible>
        <h1>General Information</h1>
        <p>
          In this section of the report, you'll discover essential details like
          the report creation date, cleanup operation date, the number of
          workers involved, estimated operation cost, observed incident type,
          exact incident location, and information about the duration of the
          cleanup operation.
        </p>
        <p>
          For authorized users with the ability to update the report, look to
          the right of each data row for pen icons or lock icons. Pen icons
          indicate fields you can update if necessary, while orange lock icons
          signify non-updateable or unchangeable data.
        </p>
        <p>
          For rows that can be updated, clicking on the pen icon opens an input
          form with the current value as the initial state. Feel free to
          modify/update the information if you believe it's incorrect or
          insufficient for that field. Once the input field is open for update,
          two new icons appear on the right of the field: the "X" icon closes
          the field after updating, and the icon to the left of the close icon
          is an "UNDO" icon. Clicking on the "UNDO" icon reverts any changes
          made to the initial state value.
        </p>
        <FieldInformationWrapper>
          <h4>Report Created At</h4>
          <p>
            This date indicates when the report was created. This is an
            unchangeable row, and the information in this field cannot be
            modified.
          </p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>Cleaned At</h4>
          <p>
            This date reflects when the incident site was cleaned. If the
            operation spanned multiple days, it shows the start date of the
            cleanup operation. If there is an error in the provided date,
            authorized users have the ability to update or change it. If you
            notice any mistakes, please reach out to an authorized user within
            your organization to update the cleanup operation date.
          </p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>Number of Workers Deployed</h4>
          <p>
            This field provides information about the number of workers deployed
            or participating in the cleanup operation. Generally, it encompasses
            all employees involved in the operation, such as drivers, standard
            workers, supervisors, and others. The field has a limit of 1000
            workers/employees. If you have authorization to update this field,
            you can click on the pen button located on the right of the field to
            activate the input field. Here, you can update or change the number
            of workers/employees who participated in the cleanup operation. Upon
            clicking the pen icon, the input field will be activated, allowing
            you to modify the number. After making changes, you can click on the
            "X" icon on the right of the field. If you believe the previous
            value was correct or are unsure about the new number, you can click
            on the "UNDO" icon to the left of the "X" icon to revert the number
            of workers to its initial state. In the subsequent sections of the
            form, you will find a list of employees who participated in the
            operation if it has been filled out by the reporter.
          </p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>Estimated Cost of Operation</h4>
          <p>
            This field contains information about the estimated cost of the
            cleanup operation. If the reporter has not filled in this
            information, a note will be displayed stating "No information has
            been provided." If you have authorization to add, update, or change
            the estimated cost, a pen icon will appear on the right of the
            field. Clicking on the pen icon will activate the input field for
            "Estimated Cost." If there is no pen icon and instead an orange lock
            icon is visible, you do not have the authority to make changes.
          </p>
          <p>
            If the pen icon is present, you can click on it to activate the
            input field, allowing you to update the estimated cost value. After
            making changes, you can click on the "X" icon to deactivate/close
            the input field. If you are uncertain about the entered value, you
            may click the "UNDO" icon on the right of the "X" icon to revert the
            value to its initial state.
          </p>
          <p>
            While this is an optional field, providing the estimated cost of the
            operation or entering the calculated cost can be beneficial for
            future operational planning. It is essential to note that this field
            is not mandatory. If you lack information about the cost of the
            operation or an estimated cost, it is advisable to leave this field
            empty to prevent any potential dissemination of misleading
            information.
          </p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>Observed Incident Type</h4>
          <p>
            While a general incident description is provided upon detection,
            adding personal observations can provide deeper insights for future
            operations. In this field, the reporter enters observed incidents
            during the cleanup operation. If the reporter has not entered any
            observed type, the field will display "No information has been
            provided."
          </p>
          <p>
            If you are authorized to update the field and are aware of the
            observed incident type, you can click on the pen icon ("Edit") to
            enter the observed type. Alternatively, you can click on the
            question mark icon on the right of the field to open the
            user-defined observed type window, where you can select one from the
            list.
          </p>
          <p>
            Within the newly opened window, you can search and select a
            previously user-defined type. Use the search section to type the
            incident name, and similar ones will appear. Alternatively, you can
            scroll through the list to find the most suitable type. Once
            identified, clicking on it will automatically add the selected type
            to the field.
          </p>
          <p>
            If the list doesn't contain the observed incident type, you can
            directly enter it into the field. This action saves the newly added
            type to the list, making it available for future use. The next time
            you encounter a similar incident, you'll be able to find and select
            the entered type from the user-defined incident types list.
          </p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>Cleanup Duartion</h4>
          <p>
            This field provides information about the duration of the cleanup
            operation, which can be specified in days or hours based on the
            reporter's input. Notably, this field is unchangeable or
            non-editable, as indicated by the lock icon on the right side of the
            field.
          </p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>Exact Location of Incident</h4>
          <p>
            This field provides information about the precise location of the
            incident for which the cleanup operation has been executed. Upon
            detecting the incident, the application service automatically
            furnishes details, including incident coordinates. However, during
            the cleanup operation, the reporter may find the provided
            information to be incorrect or insufficient, and they have the
            option to add more accurate details to the report. If no additional
            information has been added, the field displays a message stating,
            "No information has been provided."
          </p>
          <p>
            If you have authorization and know the exact incident location, you
            can click on the pen icon ("Edit") located on the right of the field
            to manually enter the precise location details. Manually inputting
            this data can provide valuable insights into the accuracy of the
            application and contribute to the enhancement of Periopsis services.
            It is important to note that only the "Exact Location of Incident"
            data will be separately recorded for the improvement of Periopsis
            Application Services, and the actual report will not be visible to
            the Periopsis Team.
          </p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible>
        <h1>List of Involved Employees</h1>
        <p>
          This field contains general information about the employees who
          participated in the cleanup operation. The list is limited to 1000 or
          the number of workers deployed. If a number is entered in the "Number
          of Workers Deployed" field, this field will only accept employees to
          be listed up to that entered number.
        </p>
        <p>
          If you have authorization and believe that more employees should be
          added, you first need to update the number of deployed workers in the
          related field. Afterward, you can click the "Add Employees" button to
          activate the list form. Upon clicking this button, the employee entry
          data form will appear. While the process of adding employees is
          optional, the fields on the form are required. If you are certain
          about all the required fields, you can enter the necessary data and
          click the "Add" button at the bottom of the form to include the
          employee in the list of involved employees.
        </p>
        <p>
          As mentioned, the "Name," "Surname," "Department," and "Duty" fields
          are mandatory on the form. Once you add an employee to the list, the
          updated list will be visible at the bottom of the form. In addition to
          adding employees, you have the option to remove them. Each employee
          has an orange-colored "-" remove icon on the right, and clicking on
          that icon removes the employee from the list. While there is no "Undo"
          process for this field, if you are unsure about the updated list, you
          can close the window to reset the list. However, closing the window
          will reset all updated data to their initial state.
        </p>
      </SectionWrapper>
      <SectionWrapper isVisible>
        <h1>List of Involved Departments</h1>
        <p>
          The list comprises the involved departments in the cleanup operation.
          For each department in the list, the number on the right indicates the
          count of workers/employees who participated in the cleanup operation
          from that specific department.
        </p>
        <p>
          If you have authorization and believe there are additional departments
          or that some listed departments were not involved, you can add or
          remove departments from the list. To add departments, click on the
          "Add Departments" button located to the right of the "List of Involved
          Departments" heading. This action will reveal two fields: "Involved
          Department" and "How many workers/employees were deployed from this
          department?"
        </p>
        <p>
          In the "Involved Department" field, enter the name of the department
          (e.g., Environmental Services Department, Sanitation Department, Risk
          Management Department, etc.). In the next field, enter the number of
          employees/workers who participated in the related incident operation
          from that department. If you are unsure about the number of workers
          involved, you can leave the number field empty.
        </p>
        <p>
          In addition to adding or updating departments and the number of
          workers/employees involved in the cleanup operation, you can also
          remove departments from the list. On the department list at the bottom
          of the field, you'll find a "Remove" icon - an orange "-" icon
          dedicated to each department on the right. Clicking on this icon will
          remove the corresponding department from the list of involved
          departments.
        </p>
        <p>
          It's crucial to exercise caution while making any changes because the
          list is not undoable. If you remove a department from the list and
          later wish to include it again, you must add it using the add
          department form; the removal process cannot be undone. The only way to
          "UNDO" any changes made to the list is to close the report window,
          which will reset or undo all updates in all fields of the report.
        </p>
      </SectionWrapper>
      <SectionWrapper isVisible>
        <h1>List of Utilized Equipments</h1>
        <p>
          This section presents a list of equipment used in the cleanup
          operation along with the corresponding quantities for each. The number
          to the right of each equipment name indicates how many of that
          specific equipment were utilized during the cleanup operation.
        </p>
        <p>
          If you have authorization, you have the ability to add or remove
          equipment from the list of utilized equipment. To add equipment, click
          on the "Add Equipments" button located to the right of the "List of
          Utilized Equipments" heading. This action activates an equipment form
          with two fields: "Equipment Utilized" for entering the equipment name
          and "How many of this equipment were used?" for specifying the
          quantity used in the cleanup operation. If you are uncertain about the
          quantity, you can leave that field empty.
        </p>
        <p>
          It's important to note that the list is not undoable. If you remove
          any equipment from the list, the deletion cannot be undone, and to
          re-add the same equipment, you will need to use the equipment entry
          field. The only way to undo changes made to the list is to close the
          report window. However, be aware that closing the window will reset
          all updated fields in the report.
        </p>
        <p>
          In addition to adding equipment to the list, you have the option to
          remove equipment by clicking the remove icon located to the right of
          each equipment. For each equipment, there is a dedicated
          orange-colored remove icon, either next to the equipment number (if it
          exists) or next to the equipment name if the quantity is not
          specified. Clicking on this icon will remove the equipment from the
          list of utilized equipment.
        </p>
      </SectionWrapper>
    </FormInformationContainer>
  );
};

export default IncidentCleanedReportInformation;
