import classes from './SelectionContainer.module.scss';

interface IProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  isInQueue?: boolean;
  isCompleted?: boolean;
  addClassName?: string;
  isScrollable?: boolean;
}

const SelectionContainer = ({
  children,
  style,
  isInQueue,
  isCompleted,
  isScrollable,
  addClassName,
}: IProps) => {
  return (
    <div
      className={`${classes.SelectionContainer} ${
        isInQueue ? classes.WaitingInQueue : ''
      } ${isCompleted ? classes.Completed : ''} ${
        addClassName ? addClassName : ''
      } ${isScrollable ? classes.MakeScrollable : ''}`}
      style={{ ...style }}
    >
      <div className={classes.ChildWrapper}>{children}</div>
    </div>
  );
};

export default SelectionContainer;
