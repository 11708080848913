import { useTranslation } from 'react-i18next';
import {
  FieldInformationWrapper,
  FormInformationContainer,
  SectionWrapper,
} from '../../../FormInformationContainer/FormInformationContainer';

interface IProps {
  onClose: () => void;
}

const IncidentMailFormInformation = ({ onClose }: IProps) => {
  const { t } = useTranslation();

  return (
    <FormInformationContainer onClose={onClose}>
      <SectionWrapper isVisible>
        <h1>{t('reportFormInfo:Navigation_And_New_Report')}</h1>
        <p>{t('reportFormInfo:Navigation_And_New_Report_Desc')}</p>

        <FieldInformationWrapper>
          <h4>{t('reportFormInfo:New_Button')}</h4>
          <p>{t('reportFormInfo:New_Button_Desc_01')}</p>
          <p>{t('reportFormInfo:New_Button_Desc_02')}</p>
          <p>{t('reportFormInfo:New_Button_Desc_03')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('reportFormInfo:Navigation_Buttons')}</h4>
          <p>{t('reportFormInfo:Navigation_Buttons_Desc_01')}</p>
          <p>{t('reportFormInfo:Navigation_Buttons_Desc_02')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible>
        <h1>{t('forms:Incident_Details')}</h1>
        <p>{t('reportFormInfo:Incident_Details_Desc_01')}</p>
        <p>{t('reportFormInfo:Incident_Details_Desc_02')}</p>
        <p>{t('reportFormInfo:Incident_Details_Desc_03')}</p>
      </SectionWrapper>
      <SectionWrapper isVisible>
        <h1>{t('reportFormInfo:Sender_Main_Receipient')}</h1>
        <p>{t('reportFormInfo:Sender_Main_Receipient_Desc_01')}</p>
        <p>{t('reportFormInfo:Sender_Main_Receipient_Desc_02')}</p>
        <p>{t('reportFormInfo:Sender_Main_Receipient_Desc_03')}</p>
      </SectionWrapper>
      <SectionWrapper isVisible>
        <h1>{t('reportFormInfo:EMAIL_ADDRESSES_AND_REPORT')}</h1>
        <p>{t('reportFormInfo:EMAIL_ADDRESSES_AND_REPORT_Desc_01')}</p>
        <p>{t('reportFormInfo:EMAIL_ADDRESSES_AND_REPORT_Desc_02')}</p>
        <p>{t('reportFormInfo:EMAIL_ADDRESSES_AND_REPORT_Desc_03')}</p>
        <FieldInformationWrapper>
          <h4>{t('reportFormInfo:What_is_CC')}</h4>
          <p>{t('reportFormInfo:What_is_CC_Desc')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('reportFormInfo:What_is_BCC')}</h4>
          <p>{t('reportFormInfo:What_is_BCC_Desc_01')}</p>
          <p>{t('reportFormInfo:What_is_BCC_Desc_02')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
    </FormInformationContainer>
  );
};

export default IncidentMailFormInformation;
