import { useTranslation } from 'react-i18next';
import classes from './Letters.module.scss';

interface IProps {
  letters: string[];
  onSelect: (letter: string) => void;
  selected: string;
  disabled?: boolean;
}

const Letters = ({ letters, onSelect, selected, disabled }: IProps) => {
  const { t } = useTranslation();

  const letterSelectHandler = (letter: string) => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(letter);
    }
  };

  if (!letters || letters.length === 0) {
    return null;
  }

  return (
    <div className={classes.Wrapper}>
      <p>{t('auth:select_a_letter')}</p>
      <div className={classes.LettersContainer}>
        {letters.map((letter) => (
          <button
            key={letter}
            className={`${
              letter.toLowerCase() === selected.toLowerCase()
                ? classes.Selected
                : ''
            } ${disabled ? classes.Disabled : ''}`}
            type='button'
            onClick={() => letterSelectHandler(letter)}
            disabled={disabled}
          >
            {letter}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Letters;
