import classes from './OrderDataCard.module.scss';

interface IProps {
  children: React.ReactNode | JSX.Element[] | JSX.Element;
  heading?: string;
}

const OrderDataCard = ({ children, heading }: IProps) => {
  return (
    <div className={classes.OrderDataCard}>
      {heading ? (
        <div className={classes.OrderDataHeading}>
          <h3>{heading}</h3>
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default OrderDataCard;
