import { useState } from 'react';
import { IRegistrationSelectionProps } from '../../../../../../tsTypes/types.components';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveInvoiceDetails,
  nextPage,
  selectAllRegistrationFormValues,
  setSelectedServiceType,
  useGetServiceTypesQuery,
} from '../../../../../../features/registration/registrationSlice';
import SelectionContainer from '../SelectionContainer/SelectionContainer';
import SectionExplanation from '../SectionExplanation/SectionExplanation';

import { MdOutlineMiscellaneousServices } from 'react-icons/md';
import SelectionForm from '../SelectionForm/SelectionForm';
import Dropdown from '../../../../../UI/FormElements/Dropdown/Dropdown';
import { IListObject } from '../../../../../../tsTypes/types.model';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { toast } from 'react-toastify';
import { AppDispatch } from '../../../../../../app/store';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import { catchError } from '../../../../../../utils/fetches';
import useDropDownSelection from '../../../../../../CustomHooks/useDropDownSelection';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

const ServiceTypeSelection = ({ pageNumber }: IRegistrationSelectionProps) => {
  const [showCheckMark, setShowCheckMark] = useState(false);
  const { currentPageNumber } = useSelector(selectAllRegistrationFormValues);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const {
    isError,
    isLoading,
    error,
    data: serviceTypes,
  } = useGetServiceTypesQuery('', { skip: currentPageNumber !== 4 });
  let serviceTypesToUse: IListObject[] = [];
  let serviceTypesList: IListObject[] = [];
  const localStorageName = 'selected-service-type';

  const {
    selected: selectedServiceType,
    setSelectedLocally: setSelectedServiceTypeLocally,
  } = useDropDownSelection({
    localStorageName,
  });

  if (serviceTypes) {
    serviceTypesToUse = serviceTypes
      .filter((st) => st.isInService)
      .map((st) => ({
        title: t(`dashboard:${getTranslationKey(st.title)}`),
        value: st.id,
        id: st.id,
      }));

    serviceTypesList = serviceTypes
      .filter((st) => st.isInService)
      .map((st) => ({
        title: t(st.title),
        value: st.id,
        id: st.id,
      }));
  }

  const serviceTypeSelectHandler = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedServiceTypeLocally(e.target.value);

    const selectedServiceType = serviceTypesList.find(
      (st) => st.id === e.target.value
    );

    if (selectedServiceType) {
      localStorage.setItem(
        'selected-service-type',
        JSON.stringify(selectedServiceType)
      );

      dispatch(setSelectedServiceType(selectedServiceType));
    }

    toast.success(
      <ToastifyStyledMessage
        singleMessage={t('auth:select_success_message')}
        heading={t('auth:next_page')}
      />,
      { autoClose: 1000 }
    );

    setShowCheckMark(true);
    dispatch(approveInvoiceDetails(false));
    localStorage.removeItem('invoice-details-approved');

    const timer = setTimeout(() => {
      dispatch(nextPage());
      toast.dismiss();
      clearTimeout(timer);
    }, 1600);
  };

  return (
    <SelectionContainer
      isInQueue={pageNumber ? pageNumber < currentPageNumber : false}
      isCompleted={pageNumber ? pageNumber > currentPageNumber : false}
    >
      <SectionExplanation
        heading={t('auth:select_prefered_service_type')}
        description={t('auth:select_preferred_service_type_desc')}
        icon={<MdOutlineMiscellaneousServices size={25} />}
      />

      <PresentationWrapper
        isLoading={isLoading}
        isError={isError}
        error={catchError(error)}
        message={t('auth:service_type_load_error')}
      >
        <SelectionForm showCheckMark={showCheckMark}>
          <Dropdown
            name='service-type'
            defaultValue={t('auth:Select_Sevice_Type')}
            optionsList={serviceTypesToUse}
            value={selectedServiceType}
            onSelect={serviceTypeSelectHandler}
          />
        </SelectionForm>
      </PresentationWrapper>
    </SelectionContainer>
  );
};

export default ServiceTypeSelection;
