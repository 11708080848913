import { IStep } from '../../../../tsTypes/types.components';
import {
  capitalizeAllWords,
  convertDistanceToKm,
  convertDuration,
} from '../../../../utils/componentUtilFns';
import HeadingBar from '../../../UI/HeadingBar/HeadingBar';
import classes from './StepsCard.module.scss';

interface IProps {
  step: IStep;
}

const StepsCard = ({ step }: IProps) => {
  if (!step) return null;

  const additionalMessage =
    step.distance !== 0
      ? `You need to drive about
  ${convertDistanceToKm(step.distance)}
  ${step.name ? `on ${step.name}` : ''}`
      : '';

  return (
    <div className={classes.StepCardContainer}>
      <div className={classes.StepCardDataSection}>
        <div>
          <HeadingBar
            heading={`${capitalizeAllWords(step.maneuver.type)} ${
              step.maneuver.modifier
                ? step.maneuver.type !== 'arrive'
                  ? capitalizeAllWords(step.maneuver.modifier)
                  : ``
                : ''
            }`}
            headingSize={4}
          />
        </div>
        <div className={classes.StepCardInstructionSection}>
          <div className={classes.Instructions}>
            <p>
              {step.voiceInstructions && step.voiceInstructions[0]
                ? step.voiceInstructions[0].announcement
                : step.maneuver.instruction}{' '}
              {additionalMessage}
            </p>
          </div>
          {step.distance !== 0 && (
            <div className={classes.DurationDistance}>
              <span>{convertDistanceToKm(step.distance)}</span>
              <span>{convertDuration(step.duration)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepsCard;
