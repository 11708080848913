import { ChangeEvent } from 'react';
import classes from './AnswerItem.module.scss';

interface IProps {
  questionId: string;
  answer: string;
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  isSelected?: boolean;
  id?: string;
  hasAuthToActions?: boolean;
}

const AnswerItem = ({
  questionId,
  answer,
  onSelect,
  isSelected,
  id,
  hasAuthToActions,
}: IProps) => {
  return (
    <label
      className={`${classes.AnswerItem} ${isSelected ? classes.Bold : ''} ${
        !hasAuthToActions ? classes.Disabled : ''
      }`}
      htmlFor={id}
    >
      <input
        type='radio'
        name={questionId}
        value={answer}
        onChange={hasAuthToActions ? onSelect : () => {}}
        checked={isSelected}
        id={id}
        className={classes.OptionInput}
        disabled={!hasAuthToActions}
      />
      {answer}
    </label>
  );
};

export default AnswerItem;
