import { IListObject } from '../../../../tsTypes/types.model';
import Dropdown from '../../../UI/FormElements/Dropdown/Dropdown';
import classes from './StatisticsMainContainer.module.scss';

interface IProps {
  children: React.ReactNode;
  heading?: string;
  years?: IListObject[];
  onSelect?: (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  selected?: string;
  isLoading?: boolean;
}

const StatisticsMainContainer = ({
  children,
  heading,
  years,
  onSelect,
  selected,
  isLoading,
}: IProps) => {
  let initValue = new Date().getFullYear();
  const selectYearHandler = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(e);
    }
  };

  let yearsToUse: IListObject[] = [];

  if (years && Array.isArray(years) && years.length > 0) {
    yearsToUse = [
      ...years.filter((year) => year.value !== new Date().getFullYear()),
      {
        id: new Date().getFullYear().toString(),
        value: new Date().getFullYear(),
        title: new Date().getFullYear().toString(),
      },
    ];
  }

  return (
    <div className={classes.StatisticsMainContainer}>
      {heading ? (
        <div className={classes.HeadingSection}>
          <h2>{heading}</h2>
          {!isLoading ? (
            <div className={classes.DropdownContainer}>
              <Dropdown
                name='years'
                optionsList={yearsToUse || []}
                defaultValue=''
                onSelect={selectYearHandler}
                value={selected || initValue.toString()}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {children}
    </div>
  );
};

export default StatisticsMainContainer;
