import { ChangeEvent, Fragment, useLayoutEffect, useState } from 'react';
import { useGetMonthlyActionStatisticsQuery } from '../../../../../features/statistics/statisticsSlice';
import FetchingReports from '../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import TableContainer from '../../../../UI/Table/TableContainer/TableContainer';
import TableRow from '../../../../UI/Table/TableRow/TableRow';
import classes from './MonthlyActions.module.scss';
import useSessionLogout from '../../../../../CustomHooks/useSessionLogout';
import TableItemContainer from '../TableItemContainer/TableItemContainer';
import ActionOptions from '../ActionOptions/ActionOptions';
import { IActionStatProps } from '../../Statistics';
import ErrorControlComponent from '../../../../UI/ErrorControlComponent/ErrorControlComponent';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

const MonthlyActions = ({
  isSelected,
  contractId,
  currentYear,
  isYearsReady,
  mainHeadings,
}: IActionStatProps) => {
  const { isLoading, isError, data, error } =
    useGetMonthlyActionStatisticsQuery(
      {
        contractId,
        year: currentYear,
      },
      { skip: !isSelected }
    );
  let content: React.ReactNode | null = null;

  const [selectedTable, setSelectedTable] = useState('visited');
  const { t } = useTranslation();

  const isVisitedTable = selectedTable === 'visited';
  const isReportedTable = selectedTable === 'reported';
  const isCleanedTable = selectedTable === 'cleaned';
  useSessionLogout({ isError, error });

  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }, []);

  if (!isSelected || !isYearsReady) {
    return null;
  }

  if (isLoading) {
    content = (
      <FetchingReports message={t('statistics:fetching_monthly_statistics')} />
    );
  }

  if (isError) {
    content = (
      <ErrorControlComponent error={error}>
        <NoDataFound
          message={t('statistics:monthly_action_statistics_fetch_error')}
        />
      </ErrorControlComponent>
    );
  }

  if (
    !isError &&
    !isLoading &&
    data &&
    typeof data === 'object' &&
    'data' in data &&
    data.data &&
    typeof data.data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'chartData' in data.data &&
    data.data.chartData &&
    typeof data.data.chartData === 'object' &&
    'results' in data.data &&
    data.data.results &&
    typeof data.data.results === 'object'
  ) {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: t('statistics:monthly_actions'),
        },
      },
    };

    const visitResult = data.data.results.visitResult as {
      month: string;
      total: number;
    }[];

    const reportResult = data.data.results.reportResult as {
      month: string;
      total: number;
    }[];

    const cleanResult = data.data.results.cleanResult as {
      month: string;
      total: number;
    }[];

    const tableHeadings = [t('statistics:month'), t('statistics:total')];

    const tableSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedTable(e.target.value);
    };

    const translatedChartData = {
      datasets: data.data.chartData.datasets.map((dataset: any) => ({
        ...dataset,
        label: t(`statistics:${getTranslationKey(dataset.label)}`),
      })),
      labels: data.data.chartData.labels.map((label: string) =>
        t(`statistics:${getTranslationKey(label)}`)
      ),
    };

    content = (
      <div className={classes.StatisticsWrapper}>
        <div className={`${classes.ChartSection}`}>
          <Line data={translatedChartData} options={options} />
        </div>

        <div className={classes.DataTableSection}>
          <ActionOptions
            onSelect={tableSelectHandler}
            selected={{ isVisitedTable, isCleanedTable, isReportedTable }}
          />
          <TableItemContainer
            heading={mainHeadings ? mainHeadings.visited : ''}
            isVisible={isVisitedTable}
          >
            <TableContainer headings={tableHeadings}>
              {visitResult.map((res) => (
                <TableRow
                  key={res.month}
                  rowData={[
                    t(`statistics:${getTranslationKey(res.month)}`),
                    res.total.toString(),
                  ]}
                />
              ))}
            </TableContainer>
          </TableItemContainer>
          <TableItemContainer
            heading={mainHeadings ? mainHeadings.reported : ''}
            isVisible={isReportedTable}
          >
            <TableContainer headings={tableHeadings}>
              {reportResult.map((res) => (
                <TableRow
                  key={res.month}
                  rowData={[
                    t(`statistics:${getTranslationKey(res.month)}`),
                    res.total.toString(),
                  ]}
                />
              ))}
            </TableContainer>
          </TableItemContainer>

          <TableItemContainer
            heading={mainHeadings ? mainHeadings.cleaned : ''}
            isVisible={isCleanedTable}
          >
            <TableContainer headings={tableHeadings}>
              {cleanResult.map((res) => (
                <TableRow
                  key={res.month}
                  rowData={[
                    t(`statistics:${getTranslationKey(res.month)}`),

                    res.total.toString(),
                  ]}
                />
              ))}
            </TableContainer>
          </TableItemContainer>
        </div>
      </div>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default MonthlyActions;
