import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../components/UI/ToastifyStyledMessage/ToastifyStyledMessage';

const checkoutUnAuthorized = () => {
  const timer = setTimeout(() => {
    window.location.replace('/');
    clearTimeout(timer);
  }, 5000);

  return toast.error(
    <ToastifyStyledMessage singleMessage='Unauthorized Access Detected: You will be automatically redirected to the login page.' />
  );
};

export default checkoutUnAuthorized;
