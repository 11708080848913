import { useEffect, useState } from 'react';
import formatTime from '../utils/formatTime';

interface IProps {
  duration?: number;
}

const useTimer = ({ duration }: IProps) => {
  const durationToUse = duration || 180;
  const [startResendTimer, setStartResendTimer] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(durationToUse);

  useEffect(() => {
    if (startResendTimer) {
      const timer = setInterval(() => {
        if (remainingSeconds > 0) {
          setRemainingSeconds(
            (prevRemainingSeconds) => prevRemainingSeconds - 1
          );
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [remainingSeconds, startResendTimer]);

  useEffect(() => {
    if (remainingSeconds === 0 && startResendTimer) {
      setRemainingSeconds(durationToUse);
      setStartResendTimer(false);
    }
  }, [remainingSeconds, startResendTimer, durationToUse]);

  const startTimer = () => {
    setStartResendTimer(true);
  };

  const resetTimer = () => {
    setStartResendTimer(false);
    setRemainingSeconds(durationToUse);
  };

  const timerFormat = formatTime(remainingSeconds);

  return {
    timerFormat,
    startTimer,
    resetTimer,
    isTimerStarted: startResendTimer,
  };
};

export default useTimer;
