const validateCreditCard = (cardNumber: string) => {
  if (!cardNumber) {
    return { isValid: false, issuer: 'None' };
  }

  // Remove non-numeric characters
  cardNumber = cardNumber.replace(/\D/g, '');

  // Check if the card number passes the Luhn algorithm
  let sum = 0;
  let doubleUp = false;
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);
    if (doubleUp) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    doubleUp = !doubleUp;
  }

  if (sum % 10 !== 0) {
    return { isValid: false, issuer: 'None' };
  }

  // Check issuer based on prefixes
  if (/^4/.test(cardNumber)) {
    return { isValid: true, issuer: 'Visa' };
  } else if (/^5/.test(cardNumber)) {
    return { isValid: true, issuer: 'MasterCard' };
  } else if (/^3[47]/.test(cardNumber) && cardNumber.length === 15) {
    return { isValid: true, issuer: 'American Express' };
  } else if (/^6(011|5|4[4-9]|22)/.test(cardNumber)) {
    return { isValid: true, issuer: 'Discover' };
  } else {
    return { isValid: true, issuer: 'Unknown' };
  }
};

export default validateCreditCard;
