import {
  ColorClassType,
  dateData,
  ICountry,
  ILocation,
  item,
  SelectSearchOption,
  alpha2Code,
  ITabButton,
} from '../tsTypes/types.components';
import definiteDump from '../assets/incidentMarkers/DefiniteDump.png';
import likelyDump from '../assets/incidentMarkers/LikelyDump.png';
import dumpingSite from '../assets/incidentMarkers/LargeDumpingSite.png';
import litter from '../assets/incidentMarkers/Litter.png';
import constructionMaterials from '../assets/incidentMarkers/ConstructionMaterials.png';
import { INotifications } from '../tsTypes/types.model';
import { frontendConstants } from '../constants/constants';
import { PropsValue } from 'react-select';
import { toast } from 'react-toastify';
import {
  bg,
  da,
  de,
  el,
  enGB,
  es,
  et,
  fi,
  fr,
  hu,
  it,
  lt,
  lv,
  nl,
  pl,
  pt,
  ro,
  sk,
  sl,
  sv,
} from 'date-fns/locale';
import { formatDistance } from 'date-fns';

export const getColorClass = (type: ColorClassType | undefined) => {
  switch (type) {
    case 'primary':
      return 'Primary';
    case 'info':
      return 'Info';
    case 'warning':
      return 'Warning';
    case 'danger':
      return 'Danger';
    case 'success':
      return 'Success';
    case 'main':
      return 'Main';
    case 'orange':
      return 'Orange';
    case 'primary-light':
      return 'PrimaryLight';
    case 'plain':
      return 'Plain';
    case 'peach':
      return 'Peach';

    case 'primary-light-blue':
      return 'PrimaryLightBlue';

    default:
      return 'Primary';
  }
};

const freeDomains = Object.freeze({
  yahoo: 'yahoo',
  gmail: 'gmail',
  hotmail: 'hotmail',
  outlook: 'outlook',
  yandex: 'yandex',
  aol: 'aol',
  icloud: 'icloud',
  protonmail: 'protonmail',
  mail: 'mail',
  zoho: 'zoho',
  gmx: 'gmx',
  inbox: 'inbox',
  tutanota: 'tutanota',
  rediffmail: 'rediffmail',
  qq: 'qq',
  '163': '163',
  '126': '126',
  sina: 'sina',
  naver: 'naver',
  daum: 'daum',
  hanmail: 'hanmail',
  wp: 'wp',
  o2: 'o2',
  interia: 'interia',
  onet: 'onet',
  libero: 'libero',
  tin: 'tin',
  live: 'live',
  rambler: 'rambler',
  list: 'list',
  bk: 'bk',
  narod: 'narod',
  abv: 'abv',
  china: 'china',
  carioca: 'carioca',
  cmmail: 'cmmail',
  ai: 'ai',
  public: 'public',
  com: 'com',
  tonghua: 'tonghua',
  sss: 'sss',
  aboutmail: 'aboutmail',
  catchaplane: 'catchaplane',
  altavista: 'altavista',
  oceanfree: 'oceanfree',
  pchome: 'pchome',
  '123india': '123india',
  '365holiday': '365holiday',
  desertmail: 'desertmail',
  batelco: 'batelco',
  arabia: 'arabia',
  ok: 'ok',
  ematic: 'ematic',
  hotvoice: 'hotvoice',
  myworldmail: 'myworldmail',
  msn: 'msn',
  wanadoo: 'wanadoo',
  orange: 'orange',
  comcast: 'comcast',
  free: 'free',
  web: 'web',
  ymail: 'ymail',
  uol: 'uol',
  bol: 'bol',
  cox: 'cox',
  sbcglobal: 'sbcglobal',
  sfr: 'sfr',
  verizon: 'verizon',
  googlemail: 'googlemail',
  ig: 'ig',
  bigpong: 'bigpong',
  terra: 'terra',
  neuf: 'neuf',
  alice: 'alice',
  rocketmail: 'rocketmail',
  att: 'att',
  laposte: 'laposte',
  facebook: 'facebook',
  bellsouth: 'bellsouth',
  tiscali: 'tiscali',
  shaw: 'shaw',
  sky: 'sky',
  earthlink: 'earthlink',
  optonline: 'optonline',
  freenet: 'freenet',
  't-online': 't-online',
  aliceadsl: 'aliceadsl',
  virgilio: 'virgilio',
  telenet: 'telenet',
  voila: 'voila',
  planet: 'planet',
  ntlworld: 'ntlworld',
  arcor: 'arcor',
  frontiernet: 'frontiernet',
  zonnet: 'zonnet',
  'club-internet': 'club-internet',
  juno: 'juno',
  optusnet: 'optusnet',
  blueyonder: 'blueyonder',
  bluewin: 'bluewin',
  skynet: 'skynet',
  sympatico: 'sympatico',
  windstream: 'windstream',
  mac: 'mac',
  centurytel: 'centurytel',
  chello: 'chello',
  aim: 'aim',
});

/**
 * Validates an email address using a regular expression pattern.
 *
 * This utility function checks whether a given string is a valid email address
 * by applying a regular expression pattern. It returns `true` if the input string
 * matches the pattern and is considered a valid email address, and `false` otherwise.
 *
 * @param email - The email address string to be validated.
 * @returns `true` if the input string is a valid email address; otherwise, `false`.
 *
 * @remarks
 * - The function employs a regular expression pattern to verify the email format.
 * - The pattern checks for the presence of at least one non-whitespace character
 *   before the '@' symbol, followed by at least one non-whitespace character after
 *   the '@' symbol, a dot ('.'), and at least one non-whitespace character after the dot.
 * - The function is suitable for basic email address format validation and may not
 *   guarantee the existence or validity of the email on the server.
 *
 * @example
 * // Validate a valid email address
 * const isValidEmail = validateEmail("john@example.com");
 * console.log(isValidEmail); // Output: true
 *
 * // Validate an email address with missing '@'
 * const isInvalidEmail1 = validateEmail("invalidemail.com"); // Output: false
 *
 * // Validate an email address with spaces
 * const isInvalidEmail2 = validateEmail("  john@example.com  "); // Output: false
 */
export const validateEmail = (email: string) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return pattern.test(email);
};

export const isValidCompanyEmail = (email: string) => {
  const isValidEmail = validateEmail(email);
  const lengthOfEmail = email.length;
  if (!isValidEmail || lengthOfEmail > 60 || lengthOfEmail < 6) {
    return false;
  }

  const domain = email.split('@')[1];
  const domainName = domain.split('.')[0];

  const isFreeEmail = domainName in freeDomains;

  if (isFreeEmail) {
    return false;
  }

  return true;
};

const extractDomain = (url: string) => {
  const parts = url.split('.');
  const domainParts = parts.slice(1);

  return domainParts.join('.');
};

export const companyEmailWebMatch = (email: string, website: string) => {
  if (!email || !website) {
    return true;
  }

  const emailDomain = email.split('@')[1];
  const websiteDomain = extractDomain(website);

  if (!emailDomain || !websiteDomain) {
    return true;
  }

  if (website.length < emailDomain.length + 8) {
    return true;
  }

  if (emailDomain === websiteDomain) {
    return true;
  }

  return false;
};

export const validateWebsite = (website: string) => {
  // Define a regular expression pattern for a valid website URL
  const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w]+(\/[\w-./?%&=]*)?$/;

  // Use the test method to check if the input matches the pattern
  return urlPattern.test(website);
};

const nowAllowedSpecialCharacters = Object.freeze({
  '!': true,
  '+': true,
  '%': true,
  '&': true,
  '/': true,
  '?': true,
  '(': true,
  ')': true,
  '[': true,
  ']': true,
  '{': true,
  '}': true,
  '@': true,
  '*': true,
  '-': true,
  '=': true,
  _: true,
  '€': true,
  $: true,
  '#': true,
  '£': true,
  '~': true,
  '.': true,
  ':': true,
  ';': true,
  '>': true,
  '<': true,
  '|': true,
  '½': true,
  '¨': true,
});

/**
 * Validates a name or surname to ensure it meets specified criteria.
 *
 * This utility function checks a given string to determine if it is a valid name or surname
 * based on predefined criteria. It examines the length of the string, checks for the presence
 * of numeric digits, and verifies that all characters are not the same. The function returns
 * `true` if the input is considered valid and `false` otherwise.
 *
 * @param name - The name or surname string to be validated.
 * @returns `true` if the input string is valid according to the criteria; otherwise, `false`.
 *
 * @remarks
 * - The function checks the following criteria for validity:
 *   1. Length: The string length should be between 2 and 60 characters, inclusive.
 *   2. Numeric Digits: The input should not contain any numeric digits (0-9).
 *   3. Character Diversity: The string should not consist of all identical characters.
 * - If the input meets all the criteria, the function returns `true` to indicate validity.
 * - If any of the criteria are not met, the function returns `false` to indicate invalidity.
 * - The function is suitable for validating names, surnames, or any input where these criteria apply.
 *
 * @example
 * // Validate a name
 * const isValidName = validateNameSurname("John");
 * console.log(isValidName); // Output: true
 *
 * // Validate a surname with an invalid length
 * const isValidSurname = validateNameSurname("Smith-Smythe-Anderson"); // Output: false
 *
 * // Validate a name containing a numeric digit
 * const isInvalidName = validateNameSurname("Jane123"); // Output: false
 *
 * // Validate a name with all identical characters
 * const isInvalidName2 = validateNameSurname("aaa"); // Output: false
 */
export const validateNameSurname = (name: string) => {
  // Check name length
  if (name.length < 2 || name.length > 60) {
    return false; // Name length is not acceptable, invalid
  }

  // Check if the input contains any numbers
  if (/\d/.test(name)) {
    return false; // Contains a number, invalid
  }

  // Check if all characters are the same
  if (/^(.)\1+$/.test(name.toLowerCase())) {
    return false; // All characters are the same, invalid
  }

  return true; // Passed all checks, valid
};

export const validateText = (
  text: string,
  minLength: number,
  maxLength: number,
  canUseMainMessageCharacters?: boolean
) => {
  const hasConsecutiveCharacters = /(.)\1{2,}/.test(text);

  if (minLength === 0 && text.trim().length === 0) {
    return true;
  }

  if (
    text.trim().length >= minLength &&
    text.trim().length <= maxLength &&
    !hasConsecutiveCharacters
  ) {
    return true;
  }

  return false;
};

const hasUnwantedCharacter = (text: string) => {
  const textArr = text.trim().split('');
  let hasUnwantedChar = false;
  const testChars = nowAllowedSpecialCharacters;

  textArr.forEach((chr) => {
    if (chr in testChars) {
      hasUnwantedChar = true;
    }
  });

  return hasUnwantedChar;
};

/**
 * Validates a full name to ensure it meets specified criteria.
 *
 * This utility function checks a given full name string to determine if it is a valid
 * name or surname based on predefined criteria. It examines the length of the string,
 * checks for the presence of unwanted characters, verifies that it consists of at least
 * two parts (first name and last name), and ensures that each part is not empty.
 * The function returns `true` if the input is considered valid and `false` otherwise.
 *
 * @param fullName - The full name string to be validated.
 * @param minLength - The minimum allowed length for the full name.
 * @param maxLength - The maximum allowed length for the full name.
 * @returns `true` if the input string is valid according to the criteria; otherwise, `false`.
 *
 * @remarks
 * - The function checks the following criteria for validity:
 *   1. Length: The length of the full name should be within the specified `minLength`
 *      and `maxLength` limits (inclusive).
 *   2. Unwanted Characters: The string should not contain unwanted characters.
 *      You can customize the character validation using the `hasUnwantedCharacter` function.
 *   3. Parts: The full name should consist of at least two parts (first name and last name).
 *      Each part should not be empty.
 * - If the input meets all the criteria, the function returns `true` to indicate validity.
 * - If any of the criteria are not met, the function returns `false` to indicate invalidity.
 * - The function is suitable for validating full names and can be used in registration
 *   forms, user profiles, or any input requiring full name validation.
 *
 * @example
 * // Validate a valid full name
 * const isValidFullName = NameSurnameValidator("John Smith", 2, 50);
 * console.log(isValidFullName); // Output: true
 *
 * // Validate a full name with an unwanted character
 * const isInvalidFullName1 = NameSurnameValidator("John$Smith", 2, 50); // Output: false
 *
 * // Validate a full name with a single part
 * const isInvalidFullName2 = NameSurnameValidator("John", 2, 50); // Output: false
 */
export const NameSurnameValidator = (
  fullName: string,
  minLength: number,
  maxLength: number
) => {
  const isValidName =
    !hasUnwantedCharacter(fullName) &&
    fullName.split(' ').length >= 2 &&
    fullName.split(' ')[0].trim().length > 0 &&
    fullName.split(' ')[1].trim().length > 0 &&
    fullName.length >= minLength &&
    fullName.length <= maxLength;

  return isValidName;
};

export const validatePassword = (
  password: string,
  minLength: number,
  maxLength: number
) => {
  const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  const lowerCase = /^[a-z\s]+$/;
  const upperCase = /^[A-Z\s]+$/;

  const NoNumberAndNoSpecialChar = /^[A-Za-z\s]+$/;

  if (password.length > maxLength || password.trim().length < minLength) {
    return false;
  }

  if (NoNumberAndNoSpecialChar.test(password)) {
    return false;
  }

  const passwordContent = password.split('');

  let numberOfLowerCaseLetters = 0;
  let numberOfUpperCaseLetters = 0;
  let numberOfNumbers = 0;
  let numberOfSpecialCharacters = 0;

  for (const char of passwordContent) {
    if (Number(char)) {
      numberOfNumbers++;
    }

    if (lowerCase.test(char)) {
      numberOfLowerCaseLetters++;
    }

    if (upperCase.test(char)) {
      numberOfUpperCaseLetters++;
    }

    if (specialCharacters.test(char)) {
      numberOfSpecialCharacters++;
    }
  }

  if (
    numberOfLowerCaseLetters < 1 ||
    numberOfNumbers < 1 ||
    numberOfUpperCaseLetters < 1 ||
    numberOfSpecialCharacters < 1
  ) {
    return false;
  }

  return true;
};

export const getDistrictsFromSelectedProvinces = (
  selectedCountry: ICountry | null,
  selectedProvinces: item[] | null
) => {
  let districtsHashMap: { [key: string]: item[] } = {};
  let filteredDistrict: item[] = [];

  if (
    selectedCountry &&
    'provinces' in selectedCountry &&
    selectedCountry.provinces
  ) {
    selectedCountry.provinces.forEach((province) => {
      return (districtsHashMap[province._id] = province.provinceDistricts);
    });
  }

  if (selectedProvinces && selectedProvinces.length >= 1) {
    selectedProvinces.forEach((province) => {
      filteredDistrict = [
        ...filteredDistrict,
        ...districtsHashMap[province._id],
      ];

      return filteredDistrict;
    });
  } else {
    for (let dist in districtsHashMap) {
      filteredDistrict = [...filteredDistrict, ...districtsHashMap[dist]];
    }
  }

  return filteredDistrict;
};

export const setOrganizationTypeHeading = (typeName: string | undefined) => {
  if (typeName && typeName !== 'undefined') return typeName;

  return 'Organization';
};

/**
 * Capitalizes the first letter of a string entry and converts the remaining letters to lowercase.
 *
 * This utility function takes a string entry as input and returns a new string with the
 * first letter capitalized and the rest of the letters converted to lowercase. It is commonly
 * used for formatting strings, such as names, titles, or labels, to ensure consistent capitalization.
 *
 * @param entry - The input string entry to be capitalized.
 * @returns A new string with the first letter capitalized and the remaining letters in lowercase.
 *
 * @remarks
 * - The function checks if the provided entry is a non-empty string and not a numeric value.
 * - If the input entry meets the criteria, it capitalizes the first letter and converts
 *   the remaining letters to lowercase.
 * - If the input entry is empty, a non-string, or a numeric value, it returns the entry as is.
 * - The function ensures that the original input string remains unaltered in such cases.
 *
 * @example
 * // Capitalize a name entry
 * const formattedName = capitalizeEntry("john"); // Output: "John"
 *
 * // Handle invalid input (numeric entry)
 * const invalidEntry = capitalizeEntry("123"); // Output: "123"
 *
 * // Handle empty string input
 * const emptyEntry = capitalizeEntry(""); // Output: ""
 */
export const capitalizeEntry = (entry: string) => {
  if (
    entry &&
    typeof entry === 'string' &&
    entry.length > 0 &&
    isNaN(Number(entry))
  ) {
    const initLetter = entry.substring(0, 1).toUpperCase();
    const remaining = entry.substring(1).toLocaleLowerCase();

    return `${initLetter}${remaining}`;
  }

  return entry;
};

/**
 * Capitalizes the first letter of each word in a given text while preserving
 * the original casing of any uppercase or mixed-case words.
 *
 * @param text - The input text to be processed.
 * @returns A new string where the first letter of each word is capitalized,
 *          while words that are entirely in uppercase or have a single
 *          uppercase letter are left unchanged.
 *
 * @example
 * const inputText = "CYENS, square, plateia dimarchias 23B, nicosia, 1016, cyprus";
 * const capitalizedText = capitalizeWords(inputText);
 * console.log(capitalizedText);
 * // Output: "CYENS, Square, Plateia Dimarcgias 23B, Nicosia, 1016, Cyprus"
 *
 * @remarks
 * - The function considers words as sequences of characters separated by spaces.
 * - It does not modify words that are already in all uppercase or have a single
 *   uppercase letter (e.g., "CYENS" remains "CYENS").
 * - It capitalizes the first letter of each word while keeping the rest of the
 *   word in its original case.
 * - Empty words (resulting from multiple spaces) are handled gracefully and
 *   preserved as empty strings.
 *
 * This function can be useful for formatting user-provided input or ensuring
 * consistent capitalization in various text-based applications.
 */
export const capitalizeAllWords = (text: string) => {
  if (!text || typeof text !== 'string') {
    return '';
  }

  // Split the text into words using spaces as delimiters
  const words = text.split(' ');

  // Process each word and capitalize the first letter
  const capitalizedWords = words.map((word) => {
    if (!word) {
      // Handle empty words, if any
      return '';
    }

    // Check if the word is all uppercase or has a single uppercase letter
    if (/^[A-Z]+$/.test(word) || /^[A-Z][a-z]+$/.test(word)) {
      // Return the word as is (no changes)
      return word;
    }

    // Capitalize the first letter and keep the rest of the word
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back together with spaces
  const result = capitalizedWords.join(' ');

  return result;
};

/**
 * Retrieves the HTTP status message for a given HTTP status code.
 *
 * This utility function accepts an HTTP status code and an optional custom heading
 * object, returning the corresponding HTTP status message. It is particularly useful
 * for obtaining human-readable status messages for HTTP responses.
 *
 * @param httpCode - The HTTP status code for which to retrieve the message.
 * @param customHeading - (Optional) An object containing custom status headings.
 *   If provided, this function first checks if a custom heading exists for the given
 *   HTTP code, and if found, returns it instead of the default status message.
 *
 * @returns The HTTP status message associated with the provided HTTP status code,
 *   or 'Unknown Error' if the code is not recognized or a custom heading is not defined.
 *
 * @example
 * // Retrieve the default HTTP status message for status code 404 (Not Found)
 * const message = getHeaderByCode(404);
 * console.log(message); // Output: "Not Found"
 *
 * // Retrieve a custom status message for status code 201 (Created)
 * const customHeadings = { 201: "Resource Created" };
 * const customMessage = getHeaderByCode(201, customHeadings);
 * console.log(customMessage); // Output: "Resource Created"
 *
 * @remarks
 * - The function supports a wide range of HTTP status codes, including common ones
 *   such as 200 (OK), 404 (Not Found), and 500 (Internal Server Error).
 * - Custom headings can be provided to override the default status messages for specific codes.
 * - If a custom heading is not found for a code, or if the code is not recognized,
 *   the function returns 'Unknown Error' as a fallback.
 *
 * This function is designed to assist in generating user-friendly messages based on
 * HTTP status codes, making it suitable for handling API responses and error messages.
 */
export const getHeaderByCode = (
  httpCode: number,
  customHeading?: { [code: number]: string }
) => {
  // Define a map of HTTP status codes to their corresponding messages
  const statusMessages: { [code: number]: string } = {
    201: 'Created',
    202: 'Accepted',
    203: 'Non-Authoritative Information',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content',
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found',
    304: 'Not Modified',
    307: 'Temporary Redirect',
    308: 'Permanent Redirect',
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Payload Too Large',
    414: 'URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Range Not Satisfiable',
    417: 'Expectation Failed',
    422: 'Unprocessable Entity',
    425: 'Too Early',
    426: 'Upgrade Required',
    428: 'Precondition Required',
    429: 'Too Many Requests',
    431: 'Request Header Fields Too Large',
    451: 'Unavailable For Legal Reasons',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    506: 'Variant Also Negotiates',
    507: 'Insufficient Storage',
    508: 'Loop Detected',
    510: 'Not Extended',
    511: 'Network Authentication Required',
  };

  // Check if a custom heading is provided for the given HTTP code
  if (customHeading && customHeading[httpCode]) {
    return customHeading[httpCode];
  }

  // Assert that httpCode is a valid key from statusMessages
  const validHttpCode = httpCode as keyof typeof statusMessages;

  // Return the corresponding message from the statusMessages map or 'Unknown Error' if not found
  return statusMessages[validHttpCode] || 'Unknown Error';
};

/**
 * Retrieves the user's current geographical location using the Geolocation API
 * and updates the provided location state.
 *
 * This utility function utilizes the Geolocation API to determine the user's
 * latitude and longitude coordinates. It then updates the provided location state
 * using a React state setter function. This can be useful for applications that
 * require the user's location for various purposes, such as mapping or location-based
 * services.
 *
 * @param locationSetter - A React state setter function that updates the location state.
 *   It should accept a state object of type ILocation or null.
 *
 * @remarks
 * - The function checks if the Geolocation API is available in the user's browser
 *   before attempting to retrieve the location.
 * - If the Geolocation API is supported, it requests the user's current position,
 *   including latitude and longitude, with high accuracy and a maximum age of 0.
 * - If the user grants permission and the location is successfully obtained, the
 *   locationSetter function is called to update the location state with the coordinates.
 * - In case of an error or if the user denies permission, minimal error handling is
 *   provided. You can customize the error handling within the function as needed.
 * - If the Geolocation API is not available or encounters an error, it may not provide
 *   accurate or any location information, and the location state remains unchanged.
 *
 * @example
 * // Usage in a React component
 * const [userLocation, setUserLocation] = useState<ILocation | null>(null);
 *
 * // Call getUserLocation to retrieve and set the user's location
 * getUserLocation(setUserLocation);
 *
 * @interface ILocation - An interface representing a geographical location with
 *   latitude and longitude coordinates.
 * @property {number} latitude - The latitude coordinate.
 * @property {number} longitude - The longitude coordinate.
 *
 *
 * @TODO
 * It will be used in useEffect, for this reason it take a useState setter function as argument
 * Setter should have a type ILocation|null
 */
export const getUserLocation = (
  locationSetter: React.Dispatch<React.SetStateAction<ILocation | null>>
) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        locationSetter({
          latitude: position.coords.latitude || 39.0,
          longitude: position.coords.longitude || 34.0,
        });
      },
      (error: GeolocationPositionError) => {
        //TODO : add error handling here!! or any error message that can be shown to us|user
        toast.info(`Unfortunately, we couldn't locate the coordinates.`);
      },
      { enableHighAccuracy: true, maximumAge: 0 }
    );
  }
};

/**
 * NOTE : Check Great-circle distance @ https://en.wikipedia.org/wiki/Great-circle_distance
 * NOTE : This function uses the Haversine Formula @ https://en.wikipedia.org/wiki/Haversine_formula
 * The haversine formula determines the great-circle distance between two points on a sphere given their longitudes and latitudes.
 * @param coords1 :Coordinates of the point 1 {latitide, longitude}
 * @param coords2 :Coordinate of the point 2 {latitude, longitude}
 * @returns distance between point 1 and point 2 in kilometers
 */
export const getDistanceBetweenTwoCoordinates = (
  coords1: ILocation | null,
  coords2: ILocation | null
) => {
  if (coords1 && coords2) {
    //Degrees to Radians coord1
    const radiansOfCoordsLat1 = (coords1.latitude * Math.PI) / 180;
    const radiansOfCoordsLon1 = (coords1.longitude * Math.PI) / 180;

    //Degrees to Radians coord2
    const radiansOfCoordsLat2 = (coords2.latitude * Math.PI) / 180;
    const radiansOfCoordsLon2 = (coords2.longitude * Math.PI) / 180;

    //Haversine Formula
    const distLon = radiansOfCoordsLon2 - radiansOfCoordsLon1;
    const distLat = radiansOfCoordsLat2 - radiansOfCoordsLat1;
    const a =
      Math.pow(Math.sin(distLat / 2), 2) +
      Math.cos(coords1.latitude) *
        Math.cos(coords2.latitude) *
        Math.pow(Math.sin(distLon / 2), 2);

    const c = 2 * Math.asin(Math.sqrt(a));
    const radOfWorldInKilometers = 6371;

    //Distance between two points in Kilometers
    return (c * radOfWorldInKilometers).toFixed(3);
  } else {
    return null;
  }
};

const IMAGE_PIXEL_SIZE = 147456; //384x384

const iconSizeCalculator = (density: number) => {
  const floatPercentage = (density * 6.5) / IMAGE_PIXEL_SIZE;

  const percentage = floatPercentage * 100;

  return 2 * percentage;
};

export const getGarbageIconByGarbageType = (
  garbageType: string,
  density: number
) => {
  switch (garbageType) {
    case 'Gh5tRfvEiP439qetKKkZAPVoRRnBu8Qn-0002':
      return {
        image: definiteDump,
        size: iconSizeCalculator(density),
        opacity: 0.9,
      };

    case 'HzfOWRjblituU1uYwoAOMkAIdJh8x9Lj-0003':
      return {
        image: likelyDump,
        size: iconSizeCalculator(density),
        opacity: 0.8,
      };

    case 'J7r6HlE3WVBX1SJdlnnrc2gsFrLVoOJt-0001':
      return {
        image: dumpingSite,
        size: iconSizeCalculator(density),
        opacity: 1,
      };

    case 'KrVCHW2gErZaGjNRRScJNXwPqVTJzSEq-0004':
      return {
        image: litter,
        size: iconSizeCalculator(density),
        opacity: 0.55,
      };

    case 'IYjKB36x10fhTTn1YSJnRjxsIgh2hUcT-0005':
      return {
        image: constructionMaterials,
        size: iconSizeCalculator(density),
        opacity: 0.7,
      };

    default:
      return {
        image: litter,
        size: iconSizeCalculator(density),
        opacity: 0.55,
      };
  }
};

export const getDefaultDropDownValue = (
  days: number
): PropsValue<SelectSearchOption> | undefined => {
  if (days < 90) {
    const text = `Last ${days} days`;
    return {
      name: text,
      value: days.toString(),
      label: text,
    };
  } else if (days >= 90 && days <= 300) {
    const text = `Last ${days / 30} Months`;
    return {
      name: text,
      value: days.toString(),
      label: text,
    };
  } else if (days > 300) {
    const text = `Last 1 Year`;
    return {
      name: text,
      value: days.toString(),
      label: text,
    };
  } else {
    return {
      name: 'Last 30 Days',
      value: '30',
      label: 'Last 30 Days',
    };
  }
};

export const formatDateForInputFormState = (date: Date) => {
  const dateLocale = date.toLocaleDateString('en-EN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const [month, day, year] = dateLocale.split('/');

  return `${year}-${month}-${day}`;
};

export const isTodayDate = (day: number, month: number, year: number) => {
  const today = new Date().toLocaleDateString('el-EL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  const enteredDate = new Date(year, month - 1, day).toLocaleDateString(
    'el-EL',
    { year: 'numeric', month: '2-digit', day: '2-digit' }
  );

  return today === enteredDate;
};

export const isFutureDate = (day: number, month: number, year: number) => {
  const today = new Date(new Date().toDateString()).getTime();
  const enteredDate = new Date(year, month - 1, day).getTime();

  return today < enteredDate;
};

export const getDateFromInputEnteredDate = (inputEntry: string) => {
  const [year, month, day] = inputEntry.split('-');

  const intYear = Number(year);
  const intMonth = Number(month);
  const intDay = Number(day);

  const enteredDate = new Date(intYear, intMonth - 1, intDay);

  return {
    parts: { year: intYear, month: intMonth, day: intDay },
    dateValue: enteredDate,
    inTime: enteredDate.getTime(),
    isToday: isTodayDate(intDay, intMonth, intYear),
    isFutureDate: isFutureDate(intDay, intMonth, intYear),
  };
};

export const isSameName = (
  name1: string,
  name2: string,
  acceptableDifRatio: number
) => {
  if (name1.toLowerCase() === name2.toLowerCase()) {
    return true;
  }

  if (name1.length !== name2.length) {
    return false;
  }

  const lengthOfNames = name1.length;
  const name1Parts = name1.toLowerCase().split('');
  const name2Parts = name2.toLowerCase().split('');
  let sameCharAtSameIndex = 0;

  name1Parts.forEach((chr, index) => {
    if (chr === name2Parts[index]) {
      sameCharAtSameIndex += 1;
    }
  });

  const ratio = sameCharAtSameIndex / lengthOfNames;

  return ratio >= acceptableDifRatio;
};

/**
 *
 * @param startDate : The start date or smaller date that we would like to compare with a later date
 * @param endDate : The later date or bigger date that we would like to compare with a previous date
 * @returns  it will return a boolean and a string message "greater"|"equal"|"less"
 */
export const compareTwoDate = (startDate: dateData, endDate: dateData) => {
  const sDate = new Date(
    startDate.year,
    startDate.month - 1,
    startDate.day
  ).getTime();
  const eDate = new Date(
    endDate.year,
    endDate.month - 1,
    endDate.day
  ).getTime();

  if (sDate > eDate) {
    return { isValid: false, message: 'greater' };
  }

  if (sDate === eDate) {
    return { isValid: true, message: 'equal' };
  }

  return { isValid: true, message: 'less' };
};

export const compareTwoInputDate = (startDate: string, endDate: string) => {
  const fromDateValues = getDateFromInputEnteredDate(startDate);
  const toDateValues = getDateFromInputEnteredDate(endDate);

  const isFutureFromDate = fromDateValues.isFutureDate;
  const isToDateLessThanFromDate = fromDateValues.inTime > toDateValues.inTime;

  return { isFutureFromDate, isToDateLessThanFromDate };
};

export const convertDistanceToKm = (distanceInMeters: number) => {
  if (distanceInMeters <= 500) return `${distanceInMeters.toFixed(2)} m`;

  return `${(distanceInMeters / 1000).toFixed(2)} km`;
};

export const convertDuration = (
  durationInSeconds: number,
  useExactTimeNames?: boolean
) => {
  if (durationInSeconds < 60)
    return `${durationInSeconds.toFixed(2)} ${
      useExactTimeNames ? 'seconds' : 'sec'
    }`;

  if (durationInSeconds > 3600)
    return `${(durationInSeconds / (60 * 60)).toFixed(2)} ${
      useExactTimeNames ? 'hour' : 'hr'
    }`;

  return `${(durationInSeconds / 60).toFixed(2)} ${
    useExactTimeNames ? 'minutes' : 'min'
  }`;
};

export const shortenText = (text: string, maxLength: number) => {
  if (text.length === maxLength) return text;

  let count = 0;
  const emptySpace = 1;
  const passedWords: string[] = [];
  const words = text.split(' ');

  if (words.length === 1 && text.length > maxLength) {
    const lastIndex = maxLength <= 25 ? maxLength : maxLength - 25;

    return `${text.substring(0, lastIndex)}...`;
  }

  for (let word of words) {
    count += word.length + emptySpace;

    if (count < maxLength) {
      passedWords.push(word);
    }

    if (count === maxLength) {
      break;
    }
  }

  return `${passedWords.join(' ').trim()}...`;
};

export const convertDate = (date: Date) => {
  return date.toLocaleDateString('el-EL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const getDateFnsLocaleFromAlphaCode = (language: alpha2Code) => {
  switch (language) {
    case 'bg':
      return bg;
    case 'cy':
      return el;
    case 'da':
      return da;
    case 'el':
      return el;
    case 'es':
      return es;
    case 'et':
      return et;
    case 'fi':
      return fi;
    case 'fr':
      return fr;
    case 'hu':
      return hu;
    case 'it':
      return it;
    case 'lt':
      return lt;
    case 'lv':
      return lv;
    case 'nl':
      return nl;
    case 'pl':
      return pl;
    case 'pt':
      return pt;
    case 'ro':
      return ro;
    case 'sk':
      return sk;
    case 'sl':
      return sl;
    case 'sv':
      return sv;
    case 'de':
      return de;

    default:
      return enGB;
  }
};

export const getCenterOfCoordinates = (coordinates: number[][]) => {
  if (!Array.isArray(coordinates)) {
    return null;
  }

  if (coordinates.length === 1) {
    return { lng: coordinates[0][0], lat: coordinates[0][1] };
  }

  const numberOfCoordinates = coordinates.length;

  let x = 0;
  let y = 0;
  let z = 0;

  for (let coordinate of coordinates) {
    const lat = (coordinate[1] * Math.PI) / 180;
    const lng = (coordinate[0] * Math.PI) / 180;

    const a = Math.cos(lat) * Math.cos(lng);
    const b = Math.cos(lat) * Math.sin(lng);
    const c = Math.sin(lat);

    x += a;
    y += b;
    z += c;
  }

  x /= numberOfCoordinates;
  y /= numberOfCoordinates;
  z /= numberOfCoordinates;

  const lng = Math.atan2(y, x);
  const hypotenus = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  const lat = Math.atan2(z, hypotenus);

  return { lat: (lat * 180) / Math.PI, lng: (lng * 180) / Math.PI };
};

export const validateBirthDate = (
  birthday: string,
  minAge: number,
  maxAge: number
) => {
  const { isFutureDate, isToday, inTime } =
    getDateFromInputEnteredDate(birthday);

  const invalidBirthDay =
    isFutureDate ||
    isToday ||
    new Date().getTime() - inTime <=
      frontendConstants.oneDayInMilliseconds * 365 * minAge ||
    new Date().getTime() - inTime >
      frontendConstants.oneDayInMilliseconds * 365 * maxAge;

  return !invalidBirthDay;
};

export const orderButtonsByIdOrder = (
  buttonIds: string[],
  buttons: ITabButton[]
) => {
  let ordered: ITabButton[] = [];

  for (let id of buttonIds) {
    const btn = buttons.find((button) => button.onSelectId === id);

    if (btn) {
      ordered.push(btn);
    }
  }

  return ordered;
};

export const generateNotificationMessage = (
  notificationData: INotifications,
  language: alpha2Code
) => {
  // const locales = {
  //   en: enGB,
  // };

  let dateOfIncident = '';
  let numberOfIncidents: number | null = null;

  if (
    notificationData &&
    'date' in notificationData &&
    'numberOfIncidents' in notificationData
  ) {
    dateOfIncident = formatDistance(
      new Date(notificationData.date),
      new Date(),
      { addSuffix: true }
    );

    numberOfIncidents = notificationData.numberOfIncidents;
  }

  if (!numberOfIncidents || !dateOfIncident) {
    return '';
  }

  return `${numberOfIncidents} new incidents detected at your organization area and added to your incidents list ${dateOfIncident}`;
};
