import { useEffect, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { toast } from 'react-toastify';
import { FaBuilding } from 'react-icons/fa';
import {
  approveInvoiceDetails,
  nextPage,
  selectAllRegistrationFormValues,
  setOrganizationType,
  setUserLocationData,
  useGetOrganizationTypesQuery,
} from '../../../../../../features/registration/registrationSlice';
import SelectionContainer from '../SelectionContainer/SelectionContainer';
import Dropdown from '../../../../../UI/FormElements/Dropdown/Dropdown';
import SectionExplanation from '../SectionExplanation/SectionExplanation';
import { AppDispatch } from '../../../../../../app/store';
import {
  IListObject,
  ILocation,
  IRegistrationSelectionProps,
} from '../../../../../../tsTypes/types.components';
import SelectionForm from '../SelectionForm/SelectionForm';
import { getUserLocation } from '../../../../../../utils/componentUtilFns';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { catchError } from '../../../../../../utils/fetches';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import useDropDownSelection from '../../../../../../CustomHooks/useDropDownSelection';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

const OrganizationTypeSelection = ({
  pageNumber,
}: IRegistrationSelectionProps) => {
  const [location, setLocation] = useState<ILocation | null>(null);
  const [showCheckMark, setShowCheckMark] = useState(false);
  let translatedOrganizationTypesList: IListObject[] = [];
  const { currentPageNumber } = useSelector(selectAllRegistrationFormValues);
  const localStorageName = 'selected-organization-type';
  const { t } = useTranslation();

  const {
    isError,
    isLoading,
    error,
    data: organizationTypes,
  } = useGetOrganizationTypesQuery('', { skip: currentPageNumber !== 1 });

  const {
    selected: organizationType,
    setSelectedLocally: setOrganizationTypeLocally,
  } = useDropDownSelection({
    localStorageName,
  });

  const dispatch = useDispatch<AppDispatch>();

  //Get User Location
  useEffect(() => {
    getUserLocation(setLocation);
  }, []);

  useEffect(() => {
    if (location) {
      dispatch(setUserLocationData(location));
    }
  }, [dispatch, location]);

  const organizationTypeSelectionHandler = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | { target: { name: string; value: string } }
  ) => {
    if (organizationTypes) {
      const signUpFormDate = localStorage.getItem('sign-up-form-date');
      const selectedOrganization = organizationTypes.find(
        (orgType) => orgType.id === e.target.value
      );

      if (!selectedOrganization) {
        return;
      }

      localStorage.setItem(
        localStorageName,
        JSON.stringify(selectedOrganization)
      );

      //Each stored form will be valid for 15 days and then will be deleted from localstorage
      if (!signUpFormDate) {
        localStorage.setItem(
          'sign-up-form-date',
          JSON.stringify({ signUpStartDate: new Date().getTime() })
        );
      }

      setOrganizationTypeLocally(e.target.value);
      setShowCheckMark(true);

      toast.success(
        <ToastifyStyledMessage
          singleMessage={t('auth:select_success_message')}
          heading={t('auth:next_page')}
        />,
        { autoClose: 1000 }
      );
      localStorage.removeItem('invoice-details-approved');

      batch(() => {
        dispatch(setOrganizationType(selectedOrganization));
        dispatch(approveInvoiceDetails(false));
        const timer = setTimeout(() => {
          dispatch(nextPage());
          toast.dismiss();
          clearTimeout(timer);
        }, 1600);
      });
    }
  };

  if (organizationTypes) {
    translatedOrganizationTypesList = organizationTypes
      .filter((orgType) => orgType.isInService)
      .map((orgTyp) => ({
        id: orgTyp.id,
        title: `${t(`auth:${getTranslationKey(orgTyp.title)}`)}`,
        value: orgTyp.value,
        isInSevice: orgTyp.isInService,
      }));
  }

  return (
    <SelectionContainer
      isInQueue={pageNumber ? pageNumber < currentPageNumber : false}
      isCompleted={pageNumber ? pageNumber > currentPageNumber : false}
    >
      <SectionExplanation
        heading={t('auth:select_organization_type')}
        description={t('auth:select_organization_type_desc')}
        icon={<FaBuilding size={25} />}
      />
      <PresentationWrapper
        isLoading={isLoading}
        isError={isError}
        error={catchError(error)}
        message={t('auth:organization_types_load_error')}
      >
        <SelectionForm showCheckMark={showCheckMark}>
          <Dropdown
            optionsList={translatedOrganizationTypesList as IListObject[]}
            name='organizationType'
            value={organizationType}
            onSelect={organizationTypeSelectionHandler}
            defaultValue={t('auth:select_organization_type_default')}
          />
        </SelectionForm>
      </PresentationWrapper>
    </SelectionContainer>
  );
};

export default OrganizationTypeSelection;
