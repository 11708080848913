export function validateFranceVATNumber(vatNumber: string): boolean {
  // Remove any spaces from the entered VAT number
  const cleanedVATNumber = vatNumber.replace(/\s/g, '');

  // Check if the VAT number has the correct length
  if (cleanedVATNumber.length !== 13) {
    return false;
  }

  // Extract the components of the VAT number
  const countryCode = cleanedVATNumber.substring(0, 2);
  const validationKey = cleanedVATNumber.substring(2, 4);
  const siren = cleanedVATNumber.substring(4);

  // Check if the country code is 'FR'
  if (countryCode !== 'FR') {
    return false;
  }

  // Check if the validation key is numeric
  if (!/^\d+$/.test(validationKey)) {
    return false;
  }

  // Check if the SIREN is numeric
  if (!/^\d+$/.test(siren)) {
    return false;
  }

  // Calculate the key
  const calculatedKey = (12 + 3 * (parseInt(siren, 10) % 97)) % 97;

  // Compare the calculated key with the one provided
  return parseInt(validationKey, 10) === calculatedKey;
}
