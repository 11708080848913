import { useEffect } from 'react';
import {
  IIncidentType,
  IUserContract,
} from '../../../../../tsTypes/interfaces';
import { IListObject } from '../../../../../tsTypes/types.model';
import Dropdown from '../../../../UI/FormElements/Dropdown/Dropdown';
import StatusFiltering from './StatusFilter/StatusFiltering';
import RangeFilter from './RangeFilter/RangeFilter';
import IncidentTypeFiltering from './IncidentTypeFiltering/IncidentTypeFiltering';
import DashboardHeading from '../../DashboardHeading/DashboardHeading';
import IOSRadioButton from '../../../../UI/FormElements/IOSRadioButton/IOSRadioButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllUIValues,
  setListMap,
} from '../../../../../features/UI/uiSlice';
import { AppDispatch } from '../../../../../app/store';
import classes from './IncidentFilteringContainer.module.scss';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import {
  selectAllIncidentValues,
  setCurrentFilterStatus,
  setFromDate,
  setSelectedIncidentType,
  setToDate,
} from '../../../../../features/incidents/incidentsSlice';
import { formatDateForInputFormState } from '../../../../../utils/componentUtilFns';
import { IncidentStatus } from '../../../../../tsTypes/enums';
import IncidentCount from './IncidentCount/IncidentCount';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

interface IProps {
  contracts?: IUserContract[];
  incidentTypes?: IIncidentType[];
  incidentCount: number;
}

const IncidentFilteringContainer = ({
  contracts,
  incidentTypes,
  incidentCount,
}: IProps) => {
  const { showListMap } = useSelector(selectAllUIValues);
  const { status, fromDate, toDate, selectedIncidentTypeId } = useSelector(
    selectAllIncidentValues
  );
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  let contractOptionList: IListObject[] = [];
  let isList = false;
  const today = new Date(new Date().toDateString());
  const currentYear = today.getFullYear();
  const fromDateValue = new Date(currentYear, 0, 1);
  const toDateValue = new Date(currentYear, 11, 31);

  const selectOptions = {
    option1: t('dashboard:List'),
    option2: t('dashboard:Map'),
  };

  useEffect(() => {
    const mapVal = localStorage.getItem('is-list');

    if (mapVal) {
      const result = JSON.parse(mapVal);
      dispatch(setListMap(result.isList));
    }
  }, [dispatch]);

  const hasStatusFilter = status !== '';
  const hasSelectedIncidentTypeFilter = selectedIncidentTypeId !== '';
  const hasFromDateFilter =
    formatDateForInputFormState(new Date(fromDate)) !==
    formatDateForInputFormState(fromDateValue);
  const hasToDateFilter =
    formatDateForInputFormState(new Date(toDate)) !==
    formatDateForInputFormState(toDateValue);

  if (!contracts || !Array.isArray(contracts) || contracts.length === 0) {
    return null;
  }

  if (contracts && Array.isArray(contracts) && contracts.length > 1) {
    contractOptionList = contracts.map((contract) => ({
      value: contract.contractId,
      title: `${contract.serviceTypeName} (${contract.status})`,
      id: contract.contractId,
    }));

    isList = true;
  }

  const displayOptionHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(
      'is-list',
      JSON.stringify({ isList: e.target.checked })
    );

    dispatch(setListMap(e.target.checked));
  };

  const resetFilterHandler = () => {
    dispatch(setFromDate(formatDateForInputFormState(fromDateValue)));
    dispatch(setToDate(formatDateForInputFormState(toDateValue)));
    dispatch(setCurrentFilterStatus(IncidentStatus.all));
    dispatch(setSelectedIncidentType(''));
  };

  return (
    <div className={classes.FilterMainContainer}>
      <DashboardHeading heading={t('dashboard:Filter_Incidents')}>
        <div className={classes.ListTypeSelectionButtonContainer}>
          <IOSRadioButton
            options={selectOptions}
            onChange={displayOptionHandler}
            size={0.5}
            checked={showListMap}
            id='map'
          />
        </div>
      </DashboardHeading>
      <div className={classes.ContentContainer}>
        <div className={classes.IncidentFilterActions}>
          <div className={classes.FilterBySection}>
            <RangeFilter />
            <StatusFiltering className={classes.StatusFiltering} />
            {incidentTypes ? (
              <IncidentTypeFiltering incidentTypes={incidentTypes} />
            ) : null}
            {incidentTypes ? (
              <StandardButton
                label={t('dashboard:Reset')}
                type='button'
                btnType='peach'
                onClick={resetFilterHandler}
                fakeButton={
                  !hasStatusFilter &&
                  !hasSelectedIncidentTypeFilter &&
                  !hasFromDateFilter &&
                  !hasToDateFilter
                }
              />
            ) : null}
          </div>
        </div>
        <div
          className={`${classes.UserContracts} ${
            isList ? classes.ListMode : ''
          }`}
        >
          <div className={classes.ContractSection}>
            {isList ? (
              <Dropdown
                optionsList={contractOptionList}
                defaultValue={``}
                name='Contracts'
                onSelect={() => {}}
                value=''
              />
            ) : (
              <p>{`${t(
                `dashboard:${getTranslationKey(contracts[0].serviceTypeName)}`
              )} ${
                contracts[0].status !== 'Paid'
                  ? `(${t(`dashboard:${contracts[0].status}`)})`
                  : ''
              }`}</p>
            )}
          </div>
          <IncidentCount incidentCount={incidentCount} viewType='plain' />
        </div>
      </div>
    </div>
  );
};

export default IncidentFilteringContainer;
