import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../style.constants';

export const ulStyles = StyleSheet.create({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3pt',
    padding: '5mm 6mm',
    fontSize: '4mm',
    color: colorConstants.periopsisMainColor,
  },
});
