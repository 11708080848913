import { Page, View } from '@react-pdf/renderer';
import { styles } from './PageLayout.style';
import React from 'react';
import LeftContent from '../../LeftContent/LeftContent';
import { Currency } from '../../../../tsTypes/types.model';

interface IProps {
  children: React.ReactNode | JSX.Element[] | JSX.Element;
  name: string;
  currency?: Currency;
  monthlyPrice?: number;
  duration?: number;
  durationUnit?: string;
  heading?: string;
  date?: string;
  priceUnit?: string;
}

const PageLayout = ({
  children,
  name,
  currency,
  monthlyPrice,
  durationUnit,
  duration,
  heading,
  date,
  priceUnit,
}: IProps) => {
  return (
    <Page size='A4' style={styles.page} wrap={false}>
      <View style={styles.main}>
        <LeftContent
          name={name}
          currency={currency}
          monthlyPrice={monthlyPrice}
          duration={duration}
          durationUnit={durationUnit}
          heading={heading}
          date={date}
          priceUnit={priceUnit}
        />
        <View style={styles.Right}>{children}</View>
      </View>
    </Page>
  );
};

export default PageLayout;
