import { TbDatabaseOff } from 'react-icons/tb';

import classes from './NoData.module.scss';

interface IProps {
  message: string;
}

const NoData = ({ message }: IProps) => {
  return (
    <div className={classes.NoData}>
      <TbDatabaseOff size={30} style={{ marginBottom: '0.9rem' }} />
      {message}
    </div>
  );
};

export default NoData;
