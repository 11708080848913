import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
// import { setLoginPage, setAdminPage } from '../../features/UI/uiSlice';
import Menu from '../UI/SideDrawer/Menu/Menu';
import Navbar from '../UI/Navbar/Navbar';
import SideMenuDesktop from '../UI/SideMenuDesktop/SideMenuDesktop';
import MainWindow from '../UI/MainWindow/MainWindow';
import {
  selectAllUIValues,
  setLoginPage,
  setShowMobileMenu,
} from '../../features/UI/uiSlice';
import { frontendConstants } from '../../constants/constants';
import LanguageMenu from '../UI/LanguageMenu/LanguageMenu';
const RootLayout = () => {
  const { showMobileMenu } = useSelector(selectAllUIValues);

  const dispatch = useDispatch();
  const location = useLocation();

  const hamburgerClickHandler = () => {
    dispatch(setShowMobileMenu());
  };

  const closeDrawerHandler = () => {
    dispatch(setShowMobileMenu());
  };

  const isLoginPage = location.pathname === frontendConstants.pages.login;

  const isRegistrationSuccess = location.pathname.includes(
    '/registration/success'
  );
  const isConfirmationPage =
    location.pathname.includes('/email/confirmed') ||
    location.pathname.includes('/email/confirm');

  useEffect(() => {
    dispatch(setLoginPage(isLoginPage));
  }, [dispatch, isLoginPage]);

  return (
    <Fragment>
      {!isConfirmationPage && !isRegistrationSuccess && (
        <>
          <Menu onClick={closeDrawerHandler} />
          <Navbar onClick={hamburgerClickHandler} show={showMobileMenu} />
        </>
      )}
      <MainWindow>
        <SideMenuDesktop />
        <Outlet />
      </MainWindow>
      <LanguageMenu />
    </Fragment>
  );
};

export default RootLayout;
