import React from 'react';
import { reportStyles as styles } from './ReportLayout.style';
import { View, Page, Image, Text } from '@react-pdf/renderer';
import periopsis from '../../../../assets/images/HomePageImages/periopsisLogov1.png';
import periopsisDark from '../../../../assets/images/HomePageImages/periopsislogodark.png';

interface IProps {
  children: React.ReactNode;
  bgColor?: 'main' | 'white';
  reportHeading?: string;
}

const ReportLayout = ({ children, bgColor, reportHeading }: IProps) => {
  return (
    <Page
      size='A4'
      wrap={false}
      style={
        bgColor === 'white' || bgColor === undefined
          ? styles.container
          : styles.containerMain
      }
    >
      <View>
        <Image
          source={
            bgColor === 'white' || bgColor === undefined
              ? periopsisDark
              : periopsis
          }
          style={styles.logo}
        />
        <View>
          <View style={styles.reportHeading}>
            <Text>{reportHeading}</Text>
          </View>
          {children}
        </View>
      </View>
    </Page>
  );
};

export default ReportLayout;
