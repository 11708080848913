import React from 'react';
import {
  FieldInformationWrapper,
  FormInformationContainer,
} from '../../FormInformationContainer/FormInformationContainer';

interface IProps {
  onClose: () => void;
}

const AllIncidentsInformation = ({ onClose }: IProps) => {
  return (
    <FormInformationContainer onClose={onClose}>
      <FieldInformationWrapper>
        <h4>Incident Details</h4>
        <p>
          The incident details section displays the incident type and detection
          date for the current incident. Typically, the incident type and
          detection date are the same for all messages since reports are
          associated with a specific incident. You are viewing the details of
          the incident that you selected from the main dashboard.
        </p>
      </FieldInformationWrapper>
      <FieldInformationWrapper>
        <h4>Reports Section</h4>
        <p>
          The Reports section may include none or multiple reports. For each
          report, you will be able to see "Reported To" and "Report Date" data.
          To view additional details for each report, click on the "Read" button
          on the right side of the respective report section.
        </p>
        <p>
          The "Department" section displays information about the primary
          department to which the report was primarily sent. The "Report Type"
          indicates how the report was sent to the recipients. Currently, in
          Periopsis, reports can be sent via Email or Whatsapp. Therefore,
          "Report Type: Mail" denotes that the report has been sent via email,
          and "Report Type: Whatsapp" denotes that the report has been sent and
          shared via Whatsapp. Below these pieces of information, the message
          section contains the sender's message to the main recipient.
        </p>
        <p>
          Keep in mind that while you can view that an incident has been
          reported on the incident dashboard, the "All Incident Reports" section
          may appear empty. This is because users authorized to submit reports
          to any department have the option to either archive or delete reports
          after 90 days from the submission date. Please note that reports
          cannot be archived or deleted before the 90-day mark from the
          submission date.
        </p>
      </FieldInformationWrapper>
    </FormInformationContainer>
  );
};

export default AllIncidentsInformation;
