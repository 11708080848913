import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import CircleBullet from '../CircleBullet/CircleBullet';
import { listStyle } from './Li.style';
interface IProps {
  item: string;
  bulletColor?: string;
  bullet?: boolean;
}

const Li = ({ item, bulletColor, bullet }: IProps) => {
  let bulletComp: React.ReactNode | null = null;

  if (bullet) {
    bulletComp = <CircleBullet color={bulletColor} />;
  }

  return (
    <View style={listStyle.ListContainer}>
      {bulletComp}
      <Text>{item}</Text>
    </View>
  );
};

export default Li;
