import { IAddress } from '../../tsTypes/types.model';

const createFullAddress = (addressObject: IAddress) => {
  let fullAddress = '';

  // Add Street Address if it exists
  if (addressObject.streetAddress && addressObject.streetAddress.name) {
    fullAddress += addressObject.streetAddress.name + ', ';
  }

  // Add District if it exists
  if (addressObject.division && addressObject.division.name) {
    fullAddress += addressObject.division.name + ', ';
  }

  // Add Postal Code if it exists
  if (addressObject.postalCode && addressObject.postalCode.name) {
    fullAddress += addressObject.postalCode.name + ', ';
  }

  // Add City/Town (Settlement) if it exists
  if (addressObject.settlement && addressObject.settlement.name) {
    fullAddress += addressObject.settlement.name + ', ';
  }

  //Add Country
  if (addressObject.countryDetails && addressObject.countryDetails.country) {
    fullAddress += addressObject.countryDetails.country;
  }

  // Remove the trailing comma and space, if any
  if (fullAddress.endsWith(', ')) {
    fullAddress = fullAddress.slice(0, -2);
  }

  return fullAddress;
};

export default createFullAddress;
