import { Document, Text, View } from '@react-pdf/renderer';
import PageLayout from '../UI/PageLayout/PageLayout';
import {
  IAreaOfInterest,
  ICountry,
  IOrganization,
  item,
  itemWithPrice,
} from '../../../tsTypes/types.components';
import { IPackageSubService } from '../../../tsTypes/types.model';
import SideHeading from '../UI/SideHeading/SideHeading';
import { styles } from './ServiceDetails.style';
import { styleConstants } from '../UI/style.constants';
import DataContainer from '../UI/DataContainer/DataContainer';
import { invoiceCalculator } from '../../../utils/invoiceCalculator';

interface IProps {
  country: ICountry | null;
  organizationType: {
    id: string;
    title: string;
    value: string;
  } | null;
  district: item | null;
  servicePackage: itemWithPrice | null;
  province: item | null;
  serviceType: {
    id: string;
    title: string;
    value: string;
  } | null;
  organization: Pick<
    IOrganization,
    'id' | 'organizationName' | 'organizationType'
  > | null;
  subservices: IPackageSubService[] | [];
  polygons: IAreaOfInterest[];
}

const ServiceDetails = ({
  country,
  organizationType,
  district,
  servicePackage,
  province,
  serviceType,
  organization,
  subservices,
  polygons,
}: IProps) => {
  let organizationTyp = '';
  let countryName = '';
  let districtName = '';
  let packName = '';
  let provinceName = '';
  let serviceTypeName = '';
  let organizationName = '';
  let packagePrice = 0;
  let priceUnit = '';
  let currency = '';
  let duration = 0;
  let discount = 0;
  let durationUnit = '';
  let vatRate = '';
  let selectedPolygons: IAreaOfInterest[] = [];
  const districts: string[] = [];

  if (organizationType) {
    organizationTyp = organizationType.title;
  }

  if (country) {
    countryName = country.name;
  }

  if (polygons && Array.isArray(polygons) && polygons.length > 0) {
    selectedPolygons = [...polygons];
  }

  if (district) {
    selectedPolygons.forEach((polygon) => {
      const polygonDistrict = polygon.district;

      if (!districts.includes(polygonDistrict)) {
        districts.push(polygonDistrict);
      }
    });

    const isMultipleDistricts = districts.length > 1;

    districtName =
      districts.length === 1 && district.name === districts[0]
        ? districts[0]
        : !isMultipleDistricts
        ? districts[0]
        : 'Multiple Districts';
  }

  if (servicePackage) {
    packName = servicePackage.name;
    packagePrice = servicePackage.price;
    currency = servicePackage.currency;
    duration = servicePackage.duration;
    durationUnit = servicePackage.durationUnit;
    vatRate = `${servicePackage.vatRate}%`;
    priceUnit = servicePackage.priceUnit;
    discount = servicePackage.discounts.customDiscount;
  }

  if (province) {
    provinceName = province.name;
  }

  if (serviceType) {
    serviceTypeName = serviceType.title;
  }

  if (organization) {
    organizationName = organization.organizationName;
  }

  const {
    totalPrice,
    discountAmount,
    totalVat,
    overallTotal,
    discountRate,
    totalArea,
  } = invoiceCalculator({
    selectedPackage: servicePackage,
    subservices,
    selectedPolygons: polygons,
    discount: discount ? discount : 0,
  });

  return (
    <Document
      title='Package Pricing'
      author='Periopsis Ltd'
      subject='Periopsis Service Packages'
      creator='Periopsis Ltd'
    >
      <PageLayout
        name='Service Details'
        heading='Selection Summary'
        date={new Date().toLocaleDateString('en', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        })}
      >
        <View style={styles.subContent}>
          <SideHeading heading='Purpose of Document' />
          <Text style={styles.legalText}>
            This document was generated on{' '}
            {new Date().toLocaleDateString('en', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}
            , and it serves to display the choices made by the user during the
            registration process, as well as the pricing details of the selected
            package or services. It's important to clarify that this document
            does not constitute a legally binding agreement or an invoice. Its
            primary purpose is to inform the user and help prevent any potential
            errors or mistakes during the registration process.
          </Text>
        </View>
        <View style={styles.subContent}>
          <SideHeading
            heading='Selections'
            marginTop={styleConstants.sideHeadingTopMargin}
          />
          <DataContainer label='Organization Type' data={organizationTyp} />
          <DataContainer label='Country' data={countryName} />
          <DataContainer label='District' data={districtName} />
          <DataContainer label='Total Area' data={`${totalArea} sq km`} />
          <DataContainer label='Package' data={packName} />
          {province ? (
            <DataContainer label='Province' data={provinceName} />
          ) : null}
          <DataContainer label='Service Type' data={serviceTypeName} />
          <DataContainer label='Organization' data={organizationName} />
        </View>
        <View>
          <SideHeading
            heading='Pricing'
            marginTop={styleConstants.sideHeadingTopMargin}
          />
          <DataContainer
            label='Monthly Package Price'
            data={`${currency}${packagePrice}${priceUnit}`}
          />
          <DataContainer
            label='Package Duration'
            data={`${duration} ${durationUnit}`}
          />
          {subservices && subservices.length > 0
            ? subservices.map((subservice) => (
                <DataContainer
                  key={subservice.subserviceId}
                  label={subservice.subserviceName}
                  data={`${subservice.currency}${subservice.subserviceMonthlyPrice}/month`}
                />
              ))
            : null}
          <DataContainer label='VAT Rate' data={vatRate} />
          <DataContainer label='Discount Rate' data={discountRate} />
          <DataContainer
            label='Total Amount'
            data={`${currency}${totalPrice}/month`}
          />
          <DataContainer
            label='Discount Amount'
            data={`${currency}${discountAmount}/month`}
          />
          <DataContainer
            label='Total VAT'
            data={`${currency}${totalVat}/month`}
          />
          <View style={styles.totalSection}>
            <DataContainer
              label='Overall Total'
              data={`${currency}${overallTotal}/month`}
            />
            <DataContainer
              label={`${duration} ${durationUnit} Price (incl. VAT)`}
              data={`${currency}${(duration / 30) * Number(overallTotal)}`}
            />
          </View>
        </View>
        <View style={{ ...styles.subContent, marginTop: '8mm' }}>
          <Text style={styles.legalText}>
            Please note that the prices provided in this document are valid for
            a maximum duration of 15 days from the date of issuance. After this
            period, prices are subject to change without prior notice. We
            recommend prompt action to secure the prices outlined herein. Thank
            you for choosing our services.
          </Text>
        </View>
      </PageLayout>
    </Document>
  );
};

export default ServiceDetails;
