import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../style.constants';

export const itemStyles = StyleSheet.create({
  container: {
    padding: '2mm 4mm',
    border: `1pt solid ${colorConstants.periopsisMainColor}`,
    borderRadius: '5pt',
  },
  heading: {
    padding: '2mm',
    marginBottom: '2mm',
    // borderBottom: `1pt solid ${colorConstants.periopsisMainColor}`,
    display: 'flex',
    width: '100%',
    backgroundColor: `${colorConstants.periopsisMainColor}`,
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '3.5mm',
    flexDirection: 'row',
  },
});
