import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectAllIncidentValues,
  setCurrentFilterStatus,
} from '../../../../../../features/incidents/incidentsSlice';
import Dropdown from '../../../../../UI/FormElements/Dropdown/Dropdown';
import { AppDispatch } from '../../../../../../app/store';
import { IncidentStatus } from '../../../../../../tsTypes/enums';
import { useTranslation } from 'react-i18next';

interface IStatusProps {
  className?: string;
}

const StatusFiltering = ({ className }: IStatusProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { status } = useSelector(selectAllIncidentValues);
  const { t } = useTranslation();

  useEffect(() => {
    const filterStatus = localStorage.getItem('filterStatus') as IncidentStatus;

    if (filterStatus) {
      dispatch(setCurrentFilterStatus(filterStatus));
    } else {
      dispatch(setCurrentFilterStatus(IncidentStatus.all));
    }
  }, [dispatch]);

  const statusSelectHandler = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = e.target;
    let selected = value as IncidentStatus;

    console.log(value);

    if (value === 'All Incidents') {
      selected = '' as IncidentStatus;
    }
    localStorage.setItem('filterStatus', selected);
    dispatch(setCurrentFilterStatus(selected));
  };

  return (
    <div className={className}>
      <Dropdown
        optionsList={[
          {
            id: 'reported',
            title: t('dashboard:Reported_Incidents'),
            value: IncidentStatus.reported,
          },
          {
            id: 'visited',
            title: t('dashboard:Visited_Incidents'),
            value: IncidentStatus.visited,
          },
          {
            id: 'cleaned',
            title: t('dashboard:Cleaned_Incidents'),
            value: IncidentStatus.cleaned,
          },
          {
            id: 'noaction',
            title: t('dashboard:No_Action'),
            value: IncidentStatus.noaction,
          },
        ]}
        name='incident-status'
        defaultValue={t('dashboard:All_Incidents')}
        value={status}
        onSelect={statusSelectHandler}
      />
    </div>
  );
};
export default StatusFiltering;
