import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants, styleConstants } from '../UI/style.constants';

export const styles = StyleSheet.create({
  legalText: {
    fontSize: '3.2mm',
    color: colorConstants.lightGray,
    padding: '2mm 0',
    width: styleConstants.rightFullWidth,
  },
  subContent: {
    width: styleConstants.rightFullWidth,
    display: 'flex',
  },
  totalSection: {
    paddingTop: '1.5mm',
    borderTop: `1pt dotted ${colorConstants.periopsisMainColor}`,
  },
});
