import OrderDataCard from '../../LoginRegister/SignUp/RegistrationCheckout/OrderDetailsSection/OrderDataCard/OrderDataCard';
import DataRow from '../../../UI/DataRow/DataRow';
import {
  ICheckoutContract,
  ICheckoutProforma,
} from '../../../../tsTypes/checkout.interfaces';
import classes from './ProformaAndContract.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  proformaInvoice: ICheckoutProforma;
  contract: ICheckoutContract;
}

const ProformaAndContract = ({ proformaInvoice, contract }: IProps) => {
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  const invoiceDate = new Date(proformaInvoice.invoiceDate).toLocaleDateString(
    language,
    { year: 'numeric', month: 'long', day: '2-digit' }
  );
  const invoiceDueDate = new Date(proformaInvoice.dueDate).toLocaleDateString(
    language,
    { year: 'numeric', month: 'long', day: '2-digit' }
  );

  const contractStartDate = new Date(contract.startDate).toLocaleDateString(
    language,
    { year: 'numeric', month: 'long', day: '2-digit' }
  );
  const contractEndDate = new Date(contract.endDate).toLocaleDateString(
    language,
    {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }
  );

  return (
    <OrderDataCard heading={t('checkout:proforma_contract')}>
      <DataRow
        heading={t('checkout:proforma_invoice_number')}
        data={proformaInvoice.proformaInvoiceNumber}
        removeSeperator
      />
      <DataRow
        heading={t('checkout:order_number')}
        data={proformaInvoice.orderNumber}
        removeSeperator
      />
      <DataRow
        heading={t('checkout:proforma_invoice_date')}
        data={invoiceDate}
        removeSeperator
      />
      <DataRow
        heading={t('checkout:proforma_invoice_due_date')}
        data={invoiceDueDate}
        removeSeperator
      />
      <DataRow
        heading={t('checkout:contract_id')}
        data={contract.contractId}
        removeSeperator
        className={classes.ContractIdRow}
      />
      <DataRow
        heading={t('checkout:service_contract_start_date')}
        data={contractStartDate}
        removeSeperator
      />
      <DataRow
        heading={t('checkout:service_contract_end_date')}
        data={contractEndDate}
        removeSeperator
      />
    </OrderDataCard>
  );
};

export default ProformaAndContract;
