import { IExistingReport } from '../../../../tsTypes/interfaces';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import garbageBag from '../../../../assets/PeriopsisGarbageBagIcon.png';
import CircleImage from '../../../UI/CircleImage/CircleImage';
import classes from './MessageListItem.module.scss';
import Subject from '../../../UI/Subject/Subject';
import { useTranslation } from 'react-i18next';

interface IProps {
  message: Pick<IExistingReport, 'incidentType' | 'detectedAt' | 'id'>;
  onRead?: (messageId: string) => void;
  onArchive?: (messageId: string) => void;
  onDelete?: (messageId: string) => void;
  onClose?: () => void;
  hideButtons?: boolean;
  archiveButtonLabel?: string;
  hideArcihveButton?: boolean;
}

const MessageListItem = ({
  message,
  onRead,
  onArchive,
  onDelete,
  onClose,
  hideButtons,
  archiveButtonLabel,
  hideArcihveButton,
}: IProps) => {
  const { t } = useTranslation();

  const readMessageHandler = (messageId: string) => {
    if (onRead && typeof onRead === 'function') {
      onRead(messageId);
    }
  };

  const archiveMessageHandler = (messageId: string) => {
    if (onArchive && typeof onArchive === 'function') {
      onArchive(messageId);
    }
  };

  const deleteMessageHandler = (messageId: string) => {
    if (onDelete && typeof onDelete === 'function') {
      onDelete(messageId);
    }
  };

  return (
    <div className={classes.MessageItemContainer}>
      <div className={classes.contentContainer}>
        <div className={classes.IncidentDetails}>
          <CircleImage
            src={garbageBag}
            alt={message.incidentType}
            className={classes.Image}
          />
          <Subject
            heading={message.incidentType}
            subheading={new Date(message.detectedAt).toLocaleDateString('tr', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          />
        </div>
        {onClose !== undefined && typeof onClose === 'function' ? (
          <div className={classes.Actions}>
            <StandardButton
              label={t('reporting:close')}
              btnType='orange'
              type='button'
              className={classes.MessageItemBtn}
              onClick={onClose}
            />
          </div>
        ) : null}
        {!hideButtons ? (
          <div className={classes.Actions}>
            <StandardButton
              label={t('reporting:read')}
              btnType='primary'
              type='button'
              className={classes.MessageItemBtn}
              onClick={() => readMessageHandler(message.id)}
            />
            {!hideArcihveButton ? (
              <StandardButton
                label={archiveButtonLabel || t('reporting:send_to_archive')}
                btnType='plain'
                type='button'
                className={classes.MessageItemBtn}
                onClick={() => archiveMessageHandler(message.id)}
              />
            ) : null}
            {onDelete && typeof onDelete === 'function' ? (
              <StandardButton
                label={t('reporting:delete_report')}
                btnType='orange'
                type='button'
                className={classes.MessageItemBtn}
                onClick={() => deleteMessageHandler(message.id)}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MessageListItem;
