import { useState } from 'react';
import { useGetContractYearsQuery } from '../../../../features/statistics/statisticsSlice';
import { IListObject } from '../../../../tsTypes/types.model';
import SelectionContainer from '../../../UI/SelectionContainer/SelectionContainer';
import { IStatisticsProps } from '../Statistics';
import StatisticsMainContainer from '../StatisticsMainContainer/StatisticsMainContainer';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import StatisticsViewSelect from '../StaticticsViewSelect/StatisticsViewSelect';
import IncidentsTypeDistribution from './IncidentsTypeDistribution/IncidentsTypeDistribution';
import MonthlyIncidentStatistics from './MonthlyIncidents/MonthlyIncidentStatistics';
import IncidentTableStats from './IncidentsTableStats/IncidentTableStats';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

const IncidentStatistics = ({
  isSelected,
  contractId,
  currentYear,
  selectYear,
}: IStatisticsProps) => {
  const [selectedIncidentStatistics, setSelectedIncidentStatistics] = useState<
    'distribution' | 'monthly' | 'percentage' | 'tables'
  >('distribution');
  const { isLoading, isError, data, isSuccess } = useGetContractYearsQuery(
    contractId,
    {
      skip: !isSelected,
    }
  );
  const { t } = useTranslation();

  let content: React.ReactNode | null = null;
  let years: IListObject[] = [];
  let hasIncidents = false;

  if (isLoading) {
    content = (
      <FetchingReports message={t('statistics:fetching_statistics_data')} />
    );
  }

  if (isError) {
    content = (
      <NoDataFound message={t('statistics:contract_years_fetch_error')} />
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'years' in data &&
    data.years &&
    Array.isArray(data.years) &&
    'hasIncidents' in data
  ) {
    years = [...data.years];
    hasIncidents = data.hasIncidents;
  }

  if (years.length === 0 && !isLoading && !hasIncidents) {
    content = (
      <NoDataFound
        icon={<FaFilterCircleXmark />}
        message={t('statistics:no_reported_incidents_no_statistics')}
      />
    );
  }

  if (years.length === 0 && !isLoading && hasIncidents) {
    content = (
      <NoDataFound message={t('statistics:contract_years_fetch_error')} />
    );
  }

  if (
    years &&
    years.length > 0 &&
    selectedIncidentStatistics === 'distribution'
  ) {
    content = (
      <IncidentsTypeDistribution
        contractId={contractId}
        isSelected
        isYearsReady={isSuccess}
        currentYear={currentYear}
      />
    );
  } else if (
    years &&
    years.length > 0 &&
    selectedIncidentStatistics === 'monthly'
  ) {
    content = (
      <MonthlyIncidentStatistics
        contractId={contractId}
        isSelected
        isYearsReady={isSuccess}
        currentYear={currentYear}
      />
    );
  } else if (
    years &&
    years.length > 0 &&
    selectedIncidentStatistics === 'tables'
  ) {
    content = (
      <IncidentTableStats
        contractId={contractId}
        isSelected
        isYearsReady={isSuccess}
        currentYear={currentYear}
      />
    );
  }

  return (
    <SelectionContainer isSelected={isSelected}>
      <StatisticsMainContainer
        heading={t('statistics:annual_incident_statistics')}
        years={years}
        onSelect={selectYear}
        selected={currentYear.toString()}
        isLoading={isLoading}
      >
        <StatisticsViewSelect
          onSelect={setSelectedIncidentStatistics}
          selected={selectedIncidentStatistics}
          buttonLabels={[
            t('statistics:incident_distribution_by_type'),
            t('statistics:monthly_incident_statistics'),
            t('statistics:monthly_incident_percentages'),
            t('statistics:incident_tables'),
          ]}
          existingButtons={[true, true, false, true]}
        />
        {content}
      </StatisticsMainContainer>
    </SelectionContainer>
  );
};

export default IncidentStatistics;
