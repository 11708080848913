import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../UI/style.constants';

export const SelectedPolygonsListStyle = StyleSheet.create({
  ListItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    fontSize: '3.1mm',
    color: colorConstants.lightGray,
    paddingBottom: '1.8mm',
    paddingLeft: '1mm',
    paddingRight: '1mm',
  },
});
