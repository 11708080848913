import { useDispatch, useSelector } from 'react-redux';
import { selectAllAuthValues } from '../../../../features/auth/authSlice';
import NavbarManuContainer from '../../NavbarMenuContainer/NavbarManuContainer';
import { useGetContractNotificationsQuery } from '../../../../features/notifications/notificationsSlice';
import useCurrentContract from '../../../../CustomHooks/useCurrentContract';
import { IContractNotification } from '../../../../tsTypes/interfaces';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import classes from './NavbarNotificationMenu.module.scss';
import CircleImage from '../../CircleImage/CircleImage';
import garbageBag from '../../../../assets/PeriopsisGarbageBagIcon.png';
import { AppDispatch } from '../../../../app/store';
import { setShowNotificationsMenu } from '../../../../features/UI/uiSlice';
import { BsEnvelopeExclamationFill } from 'react-icons/bs';
import SimpleLoading from '../../SimpleLoading/SimpleLoading';
import { FaExclamationCircle } from 'react-icons/fa';
import { frontendConstants } from '../../../../constants/constants';
import ErrorControlComponent from '../../ErrorControlComponent/ErrorControlComponent';

const NavbarNotificationMenu = () => {
  const { isAuthenticated } = useSelector(selectAllAuthValues);
  const contractId = useCurrentContract();
  const {
    isLoading: isLoadingNotifications,
    isError: isNotificationsError,
    data,
    error,
  } = useGetContractNotificationsQuery(contractId, {
    skip: !contractId,
    pollingInterval: frontendConstants.pollInterval,
  });

  const dispatch = useDispatch<AppDispatch>();

  let unreadNotifications: IContractNotification[] = [];
  let text = '';
  let content: React.ReactNode | null = null;

  if (!isAuthenticated) {
    return null;
  }

  const closeMenuHandler = () => {
    dispatch(setShowNotificationsMenu(false));
  };

  if (isLoadingNotifications) {
    content = (
      <div className={classes.NotificationProcess}>
        <p>Loading Notifications...</p>
        <SimpleLoading />
      </div>
    );
  }

  if (isNotificationsError) {
    content = (
      <div className={classes.NotificationProcess}>
        <ErrorControlComponent error={error}>
          <FaExclamationCircle />
          <p>
            An unexpected error occurred while attempting to retrieve
            notifications. Kindly try again later.
          </p>
        </ErrorControlComponent>
      </div>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'unreadNotifications' in data &&
    data.unreadNotifications &&
    data.unreadNotifications.length > 0 &&
    !isLoadingNotifications &&
    !isNotificationsError
  ) {
    unreadNotifications = data.unreadNotifications;

    content = (
      <Fragment>
        {unreadNotifications.map((notification) => {
          if (
            notification.subject === 'New Incidents' &&
            notification.contractIncidents.length === 1
          ) {
            text = `${notification.contractIncidents.length} new incident detected`;
          } else if (
            notification.subject === 'New Incidents' &&
            notification.contractIncidents.length > 1
          ) {
            text = `${notification.contractIncidents.length} new incidents detected`;
          } else {
            text = `A new ${notification.subject} added`;
          }

          return (
            <Link
              to={`/notifications?notification=${notification.id}`}
              key={notification.id}
              className={classes.NotificationItemContainer}
              onClick={closeMenuHandler}
            >
              <div className={classes.Content}>
                <CircleImage
                  src={garbageBag}
                  alt='A Blue Color Garbage Bag'
                  className={classes.Icon}
                />
                <div>
                  <h3>{text}</h3>
                  <data>
                    {formatDistance(new Date(notification.date), new Date(), {
                      addSuffix: true,
                    })}
                  </data>
                </div>
              </div>
            </Link>
          );
        })}
      </Fragment>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'unreadNotifications' in data &&
    data.unreadNotifications &&
    data.unreadNotifications.length === 0 &&
    !isLoadingNotifications &&
    !isNotificationsError
  ) {
    content = (
      <Fragment>
        <div className={classes.NotificationProcess}>
          <BsEnvelopeExclamationFill />
          <p>There are currently no notifications available at the moment.</p>
        </div>
      </Fragment>
    );
  }

  return (
    <NavbarManuContainer heading='Notifications'>{content}</NavbarManuContainer>
  );
};

export default NavbarNotificationMenu;
