import { useState } from 'react';
import FormModal from '../../../../UI/FormModal/FormModal';
import { useGetReportsOfIncidentQuery } from '../../../../../features/incidents/incidentsSlice';
import { IExistingReport } from '../../../../../tsTypes/interfaces';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import { IoDocumentLock } from 'react-icons/io5';
import IncidentReportItem from './IncidentReportItem/IncidentReportItem';
import FetchingReports from '../IncidentReportFrom/FetchingReports/FetchingReports';
import Paragraph from '../../../../UI/Paragraph/Paragraph';
import AllIncidentsInformation from './AllIncidentsInformation/AllIncidentsInformation';
import useSessionLogout from '../../../../../CustomHooks/useSessionLogout';
import classes from './AllIncidentReports.module.scss';

interface IProps {
  showAllIncidentReports: boolean;
  contractId: string | null;
  contractIncidentId: string | null;
  onClose: () => void;
}

const AllIncidentReports = ({
  showAllIncidentReports,
  contractId,
  contractIncidentId,
  onClose,
}: IProps) => {
  const { isLoading, isError, error, data } = useGetReportsOfIncidentQuery(
    {
      contractId: contractId || '',
      contractIncidentId: contractIncidentId || '',
      filter: 'all',
      withArchived: 'no',
    },
    { skip: !showAllIncidentReports }
  );
  useSessionLogout({ isError, error });
  const [showInfo, setShowInfo] = useState(false);

  let reports: IExistingReport[] = [];
  let content: React.ReactNode | null = null;

  if (!contractId || !contractIncidentId || !showAllIncidentReports) {
    return null;
  }

  if (isLoading) {
    content = (
      <FetchingReports message='Fetching all reports of incident. Please wait...' />
    );
  }

  if (isError) {
    content = (
      <NoDataFound message='We encountered an error while fetching the incident reports. Please try again later. If the issue persists, feel free to contact support for assistance.' />
    );
  }

  if (data && Array.isArray(data)) {
    reports = [...data];
  }

  if (reports.length === 0 && !isLoading && !isError) {
    content = (
      <NoDataFound
        icon={<IoDocumentLock />}
        message='No reports found. It seems that authorized users who submitted the reports have either deleted them or transferred them to the archive. Keep in mind that reports older than 30 days may be deleted or archived. If you have any questions or need assistance, feel free to reach out.'
      />
    );
  }

  if (reports.length > 0) {
    content = reports.map((report) => (
      <IncidentReportItem incidentReport={report} key={report.id} />
    ));
  }

  const showHideInfoHandler = () => {
    setShowInfo((prevState) => !prevState);
  };

  return (
    <FormModal
      show={showAllIncidentReports}
      heading='All Reports of Incident'
      showInfo={showInfo}
      onShowInformation={showHideInfoHandler}
      onClick={onClose}
    >
      {!showInfo ? (
        <div className={classes.ReportsWrapper}>
          <div className={classes.Common}>
            <Paragraph
              paragraph='This section displays all reports sent by authorized users to the
          various departments of the organization. If this section appears empty
          despite an incident being reported, it indicates that the authorized
          user has either moved the reports to the archive or deleted them,
          rendering them no longer visible.'
              noEllipsis
              noShortenMessage
            />
          </div>
          <div className={classes.ReportsList}>{content}</div>
        </div>
      ) : (
        <AllIncidentsInformation onClose={showHideInfoHandler} />
      )}
    </FormModal>
  );
};

export default AllIncidentReports;
