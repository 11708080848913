import { Text, View, Document } from '@react-pdf/renderer';
import { IAreaOfInterest } from '../../../tsTypes/types.components';
import { SelectedPolygonsListStyle } from './SelectedPolygonList.style';
import PageLayout from '../UI/PageLayout/PageLayout';
import SideHeading from '../UI/SideHeading/SideHeading';

import { Currency } from '../../../tsTypes/types.model';
import UL from '../UI/ListContainer/UL';
import FixedText from '../UI/FixedText/FixedText';
import getTotalPolygonPrice from '../../../utils/getTotalPolygonPrice';
import { colorConstants } from '../UI/style.constants';
import divideArrayIntoChunks from '../../../utils/divideArrayIntoChunks';
import { invoiceCalculator } from '../../../utils/invoiceCalculator';

interface IProps {
  selectedPolygons: IAreaOfInterest[];
  pricePerSQM?: number;
  currency?: string;
}

const SelectedPolygonsList = ({
  selectedPolygons,
  pricePerSQM,
  currency,
}: IProps) => {
  let currencyAtDocument: Currency = '€';
  let price = 0;
  let listPerPage: IAreaOfInterest[][] = [];
  let totalArea = 0;

  if (
    !selectedPolygons ||
    !Array.isArray(selectedPolygons) ||
    (Array.isArray(selectedPolygons) && selectedPolygons.length === 0)
  ) {
    return (
      <Document>
        <PageLayout name='No Selected Polygons'>
          <View>
            <Text>No Selected Polygon Found</Text>
          </View>
        </PageLayout>
      </Document>
    );
  }

  if (currency) {
    currencyAtDocument = currency as Currency;
  }

  if (pricePerSQM) {
    price = pricePerSQM;
  }

  if (
    selectedPolygons &&
    Array.isArray(selectedPolygons) &&
    selectedPolygons.length > 0
  ) {
    listPerPage = divideArrayIntoChunks(selectedPolygons, 42, 45);
    totalArea = selectedPolygons.reduce(
      (total, polygon) => total + polygon.area,
      0
    );
  }

  return (
    <Document
      title='Package Pricing'
      author='Periopsis Ltd'
      subject='Periopsis Service Packages'
      creator='Periopsis Ltd'
    >
      {listPerPage.map((list, index) => (
        <PageLayout
          name={`${selectedPolygons.length} Polygons Selected`}
          currency={currencyAtDocument}
          heading={`Selected Polygons (Page ${index + 1})`}
          key={index}
        >
          <View>
            <SideHeading
              heading={`List of Selected Polygons (${list.length} Polygons)`}
            />
          </View>
          <UL>
            <View
              style={{
                ...SelectedPolygonsListStyle.ListItem,
                backgroundColor: colorConstants.periopsisMainColor,
                color: '#fff',
                marginBottom: '1mm',
              }}
            >
              <FixedText content='Name' size={2} />
              <FixedText content='Division' />
              <FixedText content='Country' />
              <FixedText content='Area' />
              <FixedText content='Price' textAlign='center' />
            </View>
            {list.map((polygon) => (
              <View
                style={SelectedPolygonsListStyle.ListItem}
                key={polygon.mid}
              >
                <FixedText content={polygon.polygonName} size={2} />
                <FixedText content={polygon.district} />
                <FixedText content={polygon.country} />
                <FixedText content={`${polygon.area.toFixed(2)} sq km`} />
                <FixedText
                  content={`${currencyAtDocument}${getTotalPolygonPrice(
                    polygon.area,
                    price
                  )}`}
                  textAlign='center'
                />
              </View>
            ))}
            {index === listPerPage.length - 1 ? (
              <View
                style={{
                  ...SelectedPolygonsListStyle.ListItem,
                  borderTop: '0.3mm',
                  borderTopColor: colorConstants.periopsisMainColor,
                  borderTopStyle: 'dotted',
                  paddingTop: '1mm',
                }}
              >
                <FixedText content='Total Price' size={2} />
                <FixedText content='' />
                <FixedText content='' />
                <FixedText content='' />
                <FixedText
                  content={`${currencyAtDocument} ${getTotalPolygonPrice(
                    totalArea,
                    price
                  )}`}
                  textAlign='center'
                />
              </View>
            ) : null}
          </UL>
        </PageLayout>
      ))}
    </Document>
  );
};

export default SelectedPolygonsList;
