import { useState, useRef } from 'react';
import {
  selectAllRegistrationFormValues,
  useGetOrganizationByIdQuery,
} from '../../../../../../../../features/registration/registrationSlice';
import { useSelector } from 'react-redux';
import PresentationWrapper from '../../../../../../../UI/PresentationWrapper/PresentationWrapper';
import { catchError } from '../../../../../../../../utils/fetches';
import DataRow from '../../../../../../../UI/DataRow/DataRow';
import classes from './SelectedOrganization.module.scss';
import { AddressLabel } from '../../../../../../../../tsTypes/types.model';
import {
  AddressType,
  IAreaOfInterest,
} from '../../../../../../../../tsTypes/types.components';
import ActionContainer from '../../../../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../../../../UI/FormElements/Button/Button';
import UpdateForm from './UpdateForm/UpdateForm';
import getTotalPolygonPrice from '../../../../../../../../utils/getTotalPolygonPrice';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../../../utils/getTranslationKey';

interface IProps {
  selectedOrganizationId: string;
}

const SelectedOrganization = ({ selectedOrganizationId }: IProps) => {
  const { currentPageNumber, selectedPackage, areasOfInterest } = useSelector(
    selectAllRegistrationFormValues
  );
  const [updateData, setUpdateData] = useState(false);
  const areaRef = useRef<HTMLDivElement>(null);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();

  const {
    data: organization,
    isLoading,
    isError,
    error,
  } = useGetOrganizationByIdQuery(selectedOrganizationId, {
    skip: selectedOrganizationId === '' || currentPageNumber !== 6,
  });
  let webAddress = '';
  let division: AddressLabel | null = null;
  let streetAddress: AddressLabel | null = null;
  let postalCode: AddressLabel | null = null;
  let settlement: AddressLabel | null = null;
  let subdivison: AddressLabel | null = null;
  let addressDetails: AddressLabel | null = null;
  let country: string | null = null;
  let addressType: AddressType = { id: '', name: '' };
  let hasAddressType = false;
  let areasOfInterestArray: IAreaOfInterest[] = [];
  let areDivisionAndSettlementSame = false;
  let areSubdivisionAndSettlementSame = false;
  let totalArea = 0;
  let price = 0;
  let currency = '€';
  let numberOfPolygons = 0;
  let vatNumber = '';

  if (!organization && !isLoading && !isError) {
    return null;
  }

  if (organization && 'web' in organization && organization.web) {
    webAddress = organization.web;
  }

  if (
    organization &&
    'address' in organization &&
    organization.address &&
    'streetAddress' in organization.address &&
    organization.address.streetAddress &&
    'label' in organization.address.streetAddress &&
    organization.address.streetAddress.label
  ) {
    streetAddress = organization.address.streetAddress;
  }

  if (
    organization &&
    'address' in organization &&
    organization.address &&
    'postalCode' in organization.address &&
    organization.address.postalCode &&
    'label' in organization.address.postalCode &&
    organization.address.postalCode.label
  ) {
    postalCode = organization.address.postalCode;
  }

  if (
    organization &&
    'address' in organization &&
    organization.address &&
    'settlement' in organization.address &&
    organization.address.settlement &&
    'label' in organization.address.settlement &&
    organization.address.settlement.label
  ) {
    settlement = organization.address.settlement;
  }

  if (
    organization &&
    'address' in organization &&
    organization.address &&
    'division' in organization.address &&
    organization.address.division &&
    'label' in organization.address.division &&
    organization.address.division.label
  ) {
    division = organization.address.division;
  }

  if (
    organization &&
    'address' in organization &&
    organization.address &&
    'subDivision' in organization.address &&
    organization.address.subDivision &&
    'label' in organization.address.subDivision &&
    organization.address.subDivision.label
  ) {
    subdivison = organization.address.subDivision;
  }

  if (
    organization &&
    'address' in organization &&
    organization.address &&
    'addressDetails' in organization.address &&
    organization.address.addressDetails &&
    'label' in organization.address.addressDetails &&
    organization.address.addressDetails.label
  ) {
    addressDetails = organization.address.addressDetails;
  }

  if (
    organization &&
    'address' in organization &&
    organization.address &&
    'countryDetails' in organization.address &&
    organization.address.countryDetails &&
    'country' in organization.address.countryDetails &&
    organization.address.countryDetails.country
  ) {
    country = organization.address.countryDetails.country;
  }

  if (
    organization &&
    'address' in organization &&
    organization.address &&
    'addressType' in organization.address &&
    organization.address.addressType &&
    'id' in organization.address.addressType &&
    organization.address.addressType.id &&
    'name' in organization.address.addressType &&
    organization.address.addressType.name
  ) {
    addressType = {
      id: organization.address.addressType.id,
      name: organization.address.addressType.name,
    };
    hasAddressType = true;
  }

  if (organization && 'vatNumber' in organization && organization.vatNumber) {
    vatNumber = organization.vatNumber;
  }

  if (division && settlement) {
    areDivisionAndSettlementSame = division.name === settlement.name;
  }

  if (subdivison && settlement) {
    areSubdivisionAndSettlementSame = subdivison.name === settlement.name;
  }

  if (
    areasOfInterest &&
    Array.isArray(areasOfInterest) &&
    areasOfInterest.length > 0
  ) {
    areasOfInterestArray = [...areasOfInterest];
    totalArea = areasOfInterest.reduce(
      (total, polygon) => total + polygon.area,
      0
    );
    numberOfPolygons = areasOfInterest.length;
  }

  if (
    selectedPackage &&
    typeof selectedPackage === 'object' &&
    'price' in selectedPackage &&
    selectedPackage.price
  ) {
    price = selectedPackage.price;
    currency = selectedPackage.currency;
  }

  const updateDataHandler = () => {
    setUpdateData(true);

    if (organization && 'web' in organization && organization.web) {
      localStorage.setItem('update-organization-website', organization.web);
    }

    if (organization && 'phone' in organization && organization.phone) {
      localStorage.setItem(
        'update-organization-registration-phone',
        organization.phone
      );
    }

    if (organization && 'address' in organization && organization.address) {
      localStorage.setItem(
        'update-organization-address',
        JSON.stringify(organization.address)
      );
    }

    //'organization-website', 'update'
  };

  const closeUpdateFormHandler = () => {
    setUpdateData(false);
    localStorage.removeItem('periopsis-update-organization-website');
    localStorage.removeItem('update-organization-registration-phone');
    localStorage.removeItem('update-organization-address');
    localStorage.removeItem('periopsis-update-organization-name');
  };

  if (updateData && organization) {
    return (
      <UpdateForm
        shouldSkip={!updateData}
        organization={organization}
        onClose={closeUpdateFormHandler}
      />
    );
  }

  //Scroll Navigation !css also helps to get better results. Especially scroll-snap
  //We can use same idea for images and it also works well in mobile. (unprefixed global use : 92.72%)
  const scrollToNextCardHandler = () => {
    if (areaRef && areaRef.current) {
      areaRef.current.scrollLeft += areaRef.current.clientWidth;
    }
    setCount((prevState) => prevState + 1);
  };

  const scrollToPrevCardHandler = () => {
    if (areaRef && areaRef.current) {
      areaRef.current.scrollLeft -= areaRef.current.clientWidth;
    }
    setCount((prevState) => prevState - 1);
  };
  /**
   * @TODO Add max height to Areas of Interest Cards, scroll should apply to that area instead of whole page!
   */

  return (
    <div>
      <PresentationWrapper
        isLoading={isLoading}
        isError={isError}
        error={catchError(error)}
        message='An error occured while fetching the organization data.'
      >
        <div className={classes.OrganizationDataContainer}>
          <DataRow
            heading={t('auth:Organization_Name')}
            data={organization ? organization.organizationName : ''}
            removeSeperator
          />
          <DataRow
            heading={t('auth:vat_number')}
            data={vatNumber}
            removeSeperator
          />
          <DataRow
            heading={t('auth:phone_number')}
            data={
              organization
                ? `${organization.phoneCountry.countryCode}${organization.phone}`
                : ''
            }
            removeSeperator
          />
          <DataRow
            heading={t('auth:email')}
            data={organization ? organization.email : ''}
            removeSeperator
          />
          {webAddress ? (
            <DataRow
              heading={t('auth:web_address')}
              data={webAddress}
              removeSeperator
            />
          ) : null}
          <DataRow
            heading={t('auth:organization_type')}
            data={
              organization
                ? t(`auth:${getTranslationKey(organization.organizationType)}`)
                : ''
            }
            removeSeperator
          />
          <section className={classes.AddressSection}>
            <div className={classes.HeadingSection}>
              {hasAddressType ? (
                <h3>
                  {t(`translations:${getTranslationKey(addressType.name)}`)}
                </h3>
              ) : (
                <h3> {t(`translations:organization_address`)}</h3>
              )}
            </div>
            <div className={classes.addressDataConatiner}>
              {streetAddress ? <p>{streetAddress.name}</p> : null}
              {postalCode ? (
                <p>
                  {postalCode.label} {postalCode.name}
                </p>
              ) : null}
              {settlement ? <p>{settlement.name}</p> : null}
              {subdivison && !areSubdivisionAndSettlementSame ? (
                <p>{subdivison.name}</p>
              ) : null}
              {division && !areDivisionAndSettlementSame ? (
                <p>{division.name}</p>
              ) : null}
              {country ? (
                <p>{t(`auth:${getTranslationKey(country)}`)}</p>
              ) : null}
            </div>
            {addressDetails ? (
              <div className={classes.AddressDetailsSection}>
                <h3>{t('auth:Address_Details')}</h3>
                <p>{addressDetails.name}</p>
              </div>
            ) : null}
          </section>
          <ActionContainer className={classes.UpdateActions}>
            <Button onClick={updateDataHandler}>{t('auth:Update')}</Button>
          </ActionContainer>
          <section className={classes.AreasOfInterestSection}>
            <div className={classes.HeadingSection}>
              <h3>{t('auth:selected_polygons')}</h3>
            </div>
            <div className={classes.DataSection}>
              <DataRow
                heading={t('auth:Total_Area')}
                data={`${totalArea.toFixed(2)} km2`}
                removeSeperator
              />
              <DataRow
                heading={t('auth:Number_of_Polygons')}
                data={`${numberOfPolygons}`}
                removeSeperator
              />
            </div>
            <div className={classes.AreaOfInterestList} ref={areaRef}>
              {areasOfInterestArray.map((area, index) => (
                <div
                  key={area.mid}
                  className={classes.AreaOfInterestItem}
                  id={area.mid}
                >
                  <div className={classes.Area}>
                    <div className={classes.AreaNumber}>
                      <span>{index + 1}</span>
                    </div>
                    <DataRow
                      heading={t('auth:district')}
                      data={t(
                        `translations:${getTranslationKey(area.district)}`
                      )}
                    />
                    <DataRow
                      heading={t('auth:country')}
                      data={t(`auth:${getTranslationKey(area.country)}`)}
                    />
                    <DataRow
                      heading={t('auth:area')}
                      data={`${area.area} ${t(
                        `auth:${getTranslationKey(area.areaUnit)}`
                      )}`}
                    />
                    <DataRow
                      heading={t('auth:total_price')}
                      data={`${currency} ${getTotalPolygonPrice(
                        area.area,
                        price
                      )}`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <ActionContainer className={classes.AreaOfInterestCardActions}>
              <Button
                type='button'
                onClick={scrollToPrevCardHandler}
                isFake={count === 0}
                btnClass={classes.Navigation}
              >
                {t('auth:pevious_polygon')}
              </Button>
              <Button
                type='button'
                onClick={scrollToNextCardHandler}
                btnClass={classes.Navigation}
                isFake={numberOfPolygons - 1 === count}
              >
                {t('auth:next_polygon')}
              </Button>
            </ActionContainer>
          </section>
        </div>
      </PresentationWrapper>
    </div>
  );
};

export default SelectedOrganization;
