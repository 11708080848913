import { Fragment } from 'react';
import { IReportListItem } from '../../../../tsTypes/interfaces';
import MessageListItem from '../MessageListItem/MessageListItem';
import { getDaysFromCurrentDateTo } from '../../../../utils/getDaysFromCurrentDateTo';

interface IProps {
  messages: IReportListItem[];
  onRead?: (messageId: string) => void;
  onArchive?: (messageId: string) => void;
  onDelete?: (messageId: string) => void;
  archiveButtonLabel?: string;
}

const MessageList = ({
  messages,
  onRead,
  onArchive,
  onDelete,
  archiveButtonLabel,
}: IProps) => {
  return (
    <Fragment>
      {messages.map((message) => (
        <MessageListItem
          message={message}
          key={message.id}
          onRead={onRead}
          onArchive={onArchive}
          archiveButtonLabel={archiveButtonLabel}
          onDelete={onDelete}
          hideArcihveButton={
            getDaysFromCurrentDateTo(new Date(message.reportDate)) < 91
          }
        />
      ))}
    </Fragment>
  );
};

export default MessageList;
