import React from 'react';
import EditableRowDataContainer from '../../../UI/EditableRowDataContainer/EditableRowDataContainer';

interface ISettingProps {
  label: string;
  Icon: React.ReactNode;
  style?: React.CSSProperties;
  children: React.ReactNode;
  optionalText?: string;
}

const Setting = ({
  label,
  Icon,
  style,
  children,
  optionalText,
}: ISettingProps) => {
  return (
    <EditableRowDataContainer
      rowIcon={Icon}
      label={label}
      style={style}
      optionalInfoText={optionalText}
    >
      {children}
    </EditableRowDataContainer>
  );
};

export default Setting;
