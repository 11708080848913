import { IExistingReport } from '../../../../../../tsTypes/interfaces';
import { useState } from 'react';
import StandardButton from '../../../../../UI/FormElements/StandardButton/StandardButton';
import CircleImage from '../../../../../UI/CircleImage/CircleImage';
import garbageBag from '../../../../../../assets/PeriopsisGarbageBagIcon.png';
import MessageContent from '../../../../Send/MessageContent/MessageContent';
import Subject from '../../../../../UI/Subject/Subject';
import classes from './IncidentReportItem.module.scss';

interface IProps {
  incidentReport: IExistingReport;
}

const IncidentReportItem = ({ incidentReport }: IProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const showDetailsHandler = () => {
    setShowDetails((prevState) => !prevState);
  };

  return (
    <div className={classes.IncidentReportItemContainer}>
      {!showDetails ? (
        <div className={classes.ReportSummary}>
          <div className={classes.Content}>
            <CircleImage
              src={garbageBag}
              alt={incidentReport.incidentType}
              className={classes.Image}
            />

            <Subject
              heading={incidentReport.reportTo}
              subheading={new Date(
                incidentReport.reportDate
              ).toLocaleDateString('tr', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            />
          </div>

          <StandardButton
            label='Read'
            btnType='primary'
            type='button'
            onClick={showDetailsHandler}
            className={classes.MessageItemBtn}
          />
        </div>
      ) : null}
      {showDetails ? (
        <div className={classes.ContentContainer}>
          <MessageContent
            selectedReport={incidentReport}
            onClose={showDetailsHandler}
          />
        </div>
      ) : null}
    </div>
  );
};

export default IncidentReportItem;
