import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../app/store';
import {
  selectAllUIValues,
  setShowNotificationsMenu,
  setShowUserNavMenu,
} from '../features/UI/uiSlice';

const useHideNavManus = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { showNavNotificationsMenu, showNavUserMenu } =
    useSelector(selectAllUIValues);

  useEffect(() => {
    const closeMenuHandler = (e: KeyboardEvent) => {
      if (showNavNotificationsMenu || showNavUserMenu) {
        const key = e.key;

        if (key === 'Escape') {
          dispatch(setShowUserNavMenu(false));
          dispatch(setShowNotificationsMenu(false));
        }
      }
    };

    const clickOutsideHandler = (e: MouseEvent) => {
      if (showNavNotificationsMenu || showNavUserMenu) {
        const tagName = (e.target as Element).tagName;

        if (
          tagName === 'HTML' ||
          tagName === 'MAIN' ||
          tagName === 'NAV' ||
          tagName === 'DIV'
        ) {
          dispatch(setShowUserNavMenu(false));
          dispatch(setShowNotificationsMenu(false));
        }
      }
    };

    document.addEventListener('click', clickOutsideHandler);
    document.addEventListener('keyup', closeMenuHandler);

    return () => {
      document.removeEventListener('keypress', closeMenuHandler);
      document.removeEventListener('click', clickOutsideHandler);
    };
  }, [dispatch, showNavNotificationsMenu, showNavUserMenu]);
};

export default useHideNavManus;
