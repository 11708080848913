import React from 'react';
import { ColorClassType, heading } from '../../../tsTypes/types.components';
import { getColorClass } from '../../../utils/componentUtilFns';
import classes from './HeadingBar.module.scss';

interface IProps {
  children?: React.ReactNode;
  heading: string;
  headingSize?: heading;
  headingType?: ColorClassType;
  style?: React.CSSProperties;
  icon?: JSX.Element;
  buttonIcon?: JSX.Element;
  hasButton?: boolean;
  buttonLabel?: string;
  onClick?: () => void;
  childStyles?: React.CSSProperties;
  className?: string;
  title?: string;
}

const HeadingElement = ({
  headingSize,
  heading,
}: Omit<IProps, 'children' | 'headingType' | 'className'>) => {
  switch (headingSize) {
    case 1:
      return <h1>{heading}</h1>;
    case 2:
      return <h2>{heading}</h2>;
    case 3:
      return <h3>{heading}</h3>;
    case 4:
      return <h4>{heading}</h4>;
    case 5:
      return <h5>{heading}</h5>;

    default:
      return <h2>{heading}</h2>;
  }
};

const HeadingBar = ({
  children,
  heading,
  headingType,
  style,
  icon,
  buttonIcon,
  hasButton,
  buttonLabel,
  onClick,
  headingSize = 2,
  childStyles,
  className,
  title,
}: IProps) => {
  const selectedHeadingClass = headingType ? getColorClass(headingType) : '';

  return (
    <div
      className={`${classes.HeadingBarContainer} ${classes[selectedHeadingClass]} `}
      style={style}
    >
      <div
        className={`${classes.HeadingSection} ${className ? className : ''}`}
      >
        {icon ? icon : null}
        <HeadingElement heading={heading} headingSize={headingSize} />
        {hasButton ? (
          <button onClick={onClick} title={title}>
            {buttonIcon} {buttonLabel}
          </button>
        ) : null}
      </div>
      {children && (
        <div className={classes.AdditionalHeadingData} style={childStyles}>
          {children}
        </div>
      )}
    </div>
  );
};

export default HeadingBar;
