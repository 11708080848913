import { useTranslation } from 'react-i18next';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import classes from './FormInformationContainer.module.scss';

interface IProps {
  children: React.ReactNode;
  onClose?: () => void;
}

export const FormInformationContainer = ({ children, onClose }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.FormInformationContainer}>
      {children}

      <ActionContainer>
        <StandardButton
          btnType='orange'
          label={t('visitFormInfo:Close')}
          onClick={onClose}
        />
      </ActionContainer>
    </div>
  );
};

export const FieldInformationWrapper = ({ children }: IProps) => {
  return (
    <section className={classes.FieldInformationWrapper}>{children}</section>
  );
};

interface ISectionWrapperProps extends IProps {
  isVisible: boolean;
}

export const SectionWrapper = ({
  children,
  isVisible,
}: ISectionWrapperProps) => {
  if (!isVisible) {
    return null;
  }

  return <section className={classes.SectionWrapper}>{children}</section>;
};
