const getTotalPolygonPrice = (area: number, priceInSqm: number) => {
  let total = 0;

  if (
    area &&
    typeof area === 'number' &&
    priceInSqm &&
    typeof priceInSqm === 'number'
  ) {
    total = area * 1000000 * priceInSqm;
  }

  return total.toFixed(2);
};

export default getTotalPolygonPrice;
