import { useEffect, useState } from 'react';
import {
  IAreaOfInterest,
  IRegistrationSelectionProps,
  itemWithPrice,
} from '../../../../../../tsTypes/types.components';
import SelectionContainer from '../SelectionContainer/SelectionContainer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../../app/store';
import {
  approveInvoiceDetails,
  nextPage,
  selectAllRegistrationFormValues,
  setCurrentPageNumber,
} from '../../../../../../features/registration/registrationSlice';
import SectionExplanation from '../SectionExplanation/SectionExplanation';
import { FaFileInvoice, FaFilePdf } from 'react-icons/fa';
import { invoiceCalculator } from '../../../../../../utils/invoiceCalculator';
import Button from '../../../../../UI/FormElements/Button/Button';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import SelectionItem from './SelectionItem/SelectionItem';
import { confirmAlert } from 'react-confirm-alert';
import useClearRegistration from '../../../../../../CustomHooks/useClearRegistration';
import { usePDF } from '@react-pdf/renderer';
import ServiceDetails from '../../../../../pdfComponents/ServiceDetails/ServiceDetails';
import CenterDiv from '../../../../../UI/CenterDiv/CenterDiv';
import SimpleLoading from '../../../../../UI/SimpleLoading/SimpleLoading';
import PDFError from '../../../../../UI/PDFError/PDFError';
import ApprovedBadge from '../../../../../UI/ApprovedBadge/ApprovedBadge';
import classes from './SelectionSummary.module.scss';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

const SelectionSummary = ({ pageNumber }: IRegistrationSelectionProps) => {
  const {
    currentPageNumber,
    selectedOrganizationType,
    selectedCountry,
    selectedDistrict,
    selectedPackage,
    selectedProvince,
    selectedServiceType,
    selectedOrganization,
    selectedSubservices,
    isInvoiceDetailsApproved,
    areasOfInterest,
  } = useSelector(selectAllRegistrationFormValues);

  const [selectedPackageLocally, setSelectedPackageLocally] =
    useState<itemWithPrice | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { clearForm } = useClearRegistration();
  const { t } = useTranslation();

  const selectedPackageId =
    selectedPackage &&
    typeof selectedPackage === 'object' &&
    '_id' in selectedPackage
      ? selectedPackage._id
      : '';

  useEffect(() => {
    if (selectedPackageId) {
      setSelectedPackageLocally(selectedPackage);
    }
    // eslint-disable-next-line
  }, [selectedPackageId]);

  const doc = (
    <ServiceDetails
      organization={selectedOrganization}
      organizationType={selectedOrganizationType}
      country={selectedCountry}
      district={selectedDistrict}
      servicePackage={selectedPackage}
      serviceType={selectedServiceType}
      subservices={selectedSubservices}
      province={selectedProvince}
      polygons={areasOfInterest}
    />
  );
  const [pdfInstance, updatePDF] = usePDF({ document: doc });
  let pdfComponent: React.ReactNode | null = null;

  let organizationType = '';
  let country = '';
  let province = '';
  let district = '';
  let serviceType = '';
  let packageOption = '';
  let organization = '';
  let monthlyPackPrice = 0;
  let priceUnit = '';
  let vatRate = 0;
  let currency = '€';
  let duration = 0;
  let durationUnit = '';
  let selectedPolygons: IAreaOfInterest[] = [];
  let discount = 0;
  let fullPaymentDiscount = 0;
  const districts: string[] = [];
  const isExistingPage = currentPageNumber === 7;

  useEffect(() => {
    if (isExistingPage) {
      const timer = setTimeout(() => {
        updatePDF(doc);
      }, 950);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [isExistingPage]);

  useEffect(() => {
    const storedApproval = localStorage.getItem('invoice-details-approved');

    if (storedApproval) {
      dispatch(approveInvoiceDetails(true));
    }
    // eslint-disable-next-line
  }, []);

  if (selectedOrganizationType) {
    organizationType = selectedOrganizationType.title;
  }

  if (selectedCountry) {
    country = selectedCountry.name;
  }

  if (selectedProvince) {
    province = selectedProvince.name;
  }

  if (
    areasOfInterest &&
    Array.isArray(areasOfInterest) &&
    areasOfInterest.length > 0
  ) {
    selectedPolygons = [...areasOfInterest];
  }

  if (selectedDistrict) {
    selectedPolygons.forEach((polygon) => {
      const polygonDistrict = polygon.district;

      if (!districts.includes(polygonDistrict)) {
        districts.push(polygonDistrict);
      }
    });

    const isMultipleDistricts = districts.length > 1;

    district =
      districts.length === 1 && selectedDistrict.name === districts[0]
        ? districts[0]
        : !isMultipleDistricts
        ? districts[0]
        : 'Multiple Districts';
  }

  if (selectedServiceType) {
    serviceType = selectedServiceType.title;
  }

  if (selectedPackageLocally) {
    packageOption = selectedPackageLocally.name;
    monthlyPackPrice = selectedPackageLocally.price;
    priceUnit = selectedPackageLocally.priceUnit;
    vatRate = selectedPackageLocally.vatRate;
    currency = selectedPackageLocally.currency;
    duration = selectedPackageLocally.duration;
    durationUnit = selectedPackageLocally.durationUnit;
    discount = selectedPackageLocally.discounts.customDiscount;
    fullPaymentDiscount =
      selectedPackageLocally.discounts.discountAmountForFullPayment;
  }

  if (selectedOrganization) {
    organization = selectedOrganization.organizationName;
  }

  const {
    totalArea,
    totalPrice,
    discountAmount,
    totalVat,
    overallTotal,
    discountRate,
    totalAreaPrice,
  } = invoiceCalculator({
    selectedPackage: selectedPackageLocally,
    subservices: selectedSubservices,
    discount: discount ? discount : 0,
    selectedPolygons,
  });

  const approveCostHandler = () => {
    toast.success(
      <ToastifyStyledMessage
        singleMessage={t('auth:select_success_message')}
        heading={t('auth:next_page')}
      />,
      { autoClose: 1000, containerId: 'invoiceApprovalSuccess' }
    );

    dispatch(approveInvoiceDetails(true));
    localStorage.setItem('invoice-details-approved', 'true');

    const timer = setTimeout(() => {
      dispatch(nextPage());
      toast.dismiss();
      clearTimeout(timer);
    }, 1600);
  };

  const clearFormHandler = () => {
    clearForm({ shouldResetState: true });
  };

  const moveToPackageSectionHandler = () => {
    localStorage.setItem('currentPageNumber', (5).toString());
    dispatch(setCurrentPageNumber(5));
  };

  const declineCostHandler = () => {
    confirmAlert({
      title: t('auth:decline_title'),
      message: t('auth:decline_title_message'),
      buttons: [
        {
          label: t('translations:clear'),
          onClick: clearFormHandler,
          style: {
            backgroundColor: 'rgb(219, 25, 25)',
            fontWeight: '600',
            textTransform: 'uppercase',
            letterSpacing: '0.25rem',
            padding: '0.7rem 0.95rem',
          },
        },
        {
          label: t('translations:cancel'),
          onClick: moveToPackageSectionHandler,
          style: {
            fontWeight: '600',
            textTransform: 'uppercase',
            letterSpacing: '0.3rem',
          },
        },
      ],
    });
  };

  if (pdfInstance && pdfInstance.loading) {
    pdfComponent = (
      <CenterDiv>
        <SimpleLoading size={0.5} />;
      </CenterDiv>
    );
  }

  if (pdfInstance && pdfInstance.error) {
    pdfComponent = <PDFError />;
  }

  if (pdfInstance && pdfInstance.url && isExistingPage) {
    pdfComponent = (
      <a
        href={pdfInstance.url}
        download={`Periopsis_SelectionDetails_${new Date().getTime()}.pdf`}
        className={classes.ServiceDetails}
        onClick={() => updatePDF(doc)}
      >
        <FaFilePdf />
        {t('translations:download')}
      </a>
    );
  }

  return (
    <SelectionContainer
      isInQueue={pageNumber ? pageNumber < currentPageNumber : false}
      isCompleted={pageNumber ? pageNumber > currentPageNumber : false}
    >
      <SectionExplanation
        heading={t('auth:selection_summary')}
        description={t('auth:selection_summary_desc')}
        icon={<FaFileInvoice size={25} />}
      />
      <div className={classes.SummaryWrapper}>
        <div className={classes.SummaryContent}>
          <div
            className={`${classes.SelectionsList} ${classes.RightSeparator}`}
          >
            <ul>
              <SelectionItem
                itemHeading={t('auth:organization_type')}
                itemData={t(`auth:${getTranslationKey(organizationType)}`)}
              />
              <SelectionItem
                itemHeading={t('auth:country')}
                itemData={t(`auth:${getTranslationKey(country)}`)}
              />
              <SelectionItem
                itemHeading={t('auth:province')}
                itemData={t(`auth:${getTranslationKey(province)}`)}
              />
              <SelectionItem
                itemHeading={t('auth:district')}
                itemData={t(`auth:${getTranslationKey(district)}`)}
              />
              {districts.length > 1 ? (
                <SelectionItem
                  itemHeading={t('auth:Number_of_Polygons')}
                  itemData={selectedPolygons.length}
                />
              ) : null}
              <SelectionItem
                itemHeading={t('auth:Total_Area')}
                itemData={`${totalArea} km2`}
              />
              <SelectionItem
                itemHeading={t('auth:service_type')}
                itemData={serviceType}
              />
              <SelectionItem
                itemHeading={t('auth:package')}
                itemData={t(`auth:${getTranslationKey(packageOption)}`)}
              />
              <SelectionItem
                itemHeading={t('auth:organization')}
                itemData={organization}
              />
              <SelectionItem
                itemHeading={t('auth:full_payment_discount')}
                itemData={`${fullPaymentDiscount}%`}
              />
            </ul>
          </div>
          <div className={classes.SelectionsList}>
            <div className={classes.HeadingSection}>
              <h3>{t('auth:service_details')}</h3>
            </div>
            <ul>
              <SelectionItem
                itemHeading={t('auth:monthly_package_price')}
                itemData={`${currency}${monthlyPackPrice}${priceUnit}`}
                hasSeparator
              />
              <SelectionItem
                itemHeading={t('auth:package_duration')}
                itemData={`${duration} ${t(
                  `auth:${getTranslationKey(durationUnit)}`
                )}`}
                hasSeparator
              />

              {selectedSubservices.map((subservice) => (
                <SelectionItem
                  itemHeading={subservice.subserviceName}
                  key={subservice.subserviceId}
                  itemData={`${subservice.currency}${subservice.subserviceMonthlyPrice}/month`}
                  hasSeparator
                />
              ))}
              <SelectionItem
                itemHeading={t('auth:vat_rate')}
                itemData={`${vatRate}%`}
                hasSeparator
              />
              <SelectionItem
                itemHeading={t('auth:discount_rate')}
                itemData={`${discountRate}%`}
                hasSeparator
              />
              <SelectionItem
                itemHeading={t('auth:total_area_price')}
                itemData={`${currency}${totalAreaPrice.toFixed(2)}/${t(
                  'auth:month'
                )}`}
                hasSeparator
              />
              <SelectionItem
                itemHeading={t('auth:total_amount_with_subservices')}
                itemData={`${currency}${totalPrice}/${t('auth:month')}`}
                hasSeparator
              />
              <SelectionItem
                itemHeading={t('auth:discount_amount')}
                itemData={`${currency}${discountAmount}/${t('auth:month')}`}
                hasSeparator
              />
              <SelectionItem
                itemHeading={t('auth:total_vat')}
                itemData={`${currency}${totalVat}/${t('auth:month')}`}
                hasSeparator
              />
              <SelectionItem
                itemHeading={t('auth:overall_total')}
                itemData={`${currency}${overallTotal}/${t('auth:month')}`}
                hasSeparator
                className={classes.OverallTotal}
              />
              <SelectionItem
                itemHeading={`${duration} ${t(
                  `auth:${getTranslationKey(durationUnit)}`
                )} ${t(`auth:price_incl_vat`)}`}
                itemData={`${currency}${(
                  Number(overallTotal) *
                  (duration / 30)
                ).toFixed(2)}${duration === 365 ? t('auth:/year') : ``}`}
                hasSeparator
                className={classes.AnnualPrice}
              />
            </ul>
          </div>
        </div>
        <div className={classes.ActionContainer}>
          <div className={classes.PDFContainer}>
            {isExistingPage ? pdfComponent : null}
          </div>
          {!isInvoiceDetailsApproved ? (
            <div className={classes.Actions}>
              <Button
                onClick={declineCostHandler}
                btnClass={classes.DeclineBtn}
              >
                {t('translations:clear')}
              </Button>
              <Button onClick={approveCostHandler}>
                {t('translations:continue')}
              </Button>
            </div>
          ) : (
            <ApprovedBadge />
          )}
        </div>
      </div>
    </SelectionContainer>
  );
};

export default SelectionSummary;
