import { useGetContractsQuery } from '../../../features/incidents/incidentsSlice';
import useSessionLogout from '../../../CustomHooks/useSessionLogout';
import FetchingReports from '../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../UI/NoDataFound/NoDataFound';
import { Navigate } from 'react-router-dom';
import classes from './ServiceRegistration.module.scss';
import { FaExclamationTriangle } from 'react-icons/fa';

const ServiceRegistration = () => {
  const {
    data: userContracts,
    isError,
    isLoading,
    error,
  } = useGetContractsQuery('contracts');
  useSessionLogout({ isError, error });

  /**
   * @TODO :Fetch all existing services, then create service cards, at service cards, we should show service details, in which countries it is in service and when user clicks on it it should initiate
   * the service registration process. We also need to use existing contracts of the user at contracts list we will have serviceTypeId and if user has that service, it should be inactivated.
   */

  if (isLoading) {
    return <FetchingReports message='Preparing Services. Please wait...' />;
  }

  if (isError) {
    return (
      <NoDataFound message="It seems like there was a hiccup while fetching services and contracts. No worries, though. Please give it another shot a bit later, and we'll do our best to get things sorted out. Thank you for your understanding!" />
    );
  }

  //This will be removed when we prepare the Periopsis to accept service registration after user registration
  if (userContracts && userContracts.length > 0) {
    return <Navigate to='/' />;
  }

  return (
    <div className={classes.ServiceRegistrationContainer}>
      <div className={classes.UnderConstructionContainer}>
        <FaExclamationTriangle />
        <h3>Under Construction</h3>
        <p>This is is currently under construction.</p>
      </div>
    </div>
  );
};

export default ServiceRegistration;
