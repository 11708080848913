import { ChangeEvent } from 'react';
import { item } from '../../../../../tsTypes/types.components';
import Input from '../../../../UI/FormElements/Input/Input';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import { nanoid } from '@reduxjs/toolkit';
import ListItem from './ListItem/ListItem';
import BorderedSectionContainer from '../BorderedSectionContainer/BorderedSectionContainer';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import classes from './AddToList.module.scss';

interface IProps {
  label: string;
  numberLabel: string;
  numberName: string;
  numberValue: string;
  name: string;
  value: string;
  valueList: item[];
  onAdd: (value: item) => void;
  onRemove: (id: string) => void;
  onEntry: (e: ChangeEvent<HTMLInputElement>) => void;
  onNumberEntry: (e: ChangeEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  title?: string;
  entryHeading: string;
  listHeading: string;
  listItemSuffix?: string;
  onAction?: () => void;
  actionStartLabel?: string;
  isActionStart?: boolean;
  hasActionAuth?: boolean;
  isLoading?: boolean;
}

const AddToList = ({
  label,
  name,
  value,
  numberValue,
  valueList,
  onAdd,
  onRemove,
  onEntry,
  onNumberEntry,
  onAction,
  isInvalid,
  title,
  entryHeading,
  listHeading,
  numberLabel,
  numberName,
  listItemSuffix,
  actionStartLabel,
  isActionStart,
  hasActionAuth,
  isLoading,
}: IProps) => {
  const addEntryHandler = () => {
    if (value) {
      const id = nanoid();
      const listItem: item = {
        _id: id,
        name: value,
        number: Number(numberValue),
      };
      onAdd(listItem);
    }
  };

  return (
    <BorderedSectionContainer
      heading={entryHeading}
      onAction={onAction}
      actionStartLabel={actionStartLabel}
      isActionStart={isActionStart}
      hasActionAuth={hasActionAuth}
      isLoading={isLoading}
    >
      <div className={classes.AddToListForm}>
        <Input
          label={label}
          name={name}
          value={value}
          onChange={onEntry}
          isInvalid={isInvalid}
        />
        <Input
          label={numberLabel}
          name={numberName}
          value={numberValue}
          type='number'
          onChange={onNumberEntry}
          onWheel={(e) => e.currentTarget.blur()}
        />
        <StandardButton
          label='Add'
          btnType='primary'
          type='button'
          onClick={addEntryHandler}
          fakeButton={isInvalid || value.trim().length === 0}
        />
      </div>
      {valueList && valueList.length > 0 ? (
        <div className={classes.ListSection}>
          <HeadingBar
            heading={listHeading}
            headingSize={4}
            headingType='orange'
          />
          <ul className={classes.ListContainer}>
            {valueList.map((val, index) => (
              <ListItem
                item={val}
                onRemove={onRemove}
                isAuthorized
                title={title}
                key={val._id}
                index={index}
                suffix={listItemSuffix}
              />
            ))}
          </ul>
        </div>
      ) : null}
    </BorderedSectionContainer>
  );
};

export default AddToList;
