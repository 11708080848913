import { Fragment, useEffect, useState } from 'react';
import {
  useDeleteReportMutation,
  useGetReportByIdQuery,
  useGetSendReportsOfUserQuery,
  useUpdateReportArchiveStatusMutation,
} from '../../../../features/report/reportsSlice';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import MessageList from '../MessageList/MessageList';
import WindowContainer from '../WindowContainer/WindowContainer';
import {
  IExistingReport,
  IReportListItem,
} from '../../../../tsTypes/interfaces';
import { useSearchParams } from 'react-router-dom';
import MessageContent from '../MessageContent/MessageContent';
import classes from './ArchiveWindow.module.scss';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import queryErrorCatch from '../../../../utils/queryErrorCatch';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

interface IProps {
  isSelected: boolean;
  count: number;
  contractId: string;
  isLoadingCounts: boolean;
}

const ArchiveWindow = ({
  isSelected,
  count,
  contractId,
  isLoadingCounts,
}: IProps) => {
  const { isLoading, isError, data } = useGetSendReportsOfUserQuery(
    { contractId, filter: 'archive' },
    { skip: !isSelected || !contractId || count === 0 }
  );
  const [message, setMessage] = useState<IExistingReport | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedReportId = searchParams.get('report');
  const [restoreReport, { isLoading: isRestoring }] =
    useUpdateReportArchiveStatusMutation();
  const [deleteReport, { isLoading: isDeleting }] = useDeleteReportMutation();
  const { t } = useTranslation();

  let reports: IReportListItem[] = [];

  const {
    isLoading: isLoadingReport,
    isError: isReportError,
    data: selectedReport,
  } = useGetReportByIdQuery(
    { contractId, reportId: selectedReportId || '' },
    { skip: !selectedReportId }
  );

  const reportId =
    selectedReport !== undefined &&
    typeof selectedReport === 'object' &&
    'id' in selectedReport
      ? selectedReport.id
      : '';

  useEffect(() => {
    if (
      !isLoadingReport &&
      !isReportError &&
      reportId &&
      !message &&
      selectedReport &&
      selectedReportId
    ) {
      setMessage(selectedReport);
    }
    // eslint-disable-next-line
  }, [isLoadingReport, isReportError, reportId, selectedReportId]);

  useEffect(() => {
    if (!selectedReportId) {
      setMessage(null);
    }
  }, [selectedReportId]);

  if (count === 0) {
    return null;
  }

  let content: React.ReactNode = null;

  if (isLoading || isLoadingCounts || reports.length === 0) {
    content = (
      <FetchingReports message={t('reporting:fetching_archived_reports')} />
    );
  }

  if (isError) {
    content = (
      <NoDataFound message={t('reporting:archive_reports_fetch_error')} />
    );
  }

  const readMessageHandler = (messageId: string) => {
    setSearchParams({ report: messageId });
  };

  const clearSelectedReportHandler = () => {
    setSearchParams({});
    setMessage(null);
  };

  if (
    data &&
    typeof data === 'object' &&
    !isLoading &&
    !isError &&
    'isSuccess' in data &&
    data.isSuccess &&
    'data' in data &&
    data.data &&
    typeof data.data === 'object' &&
    'sendReports' in data.data &&
    Array.isArray(data.data.sendReports) &&
    data.data.sendReports.length > 0
  ) {
    reports = data.data.sendReports;
  }

  const restoreReportHandler = async (reportId: string) => {
    try {
      if (!reportId) {
        return;
      }

      const res = await restoreReport({
        reportId,
        contractId,
        process: 'restore-from-archive',
      }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('reporting:report_restored_successfully')}
            heading={t('reporting:restore_success')}
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const deleteReportProcess = async (reportId: string) => {
    try {
      const res = await deleteReport({ reportId, contractId }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('reporting:report_delete_success_message')}
            heading={t('reporting:deleted')}
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const deleteReportHandler = async (reportId: string) => {
    if (!reportId) {
      return;
    }

    const selectedReport = reports.find((report) => report.id === reportId);

    if (
      selectedReport &&
      typeof selectedReport === 'object' &&
      'reportDate' in selectedReport &&
      selectedReport.reportDate
    ) {
      const reportDate = new Date(selectedReport.reportDate).getTime();
      const currentDate = new Date().getTime();
      const hundredAndTwentyDaysInMilliseconds = 120 * 24 * 60 * 60 * 1000; // 120 days in milliseconds

      if (currentDate - reportDate < hundredAndTwentyDaysInMilliseconds) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('reporting:reports_120_days_cannot_be_deleted')}
            heading={t('reporting:deleting_limitation')}
          />
        );
      }
    }

    return confirmAlert({
      title: t('reporting:are_you_sure'),
      message: t('reporting:important_notice_deletion'),
      buttons: [
        {
          label: t('translations:Yes'),
          onClick: () => deleteReportProcess(reportId),
        },
        { label: t('translations:No') },
      ],
    });
  };

  if (reports.length > 0) {
    content = (
      <MessageList
        messages={reports}
        onRead={readMessageHandler}
        archiveButtonLabel='Restore Report'
        onArchive={restoreReportHandler}
        onDelete={deleteReportHandler}
      />
    );
  }

  if (message) {
    content = <MessageContent selectedReport={message} />;
  }

  if (isRestoring || isDeleting) {
    const message = isRestoring
      ? t('reporting:restoring_report_wait')
      : t('reporting:deleting_report_wait');

    content = <FetchingReports message={message} />;
  }

  return (
    <Fragment>
      <WindowContainer isSelected={isSelected}>{content}</WindowContainer>
      {message && isSelected ? (
        <div className={classes.ReportWindowActions}>
          <div className={classes.MainButtons}>
            <StandardButton
              label={t('reporting:back_to_reports')}
              btnType='primary'
              type='button'
              onClick={clearSelectedReportHandler}
              className={classes.ReportWindowButton}
              fakeButton={isRestoring || isDeleting}
            />
            <StandardButton
              label={t('reporting:go_to_incident')}
              btnType='primary'
              type='button'
              href={`/incident/${message.gridId}?latestIncident=${message.gridObjectId}&contractId=${message.contractId}`}
              className={classes.ReportWindowButton}
              fakeButton={isRestoring || isDeleting}
            />
            <StandardButton
              label={t('reporting:resend_message')}
              btnType='primary'
              type='button'
              className={classes.ReportWindowButton}
              fakeButton={isRestoring || isDeleting}
            />
            <StandardButton
              label={t('reporting:restore_report')}
              btnType='primary'
              type='button'
              className={classes.ReportWindowButton}
              fakeButton={isRestoring || isDeleting}
              onClick={() => restoreReportHandler(selectedReportId || '')}
            />
            <StandardButton
              label={t('reporting:delete_report')}
              btnType='orange'
              type='button'
              className={classes.ReportWindowButton}
              fakeButton={isRestoring || isDeleting}
              onClick={() => deleteReportHandler(selectedReportId || '')}
            />
          </div>
          <div className={classes.DashboardBtnContainer}>
            <StandardButton
              label={t('reporting:back_to_dashboard')}
              btnType='plain'
              type='button'
              href='/'
              className={classes.ReportWindowButton}
              fakeButton={isRestoring || isDeleting}
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default ArchiveWindow;
