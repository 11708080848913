import { useEffect, useState } from 'react';

interface IProps<T> {
  localStorageName: string;
  initialValue: T;
}

const useLocalStorage = <T,>({ localStorageName, initialValue }: IProps<T>) => {
  const [result, setResult] = useState<T>(initialValue);
  const [isLocalStorageChecked, setIsLocalStorageChecked] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem(localStorageName);

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setResult(parsedData);
      } catch (error) {
        setResult(storedData as T);
      }
    }

    setIsLocalStorageChecked(true);
  }, [localStorageName]);

  return { result, setResult, isLocalStorageChecked };
};

export default useLocalStorage;
