import { useTranslation } from 'react-i18next';
import ResendButton from '../ForgotPassword/ResendButton/ResendButton';
import classes from './ResendProcess.module.scss';

interface IProps {
  onResend: () => void;
  isResending: boolean;
  timer: string;
  isTimerStarted: boolean;
}

const ResendProcess = ({
  onResend,
  isResending,
  timer,
  isTimerStarted,
}: IProps) => {
  const { t } = useTranslation();
  let resendBtn = <ResendButton onResend={onResend} disabled={isResending} />;

  if (isTimerStarted) {
    resendBtn = <span className={classes.BtnText}>{timer}</span>;
  }

  if (isResending) {
    resendBtn = (
      <span className={classes.BtnText}>{t('translations:sending')}</span>
    );
  }

  return <div className={classes.ResendBtnContainer}>{resendBtn}</div>;
};

export default ResendProcess;
