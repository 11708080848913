import React from 'react';
import { alpha2Code } from '../../../tsTypes/types.components';
import Bulgaria from './Flags/Bulgaria';
import Cyprus from './Flags/Cyprus';
import Czech from './Flags/Czech';
import Denmark from './Flags/Denmark';
import Estonia from './Flags/Estonia';
import Finland from './Flags/Finland';
import France from './Flags/France';
import Greece from './Flags/Greece';
import Hungary from './Flags/Hungary';
import Ireland from './Flags/Ireland';
import Italy from './Flags/Italy';
import Latvia from './Flags/Latvia';
import Lithuania from './Flags/Lithuania';
import Netherlands from './Flags/Netherlands';
import Poland from './Flags/Poland';
import Portugal from './Flags/Portugal';
import Romania from './Flags/Romania';
import Slovakia from './Flags/Slovakia';
import Slovenia from './Flags/Slovenia';
import Spain from './Flags/Spain';
import Sweden from './Flags/Sweden';
import UnitedKingdom from './Flags/UnitedKingdom';
import classes from './Flag.module.scss';
import Germany from './Flags/Germany';
import Malta from './Flags/Malta';
import Croatia from './Flags/Croatia';

/**
 *
 * @TODO : Add all flags to Flag Component
 */
const getCountryByAlpha2Code = (alpha2Code: alpha2Code) => {
  switch (alpha2Code) {
    case 'bg':
      return <Bulgaria />;
    case 'cy':
      return <Cyprus />;
    case 'cz':
      return <Czech />;
    case 'da':
      return <Denmark />;
    case 'el':
      return <Greece />;
    case 'en':
      return <UnitedKingdom />;
    case 'es':
      return <Spain />;
    case 'et':
      return <Estonia />;
    case 'fi':
      return <Finland />;
    case 'fr':
      return <France />;
    case 'ga':
      return <Ireland />;
    case 'hu':
      return <Hungary />;
    case 'it':
      return <Italy />;
    case 'lt':
      return <Lithuania />;
    case 'lv':
      return <Latvia />;
    case 'nl':
      return <Netherlands />;
    case 'pl':
      return <Poland />;
    case 'pt':
      return <Portugal />;
    case 'ro':
      return <Romania />;
    case 'sk':
      return <Slovakia />;
    case 'sl':
      return <Slovenia />;
    case 'sv':
      return <Sweden />;
    case 'de':
      return <Germany />;
    case 'mt':
      return <Malta />;
    case 'hr':
      return <Croatia />;

    default:
      return <UnitedKingdom />;
  }
};

interface IProps {
  alpha2Code: alpha2Code;
  size?: number;
  borderRadius?: number;
  hasBoxShadow?: boolean;
}

//Component is reusable, we can use it at dropdowns and also for any other use cases.
const Flag = ({ alpha2Code, size, borderRadius, hasBoxShadow }: IProps) => {
  const selectedFlag = getCountryByAlpha2Code(alpha2Code);

  return (
    <div
      className={`${classes.FlagContainer} ${
        hasBoxShadow ? classes.Box_Shadow : ''
      }`}
      style={{
        width: size ? `${size}rem` : '100%',
        borderRadius: borderRadius ? `${borderRadius}rem` : 0,
      }}
    >
      {selectedFlag}
    </div>
  );
};

export default Flag;
