import React from 'react';
import ColumnDataContainer from '../../../../UI/ColumnDataContainer/ColumnDataContainer';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import Form from '../../../../UI/FormElements/Form/Form';
import Paragraph from '../../../../UI/Paragraph/Paragraph';
import Button from '../../../../UI/FormElements/Button/Button';
import classes from './CommonFormStructure.module.scss';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';

interface IProps {
  onSubmit: (e: React.FormEvent) => void;
  hasParagraph?: boolean;
  children: React.ReactNode | JSX.Element;
  isFakeButton: boolean;
  paragraph?: string;
  icon?: JSX.Element;
  className?: string;
  heading: string;
  isLoading: boolean;
}

const CommonFormStructure = ({
  onSubmit,
  hasParagraph,
  children,
  isFakeButton,
  paragraph,
  icon,
  className,
  heading,
  isLoading,
}: IProps) => {
  return (
    <ColumnDataContainer
      className={`${classes.Container} ${className ? className : ''}`}
    >
      <Form
        className={classes.ForgotPasswordUserEmailContainer}
        onSubmit={onSubmit}
      >
        <div className={classes.HeadSection}>
          <div className={classes.HeadingSection}>
            <h3>{heading}</h3>
            {icon}
          </div>

          {hasParagraph && (
            <Paragraph
              paragraph={
                paragraph ||
                `To reset your password, kindly provide the email address you used during your Periopsis registration. Once you've entered your email, we'll send you a confirmation code. Once you input the correct code, you'll be directed to a page where you can create a new password. Your security is our priority!`
              }
              noEllipsis
              noShortenMessage
              style={{ lineHeight: '1.3rem', padding: '0.4rem 0' }}
            />
          )}
        </div>
        {children}
        <ActionContainer className={classes.BtnContainer}>
          {!isLoading ? (
            <Button isFake={isFakeButton}>Continue</Button>
          ) : (
            <SimpleLoading />
          )}
        </ActionContainer>
      </Form>
    </ColumnDataContainer>
  );
};

export default CommonFormStructure;
