import React, { ReactNode } from 'react';
import { ColorClassType } from '../../../tsTypes/types.components';
import HeadingBar from '../HeadingBar/HeadingBar';
import classes from './ColumnDataContainer.module.scss';

interface IProps {
  heading?: string;
  headingType?: ColorClassType;
  children: ReactNode | JSX.Element | JSX.Element[];
  className?: string;
  style?: React.CSSProperties;
  headingChildren?: ReactNode;
  hasContentWrapper?: boolean;
  isNarrow?: boolean;
}

const ColumnDataContainer = ({
  heading,
  headingType,
  children,
  className,
  style,
  headingChildren,
  hasContentWrapper,
  isNarrow,
}: IProps) => {
  return (
    <div
      className={`${classes.DataContainer} ${className ? className : ''} ${
        isNarrow ? classes.Narrow : ''
      }`}
      style={style}
    >
      {heading && (
        <HeadingBar heading={heading} headingSize={3} headingType={headingType}>
          {headingChildren}
        </HeadingBar>
      )}
      <div className={`${hasContentWrapper ? classes.ContentWrapper : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default ColumnDataContainer;
