import classes from './Hamburger.module.scss';
import { IHider } from '../../../tsTypes/types.components';

const Hamburger = ({ onClick, show }: IHider) => {
  return (
    <div
      className={`${classes.NavIcon} ${show ? classes.Open : ''}`}
      onClick={onClick}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Hamburger;
