import {
  Api,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { frontendConstants } from '../../constants/constants';
import { coreModuleName } from '@reduxjs/toolkit/dist/query/core/module';
import { reactHooksModuleName } from '@reduxjs/toolkit/dist/query/react/module';
import {
  IContractIncident,
  IContractIncidentRequest,
  IIncidentRequest,
  IIncidentType,
  INotificationDetailsResponse,
} from '../../tsTypes/interfaces';

type TAGS =
  | 'Countries'
  | 'Incidents'
  | 'Notifications'
  | 'IncidentTypes'
  | 'OrganizationTypes'
  | 'Organizations'
  | 'ServiceTypes'
  | 'Polygons'
  | 'Organization'
  | 'CheckoutProformaData'
  | 'CheckoutContractData'
  | 'CheckoutContractData'
  | 'CheckoutUserData'
  | 'GridIncidents'
  | 'IncidentVisitReport'
  | 'IncidentReports'
  | 'send-reports'
  | 'selected-report'
  | 'incident-cleaned-report'
  | 'contract-users'
  | 'action-statistics'
  | 'type-statistics'
  | 'action-counts'
  | 'organization-coordinates'
  | 'user-settings';

export const periopsisApi: Api<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
  {
    getContracts: QueryDefinition<
      any,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      TAGS,
      any,
      'api'
    >;
    getNotificationDetails: QueryDefinition<
      { contractId: string; notificationId: string },
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      TAGS,
      INotificationDetailsResponse,
      'api'
    >;
    getContractIncidents: QueryDefinition<
      IContractIncidentRequest,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      TAGS,
      { incidents: IContractIncident[]; totalIncidents: number },
      'api'
    >;

    getContractServiceTypeIncidents: QueryDefinition<
      string,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        FetchBaseQueryMeta
      >,
      TAGS,
      IIncidentType[],
      'api'
    >;
  },
  'api',
  TAGS,
  typeof coreModuleName | typeof reactHooksModuleName
> = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${frontendConstants.backendHost}/${frontendConstants.apiVersion}`,
  }),
  tagTypes: [
    'Countries',
    'Incidents',
    'Notifications',
    'IncidentTypes',
    'OrganizationTypes',
    'Organizations',
    'ServiceTypes',
    'Polygons',
    'Organization',
    'CheckoutProformaData',
    'CheckoutContractData',
    'CheckoutUserData',
    'GridIncidents',
    'IncidentVisitReport',
    'IncidentReports',
    'send-reports',
    'selected-report',
    'incident-cleaned-report',
    'contract-users',
    'action-counts',
    'action-statistics',
    'type-statistics',
    'organization-coordinates',
    'user-settings',
  ],
  endpoints: (builder) => ({
    getContracts: builder.query({ query: () => '' }),
    getContractIncidents: builder.query({
      query: (req: IContractIncidentRequest) => '',
    }),
    getContractServiceTypeIncidents: builder.query({
      query: (serviceTypeId: string) => '',
    }),
    getGridIncidents: builder.query({
      query: (requestData: IIncidentRequest) => '',
    }),
    getIncidentVisitReport: builder.query({
      query: (idData: { contractIncidentId: string; contractId: string }) => '',
    }),
    getNotificationDetails: builder.query({
      query: (requestData: { contractId: string; notificationId: string }) =>
        '',
    }),
  }),
});
