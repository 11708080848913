import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../UI/style.constants';

export const RegistrationSuccessPageStyle = StyleSheet.create({
  accountRecoveryText: {
    marginTop: '7mm',
    marginBottom: '2.5mm',
    width: '131mm',
    fontSize: '9.6pt',
    color: colorConstants.lightGray,
  },
});
