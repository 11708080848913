import { Fragment, useLayoutEffect } from 'react';
import FetchingReports from '../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useSessionLogout from '../../../../../CustomHooks/useSessionLogout';
import { IActionStatProps } from '../../Statistics';
import { useGetMonthlyIncidentsByTypeQuery } from '../../../../../features/statistics/statisticsSlice';
import classes from './MonthlyIncidentStatistics.module.scss';
import ErrorControlComponent from '../../../../UI/ErrorControlComponent/ErrorControlComponent';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

const MonthlyIncidentStatistics = ({
  isSelected,
  contractId,
  currentYear,
  isYearsReady,
}: IActionStatProps) => {
  const { isLoading, isError, data, error } = useGetMonthlyIncidentsByTypeQuery(
    {
      contractId,
      year: currentYear,
    },
    { skip: !isSelected || !contractId }
  );
  const { t } = useTranslation();
  let content: React.ReactNode | null = null;

  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend
    );
  }, []);

  useSessionLogout({ isError, error });

  if (!isSelected || !isYearsReady) {
    return null;
  }

  if (isLoading) {
    content = (
      <FetchingReports message={t('statistics:fetching_monthly_statistics')} />
    );
  }

  if (isError) {
    content = (
      <ErrorControlComponent error={error}>
        <NoDataFound
          message={t('statistics:monthly_incident_statistics_fetch_error')}
        />
      </ErrorControlComponent>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'data' in data &&
    data.data &&
    'chartData' in data.data &&
    data.data.chartData
  ) {
    const options = {
      plugins: {
        title: {
          display: true,
          text: t('statistics:monthly_incidents_by_type'),
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    console.log(data.data.chartData);

    const translatedChartData = {
      datasets: data.data.chartData.datasets.map((datum: any) => ({
        ...datum,
        label: t(`dashboard:${getTranslationKey(datum.label)}`),
      })),
      labels: data.data.chartData.labels.map((label: string) =>
        t(`statistics:${getTranslationKey(label)}`)
      ),
    };

    content = (
      <div className={classes.StatisticsWrapper}>
        <div className={`${classes.ChartSection}`}>
          <Bar data={translatedChartData} options={options} />
        </div>
      </div>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'data' in data &&
    data.data &&
    'chartData' in data.data &&
    !data.data.chartData
  ) {
    return <NoDataFound message={t('statistics:no_statistical_data_found')} />;
  }

  return <Fragment>{content}</Fragment>;
};

export default MonthlyIncidentStatistics;
