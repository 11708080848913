import zxcvbn from 'zxcvbn';
import classes from './PasswordStrengthTest.module.scss';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

interface IProps {
  password: string;
}

const getScoreText = (score: number) => {
  const textArray = ['Very Weak', 'Weak', 'Moderate', 'Strong', 'Very Strong'];

  return textArray[score];
};

const PasswordStrengthTest = ({ password }: IProps) => {
  const passwordStrengthResult = zxcvbn(password);
  const width = (passwordStrengthResult.score / 4) * 100;
  const { t } = useTranslation();

  if (!password) {
    return null;
  }

  return (
    <div className={classes.PasswordStrengthTestContainer}>
      <div className={classes.StrengthScore}>
        <div
          className={`${classes.StrengthBar} ${
            password ? classes[`strength-${passwordStrengthResult.score}`] : ''
          }`}
          style={{ width: `${width}%` }}
        >
          <div className={classes.StrengthLabel}>
            {t(
              `registration:${getTranslationKey(
                getScoreText(passwordStrengthResult.score)
              )}`
            )}{' '}
            {t('auth:password')}
          </div>
        </div>
      </div>
      <div className={classes.TimeEstimate}>
        <label>{t('registration:estimated_time_to_crack')}</label>
        <data>
          {
            passwordStrengthResult.crack_times_display
              .offline_slow_hashing_1e4_per_second
          }
        </data>
      </div>
    </div>
  );
};

export default PasswordStrengthTest;

/**
 * 0: Very Weak
1: Weak
2: Moderate
3: Strong
4: Very Strong
 */
