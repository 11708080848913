import { MdClose } from 'react-icons/md';
import { RiFeedbackLine, RiFeedbackFill } from 'react-icons/ri';
import { useState } from 'react';
import classes from './PointActionForm.module.scss';

interface IProps {
  children: React.ReactNode;
  heading?: string;
  onClose: () => void;
  onShowInformation?: () => void;
  showInfo?: boolean;
}

const PointActionForm = ({
  children,
  heading,
  onClose,
  onShowInformation,
  showInfo,
}: IProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    setScrollPosition(scrollTop);
  };

  return (
    <div className={classes.FormContainer}>
      {heading ? (
        <div
          className={`${classes.FormHeadingSection} ${
            scrollPosition >= 60 ? classes.Scrolled : ''
          }`}
        >
          <h3>{heading}</h3>
          <div className={classes.CloseAndInformation}>
            {onShowInformation !== undefined &&
            typeof onShowInformation === 'function' ? (
              <button
                onClick={onShowInformation}
                className={classes.InfoButton}
              >
                {showInfo ? (
                  <RiFeedbackFill color='#d95c2d' />
                ) : (
                  <RiFeedbackLine color='#8aa8b2' />
                )}
              </button>
            ) : null}
            <button onClick={onClose}>
              <MdClose />
            </button>
          </div>
        </div>
      ) : null}
      <div className={classes.ContentContainer} onScroll={handleScroll}>
        <div className={classes.Wrapper}>{children}</div>
      </div>
    </div>
  );
};

export default PointActionForm;
