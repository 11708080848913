import { IoClose } from 'react-icons/io5';
import { FieldInformationWrapper } from '../FormInformationContainer/FormInformationContainer';
import classes from './ReportIcons.module.scss';
import { FaUndo } from 'react-icons/fa';
import { BiEditAlt, BiLock } from 'react-icons/bi';
import RemoveButton from '../../../../UI/RemoveButton/RemoveButton';
import { FaRegCircleQuestion } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

interface IProps {
  heading?: string;
}

const ReportIcons = ({ heading }: IProps) => {
  const header = heading || 'Icons at report';
  const { t } = useTranslation();

  return (
    <FieldInformationWrapper>
      <h4>{header}</h4>
      <ul className={classes.IconsContainer}>
        <li>
          <div className={classes.IconNameSection}>
            <BiEditAlt style={{ cursor: 'pointer' }} size={'1.5rem'} />
            <p>{t('visitFormInfo:edit_field')}</p>
          </div>
          <p className={classes.InformationText}>
            {t('visitFormInfo:edit_field_desc')}
          </p>
        </li>
        <li>
          <div className={classes.IconNameSection}>
            <IoClose style={{ cursor: 'pointer' }} size={'1.6rem'} />
            <p>{t('visitFormInfo:close_field')}</p>
          </div>
          <p className={classes.InformationText}>
            {t('visitFormInfo:close_field_desc')}
          </p>
        </li>
        <li>
          <div className={classes.IconNameSection}>
            <FaUndo style={{ cursor: 'pointer' }} size={'1.2rem'} />
            <p>{t('visitFormInfo:undo_operation_heading')}</p>
          </div>
          <p className={classes.InformationText}>
            {t('visitFormInfo:undo_operation_desc')}
          </p>
        </li>
        <li>
          <div className={classes.IconNameSection}>
            <BiLock style={{ cursor: 'not-allowed' }} size={'1.5rem'} />
            <p>{t('visitFormInfo:locked_unauth')}</p>
          </div>
          <p className={classes.InformationText}>
            {t('visitFormInfo:locked_unauth_desc')}
          </p>
        </li>
        <li>
          <div className={classes.IconNameSection}>
            <RemoveButton onRemove={() => {}} isAuthorized id='information' />
            <p>{t('visitFormInfo:remove_from_list')}</p>
          </div>
          <p className={classes.InformationText}>
            {t('visitFormInfo:remove_from_list_desc')}
          </p>
        </li>
        <li>
          <div className={classes.IconNameSection}>
            <FaRegCircleQuestion size={'1.4rem'} />
            <p>{t('visitFormInfo:list_of_predefined_data')}</p>
          </div>
          <p className={classes.InformationText}>
            {t('visitFormInfo:list_of_predefined_data_desc_01')}
          </p>
          <p className={classes.InformationText}>
            {t('visitFormInfo:list_of_predefined_data_desc_02')}
          </p>
        </li>
      </ul>
    </FieldInformationWrapper>
  );
};

export default ReportIcons;
