import { GoDot, GoDotFill } from 'react-icons/go';
import classes from './IncidentActions.module.scss';
import { IIncidentActions } from '../../../tsTypes/interfaces';
import { useTranslation } from 'react-i18next';

interface IProps {
  incidentActions: IIncidentActions;
  isCleaned?: boolean;
}

const IncidentActions = ({ incidentActions, isCleaned }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.IncidentActionsContainer}>
      <div className={classes.ActionItem}>
        {incidentActions.visit ? <GoDotFill /> : <GoDot />}
        <p>{t('dashboard:Visited')}</p>
      </div>
      <div className={classes.ActionItem}>
        {incidentActions.report.length > 0 ? <GoDotFill /> : <GoDot />}
        <p>{t('dashboard:Reported')}</p>
      </div>
      <div className={classes.ActionItem}>
        {isCleaned || incidentActions.clean ? <GoDotFill /> : <GoDot />}
        <p>{t('dashboard:Cleaned')}</p>
      </div>
    </div>
  );
};

export default IncidentActions;
