import React from 'react';
import classes from './CenterDiv.module.scss';

interface IProps {
  children?: React.ReactNode | JSX.Element | JSX.Element[];
}

const CenterDiv = ({ children }: IProps) => {
  return <div className={classes.CenterDivContainer}>{children}</div>;
};

export default CenterDiv;
