import { useTranslation } from 'react-i18next';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import classes from './AddNewUserSection.module.scss';

interface IProps {
  onAdd: () => void;
  isAdding: boolean;
}

const AddNewUserSection = ({ onAdd, isAdding }: IProps) => {
  const { t } = useTranslation();

  if (isAdding) {
    return null;
  }

  return (
    <div className={classes.AddUserSection}>
      <HeadingBar
        heading={t('admin:add_new_user')}
        headingSize={3}
        headingType='primary'
      />
      <p>{t('admin:add_new_user_desc')}</p>
      <ActionContainer className={classes.AddNewUserActions}>
        <StandardButton
          label={t('admin:add_new_user_to_users_list')}
          btnType='primary'
          type='button'
          onClick={onAdd}
        />
      </ActionContainer>
    </div>
  );
};

export default AddNewUserSection;
