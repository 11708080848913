import { useTranslation } from 'react-i18next';
import {
  FieldInformationWrapper,
  FormInformationContainer,
  SectionWrapper,
} from '../../FormInformationContainer/FormInformationContainer';
import ReportIcons from '../../ReportIcons/ReportIcons';

interface IProps {
  onClose?: () => void;
  settings?: { [key: string]: boolean };
}

const IncidentCleanFormInformation = ({ onClose, settings }: IProps) => {
  let hasCleanupDurationField = false;
  let hasAddEmployeesField = false;
  let hasAddInvolvedDepartmentsField = false;
  let hasAddUtilizedEquipmentsField = false;
  let hasLessonsLearnedField = false;
  let hasCollaborationDetailsField = false;
  let hasAdditionalNotesField = false;
  let hasCleanupMethodologyField = false;
  let hasEnvironmentalImpactAssessmentField = false;
  const { t } = useTranslation();

  if (!settings) {
    return null;
  }

  if (
    settings &&
    typeof settings === 'object' &&
    'hasCleanupDurationField' in settings &&
    'hasAddEmployeesField' in settings &&
    'hasAddInvolvedDepartmentsField' in settings &&
    'hasAddUtilizedEquipmentsField' in settings &&
    'hasLessonsLearnedField' in settings &&
    'hasCollaborationDetailsField' in settings &&
    'hasAdditionalNotesField' in settings &&
    'hasCleanupMethodologyField' in settings &&
    'hasEnvironmentalImpactAssessmentField' in settings
  ) {
    hasCleanupDurationField = settings.hasCleanupDurationField;
    hasAddEmployeesField = settings.hasAddEmployeesField;
    hasAddInvolvedDepartmentsField = settings.hasAddInvolvedDepartmentsField;
    hasAddUtilizedEquipmentsField = settings.hasAddUtilizedEquipmentsField;
    hasLessonsLearnedField = settings.hasLessonsLearnedField;
    hasCollaborationDetailsField = settings.hasCollaborationDetailsField;
    hasAdditionalNotesField = settings.hasAdditionalNotesField;
    hasCleanupMethodologyField = settings.hasCleanupMethodologyField;
    hasEnvironmentalImpactAssessmentField =
      settings.hasEnvironmentalImpactAssessmentField;
  }

  return (
    <FormInformationContainer onClose={onClose}>
      <p>{t('cleanedFormInfo:info_desc_01')}</p>
      <p>{t('cleanedFormInfo:info_desc_02')}</p>
      <ReportIcons heading={t('visitFormInfo:icons_heading')} />
      <SectionWrapper isVisible>
        <h1>{t('cleanedFormInfo:Incident_Details')}</h1>
        <p>{t('cleanedFormInfo:incident_Details_desc')}</p>
      </SectionWrapper>

      <SectionWrapper isVisible>
        <h1>{t('cleanedFormInfo:General_Information')}</h1>
        <p>{t('cleanedFormInfo:General_Information_Desc')}</p>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Date_of_Cleanup_Operation')}</h4>
          <p>{t('cleanedFormInfo:Date_of_Cleanup_Operation_Desc')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Number_of_Workers_Deployed')}</h4>
          <p>{t('cleanedFormInfo:Number_of_Workers_Deployed_Desc')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Estimated_Cost')}</h4>
          <p>{t('cleanedFormInfo:Estimated_Cost_Desc')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Exact_Location')}</h4>
          <p>{t('cleanedFormInfo:Estimated_Cost_Desc')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Observed_Incident_Type')}</h4>
          <p>{t('cleanedFormInfo:Observed_Incident_Type_Desc_01')}</p>
          <p>{t('cleanedFormInfo:Observed_Incident_Type_Desc_02')}</p>
          <p>{t('cleanedFormInfo:Observed_Incident_Type_Desc_03')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible={hasCleanupDurationField}>
        <h1>{t('cleanedFormInfo:Cleanup_Duration')}</h1>
        <p>{t('cleanedFormInfo:Cleanup_Duration_Desc')}</p>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Options')}</h4>
          <p>{t('cleanedFormInfo:Options_Desc')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Duration_Entry_Field')}</h4>
          <p>{t('cleanedFormInfo:Duration_Entry_Field_Desc')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible={hasAddEmployeesField}>
        <h1>{t('cleanedFormInfo:Add_Employee')}</h1>
        <p>{t('cleanedFormInfo:Add_Employee_Desc_01')}</p>
        <p>{t('cleanedFormInfo:Add_Employee_Desc_02')}</p>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Employee_Data_Entry')}</h4>
          <p>{t('cleanedFormInfo:Employee_Data_Entry_Desc_01')}</p>
          <p>{t('cleanedFormInfo:Employee_Data_Entry_Desc_02')}</p>
          <p>{t('cleanedFormInfo:Employee_Data_Entry_Desc_03')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Employee_List')}</h4>
          <p>{t('cleanedFormInfo:Empolyee_List_Desc')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible={hasAddInvolvedDepartmentsField}>
        <h1>{t('cleanedFormInfo:Involved_Departments')}</h1>
        <p>{t('cleanedFormInfo:Involved_Departments_Desc')}</p>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Department_Entry_Field')}</h4>
          <p>{t('cleanedFormInfo:Department_Entry_Field_Desc')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Involved_Departments_List')}</h4>
          <p>{t('cleanedFormInfo:Involved_Departments_List_Desc')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible={hasAddUtilizedEquipmentsField}>
        <h1>{t('cleanedFormInfo:Utilized_Equipments')}</h1>
        <p>{t('cleanedFormInfo:Utilized_Equipments_Desc')}</p>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Equipment_Entry_Field')}</h4>
          <p>{t('cleanedFormInfo:Equipment_Entry_Field_Desc')}</p>
        </FieldInformationWrapper>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Utilized_Equipments_List')}</h4>
          <p>{t('cleanedFormInfo:Utilized_Equipments_List_Desc')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible={hasLessonsLearnedField}>
        <h1>{t('cleanedFormInfo:Lessons_Learned')}</h1>
        <p>{t('cleanedFormInfo:Lessons_Learned_Desc')}</p>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Lessons_Learned_Entry_Field')}</h4>
          <p>{t('cleanedFormInfo:Lessons_Learned_Entry_Field_Desc')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible={hasCollaborationDetailsField}>
        <h1>{t('cleanedFormInfo:Collaboration_Details')}</h1>
        <p>{t('cleanedFormInfo:Collaboration_Details_Desc')}</p>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Collaboration_Details_Entry_Field')}</h4>
          <p>{t('cleanedFormInfo:Collaboration_Details_Entry_Field_Desc')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible={hasAdditionalNotesField}>
        <h1>{t('cleanedFormInfo:Additional_Notes_Cleanup')}</h1>
        <p>{t('cleanedFormInfo:Additional_Notes_Cleanup_Desc')}</p>
        <FieldInformationWrapper>
          <h4>{t('cleanedFormInfo:Additional_Notes_Entry_Field')}</h4>
          <p>{t('cleanedFormInfo:Additional_Notes_Entry_Field_Desc')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
      <SectionWrapper isVisible={hasCleanupMethodologyField}>
        <h1>{t('cleanedFormInfo:Cleanup_Methodology')}</h1>
        <p>{t('cleanedFormInfo:Cleanup_Methodology_Desc')}</p>
      </SectionWrapper>
      <SectionWrapper isVisible={hasEnvironmentalImpactAssessmentField}>
        <h1>{t('cleanedFormInfo:ENVIRONMENTAL_IMPACT_ASSESSMENT')}</h1>
        <p>{t('cleanedFormInfo:Environmental_Impact_Assessment_Desc')}</p>
      </SectionWrapper>
      <SectionWrapper isVisible>
        <FieldInformationWrapper>
          <h4>{''}</h4>
          <p>{t('cleanedFormInfo:Completion_Desc')}</p>
        </FieldInformationWrapper>
      </SectionWrapper>
    </FormInformationContainer>
  );
};

export default IncidentCleanFormInformation;
