import IncidentDetails from '../IncidentDetails/IncidentDetails';
import IncidentLocationLink from '../IncidentReportFrom/ReportByMail/IncidentLocationLink/IncidentLocationLink';
import { IContractIncident } from '../../../../../tsTypes/interfaces';
import BorderedSectionContainer from '../BorderedSectionContainer/BorderedSectionContainer';
import { useTranslation } from 'react-i18next';

interface IProps {
  selectedIncident: IContractIncident;
  information?: string;
  isLoading?: boolean;
}

const IncidentDetailsSection = ({
  selectedIncident,
  information,
  isLoading,
}: IProps) => {
  let latitude = 0;
  let longitude = 0;

  const { t } = useTranslation();

  if (!selectedIncident) {
    return null;
  }

  if (
    selectedIncident &&
    typeof selectedIncident === 'object' &&
    'coordinates' in selectedIncident &&
    Array.isArray(selectedIncident.coordinates) &&
    selectedIncident.coordinates.length === 2
  ) {
    latitude = selectedIncident.coordinates[0];
    longitude = selectedIncident.coordinates[1];
  }

  return (
    <BorderedSectionContainer
      heading={t('forms:Incident_Details')}
      information={information}
    >
      <IncidentDetails selectedIncident={selectedIncident} />

      {!isLoading ? (
        <IncidentLocationLink
          label={t('forms:Incident_Location_On_Map')}
          latitude={latitude}
          longitude={longitude}
        />
      ) : null}
    </BorderedSectionContainer>
  );
};

export default IncidentDetailsSection;
