import React, { FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-router-dom';
import Input from '../../../UI/FormElements/Input/Input';
import HeadingBar from '../../../UI/HeadingBar/HeadingBar';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import classes from './NewPasswordForm.module.scss';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { frontendConstants } from '../../../../constants/constants';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import { validatePassword } from '../../../../utils/componentUtilFns';
import { IUpdatePassword } from '../../../../tsTypes/types.components';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../utils/getTranslationKey';

interface IProps {
  onSubmit: (formData: IUpdatePassword) => void;
  isLoading: boolean;
  shouldReset: boolean;
}

const NewPasswordForm = ({ onSubmit, isLoading, shouldReset }: IProps) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [invalidNewPassword, setInvalidNewPassword] = useState(false);
  const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);
  const [invalidOldPassword, setInvalidOldPassword] = useState(false);
  const { t } = useTranslation();
  const passwordConditions = frontendConstants.passwordConditions.map(
    (condition) => t(`registration:${getTranslationKey(condition)}`)
  );

  useEffect(() => {
    if (shouldReset) {
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setInvalidNewPassword(false);
      setInvalidConfirmPassword(false);
      setInvalidOldPassword(false);
    }
  }, [shouldReset]);

  const isValid =
    !invalidNewPassword &&
    !invalidOldPassword &&
    !invalidConfirmPassword &&
    validatePassword(newPassword, 6, 25) &&
    newPassword === confirmPassword &&
    validatePassword(oldPassword, 6, 25) &&
    newPassword.trim().length > 0;

  const oldPasswordEntryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const oldPass = e.target.value;
    setOldPassword(oldPass);

    if (validatePassword(oldPassword, 6, 25)) {
      setInvalidOldPassword(false);
    }
  };

  const newPasswordEntryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPass = e.target.value;
    setNewPassword(newPass);

    if (validatePassword(newPass, 6, 25)) {
      setInvalidNewPassword(false);
    }
  };

  const confirmPasswordEntryHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const confirmPass = e.target.value;
    setConfirmPassword(confirmPass);

    if (confirmPass === newPassword) {
      setInvalidConfirmPassword(false);
    }
  };

  const showPasswordConditionsHandler = () => {
    toast.dismiss();
    toast.info(
      <ToastifyStyledMessage
        messageList={passwordConditions}
        heading={t('profilesettings:password_conditions')}
      />
    );
  };

  const formSubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    const isValidNewPassword = validatePassword(newPassword, 6, 25);

    if (
      oldPassword.trim().length === 0 ||
      newPassword.trim().length === 0 ||
      confirmPassword.trim().length === 0
    ) {
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('profilesettings:required_data_error_message')}
          heading={t('profilesettings:required_data_error')}
        />
      );
      setInvalidOldPassword(true);
      setInvalidNewPassword(true);
      setInvalidConfirmPassword(true);

      return;
    }

    if (oldPassword === newPassword) {
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('profilesettings:same_password_message')}
          heading={t('profilesettings:same_password')}
        />
      );
    }

    if (newPassword.includes(oldPassword)) {
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('profilesettings:old_in_new_message')}
          heading={t('profilesettings:old_in_new')}
        />
      );
    }

    if (!isValidNewPassword) {
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('profilesettings:not_valid_error_message')}
          heading={t('profilesettings:not_valid_error')}
        />
      );
      setInvalidNewPassword(true);
    }

    if (newPassword !== confirmPassword) {
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('profilesettings:no_match_error_message')}
          heading={t('profilesettings:no_match_error')}
        />
      );
      setInvalidConfirmPassword(true);
    }

    if (
      onSubmit &&
      oldPassword &&
      newPassword &&
      confirmPassword &&
      newPassword === confirmPassword
    ) {
      onSubmit({ oldPassword, password: newPassword, confirmPassword });
    }
  };

  if (isLoading) {
    return (
      <FetchingReports
        message={t('profilesettings:sending_verification_code')}
      />
    );
  }

  return (
    <div className={classes.Wrapper}>
      <HeadingBar
        heading={t('profilesettings:change_password')}
        headingSize={3}
      >
        <div className={classes.PasswordConditionsContainer}>
          <AiOutlineInfoCircle
            id='info-icon'
            onClick={showPasswordConditionsHandler}
            size={22}
            title={t('profilesettings:click_to_see_conditions')}
          />
        </div>
      </HeadingBar>

      <Form
        className={classes.PasswordFormContainer}
        onSubmit={formSubmitHandler}
      >
        <Input
          label={t('profilesettings:old_password')}
          name='oldpassword'
          value={oldPassword}
          type='password'
          isRequired
          hasShowPasswordButton
          onChange={oldPasswordEntryHandler}
          isInvalid={invalidOldPassword}
          maxLength={25}
          title={t('profilesettings:old_password_title')}
        />
        <Input
          label={t('profilesettings:new_password')}
          name='newpassword'
          value={newPassword}
          type='password'
          isRequired
          hasShowPasswordButton
          onChange={newPasswordEntryHandler}
          isInvalid={invalidNewPassword}
          maxLength={25}
          title={t('profilesettings:new_password_title')}
        />
        <Input
          label={t('profilesettings:confirm_new_password')}
          name='confirmpassword'
          value={confirmPassword}
          type='password'
          isRequired
          hasShowPasswordButton
          onChange={confirmPasswordEntryHandler}
          isInvalid={invalidConfirmPassword}
          maxLength={25}
          title={t('profilesettings:confirm_new_password_title')}
        />
        <ActionContainer>
          <StandardButton
            type='submit'
            btnType='primary'
            label={t('profilesettings:save')}
            style={{ width: '100%' }}
            fakeButton={!isValid}
          />
        </ActionContainer>
      </Form>
    </div>
  );
};

export default NewPasswordForm;
