import { nanoid } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { IStep } from '../tsTypes/types.components';
import { AppDispatch } from '../app/store';
import { storePointDirectionsData } from '../features/point/pointSlice';

interface ITwoPointCoordinates {
  latitudeStart: number | null;
  longitudeStart: number | null;
  latitudeEnd: number | null;
  longitudeEnd: number | null;
}

const useDirections = (
  twoPointCoordinates: ITwoPointCoordinates,
  additionalFunctionsForClearOperation?: () => void
) => {
  const [steps, setSteps] = useState<{
    steps: IStep[];
    distance: number;
    duration: number;
  } | null>(null);
  const isFetched = useRef(false);

  const [directions, setDirections] = useState<any[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getRoutes = async () => {
      const res = await axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${
          twoPointCoordinates.longitudeStart
        },${twoPointCoordinates.latitudeStart};${
          twoPointCoordinates.longitudeEnd
        },${
          twoPointCoordinates.latitudeEnd
        }?geometries=geojson&overview=full&steps=${true}&voice_instructions=true&access_token=pk.eyJ1IjoiYXl0YWNnMjYiLCJhIjoiY2s4aGY5eW5rMDA2czNrcDJzZWRoMDk3dSJ9.31MuOn331AVktMDlS0LA6A`
      );

      if (res.status === 200) {
        if (res.data && res.data.routes && Array.isArray(res.data.routes)) {
          const directionGeoJSON = res.data.routes[0].geometry.coordinates;
          let steps: IStep[] = res.data.routes[0].legs[0].steps;
          const distance = res.data.routes[0].distance;
          const duration = res.data.routes[0].duration;

          if (steps) {
            steps = steps.map((step) => ({ ...step, id: nanoid() }));
          }

          const data = {
            steps,
            distance,
            duration,
          };

          setSteps(data);
          setDirections(directionGeoJSON);
          dispatch(storePointDirectionsData(data));
        }
      }
    };

    if (
      !isFetched.current &&
      twoPointCoordinates.latitudeStart &&
      twoPointCoordinates.longitudeStart &&
      twoPointCoordinates.latitudeEnd &&
      twoPointCoordinates.longitudeEnd
    ) {
      getRoutes();
      isFetched.current = true;
    }
  }, [
    twoPointCoordinates.latitudeStart,
    twoPointCoordinates.longitudeStart,
    twoPointCoordinates.latitudeEnd,
    twoPointCoordinates.longitudeEnd,
    dispatch,
  ]);

  const clearState = () => {
    setSteps(null);
    setDirections([]);
    dispatch(storePointDirectionsData(null));
    isFetched.current = false;

    if (additionalFunctionsForClearOperation) {
      additionalFunctionsForClearOperation();
    }
  };

  const restartFetch = () => {
    isFetched.current = false;
  };

  return { directions, steps, clearState, restartFetch };
};

export default useDirections;
