import { Document, View, Text } from '@react-pdf/renderer';
import PageLayout from '../UI/PageLayout/PageLayout';
import {
  ICheckoutContract,
  ICheckoutProforma,
  ICheckoutUserData,
} from '../../../tsTypes/checkout.interfaces';
import ProformaSection from './ProformaSection/ProformaSection';
import DataContainer from '../UI/DataContainer/DataContainer';
import { ProformaInvoicePageStyle } from './ProformaInvoice.style';
import createFullAddress from '../../../utils/addressUtilityFunctions/createFullAddress';
import SubserviceContainer from './SubserviceContainer/SubserviceContainer';
import divideArrayIntoChunks from '../../../utils/divideArrayIntoChunks';
import getTotalPolygonPrice from '../../../utils/getTotalPolygonPrice';

interface IProps {
  proformaInvoice: ICheckoutProforma | null;
  contract: ICheckoutContract | null;
  user: ICheckoutUserData | null;
}

const ProformaInvoicePDF = ({ proformaInvoice, contract, user }: IProps) => {
  let isIndividualType = false;

  if (!proformaInvoice || typeof proformaInvoice !== 'object') {
    return (
      <Document>
        <PageLayout name='No Package'>
          <View>
            <Text>Oops! It looks like we couldn't find any invoice data.</Text>
          </View>
        </PageLayout>
      </Document>
    );
  }

  if (!contract || typeof contract !== 'object') {
    return (
      <Document>
        <PageLayout name='No Package'>
          <View>
            <Text>Oops! It looks like we couldn't find any contract data.</Text>
          </View>
        </PageLayout>
      </Document>
    );
  }

  if (
    proformaInvoice &&
    typeof proformaInvoice === 'object' &&
    'organization' in proformaInvoice &&
    proformaInvoice.organization &&
    typeof proformaInvoice.organization === 'object' &&
    'type' in proformaInvoice.organization &&
    proformaInvoice.organization.type
  ) {
    isIndividualType = proformaInvoice.organization.type === 'Individual';
  }

  const hasSubservices =
    proformaInvoice &&
    'selectedPackage' in proformaInvoice &&
    proformaInvoice.selectedPackage &&
    typeof proformaInvoice.selectedPackage === 'object' &&
    'subServices' in proformaInvoice.selectedPackage &&
    Array.isArray(proformaInvoice.selectedPackage.subServices) &&
    proformaInvoice.selectedPackage.subServices.length > 0;

  const invoiceDate = new Date(proformaInvoice.invoiceDate).toLocaleDateString(
    'en',
    { year: 'numeric', month: 'long', day: '2-digit' }
  );
  const invoiceDueDate = new Date(proformaInvoice.dueDate).toLocaleDateString(
    'en',
    { year: 'numeric', month: 'long', day: '2-digit' }
  );

  const contractStartDate = new Date(contract.startDate).toLocaleDateString(
    'en',
    { year: 'numeric', month: 'long', day: '2-digit' }
  );
  const contractEndDate = new Date(contract.endDate).toLocaleDateString('en', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });

  const polygonChunks = divideArrayIntoChunks(contract.polygons, 6);

  let billingAddress: React.ReactNode = null;

  billingAddress = (
    <View style={ProformaInvoicePageStyle.addressType}>
      <Text>{proformaInvoice.address.addressType.name}</Text>
      <Text>{`${proformaInvoice.customerName}`}</Text>
    </View>
  );

  //Billing Address :
  if (
    !isIndividualType &&
    user &&
    typeof user === 'object' &&
    'organizationAddressId' in user &&
    user.organizationAddressId === proformaInvoice.address.addressId
  ) {
    billingAddress = (
      <View style={ProformaInvoicePageStyle.addressType}>
        <Text>{proformaInvoice.organization.name}</Text>
        <Text style={ProformaInvoicePageStyle.vatNumber}>
          VAT Number : {proformaInvoice.organization.vatNumber}
        </Text>
      </View>
    );
  } else if (
    isIndividualType &&
    user &&
    typeof user === 'object' &&
    'organizationAddressId' in user &&
    user.organizationAddressId !== proformaInvoice.address.addressId
  ) {
    billingAddress = (
      <View style={ProformaInvoicePageStyle.addressType}>
        <Text>{proformaInvoice.address.addressType.name}</Text>
        <Text>{`${user.name} ${user.surname}`}</Text>
      </View>
    );
  }

  return (
    <Document
      title='Proforma Invoice'
      author='Periopsis Ltd'
      subject='Periopsis Proforma Invoice Details'
      creator='Periopsis Ltd'
    >
      <PageLayout
        name={`${invoiceDate}`}
        heading={'Proforma Invoice'}
        monthlyPrice={proformaInvoice.overallTotal}
        priceUnit={'/Month'}
        currency={proformaInvoice.selectedPackage.currency}
      >
        <View style={ProformaInvoicePageStyle.detailsContainer}>
          <ProformaSection heading='Proforma & Contract'>
            <DataContainer
              label='Proforma Invoice Number'
              data={proformaInvoice.proformaInvoiceNumber}
            />
            <DataContainer
              label='Order Number'
              data={proformaInvoice.orderNumber}
            />
            <DataContainer label='Proforma Invoice Date' data={invoiceDate} />
            <DataContainer
              label='Proforma Invoice Due Date'
              data={invoiceDueDate}
            />
            <DataContainer
              label='Service Contract Start Date'
              data={contractStartDate}
            />
            <DataContainer
              label='Service Contract End Date'
              data={contractEndDate}
            />
          </ProformaSection>
          <ProformaSection heading='Selection Details'>
            <DataContainer
              label='Selected Service Type'
              data={proformaInvoice.serviceType}
            />
            <DataContainer
              label='Selected Package'
              data={proformaInvoice.selectedPackage.packageName}
            />
            <DataContainer
              label='Monthly Price'
              data={`${proformaInvoice.selectedPackage.monthlyPrice}${proformaInvoice.selectedPackage.priceUnit}`}
            />
            <DataContainer
              label='Duration'
              data={`${proformaInvoice.selectedPackage.duration}${proformaInvoice.selectedPackage.durationUnit}`}
            />
            <DataContainer
              label='Package Area Limit'
              data={`${proformaInvoice.selectedPackage.areaLimit}${proformaInvoice.selectedPackage.areaLimitUnit}`}
            />
            <DataContainer
              label='Selected Total Area'
              data={`${proformaInvoice.totalArea}${proformaInvoice.areaUnit}`}
            />
            <DataContainer
              label='User Limit'
              data={`${proformaInvoice.selectedPackage.userLimit}`}
            />
            <DataContainer
              label='Number of Selected Subservices'
              data={proformaInvoice.selectedPackage.subServices.length}
            />
            <DataContainer
              label='Number of Selected Polygons'
              data={contract.polygons.length}
            />
            <View style={ProformaInvoicePageStyle.listDetailsNote}>
              <Text>
                List of selected subservices and price of each subservice is
                given in second page and list of selected polygons and polygon
                details is listed in third page
              </Text>
            </View>
          </ProformaSection>
          <ProformaSection heading='Customer Details'>
            <DataContainer label='Name' data={proformaInvoice.customerName} />
            <DataContainer label='Email' data={proformaInvoice.customerEmail} />
            <View style={ProformaInvoicePageStyle.addressContainer}>
              <Text style={ProformaInvoicePageStyle.addressHeading}>
                Customer Billing Address
              </Text>
              {billingAddress}
              <Text>{createFullAddress(proformaInvoice.address)}</Text>
            </View>
          </ProformaSection>
          <ProformaSection heading='Pricing Details'>
            <DataContainer
              label='Polygon Total Area Price'
              data={`${proformaInvoice.selectedPackage.currency}${(
                proformaInvoice.totalAmount -
                proformaInvoice.selectedPackage.subTotal
              ).toFixed(2)}`}
            />
            <DataContainer
              label='Subservices Price'
              data={`${
                proformaInvoice.selectedPackage.currency
              }${proformaInvoice.selectedPackage.subTotal.toFixed(2)}`}
            />
            <DataContainer
              label='Discount Rate'
              data={`${proformaInvoice.discountRate.toFixed(2)}%`}
            />
            <DataContainer
              label='Discount Amount'
              data={`${
                proformaInvoice.selectedPackage.currency
              }${proformaInvoice.discountAmount.toFixed(2)}`}
            />
            <DataContainer
              label='VAT Rate'
              data={`${proformaInvoice.VATRate.toFixed(2)}%`}
            />
            <DataContainer
              label='VAT Amount'
              data={`${
                proformaInvoice.selectedPackage.currency
              }${proformaInvoice.VATAmount.toFixed(2)}`}
            />
            <View>
              <DataContainer
                label='Overall Total'
                data={`${
                  proformaInvoice.selectedPackage.currency
                }${proformaInvoice.overallTotal.toFixed(2)} / month`}
              />
              <DataContainer
                label={`Service ${proformaInvoice.selectedPackage.duration} ${proformaInvoice.selectedPackage.durationUnit} Total Amount`}
                data={`${proformaInvoice.selectedPackage.currency}${(
                  (proformaInvoice.selectedPackage.duration / 30) *
                  proformaInvoice.overallTotal
                ).toFixed(2)}`}
              />
            </View>
          </ProformaSection>
        </View>
      </PageLayout>
      {hasSubservices ? (
        <PageLayout
          name={`${invoiceDate}`}
          heading={'Proforma Invoice'}
          monthlyPrice={proformaInvoice.overallTotal}
          priceUnit={'/Month'}
          currency={proformaInvoice.selectedPackage.currency}
        >
          <View style={ProformaInvoicePageStyle.subservicesHeading}>
            <Text>Selected Subservices and Prices</Text>
          </View>
          {proformaInvoice.selectedPackage.subServices.map((subservice) => (
            <SubserviceContainer key={subservice._id}>
              <DataContainer
                label='Subservice'
                data={subservice.subServiceName}
              />
              <DataContainer
                label='Price'
                data={`${subservice.currency}${subservice.unitPrice}`}
              />
            </SubserviceContainer>
          ))}
        </PageLayout>
      ) : null}
      {polygonChunks && Array.isArray(polygonChunks) && polygonChunks.length > 0
        ? polygonChunks.map((polygonChunk, index) => (
            <PageLayout
              name={`${invoiceDate}`}
              heading={'Proforma Invoice'}
              monthlyPrice={proformaInvoice.overallTotal}
              priceUnit={'/Month'}
              currency={proformaInvoice.selectedPackage.currency}
              key={index}
            >
              <View style={ProformaInvoicePageStyle.subservicesHeading}>
                <Text>Selected Polygons and Prices (Page {index + 1})</Text>
              </View>
              {polygonChunk.map((polygon) => (
                <View
                  key={polygon.mid}
                  style={ProformaInvoicePageStyle.polygonDataContainer}
                >
                  <DataContainer label='Polygon Id' data={polygon.mid} />
                  {polygon.polygonName ? (
                    <DataContainer
                      label='Polygon Name'
                      data={polygon.polygonName}
                    />
                  ) : null}
                  <DataContainer
                    label='Area'
                    data={`${polygon.area} ${polygon.areaUnit}`}
                  />
                  <DataContainer
                    label='Polygon Price'
                    data={`${
                      proformaInvoice.selectedPackage.currency
                    }${getTotalPolygonPrice(
                      polygon.area,
                      proformaInvoice.selectedPackage.monthlyPrice
                    )}/Month`}
                  />
                  <DataContainer label='District' data={polygon.district} />
                  <DataContainer label='Country' data={polygon.country} />
                </View>
              ))}
              {index === polygonChunks.length - 1 ? (
                <View style={ProformaInvoicePageStyle.proformaNoteSection}>
                  <Text>
                    The prices listed in this Proforma Invoice are valid for a
                    period of 15 days from the date of issuance. Please be
                    advised that these prices may be subject to revision after
                    the 15-day validity period due to potential updates. We
                    kindly recommend reviewing and confirming your order within
                    this timeframe to secure the current pricing.
                  </Text>
                </View>
              ) : null}
            </PageLayout>
          ))
        : null}
    </Document>
  );
};

export default ProformaInvoicePDF;
