import { ChangeEvent, useRef, useState, useMemo, useEffect } from 'react';
import CodeInput from '../../../../../../../UI/CodeInput/CodeInput';
import classes from './AccountValidationCode.module.scss';
import Button from '../../../../../../../UI/FormElements/Button/Button';
import CodeView from './CodeView/CodeView';
import ActionContainer from '../../../../../../../UI/FormElements/ActionContainer/ActionContainer';
import formatTime from '../../../../../../../../utils/formatTime';
import { useTranslation } from 'react-i18next';

interface IProps {
  onResend: () => void;
  onVerify: (code: string) => void;
  isValidCode: boolean;
  isLoading: boolean;
  isError: boolean;
}

const AccountValidationCode = ({
  onResend,
  onVerify,
  isValidCode,
  isLoading,
  isError,
}: IProps) => {
  const [firstVal, setFirstVal] = useState('');
  const [secondVal, setSecondVal] = useState('');
  const [thirdVal, setThirdVal] = useState('');
  const [fourthVal, setFourthVal] = useState('');
  const [fifthVal, setFifthVal] = useState('');
  const [time, setTime] = useState(300);

  const firstRef = useRef<HTMLInputElement | null>(null);
  const secondRef = useRef<HTMLInputElement | null>(null);
  const thirdRef = useRef<HTMLInputElement | null>(null);
  const fourthRef = useRef<HTMLInputElement | null>(null);
  const fifthRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();

  const code = `${firstVal.toUpperCase()}${secondVal.toUpperCase()}${thirdVal.toUpperCase()}${fourthVal.toUpperCase()}${fifthVal.toUpperCase()}`;

  useEffect(() => {
    if (time > 0) {
      const timer = setTimeout(() => {
        setTime((prevState) => prevState - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [time]);

  useEffect(() => {
    if (isError) {
      setTime(0);
    }
  }, [isError]);

  const codeArray = useMemo(
    () => [
      { code: firstVal, id: 'first-code' },
      { code: secondVal, id: 'second-code' },
      { code: thirdVal, id: 'third-code' },
      { code: fourthVal, id: 'fourth-code' },
      { code: fifthVal, id: 'fifth-code' },
    ],
    [firstVal, secondVal, thirdVal, fourthVal, fifthVal]
  );

  const firstCodeEntryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const codeElement = e.target.value;

    if (firstVal === '') {
      setFirstVal(codeElement);
    }
  };

  const firstFocusHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Backspace') {
      setFirstVal('');
      return;
    }

    if (
      firstRef.current &&
      firstRef.current.value &&
      secondRef &&
      secondRef.current
    ) {
      secondRef.current.focus();
    }
  };

  const secondCodeEntryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const codeElement = e.target.value;

    if (secondVal === '') {
      setSecondVal(codeElement);
    }

    if (codeElement === '') {
      setSecondVal('');
    }
  };

  const secondFocusHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      secondRef.current &&
      secondRef.current.value &&
      thirdRef &&
      thirdRef.current
    ) {
      thirdRef.current.focus();
    } else if (
      secondRef.current &&
      !secondRef.current.value &&
      firstRef &&
      firstRef.current
    ) {
      firstRef.current.focus();
    }
  };

  const thirdCodeEntryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const codeElement = e.target.value;
    if (thirdVal === '') {
      setThirdVal(codeElement);
    }

    if (codeElement === '') {
      setThirdVal('');
    }
  };

  const thirdFocusHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      thirdRef.current &&
      thirdRef.current.value &&
      fourthRef &&
      fourthRef.current
    ) {
      fourthRef.current.focus();
    } else if (
      thirdRef.current &&
      !thirdRef.current.value &&
      secondRef &&
      secondRef.current
    ) {
      secondRef.current.focus();
    }
  };

  const fourthCodeEntryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const codeElement = e.target.value;
    if (fourthVal === '') {
      setFourthVal(codeElement);
    }

    if (codeElement === '') {
      setFourthVal('');
    }
  };

  const fourthFocusHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      fourthRef.current &&
      fourthRef.current.value &&
      fifthRef &&
      fifthRef.current
    ) {
      fifthRef.current.focus();
    } else if (
      fourthRef.current &&
      !fourthRef.current.value &&
      thirdRef &&
      thirdRef.current
    ) {
      thirdRef.current.focus();
    }
  };

  const fifthCodeEntryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const codeElement = e.target.value;
    if (fifthVal === '') {
      setFifthVal(codeElement);
    }

    if (codeElement === '') {
      setFifthVal('');
    }
  };

  const fifthFocusHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      fifthRef.current &&
      !fifthRef.current.value &&
      fourthRef &&
      fourthRef.current
    ) {
      fourthRef.current.focus();
    }
  };

  const resendHandler = () => {
    if (onResend && typeof onResend === 'function') {
      onResend();
      setTime(300);
    }
  };

  const verifyCodeHandler = () => {
    if (onVerify && typeof onVerify === 'function') {
      onVerify(code);
    }
  };

  const allEntered =
    firstVal !== '' &&
    secondVal !== '' &&
    thirdVal !== '' &&
    fourthVal !== '' &&
    fifthVal !== '';

  return (
    <div className={classes.Wrapper}>
      <p>{t('auth:verify_email_desc')}</p>
      <div className={classes.CodeEntryContainer}>
        <CodeInput
          id='first-value'
          onChange={firstCodeEntryHandler}
          value={firstVal}
          ref={firstRef}
          onKeyUp={firstFocusHandler}
        />
        <CodeInput
          id='second-value'
          onChange={secondCodeEntryHandler}
          value={secondVal}
          ref={secondRef}
          onKeyUp={secondFocusHandler}
        />
        <CodeInput
          id='third-value'
          onChange={thirdCodeEntryHandler}
          value={thirdVal}
          ref={thirdRef}
          onKeyUp={thirdFocusHandler}
        />
        <CodeInput
          id='fourth-value'
          onChange={fourthCodeEntryHandler}
          value={fourthVal}
          ref={fourthRef}
          onKeyUp={fourthFocusHandler}
        />
        <CodeInput
          id='fifth-value'
          onChange={fifthCodeEntryHandler}
          value={fifthVal}
          ref={fifthRef}
          onKeyUp={fifthFocusHandler}
        />
      </div>
      <CodeView codeArray={codeArray} timer={formatTime(time)} />
      <ActionContainer className={classes.Actions}>
        <Button
          isFake={time > 0}
          btnClass={classes.CodeButton}
          onClick={resendHandler}
        >
          {t('auth:Resend')}
        </Button>
        <Button
          btnClass={classes.CodeButton}
          onClick={verifyCodeHandler}
          isFake={!allEntered}
        >
          {t('auth:Verify_Email')}
        </Button>
      </ActionContainer>
    </div>
  );
};

export default AccountValidationCode;
