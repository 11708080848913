import { item } from '../tsTypes/types.components';

const calculateLevenshteinDistance = (a: string, b: string): number => {
  const matrix: number[][] = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      const cost = a[j - 1] === b[i - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1,
        matrix[i][j - 1] + 1,
        matrix[i - 1][j - 1] + cost
      );
    }
  }

  const maxLen = Math.max(a.length, b.length);
  return 1 - matrix[b.length][a.length] / maxLen;
};

const isSimilarEntry = (
  enteredName: string,
  departmentsList: item[],
  threshold: number = 0.8
): boolean => {
  // Convert entered name and department names to lowercase for case-insensitive comparison
  const lowerCaseEnteredName = enteredName.toLowerCase();

  // Find the department name with the highest similarity
  const bestMatch = departmentsList.reduce(
    (acc, department) => {
      const similarity = calculateLevenshteinDistance(
        lowerCaseEnteredName,
        department.name.toLowerCase()
      );
      return similarity > acc.similarity
        ? { name: department.name, similarity }
        : acc;
    },
    { name: '', similarity: 0 }
  );

  // Check if the best match has similarity above the specified threshold
  const isSimilar = bestMatch.similarity >= threshold / 100;

  return isSimilar;
};

export default isSimilarEntry;
