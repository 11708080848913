import { FaFilePdf } from 'react-icons/fa';
import classes from './PDFError.module.scss';

const PDFError = () => {
  return (
    <div className={classes.PDFError}>
      <FaFilePdf />
      <p>PDF Error...</p>
    </div>
  );
};

export default PDFError;
