import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface IUiState {
  isExpanded: boolean;
  isLoginPage: boolean;
  showListMap: boolean;
  isRangeFilter: boolean;
  isIncidentTypeFilter: boolean;
  showMobileMenu: boolean;
  isOutBoxMessageWindowClosed: boolean;
  language: string;
  showNavUserMenu: boolean;
  showNavNotificationsMenu: boolean;
  buttonIds: string[];
  isLoading: boolean;
  isPolygonListPresentationAtAreaOfInterest: boolean;
  isOrderCompleteSuccess: boolean;
  dashboardCurrentPage: number;
  showLanguageMenu: boolean;
}

const initialState: IUiState = {
  isExpanded: false,
  isLoginPage: false,
  showListMap: true,
  isRangeFilter: false,
  isIncidentTypeFilter: false,
  showMobileMenu: false,
  isOutBoxMessageWindowClosed: false,
  language: 'en',
  showNavUserMenu: false,
  showNavNotificationsMenu: false,
  buttonIds: ['recent', 'weekly', 'heatmap', 'piechart'],
  isLoading: false,
  isPolygonListPresentationAtAreaOfInterest: false,
  isOrderCompleteSuccess: false, //This must be false, for test purpose we made it true to complete success window UI
  dashboardCurrentPage: 1,
  showLanguageMenu: false,
};

export const uiSlice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    setExpand: (state) => {
      state.isExpanded = !state.isExpanded;
    },
    setLoginPage: (state, action) => {
      state.isLoginPage = action.payload;
    },
    setListMap: (state, action) => {
      state.showListMap = action.payload;
    },
    setIsRangeFilter: (state, action) => {
      state.isRangeFilter = action.payload;
    },
    setIsIncidentTypeFilter: (state, action) => {
      state.isIncidentTypeFilter = action.payload;
    },
    setShowMobileMenu: (state) => {
      state.showMobileMenu = !state.showMobileMenu;
    },
    clearUIState: (state) => {
      state.isExpanded = false;
      state.isLoginPage = false;
      state.showListMap = true;
      state.isRangeFilter = false;
      state.showMobileMenu = false;
    },

    setIsMessageWindowClosed: (state) => {
      state.isOutBoxMessageWindowClosed = true;
    },

    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },

    setShowUserNavMenu: (state, action: PayloadAction<boolean>) => {
      state.showNavUserMenu = action.payload;
    },

    setButtonIdOrder: (state, action: PayloadAction<string[]>) => {
      state.buttonIds = action.payload;
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setShowNotificationsMenu: (state, action: PayloadAction<boolean>) => {
      state.showNavNotificationsMenu = action.payload;
    },

    setPolygonListPresentation: (state) => {
      state.isPolygonListPresentationAtAreaOfInterest =
        !state.isPolygonListPresentationAtAreaOfInterest;
    },
    setIsOrderCompleteSuccess: (state, action: PayloadAction<boolean>) => {
      state.isOrderCompleteSuccess = action.payload;
    },
    setShowLanguageMenu: (state) => {
      state.showLanguageMenu = !state.showLanguageMenu;
    },
  },
});

export const selectAllUIValues = (state: RootState) => state.userInterface;
export const {
  setExpand,
  setLoginPage,
  setListMap,
  setIsRangeFilter,
  setShowMobileMenu,
  clearUIState,
  setIsMessageWindowClosed,
  setLanguage,
  setShowUserNavMenu,
  setButtonIdOrder,
  setLoading,
  setIsIncidentTypeFilter,
  setShowNotificationsMenu,
  setPolygonListPresentation,
  setIsOrderCompleteSuccess,
  setShowLanguageMenu,
} = uiSlice.actions;
export default uiSlice.reducer;
