import { ChangeEvent, useState } from 'react';
import { IContractUser } from '../../../../../../tsTypes/interfaces';
import classes from './UserListItem.module.scss';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { toast } from 'react-toastify';
import DataRow from '../../../../../UI/DataRow/DataRow';
import InfoBox from '../../../../../UI/InfoBox/InfoBox';
import StandardButton from '../../../../../UI/FormElements/StandardButton/StandardButton';
import { useTranslation } from 'react-i18next';

interface IProps {
  user: IContractUser;
  currentUserId?: string;
  onUpdate: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemove: (userId: string) => void;
  onBlock: (userId: string) => void;
  onUnblock: (userId: string) => void;
  isUpdateable?: boolean;
}

const UserListItem = ({
  user,
  currentUserId,
  onUpdate,
  onRemove,
  onBlock,
  onUnblock,
  isUpdateable,
}: IProps) => {
  const [showActionsInfo, setShowActionsInfo] = useState(false);
  const [showAdminInfo, setShowAdminInfo] = useState(false);
  const isCurrentUser = user.userId === currentUserId;
  const { t } = useTranslation();

  const showInfoHandler = (selected: string) => {
    if (selected === 'Action') {
      setShowActionsInfo((prevState) => !prevState);
      setShowAdminInfo(false);
    }

    if (selected === 'Admin') {
      setShowAdminInfo((prevState) => !prevState);
      setShowActionsInfo(false);
    }
  };

  const removeUserHandler = (userId: string) => {
    if (isCurrentUser) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('admin:you_cannot_remove_yourself')}
          heading={t('admin:not_allowed')}
        />
      );
    }

    onRemove(userId);
  };

  const blockUserHandler = (userId: string) => {
    if (isCurrentUser) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('admin:you_cannot_block_yourself')}
          heading={t('admin:not_allowed')}
        />
      );
    }

    onBlock(userId);
  };

  const unblockUser = (userId: string) => {
    if (userId) {
      onUnblock(userId);
    }
  };

  /**
     *   <td>{user.name}</td>
      <td>{user.surname}</td>
      <td>{user.emailAddress}</td>
     */

  return (
    <div className={classes.CurrentUserContainer}>
      <div className={classes.UserData}>
        <h3>{t('admin:user_information')}</h3>
        <div className={classes.UserInformationSection}>
          <DataRow
            heading={t('translations:Name')}
            data={user.name}
            removeSeperator
          />
          <DataRow
            heading={t('translations:Surname')}
            data={user.surname}
            removeSeperator
          />
          <DataRow
            heading={t('checkout:email')}
            data={user.emailAddress}
            removeSeperator
          />
          <DataRow
            heading={t('admin:blocked_user')}
            data={
              user.isBlockedUser ? t('translations:Yes') : t('translations:No')
            }
            removeSeperator
          />
        </div>
      </div>
      <div className={classes.UserData}>
        <h3>{t('admin:authorizations')}</h3>
        <div className={classes.UserInformationSection}>
          <div className={classes.AuthContainer}>
            <label>{t('admin:action_authorization')} </label>
            <input
              type='checkbox'
              id='action-auth'
              name='action-auth'
              value={user.userId}
              checked={user.authorizations.hasAuthToActions}
              onChange={onUpdate}
              disabled={!isUpdateable}
            />

            <InfoBox
              onShowInfo={() => showInfoHandler('Action')}
              showInfo={showActionsInfo}
              heading={t('admin:actions_authorization')}
            >
              <div className={classes.Information}>
                <p>{t('admin:actions_authorization_desc')}</p>
              </div>
            </InfoBox>
          </div>
          <div className={classes.AuthContainer}>
            <label>{t('admin:admin_authorization')}</label>
            <input
              type='checkbox'
              name='admin-auth'
              id='admin-auth'
              value={user.userId}
              checked={user.authorizations.hasAuthtoAdmin}
              onChange={onUpdate}
              disabled={!isUpdateable}
            />
            <InfoBox
              showInfo={showAdminInfo}
              onShowInfo={() => showInfoHandler('Admin')}
              heading={t('admin:admin_authorization')}
            >
              <div className={classes.Information}>
                <p>{t('admin:admin_authorization_desc_1')}</p>
                <p>{t('admin:admin_authorization_desc_2')}</p>
                <p>{t('admin:admin_authorization_desc_3')}</p>
              </div>
            </InfoBox>
          </div>
        </div>
      </div>
      <div className={classes.Actions}>
        {!user.isBlockedUser ? (
          <td>
            <StandardButton
              label={t('admin:block_user')}
              type='button'
              btnType='orange'
              onClick={() => blockUserHandler(user.userId)}
              fakeButton={isCurrentUser || !isUpdateable}
            />
          </td>
        ) : (
          <td>
            <StandardButton
              label={t('admin:unblock_user')}
              btnType='danger'
              type='button'
              onClick={() => unblockUser(user.userId)}
              fakeButton={!isUpdateable}
            />
          </td>
        )}
        <StandardButton
          label={t('admin:remove_user')}
          type='button'
          btnType='orange'
          onClick={() => removeUserHandler(user.userId)}
          fakeButton={isCurrentUser || !isUpdateable}
        />
      </div>
    </div>
  );
};

export default UserListItem;
