import { ChangeEvent, useEffect, useRef, useState } from 'react';
import classes from './TextArea.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  label: string;
  maxLength: number;
  invalid: boolean;
  title: string;
  errorMessage: string;
  showRemaining?: boolean;
  dataInputType?: string;
  disabled?: boolean;
  id?: string;
  style?: React.CSSProperties;
}

const TextArea = ({
  name,
  value,
  onChange,
  label,
  maxLength,
  invalid,
  title,
  errorMessage,
  showRemaining,
  dataInputType,
  disabled,
  id,
  style,
}: IProps) => {
  const [rows, setRows] = useState(3);
  const totalLength = value.length;
  const additionalRow = Math.floor(totalLength / 120) + 3;
  const [remaining, setRemaining] = useState(maxLength);
  const labelStyle = value ? { fontSize: '11px', bottom: '100%' } : {};
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (additionalRow && rows < 18) {
      setRows(additionalRow);
    }
    // eslint-disable-next-line
  }, [additionalRow, totalLength]);

  useEffect(() => {
    if (maxLength >= 0) {
      const remainingChars = maxLength - totalLength;
      setRemaining(remainingChars);
    }
  }, [totalLength, maxLength]);

  const focusOnTextArea = () => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  return (
    <div className={classes.TextAreaComponentWrapper}>
      <div
        className={`${classes.TextAreaContainer} ${
          invalid ? classes.Invalid : ''
        }`}
      >
        <label style={labelStyle} onClick={focusOnTextArea}>
          {label}
        </label>
        <textarea
          cols={10}
          rows={rows}
          name={name}
          value={value}
          onChange={!disabled ? onChange : () => {}}
          placeholder={label}
          maxLength={maxLength}
          className={classes.TextArea}
          title={title}
          data-input-type={dataInputType}
          disabled={disabled}
          id={id}
          style={style}
          ref={textAreaRef}
        />
      </div>
      {showRemaining ? (
        <div className={classes.RemainingCharsContainer}>
          <p>{t('forms:Remaining')} : </p>
          <data>{remaining}</data>
        </div>
      ) : null}
      {invalid ? (
        <span className={classes.ErrorMessage}>{`${errorMessage}`}</span>
      ) : null}
    </div>
  );
};

export default TextArea;
