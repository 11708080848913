import { ChangeEvent, useEffect, useState } from 'react';
import classes from './PhoneCodesDropDown.module.scss';
import useEscape from '../../../../CustomHooks/useEscape';
import { ICountryPhone } from '../../../../tsTypes/types.model';
import Input from '../../FormElements/Input/Input';
import { BsFillExclamationDiamondFill } from 'react-icons/bs';

interface IProps {
  countryPhones?: ICountryPhone[];
  userCountry: ICountryPhone | null;
  optionSelectClass?: string;
  onCodeSelect: (codeData: ICountryPhone) => void;
  disabled?: boolean;
}

const PhoneCodesDropDown = ({
  countryPhones,
  userCountry,
  optionSelectClass,
  onCodeSelect,
  disabled,
}: IProps) => {
  const [show, setShow] = useState(false);
  const [selectedCode, setSelectedCode] = useState('Code');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [search, setSearch] = useState('');

  const [mount, setMount] = useState(false);
  const hasUserCountry = userCountry !== null;

  const optionSelectBtnId = 'option-select-button';

  useEffect(() => {
    if (hasUserCountry) {
      setSelectedCode(userCountry.countryCode);
      setSelectedCountry(userCountry.name);
    }
    // eslint-disable-next-line
  }, [hasUserCountry]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    let innerTimer: NodeJS.Timeout;
    const outsideClickHandler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (target.id !== optionSelectBtnId && target.id !== 'search-country') {
        timer = setTimeout(() => {
          setShow(false);

          innerTimer = setTimeout(() => {
            setMount(false);
          }, 200);
        }, 200);
      }
    };

    document.addEventListener('mouseup', outsideClickHandler);

    return () => {
      document.removeEventListener('mouseup', outsideClickHandler);
      if (timer) {
        clearTimeout(timer);
      }

      if (innerTimer) {
        clearTimeout(innerTimer);
      }
    };
  }, []);

  useEscape({
    setterValue: show,
    onEscape: () => {
      const timer = setTimeout(() => {
        setShow(false);
        clearTimeout(timer);
        const innerTimer = setTimeout(() => {
          setMount(false);
          clearTimeout(innerTimer);
        }, 200);
      }, 200);
    },
  });

  if (!countryPhones) {
    return null;
  }

  const selectCountryCodeHandler = (countryPhone: ICountryPhone) => {
    if (disabled) {
      return;
    }

    setSelectedCode(countryPhone.countryCode);
    setSelectedCountry(selectedCountry.trim());

    if (onCodeSelect && typeof onCodeSelect === 'function') {
      onCodeSelect(countryPhone);
    }

    const timer = setTimeout(() => {
      setShow(false);
      clearTimeout(timer);
      setSearch('');
    }, 200);
  };

  const showMenuHandler = () => {
    if (disabled) {
      return;
    }

    if (!show) {
      setMount(true);

      const timer = setTimeout(() => {
        setShow(true);
        clearTimeout(timer);
      }, 50);
    } else {
      setShow(false);

      const timer = setTimeout(() => {
        setMount(false);
        clearTimeout(timer);
      }, 100);
    }

    setSearch('');
  };

  const searchCountryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const countryName = e.target.value;
    setSearch(countryName);
  };

  const filteredPhones = countryPhones.filter((cnt) =>
    cnt.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className={classes.PhoneCodesContainer}>
      <button
        className={`${classes.OptionSelect} ${
          optionSelectClass ? optionSelectClass : ''
        } ${disabled ? classes.Disabled : ''}`}
        onClick={showMenuHandler}
        type='button'
        id={optionSelectBtnId}
      >
        {selectedCode}
      </button>

      {mount ? (
        <div
          className={`${classes.Options} ${show ? classes.Show : ''}`}
          style={{
            height: filteredPhones.length <= 3 ? 'max-content' : undefined,
          }}
        >
          <Input
            type='search'
            placeholder='Search Country'
            name='search-country'
            onChange={searchCountryHandler}
          />
          <div className={classes.CountryList}>
            {filteredPhones.length > 0 &&
              filteredPhones.map((data) => (
                <div
                  className={`${classes.OptionWrapper} ${
                    selectedCountry === data.name.trim() ? classes.Selected : ''
                  }`}
                  title={`International phone code of ${data.name} : ${data.countryCode}`}
                  key={data.code}
                >
                  <button
                    type='button'
                    className={`${classes.Option}`}
                    onClick={() => selectCountryCodeHandler(data)}
                  >
                    <div>
                      <img
                        src={`/images/flags/${data.code.toLowerCase()}.png`}
                        alt={`${data.name} Flag`}
                        title={`${data.name} Flag`}
                        width={32 * 0.6}
                        height={24 * 0.6}
                      />
                    </div>
                    <span>{data.countryCode}</span>
                    <span>{data.name}</span>
                  </button>
                </div>
              ))}
            {filteredPhones.length === 0 ? (
              <div className={classes.NoCountry}>
                <BsFillExclamationDiamondFill />
                <p>No Country Found</p>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PhoneCodesDropDown;
