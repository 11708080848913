import { Fragment } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import classes from './Loading.module.scss';

interface IProps {
  text?: string;
  showBackDrop: boolean;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}

const Loading = ({ text, showBackDrop, size, style, className }: IProps) => {
  const loadingText = text ? text : 'Loading';
  const sizeToUse = size ? size : 1;
  const translateValue = 50 / sizeToUse;
  let textStyle = { fontSize: 19 };

  if (text && text.length > 7) {
    const textLength = text.length;

    textStyle = { fontSize: 20 * (1 - (textLength - 7) * 0.142857) };
  }

  return (
    <Fragment>
      <Backdrop show={showBackDrop} />
      <div
        className={`${classes.Ring} ${className ? className : ''}`}
        style={{
          transform: `scale(${sizeToUse}) translate(-${translateValue}%, -${translateValue}%)`,
          ...style,
        }}
      >
        <span className={classes.Text} style={textStyle}>
          {loadingText}
        </span>
      </div>
    </Fragment>
  );
};

export default Loading;
