import { MdOutlineInfo } from 'react-icons/md';
import HeadingBar from '../HeadingBar/HeadingBar';
import classes from './InfoBox.module.scss';

interface IProps {
  showInfo: boolean;
  onShowInfo: () => void;
  heading?: string;
  children: React.ReactNode;
}

const InfoBox = ({ showInfo, onShowInfo, heading, children }: IProps) => {
  return (
    <div className={classes.InfoBoxWrapper}>
      <button type='button' onMouseEnter={onShowInfo} onMouseLeave={onShowInfo}>
        <MdOutlineInfo />
      </button>
      {showInfo ? (
        <div className={classes.InfoBox}>
          {heading ? <HeadingBar heading={heading} headingSize={4} /> : null}
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default InfoBox;
