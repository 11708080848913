import { ChangeEvent } from 'react';
import { IAddress } from '../../../tsTypes/types.model';
import createFullAddress from '../../../utils/addressUtilityFunctions/createFullAddress';
import classes from './AddressOption.module.scss';
import { AiTwotoneDelete } from 'react-icons/ai';
import RadioButtonDot from '../RadioButtonDot/RadioButtonDot';
import { useTranslation } from 'react-i18next';

interface IProps {
  address: IAddress;
  isSelected: boolean;
  onAddressSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  organizationData?: {
    addressId: string;
    organizationName: string;
    VATNumber: string;
  };
  onDelete: (addressId: string) => void;
}

const AddressOption = ({
  address,
  isSelected,
  onAddressSelect,
  organizationData,
  onDelete,
}: IProps) => {
  let organizationName = '';
  let VATNumber = '';
  let addressId = '';
  let targetId = '';
  let organizationDetailsAtAddress: React.ReactNode | null = null;
  const { t } = useTranslation();

  if (!address) {
    return null;
  }

  if (
    organizationData &&
    typeof organizationData === 'object' &&
    'addressId' in organizationData &&
    organizationData.addressId &&
    'organizationName' in organizationData &&
    organizationData.organizationName
  ) {
    organizationName = organizationData.organizationName;
    VATNumber = organizationData.VATNumber;
    addressId = organizationData.addressId;
  }

  if (organizationName && VATNumber && addressId === address.addressId) {
    organizationDetailsAtAddress = (
      <div className={classes.OrganizationDetails}>
        <h3>{organizationName}</h3>
        <p>
          {t('translations:vat_number')} : {VATNumber}
        </p>
      </div>
    );
  }

  if (
    address &&
    typeof address === 'object' &&
    'addressId' in address &&
    address.addressId
  ) {
    targetId = address.addressId;
  }

  const deleteAddressHandler = (addressId: string) => {
    onDelete(addressId);
  };

  return (
    <div
      className={`${classes.OptionWrapper} ${
        isSelected ? classes.Selected : ''
      }`}
    >
      <div className={`${classes.AddressOptionContainer} `}>
        <label htmlFor={address.addressId} className={classes.Label}>
          <div className={classes.LabelContent}>
            <div className={classes.AddressContent}>
              <h3>{address.addressType.name}</h3>
              {organizationDetailsAtAddress}
              <address>{createFullAddress(address)}</address>
              {address.addressDetails && address.addressDetails.name ? (
                <div>
                  <h4>{t('auth:Address_Details')}</h4>
                  <address>{address.addressDetails.name}</address>
                </div>
              ) : null}
            </div>
            <RadioButtonDot isSelected={isSelected} />
            {/* <div
              className={`${classes.RadioButton} ${
                isSelected ? classes.Selected : ''
              }`}
            >
              <div className={classes.Dot}></div>
            </div> */}
          </div>
        </label>
        <input
          type='radio'
          name='address-option'
          id={address.addressId}
          checked={isSelected}
          value={address.addressId}
          onChange={onAddressSelect}
        />
      </div>
      <div className={classes.DeleteContainer}>
        <AiTwotoneDelete
          title={t('translations:delete_address')}
          onClick={() => deleteAddressHandler(targetId)}
        />
      </div>
    </div>
  );
};

export default AddressOption;
