import React from 'react';
import classes from './PackageDescription.module.scss';
import PackageTypeSection from '../PackageTypeSection/PackageTypeSection';

interface IProps {
  packageName: string;
  description: string;
  onClick: () => void;
  isCloseButton: boolean;
  show: boolean;
}

const PackageDescription = ({
  packageName,
  description,
  onClick,
  isCloseButton,
  show,
}: IProps) => {
  return (
    <div
      className={`${classes.PackageDescription} ${show ? classes.Show : ''}`}
    >
      <PackageTypeSection
        packageName={packageName}
        onClick={onClick}
        isCloseButton={isCloseButton}
      />
      <div className={classes.DescriptionContainer}>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default PackageDescription;
