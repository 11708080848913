import { useTranslation } from 'react-i18next';
import { MdLanguage, MdOutlineClose } from 'react-icons/md';
import Backdrop from '../Backdrop/Backdrop';
import classes from './LanguageMenu.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import {
  selectAllUIValues,
  setShowLanguageMenu,
} from '../../../features/UI/uiSlice';

const languages = [
  { label: 'English', value: 'en' },
  { label: 'Greek', value: 'el' },
];

const LanguageMenu = () => {
  const { showLanguageMenu } = useSelector(selectAllUIValues);
  const dispatch = useDispatch<AppDispatch>();
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const languageMenuHandler = () => {
    dispatch(setShowLanguageMenu());
  };

  const changeLanguageHandler = (selected: string) => {
    i18n.changeLanguage(selected);
    dispatch(setShowLanguageMenu());
  };

  if (!showLanguageMenu) {
    return null;
  }

  return (
    <>
      <div className={classes.LanguageWindow}>
        <div className={classes.HeadingSection}>
          <div className={classes.Header}>
            <MdLanguage />
            <h3>{t('translations:Select_Language')}</h3>
          </div>
          <button onClick={languageMenuHandler}>
            <MdOutlineClose />
          </button>
        </div>
        <div className={classes.Languages}>
          {languages.map((language) => (
            <button
              key={language.value}
              onClick={() => changeLanguageHandler(language.value)}
              className={language.value === lang ? classes.Active : ''}
            >
              {t(`translations:${language.label}`)}
            </button>
          ))}
        </div>
      </div>
      <Backdrop show={showLanguageMenu} />
    </>
  );
};

export default LanguageMenu;
