import React from 'react';
import classes from './ListItemLoader.module.scss';

const ListItemLoader = () => {
  return (
    <div className={classes.IncidentListItemContainer}>
      <div className={classes.IncidentImageContainer}>
        <div className={classes.IncidentImage} />
      </div>
      <div className={classes.DataMainContainer}>
        <div className={classes.IncidentDataContainer}>
          <div className={classes.TypeDateMainContainer}>
            <div className={classes.TypeContent}>
              <div />
            </div>
            <div className={classes.DetectionDateContainer}>
              <div />
            </div>
          </div>
          <div className={classes.GarbageIconContainer}>
            <div />
          </div>
        </div>
        <div className={classes.Actions}>
          <div className={classes.Action} />
          <div className={classes.Action} />
          <div className={classes.Action} />
        </div>
      </div>
    </div>
  );
};

export default ListItemLoader;
