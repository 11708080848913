import { IContractNotification } from '../../../../tsTypes/interfaces';
import NotificationListItem from '../NotificationListItem/NotificationListItem';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';

import classes from './NotificationList.module.scss';
import { Fragment, useState } from 'react';
import NotificationsSectionContainer from '../NotificationsSectionContainer/NotificationsSectionContainer';

interface IProps {
  notifications: IContractNotification[];
  heading?: string;
  // onSelect: (id: string) => void;
}
//
const NotificationList = ({ notifications, heading }: IProps) => {
  const [showList, setShowList] = useState(true);

  const showListHandler = () => {
    if (showList) {
      // onSelect('');
    }

    setShowList((prevState) => !prevState);
  };

  return (
    <NotificationsSectionContainer
      headingContent={
        heading ? (
          <Fragment>
            <button onClick={showListHandler} type='button'>
              {showList ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </button>

            <h3 onClick={showListHandler}>{heading}</h3>
          </Fragment>
        ) : null
      }
    >
      {showList ? (
        <div className={classes.NotificationsList}>
          {notifications.map((notification) => (
            <NotificationListItem
              notification={notification}
              key={notification.id}
              // onSelect={onSelect}
            />
          ))}
        </div>
      ) : null}
    </NotificationsSectionContainer>
  );
};

export default NotificationList;
