import SideMenuLinks from './SideMenuLinks/SideMenuLinks';
import classes from './SideMenuDesktop.module.scss';

const SideMenuDesktop = () => {
  return (
    <div className={`${classes.SideMenuContainer}`}>
      <SideMenuLinks isExpanded={false} />
    </div>
  );
};

export default SideMenuDesktop;
