import DataRow from '../../../../UI/DataRow/DataRow';
import {
  IUserAdminContract,
  IUserAdminContractPackage,
} from '../../../../../tsTypes/interfaces';
import classes from './SelectedContractSummary.module.scss';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

interface IProps {
  selectedContract: IUserAdminContract;
  currentPackage?: IUserAdminContractPackage;
  children?: React.ReactNode;
  remainingUsers?: number;
}

const SelectedContractSummary = ({
  selectedContract,
  currentPackage,
  children,
  remainingUsers,
}: IProps) => {
  const contractStatus = selectedContract.contractStatus;
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  return (
    <div className={classes.SelectedContractSummary}>
      <div className={classes.HeadingSection}>
        <h3>{t('admin:selected_contract_summary')}</h3>
      </div>
      <div className={classes.Details}>
        <DataRow
          heading={t('admin:current_contract')}
          data={t(
            `dashboard:${getTranslationKey(selectedContract.serviceTypeName)}`
          )}
          removeSeperator
        />
        <DataRow
          heading={t('admin:status')}
          data={t(`admin:${selectedContract.status.toLowerCase()}`)}
          removeSeperator
        />
        <DataRow
          heading={t('admin:start_date')}
          data={new Date(selectedContract.createdAt).toLocaleDateString(
            language,
            {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }
          )}
          removeSeperator
        />
        <DataRow
          heading={t('admin:expiry_date')}
          data={new Date(selectedContract.endDate).toLocaleDateString(
            language,
            {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }
          )}
          removeSeperator
        />
        {currentPackage ? (
          <DataRow
            heading={t('admin:contract_duration')}
            data={`${currentPackage.duration} ${t(
              `auth:${getTranslationKey(currentPackage.durationUnit)}`
            )}`}
            removeSeperator
          />
        ) : null}
        <DataRow
          heading={t('admin:remaining_duration')}
          data={`${contractStatus.remainingDaysToExpire} ${t('auth:days')}`}
          removeSeperator
        />
        <DataRow
          heading={t('admin:total_area_coverage')}
          data={`${selectedContract.totalArea.toFixed(2)} ${
            selectedContract.areaUnit
          }`}
          removeSeperator
        />
        {currentPackage ? (
          <DataRow
            heading={t('admin:selected_package')}
            data={currentPackage.packageName}
            removeSeperator
          />
        ) : null}
        {currentPackage ? (
          <DataRow
            heading={t('admin:user_limit')}
            data={currentPackage.userLimit.toString()}
            removeSeperator
          />
        ) : null}
        {remainingUsers ? (
          <DataRow
            heading={t('admin:remaining_users')}
            data={remainingUsers.toString()}
            removeSeperator
          />
        ) : null}
        <DataRow
          heading={t('admin:total_service_price')}
          data={`€${selectedContract.proformaData.overallTotal}`}
          removeSeperator
        />
      </div>
      {children}
    </div>
  );
};

export default SelectedContractSummary;
