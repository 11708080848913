import classes from './TableRow.module.scss';

interface IProps {
  rowData: string[] | number[] | React.ReactNode[];
  alignFirstToLeft?: boolean;
  alignLastToRight?: boolean;
}

const TableRow = ({ rowData, alignFirstToLeft, alignLastToRight }: IProps) => {
  return (
    <tr>
      {rowData.map((datum, index) => (
        <td
          key={index}
          className={`${
            alignFirstToLeft && index === 0 ? classes.AlignLeft : ''
          } ${
            alignLastToRight && index === rowData.length - 1
              ? classes.AlignRight
              : ''
          }`}
        >
          {datum}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
