import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { IStep } from '../../tsTypes/types.components';

export interface IPointState {
  stepsData: {
    steps: IStep[];
    distance: number;
    duration: number;
  } | null;
}

const initialState: IPointState = {
  stepsData: null,
};

const pointSlice = createSlice({
  name: 'point',
  initialState,
  reducers: {
    clearPointState: (state) => {
      state.stepsData = null;
    },
    storePointDirectionsData: (
      state,
      action: PayloadAction<{
        steps: IStep[];
        distance: number;
        duration: number;
      } | null>
    ) => {
      if (action && action.payload && action.payload.steps) {
        state.stepsData = {
          steps: action.payload.steps,
          distance: action.payload.distance,
          duration: action.payload.duration,
        };
      } else {
        state.stepsData = null;
      }
    },
  },
});

export const selectAllPointValues = (state: RootState) => state.point;

export const { storePointDirectionsData, clearPointState } = pointSlice.actions;

export default pointSlice.reducer;
