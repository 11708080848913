import { ChangeEvent } from 'react';
import classes from './ActionOptions.module.scss';
import RadioButton from '../../../../UI/FormElements/RadioButton/RadioButton';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  selected: {
    isVisitedTable: boolean;
    isReportedTable: boolean;
    isCleanedTable: boolean;
  };
}

const ActionOptions = ({ onSelect, selected }: IProps) => {
  const { isVisitedTable, isCleanedTable, isReportedTable } = selected;
  const { t } = useTranslation();

  return (
    <div className={classes.OptionSelection}>
      <RadioButton
        name='table'
        label={t('statistics:visited')}
        id='visited'
        onChange={onSelect}
        value='visited'
        checked={isVisitedTable}
      />
      <RadioButton
        name='table'
        label={t('statistics:reported')}
        id='reported'
        onChange={onSelect}
        value='reported'
        checked={isReportedTable}
      />
      <RadioButton
        name='table'
        label={t('statistics:cleaned')}
        id='cleaned'
        onChange={onSelect}
        value='cleaned'
        checked={isCleanedTable}
      />
    </div>
  );
};

export default ActionOptions;
