import { useSelector } from 'react-redux';
import FormModal from '../../../../UI/FormModal/FormModal';
import { selectAllAuthValues } from '../../../../../features/auth/authSlice';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
  useGetIncidentCleanedReportQuery,
  useUpdateIncidentCleanedReportMutation,
} from '../../../../../features/incidents/incidentsSlice';
import FetchingReports from '../IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import EditableRowData from '../../../../UI/EditableRowData/EditableRowData';
import { PiDetectiveDuotone } from 'react-icons/pi';
import { CiCalendarDate } from 'react-icons/ci';
import {
  ICleanUpdate,
  IContractIncident,
  IUserAnswer,
  IVisitor,
} from '../../../../../tsTypes/interfaces';
import IncidentDetailsSection from '../IncidentDetailsSection/IncidentDetailsSection';
import BorderedSectionContainer from '../BorderedSectionContainer/BorderedSectionContainer';
import ReportContainer from '../ReportConatiner/ReportContainer';
// import { FaPersonDigging } from 'react-icons/fa6';
// import { AiFillEuroCircle } from 'react-icons/ai';
import { GoStopwatch } from 'react-icons/go';
import VisitorsListItem from '../IncidentVisitForm/VisitorsListItem/VisitorsListItem';
import useFormEntry from '../../../../../CustomHooks/useFormEntry';
import {
  getDateFromInputEnteredDate,
  validateNameSurname,
  validateText,
} from '../../../../../utils/componentUtilFns';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { confirmAlert } from 'react-confirm-alert';
import { nanoid } from '@reduxjs/toolkit';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import { IoClose, IoPerson } from 'react-icons/io5';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import AddVisitorsForm from '../AddVisitorsForm/AddVisitorsForm';
import classes from './IncidentCleanedReport.module.scss';
import { item } from '../../../../../tsTypes/types.components';
import ListItem from '../AddToList/ListItem/ListItem';
import { BsBuildingFillDown } from 'react-icons/bs';
import AddToList from '../AddToList/AddToList';
import isSimilarEntry from '../../../../../utils/checkEntrySimilarity';
import NoteContainer from '../NoteContainer/NoteContainer';
import TextArea from '../../../../UI/FormElements/TextArea/TextArea';
import useUpdateNote from '../../../../../CustomHooks/useUpdateNote';
import { SiReactos } from 'react-icons/si';
import CleanupMethodology from '../IncidentCleanedForm/CleanupMethodologySection/CleanupMethodology';
// import { IoLocationSharp } from 'react-icons/io5';
import { useGetCleanReportQuestionsQuery } from '../../../../../features/report/reportsSlice';
import QuestionItem from '../IncidentCleanedForm/QuestionItem/QuestionItem';
import { arraysAreEqual } from '../../../../../utils/arraysAreEqual';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import isValidListItem from '../../../../../utils/isValidListItem';
import isLoremIpsum from '../../../../../utils/isLoremIpsumText';
import { formatDistance } from 'date-fns';
import { Settings } from '../../../../../tsTypes/enums';
import { useGetPageSettingsQuery } from '../../../../../features/Settings/settingsSlice';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import ObservedIncidentType from '../ObservedIncidentType/ObservedIncidentType';
import { FaUndo } from 'react-icons/fa';
import IncidentCleanedReportInformation from './IncidentCleanedReportInformation/IncidentCleanedReportInformation';
import { FaUserEdit } from 'react-icons/fa';
import CleanedReportPDF from '../ReportPDFs/CleanedReportPDF/CleanedReportPDF';
import { usePDF } from '@react-pdf/renderer';
import PDFError from '../../../../UI/PDFError/PDFError';
import DownloadButton from '../../../../UI/DownloadButton/DownloadButton';

interface IProps {
  show: boolean;
  contractIncidentId: string;
  contractId: string;
  isCleaned: boolean;
  onClose: () => void;
  selectedIncident: IContractIncident;
}

const validateNumberOfWorkers = (workers: string) => {
  if (isNaN(Number(workers))) {
    return false;
  }

  if (Number(workers) > 1000 || Number(workers) < 0) {
    return false;
  }

  return true;
};

const validateEnteredText = (text: string) => {
  const isValid = validateText(text, 3, 120) && isNaN(Number(text));

  return isValid;
};
const validateDepartment = (department: string) => {
  const isValid = validateText(department, 3, 120) && isNaN(Number(department));

  return isValid;
};

const validateDate = (date: string, detectedAt: string) => {
  const dateResult = getDateFromInputEnteredDate(date);
  const detectionDate = getDateFromInputEnteredDate(detectedAt);

  if (dateResult.inTime < detectionDate.inTime) {
    return false;
  }

  if (isNaN(Number(dateResult.inTime))) {
    return false;
  }

  if (dateResult.isFutureDate) {
    return false;
  }

  return true;
};

const validateExactLocation = (location: string) => {
  const isValid = validateText(location, 0, 120);

  if (location.length > 0 && isNaN(Number(location))) {
    return true;
  }

  return isValid;
};

const validateObservedType = (observedType: string) => {
  let isValidObservedType = validateText(observedType, 0, 200);

  if (
    isValidObservedType &&
    observedType.length > 0 &&
    isNaN(Number(observedType))
  ) {
    return true;
  }

  return isValidObservedType;
};

const validateEquipment = (equipment: string) =>
  validateText(equipment, 0, 100);
const IncidentCleanedReport = ({
  show,
  contractIncidentId,
  contractId,
  isCleaned,
  onClose,
  selectedIncident,
}: IProps) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [addEmployees, setAddEmployees] = useState(false);
  const [addDepartments, setAddDepartments] = useState(false);
  const [addEquipment, setAddEquipment] = useState(false);
  const [addMethodology, setAddMethodology] = useState(false);
  const [showFormInfo, setShowFormInfo] = useState(false);
  const [userAnswers, setUserAnswers] = useState<IUserAnswer[]>([]);
  const [selectedMethodologies, setSelectedMethodologies] = useState<string[]>(
    []
  );
  const [employees, setEmployees] = useState<IVisitor[]>([]);
  const [involvedDepartments, setInvolvedDepartments] = useState<item[]>([]);
  const [utilizedEquipments, setUtilizedEquipments] = useState<item[]>([]);

  const [editCleanedAt, setEditCleanedAt] = useState(false);
  const [cleanedAt, setCleanedAt] = useState('');

  // const [editNumberOfWorkers, setEditNumberOfWorkers] = useState(false);
  const [numberOfWorkers, setNumberOfWorkers] = useState(0);

  // const [editExactLocation, setEditExactLocation] = useState(false);
  const [exactLocation, setExactLocation] = useState('');

  // const [editEstimateCost, setEditEstimateCost] = useState(false);
  const [costEstimate, setCostEstimate] = useState(0);

  const [editObservedType, setEditObservedType] = useState(false);

  const {
    data: settings,
    isError: isSettingsError,
    isLoading: isLoadingSettings,
  } = useGetPageSettingsQuery(Settings.IncidentDashboardCleanedForm, {
    skip: !show || !shouldFetch,
  });

  const { userAuthorizations } = useSelector(selectAllAuthValues);
  let content: React.ReactNode = null;
  let hasAuthToActions = false;
  let hasEdited = false;

  let hasCleanupDurationField = false;
  let hasAddEmployeesField = false;
  let hasAddInvolvedDepartmentsField = false;
  let hasAddUtilizedEquipmentsField = false;
  let hasLessonsLearnedField = false;
  let hasCollaborationDetailsField = false;
  let hasAdditionalNotesField = false;
  let hasCleanupMethodologyField = false;
  let hasEnvironmentalImpactAssessmentField = false;

  const {
    isLoading: isLoadingCleanedReport,
    isError: isCleanedReportError,

    data: cleanedReport,
  } = useGetIncidentCleanedReportQuery(
    { contractId, contractIncidentId },
    { skip: !contractId || !contractIncidentId || !isCleaned || !shouldFetch }
  );

  const doc = (
    <CleanedReportPDF
      show={show}
      cleanReport={cleanedReport}
      selectedIncident={selectedIncident}
    />
  );

  const [instance, update] = usePDF({ document: doc });
  let pdfDoc: React.ReactNode | null = null;

  const {
    isLoading: isLoadingQuestions,
    isError: isQuestionsFetchError,
    data: questions,
  } = useGetCleanReportQuestionsQuery('', { skip: !shouldFetch });
  let environmentalAssessmentContent: React.ReactNode | null = null;

  const [updateReport, { isLoading: isUpdateLoading, reset }] =
    useUpdateIncidentCleanedReportMutation();

  const {
    value: name,
    isValid: isValidName,
    entryHandler: nameEntryHandler,
    clearEntry: clearName,
  } = useFormEntry(validateNameSurname, 'employee-name', '', true);

  const {
    value: surname,
    isValid: isValidSurname,
    entryHandler: surnameEntryHandler,
    clearEntry: clearSurname,
  } = useFormEntry(validateNameSurname, 'employee-surname', '', true);

  const {
    value: department,
    isValid: isValidEmployeeDepartment,
    entryHandler: departmentEntryHandler,
    clearEntry: clearDepartment,
  } = useFormEntry(validateEnteredText, 'employee-department', '', true);

  const {
    value: duty,
    isValid: isValidDuty,
    entryHandler: dutyEntryHandler,
    clearEntry: clearDuty,
  } = useFormEntry(validateEnteredText, 'employee-duty', '', true);

  const {
    value: involvedDepartment,
    entryHandler: involvedDepartmentEntry,
    isValid: isValidDepartment,
    clearEntry: clearDepartmentEntry,
  } = useFormEntry(validateDepartment, 'clean-department', '', true);

  const {
    value: involvedDepartmentWorkers,
    entryHandler: involvedDepartmentWorkerNumberEntry,
    clearEntry: clearInvolvedDepartmentWorkers,
  } = useFormEntry(validateNumberOfWorkers, 'department-workers', '');

  const {
    value: utilizedEquipmentNumber,
    entryHandler: utilizedEquipmentNumberEntry,
    clearEntry: clearUtilizedEquipmentNumber,
  } = useFormEntry(validateNumberOfWorkers, 'department-workers', '');

  const {
    value: observedType,
    isValid: isValidObservedType,
    entryHandler: observedTypeEntryHandler,
    clearEntry: clearObservedType,
    setter: addObserveredType,
  } = useFormEntry(validateEnteredText, 'clean-observed-type', '', true);

  const {
    value: utilizedEquipment,
    entryHandler: utilizedEqupimentEntry,
    isValid: isValidEquipment,
    clearEntry: clearEquipmentEntry,
  } = useFormEntry(validateEquipment, 'clean-equipment', '', true);

  const hasEmployees =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'employees' in cleanedReport &&
    cleanedReport.employees &&
    Array.isArray(cleanedReport.employees) &&
    cleanedReport.employees.length > 0;

  const hasDepartments =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'involvedDepartments' in cleanedReport &&
    cleanedReport.involvedDepartments &&
    Array.isArray(cleanedReport.involvedDepartments) &&
    cleanedReport.involvedDepartments.length > 0;

  const hasEquipments =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'utilizedEquipments' in cleanedReport &&
    cleanedReport.utilizedEquipments &&
    Array.isArray(cleanedReport.utilizedEquipments) &&
    cleanedReport.utilizedEquipments.length > 0;

  const hasMethodologies =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'selectedMethodologies' in cleanedReport &&
    cleanedReport.selectedMethodologies &&
    Array.isArray(cleanedReport.selectedMethodologies) &&
    cleanedReport.selectedMethodologies.length > 0;

  const existingLessonsLearned =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'lessonsLearned' in cleanedReport &&
    cleanedReport.lessonsLearned &&
    typeof cleanedReport.lessonsLearned === 'string'
      ? cleanedReport.lessonsLearned
      : '';

  const existingCollaborationDetails =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'collaborationDetails' in cleanedReport &&
    cleanedReport.collaborationDetails &&
    typeof cleanedReport.collaborationDetails === 'string'
      ? cleanedReport.collaborationDetails
      : '';

  const existingAdditionalNotes =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'additionalNotes' in cleanedReport &&
    cleanedReport.additionalNotes &&
    typeof cleanedReport.additionalNotes === 'string'
      ? cleanedReport.additionalNotes
      : '';

  const hasUserAnswers =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'environmentalImpactAssessment' in cleanedReport &&
    cleanedReport.environmentalImpactAssessment &&
    Array.isArray(cleanedReport.environmentalImpactAssessment) &&
    cleanedReport.environmentalImpactAssessment.length > 0;

  const hasCleanedAtDate =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'cleanDate' in cleanedReport &&
    cleanedReport.cleanDate;

  const hasDeployedWorkers =
    cleanedReport !== undefined &&
    typeof cleanedReport == 'object' &&
    'numberOfWorkers' in cleanedReport &&
    cleanedReport.numberOfWorkers;

  const hasEstimateCost =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'costEstimate' in cleanedReport &&
    cleanedReport.costEstimate;

  const hasExactLocation =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'exactLocation' in cleanedReport &&
    cleanedReport.exactLocation;

  const hasObservedType =
    cleanedReport !== undefined &&
    typeof cleanedReport === 'object' &&
    'observedType' in cleanedReport &&
    cleanedReport.observedType;

  const {
    edit: editLessonsLearned,
    undoEditedHandler: undoEditedLessonsLearnedHandler,
    willUpdateHandler: updateLessonsLearnedHandler,
    updated: updatedLessonsLearned,
    closeUpdateHandler: closeUpdateLessonsLearnedHandler,
    addOrChangeNoteHandler: addOrChangeLessonsLearnedHandler,
  } = useUpdateNote({
    existingNote: existingLessonsLearned,
    isLoading: isUpdateLoading,
  });

  const {
    edit: editCollaborationDetails,
    undoEditedHandler: undoEditedCollaborationDetailsHandler,
    willUpdateHandler: updateCollaborationDetailsHandler,
    updated: updatedCollaborationDetails,
    closeUpdateHandler: closeUpdateCollaborationDetailsHandler,
    addOrChangeNoteHandler: addOrChangeCollaborationDetailsHandler,
  } = useUpdateNote({
    existingNote: existingCollaborationDetails,
    isLoading: isUpdateLoading,
  });

  const {
    edit: editAdditionalNotes,
    undoEditedHandler: undoEditedAdditionalNotesHandler,
    willUpdateHandler: updateAdditionalNotesHandler,
    updated: updatedAdditionalNotes,
    closeUpdateHandler: closeUpdateAdditionalNotesHandler,
    addOrChangeNoteHandler: addOrChangeAdditionalNotesHandler,
  } = useUpdateNote({
    existingNote: existingAdditionalNotes,
    isLoading: isUpdateLoading,
  });

  useEffect(() => {
    if (isCleaned && shouldFetch && show) {
      update(doc);
    }

    // eslint-disable-next-line
  }, [shouldFetch, isCleaned, show]);

  useEffect(() => {
    if (isCleaned && contractId && contractIncidentId && !shouldFetch) {
      const timer = setTimeout(() => {
        setShouldFetch(true);
      }, 600);

      return () => clearTimeout(timer);
    }
  }, [isCleaned, contractId, contractIncidentId, shouldFetch]);

  useEffect(() => {
    if (hasEmployees) {
      setEmployees(cleanedReport.employees);
    }
    // eslint-disable-next-line
  }, [hasEmployees]);

  useEffect(() => {
    if (hasDepartments) {
      setInvolvedDepartments(cleanedReport.involvedDepartments);
    }
    // eslint-disable-next-line
  }, [hasDepartments]);

  useEffect(() => {
    if (hasEquipments) {
      setUtilizedEquipments(cleanedReport.utilizedEquipments);
    }
    // eslint-disable-next-line
  }, [hasEquipments]);

  useEffect(() => {
    if (hasMethodologies) {
      setSelectedMethodologies(cleanedReport.selectedMethodologies);
    }

    // eslint-disable-next-line
  }, [hasMethodologies]);

  useEffect(() => {
    if (hasUserAnswers) {
      setUserAnswers(cleanedReport.environmentalImpactAssessment);
    }
    // eslint-disable-next-line
  }, [hasUserAnswers]);

  useEffect(() => {
    if (hasCleanedAtDate) {
      setCleanedAt(cleanedReport.cleanDate);
    }
    // eslint-disable-next-line
  }, [hasCleanedAtDate]);

  useEffect(() => {
    if (hasDeployedWorkers) {
      setNumberOfWorkers(cleanedReport.numberOfWorkers);
    }

    // eslint-disable-next-line
  }, [hasDeployedWorkers]);

  useEffect(() => {
    if (hasEstimateCost) {
      setCostEstimate(cleanedReport.costEstimate);
    }

    // eslint-disable-next-line
  }, [hasEstimateCost]);

  useEffect(() => {
    if (hasExactLocation) {
      setExactLocation(cleanedReport.exactLocation);
    }
    // eslint-disable-next-line
  }, [hasExactLocation]);

  useEffect(() => {
    if (hasObservedType) {
      addObserveredType(cleanedReport.observedType);
    }

    // eslint-disable-next-line
  }, [hasObservedType]);

  if (isLoadingCleanedReport) {
    content = (
      <FetchingReports message='Fetching cleaned report of incident. Please wait...' />
    );
  }

  if (isCleanedReportError) {
    content = (
      <NoDataFound message='We apologize, but an unexpected error occurred while retrieving the cleaned report for this incident. Kindly attempt the process again later. We appreciate your understanding.' />
    );
  }

  if (
    settings &&
    typeof settings === 'object' &&
    'hasCleanupDurationField' in settings &&
    'hasAddEmployeesField' in settings &&
    'hasAddInvolvedDepartmentsField' in settings &&
    'hasAddUtilizedEquipmentsField' in settings &&
    'hasLessonsLearnedField' in settings &&
    'hasCollaborationDetailsField' in settings &&
    'hasAdditionalNotesField' in settings &&
    'hasCleanupMethodologyField' in settings &&
    'hasEnvironmentalImpactAssessmentField' in settings
  ) {
    hasCleanupDurationField = settings.hasCleanupDurationField;
    hasAddEmployeesField = settings.hasAddEmployeesField;
    hasAddInvolvedDepartmentsField = settings.hasAddInvolvedDepartmentsField;
    hasAddUtilizedEquipmentsField = settings.hasAddUtilizedEquipmentsField;
    hasLessonsLearnedField = settings.hasLessonsLearnedField;
    hasCollaborationDetailsField = settings.hasCollaborationDetailsField;
    hasAdditionalNotesField = settings.hasAdditionalNotesField;
    hasCleanupMethodologyField = settings.hasCleanupMethodologyField;
    hasEnvironmentalImpactAssessmentField =
      settings.hasEnvironmentalImpactAssessmentField;
  }

  if (
    userAuthorizations &&
    typeof userAuthorizations === 'object' &&
    'hasAuthToActions' in userAuthorizations &&
    'hasAuthtoAdmin' in userAuthorizations
  ) {
    hasAuthToActions = userAuthorizations.hasAuthToActions;
  }

  if (cleanedReport) {
    const updatedAnswers =
      userAnswers.length > 0 ? userAnswers.map((ans) => ans.answerId) : [];
    const storedAnswers = cleanedReport
      ? cleanedReport.environmentalImpactAssessment.map((ans) => ans.answerId)
      : [];

    if (
      employees.length !== cleanedReport.employees.length ||
      involvedDepartments.length !== cleanedReport.involvedDepartments.length ||
      utilizedEquipments.length !== cleanedReport.utilizedEquipments.length ||
      updatedLessonsLearned !== cleanedReport.lessonsLearned ||
      updatedCollaborationDetails !== cleanedReport.collaborationDetails ||
      updatedAdditionalNotes !== cleanedReport.additionalNotes ||
      !arraysAreEqual(
        selectedMethodologies,
        cleanedReport.selectedMethodologies
      ) ||
      !arraysAreEqual(updatedAnswers, storedAnswers) ||
      cleanedAt !== cleanedReport.cleanDate ||
      numberOfWorkers !== cleanedReport.numberOfWorkers ||
      costEstimate !== cleanedReport.costEstimate ||
      exactLocation !== cleanedReport.exactLocation ||
      observedType !== cleanedReport.observedType
    ) {
      hasEdited = true;
    } else {
      hasEdited = false;
    }
  }

  if (instance && instance.error) {
    pdfDoc = <PDFError />;
  }

  if (instance && instance.url && cleanedReport) {
    pdfDoc = (
      <DownloadButton
        url={instance.url}
        fileName={`Periopsis_${
          cleanedReport.detectedAt
        }_clean_${new Date().getTime()}.pdf`}
        title='Download Cleaned Report'
        label='Cleaned Report'
      />
    );
  }

  const resetState = () => {
    setShouldFetch(false);
    setAddEmployees(false);
    setAddDepartments(false);
    setAddEquipment(false);
    setAddMethodology(false);
    setEditObservedType(false);
    setEditCleanedAt(false);
    // setEditNumberOfWorkers(false);
    // setEditExactLocation(false);
    // setEditEstimateCost(false);
    setShowFormInfo(false);
  };

  const showAddEmployeesFormHandler = () => {
    if (isUpdateLoading) {
      return;
    }

    setAddEmployees((prevState) => !prevState);
  };

  const showAddDepartmentsFromHandler = () => {
    if (isUpdateLoading) {
      return;
    }

    setAddDepartments((prevState) => !prevState);
  };

  const showAddEquipmentsFormHandler = () => {
    if (isUpdateLoading) {
      return;
    }
    setAddEquipment((prevState) => !prevState);
  };

  const showMethodologiesSelectionList = () => {
    if (isUpdateLoading) {
      return;
    }

    setAddMethodology((prevState) => !prevState);
  };

  const resetEmployeeForm = () => {
    clearName();
    clearSurname();
    clearDepartment();
    clearDuty();
  };

  const addEmployeeHandler = () => {
    if (isUpdateLoading) {
      return;
    }

    if (!name && !surname && !department && !duty) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage="Oops! It seems like some information is missing. To continue, please provide the employee's complete details, including their first name, last name, department, and duty. "
          heading='Employee Details Error'
        />
      );
    }

    if (!name) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage="Please provide the employee's first name. Enter the name of the employee."
          heading='Name Error'
        />
      );
    }

    if (!surname) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage="Please provide the employee's last name. Enter the surname of the employee."
          heading='Surname Error'
        />
      );
    }

    if (!department) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage="Please specify the employee's department. Enter the department information for the employee."
          heading='Department Error'
        />
      );
    }

    if (!duty) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage="Kindly provide the employee's role or purpose. Enter the duty or role of the employee."
          heading='Duty Error'
        />
      );
    }

    if (numberOfWorkers && employees.length === Number(numberOfWorkers)) {
      return toast.error(
        <ToastifyStyledMessage
          heading='Limit Reached'
          singleMessage={`In the "Number of Workers" field, it has been indicated that ${numberOfWorkers} workers were deployed for the cleanup process, and it seems you've reached this amount in the list. If the actual number of workers deployed exceeds ${numberOfWorkers}, please make sure to correct the value in the "Number of Workers Deployed" field as well. `}
        />
      );
    }

    if (name && surname && department && duty) {
      const existingVisitor = employees.find(
        (employee) =>
          employee.name.toLowerCase() === name.toLowerCase() &&
          employee.surname.toLowerCase() === surname.toLowerCase()
      );

      if (existingVisitor) {
        return confirmAlert({
          title: 'Existing Visitor',
          message: `Currently, the list contains a visitor with a name of ${existingVisitor.name} ${existingVisitor.surname}. Would you like to continue?`,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                const newEmployee = {
                  name,
                  surname,
                  department,
                  duty,
                  id: nanoid(),
                };
                const storedData = [newEmployee, ...employees];
                localStorage.setItem(
                  'employees-list',
                  JSON.stringify(storedData)
                );
                setEmployees((prevState) => [newEmployee, ...prevState]);
                resetEmployeeForm();
              },
            },
            {
              label: 'No',
              onClick: () => resetEmployeeForm(),
            },
          ],
        });
      }

      const newEmployee = { name, surname, department, duty, id: nanoid() };
      const storedData = [newEmployee, ...employees];
      localStorage.setItem('employees-list', JSON.stringify(storedData));
      setEmployees((prevState) => [newEmployee, ...prevState]);
      resetEmployeeForm();
    }
  };

  const removeEmployeeHandler = (id: string) => {
    if (isUpdateLoading) {
      return;
    }

    const newList = employees.filter((employee) => employee.id !== id);

    setEmployees(newList);
  };

  const addInvolvedDepartmentHandler = (department: item) => {
    if (isUpdateLoading) {
      return;
    }

    //check if it is an existing department
    let isSameDepartment = false;

    if (involvedDepartments.length === 50) {
      return toast.error(
        <ToastifyStyledMessage
          heading='Limit Reached'
          singleMessage={`The maximum allowable number of involved departments is 50. The current list already includes 50 departments, so ${department.name} cannot be added to the list. `}
        />
      );
    }

    if (isSimilarEntry(department.name, involvedDepartments, 80)) {
      isSameDepartment = true;
    }

    if (isSameDepartment) {
      return confirmAlert({
        title: 'Similar Department',
        message:
          'A similar or same department exist in the involved departments list, would you like to add this department to the list?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setInvolvedDepartments((prevState) => [department, ...prevState]);
              clearDepartmentEntry();
            },
          },
          {
            label: 'No',
            onClick: () => clearDepartmentEntry(),
          },
        ],
      });
    }

    setInvolvedDepartments((prevState) => [department, ...prevState]);
    clearDepartmentEntry();
    clearInvolvedDepartmentWorkers();
  };

  const addUtilizedEquipmentHandler = (equipment: item) => {
    if (isUpdateLoading) {
      return;
    }

    let isSameEquipment = false;

    if (utilizedEquipments.length === 100) {
      return toast.error(
        <ToastifyStyledMessage
          heading='Limit Reached'
          singleMessage={`The maximum allowable number of utilized equipments is 100. The current list already includes 100 equipments, so ${equipment.name} cannot be added to the list.`}
        />
      );
    }

    if (isSimilarEntry(equipment.name, utilizedEquipments, 80)) {
      isSameEquipment = true;
    }

    if (isSameEquipment) {
      return confirmAlert({
        title: 'Similar Equipment',
        message:
          'A similar or same equipment exist in the utilized equipments list, would you like to add this equipment to the list?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              setUtilizedEquipments((prevState) => [equipment, ...prevState]);
              clearEquipmentEntry();
            },
          },
          {
            label: 'No',
            onClick: () => clearEquipmentEntry(),
          },
        ],
      });
    }

    setUtilizedEquipments((prevState) => [equipment, ...prevState]);
    clearEquipmentEntry();
    clearUtilizedEquipmentNumber();
  };

  const removeInvolvedDepartmentFromList = (id: string) => {
    if (isUpdateLoading) {
      return;
    }

    const updatedDepartmentsList = involvedDepartments.filter(
      (dept) => dept._id !== id
    );
    setInvolvedDepartments(updatedDepartmentsList);
  };

  const removeUtilizedEquipmentFromList = (id: string) => {
    if (isUpdateLoading) {
      return;
    }

    const updatedList = utilizedEquipments.filter((equip) => equip._id !== id);

    setUtilizedEquipments(updatedList);
  };

  const clearForm = () => {
    resetEmployeeForm();
    setAddEmployees(false);
    setAddDepartments(false);
    setAddEquipment(false);
    setShowFormInfo(false);
    setAddMethodology(false);
    setEditObservedType(false);
    setEditCleanedAt(false);
    // setEditNumberOfWorkers(false);
    // setEditExactLocation(false);
    // setEditEstimateCost(false);
    clearName();
    clearSurname();
    clearDepartment();
    clearDuty();
    clearDepartmentEntry();
    clearInvolvedDepartmentWorkers();
    clearUtilizedEquipmentNumber();
    clearEquipmentEntry();
    clearObservedType();

    if (
      cleanedReport &&
      typeof cleanedReport === 'object' &&
      'employees' in cleanedReport &&
      'involvedDepartments' in cleanedReport
    ) {
      setEmployees(cleanedReport.employees);
      setInvolvedDepartments(cleanedReport.involvedDepartments);
      setUtilizedEquipments(cleanedReport.utilizedEquipments);
      setSelectedMethodologies(cleanedReport.selectedMethodologies);
      setUserAnswers(cleanedReport.environmentalImpactAssessment);
      setCleanedAt(cleanedReport.cleanDate);
      setNumberOfWorkers(cleanedReport.numberOfWorkers);
      setCostEstimate(cleanedReport.costEstimate);
      setExactLocation(cleanedReport.exactLocation);
      addObserveredType(cleanedReport.observedType);
    }
  };

  const closeFormHandler = () => {
    if (hasEdited) {
      return confirmAlert({
        title: 'Closing Window Alert',
        message:
          'Closing the window will discard any unsaved updates. If you intend to apply changes, please finalize the update process before closing the window; otherwise, any modifications will be lost. Are you sure you want to close the window?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              clearForm();
              onClose();
            },
          },
          {
            label: 'No',
          },
        ],
      });
    }

    clearForm();
    onClose();
  };

  const selectCleanupMethodologyHandler = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const selected = e.target.value;

    if (selectedMethodologies.includes(selected)) {
      const updated = selectedMethodologies.filter(
        (methodology) => methodology.toLowerCase() !== selected.toLowerCase()
      );
      setSelectedMethodologies(updated);
    } else {
      setSelectedMethodologies((prevState) => [...prevState, selected]);
    }
  };

  const removeMethodology = (removed: string) => {
    setSelectedMethodologies((prevState) => {
      return prevState.filter(
        (methodology) => methodology.toLowerCase() !== removed.toLowerCase()
      );
    });
  };

  const assessmentAnswersHandler = (answer: IUserAnswer) => {
    if (!hasAuthToActions) {
      return;
    }

    const isExistingAnswer = userAnswers.find((ans) => ans.id === answer.id);

    if (isExistingAnswer) {
      //This means user changed the answer of the question

      setUserAnswers((prevState) => {
        const otherAnswers = prevState.filter((ans) => ans.id !== answer.id);
        return [...otherAnswers, answer];
      });
    } else {
      setUserAnswers((prevState) => [...prevState, answer]);
    }
  };

  const resetCleanedReportChangesHandler = () => {
    clearForm();
    return toast.success(
      <ToastifyStyledMessage
        heading='Reset'
        singleMessage='Cleaned Report successfully reset to its initial state.'
      />
    );
  };

  const willEditCleanedAtHandler = () => {
    if (cleanedReport) {
      const isValidDate = validateDate(cleanedAt, cleanedReport.cleanDate);

      if (!isValidDate) {
        setCleanedAt(cleanedReport.cleanDate);
      }
    }

    setEditCleanedAt((prevState) => !prevState);
  };

  const editCleanedAtHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCleanedAt(e.target.value);
  };

  const undoCleanedAtHandler = () => {
    if (cleanedReport) {
      setCleanedAt(cleanedReport.cleanDate);
    }
  };

  // const willEditNumberOfWorkersHandler = () => {
  //   if (cleanedReport) {
  //     if (numberOfWorkers > 1000 || numberOfWorkers < 0) {
  //       setNumberOfWorkers(cleanedReport.numberOfWorkers);
  //     }
  //   }

  //   setEditNumberOfWorkers((prevState) => !prevState);
  // };

  // const editNumberOfWorkersHandler = (e: ChangeEvent<HTMLInputElement>) => {
  //   setNumberOfWorkers(Number(e.target.value));
  // };

  // const undoNumberOfWorkers = () => {
  //   if (cleanedReport) {
  //     setNumberOfWorkers(cleanedReport.numberOfWorkers);
  //   }
  // };

  // const willEditEstimateCost = () => {
  //   if (cleanedReport) {
  //     if (costEstimate < 0 || costEstimate > 999999999) {
  //       setCostEstimate(cleanedReport.costEstimate);
  //     }
  //   }

  //   setEditEstimateCost((prevState) => !prevState);
  // };

  // const editEstimateCostHandler = (e: ChangeEvent<HTMLInputElement>) => {
  //   setCostEstimate(Number(e.target.value));
  // };

  // const undoEstimateCost = () => {
  //   if (cleanedReport) {
  //     setCostEstimate(cleanedReport.costEstimate);
  //   }
  // };

  // const willEditExactLocation = () => {
  //   if (cleanedReport) {
  //     if (!validateExactLocation(exactLocation)) {
  //       setExactLocation(cleanedReport.exactLocation);
  //     }
  //   }

  //   setEditExactLocation((prevState) => !prevState);
  // };

  // const editExactLocationHandler = (e: ChangeEvent<HTMLInputElement>) => {
  //   setExactLocation(e.target.value);
  // };

  // const undoExactLocationHandler = () => {
  //   if (cleanedReport) {
  //     setExactLocation(cleanedReport.exactLocation);
  //   }
  // };

  const willEditObservedType = () => {
    let isValidObservedType = validateObservedType(observedType);

    if (!isValidObservedType && cleanedReport) {
      addObserveredType(cleanedReport.observedType);
    }

    setEditObservedType((prevState) => !prevState);
  };

  const undoObservedTypeHandler = () => {
    if (cleanedReport) {
      addObserveredType(cleanedReport.observedType);
    }
  };

  const showCleanReportInformationHandler = () => {
    setShowFormInfo((prevState) => !prevState);
  };

  const isFakeAddButton =
    !isValidName ||
    !isValidSurname ||
    !isValidDuty ||
    !isValidDepartment ||
    name === '' ||
    surname === '' ||
    duty === '' ||
    department === '';

  if (
    cleanedReport &&
    typeof cleanedReport === 'object' &&
    !isCleanedReportError &&
    !isLoadingCleanedReport
  ) {
    const duration =
      cleanedReport.cleanUpDays && cleanedReport.cleanUpDays > 1
        ? `${cleanedReport.cleanUpDays} days`
        : cleanedReport.cleanUpDays && cleanedReport.cleanUpDays === 1
        ? `${cleanedReport.cleanUpDays} day`
        : cleanedReport.cleanUpHours && cleanedReport.cleanUpHours > 1
        ? `${cleanedReport.cleanUpHours} hours`
        : `${cleanedReport.cleanUpHours} hour`;

    const isValidLessonsLearned = validateText(updatedLessonsLearned, 0, 1000);
    const isValidCollaborationDetails = validateText(
      updatedCollaborationDetails,
      0,
      1000
    );
    const isValidAdditionalNotes = validateText(
      updatedAdditionalNotes,
      0,
      1000
    );

    const isValidDate = validateDate(cleanedAt, cleanedReport.cleanDate);
    // const isValidNumberOfWorkers =
    //   numberOfWorkers >= 0 && numberOfWorkers <= 1000;
    // const isValidCostEstimate = costEstimate >= 0 && costEstimate <= 999999999;
    // const isValidExactLocation = validateExactLocation(exactLocation);

    let involvedDepartmentsSection = (
      <BorderedSectionContainer
        heading='Involved Departments'
        hasActionAuth={hasAuthToActions}
        onAction={showAddDepartmentsFromHandler}
        isActionStart={addDepartments}
        actionStartLabel='Add Departments'
        isLoading={isUpdateLoading}
      >
        <NoDataFound
          message={
            cleanedReport.involvedDepartments.length > 0
              ? 'All involved departments removed'
              : 'No Involved Departments added'
          }
        />
      </BorderedSectionContainer>
    );

    let involvedEmployees = (
      <BorderedSectionContainer
        heading='Involved Employees'
        actionStartLabel='Add Employees'
        onAction={showAddEmployeesFormHandler}
        isActionStart={addEmployees}
        hasActionAuth={hasAuthToActions}
        isLoading={isUpdateLoading}
      >
        <NoDataFound
          message={
            cleanedReport.employees.length > 0
              ? 'All involved employees removed'
              : 'No involved employees added'
          }
        />
      </BorderedSectionContainer>
    );

    let utilizedEquipmentsContent = (
      <BorderedSectionContainer
        heading='Utilized Equipments'
        onAction={showAddEquipmentsFormHandler}
        actionStartLabel='Add Equipments'
        hasActionAuth={hasAuthToActions}
        isActionStart={addEquipment}
        isLoading={isUpdateLoading}
      >
        <NoDataFound
          message={
            cleanedReport.utilizedEquipments.length > 0
              ? 'All utilized equipments removed'
              : 'No utilized equipment added'
          }
        />
      </BorderedSectionContainer>
    );

    let selectedMethodologiesContent = (
      <BorderedSectionContainer
        heading='Cleanup Methodology'
        actionStartLabel='Select Methodology'
        onAction={showMethodologiesSelectionList}
        hasActionAuth={hasAuthToActions}
        isActionStart={addMethodology}
        isLoading={isUpdateLoading}
      >
        <NoDataFound
          message={
            cleanedReport.selectedMethodologies.length > 0
              ? 'All methodologies removed'
              : 'No methodology selected'
          }
        />
      </BorderedSectionContainer>
    );

    if (addDepartments) {
      involvedDepartmentsSection = (
        <AddToList
          name='add-department'
          value={involvedDepartment}
          numberValue={involvedDepartmentWorkers}
          numberName='number-of-workers'
          label='Involved Department'
          numberLabel='How many workers/employees were deployed from this department?'
          valueList={involvedDepartments}
          onAdd={addInvolvedDepartmentHandler}
          onRemove={removeInvolvedDepartmentFromList}
          onEntry={involvedDepartmentEntry}
          onNumberEntry={involvedDepartmentWorkerNumberEntry}
          onAction={showAddDepartmentsFromHandler}
          isInvalid={!isValidDepartment}
          entryHeading='Add Involved Department'
          listHeading='Added Involved Departments'
          listItemSuffix='employees'
          actionStartLabel='Add Departments'
          isActionStart={addDepartments}
          hasActionAuth={hasAuthToActions}
          isLoading={isUpdateLoading}
        />
      );
    } else if (involvedDepartments.length > 0 && !addDepartments) {
      involvedDepartmentsSection = (
        <BorderedSectionContainer
          heading='List of Involved Departments'
          onAction={showAddDepartmentsFromHandler}
          isActionStart={addDepartments}
          actionStartLabel='Add Departments'
          hasActionAuth={hasAuthToActions}
          isLoading={isUpdateLoading}
        >
          <Fragment>
            <HeadingBar
              heading={'Added Departments'}
              headingSize={4}
              headingType='orange'
              icon={<BsBuildingFillDown />}
            />
            {!isUpdateLoading ? (
              <ul>
                {involvedDepartments.map((dept, index) => (
                  <ListItem
                    item={dept}
                    isAuthorized={hasAuthToActions}
                    index={index}
                    onRemove={removeInvolvedDepartmentFromList}
                    key={dept._id}
                    suffix={dept.number === 1 ? 'employee' : 'employees'}
                  />
                ))}
              </ul>
            ) : (
              <div className={classes.VisitorsListLoadingContainer}>
                <SimpleLoading />
              </div>
            )}
          </Fragment>
        </BorderedSectionContainer>
      );
    }

    if (addEmployees) {
      involvedEmployees = (
        <AddVisitorsForm
          handler={{
            nameEntryHandler,
            surnameEntryHandler,
            departmentEntryHandler,
            dutyEntryHandler,
            resetVisitorForm: resetEmployeeForm,
            addVisitorHandler: addEmployeeHandler,
            removeVisitorHandler: removeEmployeeHandler,
          }}
          validators={{
            isValidName,
            isValidSurname,
            isValidDuty,
            isValidDepartment: isValidEmployeeDepartment,
            isFakeAddButton,
          }}
          value={{ name, surname, department, duty }}
          visitors={employees}
          isLoading={isUpdateLoading}
          heading='Add Employee'
          listHeading='Added Employees'
          information={`We encourage you to recognize the contributions of employees who actively participated in the cleanup efforts for this incident. Although adding their details is optional, if you opt to include them, kindly furnish the necessary information, including their name, surname, department, and specific duties during the cleanup. You have the flexibility to acknowledge up to 1000 employees if you haven't specified a number in the "Number of Workers Deployed" field. Otherwise, the maximum number of employees you can add is limited to the value entered in the "Number of Workers Deployed" field. Your acknowledgment adds a personal touch to the cleanup efforts, recognizing the dedicated individuals who played a crucial role in the process.`}
          onAction={showAddEmployeesFormHandler}
          actionStartLabel='Add Employees'
          isActionStart={addEmployees}
          hasActionAuth={hasAuthToActions}
        />
      );
    } else if (employees.length > 0 && !addEmployees) {
      involvedEmployees = (
        <BorderedSectionContainer
          heading='List of Involved Employees'
          onAction={showAddEmployeesFormHandler}
          isActionStart={addEmployees}
          actionStartLabel='Add Employees'
          hasActionAuth={hasAuthToActions}
          isLoading={isUpdateLoading}
        >
          <div className={classes.VisitorsList}>
            <HeadingBar
              heading='Added Employees'
              headingSize={4}
              icon={<IoPerson />}
              className={classes.VisitorsHeading}
              headingType='orange'
            />
            {!isUpdateLoading ? (
              <div>
                {employees.map((employee, index) => (
                  <VisitorsListItem
                    key={employee.id}
                    visitorDetails={employee}
                    orderNumber={index + 1}
                    onRemove={removeEmployeeHandler}
                    isAuthorized={hasAuthToActions}
                  />
                ))}
              </div>
            ) : (
              <div className={classes.VisitorsListLoadingContainer}>
                <SimpleLoading />
              </div>
            )}
          </div>
        </BorderedSectionContainer>
      );
    }

    if (addEquipment) {
      utilizedEquipmentsContent = (
        <AddToList
          name='add-equipment'
          value={utilizedEquipment}
          valueList={utilizedEquipments}
          numberLabel='How many of this equipment were used?'
          numberName='number-of-equipment'
          numberValue={utilizedEquipmentNumber}
          onNumberEntry={utilizedEquipmentNumberEntry}
          label='Equipment Utilized'
          onAdd={addUtilizedEquipmentHandler}
          onRemove={removeUtilizedEquipmentFromList}
          onEntry={utilizedEqupimentEntry}
          isInvalid={!isValidEquipment}
          entryHeading='Add Utilized Equipment'
          listHeading='Added Utilized Equipments'
          hasActionAuth={hasAuthToActions}
          onAction={showAddEquipmentsFormHandler}
          actionStartLabel='Add Equipments'
          isActionStart={addEquipment}
          isLoading={isUpdateLoading}
        />
      );
    } else if (utilizedEquipments.length > 0 && !addEquipment) {
      utilizedEquipmentsContent = (
        <BorderedSectionContainer
          heading='List of Utilized Equipments'
          hasActionAuth={hasAuthToActions}
          onAction={showAddEquipmentsFormHandler}
          actionStartLabel='Add Equipments'
          isActionStart={addEquipment}
          isLoading={isUpdateLoading}
        >
          <Fragment>
            <HeadingBar
              heading={'Added Equipments'}
              headingSize={4}
              headingType='orange'
              icon={<BsBuildingFillDown />}
            />
            {!isUpdateLoading ? (
              <ul>
                {utilizedEquipments.map((equip, index) => (
                  <ListItem
                    item={equip}
                    isAuthorized={hasAuthToActions}
                    index={index}
                    onRemove={removeUtilizedEquipmentFromList}
                    key={equip._id}
                  />
                ))}
              </ul>
            ) : (
              <div className={classes.VisitorsListLoadingContainer}>
                <SimpleLoading />
              </div>
            )}
          </Fragment>
        </BorderedSectionContainer>
      );
    }

    if (addMethodology) {
      selectedMethodologiesContent = (
        <BorderedSectionContainer
          heading='Cleanup Methodology'
          information='Please select the specific method or methods employed during the
    cleaning operation, providing insights into the approach taken to
    address the incident.'
          actionStartLabel='Select Methodology'
          onAction={showMethodologiesSelectionList}
          hasActionAuth={hasAuthToActions}
          isActionStart={addMethodology}
          isLoading={isUpdateLoading}
        >
          <CleanupMethodology
            onChange={selectCleanupMethodologyHandler}
            selected={selectedMethodologies}
          />
        </BorderedSectionContainer>
      );
    } else if (selectedMethodologies.length > 0 && !addMethodology) {
      selectedMethodologiesContent = (
        <BorderedSectionContainer
          heading='Cleanup Methodology'
          actionStartLabel='Select Methodology'
          onAction={showMethodologiesSelectionList}
          hasActionAuth={hasAuthToActions}
          isActionStart={addMethodology}
          isLoading={isUpdateLoading}
        >
          <Fragment>
            <HeadingBar
              heading={
                selectedMethodologies.length > 1
                  ? 'Selected Methodologies'
                  : 'Selected Methodology'
              }
              headingSize={4}
              headingType='orange'
              icon={<SiReactos />}
            />
            {!isUpdateLoading ? (
              <ul>
                {selectedMethodologies.map((methodology, index) => (
                  <ListItem
                    index={index}
                    item={{ _id: methodology, name: methodology }}
                    isAuthorized={hasAuthToActions}
                    onRemove={() => removeMethodology(methodology)}
                    key={methodology}
                  />
                ))}
              </ul>
            ) : (
              <div className={classes.VisitorsListLoadingContainer}>
                <SimpleLoading />
              </div>
            )}
          </Fragment>
        </BorderedSectionContainer>
      );
    }

    if (
      !isLoadingQuestions &&
      questions &&
      Array.isArray(questions) &&
      questions.length > 0
    ) {
      environmentalAssessmentContent = questions.map((question, index) => {
        let selectedAnswer = '';
        const questionAnswer = userAnswers.find(
          (ans) => ans.id === question.id
        );

        if (questionAnswer) {
          selectedAnswer = questionAnswer.answerId;
        }

        return (
          <QuestionItem
            key={question.id}
            question={question}
            index={index}
            onQuestionAnswer={assessmentAnswersHandler}
            selectedAnswerId={selectedAnswer}
            hasAuthToActions={hasAuthToActions}
          />
        );
      });
    } else {
      environmentalAssessmentContent = (
        <NoDataFound message='No Questions found for Environmental Assessment' />
      );
    }

    if (isQuestionsFetchError) {
      environmentalAssessmentContent = (
        <NoDataFound message='An error occured while fetching the environmental assessment impact questions.' />
      );
    }

    const closeIconSize = '1.08rem';

    content = (
      <ReportContainer>
        {pdfDoc}
        <IncidentDetailsSection
          selectedIncident={selectedIncident}
          information='
          In the presentation view, we are pleased to showcase a detailed summary of the cleaning procedures implemented in response to the identified incident. This comprehensive report includes vital information, such as the date of the cleaning operation, the collaborating departments, the equipment employed, the workforce deployed, and the timeframe dedicated to restoring the affected area. Moreover, the estimated cost of the cleaning operation is highlighted, providing valuable insights into the financial aspects of the cleanup process.'
          isLoading={isUpdateLoading}
        />
        <BorderedSectionContainer heading='General Details'>
          <EditableRowData
            label='Report Created At'
            rowData={`${new Date(cleanedReport.createdAt).toLocaleDateString(
              'en',
              { year: 'numeric', month: 'long', day: '2-digit' }
            )}   (${formatDistance(
              new Date(cleanedReport.createdAt),
              new Date(),
              { addSuffix: true }
            )})`}
            rowIcon={<CiCalendarDate />}
          />
          <EditableRowData
            label='Report Created By'
            rowData={cleanedReport.createdBy.userName}
            rowIcon={<FaUserEdit />}
          />
          <EditableRowData
            label='Cleaned At'
            rowData={
              editCleanedAt
                ? cleanedAt
                : new Date(cleanedAt).toLocaleString('en', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  })
            }
            rowIcon={<CiCalendarDate />}
            isEditable={hasAuthToActions && !isUpdateLoading}
            edit={editCleanedAt}
            onClick={willEditCleanedAtHandler}
            type='date'
            closeIcon={
              <IoClose
                style={{ cursor: 'pointer' }}
                onClick={willEditCleanedAtHandler}
                size={closeIconSize}
              />
            }
            onChange={editCleanedAtHandler}
            onUndo={undoCleanedAtHandler}
            invalid={!isValidDate}
            invalidMessage='The entered date is not valid'
          />
          {/* <EditableRowData
            label='Number of Workers Deployed'
            rowData={
              numberOfWorkers || editNumberOfWorkers
                ? numberOfWorkers
                : 'No information has been provided.'
            }
            rowIcon={<FaPersonDigging />}
            isEditable={hasAuthToActions && !isUpdateLoading}
            edit={editNumberOfWorkers}
            onClick={willEditNumberOfWorkersHandler}
            closeIcon={
              <IoClose
                style={{ cursor: 'pointer' }}
                onClick={willEditNumberOfWorkersHandler}
                size={closeIconSize}
              />
            }
            type='number'
            onChange={editNumberOfWorkersHandler}
            onUndo={undoNumberOfWorkers}
            invalid={!isValidNumberOfWorkers}
            invalidMessage={
              numberOfWorkers < 0
                ? 'Negative value is not allowed'
                : 'Number of workers cannot exceed 1000'
            }
          /> */}
          {/* <EditableRowData
            label='Estimated Cost of Operation'
            rowData={
              editEstimateCost
                ? costEstimate
                : costEstimate && !editEstimateCost
                ? `€${costEstimate}`
                : 'No information has been provided.'
            }
            rowIcon={<AiFillEuroCircle />}
            isEditable={hasAuthToActions && !isUpdateLoading}
            closeIcon={
              <IoClose
                style={{ cursor: 'pointer' }}
                onClick={willEditEstimateCost}
                size={closeIconSize}
              />
            }
            edit={editEstimateCost}
            onClick={willEditEstimateCost}
            onChange={editEstimateCostHandler}
            onUndo={undoEstimateCost}
            type='number'
            invalid={!isValidCostEstimate}
            invalidMessage={
              costEstimate < 0
                ? 'Cost estimates must be non-negative; negative values are not permitted.'
                : 'The entered cost estimate is invalid. Kindly review and verify the provided value.'
            }
          /> */}
          {!editObservedType ? (
            <EditableRowData
              label='Observed Incident Type'
              rowData={
                observedType
                  ? observedType
                  : 'No information has been provided.'
              }
              rowIcon={<PiDetectiveDuotone />}
              isEditable={hasAuthToActions && !isUpdateLoading}
              onClick={willEditObservedType}
              edit={editObservedType}
            />
          ) : (
            <div className={classes.ObservedTypeField}>
              <ObservedIncidentType
                value={observedType}
                onChange={observedTypeEntryHandler}
                onSelect={addObserveredType}
                isValid={isValidObservedType}
              />
              <FaUndo
                style={{ cursor: 'pointer' }}
                onClick={undoObservedTypeHandler}
              />
              <IoClose
                style={{ cursor: 'pointer' }}
                onClick={willEditObservedType}
                size={closeIconSize}
              />
            </div>
          )}
          {hasCleanupDurationField ? (
            <EditableRowData
              label='Cleanup Duration'
              rowData={duration}
              rowIcon={<GoStopwatch />}
            />
          ) : null}
          {/* <EditableRowData
            label='Exact Location'
            rowData={
              exactLocation || editExactLocation
                ? exactLocation
                : 'No information has been provided'
            }
            rowIcon={<IoLocationSharp />}
            isEditable={hasAuthToActions && !isUpdateLoading}
            edit={editExactLocation}
            onClick={willEditExactLocation}
            closeIcon={
              <IoClose
                style={{ cursor: 'pointer' }}
                onClick={willEditExactLocation}
                size={closeIconSize}
              />
            }
            onChange={editExactLocationHandler}
            onUndo={undoExactLocationHandler}
            invalid={!isValidExactLocation}
            invalidMessage='Location names must not exceed 120 characters in length.'
          /> */}
        </BorderedSectionContainer>
        {hasAddEmployeesField ? involvedEmployees : null}
        {hasAddInvolvedDepartmentsField ? involvedDepartmentsSection : null}
        {hasAddUtilizedEquipmentsField ? utilizedEquipmentsContent : null}
        {hasLessonsLearnedField ? (
          <BorderedSectionContainer heading='Lessons Learned'>
            <NoteContainer
              willEditNotes={editLessonsLearned}
              hasAuthToActions={hasAuthToActions}
              onUpdateNotes={updateLessonsLearnedHandler}
              onCloseUpdateNotes={closeUpdateLessonsLearnedHandler}
              onUndoEdit={undoEditedLessonsLearnedHandler}
              updatedNotes={
                updatedLessonsLearned || cleanedReport.lessonsLearned
              }
            >
              <TextArea
                name='lessons-learned'
                label='Lessons Learned'
                maxLength={1000}
                showRemaining
                value={updatedLessonsLearned}
                onChange={addOrChangeLessonsLearnedHandler}
                title='Add or Updated Lessons Learned'
                errorMessage='Please review the entered text to ensure it complies with the 1000-character limit and does not contain consecutive repeating characters.'
                invalid={!isValidLessonsLearned}
              />
            </NoteContainer>
          </BorderedSectionContainer>
        ) : null}
        {hasCollaborationDetailsField ? (
          <BorderedSectionContainer heading='Collaboration Details'>
            <NoteContainer
              updatedNotes={
                updatedCollaborationDetails ||
                cleanedReport.collaborationDetails
              }
              willEditNotes={editCollaborationDetails}
              hasAuthToActions={hasAuthToActions}
              onUpdateNotes={updateCollaborationDetailsHandler}
              onUndoEdit={undoEditedCollaborationDetailsHandler}
              onCloseUpdateNotes={closeUpdateCollaborationDetailsHandler}
            >
              <TextArea
                name='collaboration-details'
                label='Collaboration Details'
                maxLength={1000}
                showRemaining
                value={updatedCollaborationDetails}
                onChange={addOrChangeCollaborationDetailsHandler}
                title='Add or Update Collaboration Details'
                errorMessage='Please review the entered text to ensure it complies with the 1000-character limit and does not contain consecutive repeating characters.'
                invalid={!isValidCollaborationDetails}
              />
            </NoteContainer>
          </BorderedSectionContainer>
        ) : null}
        {hasAdditionalNotesField ? (
          <BorderedSectionContainer heading='Additional Notes about Cleanup Operation'>
            <NoteContainer
              updatedNotes={
                updatedAdditionalNotes || cleanedReport.additionalNotes
              }
              willEditNotes={editAdditionalNotes}
              hasAuthToActions={hasAuthToActions}
              onUpdateNotes={updateAdditionalNotesHandler}
              onUndoEdit={undoEditedAdditionalNotesHandler}
              onCloseUpdateNotes={closeUpdateAdditionalNotesHandler}
            >
              <TextArea
                name='additional-notes'
                label='Additional Notes'
                maxLength={1000}
                showRemaining
                value={updatedAdditionalNotes}
                onChange={addOrChangeAdditionalNotesHandler}
                title='Add or update Additional Notes'
                errorMessage='Please review the entered text to ensure it complies with the 1000-character limit and does not contain consecutive repeating characters.'
                invalid={!isValidAdditionalNotes}
              />
            </NoteContainer>
          </BorderedSectionContainer>
        ) : null}
        {hasCleanupMethodologyField ? selectedMethodologiesContent : null}
        {hasEnvironmentalImpactAssessmentField ? (
          <BorderedSectionContainer heading='Environmental Impact Assessment'>
            {environmentalAssessmentContent}
          </BorderedSectionContainer>
        ) : null}
      </ReportContainer>
    );
  }

  if (isUpdateLoading) {
    content = (
      <FetchingReports message='Updating Cleaned Report. Please wait...' />
    );
  }

  if (isLoadingSettings) {
    content = <FetchingReports message='Loading Settings. Please wait...' />;
  }

  if (isSettingsError) {
    content = (
      <NoDataFound message='An error occurred while retrieving the window settings. Please attempt the operation again later.' />
    );
  }

  const updateReportHandler = async () => {
    try {
      if (!cleanedReport) {
        return;
      }

      const isValidNumberOfWorkers = validateNumberOfWorkers(
        numberOfWorkers.toString()
      );
      const isValidCleanedDate = validateDate(
        cleanedAt,
        cleanedReport.cleanDate
      );

      const isValidExactLocation = validateExactLocation(exactLocation);
      const isValidObservedType = validateObservedType(observedType);
      const isValidEstimatedCost =
        !isNaN(costEstimate) && costEstimate >= 0 && costEstimate <= 999999999;

      const isValidListOfEmployees = employees.length <= numberOfWorkers;
      const isValidListOfDepartments =
        involvedDepartments.length <= 50 &&
        isValidListItem(involvedDepartments);
      const isValidUtilizedEquipments =
        utilizedEquipments.length <= 100 && isValidListItem(utilizedEquipments);

      const isValidLessonsLearned = validateText(
        updatedLessonsLearned,
        0,
        1000
      );
      const isLessonsLearnedLorem = isLoremIpsum(updatedLessonsLearned);

      const isValidCollaborationDetails = validateText(
        updatedCollaborationDetails,
        0,
        1000
      );
      const isCollaborationDetailsLorem = isLoremIpsum(
        updatedCollaborationDetails
      );

      const isValidAdditionalNotes = validateText(
        updatedAdditionalNotes,
        0,
        1000
      );
      const isAdditionalNotesLorem = isLoremIpsum(updatedAdditionalNotes);

      if (!isValidNumberOfWorkers) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Invalid Number'
            singleMessage='The number of workers cannot surpass 1000. Kindly review and adjust the entered value accordingly.'
          />
        );
      }

      if (!isValidCleanedDate) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Invalid Date'
            singleMessage='Please verify the cleaned date; it must not precede the detection date or extend beyond the current date.'
          />
        );
      }

      if (!isValidExactLocation) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Invalid Exact Location'
            singleMessage='Kindly review the entered value in the "Exact Location" field; the provided data appears to be invalid.'
          />
        );
      }

      if (!isValidObservedType) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Invalid Observed Type'
            singleMessage='The observed type must not exceed 200 characters, and it should not contain any numbers.'
          />
        );
      }

      const estimateCostMessage =
        costEstimate < 0
          ? 'Please verify the entered value in the cost estimation field; the provided data appears to be invalid. Negative values are not acceptable.'
          : 'Please review the entered value in the cost estimation field; the provided data appears to be invalid. The entered cost seems excessively high.';

      if (!isValidEstimatedCost) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Invalid Cost Estimation'
            singleMessage={estimateCostMessage}
          />
        );
      }

      if (!isValidListOfEmployees) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Invalid employees list'
            singleMessage='Please verify the employee list. The number of employees in the list cannot exceed the count of deployed workers.'
          />
        );
      }

      if (!isValidListOfDepartments) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Invalid List of Departments'
            singleMessage='Kindly review the entered data in the list of departments. The list cannot exceed 50, and the entered data should consist of valid departments.'
          />
        );
      }

      if (!isValidUtilizedEquipments) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Invalid List of Equipments'
            singleMessage='Please review the entered data in the list of equipments. The list cannot exceed 100, and the provided data should consist of valid equipment entries.'
          />
        );
      }

      if (!isValidLessonsLearned) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Limit exceeded'
            singleMessage='Kindly review the content in the "Lessons Learned" field; it should not exceed 1000 characters.'
          />
        );
      }

      if (isLessonsLearnedLorem) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Lorem Detected'
            singleMessage='Please share your experiences and insights regarding lessons learned in the provided field. If you have no information to provide, kindly leave the field empty. Avoid using Lorem Ipsum text.'
          />
        );
      }

      if (!isValidCollaborationDetails) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage='Kindly review the content in the "Collaboration Details" field; it should not exceed 1000 characters.'
            heading='Limit exceeded'
          />
        );
      }

      if (isCollaborationDetailsLorem) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Lorem Detected'
            singleMessage='Please share your experiences and insights regarding collaboration details in the provided field. If you have no information to provide, kindly leave the field empty. Avoid using Lorem Ipsum text.'
          />
        );
      }

      if (!isValidAdditionalNotes) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage='Kindly review the content in the "Additional Notes" field; it should not exceed 1000 characters.'
            heading='Limit exceeded'
          />
        );
      }

      if (isAdditionalNotesLorem) {
        return toast.error(
          <ToastifyStyledMessage
            heading='Lorem Detected'
            singleMessage='Please include additional notes related to the detected incident or clean operation in the provided field. If you have no information to provide, please leave the field empty. Avoid using Lorem Ipsum text.'
          />
        );
      }

      const updateData: ICleanUpdate = {
        reportId: cleanedReport.id,
        incidentId: selectedIncident._id,
        contractId: selectedIncident.contractId,
        gridObjectId: selectedIncident.gridObjectId,
        incidentType: selectedIncident.type,
        cleanDate: new Date(cleanedAt),
        numberOfWorkers,
        costEstimate,
        exactLocation,
        durationOption: cleanedReport.durationOption,
        cleanUpDays: cleanedReport.cleanUpDays,
        cleanUpHours: cleanedReport.cleanUpHours,
        employees,
        involvedDepartments,
        utilizedEquipments,
        lessonsLearned: updatedLessonsLearned,
        collaborationDetails: updatedCollaborationDetails,
        additionalNotes: updatedAdditionalNotes,
        selectedMethodologies,
        environmentalImpactAssessment: userAnswers,
        observedType,
        detectedAt: cleanedReport.detectedAt,
        coordinates: cleanedReport.coordinates,
        markAsVisited: 'No',
      };

      const res = await updateReport(updateData).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        reset();
        resetState();
        const timer = setTimeout(() => {
          onClose();
          clearTimeout(timer);
        }, 4000);

        toast.success(
          <ToastifyStyledMessage
            heading='Update Success'
            singleMessage='The Cleaned Report has been successfully updated. The report window will automatically close in 4 seconds for your convenience.'
          />,
          { autoClose: 3500 }
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  if (showFormInfo) {
    content = (
      <IncidentCleanedReportInformation
        onClose={showCleanReportInformationHandler}
        settings={settings}
      />
    );
  }

  return (
    <FormModal
      show={show}
      onClick={closeFormHandler}
      heading='Incident Cleaned Report'
      onShowInformation={showCleanReportInformationHandler}
      showInfo={showFormInfo}
    >
      <Fragment>
        {content}
        {hasAuthToActions &&
        !isSettingsError &&
        !isLoadingSettings &&
        !isUpdateLoading &&
        !showFormInfo ? (
          <ActionContainer className={classes.Action}>
            <StandardButton
              label='Reset Changes'
              btnType='orange'
              type='button'
              onClick={resetCleanedReportChangesHandler}
              fakeButton={!hasEdited}
            />
            <StandardButton
              label='Update Report'
              btnType='orange'
              type='button'
              fakeButton={!hasEdited}
              onClick={updateReportHandler}
            />
          </ActionContainer>
        ) : null}
      </Fragment>
    </FormModal>
  );
};

export default IncidentCleanedReport;
