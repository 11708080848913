import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RegistrationForm from './SignUpParts/RegistrationForm/RegistrationForm';
import HaveAccount from './SignUpParts/HaveAccount/HaveAccount';
import CountrySelection from './SignUpParts/CountrySelection/CountrySelection';
import DistrictSelection from './SignUpParts/DistrictSelection/DistrictSelection';
import OrganizationTypeSelection from './SignUpParts/OrganizationTypeSelection/OrganizationTypeSelection';
import {
  selectAllRegistrationFormValues,
  setAreasOfInterestStored,
  setCurrentPageNumber,
  setOrganizationType,
  setSelectedCountry,
  setSelectedDistrict,
  setSelectedOrganization,
  setSelectedPackageType,
  setSelectedProvince,
  setSelectedServiceType,
  setSelectedSubservices,
} from '../../../../features/registration/registrationSlice';
import { selectAllAuthValues } from '../../../../features/auth/authSlice';
import PageNavigation from './SignUpParts/PageNavigation/PageNavigation';
import { AppDispatch } from '../../../../app/store';
import ServiceTypeSelection from './SignUpParts/ServiceTypeSelection/ServiceTypeSelection';
import classes from './SignUp.module.scss';
import PackageSelection from './SignUpParts/PackageSelection/PackageSelection';
import OrganizationSelection from './SignUpParts/OrganizationSelection/OrganizationSelection';
import SelectionSummary from './SignUpParts/SelectionSummary/SelectionSummary';
import useClearRegistration from '../../../../CustomHooks/useClearRegistration';
import { getDaysFromCurrentDateTo } from '../../../../utils/getDaysFromCurrentDateTo';
import FlowBar from '../../../UI/FlowBar/FlowBar';
import useFlowData from '../../../../CustomHooks/useFlowData';
import LanguageSelection from '../LanguageSelection/LanguageSelection';

const SignUp = () => {
  const { user, hideNavigation } = useSelector(selectAllAuthValues);
  const { currentPageNumber } = useSelector(selectAllRegistrationFormValues);

  const flowData = useFlowData();

  const dispatch = useDispatch<AppDispatch>();
  const { clearForm } = useClearRegistration();

  useEffect(() => {
    const signUpFormDate = localStorage.getItem('sign-up-form-date');

    if (!signUpFormDate) {
      localStorage.setItem(
        'sign-up-form-date',
        JSON.stringify({ signUpStartDate: new Date().getTime() })
      );
    } else {
      const formDate: { signUpStartDate: number } = JSON.parse(signUpFormDate);

      const numberOfDays = getDaysFromCurrentDateTo(
        new Date(formDate.signUpStartDate)
      );

      if (numberOfDays > 30) {
        clearForm({ shouldResetState: true });
        window.location.reload();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const currentPageNumber = localStorage.getItem('currentPageNumber');
    const selectedOrganizationType = localStorage.getItem(
      'selected-organization-type'
    );
    const selectedCountry = localStorage.getItem('selected-Country');
    const selectedDistrict = localStorage.getItem('selected-district');
    const selectedProvice = localStorage.getItem('selected-province');
    const selectedServiceType = localStorage.getItem('selected-service-type');
    const areasOfInterest = localStorage.getItem('areas-of-interest-stored');
    const organization = localStorage.getItem('selected-organization');

    if (currentPageNumber) {
      dispatch(setCurrentPageNumber(Number(currentPageNumber)));
    }

    if (selectedOrganizationType) {
      const parsedData = JSON.parse(selectedOrganizationType);
      dispatch(setOrganizationType(parsedData));
    }

    if (selectedCountry) {
      const parsedData = JSON.parse(selectedCountry);
      dispatch(setSelectedCountry(parsedData));
    }

    if (selectedDistrict) {
      const parsedData = JSON.parse(selectedDistrict);
      dispatch(setSelectedDistrict(parsedData));
    }

    if (selectedProvice) {
      const parsedData = JSON.parse(selectedProvice);
      dispatch(setSelectedProvince(parsedData));
    }

    if (selectedServiceType) {
      const parsedData = JSON.parse(selectedServiceType);
      dispatch(setSelectedServiceType(parsedData));
    }

    if (areasOfInterest) {
      const parsedData = JSON.parse(areasOfInterest);
      dispatch(setAreasOfInterestStored(parsedData));
    }

    if (organization) {
      const parsedData = JSON.parse(organization);
      dispatch(setSelectedOrganization(parsedData));
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedSelectedPackage = localStorage.getItem('selected-package');
    const storedSelectedSubservices = localStorage.getItem(
      'selected-subservices'
    );

    if (storedSelectedPackage) {
      const parsedData = JSON.parse(storedSelectedPackage);

      dispatch(setSelectedPackageType(parsedData));
    }

    if (storedSelectedSubservices) {
      const parsedData = JSON.parse(storedSelectedSubservices);

      dispatch(setSelectedSubservices(parsedData));
    }
    // eslint-disable-next-line
  }, []);

  let pages = (
    <Fragment>
      {currentPageNumber === 1 ? (
        <OrganizationTypeSelection pageNumber={1} />
      ) : null}
      {currentPageNumber === 2 ? <CountrySelection pageNumber={2} /> : null}
      {currentPageNumber === 3 ? <DistrictSelection pageNumber={3} /> : null}
      {currentPageNumber === 4 ? <ServiceTypeSelection pageNumber={4} /> : null}
      {currentPageNumber === 5 ? <PackageSelection pageNumber={5} /> : null}
      {currentPageNumber === 6 ? (
        <OrganizationSelection pageNumber={6} />
      ) : null}
      {currentPageNumber === 7 ? <SelectionSummary pageNumber={7} /> : null}
      {currentPageNumber === 8 ? <RegistrationForm pageNumber={8} /> : null}
    </Fragment>
  );
  //'step-indicator current-step'
  return (
    <div className={classes.SignUpFormContainer}>
      <FlowBar flowData={flowData} currentPageNumber={currentPageNumber} />
      {!hideNavigation ? <PageNavigation /> : null}
      <div className={classes.PagesContainer}>{pages}</div>
      {!user && <HaveAccount />}
      <LanguageSelection />
    </div>
  );
};

export default SignUp;
