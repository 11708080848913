import React, { Fragment, useEffect, useState } from 'react';
import WindowContainer from '../WindowContainer/WindowContainer';
import {
  useGetReportByIdQuery,
  useGetSendReportsOfUserQuery,
  useUpdateReportArchiveStatusMutation,
} from '../../../../features/report/reportsSlice';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import { HiOutlineDocumentSearch } from 'react-icons/hi';
import MessageList from '../MessageList/MessageList';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import {
  IExistingReport,
  IReportListItem,
} from '../../../../tsTypes/interfaces';
import { useSearchParams } from 'react-router-dom';
import MessageContent from '../MessageContent/MessageContent';
import classes from './SendWindow.module.scss';
import queryErrorCatch from '../../../../utils/queryErrorCatch';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { useTranslation } from 'react-i18next';

interface IProps {
  isSelected: boolean;
  count: number;
  contractId: string;
  isLoadingCounts: boolean;
  countReportedIncidents: number;
}

const SendWindow = ({
  isSelected,
  count,
  contractId,
  isLoadingCounts,
  countReportedIncidents,
}: IProps) => {
  const { isLoading, isError, data } = useGetSendReportsOfUserQuery(
    { contractId, filter: 'send' },
    { skip: !isSelected || !contractId || count === 0 }
  );
  const [archiveReport, { isLoading: isArchiving, reset }] =
    useUpdateReportArchiveStatusMutation();
  const [message, setMessage] = useState<IExistingReport | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const selectedReportId = searchParams.get('report');
  let reports: IReportListItem[] = [];

  const {
    isLoading: isLoadingReport,
    isError: isReportError,
    data: selectedReport,
  } = useGetReportByIdQuery(
    { contractId, reportId: selectedReportId || '' },
    { skip: !selectedReportId }
  );

  let content: React.ReactNode = null;

  const reportId =
    selectedReport !== undefined &&
    typeof selectedReport === 'object' &&
    'id' in selectedReport
      ? selectedReport.id
      : '';

  useEffect(() => {
    if (
      !isLoadingReport &&
      !isReportError &&
      reportId &&
      !message &&
      selectedReport &&
      selectedReportId
    ) {
      setMessage(selectedReport);
    }
    // eslint-disable-next-line
  }, [isLoadingReport, isReportError, reportId, selectedReportId]);

  useEffect(() => {
    if (!selectedReportId) {
      setMessage(null);
    }
  }, [selectedReportId]);

  if (isLoading || isLoadingCounts) {
    content = (
      <FetchingReports message={t('reporting:fetching_send_reports')} />
    );
  }

  if (isError) {
    content = <NoDataFound message={t('reporting:send_reports_fetch_error')} />;
  }

  if (count === 0 && !isLoadingCounts && countReportedIncidents > 0) {
    content = (
      <NoDataFound
        icon={<HiOutlineDocumentSearch />}
        message={t('reporting:no_send_reports_available')}
      />
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    !isLoading &&
    !isError &&
    'isSuccess' in data &&
    data.isSuccess &&
    'data' in data &&
    data.data &&
    typeof data.data === 'object' &&
    'sendReports' in data.data &&
    Array.isArray(data.data.sendReports) &&
    data.data.sendReports.length > 0
  ) {
    reports = data.data.sendReports;
  }

  const readMessageHandler = (messageId: string) => {
    setSearchParams({ report: messageId });
  };

  const clearSelectedReportHandler = () => {
    setSearchParams({});
    setMessage(null);
  };

  const archiveReportHandler = async (reportId: string) => {
    try {
      if (!reportId) {
        return;
      }

      let selectedReport: IReportListItem | undefined = undefined;

      if (reports && reports.length > 0) {
        selectedReport = reports.find((report) => report.id === reportId);
      }

      if (
        selectedReport &&
        typeof selectedReport === 'object' &&
        'reportDate' in selectedReport &&
        selectedReport.reportDate
      ) {
        const reportDate = new Date(selectedReport.reportDate).getTime();
        const currentDate = new Date().getTime();

        const ninetyDaysInMilliseconds = 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds

        if (currentDate - reportDate < ninetyDaysInMilliseconds) {
          return toast.error(
            <ToastifyStyledMessage
              singleMessage={t('reporting:report_archive_limit_message')}
              heading={t('reporting:archiving_limitation')}
            />
          );
        }
      }

      const res = await archiveReport({
        reportId,
        contractId,
        process: 'move-to-archive',
      }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess &&
        'data' in res &&
        res.data &&
        typeof res.data === 'object' &&
        'updatedReport' in res.data &&
        res.data.updatedReport
      ) {
        reset();
        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('reporting:archive_success_message')}
            heading={t('reporting:archive_success_heading')}
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  if (reports.length === 0 && !isLoading && countReportedIncidents > 0) {
    content = <NoDataFound message={t('reporting:deleted_all_reports')} />;
  }

  if (
    reports.length === 0 &&
    !isLoading &&
    !isLoadingCounts &&
    countReportedIncidents === 0
  ) {
    content = <NoDataFound message={t('reporting:no_reports_available')} />;
  }

  if (reports && reports.length > 0) {
    const sendReports = reports.filter((report) => !report.isArchived);

    content = (
      <MessageList
        messages={sendReports}
        onRead={readMessageHandler}
        onArchive={archiveReportHandler}
      />
    );
  }

  if (message) {
    content = <MessageContent selectedReport={message} />;
  }

  if (isArchiving) {
    content = <FetchingReports message={t('reporting:archiving_report')} />;
  }

  return (
    <Fragment>
      <WindowContainer isSelected={isSelected}>{content}</WindowContainer>
      {message && isSelected ? (
        <div className={classes.ReportWindowActions}>
          <div className={classes.MainButtons}>
            <StandardButton
              label={t('reporting:back_to_reports')}
              btnType='primary'
              type='button'
              onClick={clearSelectedReportHandler}
              className={classes.ReportWindowButton}
              fakeButton={isArchiving}
            />
            <StandardButton
              label={t('reporting:go_to_incident')}
              btnType='primary'
              type='button'
              href={`/incident/${message.gridId}?latestIncident=${message.gridObjectId}&contractId=${message.contractId}`}
              className={classes.ReportWindowButton}
              fakeButton={isArchiving}
            />
            {/* <StandardButton
              label='Resend Message'
              btnType='primary'
              type='button'
              className={classes.ReportWindowButton}
              fakeButton={isArchiving}
            /> */}
            <StandardButton
              label={t('reporting:send_to_archive')}
              btnType='primary'
              type='button'
              className={classes.ReportWindowButton}
              fakeButton={isArchiving}
              onClick={() => archiveReportHandler(selectedReportId || '')}
            />
          </div>
          <div className={classes.DashboardBtnContainer}>
            <StandardButton
              label={t('reporting:back_to_dashboard')}
              btnType='plain'
              type='button'
              href='/'
              className={classes.ReportWindowButton}
              fakeButton={isArchiving}
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default SendWindow;
