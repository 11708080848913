import {
  ICleanResponse,
  IContractIncident,
  IVisitor,
} from '../../../../../../tsTypes/interfaces';
import { Document } from '@react-pdf/renderer';
import ReportLayout from '../../../../../pdfComponents/UI/ReportLayout/ReportLayout';
import NotFound from '../../../../../pdfComponents/UI/NotFound/NotFound';
import IncidentDetailsPDF from '../IncidentDetails/IncidentDetailsPDF';
import ReportDataContainer from '../ReportDataContainer/ReportDataContainer';
import DataRowPDF from '../../../../../pdfComponents/UI/DataRowPDF/DataRowPDF';
import divideArrayIntoChunks from '../../../../../../utils/divideArrayIntoChunks';
import ReportNote from '../ReportNote/ReportNote';
import VisitorsListItem from '../VisitReportPDF/VisitorsListItem/VisitorsListItem';

interface IProps {
  cleanReport?: ICleanResponse;
  selectedIncident: IContractIncident;
  show: boolean;
}

const CleanedReportPDF = ({ cleanReport, selectedIncident, show }: IProps) => {
  if (!cleanReport || !selectedIncident || !show) {
    return (
      <Document>
        <ReportLayout bgColor='main' reportHeading='Error'>
          <NotFound />
        </ReportLayout>
      </Document>
    );
  }

  let duration = '';

  if (cleanReport && (cleanReport.cleanUpDays || cleanReport.cleanUpHours)) {
    duration =
      cleanReport.cleanUpDays && cleanReport.cleanUpDays > 1
        ? `${cleanReport.cleanUpDays} days`
        : cleanReport.cleanUpDays && cleanReport.cleanUpDays === 1
        ? `${cleanReport.cleanUpDays} day`
        : cleanReport.cleanUpHours && cleanReport.cleanUpHours > 1
        ? `${cleanReport.cleanUpHours} hours`
        : `${cleanReport.cleanUpHours} hour`;
  }

  let employeesChunkList: IVisitor[][] = [];

  if (cleanReport.employees.length > 0) {
    employeesChunkList = divideArrayIntoChunks(cleanReport.employees, 100, 100);
  }

  const hasLessonsLearned = cleanReport.lessonsLearned.length > 0;
  const hasCollaborationDetails = cleanReport.collaborationDetails.length > 0;
  const hasNotes = cleanReport.additionalNotes.length > 0;
  const hasInvolvedDepartments = cleanReport.involvedDepartments.length > 0;
  const hasEquipments = cleanReport.utilizedEquipments.length > 0;

  return (
    <Document>
      <ReportLayout reportHeading='Incident Cleaned Report'>
        <IncidentDetailsPDF
          selectedIncident={selectedIncident}
          incidentType={cleanReport.incidentType}
        />
        <ReportDataContainer heading='General Information'>
          <DataRowPDF
            label='Report Created At'
            data={new Date(cleanReport.createdAt).toLocaleDateString('en', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}
          />
          <DataRowPDF
            label='Report Created By'
            data={cleanReport.createdBy.userName}
          />
          <DataRowPDF
            label='Cleaned At'
            data={new Date(cleanReport.cleanDate).toLocaleDateString('en', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })}
          />
          <DataRowPDF
            label='Number of Workers Deployed'
            data={
              cleanReport.numberOfWorkers
                ? cleanReport.numberOfWorkers
                : 'No information has been provided.'
            }
          />
          <DataRowPDF
            label='Estimated Cost of Operation'
            data={
              cleanReport.costEstimate
                ? `€${cleanReport.costEstimate}`
                : 'No information has been provided.'
            }
          />
          <DataRowPDF
            label='Observed Incident Type'
            data={
              cleanReport.observedType
                ? `${cleanReport.observedType}`
                : 'No information has been provided.'
            }
          />
          <DataRowPDF
            label='Cleanup Duartion'
            data={
              duration !== ''
                ? `${duration}`
                : 'No information has been provided.'
            }
          />
          <DataRowPDF
            label='Exact Location'
            data={
              cleanReport.exactLocation
                ? `${cleanReport.exactLocation}`
                : 'No information has been provided.'
            }
          />
        </ReportDataContainer>
      </ReportLayout>
      {hasCollaborationDetails || hasLessonsLearned || hasNotes ? (
        <ReportLayout reportHeading='Operation Notes'>
          {hasLessonsLearned ? (
            <ReportDataContainer heading='Lessons Learned'>
              <ReportNote>{cleanReport.lessonsLearned}</ReportNote>
            </ReportDataContainer>
          ) : null}
          {hasCollaborationDetails ? (
            <ReportDataContainer heading='Collaboration Details'>
              <ReportNote>{cleanReport.collaborationDetails}</ReportNote>
            </ReportDataContainer>
          ) : null}
          {hasNotes ? (
            <ReportDataContainer heading='Additional Notes About Cleanup Operation'>
              <ReportNote>{cleanReport.additionalNotes}</ReportNote>
            </ReportDataContainer>
          ) : null}
        </ReportLayout>
      ) : null}

      {employeesChunkList.length > 0
        ? employeesChunkList.map((chunk, chunkIndex) => (
            <ReportLayout reportHeading='Employees' key={chunkIndex}>
              <ReportDataContainer
                heading={`List of Involved Employees ${
                  employeesChunkList.length > 1
                    ? `(Page ${chunkIndex + 1})`
                    : ''
                }`}
              >
                {chunk.map((visitor, index) => (
                  <VisitorsListItem
                    visitor={visitor}
                    key={visitor.id}
                    index={chunkIndex === 0 ? index : chunkIndex * 100 + index}
                  />
                ))}
              </ReportDataContainer>
            </ReportLayout>
          ))
        : null}
      {hasEquipments || hasInvolvedDepartments ? (
        <ReportLayout reportHeading='Departments and Equipments'>
          {hasInvolvedDepartments ? (
            <ReportDataContainer heading='List of Involved Departments'>
              {cleanReport.involvedDepartments.map((department, index) => (
                <DataRowPDF
                  label={department.name}
                  data={
                    department.number ? `${department.number} employees` : ''
                  }
                  key={index}
                />
              ))}
            </ReportDataContainer>
          ) : null}
          {hasEquipments ? (
            <ReportDataContainer heading='List of Utilized Equipments'>
              {cleanReport.utilizedEquipments.map((equipmet, index) => (
                <DataRowPDF
                  label={equipmet.name}
                  data={equipmet.number ? equipmet.number : ''}
                />
              ))}
            </ReportDataContainer>
          ) : null}
        </ReportLayout>
      ) : null}
    </Document>
  );
};

export default CleanedReportPDF;
