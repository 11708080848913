import { useEffect } from 'react';

interface IProps {
  setterValue: boolean;
  onEscape: () => void;
}

const useEscape = ({ setterValue, onEscape }: IProps) => {
  useEffect(() => {
    const closeMenuOnEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.code === 'Escape') {
        onEscape();
      }
    };

    document.addEventListener('keyup', closeMenuOnEscape);

    return () => {
      document.removeEventListener('keyup', closeMenuOnEscape);
    };
    // eslint-disable-next-line
  }, [setterValue]);
};

export default useEscape;
