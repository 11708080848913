import classes from './WindowContainer.module.scss';

interface IProps {
  isSelected: boolean;
  children: React.ReactNode;
}

const WindowContainer = ({ isSelected, children }: IProps) => {
  if (!isSelected) {
    return null;
  }

  return <div className={classes.WindowContainer}>{children}</div>;
};

export default WindowContainer;
