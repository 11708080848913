import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import uiReducer from '../features/UI/uiSlice';
import registrationReducer from '../features/registration/registrationSlice';
import pointReducer from '../features/point/pointSlice';
import organizationReducer from '../features/organization/organizationSlice';
import incidentReucer from '../features/incidents/incidentsSlice';
import { periopsisApi } from '../features/api/apiSlice';

// import incidentTypesReducer from '../features/incidentTypes/incidentTypesSlice';

export const store = configureStore({
  reducer: {
    authentication: authReducer,
    userInterface: uiReducer,
    registrationForm: registrationReducer,
    point: pointReducer,
    organization: organizationReducer,
    incidents: incidentReucer,
    api: periopsisApi.reducer,
  },
  middleware: (curryGetDefaultMiddleware) =>
    curryGetDefaultMiddleware().concat(periopsisApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
