import { IPredefinedPolygon } from '../tsTypes/types.model';

/**
 * Groups a collection of municipalities by their associated districts.
 *
 * @param {IPredefinedPolygon[]} municipalities - An array of objects representing municipalities
 *   with each object having a 'district' property used for grouping.
 *
 * @returns {{
 *   groupedMunicipalities: Object<string, IPredefinedPolygon[]>,
 *   arrayGroupedMunicipalities: IPredefinedPolygon[][]
 * }} - An object containing two properties:
 *   - 'groupedMunicipalities': A key-value object where keys are district names and values
 *     are arrays of municipalities belonging to each district.
 *   - 'arrayGroupedMunicipalities': An array of arrays, where each inner array contains
 *     municipalities grouped by district.
 */
export const getDistrictMunicipalities = (
  municipalities: IPredefinedPolygon[]
) => {
  const groupedMunicipalities = new Map<string, IPredefinedPolygon[]>();

  // Group municipalities by district
  municipalities.forEach((municipality) => {
    const district = municipality.district;

    // Check if the district is already a key in the groupedMunicipalities Map
    if (district && !groupedMunicipalities.has(district)) {
      // If not, create an array for the district
      groupedMunicipalities.set(district, []);
    }

    if (district) {
      // Add the municipality to the corresponding district's array
      const districtMunicipalities = groupedMunicipalities.get(district);
      if (districtMunicipalities) {
        districtMunicipalities.push(municipality);
      }
    }
  });

  // Convert the groupedMunicipalities Map into an array of arrays
  const arrayOfArrays: IPredefinedPolygon[][] = Array.from(
    groupedMunicipalities.values()
  );

  return {
    groupedMunicipalities: Object.fromEntries(groupedMunicipalities),
    arrayGroupedMunicipalities: arrayOfArrays,
  };
};
