import { StyleSheet } from '@react-pdf/renderer';

export const listStyle = StyleSheet.create({
  ListContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1pt',
    alignItems: 'center',
  },
});
