import { ChangeEvent, useEffect, useState } from 'react';
import {
  IBankDetails,
  ICardPaymentRequest,
  ICheckoutContract,
  ICheckoutProforma,
  ICheckoutSectionSettings,
  ICheckoutUserData,
  paymentMethod,
  receiptInvoiceOption,
} from '../../../../../../tsTypes/checkout.interfaces';
import ContentContainer from '../ContentContainer/ContentContainer';
import ActionContainer from '../../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../../UI/FormElements/Button/Button';
import AddressContent from '../AddressContent/AddressContent';
import CustomerDetails from '../CustomerDetails/CustomerDetails';
import PaymentOption from '../PaymentWindow/PaymentOption/PaymentOption';
import CreditCardPaymentOption from '../PaymentWindow/CreditCardPaymentOption/CreditCardPatmentOption';
import DataRow from '../../../../../UI/DataRow/DataRow';
import useQuery from '../../../../../../CustomHooks/useQuery';
import { useParams } from 'react-router-dom';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import { catchError } from '../../../../../../utils/fetches';
import BankDetailsItem from '../BankDetailsItem/BankDetailsItem';
import CheckoutOrderDetails from './CheckoutOrderDetails/CheckoutOrderDetails';
import CheckoutCard from './CheckoutOrderDetails/CheckoutCard/CheckoutCard';
import PaymentTypeOption from './CheckoutOrderDetails/PaymentTypeOption/PaymentTypeOption';
import {
  cardType,
  paymentType,
} from '../../../../../../tsTypes/types.components';
import PaymentTypeTotal from './CheckoutOrderDetails/PaymentTypeTotal/PaymentTypeTotal';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import validateCreditCard from '../../../../../../utils/validateCreditCard';
import validateCVV from '../../../../../../utils/validateCVV';
import validateExpiryDate from '../../../../../../utils/validateCardExpiryDate';
import {
  NameSurnameValidator,
  getHeaderByCode,
} from '../../../../../../utils/componentUtilFns';
import ReceiptInvoiceOptionSection from './ReceiptInvoiceOptionSection/ReceiptInvoiceOptionSection';
import classes from './CheckoutSection.module.scss';
import {
  useCompleteOrderMutation,
  useCompletePaymentMutation,
  useGetBankDetailsQuery,
} from '../../../../../../features/checkout/checkoutSlice';
import ProcessingPayment from './CheckoutOrderDetails/ProcessingPayment/ProcessingPayment';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllUIValues,
  setIsOrderCompleteSuccess,
} from '../../../../../../features/UI/uiSlice';
import { AppDispatch } from '../../../../../../app/store';
import OrderCompletion from './OrderComplation/OrderCompletion';
import { useTranslation } from 'react-i18next';

interface IProps {
  proformaInvoice: ICheckoutProforma;
  contract: ICheckoutContract;
  user: ICheckoutUserData;
  settings: ICheckoutSectionSettings | null;
  onAddressUpdate: () => void;
}

const CheckoutSection = ({
  proformaInvoice,
  contract,
  user,
  settings,
  onAddressUpdate,
}: IProps) => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<
    paymentMethod | ''
  >('');

  const { isOrderCompleteSuccess } = useSelector(selectAllUIValues);
  const dispatch = useDispatch<AppDispatch>();

  const [selectedPaymentType, setSelectedPaymentType] =
    useState<paymentType>('full-payment');
  const [selectedCardType, setSelectedCardType] =
    useState<cardType>('credit-card');
  const [selectedReceiptInvoiceOption, setSelectedReceiptInvoiceOption] =
    useState<receiptInvoiceOption>('invoice-receipt');
  const [expiration, setExpiration] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [cvv, setCVV] = useState('');
  const { checkoutToken, registrationCode } = useParams();
  const { t } = useTranslation();
  // const [isOrderCompleteSuccess, setIsOrderCompleteSuccess] = useState(true);
  const query = useQuery();
  const proformaInvoiceNumberQuery = query.get('proformaInvoice');
  let proformaInvoiceNumber = '';
  let bankDetails: IBankDetails[] = [];
  let isFreeTrier = proformaInvoice.overallTotal === 0;
  let isBankTransfer = selectedPaymentOption === 'bank-transfer';
  let isCardPayment = selectedPaymentOption === 'credit-card';

  const hasSettings =
    settings !== null &&
    typeof settings === 'object' &&
    'bankTransferPaymentOption' in settings &&
    'changeBillingAddressOption' in settings &&
    'creditCardPaymentOption' in settings;

  const hasCreditCardPaymentOption = hasSettings
    ? settings.creditCardPaymentOption
    : false;
  const hasBankTransferOption = hasSettings
    ? settings.bankTransferPaymentOption
    : false;
  const hasPaypalOption = hasSettings ? settings.paypalPaymentOption : false;
  const hasFullPaymentOption = hasSettings ? settings.fullPaymentOption : false;
  const hasMonthlyPaymentOption = hasSettings
    ? settings.monthlyPaymentOption
    : false;

  const canChangeBillingAddress = hasSettings
    ? settings.changeBillingAddressOption
    : true;

  const { isLoading, isError, error, data } = useGetBankDetailsQuery(
    {
      registrationCode: registrationCode || '',
      checkoutToken: checkoutToken || '',
    },
    { skip: selectedPaymentOption !== 'bank-transfer' }
  );

  const [
    completePayment,
    {
      isLoading: isProcessingPayment,
      isError: isPaymentError,
      error: paymentError,
      data: paymentData,
    },
  ] = useCompletePaymentMutation({
    fixedCacheKey: 'payment-processing',
  });

  const [
    completeOrder,
    { isLoading: isCompletingOrder, data: completedOrderData },
  ] = useCompleteOrderMutation({ fixedCacheKey: 'complete-order-processing' });

  useEffect(() => {
    if (hasSettings) {
      const paymentTypeStatus = settings.paymentTypeDefault;
      const paymentMethodStatus = settings.paymentMethodDefault;

      setSelectedPaymentOption(paymentMethodStatus);
      setSelectedPaymentType(paymentTypeStatus);
    }

    // eslint-disable-next-line
  }, [hasSettings]);

  useEffect(() => {
    const storedOrderCompleted = localStorage.getItem('order-completed');

    if (storedOrderCompleted && storedOrderCompleted === 'true') {
      dispatch(setIsOrderCompleteSuccess(true));
    }
  }, [dispatch]);

  let orderNumber = '';

  if (!proformaInvoice || !contract || !user) {
    return null;
  }

  const paymentOptionSelectionHandler = (selectionId: paymentMethod) => {
    if (isProcessingPayment) {
      return;
    }

    setSelectedPaymentOption(selectionId);
  };

  const selectPaymentTypeHandler = (selectionId: paymentType) => {
    if (isProcessingPayment) {
      return;
    }

    setSelectedPaymentType(selectionId);
  };

  if (
    proformaInvoice &&
    typeof proformaInvoice === 'object' &&
    'proformaInvoiceNumber' in proformaInvoice &&
    proformaInvoice.proformaInvoiceNumber &&
    'orderNumber' in proformaInvoice &&
    proformaInvoice.orderNumber &&
    proformaInvoiceNumberQuery
  ) {
    proformaInvoiceNumber = proformaInvoiceNumberQuery;
    orderNumber = proformaInvoice.orderNumber;
  }

  if (
    data &&
    typeof data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'bankDetails' in data &&
    data.bankDetails &&
    Array.isArray(data.bankDetails) &&
    data.bankDetails.length > 0
  ) {
    bankDetails = data.bankDetails;
  }

  const completeOrderHandler = async () => {
    if (!isFreeTrier && !selectedPaymentOption) {
      toast.error(
        <ToastifyStyledMessage
          singleMessage={t('checkout:choose_a_payment_method')}
          heading={t('checkout:no_payment_method')}
        />,
        { autoClose: 26000 }
      );
    } else if (isBankTransfer || isFreeTrier) {
      const paymentType = selectedPaymentType;
      const paymentMethod = selectedPaymentOption;

      try {
        const response = await completeOrder({
          proformaInvoiceNumber,
          checkoutToken,
          registrationCode,
          selectedOptions: { paymentType, paymentMethod },
        }).unwrap();

        if (response && 'isSuccess' in response && response.isSuccess) {
          dispatch(setIsOrderCompleteSuccess(true));
          localStorage.setItem('order-completed', 'true');
        }
      } catch (error) {
        const err = catchError(error);
        const heading = getHeaderByCode(err.status);

        return toast.error(
          <ToastifyStyledMessage
            singleMessage={err.message}
            heading={heading}
          />
        );
      }
    } else if (selectedPaymentOption === 'credit-card') {
      return;
    }
  };

  const cardDateEntryHandler = (value: string) => {
    // Remove non-numeric characters
    value = value.replace(/\D/g, '');

    // Ensure MM is between 01 and 12
    if (value.length >= 2) {
      let mm = parseInt(value.slice(0, 2), 10);
      // Ensure MM is not 00
      mm = Math.min(Math.max(1, mm), 12);
      value = mm.toString().padStart(2, '0') + value.slice(2);
    }

    // Format as MM/YY
    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }

    setExpiration(value);
  };

  const cardNumberEntryHandler = (value: string) => {
    // Remove non-numeric characters
    value = value.replace(/\D/g, '');

    // Format as a credit card number (4 digits, space, repeat)
    if (value && value.length > 0) {
      value = value.match(/.{1,4}/g)?.join(' ') || '';
    }

    if (value.length === 20) {
      return;
    }

    setCardNumber(value);
  };

  const cardHolderEntryHandler = (value: string) => {
    if (!isNaN(Number(value))) {
      setCardHolder('');
      return;
    }

    setCardHolder(value);
  };
  const cvvEntryHandler = (value: string) => {
    if (value.length > 4) {
      return;
    }

    setCVV(value);
  };

  const cardDataEntryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (isProcessingPayment) {
      return;
    }
    const { value, name } = e.target;

    switch (name) {
      case 'card-holder':
        return cardHolderEntryHandler(value);
      case 'card-number':
        return cardNumberEntryHandler(value);
      case 'card-date':
        return cardDateEntryHandler(value);
      case 'cvv':
        return cvvEntryHandler(value);
    }
  };

  const completePaymentHandler = async () => {
    if (isProcessingPayment) {
      return;
    }

    try {
      if (!isFreeTrier && !selectedPaymentOption) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('checkout:choose_a_payment_method_credit_card')}
            heading={t('checkout:no_payment_method')}
          />,
          { autoClose: 26000 }
        );
      }

      const cardValidation = validateCreditCard(cardNumber);
      const cvvValidation = validateCVV(cvv, cardValidation.issuer);
      const isValidNameSurname = NameSurnameValidator(cardHolder, 4, 121);
      let expirationParts: string[] = [];
      let expirationMonth = 0;
      let expirationYear = 0;

      if (
        selectedCardType === 'debit-card' &&
        cardValidation.issuer !== 'Visa' &&
        cardValidation.issuer !== 'MasterCard'
      ) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={`${t('checkout:for_debit_cards')}. ${t(
              'checkout:we_apologize_for_any_inconvenience'
            )}`}
            heading={t('checkout:card_restriction_error')}
          />,
          { autoClose: 25000 }
        );
      }

      if (
        typeof expiration !== 'string' ||
        expiration.length < 5 ||
        expiration.length > 5
      ) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('checkout:expiration_date_error')}
            heading={t('checkout:date_error')}
          />
        );
      }

      if (expiration && expiration.length === 5) {
        expirationParts = expiration.split('/');

        if (expirationParts.length === 2) {
          expirationMonth = Number(expirationParts[0]);
          expirationYear = Number(expirationParts[1]);
        }
      }
      const isValidExpiaryDate = validateExpiryDate(
        expirationMonth,
        expirationYear
      );

      if (!isValidNameSurname) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('checkout:card_holder_error_message')}
            heading={t('checkout:card_holder_error')}
          />
        );
      }

      if (!cvvValidation.isValid) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('checkout:cvv_error_message')}
            heading={t('checkout:cvv_error')}
          />
        );
      }

      if (!isValidExpiaryDate) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('checkout:card_epiration_error_message')}
            heading={t('checkout:expiary_date_error')}
          />
        );
      }

      const requestData: ICardPaymentRequest = {
        checkoutToken,
        registrationCode,
        proformaInvoiceNumber,
        cardData: {
          cardHolder,
          cardNumber,
          expiration,
          cvv,
          issuer: cardValidation.issuer,
        },
        selectedOptions: {
          paymentMethod: selectedPaymentOption,
          paymentType: selectedPaymentType,
          cardType: selectedCardType,
          receiptInvoiceOption: selectedReceiptInvoiceOption,
        },
      };

      const response = await completePayment(requestData).unwrap();

      if (response && 'isSuccess' in response && response.isSuccess) {
        dispatch(setIsOrderCompleteSuccess(true));
        localStorage.setItem('order-completed', 'true');
        setCardNumber('');
        setCardHolder('');
        setCVV('');
        setExpiration('');
      }
    } catch (error) {
      const err = catchError(error);
      const heading = getHeaderByCode(err.status);

      return toast.error(
        <ToastifyStyledMessage singleMessage={err.message} heading={heading} />
      );
    }
  };

  const selectCardTypeHandler = (optionId: string) => {
    const cardType = optionId as cardType;

    if (isProcessingPayment) {
      return;
    }

    setSelectedCardType(cardType);
  };

  const selectReceiptInvoiceOption = (optionId: receiptInvoiceOption) => {
    if (isProcessingPayment) {
      return;
    }

    setSelectedReceiptInvoiceOption(optionId);
  };

  const redirectUser = (data: { isEmailConfirmed: boolean }) => {
    const { isEmailConfirmed } = data;

    if (isEmailConfirmed) {
      //send order completed login request to the backend and log user in
    } else {
      localStorage.setItem('auth-form', 'login');
      localStorage.clear();
      window.location.replace('/login');
    }
  };

  if (
    isOrderCompleteSuccess &&
    paymentData &&
    typeof paymentData === 'object' &&
    'isSuccess' in paymentData &&
    paymentData.isSuccess &&
    'isEmailConfirmed' in paymentData
  ) {
    const checkoutDetails = paymentData.data.checkoutDetails;
    const receiptInvoiceMessage = paymentData.data.isReceiptOnly
      ? t('checkout:receipt_generated_message')
      : t('checkout:invoice_and_receipt_prepared');
    const isEmailConfirmed = paymentData.isEmailConfirmed;

    const redirectMessage = isEmailConfirmed
      ? t('checkout:upon_the_successful_conclusion')
      : t('checkout:upon_the_successful_completion');

    const greeting = `${t('checkout:dear')} ${
      checkoutDetails.customerDetails.name
    }`;
    const messageArray = [
      t('checkout:payment_success'),
      redirectMessage,
      receiptInvoiceMessage,
    ];

    return (
      <CheckoutCard heading={t('checkout:payment_completed_successfully')}>
        <OrderCompletion
          messageArray={messageArray}
          greeting={greeting}
          isError={isPaymentError}
          errorMessage={catchError(paymentError).message}
        />
      </CheckoutCard>
    );
  } else if (
    isOrderCompleteSuccess &&
    completedOrderData &&
    typeof completedOrderData === 'object' &&
    'isSuccess' in completedOrderData &&
    completedOrderData.isSuccess &&
    'data' in completedOrderData &&
    completedOrderData.data &&
    typeof completedOrderData.data === 'object' &&
    'isBankTransfer' in completedOrderData.data &&
    completedOrderData.data.isBankTransfer &&
    completedOrderData.data.organizationType
  ) {
    const checkoutDetails = completedOrderData.data.checkoutDetails;
    const duration = completedOrderData.data.analysisDuration;
    const greeting = `Dear ${checkoutDetails.customerDetails.name}`;
    const isEmailConfirmed = completedOrderData.data.isEmailConfirmed;
    const durationMessage =
      duration / 60 > 1.5
        ? t('checkout:approval_process_at_chosen_polygon')
        : '';

    const lastMessage = !isEmailConfirmed
      ? t('checkout:do_not_forget_to_confirm_your_account')
      : t('checkout:ensure_you_include_your_order_number');

    const messageArray = [
      t('checkout:your_order_and_proforma_invoice_received'),
      t('checkout:following_payment_approval'),
      durationMessage,
      t('checkout:should_you_have_any_question'),
      lastMessage,
    ];

    return (
      <CheckoutCard heading={t('checkout:order_taken_successfully')}>
        <OrderCompletion
          messageArray={messageArray}
          hasRedirectTimer
          showTimer
          redirectFunction={() => redirectUser({ isEmailConfirmed })}
          duration={duration}
          greeting={greeting}
        />
      </CheckoutCard>
    );
  } else if (isOrderCompleteSuccess && isFreeTrier) {
    // const completedOrderData = {
    //   isSuccess: true,
    //   message: 'Order completed successfully',
    //   data: {
    //     selectedPackageName: 'Free Tier',
    //     serviceType: 'Dumping Detection',
    //     packageDuration: 30,
    //     durationUnit: 'days',
    //     organizationType: 'Enterprise',
    //     isEmailConfirmed: false,
    //     checkoutDetails: {
    //       orderNumber: 'Order-313131',
    //       customerDetails: {
    //         name: 'Aytac Guley',
    //       },
    //     },
    //     analysisDuration: 49850,
    //   },
    // };

    const checkoutDetails = completedOrderData.data.checkoutDetails;
    const greeting = `${t('checkout:dear')} ${
      checkoutDetails.customerDetails.name
    }`;
    const totalDuration = completedOrderData.data.analysisDuration;
    const isEmailConfirmed = completedOrderData.data.isEmailConfirmed;

    const confirmEmailMessage = !isEmailConfirmed
      ? t('checkout:before_you_log_in_to_your_account')
      : t('checkout:to_embark_on_your_periopsis_journey');

    const messageArray = [
      t('checkout:congratulations_your_order_for_the_periopsis'),
      confirmEmailMessage,
      t('checkout:should_you_have_any_question'),
    ];

    return (
      <CheckoutCard heading={t('checkout:free_trier_registration_completed')}>
        <OrderCompletion
          messageArray={messageArray}
          hasRedirectTimer
          isProcessing
          redirectFunction={() => redirectUser({ isEmailConfirmed })}
          greeting={greeting}
          duration={totalDuration}
        />
      </CheckoutCard>
    );
  }

  return (
    <ContentContainer className={classes.CheckoutContentContainer}>
      <div className={classes.CheckoutContainer}>
        <div className={classes.LeftSection}>
          {isCompletingOrder ? (
            <CheckoutCard heading={t('checkout:processing_order')}>
              <ProcessingPayment small />
            </CheckoutCard>
          ) : null}
          <CheckoutCard heading={t('checkout:billing_address')}>
            <AddressContent
              proformaInvoice={proformaInvoice}
              user={user}
              onAddressUpdate={onAddressUpdate}
              isAddressUpdatable={canChangeBillingAddress}
            />
          </CheckoutCard>
          <CheckoutCard heading={t('checkout:customer_details')}>
            <CustomerDetails
              customerEmail={proformaInvoice.customerEmail}
              customerName={proformaInvoice.customerName}
              userPhone={user.userPhone}
            />
          </CheckoutCard>

          <CheckoutCard heading={t('checkout:payment_type')} hide={isFreeTrier}>
            <div>
              <div className={classes.PaymentTypeContainer}>
                {hasFullPaymentOption ? (
                  <PaymentTypeOption
                    optionName={t('checkout:full_payment')}
                    selectionId='full-payment'
                    isSelected={selectedPaymentType === 'full-payment'}
                    onSelect={selectPaymentTypeHandler}
                  />
                ) : null}
                {hasMonthlyPaymentOption ? (
                  <PaymentTypeOption
                    optionName={t('checkout:monthly_payment')}
                    selectionId='monthly-payment'
                    isSelected={selectedPaymentType === 'monthly-payment'}
                    onSelect={selectPaymentTypeHandler}
                  />
                ) : null}
              </div>
              {
                <PaymentTypeTotal
                  totalAmount={proformaInvoice.totalAmount}
                  currency={proformaInvoice.selectedPackage.currency}
                  packageDuration={proformaInvoice.selectedPackage.duration}
                  paymentType={selectedPaymentType}
                  discountRateForFullPayment={
                    proformaInvoice.selectedPackage.discounts
                      .discountAmountForFullPayment
                  }
                  VATRate={proformaInvoice.VATRate}
                />
              }
            </div>
          </CheckoutCard>

          <CheckoutCard
            heading={t('checkout:payment_method')}
            hide={isFreeTrier}
          >
            <div className={classes.PaymentOptions}>
              {hasCreditCardPaymentOption ? (
                <PaymentOption
                  onSelect={paymentOptionSelectionHandler}
                  selected={selectedPaymentOption}
                  optionId='credit-card'
                  label={t('checkout:online_credit_debit_card')}
                >
                  <CreditCardPaymentOption
                    expiration={expiration}
                    cardHolder={cardHolder}
                    cardNumber={cardNumber}
                    cvv={cvv}
                    onCardDataEntry={cardDataEntryHandler}
                    onCardTypeSelect={selectCardTypeHandler}
                    selectedCardType={selectedCardType}
                    isProcessingPayment={isProcessingPayment}
                  />
                </PaymentOption>
              ) : null}
              {hasPaypalOption ? (
                <PaymentOption
                  label={t('checkout:paypal_payment')}
                  optionId='paypal'
                  selected={selectedPaymentOption}
                  onSelect={paymentOptionSelectionHandler}
                >
                  <div>Paypal</div>
                </PaymentOption>
              ) : null}
              {hasBankTransferOption ? (
                <PaymentOption
                  onSelect={paymentOptionSelectionHandler}
                  selected={selectedPaymentOption}
                  optionId='bank-transfer'
                  label={t('checkout:bank_transfer')}
                >
                  <PresentationWrapper
                    isLoading={isLoading}
                    isError={isError}
                    error={catchError(error)}
                    message={t('checkout:bank_details_fetch_error')}
                  >
                    {bankDetails.map((bankDetail) => (
                      <BankDetailsItem
                        key={bankDetail.bankId}
                        bankDetails={bankDetail}
                      />
                    ))}
                    <DataRow
                      heading={t('checkout:proforma_invoice_number')}
                      data={proformaInvoiceNumber}
                      removeSeperator
                      className={classes.DataRow}
                    />
                    <DataRow
                      heading={t('checkout:order_number')}
                      data={orderNumber}
                      removeSeperator
                      className={classes.DataRow}
                    />
                    <p className={classes.BankTransferNote}>
                      {t('checkout:during_eft_or_bank_transfer')}
                    </p>
                  </PresentationWrapper>
                </PaymentOption>
              ) : null}
            </div>
          </CheckoutCard>

          <CheckoutCard
            heading={
              isProcessingPayment
                ? t('checkout:payment_process')
                : t('checkout:receipt_and_invoice_options')
            }
            hide={isFreeTrier || !isCardPayment}
          >
            {!isProcessingPayment ? (
              <ReceiptInvoiceOptionSection
                selected={selectedReceiptInvoiceOption}
                onSelect={selectReceiptInvoiceOption}
              />
            ) : (
              <ProcessingPayment />
            )}
          </CheckoutCard>
          <ActionContainer className={classes.CheckoutActions}>
            {isFreeTrier || isBankTransfer ? (
              <Button onClick={completeOrderHandler} isFake={isCompletingOrder}>
                {t('checkout:complete_order')}
              </Button>
            ) : (
              <Button
                onClick={completePaymentHandler}
                isFake={isProcessingPayment}
              >
                {t('checkout:complete_payment')}
              </Button>
            )}
          </ActionContainer>
        </div>
        <div className={classes.RightSection}>
          <CheckoutCard heading={t('checkout:order_details')}>
            <CheckoutOrderDetails
              proformaInvoice={proformaInvoice}
              paymentType={selectedPaymentType}
            />
          </CheckoutCard>
        </div>
      </div>
    </ContentContainer>
  );
};

export default CheckoutSection;
