import { View, Text, Image } from '@react-pdf/renderer';
import { styles } from './NotFound.style';
import satallite from '../../../../assets/images/satallite.png';

interface IProps {
  message?: string;
}

const NotFound = ({ message }: IProps) => {
  const defaultMessage =
    message ||
    'We regret to inform you that no data was found to generate the PDF file. We apologize for this inconvenience and appreciate your understanding. Kindly attempt the operation again later. If the issue persists, feel free to contact our support team for further assistance. Thank you for your patience.';

  return (
    <View style={styles.container}>
      <View style={styles.imageWrapper}>
        <Image source={satallite} style={styles.satallite} />
        <Text style={styles.headingText}>No Data Found</Text>
      </View>
      <Text style={styles.text}>{defaultMessage}</Text>
    </View>
  );
};

export default NotFound;
