import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import classes from './StatisticsViewSelect.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  selected: 'distribution' | 'monthly' | 'percentage' | 'tables';
  onSelect: (
    selected: 'distribution' | 'monthly' | 'percentage' | 'tables'
  ) => void;
  buttonLabels?: string[];
  existingButtons: boolean[];
}

const StatisticsViewSelect = ({
  selected,
  onSelect,
  buttonLabels,
  existingButtons,
}: IProps) => {
  let labels = ['', '', '', ''];
  const { t } = useTranslation();

  if (
    buttonLabels &&
    Array.isArray(buttonLabels) &&
    buttonLabels.length === 4
  ) {
    labels = [...buttonLabels];
  }

  return (
    <div className={classes.ActionStatisticsSelection}>
      <div className={classes.StatisticsSelect}>
        {existingButtons[0] ? (
          <StandardButton
            label={labels[0] || t('statistics:distibution_by_type')}
            btnType={selected === 'distribution' ? 'orange' : 'primary-light'}
            onClick={() => onSelect('distribution')}
          />
        ) : null}
        {existingButtons[1] ? (
          <StandardButton
            label={labels[1] || t('statistics:monthly_statistics')}
            btnType={selected === 'monthly' ? 'orange' : 'primary-light'}
            onClick={() => onSelect('monthly')}
          />
        ) : null}
        {/* {existingButtons[2] ? (
          <StandardButton
            label={labels[2] || 'Monthly Percentages'}
            btnType={selected === 'percentage' ? 'orange' : 'primary-light'}
            onClick={() => onSelect('percentage')}
          />
        ) : null} */}
        {existingButtons[3] ? (
          <StandardButton
            label={labels[3] || t('statistics:tables')}
            btnType={selected === 'tables' ? 'orange' : 'primary-light'}
            onClick={() => onSelect('tables')}
          />
        ) : null}
      </div>
    </div>
  );
};

export default StatisticsViewSelect;
