import { Fragment } from 'react';
import { formatDistance } from 'date-fns';
import { Popup, Marker } from 'react-map-gl';
import { getGarbageIconByGarbageType } from '../../../utils/componentUtilFns';
import IncidentPopUpInfo from '../IncidentPopUpInfo/IncidentPopUpInfo';
import { IContractIncident } from '../../../tsTypes/interfaces';
import { MarkerEvent } from 'react-map-gl/dist/esm/types';
import classes from './IncidentMarker.module.scss';

interface IProps {
  incidentData: IContractIncident;
  hidePopup?: boolean;
  onShowPopup?: (e?: MarkerEvent<mapboxgl.Marker, MouseEvent>) => void;
  onClosePopup?: () => void;
  showPopUp?: boolean;
  makeOpac?: boolean;
}

const IncidentMarker = ({
  incidentData,
  hidePopup,
  onShowPopup,
  onClosePopup,
  showPopUp,
  makeOpac,
}: IProps) => {
  if (!incidentData) {
    return null;
  }

  const icon = getGarbageIconByGarbageType(
    incidentData.typeId,
    incidentData.density
  );

  return (
    <Fragment>
      <Marker
        latitude={incidentData.coordinates[0]}
        longitude={incidentData.coordinates[1]}
        onClick={onShowPopup}
      >
        <img
          src={icon.image}
          alt={`${incidentData.type} detected ${formatDistance(
            new Date(incidentData.detectionDate),
            new Date(),
            { addSuffix: true }
          )}`}
          className={classes.MarkerImage}
          title={`Click to see details about ${
            incidentData.type
          } detected on ${formatDistance(
            new Date(incidentData.detectionDate),
            new Date(),
            { addSuffix: true }
          )}`}
          style={{
            height: icon.size,
            width: icon.size,
            opacity: icon.opacity,
          }}
        />
      </Marker>
      {showPopUp && !hidePopup && (
        <Popup
          latitude={incidentData.coordinates[0]}
          longitude={incidentData.coordinates[1]}
          anchor='bottom'
          offset={18}
          closeOnClick={false}
          focusAfterOpen={true}
          closeButton={true}
          onClose={onClosePopup}
          style={{ minWidth: 'max-content' }}
          className={`${classes.PopUp} ${
            makeOpac ? classes.DecreaseOpacity : ''
          }`}
        >
          <IncidentPopUpInfo incident={incidentData} />
        </Popup>
      )}
    </Fragment>
  );
};

export default IncidentMarker;
