import classes from './ListItemContainer.module.scss';

interface IProps {
  children?: React.ReactNode | JSX.Element | JSX.Element[];
  sections: number;
}

const ListItemContainer = ({ children, sections }: IProps) => {
  return (
    <li
      className={classes.ListItemContainer}
      style={{ gridTemplateColumns: `35px repeat(${sections}, 1fr) 45px` }}
    >
      {children}
    </li>
  );
};

export default ListItemContainer;
