import { useState, useEffect, Fragment } from 'react';
import visa from '../../../../../../../assets/creditcardlogos/VISA-logo.png';
import masterCard from '../../../../../../../assets/creditcardlogos/Mastercard-logo.png';
import americanExpress from '../../../../../../../assets/creditcardlogos/American-Express-logo.png';
import discoverLogo from '../../../../../../../assets/creditcardlogos/discover.png';
import visaIssuer from '../../../../../../../assets/creditcards/visaCard.png';
import masterIssuer from '../../../../../../../assets/creditcards/masterCard.png';
import americanIssuer from '../../../../../../../assets/creditcards/AmericanExpressCard.png';
import discover from '../../../../../../../assets/creditcards/discover.png';
import unknownIssuer from '../../../../../../../assets/creditcards/UnknownCard.png';
import invalidIssuer from '../../../../../../../assets/creditcards/InvalidCreditCard.png';
import Form from '../../../../../../UI/FormElements/Form/Form';
import Input from '../../../../../../UI/FormElements/Input/Input';
import { ChangeEvent } from 'react';
import validateCreditCard from '../../../../../../../utils/validateCreditCard';
import {
  NameSurnameValidator,
  capitalizeAllWords,
} from '../../../../../../../utils/componentUtilFns';
import validateExpiryDate from '../../../../../../../utils/validateCardExpiryDate';
import PillOptionButton from '../../../../../../UI/PillOptionButton/PillOptionButton';
import { BsInfoCircle } from 'react-icons/bs';
import CVVInfoModal from './CVVInfoModal/CVVInfoModal';
import classes from './CreditCardPaymentOption.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  expiration: string;
  cardNumber: string;
  cardHolder: string;
  cvv: string;
  onCardDataEntry: (e: ChangeEvent<HTMLInputElement>) => void;
  onCardTypeSelect: (optionId: string) => void;
  selectedCardType: string;
  isProcessingPayment?: boolean;
}

const CreditCardPaymentOption = ({
  expiration,
  cardNumber,
  cardHolder,
  cvv,
  selectedCardType,
  onCardDataEntry,
  onCardTypeSelect,
  isProcessingPayment,
}: IProps) => {
  const [cardValidationResult, setCardValidationResult] = useState<{
    isValid: boolean;
    issuer: string;
  }>({ isValid: false, issuer: '' });
  const [showCVVInfo, setShowCVVInfo] = useState(false);
  let image = '';
  let expirationMonth = 0;
  let expirationYear = 0;
  const { t } = useTranslation();

  useEffect(() => {
    if (cardNumber && cardNumber.length > 17) {
      const cardValResult = validateCreditCard(cardNumber);
      setCardValidationResult(cardValResult);
    }
  }, [cardNumber]);

  if (cardNumber.length > 17) {
    switch (cardValidationResult.issuer) {
      case 'Visa':
        image = visaIssuer;
        break;
      case 'MasterCard':
        image = masterIssuer;
        break;
      case 'American Express':
        image = americanIssuer;
        break;
      case 'Discover':
        image = discover;
        break;
      case 'Unknown':
        image = unknownIssuer;
        break;

      default:
        image = invalidIssuer;
        break;
    }
  }

  if (expiration && typeof expiration === 'string' && expiration.length === 5) {
    const expirationParts = expiration.split('/');

    if (expirationParts.length === 2) {
      expirationMonth = Number(expirationParts[0]);
      expirationYear = Number(expirationParts[1]);
    }
  }

  const isDebitCard = selectedCardType === 'debit-card';
  const validCardNumberLength =
    cardValidationResult.issuer &&
    cardValidationResult.issuer === 'American Express'
      ? 18
      : 19;

  const invalidCVV =
    cardValidationResult.issuer === 'American Express'
      ? (cvv.length > 1 && cvv.length < 4) || cvv.length > 4
      : (cvv.length > 0 && cvv.length < 3) || cvv.length > 3;

  const showCVVInfoHandler = () => {
    setShowCVVInfo((prevState) => !prevState);
  };

  return (
    <Fragment>
      <div className={classes.CreditCardPaymentOptionContainer}>
        <div>
          <PillOptionButton
            options={{
              leftOption: t('checkout:credit_card'),
              rightOption: t('checkout:debit_card'),
            }}
            onSelect={onCardTypeSelect}
            selected={selectedCardType}
            optionIds={{
              leftOptionId: 'credit-card',
              rightOptionId: 'debit-card',
            }}
          />
        </div>
        <div className={classes.Images}>
          <img
            src={visa}
            alt='Visa Card'
            title={t('checkout:visa_image_title')}
          />
          <img
            src={masterCard}
            alt='Master Card'
            title={t('checkout:master_card_image_title')}
          />
          {!isDebitCard ? (
            <img
              src={americanExpress}
              alt='American Express Card'
              title={t('checkout:american_express_image_title')}
            />
          ) : null}
          {!isDebitCard ? (
            <img
              src={discoverLogo}
              alt='Discover Card'
              title={t('checkout:discover_image_title')}
            />
          ) : null}
        </div>
        <Form className={classes.CardForm}>
          <Input
            label={t('checkout:name_of_the_card_holder')}
            name='card-holder'
            type='text'
            onChange={onCardDataEntry}
            value={capitalizeAllWords(cardHolder)}
            isInvalid={
              cardHolder.length > 3 && !NameSurnameValidator(cardHolder, 4, 121)
            }
            autoComplete='cc-name'
            disabled={isProcessingPayment}
          />
          <div className={classes.CardNumberSection}>
            <Input
              label={t('checkout:card_number')}
              name='card-number'
              type='text'
              maxLength={validCardNumberLength}
              onChange={onCardDataEntry}
              value={cardNumber}
              isInvalid={
                cardNumber.length > validCardNumberLength - 1 &&
                !cardValidationResult.isValid
              }
              autoComplete='cc-number'
              disabled={isProcessingPayment}
            />
            {cardNumber && cardNumber.length > validCardNumberLength - 1 ? (
              <div className={classes.CardTypeImageSection}>
                <img src={image} alt='Card Issuer' />
              </div>
            ) : null}
          </div>
          <div className={classes.CreditCardDateSection}>
            <div className={classes.DateInput}>
              <Input
                label='MM/YY'
                name='card-date'
                type='text'
                maxLength={5}
                onChange={onCardDataEntry}
                value={expiration}
                isInvalid={
                  expiration.length === 5 &&
                  !validateExpiryDate(expirationMonth, expirationYear)
                }
                autoComplete='cc-exp'
                disabled={isProcessingPayment}
              />
            </div>
            <div className={classes.CVVInput}>
              <Input
                label='CVV'
                name='cvv'
                type='text'
                maxLength={
                  cardValidationResult.issuer === 'American Express' ? 4 : 3
                }
                onChange={onCardDataEntry}
                value={cvv}
                isInvalid={invalidCVV}
                autoComplete='cc-csc'
                disabled={isProcessingPayment}
              />
              <button className={classes.CVVInfo} onClick={showCVVInfoHandler}>
                <BsInfoCircle />
              </button>
            </div>
          </div>
        </Form>
      </div>
      <CVVInfoModal
        issuer={cardValidationResult.issuer}
        show={showCVVInfo}
        onClick={showCVVInfoHandler}
      />
    </Fragment>
  );
};

export default CreditCardPaymentOption;
