import React, { ChangeEvent, Fragment, useState, useEffect } from 'react';
import {
  ICheckoutProforma,
  ICheckoutUserData,
} from '../../../../../../tsTypes/checkout.interfaces';
import classes from './AddressContent.module.scss';
import createFullAddress from '../../../../../../utils/addressUtilityFunctions/createFullAddress';
import AddressOption from '../../../../../UI/AddressOption/AddressOption';
import { IAddress } from '../../../../../../tsTypes/types.model';
import checkoutUnAuthorized from '../../../../../../utils/checkoutUnauthorized';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { toast } from 'react-toastify';
import { catchError } from '../../../../../../utils/fetches';
import { getHeaderByCode } from '../../../../../../utils/componentUtilFns';
import { useParams } from 'react-router-dom';
import useQuery from '../../../../../../CustomHooks/useQuery';
import ActionContainer from '../../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../../UI/FormElements/Button/Button';
import BillingAddressForm from '../BillingAddressForm/BillingAddressForm';
import CenterDiv from '../../../../../UI/CenterDiv/CenterDiv';
import SimpleLoading from '../../../../../UI/SimpleLoading/SimpleLoading';
import {
  useDeleteAddressMutation,
  useUpdateBillingAddressMutation,
} from '../../../../../../features/checkout/checkoutSlice';
import { useTranslation } from 'react-i18next';

interface IProps {
  user: ICheckoutUserData;
  proformaInvoice: ICheckoutProforma;
  onAddressUpdate: () => void;
  isAddressUpdatable?: boolean;
}

const AddressContent = ({
  user,
  proformaInvoice,
  onAddressUpdate,
  isAddressUpdatable,
}: IProps) => {
  let isIndividualType = false;
  let addressDetails = '';
  const [userAddresses, setUserAddresses] = useState<IAddress[]>([]);
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [currentAddressId, setCurrentAddressId] = useState('');
  const [updateBillingAddress, { isLoading, isSuccess, reset }] =
    useUpdateBillingAddressMutation();
  const [deleteBillingAddress] = useDeleteAddressMutation();
  const { checkoutToken, registrationCode } = useParams();
  const query = useQuery();
  const proformaInvoiceNumber = query.get('proformaInvoice');
  const { t } = useTranslation();

  const proformaInvoiceAddressId =
    proformaInvoice &&
    typeof proformaInvoice === 'object' &&
    'address' in proformaInvoice &&
    proformaInvoice.address
      ? proformaInvoice.address.addressId
      : '';
  const hasUserData = user && typeof user === 'object';

  useEffect(() => {
    if (proformaInvoiceAddressId) {
      setCurrentAddressId(proformaInvoiceAddressId);
    }
  }, [proformaInvoiceAddressId]);

  useEffect(() => {
    if (hasUserData) {
      setUserAddresses(user.addresses);
    }
    // eslint-disable-next-line
  }, [hasUserData]);

  if (
    proformaInvoice &&
    typeof proformaInvoice === 'object' &&
    'organization' in proformaInvoice &&
    proformaInvoice.organization &&
    typeof proformaInvoice.organization === 'object' &&
    'type' in proformaInvoice.organization &&
    proformaInvoice.organization.type
  ) {
    isIndividualType = proformaInvoice.organization.type === 'Individual';
  }

  if (
    proformaInvoice &&
    typeof proformaInvoice === 'object' &&
    'address' in proformaInvoice &&
    proformaInvoice.address &&
    proformaInvoice.address.addressDetails
  ) {
    addressDetails = proformaInvoice.address.addressDetails.name;
  }

  const showAddressOptionsHandler = () => {
    if (!isAddressUpdatable) {
      return;
    }

    setShowAddressOptions((prevState) => !prevState);

    if (proformaInvoiceAddressId) {
      setCurrentAddressId(proformaInvoiceAddressId);
    }

    reset();
  };

  const selectAddressHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentAddressId(e.target.id);
  };

  const showNewAddressFormHandler = () => {
    setShowAddressForm((prevState) => !prevState);
  };

  const deleteAddressOptionHandler = async (addressId: string) => {
    if (addressId === currentAddressId) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('checkout:delete_error_message')}
          heading={t('checkout:delete_error')}
        />
      );
    }

    const updatedUserAddresses = userAddresses.filter(
      (address) => address.addressId !== addressId
    );
    setUserAddresses(updatedUserAddresses);

    try {
      if (!checkoutToken || !registrationCode) {
        return checkoutUnAuthorized();
      }

      const response = await deleteBillingAddress({
        addressId,
        checkoutToken,
        registrationCode,
      }).unwrap();

      if (
        response &&
        typeof response === 'object' &&
        'user' in response &&
        response.user &&
        typeof response.user === 'object'
      ) {
        toast.success(
          <ToastifyStyledMessage
            singleMessage={`${t('checkout:dear')} ${response.user.name} ${
              response.user.surname
            }, ${t('checkout:address_remove_success')}`}
          />,
          { autoClose: 4500 }
        );
      }
    } catch (error) {
      const err = catchError(error);

      if (hasUserData) {
        setUserAddresses(user.addresses);
      }

      toast.error(
        <ToastifyStyledMessage
          singleMessage={`${err.message}`}
          heading={getHeaderByCode(err.status)}
        />
      );
    }
  };

  const updateUserBillingAddressHandler = async () => {
    try {
      const selectedAddress = userAddresses.find(
        (address) => address.addressId === currentAddressId
      );

      const currentProformaInvoiceAddressId = proformaInvoice.address.addressId;

      if (
        selectedAddress &&
        selectedAddress.addressId === currentProformaInvoiceAddressId
      ) {
        toast.info(
          <ToastifyStyledMessage
            singleMessage={t('checkout:same_address_message')}
            heading={t('checkout:same_address')}
          />,
          { autoClose: 1500 }
        );

        return;
      }

      if (
        selectedAddress &&
        selectedAddress.addressId !== currentProformaInvoiceAddressId &&
        registrationCode &&
        proformaInvoiceNumber &&
        checkoutToken
      ) {
        const response = await updateBillingAddress({
          updatedAddress: selectedAddress,
          registrationCode,
          proformaInvoiceNumber,
          checkoutToken,
        }).unwrap();

        if (
          response &&
          typeof response === 'object' &&
          'proformaInvoice' in response &&
          response.proformaInvoice &&
          typeof response.proformaInvoice === 'object'
        ) {
          toast.success(
            <ToastifyStyledMessage
              singleMessage={t('checkout:address_update_success_message')}
              heading={t('checkout:address_update_success_heading')}
            />,
            { autoClose: 1600 }
          );

          onAddressUpdate();
          let timer = setTimeout(() => {
            setShowAddressOptions(false);
            //This will reset useUpdateBillingAddressMutation state
            reset();
            clearTimeout(timer);
          }, 1800);
        }
      }
    } catch (error) {
      const err = catchError(error);

      toast.error(
        <ToastifyStyledMessage
          singleMessage={`${err.message}`}
          heading={getHeaderByCode(err.status)}
        />
      );
    }
  };

  const updateAddressIdHandler = (addressId: string) => {
    setCurrentAddressId(addressId);
  };

  const updateUserAddressesHandler = (newAddress: IAddress) => {
    setUserAddresses((prevState) => [...prevState, newAddress]);

    const timer = setTimeout(() => {
      setShowAddressOptions(false);
      clearTimeout(timer);
    }, 1600);
  };

  let actionButtons: React.ReactNode | null = null;

  if (isAddressUpdatable) {
    actionButtons = (
      <ActionContainer className={classes.AddressOptionsActions}>
        <Button
          onClick={updateUserBillingAddressHandler}
          type='button'
          title={t('checkout:update_title')}
          btnClass={classes.ActionButton}
        >
          {t('checkout:update')}
        </Button>
        <Button
          onClick={showNewAddressFormHandler}
          type='button'
          title={
            userAddresses.length >= 6
              ? t('checkout:add_different_address')
              : t('checkout:open_new_address_form')
          }
          isFake={userAddresses.length >= 6}
          btnClass={classes.ActionButton}
        >
          {t('checkout:add_new_address')}
        </Button>
        <Button
          onClick={showAddressOptionsHandler}
          type='button'
          title={t('checkout:close_title')}
          btnClass={classes.ActionButton}
        >
          {t('checkout:close')}
        </Button>
      </ActionContainer>
    );
  }

  if (isLoading) {
    actionButtons = (
      <CenterDiv>
        <SimpleLoading />
      </CenterDiv>
    );
  }

  if (isSuccess) {
    actionButtons = null;
  }

  let addressContent = (
    <Fragment>
      {!isIndividualType &&
      user.organizationAddressId === proformaInvoice.address.addressId ? (
        <div className={classes.AddressOrganizationData}>
          <h5>{proformaInvoice.organization.name}</h5>
          <span>
            {t('checkout:vat_number')} :{' '}
            {proformaInvoice.organization.vatNumber}
          </span>
        </div>
      ) : (
        <div className={classes.AddressOrganizationData}>
          <h5>{proformaInvoice.address.addressType.name}</h5>
          <h5>{`${user.name} ${user.surname}`}</h5>
        </div>
      )}
      <address className={classes.AddressText}>
        {createFullAddress(proformaInvoice.address)}
      </address>
      {addressDetails ? (
        <div className={classes.AddressOrganizationData}>
          <h5>{t('checkout:address_details')}</h5>
          <address className={classes.AddressText}>{addressDetails}</address>
        </div>
      ) : null}
    </Fragment>
  );

  if (
    showAddressOptions &&
    user &&
    'addresses' in user &&
    user.addresses &&
    Array.isArray(user.addresses) &&
    user.addresses.length > 0 &&
    userAddresses &&
    userAddresses.length > 0
  ) {
    addressContent = (
      <div className={classes.AddressOptions}>
        {userAddresses.map((address) => (
          <AddressOption
            key={address.addressId}
            address={address}
            isSelected={address.addressId === currentAddressId}
            onAddressSelect={selectAddressHandler}
            organizationData={
              address.addressId === user.organizationAddressId
                ? {
                    organizationName: proformaInvoice.organization.name,
                    VATNumber: proformaInvoice.organization.vatNumber,
                    addressId: user.organizationAddressId,
                  }
                : undefined
            }
            onDelete={deleteAddressOptionHandler}
          />
        ))}

        {actionButtons}
      </div>
    );
  }

  if (showAddressForm) {
    addressContent = (
      <BillingAddressForm
        shouldSkip={!showAddressForm}
        onShowForm={showNewAddressFormHandler}
        addressIdUpdater={updateAddressIdHandler}
        onUserAddressesUpdate={updateUserAddressesHandler}
      />
    );
  }

  let heading = t('checkout:customer_billing_address');

  if (showAddressOptions && !showAddressForm) {
    heading = t('checkout:registered_addresses');
  }

  if (showAddressForm) {
    heading = t('checkout:add_new_address');
  }

  return (
    <div className={classes.Address}>
      <div className={classes.AddressHeading}>
        <h4>{heading}</h4>

        {!showAddressForm && isAddressUpdatable ? (
          <button type='button' onClick={showAddressOptionsHandler}>
            {showAddressOptions
              ? t('checkout:close')
              : t('checkout:change_address')}
          </button>
        ) : null}
      </div>
      {showAddressOptions && userAddresses.length >= 6 ? (
        <span className={classes.AddressLimitMessage}>
          {t('checkout:address_limit_error_message')}
        </span>
      ) : null}
      {addressContent}
    </div>
  );
};

export default AddressContent;
