import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
  useGetIncidentVisitReportQuery,
  useUpdateIncidentVisitReportMutation,
} from '../../../../../features/incidents/incidentsSlice';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import FormModal from '../../../../UI/FormModal/FormModal';
import EditableRowData from '../../../../UI/EditableRowData/EditableRowData';
import { GrDocumentUpdate } from 'react-icons/gr';
import { PiDetectiveFill } from 'react-icons/pi';
import { FaUser } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { selectAllAuthValues } from '../../../../../features/auth/authSlice';
import { IoClose, IoPerson } from 'react-icons/io5';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import TextArea from '../../../../UI/FormElements/TextArea/TextArea';
import { IContractIncident, IVisitor } from '../../../../../tsTypes/interfaces';
import AddVisitorsForm from '../AddVisitorsForm/AddVisitorsForm';
import useFormEntry from '../../../../../CustomHooks/useFormEntry';
import {
  validateNameSurname,
  validateText,
} from '../../../../../utils/componentUtilFns';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { confirmAlert } from 'react-confirm-alert';
import { nanoid } from '@reduxjs/toolkit';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import VisitorsListItem from '../IncidentVisitForm/VisitorsListItem/VisitorsListItem';
import isSameVisitorsList from '../../../../../utils/isSameVisitorsList';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import ReportContainer from '../ReportConatiner/ReportContainer';
import BorderedSectionContainer from '../BorderedSectionContainer/BorderedSectionContainer';
import IncidentDetailsSection from '../IncidentDetailsSection/IncidentDetailsSection';
import NoteContainer from '../NoteContainer/NoteContainer';
import classes from './IncidentVisitReport.module.scss';
import VisitReportPDF from '../ReportPDFs/VisitReportPDF/VisitReportPDF';
import { usePDF } from '@react-pdf/renderer';
import PDFError from '../../../../UI/PDFError/PDFError';
import DownloadButton from '../../../../UI/DownloadButton/DownloadButton';
import { useTranslation } from 'react-i18next';

interface IProps {
  show: boolean;
  contractIncidentId: string;
  contractId: string;
  isVisited: boolean;
  onClose: () => void;
  selectedIncident: IContractIncident;
}

const validateEnteredText = (text: string) => validateText(text, 3, 100);
const validateEnteredNotes = (text: string) => validateText(text, 0, 1500);

const IncidentVisitReport = ({
  show,
  isVisited,
  contractId,
  contractIncidentId,
  onClose,
  selectedIncident,
}: IProps) => {
  const [shouldFetch, setShouldFetch] = useState(false);
  const [rowName, setRowName] = useState('');
  const [updatedObservedType, setUpdatedObservedType] = useState('');
  const [updatedNotes, setUpdatedNotes] = useState('');
  const [editNotes, setEditNotes] = useState(false);
  const [visitors, setVisitors] = useState<IVisitor[]>([]);
  const [addVisitors, setAddVisitors] = useState(false);
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en';

  let content: React.ReactNode = null;
  const { userAuthorizations } = useSelector(selectAllAuthValues);
  let hasAuthToActions = false;
  let showUpdateButton = false;
  const isValidNotes = validateEnteredNotes(updatedNotes);

  const { isLoading, isError, data, isSuccess } =
    useGetIncidentVisitReportQuery(
      { contractId, contractIncidentId },
      { skip: !isVisited && !shouldFetch }
    );

  const doc = (
    <VisitReportPDF
      isVisited={isVisited}
      shouldFetch={shouldFetch}
      data={data}
      selectedIncident={selectedIncident}
    />
  );
  const [instance, update] = usePDF({ document: doc });
  let pdfDoc: React.ReactNode | null = null;

  const [
    updateVisitReport,
    { isLoading: isUpdateLoading, isSuccess: isUpdateSuccess },
  ] = useUpdateIncidentVisitReportMutation();

  const {
    value: name,
    isValid: isValidName,
    entryHandler: nameEntryHandler,
    clearEntry: clearName,
  } = useFormEntry(validateNameSurname, 'visitor-name', '', true);

  const {
    value: surname,
    isValid: isValidSurname,
    entryHandler: surnameEntryHandler,
    clearEntry: clearSurname,
  } = useFormEntry(validateNameSurname, 'visitor-surname', '', true);

  const {
    value: department,
    isValid: isValidDepartment,
    entryHandler: departmentEntryHandler,
    clearEntry: clearDepartment,
  } = useFormEntry(validateEnteredText, 'visitor-department', '', true);

  const {
    value: duty,
    isValid: isValidDuty,
    entryHandler: dutyEntryHandler,
    clearEntry: clearDuty,
  } = useFormEntry(validateEnteredText, 'visitor-duty', '', true);

  useEffect(() => {
    if (isVisited && contractId && contractIncidentId && !shouldFetch) {
      const timer = setTimeout(() => {
        setShouldFetch(true);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isVisited, contractId, contractIncidentId, shouldFetch]);

  useEffect(() => {
    if (isVisited && shouldFetch && (isSuccess || isUpdateSuccess || show)) {
      update(doc);
    }

    // eslint-disable-next-line
  }, [isVisited, shouldFetch, isSuccess, isUpdateSuccess, show]);

  const hasVisitors =
    data !== undefined &&
    typeof data === 'object' &&
    'visitors' in data &&
    data.visitors.length > 0;

  const hasNotes =
    data !== undefined &&
    typeof data === 'object' &&
    'notes' in data &&
    data.notes !== undefined &&
    typeof data.notes === 'string' &&
    data.notes.length > 0;

  const hasObservedType =
    data !== undefined &&
    typeof data === 'object' &&
    'observedIncident' in data &&
    data.observedIncident !== undefined;

  useEffect(() => {
    if (hasVisitors) {
      setVisitors(data.visitors);
    }

    // eslint-disable-next-line
  }, [hasVisitors]);

  useEffect(() => {
    if (hasNotes) {
      setUpdatedNotes(data.notes);
    }
    // eslint-disable-next-line
  }, [hasNotes]);

  useEffect(() => {
    if (hasObservedType) {
      setUpdatedObservedType(data.observedIncident);
    }
    // eslint-disable-next-line
  }, [hasObservedType]);

  if (!show || !isVisited) {
    return null;
  }

  if (isLoading) {
    content = (
      <div className={classes.ProgressWrapper}>
        <SimpleLoading />
      </div>
    );
  }

  if (isError && !isLoading) {
    content = (
      <div className={classes.ProgressWrapper}>
        <NoDataFound
          message={t('incidentVisitReport:Visit_Report_Retrieve_Error')}
        />
      </div>
    );
  }

  if (instance && instance.error) {
    pdfDoc = <PDFError />;
  }

  if (instance && instance.url && data) {
    pdfDoc = (
      <DownloadButton
        url={instance.url}
        fileName={`Periopsis_${
          data.detectedType
        }_visit_${new Date().getTime()}.pdf`}
        title='Download Visit Report'
        label={t('incidentVisitReport:Visit_Report')}
      />
    );
  }

  if (
    userAuthorizations &&
    typeof userAuthorizations === 'object' &&
    'hasAuthToActions' in userAuthorizations &&
    'hasAuthtoAdmin' in userAuthorizations
  ) {
    hasAuthToActions = userAuthorizations.hasAuthToActions;
  }

  const editRowHandler = (name?: string) => {
    if (isUpdateLoading) {
      return;
    }

    if (hasAuthToActions && name) {
      if (rowName === name) {
        setRowName('');
      } else {
        setRowName(name);
      }
    }
  };

  const updateObservedIncidentHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (isUpdateLoading) {
      return;
    }

    setUpdatedObservedType(e.target.value);
  };

  const closeObservedTypeEditHandler = () => {
    setRowName('');
  };

  const updateNotesHandler = () => {
    if (isUpdateLoading) {
      return;
    }
    setEditNotes(true);
  };

  const closeUpdateNotesHandler = () => {
    setEditNotes(false);
  };

  const undoEditedTextHandler = () => {
    if (isUpdateLoading) {
      return;
    }

    if (data && typeof data === 'object' && 'notes' in data && data.notes) {
      setUpdatedNotes(data.notes);
    }
  };

  const addOrChangeNotesHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (isUpdateLoading) {
      return;
    }

    setUpdatedNotes(e.target.value);
  };

  const showAddVisitorsFormHandler = () => {
    if (isUpdateLoading) {
      return;
    }

    setAddVisitors((prevState) => !prevState);
  };

  const resetVisitorForm = () => {
    clearName();
    clearSurname();
    clearDepartment();
    clearDuty();
  };

  const addVisitorHandler = () => {
    if (isUpdateLoading) {
      return;
    }

    if (!name && !surname && !department && !duty) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('incidentVisitReport:Visitor_Details_Error_Message')}
          heading={t('incidentVisitReport:Visitor_Details_Error_Heading')}
        />
      );
    }

    if (!name) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:name_error_message')}
          heading={t('forms:name_error_heading')}
        />
      );
    }

    if (!surname) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:surname_error_message')}
          heading={t('forms:surname_error_heading')}
        />
      );
    }

    if (!department) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:department_error_message')}
          heading={t('forms:department_error_heading')}
        />
      );
    }

    if (!duty) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:duty_error_message')}
          heading={t('forms:duty_error_heading')}
        />
      );
    }

    if (visitors.length >= 25) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:visitors_limit_error')}
          heading={t('forms:limit_error_heading')}
        />
      );
    }

    if (name && surname && department && duty) {
      const existingVisitor = visitors.find(
        (visitor) =>
          visitor.name.toLowerCase() === name.toLowerCase() &&
          visitor.surname.toLowerCase() === surname.toLowerCase()
      );

      if (existingVisitor) {
        return confirmAlert({
          title: t('forms:existing_visitor_heading'),
          message: `${t('forms:existing_visitor_message')} ${
            existingVisitor.name
          } ${existingVisitor.surname}. ${t('forms:message_end_question')}`,
          buttons: [
            {
              label: t('incidentDashboard:Yes'),
              onClick: () => {
                const newVisitor = {
                  name,
                  surname,
                  department,
                  duty,
                  id: nanoid(),
                };
                const storedData = [newVisitor, ...visitors];
                localStorage.setItem(
                  'visitors-list',
                  JSON.stringify(storedData)
                );
                setVisitors((prevState) => [newVisitor, ...prevState]);
                resetVisitorForm();
              },
            },
            {
              label: t('incidentDashboard:No'),
              onClick: () => resetVisitorForm(),
            },
          ],
        });
      }

      const newVisitor = { name, surname, department, duty, id: nanoid() };
      const storedData = [newVisitor, ...visitors];
      localStorage.setItem('visitors-list', JSON.stringify(storedData));
      setVisitors((prevState) => [newVisitor, ...prevState]);
      resetVisitorForm();
    }
  };

  const removeVisitorHandler = (id: string) => {
    if (isUpdateLoading) {
      return;
    }

    const newList = visitors.filter((visitor) => visitor.id !== id);

    setVisitors(newList);
  };

  const undoObservedTypeHandler = () => {
    if (isUpdateLoading) {
      return;
    }

    if (
      data &&
      typeof data === 'object' &&
      'observedIncident' in data &&
      data.observedIncident
    ) {
      setUpdatedObservedType(data.observedIncident);
    }
  };

  const isFakeAddButton =
    !isValidName ||
    !isValidSurname ||
    !isValidDuty ||
    !isValidDepartment ||
    name === '' ||
    surname === '' ||
    duty === '' ||
    department === '';

  const updateReportHandler = async () => {
    try {
      let isSameNotes = false;
      let isSameVisitors = false;
      let isSameObservedType = false;
      let visitReportId = '';

      if (
        data &&
        typeof data === 'object' &&
        'observedIncident' in data &&
        typeof data.observedIncident === 'string' &&
        'visitors' in data &&
        data.visitors &&
        Array.isArray(data.visitors) &&
        'notes' in data &&
        typeof data.notes === 'string' &&
        'id' in data &&
        data.id
      ) {
        isSameNotes =
          updatedNotes.trim().toLowerCase() === data.notes.trim().toLowerCase();
        isSameVisitors = isSameVisitorsList({
          existingVisitorsList: data.visitors,
          updatedVisitorsList: visitors,
        });
        isSameObservedType =
          data.observedIncident.trim().toLowerCase() ===
          updatedObservedType.trim().toLowerCase();

        visitReportId = data.id;
      }

      if (!isSameNotes || !isSameVisitors || !isSameObservedType) {
        closeObservedTypeEditHandler();
        closeUpdateNotesHandler();
        setAddVisitors(false);

        const res = await updateVisitReport({
          notes: updatedNotes,
          observedIncident: updatedObservedType,
          visitors,
          contractId,
          contractIncidentId,
          visitReportId,
        }).unwrap();

        if (
          res &&
          typeof res === 'object' &&
          'isSuccess' in res &&
          res.isSuccess
        ) {
          const timer = setTimeout(() => {
            resetVisitorForm();
            onClose();
            clearTimeout(timer);
          }, 4000);

          return toast.success(
            <ToastifyStyledMessage
              singleMessage={t(
                'incidentVisitReport:Visit_Report_Update_Message_Success'
              )}
              heading={t(
                'incidentVisitReport:Visit_Report_Update_Message_Success_Heading'
              )}
            />,
            { autoClose: 5000 }
          );
        }
      } else {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t(
              'incidentVisitReport:Visit_Report_Update_Message_Error'
            )}
            heading={t(
              'incidentVisitReport:Visit_Report_Update_Message_Error_Heading'
            )}
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  if (
    data &&
    typeof data === 'object' &&
    'contractIncidentId' in data &&
    data.contractIncidentId &&
    'contractId' in data &&
    data.contractId &&
    typeof data.contractIncidentId === 'string' &&
    typeof data.contractId === 'string' &&
    !isLoading &&
    !isError &&
    isSuccess
  ) {
    showUpdateButton =
      (updatedObservedType !== '' &&
        updatedObservedType !== data.observedIncident) ||
      (updatedNotes !== '' && updatedNotes !== data.notes) ||
      !isSameVisitorsList({
        existingVisitorsList: data.visitors,
        updatedVisitorsList: visitors,
      });

    let visitorsContent = (
      <BorderedSectionContainer
        heading={t('incidentVisitReport:Incident_Site_Visitors')}
        onAction={showAddVisitorsFormHandler}
        isActionStart={addVisitors}
        actionStartLabel={t('incidentVisitReport:Add_Visitors')}
        hasActionAuth={hasAuthToActions}
        isLoading={isUpdateLoading}
      >
        <NoDataFound message={t('incidentVisitReport:No_Visitors_Added')} />
      </BorderedSectionContainer>
    );

    if (addVisitors) {
      visitorsContent = (
        <AddVisitorsForm
          handler={{
            nameEntryHandler,
            surnameEntryHandler,
            departmentEntryHandler,
            dutyEntryHandler,
            resetVisitorForm,
            addVisitorHandler,
            removeVisitorHandler,
          }}
          validators={{
            isValidName,
            isValidSurname,
            isValidDuty,
            isValidDepartment,
            isFakeAddButton,
          }}
          value={{ name, surname, department, duty }}
          visitors={visitors}
          isLoading={isUpdateLoading}
          heading={t('incidentVisitReport:Add_Visitors')}
          listHeading={t('incidentVisitReport:Added_Visitors')}
          information={t('incidentVisitReport:Add_Visitors_Information')}
          actionStartLabel='Add Visitors'
          hasActionAuth={hasAuthToActions}
          isActionStart={addVisitors}
          onAction={showAddVisitorsFormHandler}
        />
      );
    } else if (visitors.length > 0 && !addVisitors) {
      visitorsContent = (
        <BorderedSectionContainer
          heading={t('incidentVisitReport:Incident_Site_Visitors')}
          actionStartLabel={t('incidentVisitReport:Add_Visitors')}
          hasActionAuth={hasAuthToActions}
          isActionStart={addVisitors}
          onAction={showAddVisitorsFormHandler}
          isLoading={isUpdateLoading}
        >
          <div className={classes.VisitorsList}>
            <HeadingBar
              heading={t('incidentVisitReport:Added_Visitors')}
              headingSize={4}
              icon={<IoPerson />}
              className={classes.VisitorsHeading}
              headingType='orange'
            />
            {!isUpdateLoading ? (
              <ul>
                {visitors.map((visitor, index) => (
                  <VisitorsListItem
                    key={visitor.id}
                    visitorDetails={visitor}
                    orderNumber={index + 1}
                    onRemove={removeVisitorHandler}
                    isAuthorized={hasAuthToActions}
                  />
                ))}
              </ul>
            ) : (
              <div className={classes.VisitorsListLoadingContainer}>
                <SimpleLoading />
              </div>
            )}
          </div>
        </BorderedSectionContainer>
      );
    }

    content = (
      <ReportContainer>
        {pdfDoc}
        <IncidentDetailsSection
          selectedIncident={selectedIncident}
          isLoading={isUpdateLoading}
        />
        <BorderedSectionContainer
          heading={t('incidentVisitReport:General_Info')}
        >
          <EditableRowData
            isEditable={false}
            label={t('incidentVisitReport:Report_Created_At')}
            rowData={new Date(data.createdAt).toLocaleDateString(language, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })}
            rowIcon={<GrDocumentUpdate />}
          />

          <EditableRowData
            isEditable={false}
            label={t('incidentVisitReport:Reporter_Name')}
            rowData={data.visitReportedBy.name}
            rowIcon={<FaUser />}
          />
          <EditableRowData
            isEditable={hasAuthToActions && !isUpdateLoading}
            label={t('incidentVisitReport:Observed_Incident_Type')}
            rowData={updatedObservedType}
            rowIcon={<PiDetectiveFill />}
            name='observed-incident-type'
            edit={rowName === 'observed-incident-type'}
            closeIcon={
              <IoClose
                style={{ cursor: 'pointer' }}
                onClick={closeObservedTypeEditHandler}
              />
            }
            onClick={editRowHandler}
            onChange={updateObservedIncidentHandler}
            onUndo={undoObservedTypeHandler}
          />
        </BorderedSectionContainer>

        <BorderedSectionContainer
          heading={t('incidentVisitReport:Notes_About_Visit')}
        >
          <NoteContainer
            onCloseUpdateNotes={closeUpdateNotesHandler}
            onUpdateNotes={updateNotesHandler}
            onUndoEdit={undoEditedTextHandler}
            updatedNotes={updatedNotes}
            willEditNotes={editNotes}
            hasAuthToActions={hasAuthToActions}
          >
            <TextArea
              value={updatedNotes}
              name='edit-notes'
              label={t('incidentVisitReport:Edit_Notes')}
              maxLength={1500}
              invalid={!isValidNotes}
              onChange={addOrChangeNotesHandler}
              title=''
              errorMessage=''
              showRemaining
              style={{ paddingRight: '6rem' }}
            />
          </NoteContainer>
        </BorderedSectionContainer>
        {visitorsContent}

        {showUpdateButton && hasAuthToActions ? (
          <ActionContainer setup={{ justifyContent: 'flex-end' }}>
            <StandardButton
              label={t('incidentVisitReport:Update_Report')}
              btnType='orange'
              onClick={updateReportHandler}
              fakeButton={isUpdateLoading}
            />
          </ActionContainer>
        ) : null}
      </ReportContainer>
    );
  }

  const closeFormHandler = () => {
    onClose();
    setRowName('');
    resetVisitorForm();
    setEditNotes(false);
    closeObservedTypeEditHandler();
    setAddVisitors(false);
    undoObservedTypeHandler();
    undoEditedTextHandler();
    if (
      data &&
      typeof data === 'object' &&
      'visitors' in data &&
      data.visitors &&
      Array.isArray(data.visitors) &&
      data.visitors.length > 0
    ) {
      setVisitors(data.visitors);
    }
  };

  return (
    <FormModal
      show={show}
      heading={t('incidentVisitReport:Incident_Visit_Report')}
      onClick={closeFormHandler}
    >
      <Fragment>{content}</Fragment>
    </FormModal>
  );
};

export default IncidentVisitReport;
