import {
  IContractNotification,
  INotificationDetailsResponse,
  IUData,
} from '../../tsTypes/interfaces';
import { IResponseData } from '../../tsTypes/types.model';
import getHeaders from '../../utils/getHeaders';
import { periopsisApi } from '../api/apiSlice';

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getContractNotifications: builder.query({
      query: (contractId: string) => {
        const headers = getHeaders();

        return {
          url: `/notifications/${contractId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          unreadNotifications: IContractNotification[];
          readNotifications: IContractNotification[];
          uData: IUData;
          pToken: string;
        }>
      ) => {
        const { unreadNotifications, readNotifications, uData, pToken } =
          responseData.data;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return { unreadNotifications, readNotifications };
      },
      providesTags: ['Notifications'],
    }),
    updateNotificationStatus: builder.mutation({
      query: (reqData: { notificationId: string; contractId: string }) => {
        const headers = getHeaders();

        const { notificationId, contractId } = reqData;

        return {
          url: `/notifications/${notificationId}`,
          method: 'PATCH',
          headers,
          body: {
            contractId,
          },
        };
      },
      invalidatesTags: ['Notifications'],
    }),
    getNotificationDetails: builder.query({
      query: (requestData: { contractId: string; notificationId: string }) => {
        const { contractId, notificationId } = requestData;
        const headers = getHeaders();

        return {
          url: `/notifications/details/${notificationId}/${contractId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          notificationDetails: INotificationDetailsResponse;
        }>
      ) => {
        const notificationDetails = responseData.data.notificationDetails;

        return notificationDetails;
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetContractNotificationsQuery,
  useUpdateNotificationStatusMutation,
  useLazyGetNotificationDetailsQuery,
} = extendedApiSlice;
