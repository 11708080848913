import React from 'react';
import RadioButtonDot from '../../../../../../../UI/RadioButtonDot/RadioButtonDot';
import classes from './PaymentTypeOption.module.scss';
import { paymentType } from '../../../../../../../../tsTypes/types.components';

interface IProps {
  optionName: string;
  isSelected: boolean;
  onSelect: (selectionId: paymentType) => void;
  selectionId: paymentType;
}

const PaymentTypeOption = ({
  optionName,
  isSelected,
  selectionId,
  onSelect,
}: IProps) => {
  const selectPaymentTypeHandler = () => {
    if (
      selectionId &&
      typeof selectionId === 'string' &&
      onSelect &&
      typeof onSelect === 'function'
    ) {
      onSelect(selectionId);
    }
  };

  return (
    <button
      className={`${classes.PaymentTypeOption} ${
        isSelected ? classes.Selected : ''
      }`}
      onClick={selectPaymentTypeHandler}
    >
      <RadioButtonDot isSelected={isSelected} /> {optionName}
    </button>
  );
};

export default PaymentTypeOption;
