import { ChangeEvent, useState } from 'react';
import { IOrganizationUser } from '../../../../../tsTypes/interfaces';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import { confirmAlert } from 'react-confirm-alert';
import { MdOutlineInfo } from 'react-icons/md';
import classes from './OrganizationUserItem.module.scss';
import DataRow from '../../../../UI/DataRow/DataRow';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';

interface IProps {
  user: IOrganizationUser;
  onRemove: (userId: string) => void;
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
}

const OrganizationUserItem = ({ user, onRemove, onSelect }: IProps) => {
  const [showInfo, setShowInfo] = useState(false);

  if (user.isUserOfContract) {
    return null;
  }

  const removeUserFromOrganizationUsersHandler = (userId: string) => {
    const contractData = user.assignedContractsData;

    const removeButtons = [
      { label: 'Proceed', onClick: () => onRemove(userId) },
      { label: 'Cancel' },
    ];

    const notAllowedButton = [{ label: 'Ok' }];

    const onlyAdminContracts = contractData.filter(
      (contract) => contract.isOnlyAdminUser
    );
    const soleUser = contractData.filter((contract) => contract.isSoleUser);
    const onlyActionContracts = contractData.filter(
      (contract) => contract.isOnlyActionUser
    );

    const isUserOfAContract = contractData.filter(
      (contract) => contract.isUserAtOtherContracts
    );

    if (onlyAdminContracts.length === 1) {
      const contractId = onlyAdminContracts[0].contractId;
      const serviceType = onlyAdminContracts[0].serviceType;

      return confirmAlert({
        title: 'Not Allowed',
        message: `${user.user.name} ${
          user.user.surname
        } is the sole admin user for Contract ID starts with ${contractId.substring(
          0,
          12
        )} under the service type ${serviceType}. To remove this user from the organization users list, please assign another admin user to the contract either yourself or through the contract administrator. Failure to do so may impact administrative access to critical functions. Thank you for your prompt attention to this matter. `,
        buttons: notAllowedButton,
      });
    }

    if (onlyAdminContracts.length > 1) {
      return confirmAlert({
        title: 'Not Allowed',
        message: `${user.user.name} ${user.user.surname} is the sole admin user for a total of ${onlyAdminContracts.length} contracts. Removing this user from the organization users list will break and prevent access to those contracts. Before proceeding with the removal, please ensure that at least one new admin user is assigned to each of these contracts to maintain administrative access and functionality. Failure to assign a new admin user may result in the disruption of critical services associated with these contracts.`,
        buttons: notAllowedButton,
      });
    }

    if (soleUser.length > 0) {
      const message =
        soleUser.length > 1
          ? `${user.user.name} ${user.user.surname} is the sole user of a total of ${soleUser.length} contracts. Removing ${user.user.name} ${user.user.surname} from the organization users list will break the entry and usage of those contracts. To proceed with the removal, you or the administrator of those contracts should assign new users to the related contracts. Importantly, for each contract, at least one admin user should be assigned to ensure continued access and functionality. Failure to assign new users, especially admin users, may result in the disruption of critical services associated with these contracts. `
          : `${user.user.name} ${user.user.surname} is the sole user of the contract under the service type "Dumping Detection." Removing ${user.user.name} ${user.user.surname} from the organization users list will break the entry and usage of this contract. To proceed with the removal, you must assign a new user or users to the related contract. Importantly, at least one of these users should be designated as an admin user to ensure continued access and functionality. Failure to assign a new admin user may result in the disruption of critical services associated with this contract.`;

      return confirmAlert({
        title: 'Not Allowed',
        message,
        buttons: notAllowedButton,
      });
    }

    if (onlyActionContracts.length > 0) {
      const message =
        onlyActionContracts.length > 1
          ? `${user.user.name} ${user.user.surname} is the sole action user for a total of ${onlyActionContracts.length} contracts. Removing this user from the organization users list will prevent the application of report actions at the Incidents Dashboard section. To ensure the continued functionality of these contracts, it is strongly suggested to assign another user or users for actions before proceeding with the removal of ${user.user.name} ${user.user.surname} from the organization users list. Are you sure you would like to proceed with this removal process?`
          : `${user.user.name} ${user.user.surname} is the sole action user of ${onlyActionContracts[0].serviceType} contract. Removing this user from the organization users list will prevent the application of report actions at the Incident Dashboard of ${onlyActionContracts[0].serviceType} contract. To ensure the continued functionality of ${onlyActionContracts[0].serviceType} contracti it is strogly suggested to assign another user or users for action before proceeding with the removal of ${user.user.name} ${user.user.surname} from the organization users list. Are you sure you would like to proceed with this removal process?`;

      return confirmAlert({
        title: 'Removal Warning',
        message,
        buttons: removeButtons,
      });
    }

    if (user.isUserOfContract) {
      return confirmAlert({
        title: 'User Removal',
        message: `You are about to remove ${user.user.name} ${user.user.surname} from the organization users list. This action will result in the removal of the user from the Current Contract Users and the Periopsis System. Consequently, you will no longer be able to assign this user to any contract. If your intention is to remove the user from the current contract only, please use the remove action in the Current Users of Contract list above. Are you sure about proceeding with this process?`,
        buttons: removeButtons,
      });
    }

    if (isUserOfAContract.length > 1 && !user.isUserOfContract) {
      const message =
        isUserOfAContract.length >= 2
          ? `You are about to remove ${user.user.name} ${user.user.surname} from the organization users list. ${user.user.name} ${user.user.surname} is associated with ${isUserOfAContract.length} other contracts. Removing the user from the organization users list will consequently remove them from those contracts as well. If you are not an administrator of those other contracts, it is strongly suggested that you reach out to the administrators of those contracts before proceeding with the removal. Are you sure about this process?`
          : `You are about to remove ${user.user.name} ${user.user.surname} from the organization users list. ${user.user.name} ${user.user.surname} is associated with another contract. Removing the use from the organization users list will consequently remove the user from the ${isUserOfAContract[0].serviceType} contract as well. If you are not an administrator of ${isUserOfAContract[0].serviceType} contract, it is strogly suggested that you reach out to the administrator of contract before proceeding with the removal. Are you sure about this process?`;

      return confirmAlert({
        title: 'User Removal',
        message,
        buttons: removeButtons,
      });
    }

    return confirmAlert({
      title: 'User Removal',
      message: `You are about to remove ${user.user.name} ${user.user.surname} from the organization users list. This action will entirely remove the user from the Periopsis System, requiring another registration process to assign this user to any contract in the future. Are you sure about proceeding with this removal process?`,
      buttons: removeButtons,
    });
  };

  const showAssignedByInfo = () => {
    setShowInfo((prevState) => !prevState);
  };

  return (
    <tr>
      <td>{user.user.name}</td>
      <td>{user.user.surname}</td>
      <td>{user.user.emailAddress}</td>
      <td>{user.user.contractsAssigned.length}</td>
      <td>
        <input
          type='checkbox'
          name={user.user.name}
          value={user.user.userId}
          onChange={onSelect}
          checked={user.isUserOfContract}
        />
      </td>
      <td className={classes.AssignedByInfo}>
        <button
          type='button'
          onMouseEnter={showAssignedByInfo}
          onMouseLeave={showAssignedByInfo}
        >
          <MdOutlineInfo />
        </button>
        {showInfo ? (
          <div className={classes.InfoBox}>
            <HeadingBar heading='User Assignment Information' headingSize={3} />
            <div className={classes.DataContent}>
              <DataRow
                heading='Name'
                data={`${user.user.name} ${user.user.surname}`}
                removeSeperator
              />
              <DataRow
                heading='Organization'
                data={user.organizationName}
                removeSeperator
              />
              <DataRow
                heading='Assigned By'
                data={
                  user.user.userId === user.addedBy.id
                    ? 'Contract Assignment Upon Registration'
                    : `${user.addedBy.name} ${user.addedBy.surname}`
                }
                removeSeperator
              />
            </div>
          </div>
        ) : null}
      </td>
      <td>
        <StandardButton
          type='button'
          btnType='orange'
          label='Remove'
          onClick={() =>
            removeUserFromOrganizationUsersHandler(user.user.userId)
          }
        />
      </td>
    </tr>
  );
};

export default OrganizationUserItem;
