import Dropdown from '../../../../../UI/FormElements/Dropdown/Dropdown';
import { IIncidentType } from '../../../../../../tsTypes/interfaces';
import { IListObject } from '../../../../../../tsTypes/types.model';
import classes from './IncidentTypeFiltering.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllIncidentValues,
  setSelectedIncidentType,
} from '../../../../../../features/incidents/incidentsSlice';
import { AppDispatch } from '../../../../../../app/store';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

interface IProps {
  incidentTypes: IIncidentType[];
}

const IncidentTypeFiltering = ({ incidentTypes }: IProps) => {
  const { selectedIncidentTypeId } = useSelector(selectAllIncidentValues);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  let list: IListObject[] = [];

  useEffect(() => {
    const selectedIncidentType = localStorage.getItem('selected-incident-type');

    if (selectedIncidentType) {
      dispatch(setSelectedIncidentType(selectedIncidentType));
    }
  }, [dispatch]);

  if (
    !incidentTypes ||
    !Array.isArray(incidentTypes) ||
    incidentTypes.length === 0
  ) {
    return null;
  }

  list = incidentTypes.map((incType) => ({
    id: incType.incidentTypeId,
    title: t(`dashboard:${getTranslationKey(incType.incidentType)}`),
    value: incType.incidentTypeId,
  }));

  const selectIncidentTypeHandler = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = e.target;
    let selected = value;

    if (
      value !== 'J7r6HlE3WVBX1SJdlnnrc2gsFrLVoOJt-0001' &&
      value !== 'Gh5tRfvEiP439qetKKkZAPVoRRnBu8Qn-0002' &&
      value !== 'HzfOWRjblituU1uYwoAOMkAIdJh8x9Lj-0003' &&
      value !== 'KrVCHW2gErZaGjNRRScJNXwPqVTJzSEq-0004' &&
      value !== 'IYjKB36x10fhTTn1YSJnRjxsIgh2hUcT-0005'
    ) {
      selected = '';
    }

    dispatch(setSelectedIncidentType(selected));
  };

  return (
    <div className={classes.IncidentTypesContainer}>
      <Dropdown
        optionsList={list}
        defaultValue={t('dashboard:all_incident_types')}
        name='incident-types'
        value={selectedIncidentTypeId}
        onSelect={selectIncidentTypeHandler}
        title='Filter incidents by incident type (e.g. Likely Dump)'
      />
    </div>
  );
};

export default IncidentTypeFiltering;
