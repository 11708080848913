import { Fragment } from 'react';
import { IVisitor } from '../../../../../../tsTypes/interfaces';
import ListItemContainer from '../../../../../UI/ListItemContainer/ListItemContainer';
import RemoveButton from '../../../../../UI/RemoveButton/RemoveButton';
import classes from './VisitorsListItem.module.scss';
import DataRow from '../../../../../UI/DataRow/DataRow';

interface IProps {
  visitorDetails: IVisitor;
  orderNumber: number;
  onRemove: (id: string) => void;
  isAuthorized: boolean;
}

const VisitorsListItem = ({
  visitorDetails,
  orderNumber,
  onRemove,
  isAuthorized,
}: IProps) => {
  if (!visitorDetails || typeof visitorDetails !== 'object') {
    return null;
  }

  const { name, surname, department, duty, id } = visitorDetails;

  return (
    <Fragment>
      <ListItemContainer sections={4}>
        <p>{orderNumber}</p>
        <p>{name}</p>
        <p>{surname}</p>
        <p>{department}</p>
        <p>{duty}</p>
        <RemoveButton
          onRemove={onRemove}
          id={id}
          isAuthorized={isAuthorized}
          title='Remove from the list'
        />
      </ListItemContainer>
      <li className={classes.MobileListItem}>
        <DataRow heading='Name' data={`${name} ${surname}`} removeSeperator />
        <DataRow heading='Department' data={`${department}`} removeSeperator />
        <DataRow heading='Duty' data={`${duty}`} removeSeperator />
        <div className={classes.ActionSection}>
          <RemoveButton
            onRemove={onRemove}
            id={id}
            isAuthorized={isAuthorized}
            title='Remove from the list'
          />
        </div>
      </li>
    </Fragment>
  );
};

export default VisitorsListItem;
