import SkeletonLoading from '../../../UI/SkeletonLoading/SkeletonLoading';
import ListItemLoader from '../Incidents/ListItemLoader/ListItemLoader';
import IncidentContentContainer from '../IncidentContentContainer/IncidentContentContainer';
import classes from './DashboardInitLoader.module.scss';

const DashboardInitLoader = () => {
  return (
    <div className={classes.DashboardContainer}>
      <div className={classes.FilterMainContainer}>
        <SkeletonLoading width={'100%'} height={'9rem'} />
      </div>
      <div className={classes.ListWrapper}>
        <IncidentContentContainer>
          <div className={classes.IncidentListContainer}>
            <div>
              <ListItemLoader />
            </div>
            <div>
              <ListItemLoader />
            </div>
            <div>
              <ListItemLoader />
            </div>
            <div>
              <ListItemLoader />
            </div>
            <div>
              <ListItemLoader />
            </div>
            <div>
              <ListItemLoader />
            </div>
            <div>
              <ListItemLoader />
            </div>
            <div>
              <ListItemLoader />
            </div>
          </div>
        </IncidentContentContainer>
      </div>
    </div>
  );
};

export default DashboardInitLoader;
