import { Fragment, useState } from 'react';
import { formatDistance } from 'date-fns';
import { enGB, el } from 'date-fns/locale';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  clearAuthData,
  loggedInUser,
  selectAllAuthValues,
  useSendVerificationCodeMutation,
  useUpdateUserPasswordMutation,
  useUpdateUsernameMutation,
} from '../../../features/auth/authSlice';
import NoData from '../../UI/NoData/NoData';
import Form from '../../UI/FormElements/Form/Form';
import EditableRowData from '../../UI/EditableRowData/EditableRowData';
import { FaUserAlt, FaUserEdit } from 'react-icons/fa';
import { AiTwotoneMail } from 'react-icons/ai';
import { ImLocation } from 'react-icons/im';
import { BiRegistered, BiLogInCircle } from 'react-icons/bi';
import { RiLockPasswordFill } from 'react-icons/ri';
import FormModal from '../../UI/FormModal/FormModal';
import NewUsernameForm from './NewUsernameForm/NewUsernameForm';
import NewPasswordForm from './NewPasswordForm/NewPasswordForm';
import Loading from '../../UI/Loading/Loading';
import {
  IUpdatePassword,
  IUpdateUsername,
  IUpdateUsernameQuery,
} from '../../../tsTypes/types.components';
import VerificationCodeForm from './VerificationCodeForm/VerificationCodeForm';
import { AppDispatch } from '../../../app/store';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import ColumnDataContainer from '../../UI/ColumnDataContainer/ColumnDataContainer';
import { frontendConstants } from '../../../constants/constants';
import useToken from '../../../CustomHooks/useToken';
import queryErrorCatch from '../../../utils/queryErrorCatch';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../utils/getTranslationKey';

const User = () => {
  const { user, isAuthenticated, hasToken, isLoading } =
    useSelector(selectAllAuthValues);
  const { pToken, token, userId } = useToken({ giveUserId: true });
  const [editCredential, setEditCredential] = useState(false);
  const [isUsername, setIsUsername] = useState(false);
  const [passwordData, setPasswordData] = useState<IUpdatePassword | null>(
    null
  );
  const [usernameData, setUsernameData] = useState<IUpdateUsername | null>(
    null
  );
  const [isEmailVerificationForm, setIsEmailVerificationForm] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  let content: React.ReactNode = null;

  const [sendVerificationCodeM, { isLoading: isSendingVerificationCode }] =
    useSendVerificationCodeMutation();
  const [updatePassword, { isLoading: isUpdatingPassword }] =
    useUpdateUserPasswordMutation();
  const [updateUsername, { isLoading: isUpdatingUsername }] =
    useUpdateUsernameMutation();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  if (!user && !isAuthenticated && !hasToken && !isLoading) {
    return <Navigate to={frontendConstants.pages.login} replace />;
  }

  if (!user && !isLoading) {
    return <NoData message={t('profilesettings:no_user_data_found')} />;
  }

  const editCredentialDataHandler = (name: string | undefined) => {
    if (name === 'username') {
      setIsUsername(true);
    }
    setEditCredential(true);
  };

  const closeModalHandler = () => {
    setEditCredential(false);
    setIsUsername(false);
    setPasswordData(null);
    setIsEmailVerificationForm(false);
  };

  const newUsernameStoreHandler = async (formData: IUpdateUsername) => {
    console.log(usernameData);

    if (
      !usernameData &&
      formData &&
      'password' in formData &&
      formData['password'].trim().length >= 6 &&
      'username' in formData &&
      formData['username'].trim().length >= 5
    ) {
      setUsernameData(formData);

      if (token && pToken && userId) {
        const password = formData['password'];

        try {
          const res = await sendVerificationCodeM({
            userId,
            password,
          }).unwrap();

          if (
            res &&
            typeof res === 'object' &&
            'isSuccess' in res &&
            res.isSuccess
          ) {
            setIsEmailVerificationForm(true);
            return toast.success(
              <ToastifyStyledMessage
                heading={t('profilesettings:verification_code')}
                singleMessage={t('profilesettings:verification_code_message')}
              />
            );
          }
        } catch (error) {
          setUsernameData(null);
          return queryErrorCatch(error);
        }

        // dispatch(sendVerificationCode({ password, userId, token, pToken }));
      }
    }
  };

  //stores the passwords at state and opens the email verification form
  //also will dispatch the sendValidationCode to send validation code to the email of the user
  const newPasswordStoreHandler = async (formData: IUpdatePassword) => {
    if (
      !passwordData &&
      formData &&
      'oldPassword' in formData &&
      formData['oldPassword'].trim().length >= 6 &&
      'password' in formData &&
      formData['password'].trim().length >= 6 &&
      'confirmPassword' in formData &&
      formData['password'] === formData['confirmPassword']
    ) {
      setPasswordData(formData);

      if (token && pToken && userId) {
        const password = formData.oldPassword;

        try {
          const res = await sendVerificationCodeM({
            userId,
            password,
          }).unwrap();

          if (
            res &&
            typeof res === 'object' &&
            'isSuccess' in res &&
            res.isSuccess
          ) {
            setIsEmailVerificationForm(true);
            return toast.success(
              <ToastifyStyledMessage
                heading={t('profilesettings:verification_code')}
                singleMessage={t('profilesettings:verification_code_message')}
              />
            );
          }
        } catch (error) {
          setPasswordData(null);
          return queryErrorCatch(error);
        }
      }
    }
  };

  const usernameSubmitHandler = async (code: string) => {
    if (
      code &&
      usernameData &&
      code.trim().length > 0 &&
      code.trim().length < 9
    ) {
      if (
        pToken &&
        token &&
        userId &&
        usernameData.password &&
        usernameData.username
      ) {
        try {
          const updateData: IUpdateUsernameQuery = {
            pToken,
            token,
            password: usernameData.password,
            username: usernameData.username,
            userId,
            validationCode: code,
          };

          const res = await updateUsername(updateData).unwrap();

          if (
            res &&
            typeof res === 'object' &&
            'isSuccess' in res &&
            res.isSuccess
          ) {
            setUsernameData(null);
            setEditCredential(false);
            setIsEmailVerificationForm(false);
            setIsUsername(false);
            setPasswordData(null);
            dispatch(loggedInUser({ token, pToken }));
            return toast.success(
              <ToastifyStyledMessage
                heading={t('profilesettings:username_updated')}
                singleMessage={t('profilesettings:username_updated_success')}
              />
            );
          }
        } catch (error) {
          return queryErrorCatch(error);
        }
      }
    }
  };

  const passwordSubmitHandler = async (code: string) => {
    //we will send oldPassword, password (newPassword), confirmPassword (backend confirm password checks password & confirmPassword)
    //Validate password checks password at req.body

    if (
      code &&
      passwordData &&
      code.trim().length > 0 &&
      code.trim().length < 9
    ) {
      const uData = localStorage.getItem('uData');
      const pToken = localStorage.getItem('pToken');
      // setVerificationCode(code);

      if (!uData || !pToken) {
        dispatch(clearAuthData());
        localStorage.clear();
        window.location.reload();
      }

      if (uData && pToken) {
        const userData = JSON.parse(uData);

        const token = userData.token;
        const id = userData.id;
        const oldPassword = passwordData.oldPassword;
        const password = passwordData.password;
        const confirmPassword = passwordData.confirmPassword;

        try {
          const res = await updatePassword({
            token,
            pToken,
            userId: id,
            password,
            oldPassword,
            confirmPassword,
            validationCode: code,
          }).unwrap();

          if (
            res &&
            typeof res === 'object' &&
            'isSuccess' in res &&
            res.isSuccess
          ) {
            // setVerificationCode(null);
            setPasswordData(null);
            setEditCredential(false);
            setIsEmailVerificationForm(false);
            setIsUsername(false);
            setUsernameData(null);
            return toast.success(
              <ToastifyStyledMessage
                heading={t('profilesettings:password_updated')}
                singleMessage={t('profilesettings:password_updated_success')}
              />
            );
          }
        } catch (error) {
          return queryErrorCatch(error);
        }
      }
    }
  };

  if (isLoading && !user) {
    content = <Loading showBackDrop={false} />;
  }

  if (user) {
    content = (
      <Fragment>
        <Form>
          <EditableRowData
            rowData={`${user.name} ${user.surname}`}
            rowIcon={<FaUserAlt />}
            label={t('translations:Name')}
            optionalText={t('profilesettings:name_update_description')}
          />
          <EditableRowData
            rowData={`${user.email}`}
            rowIcon={<AiTwotoneMail />}
            label={t('auth:email')}
            optionalText={t('profilesettings:email_update_description')}
          />
          <EditableRowData
            rowData={user.username}
            label={t('profilesettings:username')}
            name='username'
            rowIcon={<FaUserEdit />}
            isEditable
            onClick={editCredentialDataHandler}
            optionalText={t('profilesettings:username_update_description')}
          />
          <EditableRowData
            rowData={
              user.address && user.address.countryDetails
                ? t(
                    `auth:${getTranslationKey(
                      user.address.countryDetails.country
                    )}`
                  )
                : ''
            }
            label={t('auth:country')}
            rowIcon={<ImLocation />}
            optionalText={t('profilesettings:country_update_description')}
          />
          <EditableRowData
            rowData={formatDistance(
              new Date(user.createdAt as string),
              new Date(),
              {
                addSuffix: true,
                locale:
                  language === 'en' ? enGB : language === 'el' ? el : enGB,
              }
            )}
            label={t('profilesettings:registered')}
            rowIcon={<BiRegistered />}
            optionalText={t('profilesettings:registered_description')}
          />
          <EditableRowData
            rowData={formatDistance(
              new Date(user.lastLoggedInAt as string),
              new Date(),
              {
                addSuffix: true,
                locale:
                  language === 'en' ? enGB : language === 'el' ? el : enGB,
              }
            )}
            label={t('profilesettings:logged_in')}
            rowIcon={<BiLogInCircle />}
            optionalText={t('profilesettings:logged_in_desc')}
          />
          <EditableRowData
            rowData={'**************'}
            label={t('auth:password')}
            rowIcon={<RiLockPasswordFill />}
            isEditable
            name='password'
            onClick={editCredentialDataHandler}
            optionalText={t('profilesettings:password_update_desc')}
          />
        </Form>
        <FormModal show={editCredential} onClick={closeModalHandler}>
          {isUsername && !isEmailVerificationForm ? (
            <NewUsernameForm
              onSubmit={newUsernameStoreHandler}
              isVerificationLoading={isSendingVerificationCode}
              shouldReset={!editCredential}
            />
          ) : !isUsername && !isEmailVerificationForm ? (
            <NewPasswordForm
              onSubmit={newPasswordStoreHandler}
              isLoading={isSendingVerificationCode}
              shouldReset={!editCredential}
            />
          ) : (
            <VerificationCodeForm
              onSubmit={
                isUsername ? usernameSubmitHandler : passwordSubmitHandler
              }
              isLoading={isUpdatingPassword || isUpdatingUsername}
              shouldClear={!editCredential}
            />
          )}
        </FormModal>
      </Fragment>
    );
  }

  return (
    <ColumnDataContainer
      heading={t('profilesettings:general_profile_settings')}
      isNarrow
    >
      {content}
    </ColumnDataContainer>
  );
};

export default User;
