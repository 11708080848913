import { IContractNotification } from '../../../../tsTypes/interfaces';
import { FaEnvelope, FaEnvelopeOpen } from 'react-icons/fa6';
import NotificationDetailsMobile from '../NotificationDetailsMobile/NotificationDetailsMobile';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useLazyGetNotificationDetailsQuery } from '../../../../features/notifications/notificationsSlice';
import classes from './NotificationListItem.module.scss';

interface IProps {
  notification: IContractNotification;
}

const NotificationListItem = ({ notification }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedNotification = searchParams.get('notification');

  const [getNotificationDetails] = useLazyGetNotificationDetailsQuery();

  const contractId =
    notification && typeof notification === 'object'
      ? notification.contractId
      : '';
  const notificationId =
    notification && typeof notification === 'object' ? notification.id : '';

  useEffect(() => {
    if (selectedNotification === notificationId && contractId) {
      const timer = setTimeout(() => {
        getNotificationDetails(
          {
            notificationId: selectedNotification,
            contractId,
          },
          true
        );
      }, 1200);

      return () => clearTimeout(timer);
    }

    if (selectedNotification === '') {
      setSearchParams({});
    }

    // eslint-disable-next-line
  }, [selectedNotification, contractId, notificationId]);

  const selectedNotificationHandler = (selectedNotificationId: string) => {
    if (selectedNotificationId) {
      setSearchParams({ notification: selectedNotificationId });
    } else {
      setSearchParams({});
    }
  };

  let heading = (
    <h2>
      {notification.contractIncidents.length > 0
        ? notification.contractIncidents.length
        : ''}{' '}
      {notification.subject}
    </h2>
  );

  if (
    notification.contractIncidents.length === 1 &&
    notification.subject === 'New Incidents'
  ) {
    heading = <h2>{notification.contractIncidents.length} New Incident</h2>;
  }

  return (
    <div
      className={`${classes.ListItemWrapper} ${
        selectedNotification === notification.id ? classes.Selected : ''
      }`}
    >
      <button
        className={classes.NotificationListItemContainer}
        onClick={() => selectedNotificationHandler(notification.id)}
        onFocus={() => selectedNotificationHandler(notification.id)}
      >
        <div className={classes.NotificationContent}>
          <div className={classes.MainContent}>
            <span
              className={`${classes.Envelope} ${
                notification.hasSeen ? classes.Seen : ''
              }`}
            >
              {notification.hasSeen ? <FaEnvelopeOpen /> : <FaEnvelope />}
            </span>
            <div className={classes.Subject}>
              {heading}
              <data>
                {new Date(notification.date).toLocaleDateString('el', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </data>
            </div>
          </div>
          <div
            className={`${classes.NotificationDot} ${
              notification.hasSeen ? classes.Seen : ''
            }`}
          ></div>
        </div>
      </button>

      <NotificationDetailsMobile
        notificationId={notification.id}
        contractId={notification.contractId}
      />
    </div>
  );
};

export default NotificationListItem;
