import { useDispatch, useSelector } from 'react-redux';
import SideMenuLinks from '../SideMenuDesktop/SideMenuLinks/SideMenuLinks';
import Logo from '../Logo/Logo';
import classes from './SideDrawer.module.scss';
import { AppDispatch } from '../../../app/store';
import {
  selectAllUIValues,
  setShowMobileMenu,
  setShowUserNavMenu,
} from '../../../features/UI/uiSlice';
import UserBadge from '../UserBadge/UserBadge';
import { useEffect } from 'react';
import NavHomeLinks from '../Navbar/NavHomeLinks/NavHomeLinks';
import { selectAllAuthValues } from '../../../features/auth/authSlice';

const SideDrawer = () => {
  const { showMobileMenu, showNavUserMenu } = useSelector(selectAllUIValues);
  const { isAuthenticated } = useSelector(selectAllAuthValues);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (showMobileMenu) {
      document.body.classList.add('stop-scrolling-on-modal');
    } else {
      document.body.classList.remove('stop-scrolling-on-modal');
    }
  }, [showMobileMenu]);

  const menuLinkSelectHandler = () => {
    dispatch(setShowMobileMenu());
  };

  const showMenuHandler = () => {
    if (!showNavUserMenu) {
      dispatch(setShowUserNavMenu(true));
    } else {
      dispatch(setShowUserNavMenu(false));
    }
  };

  return (
    <nav
      className={`${classes.SideDrawer} ${showMobileMenu ? classes.Open : ''}`}
    >
      <div className={classes.BrandingSection}>
        <div className={classes.LogoContainer}>
          <Logo />
        </div>
        <div className={classes.UserContainer}>
          <UserBadge onClick={showMenuHandler} />
        </div>
      </div>
      <div className={classes.MenuSection}>
        {isAuthenticated && (
          <SideMenuLinks isExpanded onSelect={menuLinkSelectHandler} />
        )}
        {!isAuthenticated && <NavHomeLinks onSelect={menuLinkSelectHandler} />}
      </div>
    </nav>
  );
};

export default SideDrawer;
