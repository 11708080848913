import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, Navigate } from 'react-router-dom';
import {
  getHeaderByCode,
  validatePassword,
} from '../../../../utils/componentUtilFns';
import Input from '../../../UI/FormElements/Input/Input';
import CommonFormStructure from '../ForgotPassword/CommonFormStructure/CommonFormStructure';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { frontendConstants } from '../../../../constants/constants';
import { selectAllUIValues } from '../../../../features/UI/uiSlice';
import { IForgotAccountQuery } from '../../../../tsTypes/types.components';
import { useForgotAccountMutation } from '../../../../features/auth/authSlice';
import axios from 'axios';
import PeriopsisCaptcha from '../../../UI/PeriopsisCaptcha/PeriopsisCaptcha';
import useRecaptcha from '../../../../CustomHooks/useRecaptcha';
import PasswordStrengthTest from '../SignUp/SignUpParts/PasswordStrengthTest/PasswordStrengthTest';
import classes from './PasswordUpdate.module.scss';
import SiteEntryContainer from '../../../UI/EnterSite/SiteEntryContainer';
import SuccessSign from '../../../UI/ConfirmResult/SuccessSign/SuccessSign';
import useTimer from '../../../../CustomHooks/useTimer';
import PasswordConditions from '../../../UI/PasswordConditions/PasswordConditions';
import useFormEntry from '../../../../CustomHooks/useFormEntry';
import { useTranslation } from 'react-i18next';

const validatePasswordEntry = (password: string) =>
  validatePassword(password, 6, 25);

const PasswordUpdate = () => {
  const { language } = useSelector(selectAllUIValues);
  const [showConditionsBoard, setShowConditionsBoard] = useState(false);
  const { t } = useTranslation();
  const [
    forgotAccountProcessing,
    { isLoading: isForgotAccoutLoading, isSuccess: isForgotAccountSuccess },
  ] = useForgotAccountMutation();

  const { timerFormat, startTimer } = useTimer({ duration: 15 });

  const [validateResult, setValidateResult] = useState<{
    isSuccess: boolean;
    message: string;
    data: { id: string; name: string } | null;
  } | null>(null);
  const isValidated = useRef(false);

  const {
    isValidRecaptcha,
    recaptchaChangeHandler,
    recaptchaExpiredHandler,
    recaptchaRef,
    recaptchaCode,
    resetRecaptcha,
  } = useRecaptcha();

  const location = useLocation();
  const queryParam = new URLSearchParams(location.search);
  const userEmail = queryParam.get('email');
  const { token, code } = useParams();
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  // const isValidPassword = validatePassword(newPassword, 6, 25);

  useEffect(() => {
    const validateToken = async (token: string) => {
      try {
        const res = await axios.post(
          `${frontendConstants.backendHost}/api/v1/users/validate-forgotaccount/${token}/${code}`
        );

        setValidateResult(res.data);
      } catch (error: Error | any | unknown) {
        if (error.response.status === 404) {
          setValidateResult(error.response.data);
        } else {
          setValidateResult({
            isSuccess: false,
            message: frontendConstants[language].messages.serverError.message,
            data: null,
          });
        }
      }
    };

    if (!isValidated.current && token) {
      validateToken(token);
      isValidated.current = true;
    }
  }, [language, token, code]);

  const {
    value: newPassword,
    isValid: isValidPassword,
    entryHandler: passwordEntryHandler,
    clearEntry: clearPasswordEntry,
  } = useFormEntry(validatePasswordEntry);

  const validateConfirmPassword = (confirmPasswordEntry: string) =>
    validatePasswordEntry(confirmPasswordEntry) &&
    confirmPasswordEntry === newPassword;

  const {
    value: confirmPassword,
    isValid: isValidConfirmPassword,
    entryHandler: confirmPasswordEntryHandler,
    clearEntry: clearConfirmPasswordEntry,
  } = useFormEntry(validateConfirmPassword);

  const fakeButton =
    newPassword.trim().length === 0 &&
    !isValidConfirmPassword &&
    !isValidPassword;

  const submitNewPasswordHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (
        userEmail &&
        token &&
        code &&
        isValidPassword &&
        isValidConfirmPassword &&
        isValidRecaptcha &&
        recaptchaCode &&
        recaptchaCode.current
      ) {
        const data: IForgotAccountQuery = {
          email: userEmail,
          token,
          validationCode: code,
          password: newPassword,
          confirmPassword,
          recaptcha: recaptchaCode.current,
        };

        // dispatch(forgotAccountProcessing(data));
        const res = await forgotAccountProcessing(data).unwrap();

        if (
          res &&
          typeof res === 'object' &&
          'isPasswordUpdated' in res &&
          res.isPasswordUpdated
        ) {
          startTimer();
          clearPasswordEntry();
          clearConfirmPasswordEntry();
          const timer = setTimeout(() => {
            setRedirectToLogin(true);
            clearTimeout(timer);
          }, 14000);
        }
      }
    } catch (error) {
      if (
        error &&
        typeof error === 'object' &&
        'status' in error &&
        error.status &&
        typeof error.status === 'number' &&
        'data' in error &&
        error.data &&
        typeof error.data === 'object' &&
        'message' in error.data &&
        error.data.message &&
        typeof error.data.message === 'string'
      ) {
        const errorCode = error.status;
        const heading = getHeaderByCode(errorCode);
        const message = error.data.message;
        resetRecaptcha(true);

        return toast.error(
          <ToastifyStyledMessage singleMessage={message} heading={heading} />
        );
      }

      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('passwordupdate:passupdate_server_error_message')}
          heading={t('passwordupdate:server_error')}
        />
      );
    }
  };

  if ((validateResult && !validateResult.isSuccess) || redirectToLogin) {
    return <Navigate to={frontendConstants.pages.login} />;
  }

  if (isForgotAccountSuccess) {
    return (
      <SiteEntryContainer>
        <div className={classes.UpdateSuccess}>
          <SuccessSign />
          <h2>{t('passwordupdate:congratulations')}</h2>
          <h3>{timerFormat}</h3>
          <p>{t('passwordupdate:congrat_message')}</p>
        </div>
      </SiteEntryContainer>
    );
  }

  const showPasswordConditionsBoardHandler = () => {
    setShowConditionsBoard(true);
  };

  const hidePasswordConditionsBoardHandler = () => {
    setShowConditionsBoard(false);
  };

  return (
    <SiteEntryContainer>
      <CommonFormStructure
        onSubmit={submitNewPasswordHandler}
        isFakeButton={fakeButton || !isValidRecaptcha || isForgotAccoutLoading}
        hasParagraph
        heading={t('passwordupdate:enter_new_password')}
        paragraph={t('passwordupdate:new_password_desc')}
        className={classes.PasswordUpdate}
        isLoading={isForgotAccoutLoading}
      >
        <div className={classes.InputElements}>
          <Input
            name='password'
            label={t('passwordupdate:new_password')}
            onChange={passwordEntryHandler}
            value={newPassword}
            isInvalid={!isValidPassword}
            isRequired
            hasShowPasswordButton
            showInformationBoard={showConditionsBoard}
            onFocus={showPasswordConditionsBoardHandler}
            onBlur={hidePasswordConditionsBoardHandler}
            autoComplete='new-password'
            type='password'
          >
            <PasswordConditions />
          </Input>
          <Input
            name='confirmPassword'
            label={t('passwordupdate:confirm_new_password')}
            hasShowPasswordButton
            type='password'
            value={confirmPassword}
            onChange={confirmPasswordEntryHandler}
            isInvalid={
              !isValidConfirmPassword && confirmPassword.trim().length > 5
            }
          />
          <PasswordStrengthTest password={newPassword} />
          <PeriopsisCaptcha
            onExpired={recaptchaExpiredHandler}
            onChange={recaptchaChangeHandler}
            ref={recaptchaRef}
            show={
              !fakeButton &&
              newPassword.trim().length > 5 &&
              confirmPassword.trim().length > 5
            }
          />
        </div>
      </CommonFormStructure>
    </SiteEntryContainer>
  );
};

export default PasswordUpdate;
