import { IMapContainer } from '../../../tsTypes/interfaces';
import CyprusMap from './CyprusMap/CyprusMap';
import GreeceMap from './GreeceMap/GreeceMap';

const AreaOfInterestMaps = ({
  onSelect,
  selected,
  countryCode,
}: IMapContainer) => {
  switch (countryCode) {
    case 'CY':
      return <CyprusMap onSelect={onSelect} selected={selected} />;

    case 'GR':
      return <GreeceMap onSelect={onSelect} selected={selected} />;

    default:
      return null;
  }
};

export default AreaOfInterestMaps;
