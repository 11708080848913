import { StyleSheet } from '@react-pdf/renderer';

export const PackagePricingPageStyle = StyleSheet.create({
  page: { backgroundColor: '#fff' },
  main: {
    display: 'flex',
    flexDirection: 'row',
  },

  Right: {
    padding: '4mm 6mm',
  },
});
