import { Fragment } from 'react';
import { FaLock } from 'react-icons/fa';
import { MdRemoveCircle } from 'react-icons/md';
import classes from './RemoveButton.module.scss';

interface IProps {
  onRemove: (id: string) => void;
  isAuthorized: boolean;
  id: string;
  title?: string;
}

const RemoveButton = ({ isAuthorized, onRemove, id, title }: IProps) => {
  return (
    <Fragment>
      {isAuthorized ? (
        <button
          onClick={() => onRemove(id)}
          title={title}
          className={classes.RemoveButton}
        >
          <MdRemoveCircle />
        </button>
      ) : (
        <FaLock style={{ cursor: 'not-allowed' }} color='#d95c2d' />
      )}
    </Fragment>
  );
};

export default RemoveButton;
