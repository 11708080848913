/**
 *
 * @param value the entered phone number by user
 *
 * This will take the phone number and create a pattern like 999 999 9999
 * we may have different length of phone numbers and hence this will update pattern according
 * to entered phone character size.
 *                      Pattern :
 * Phone Length 5 =>    XX XXX
 * Phone Length 6 =>    XXX XXX
 * Phone Length 7 =>    XX XXX XXX
 * Phone Length 8 =>    XX XXX XXXX
 * Phone Length 9 =>    XXX XXX XXX
 * Phone Length 10 =>   XXX XXX XXXX
 * Phone Length 11 =>   XXX XXXX XXXX
 * Phone Length 12 =>   XX XXX XXX XXXX
 * Phone Length 13 =>   XXX XXX XXX XXXX
 * Phone Length 14 =>   XXX XXX XXXX XXXX
 * Phone Length 15 =>   XX XXX XXX XXX XXXX
 * Phone Length 16 =>   XX XXX XXX XXXX XXXX
 *
 */
export const createPattern = (value: string) => {
  if (!value) return '';
  let firstPart = '';
  let secondPart = '';
  let thirdPart = '';
  let fourthPart = '';
  let fifthPart = '';

  const valFromPattern = value.split(' ').join('');

  const isValidNumber = !isNaN(Number(valFromPattern));

  if (isValidNumber) {
    const numLength = valFromPattern.length;

    switch (numLength) {
      case 5:
        //Phone Length 5 =>    XX XXX
        firstPart = valFromPattern.substring(0, 2);
        secondPart = valFromPattern.substring(2);
        return `${firstPart} ${secondPart}`;

      case 6:
        //Phone Length 6 =>    XXX XXX
        firstPart = valFromPattern.substring(0, 3);
        secondPart = valFromPattern.substring(3);

        return `${firstPart} ${secondPart}`;
      case 7:
        //Phone Length 7 =>    XX XX XXX
        firstPart = valFromPattern.substring(0, 2);
        secondPart = valFromPattern.substring(2, 4);
        thirdPart = valFromPattern.substring(4);

        return `${firstPart} ${secondPart} ${thirdPart}`;
      case 8:
        //Phone Length 8 =>    XX XXX XXX
        firstPart = valFromPattern.substring(0, 2);
        secondPart = valFromPattern.substring(2, 5);
        thirdPart = valFromPattern.substring(5);
        return `${firstPart} ${secondPart} ${thirdPart}`;
      case 9:
      case 10:
        //Phone Length 9 =>    XXX XXX XXX
        //Phone Length 10 =>   XXX XXX XXXX
        firstPart = valFromPattern.substring(0, 3);
        secondPart = valFromPattern.substring(3, 6);
        thirdPart = valFromPattern.substring(6);

        return `${firstPart} ${secondPart} ${thirdPart}`;

      case 11:
        //Phone Length 11 =>   XXX XXXX XXXX
        firstPart = valFromPattern.substring(0, 3);
        secondPart = valFromPattern.substring(3, 7);
        thirdPart = valFromPattern.substring(7);

        return `${firstPart} ${secondPart} ${thirdPart}`;

      case 12:
        //Phone Length 12 =>   XX XXX XXX XXXX
        firstPart = valFromPattern.substring(0, 2);
        secondPart = valFromPattern.substring(2, 5);
        thirdPart = valFromPattern.substring(5, 8);
        fourthPart = valFromPattern.substring(8);

        return `${firstPart} ${secondPart} ${thirdPart} ${fourthPart}`;

      case 13:
        //Phone Length 13 =>   XXX XXX XXX XXXX
        firstPart = valFromPattern.substring(0, 3);
        secondPart = valFromPattern.substring(3, 6);
        thirdPart = valFromPattern.substring(6, 9);
        fourthPart = valFromPattern.substring(9);

        return `${firstPart} ${secondPart} ${thirdPart} ${fourthPart}`;

      case 14:
        //Phone Length 14 =>   XXX XXX XXXX XXXX
        firstPart = valFromPattern.substring(0, 3);
        secondPart = valFromPattern.substring(3, 6);
        thirdPart = valFromPattern.substring(6, 10);
        fourthPart = valFromPattern.substring(10);

        return `${firstPart} ${secondPart} ${thirdPart} ${fourthPart}`;

      case 15:
        //Phone Length 15 =>   XX XXX XXX XXX XXXX
        firstPart = valFromPattern.substring(0, 2);
        secondPart = valFromPattern.substring(2, 5);
        thirdPart = valFromPattern.substring(5, 8);
        fourthPart = valFromPattern.substring(8, 11);
        fifthPart = valFromPattern.substring(11);
        return `${firstPart} ${secondPart} ${thirdPart} ${fourthPart} ${fifthPart}`;

      case 16:
        //Phone Length 16 =>   XX XXX XXX XXXX XXXX
        firstPart = valFromPattern.substring(0, 2);
        secondPart = valFromPattern.substring(2, 5);
        thirdPart = valFromPattern.substring(5, 8);
        fourthPart = valFromPattern.substring(8, 12);
        fifthPart = valFromPattern.substring(12);
        return `${firstPart} ${secondPart} ${thirdPart} ${fourthPart} ${fifthPart}`;

      default:
        return valFromPattern;
    }
  }
};

export const reviseMaxLengthForPattern = (maxLength: number) => {
  if (maxLength < 5) {
    return maxLength;
  } else if (maxLength >= 5 && maxLength < 7) {
    return maxLength + 1;
  } else if (maxLength >= 7 && maxLength < 12) {
    return maxLength + 2;
  } else if (maxLength >= 12 && maxLength < 15) {
    return maxLength + 3;
  } else {
    return maxLength + 4;
  }
};

/**
 *
 * @param value The phone number which has been converted to a pattern in createPattern Utility Function
 *
 * This will take pattern structure and dissolve the pattern to validate and send as correct number sequence
 * to backend
 */
export const decodePattern = (value: string) => {
  return value.split(' ').join('');
};
