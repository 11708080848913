import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../utils/getTranslationKey';
import classes from './FlowBar.module.scss';
import FlowBarArrowStep from './FlowBarArrowStep/FlowBarArrowStep';

interface IProps {
  flowData: {
    id: string;
    label: string;
    pageNumber: number;
    title?: string;
  }[];
  currentPageNumber: number;
}

const FlowBar = ({ flowData, currentPageNumber }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.FlowContainer}>
      {flowData.map((data, index) => (
        <FlowBarArrowStep
          key={data.id}
          label={t(`auth:${getTranslationKey(data.label)}`)}
          isCompleted={data.pageNumber <= currentPageNumber}
          FrontArrow={index < flowData.length - 1}
          BackArrow={index > 0}
          title={data.title ? t(`auth:${getTranslationKey(data.title)}`) : ''}
          number={index + 1}
        />
      ))}
    </div>
  );
};

export default FlowBar;
