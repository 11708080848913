import { alpha2Code, ITranslation } from '../tsTypes/types.components';
import { IListObject } from '../tsTypes/types.model';

interface IFrontend {
  backendHost: string;
  apiVersion: string;
  pollInterval: number;
  pages: {
    login: string;
    settings: string;
    user: string;
    userAdmin: string;
    statistics: string;
    emailConfirmed: string;
    emailConfirmError: string;
    forgotPassword: string;
    userPasswordRenewal: string;
    dashboard: string;
    incidentPage: string;
    notifications: string;
    notificationPage: string;
    outbox: string;
    reportOfIncident: string;
    registrationCheckoutPage: string;
    serviceRegistration: string;
  };
  passwordConditions: readonly string[];
  specialCodes: {
    invaildCredentials: number;
    pendingAccount: number;
    unAuthorized: number;
    sessionEnd: number;
    accountBlocked: number;
    invalidKey: number;
  };
  addressTypeOptionList: IListObject[];
  oneDayInMilliseconds: number;
  oneHourInMilliseconds: number;
  languages: {
    [key in alpha2Code]: {
      id: string;
      language: string;
      code: string;
      inUse: boolean;
    };
  };
  en: ITranslation;
  de: ITranslation;

  [key: string]: any;
  footerLinks: readonly {
    linkId: string;
    label: string;
    title: string;
    id?: string;
    onClick?: () => void;
    link?: string;
  }[];
}

export const frontendConstants: IFrontend = Object.freeze({
  //backendHost: 'http://localhost:8000',
  backendHost: '', //for production
  apiVersion: 'api/v1',
  oneDayInMilliseconds: 86400000,
  oneHourInMilliseconds: 3600000,
  pollInterval: 50000,

  passwordConditions: Object.freeze([
    'Password should be alphanumeric',
    'Password must have at least one uppercase letter',
    'Password must contain at least one lowercase letter',
    'Password must contain at least one number.',
    'Password must contain at least one special character',
    'Password should has at least 6 characters and at most 25 characters',
  ]),

  languages: Object.freeze({
    bg: {
      id: 'language-bulgarian-00001',
      language: 'Bulgarian',
      code: 'bg' as alpha2Code,
      inUse: false,
    },
    cz: {
      id: 'language-czech-00002',
      language: 'Czech',
      code: 'cz' as alpha2Code,
      inUse: false,
    },
    da: {
      id: 'language-danish-00003',
      language: 'Danish',
      code: 'da' as alpha2Code,
      inUse: false,
    },
    nl: {
      id: 'language-dutch-00012',
      language: 'Dutch',
      code: 'nl' as alpha2Code,
      inUse: false,
    },
    de: {
      id: 'language-german-00004',
      language: 'German',
      code: 'de' as alpha2Code,
      inUse: true,
    },
    el: {
      id: 'language-greek-00005',
      language: 'Greek',
      code: 'el' as alpha2Code,
      inUse: false,
    },
    en: {
      id: 'language-english-00006',
      language: 'English',
      code: 'en' as alpha2Code,
      inUse: true,
    },
    et: {
      id: 'language-estonian-00007',
      language: 'Estonian',
      code: 'et' as alpha2Code,
      inUse: false,
    },
    es: {
      id: 'language-spanish-00008',
      language: 'Spanish',
      code: 'es' as alpha2Code,
      inUse: false,
    },
    fi: {
      id: 'language-finnish-00009',
      language: 'Finnish',
      code: 'fi' as alpha2Code,
      inUse: false,
    },
    fr: {
      id: 'language-french-00010',
      language: 'French',
      code: 'fr' as alpha2Code,
      inUse: false,
    },
    hu: {
      id: 'language-hungarian-00011',
      language: 'Hungarian',
      code: 'hu' as alpha2Code,
      inUse: false,
    },
    it: {
      id: 'language-Italian-00012',
      language: 'Italian',
      code: 'it' as alpha2Code,
      inUse: false,
    },
    ga: {
      id: 'language-irish-00013',
      language: 'Irish',
      code: 'ga' as alpha2Code,
      inUse: false,
    },
    lv: {
      id: 'language-latvian-00014',
      language: 'Latvian',
      code: 'lv' as alpha2Code,
      inUse: false,
    },
    lt: {
      id: 'language-lithuanian-00015',
      language: 'Lithuanian',
      code: 'lt' as alpha2Code,
      inUse: false,
    },
    pl: {
      id: 'language-polish-00016',
      language: 'Polish',
      code: 'pl' as alpha2Code,
      inUse: false,
    },
    pt: {
      id: 'language-portuguese-00017',
      language: 'Portuguese',
      code: 'pt' as alpha2Code,
      inUse: false,
    },
    ro: {
      id: 'language-romanian-00018',
      language: 'Romanian',
      code: 'ro' as alpha2Code,
      inUse: false,
    },
    sv: {
      id: 'language-swedish-00019',
      language: 'Swedish',
      code: 'sv' as alpha2Code,
      inUse: false,
    },
    sk: {
      id: 'language-slovak-00020',
      language: 'Slovak',
      code: 'sk' as alpha2Code,
      inUse: false,
    },
    sl: {
      id: 'language-slovenian-00021',
      language: 'Slovenian',
      code: 'sl' as alpha2Code,
      inUse: false,
    },
    cy: {
      id: 'language-cyprus-00022',
      language: 'Greek',
      code: 'cy' as alpha2Code,
      inUse: false,
    },
    mt: {
      id: 'language-maltese-00023',
      language: 'Maltese',
      code: 'mt' as alpha2Code,
      inUse: false,
    },
    hr: {
      id: 'language-croatian-00024',
      language: 'Croatian',
      code: 'hr' as alpha2Code,
      inUse: false,
    },
  }),

  specialCodes: {
    invaildCredentials: 1001,
    pendingAccount: 1002,
    unAuthorized: 1003,
    sessionEnd: 1004,
    accountBlocked: 1005,
    invalidKey: 1006,
  },

  addressTypeOptionList: [
    {
      id: 'TBySF5qfnbcWdfpqfJzbbvBWFwoZl33E',
      title: 'Accountant Address',
      value: 'Accountant Address',
    },
    {
      id: 'bDi3W4joglYVOfUKzqbBSDhR7TabEXWd',
      title: 'Business Address',
      value: 'Business Address',
    },
    {
      id: 'ROLogAGlHsUQZLlqGlHZyP2lGHEh23G5',
      title: 'Home Address',
      value: 'Home Address',
    },
    {
      id: 'KSj34FqqYiazOq97CzjApqNGvKIvnRQH',
      title: 'Mailing Address',
      value: 'Mailing Address',
    },
    {
      id: 'DHCr996jBgcoXj2kQNNEgVjAeTCtk5cE',
      title: 'Office Address',
      value: 'Office Address',
    },
    {
      id: 'UiCSGKwwA2QReDzk5Ao58wqn635vbVeC',
      title: 'Organization Address',
      value: 'Organization Address',
    },
    {
      id: 'YjR1edSu3zqB1WmT1AR9udSyO5lr3TyI',
      title: 'Personal Address',
      value: 'Personal Address',
    },
    {
      id: 'Saqi6a6NuQm5IWPr9Pc2KFl5OYmi0mel',
      title: 'Work Address',
      value: 'Work Address',
    },
  ],

  pages: Object.freeze({
    login: '/login',
    settings: '/settings',
    user: '/user',
    statistics: '/statistics',
    userAdmin: '/user-admin',
    emailConfirmed: '/email/confirmed/:token',
    emailConfirmError: '/email/confirm/:token/error',
    forgotPassword: '/forgotpassword',
    userPasswordRenewal: '/user/passwordrenewal/:token/:code/updatepassword',
    dashboard: '/',
    incidentPage: '/incident/:gridId',
    notifications: '/notifications',
    notificationPage: '/notification/:notificationId',
    outbox: '/report',
    reportOfIncident: '/outbox/:incidentId/report/:reportId',
    registrationCheckoutPage:
      '/check-out/:checkoutToken/:contractId/:registrationCode',
    serviceRegistration: '/service-registration',
  }),
  //languages:
  //English:

  en: {
    languages: Object.freeze([
      {
        id: 'language-bulgarian-00001',
        language: 'Bulgarian',
        code: 'bg' as alpha2Code,
      },
      {
        id: 'language-czech-00002',
        language: 'Czech',
        code: 'cz' as alpha2Code,
      },
      {
        id: 'language-danish-00003',
        language: 'Danish',
        code: 'da' as alpha2Code,
      },
      {
        id: 'language-dutch-00012',
        language: 'Dutch',
        code: 'nl' as alpha2Code,
      },
      {
        id: 'language-german-00004',
        language: 'German',
        code: 'de' as alpha2Code,
      },
      {
        id: 'language-greek-00005',
        language: 'Greek',
        code: 'el' as alpha2Code,
      },
      {
        id: 'language-english-00006',
        language: 'English',
        code: 'en' as alpha2Code,
      },
      {
        id: 'language-estonian-00007',
        language: 'Estonian',
        code: 'et' as alpha2Code,
      },
      {
        id: 'language-spanish-00008',
        language: 'Spanish',
        code: 'es' as alpha2Code,
      },
      {
        id: 'language-finnish-00009',
        language: 'Finnish',
        code: 'fi' as alpha2Code,
      },
      {
        id: 'language-french-00010',
        language: 'French',
        code: 'fr' as alpha2Code,
      },
      {
        id: 'language-hungarian-00011',
        language: 'Hungarian',
        code: 'hu' as alpha2Code,
      },
      {
        id: 'language-Italian-00012',
        language: 'Italian',
        code: 'it' as alpha2Code,
      },
      {
        id: 'language-irish-00013',
        language: 'Irish',
        code: 'ga' as alpha2Code,
      },
      {
        id: 'language-latvian-00014',
        language: 'Latvian',
        code: 'lv' as alpha2Code,
      },
      {
        id: 'language-lithuanian-00015',
        language: 'Lithuanian',
        code: 'lt' as alpha2Code,
      },
      {
        id: 'language-polish-00016',
        language: 'Polish',
        code: 'pl' as alpha2Code,
      },
      {
        id: 'language-portuguese-00017',
        language: 'Portuguese',
        code: 'pt' as alpha2Code,
      },
      {
        id: 'language-romanian-00018',
        language: 'Romanian',
        code: 'ro' as alpha2Code,
      },
      {
        id: 'language-swedish-00019',
        language: 'Swedish',
        code: 'sv' as alpha2Code,
      },
      {
        id: 'language-slovak-00020',
        language: 'Slovak',
        code: 'sk' as alpha2Code,
      },
      {
        id: 'language-slovenian-00021',
        language: 'Slovenian',
        code: 'sl' as alpha2Code,
      },
      {
        id: 'language-cyprus-00022',
        language: 'Greek',
        code: 'cy' as alpha2Code,
      },
      {
        id: 'language-maltese-00023',
        language: 'Maltese',
        code: 'mt' as alpha2Code,
      },
      {
        id: 'language-croatian-00024',
        language: 'Croatian',
        code: 'hr' as alpha2Code,
      },
    ]),

    filterDaysList: Object.freeze([
      { name: 'Last 30 Days', value: '30', label: 'Last 30 Days' },
      { name: 'Last 60 Days', value: '60', label: 'Last 60 Days' },
      { name: 'Last 3 Months', value: '90', label: 'Last 3 Months' },
      { name: 'Last 4 Months', value: '120', label: 'Last 4 Months' },
      { name: 'Last 5 Months', value: '150', label: 'Last 5 Months' },
      { name: 'Last 6 Months', value: '180', label: 'Last 6 Months' },
      { name: 'Last 8 Months', value: '240', label: 'Last 8 Months' },
      { name: 'Last 10 Months', value: '300', label: 'Last 10 Months' },
      { name: 'Last 1 Year', value: '365', label: 'Last 1 Year' },
    ]),
    filterTypesList: Object.freeze([
      {
        name: 'All',
        value: 'All',
        label: 'All',
      },
      {
        name: 'Electrical Waste',
        value: 'Electrical Waste',
        label: 'Electrical Waste',
      },
      {
        name: 'Hazardous Waste',
        value: 'Hazardous Waste',
        label: 'Hazardous Waste',
      },
      {
        name: 'Recyclable Waste',
        value: 'Recyclable Waste',
        label: 'Recyclable Waste',
      },
      {
        name: 'Medical/Clinical Waste',
        value: 'Medical/Clinical Waste',
        label: 'Medical/Clinical Waste',
      },
      {
        name: 'Solid Household Waste',
        value: 'Solid Household Waste',
        label: 'Solid Household Waste',
      },
      {
        name: 'Garbage',
        value: 'Garbage',
        label: 'Garbage',
      },
      {
        name: 'Oil',
        value: 'Oil',
        label: 'Oil',
      },
    ]),
    messages: Object.freeze({
      dateOrderError: {
        message: 'The Start date cannot be greater than the end date.',
        heading: 'Date Order Error',
      },
      futureDateError: {
        message: "The date cannot be a date greater than today's date.",
        heading: 'Future Date Error',
      },
      noPasswordMatch: {
        message:
          'Confirm Password does not match with Password. Please check your password and confirm password.',
        heading: 'No Match Error!',
      },
      noPasswordEnteredOnPasswordChange: {
        message:
          'Old Password, New Password and Confirm Password are required.',
        heading: 'Required Data Error!',
      },
      notAValidPasswordOnPasswordChange: {
        message:
          'Not a valid password. Please check password conditions by clicking info icon on the heading',
        heading: 'Not Valid Error!',
      },
      newPassEqualOldPass: {
        message:
          'Please enter a new password different than your old password.',
        heading: 'Same Password!',
      },
      newPasswordContainsOldPassword: {
        message:
          'Please do not use your old password in your new password, try to enter completely different password.',
        heading: 'Old in New Password!',
      },
      allRequired: {
        message:
          'Please check the form and fill it, all data are required for registration',
        heading: 'All Required!',
      },
      invalidEmailAddress: {
        message: 'Please enter a valid email address',
        heading: 'Invalid Email Address',
      },
      removedFromList: {
        message: 'Removed from list successfully',
        heading: 'Deleted',
      },
      existingName: {
        message:
          'A similar name with the entered name is exist in the list, would you like to add same name to the list?',
        heading: 'Existing Name',
      },
      existingEmail: {
        message:
          'Same email exists in the list, would you like to add it again?',
        heading: 'Existing Email',
      },
      existingText: {
        message:
          'Same text exists in the list, would you like to add it again?',
        heading: 'Existing Text',
      },
      textCharError: {
        message:
          'Entered text must not contain special characters. Minimum length of item name should be 3 and maximum should be at most 50 characters.',
        heading: 'Check Text',
      },
      nameCharError: {
        message:
          'Please enter Name, Surname and Name or Surname must not contain special characters. Minimum length should be 4 and maximum should be at most 50 characters',
        heading: 'Check Name',
      },
      invalidMessage: {
        message:
          'Entered message must not contain special characters and maximum length of the message should be at most 1000 characters.',
        heading: 'Check Message',
      },
      unacceptableMessage: {
        message:
          'The message cannot contain special characters (except comma, dot and question mark). Please do not use any special character in your message.',
        heading: 'Unacceptable Message',
      },

      oldDate: {
        heading: 'Old Date',
        message: 'You are about to enter an old date.',
      },
      messageSend: {
        heading: 'Report Send',
        message: 'Report send successfully to the email addresses.',
      },
      nextPage: {
        heading: 'Next Page',
        message: 'Great! Please wait, you will be forwarded to the next page',
      },
      notInServiceCountry: {
        heading: 'Not in service for now',
        message:
          'We are sorry but currently, we are giving service to the countries listed at the end of the message. We are working hard to give service to whole Europe. Please select another country. Countries in Service :',
      },
      notInServiceOrganizationType: {
        heading: 'Not in service for now.',
        message: `Thank you very much for deciding to take our services but unfortunately, currently, we are giving service to listed types, please check the list at the end of message.
        We are working hard to give service every possible organization in Europe. We hope to see you again in the future. Organization Types in Service :`,
      },
      noDistrictSelected: {
        heading: 'District Selection is Required',
        message:
          'No district has been selected, please select at least one district',
      },

      redirectToLogin: {
        heading: 'Redirect',
        message: 'You will be redirected to Login Page within 3 seconds',
      },

      usernameCopied: {
        heading: 'Copied!',
        message: 'Username copied to clipboard.',
      },
      locationRequired: {
        heading: 'Location Would be Helpful',
        message:
          'For registration purposes, we will be happy to have your location data.',
      },
      noProvinceSelected: {
        heading: 'Province Selection is Required!',
        message:
          'No province has been selected, please select at least one province',
      },
      invalidName: {
        heading: 'Invalid Name',
        message:
          'Please enter a valid name, name cannot have less than 2 characters, more than 60 characters and also cannot contain special characters.',
      },

      invalidSurname: {
        heading: 'Invalid Surname',
        message:
          'Please enter a valid surname, surname cannot have less than 2 characters, more than 60 characters and also cannot contain special characters.',
      },
      invalidPassword: {
        heading: 'Invalid Password',
        message: '',
      },
      serverError: {
        heading: 'Server Error',
        message:
          'An unexpected server error occurred. We will fix it soon. We are sorry for this problem.',
      },
      authSessionError: {
        heading: 'Authorization Session Error',
        message:
          'You are not authorized for this process or your logged in session over. You will be redirected to login page. Please login again!',
      },
      currentDate: {
        heading: 'Date is Today',
        message:
          'The entered date is the date of today. was incident visited today?',
      },
      checkReportForm: {
        heading: 'Report Form Error',
        message: 'An error found, please check report from',
      },
      languageIsNotInUse: {
        heading: 'Not in use for now',
        message:
          'We are sorry, the selected language is not in use for now. We are working hard to include all EU languages',
      },
      countryAndDistrictSelectionProblem: {
        heading: 'Selection Problem',
        message: 'There is a problem with Country and District Selections',
      },
      logout: {
        heading: 'Please logout',
        message:
          'Would you like to logout before leaving the page? It is a very good practice to always logout.',
      },
    }),
    titleMessages: Object.freeze({
      oldPasswordTitle: 'Please enter your current password',
      newPasswordTitle:
        'Enter new password, a password different than the old one, before choosing your password, please check password conditions by clicking on the info icon',
      confirmPassword:
        'Please enter the password that you have choosen for the new password to confirm the entry.',
      passwordConditions: 'Click to see password conditions.',
      verificationCodeDescription: `Please check your email, you will receive an verification code, to
        update your password, you need to enter the verification code.`,
      noIncident: 'No incident data found.',
      seeIncidentData: 'Click to see incident data.',
      seeIncidentImages: 'Click to see incident images.',
      latitude:
        'The coordinate that specifies the north-south position of the incident on the surface of the Earth.',
      longitude:
        'The coordinate that specifies the east-west position of the incident on the surface of the Earth.',
      detectedAt:
        'The date that the current incident detected by satellite at the specified location (coordinates).',
      detectedBy:
        'The satellite that detected the current incident at the specified date.',
      visited:
        'It specifies if the current incident was visited by a team or a department of your organization.',
      reported:
        'It specifies if the current incident was reported to a manager, team or a department of your organization.',
      cleaned:
        'It specifies if the current incident was cleaned by a team or a department of your organization.',
      distance:
        'It specifies the optimized distance of the current incident location to the registered location (coordinates) of your organization.',
      duration:
        'It specifies the optimized driving duration (with no traffic consideration) to the location of the current incident location from the registered location of your organization.',
      toReportTitle:
        'REPORT TO: The name of the person, who is mainly responsible for this kind of incident in the organization.',
      selectSingleDistrict: 'Select Single Target District',
      selectMultipleDistrict: 'Select Multiple Target District',
      clickToCopy: 'Click to copy!',
      organizationType: 'Organization Type',
      goToPreviousForm: 'Go to Previous Form Page',
      goToNextForm: 'Go to Next Form Page',
      selectSingleTargetProvince: 'Select Single Target Province',
      selectMultiProvinces: 'Select Multiple Target Provinces',
      pageNotFound: "We couldn't find the page you were looking for",
      numberOfReports: 'Number of reports',
      incidentReportsArchive: 'Incident Reports Archive',
      restoreSelectedFromArchive: 'Restore Selected Reports from Archive',
      sendSelectedToArchive: 'Send Selected Reports to Archive',
      restoreFromArchive: 'Restore from Archive',
      clickToSeeAllCharts: 'Click to see all charts',
      clickForFullScreenPresentation: 'Click for full screen presentation.',
      incidentWillbeRemoved:
        'The incident will be removed from the incidents list permanently. Are you sure about this delete process?',
      visitedByTitle:
        'Please enter by who this incident was visited (Name and Surname)',
      visitedAtTitle: 'Please enter when this incident was visited',
      notesAboutVisitTitle: 'Optional notes about visit',
      goToIncidentListBoard: 'Go to incidents list board',
      closeDirectionsBoard: 'Close Directions Board',
      showDirections: 'Show Directions',
      incidentReportedOn: 'Incident reported on',
      clickToCheckAboutReportSummary: 'click to check about report summary',
      clickToReportIncident:
        'Click to report incident to the relevant department or people',
      clickToEnterVisitReportData: 'Click to enter the visit report data.',
      incidentVisitedOn: 'Incident visited on',
      clickToCheckReportAboutVisit: 'click to check report about visit.',
      addCleanedReportAboutIncident:
        'If the incident has been cleaned, click to add report about clean process.',
      incidentCleanedOn: 'Incident cleaned on',
      clickToCheckCleanedReport: 'click to check report about clean operation.',
      noIncidentTitle:
        'If there is no incident, you may remove it from the list.',
      removeFromTheList: 'To remove from the list click on the badge',
      outboxForIncidentReports: 'Outbox for Incident Reports',
      userSettings: 'User Settings',
      aboutPeriopsis: 'About Periopsis',
      filterIncidents: 'Filter Incidents',
      last30Days: 'Last 30 Days',
    }),

    headings: Object.freeze({
      changePassword: 'Change Password',
      changeUsername: 'Change Username',
      verificationCode: 'Verification Code',
      incidentDashBoardHeading: 'Dumping Map',
      generalInfo: 'General Information',
      incidentImages: 'Incident Images',
      detectedAt: 'Detected On',
      detectedBy: 'Detected By',
      latitude: 'Latitude',
      longitude: 'Longitude',
      reported: 'Reported',
      visited: 'Visited',
      cleaned: 'Cleaned',
      distance: 'Distance',
      duration: 'Duration',
      reportTo: 'Report To',
      reportedBy: 'Reported By',
      reportedAt: 'Reported At',
      archivedReport: 'Archived Report',
      emailedTo: 'Emailed To',
      description: 'Description',
      sendMessage: 'Send Message',
      report: 'Report',
      selectTargetCountry: 'Select Target Country',
      selectTargetDistrict: 'Select Target District',
      selectOrganizationType: 'Please Select Your Organization Type',
      selectOrganization: 'Please Select Your Organization',
      selectTargetProvince: 'Select Target Province',
      selectTargetProvinces: 'Select Target Province',
      passwordConditions: 'Password Conditions',
      notFound: 'Not Found',
      serverError: 'Server Error',
      archivedReportsOf: 'Archived reports of',
      weeklyStats: 'Weekly Statistics By Year',
      annualActionsPieChart: 'Annual Actions Pie Chart',
      incidentsHeatMap: 'Incidents Heat Map',
      annualActions: 'Annual Actions',
      totals: 'Totals',
      totalDetected: 'Total Detected',
      totalReported: 'Total Reported',
      totalVisited: 'Total Visited',
      totalCleaned: 'Total Cleaned',
      generalProfileSettings: 'General Profile Settings',
      districtOverview: 'District Overview',
      incidentVisitDetails: 'Incident Visit Details',
      incidentVisitReport: 'Incident Visit Report',
      aboutVisit: 'About Visit',
      notes: 'Notes',
      incidentCleanedReport: 'Incident Cleaned Report',
      aboutCleanOperation: 'About Clean Operation',
      emailAlreadyExists: 'Email Already Exists!',
      notAValidEmail: 'Not a valid email!',
      tooManyRegistrationRequest: 'Too many registration requests from IP : ',
      pleaseTryAgain120Hours: 'Please try again after 120 hours.',
      beforeFillingtheForm: 'Before filling out the form, please select',
      recentStatistics: 'Recent Statistics (Latest 12 Weeks)',
    }),

    labels: {
      loginUsername: 'Username or email',
      username: 'Username',
      login: 'Login',
      email: 'Email',
      name: 'Name',
      surname: 'Surname',
      forgotPassword: 'Forgot Password?',
      signUp: 'Sign up',
      signIn: 'Sign in',
      needAccount: '',
      password: 'Password',
      newpassword: 'New Password',
      oldpassword: 'Old Password',
      confirmPassword: 'Confirm Password',
      confirmNewPassword: 'Confirm New Password',
      verificationCode: 'Verification Code',
      save: 'Save',
      submit: 'Submit',
      loading: 'Loading',
      registering: 'Registering',
      yes: 'Yes',
      no: 'No',
      send: 'Send',
      message: 'Message',
      additionalNotes: 'Additional Notes',
      subject: 'Subject',
      remaining: 'Remaining',
      to: 'To',
      attachCurrent: 'Attach current incident report',
      attachFile: 'Attach a file',
      bold: 'Bold text',
      italic: 'Italic text',
      textColor: 'Text Color',
      fontSize: 'Font Size',
      sendACopyToMyEmail: 'Send a copy to my email',
      nameOfAuthorizedPerson: 'Name of Related (Authorized) Person',
      selectCountry: 'Select Country',
      continue: 'Continue',
      multiple: 'Multiple',
      single: 'Single',
      dear: 'Dear',
      approve: 'Approve',
      haveAccount: 'Already have an account?',
      total: 'Total',
      report: 'report',
      reports: 'reports',
      archivedReport: 'archived report',
      archivedReports: 'archived reports',
      sendToArchive: 'Send to Archive',
      reportOf: 'Report of',
      selectAll: 'Select All',
      detectedAt: 'detected at',
      outbox: 'Outbox',
      archive: 'Archive',
      resend: 'Resend',
      restore: 'Restore',
      dashboard: 'Dashboard',
      detectedPerWeek: 'Detected Per Week',
      cleanedPerWeek: 'Cleaned Per Week',
      visitedPerWeek: 'Visited Per Week',
      reportedPerWeek: 'Reported Per Week',
      country: 'Country',
      registered: 'Registered',
      loggedIn: 'Logged in',
      filterByOrganization: 'Filter By Organization',
      filterByDate: 'Filter Incidents by Date',
      days: 'Days',
      range: 'Range',
      delete: 'Delete',
      deleting: 'Deleting',
      visitedBy: 'Visited By',
      visitedAt: 'Visited At',
      notesAboutVisit: 'Optional - Notes about visit',
      signVisited: 'Sign Visited',
      error: 'Error!',
      createdBy: 'Created By',
      createdAt: 'Created At',
      goToOutbox: 'Go to Outbox',
      select: 'Select',
      close: 'Close',
      directions: 'Directions',
      Report: 'Report',
      reportSummary: 'Report Summary',
      visited: 'Visited',
      visitReport: 'Visit Report',
      markCleaned: 'Mark Cleaned',
      cleanedReport: 'Cleaned Report',
      noIncident: 'No Incident',
      startedAt: 'Started At',
      cleanedAt: 'Cleaned At',
      cleanOperationTook: 'Clean Operation Took',
      noInfo: 'No Info',
      cleanedBy: 'Cleaned By',
      equipmentUsed: 'Equipment Used',
      List: 'List',
      Map: 'Map',
      All: 'All',
      noAction: 'No Action',
      guestSettings: 'Guest Settings',
      incidentsList: 'Incidents',
    },

    descriptions: {
      targetCountrySelectionDescription:
        'Selecting the country will help us to filter provinces and districts and also will help you to choose target district among lesser option easily.',
      targetDistrictSelectionDescription:
        'Selecting the district will help us to filter existing list of organizations.',

      organizationTypeSelectionDescription:
        'To give you better service, we will need your organization type.',
      organizationSelectionDescription:
        'To filter incidents properly, we will need your organization information.',
      provinceSelectionDescription:
        'Selecting the province will help us to filter districts and also will help you to choose target district among lesser option easily.',
      registrationSuccessMessagePartI_registerSuccess:
        'Thank you very much; you have registered Periopsis successfully.',
      registrationSuccessMessagePartII_temporaryUserName:
        'Please save your temporary username :',
      registrationSuccessMessagePartIII_authStepsExplanation: `Please check the listed steps below. Upon approval you will be
        redirected to the login page, but before login, as mentioned in
        the following list, you have two more steps to start to use
        Periopsis Services.`,

      registrationSuccessListMessages: {
        checkYourEmail:
          'Please check your email address and confirm your email.',
        afterEmailConfirmation: `After email confirmation, your account will be reviewed by our
        team and if everything is ok, your account will be approved`,
        afterAdminApproval: `After approval of your account, you will receive a one time
        login key which you will need to enter to unlock your account
        and the with your username or email address and password, you
        will be able to enter your account`,
      },
      youHaveSuccessfullyVerifiedAccountEmail:
        'you have successfully verified your account email. As soon as you receive account approval email from admin, you will be able to login your account.',
      allReportsOlderthanSixMonths:
        "All reports that are older than 6 months or archived are stored at archive. You couldn't find a report that you are looking for here, please check archive",
      slogan: 'Locate the incidents and take the required actions',
    },

    NoDataMessages: {
      noReportFound: 'No report found',
      noReportFoundForThisIncident:
        'No report found for this incident, all reports were archived or no report send for this incident.',
      noArchivedReportFound:
        'No archived report found for this incident, all archived reports were restored or no report have been send to archive.',

      noStatisticsData: 'No statistics data found',
      noAnnualStats: 'No Annual Statistics Found',
      noUserData: 'No User Data Found!',
      noVisitData: 'No visit data found for this report',
      noReportSummary: 'No Report Summary Found.',
      allReportsArchived: 'All reports were archived',
      noCleanedData: 'No clean data found for this report',
      noIncidentFound: 'No incident was found under the given conditions.',
    },
    menuLinks: {
      home: 'Home',
      projects: 'Projects',
      about: 'About',
      news: 'News',
      contact: 'Contact',
      dashboard: 'Dashboard',
      shrinkMenu: 'Shrink Menu',
      expandMenu: 'Expand Menu',
      statistics: 'Statistics',
      notifications: 'Notifications',
      outbox: 'Outbox',
      user: 'User',
      settings: 'Settings',
      logout: 'Logout',
      team: 'Team',
    },
    homePage: {
      aboutHeading: 'About Us',
      aboutFirstParagraph:
        'PERIOPSIS LTD is an innovative company providing satellite imagery and aerial photography-based analytic services employing state-of-art Artificial Intelligence and Computer Vision techniques.',
      aboutSecondParagraph:
        'The mission of PERIOPSIS is to become established in Europe as a highly innovative company engaged in the use of emerging IT technologies (satellite imagery and aerial photography, AI, machine and deep learning, Internet of Things) for the purpose of large-scale environmental observation and modelling, with potential clients ranging from the industry and the commercial world up to governments and governmental entities, for shaping together environmental policy-making.',
      whatWeDo: 'What we do?',
      wwDListElement1:
        'Satellite imagery and/or aerial photography analytic services',
      wwDListElement2:
        'Employing state-of-art Artificial Intelligence and Computer Vision techniques.',
      wwDListElement3:
        'Use of emerging IT technologies (satellite imagery and aerial photography, AI, machine and deep learning, Internet of Things) for the purpose of large-scale environmental observation and modelling',
      wwDListElement4:
        'Shaping environmental policy-making, together with all relevant stakeholders',
      meetTheTeam: 'Meet The Team',
      managingDirector: 'Managing Director',
      operationsManager: 'Operations Manager',
      marketingManager: 'Marketing Manager',
      deepLearningScientist: 'Deep Learning Scientist',
      GISAnalyst: 'GIS Analyst',
      satelliteImageryAnalyst: 'Satellite Imagery Analyst',
    },
  },

  de: {
    languages: Object.freeze([
      {
        id: 'language-bulgarian-00001',
        language: 'Bulgarisch',
        code: 'bg' as alpha2Code,
      },
      {
        id: 'language-czech-00002',
        language: 'Tschechisch',
        code: 'cz' as alpha2Code,
      },
      {
        id: 'language-danish-00003',
        language: 'Dänisch',
        code: 'da' as alpha2Code,
      },
      {
        id: 'language-dutch-00012',
        language: 'Niederländisch',
        code: 'nl' as alpha2Code,
      },
      {
        id: 'language-german-00004',
        language: 'Deutsch',
        code: 'de' as alpha2Code,
      },
      {
        id: 'language-greek-00005',
        language: 'Griechisch',
        code: 'el' as alpha2Code,
      },
      {
        id: 'language-english-00006',
        language: 'Englisch',
        code: 'en' as alpha2Code,
      },
      {
        id: 'language-estonian-00007',
        language: 'Estnisch',
        code: 'et' as alpha2Code,
      },
      {
        id: 'language-spanish-00008',
        language: 'Spanisch',
        code: 'es' as alpha2Code,
      },
      {
        id: 'language-finnish-00009',
        language: 'Finnisch',
        code: 'fi' as alpha2Code,
      },
      {
        id: 'language-french-00010',
        language: 'Französisch',
        code: 'fr' as alpha2Code,
      },
      {
        id: 'language-hungarian-00011',
        language: 'Ungarisch',
        code: 'hu' as alpha2Code,
      },
      {
        id: 'language-Italian-00012',
        language: 'Italienisch',
        code: 'it' as alpha2Code,
      },
      {
        id: 'language-irish-00013',
        language: 'Irisch',
        code: 'ga' as alpha2Code,
      },
      {
        id: 'language-latvian-00014',
        language: 'Lettisch',
        code: 'lv' as alpha2Code,
      },
      {
        id: 'language-lithuanian-00015',
        language: 'Litauisch',
        code: 'lt' as alpha2Code,
      },
      {
        id: 'language-polish-00016',
        language: 'Polnisch',
        code: 'pl' as alpha2Code,
      },
      {
        id: 'language-portuguese-00017',
        language: 'Portugiesisch',
        code: 'pt' as alpha2Code,
      },
      {
        id: 'language-romanian-00018',
        language: 'Rumänisch',
        code: 'ro' as alpha2Code,
      },
      {
        id: 'language-swedish-00019',
        language: 'Schwedisch',
        code: 'sv' as alpha2Code,
      },
      {
        id: 'language-slovak-00020',
        language: 'Slowakisch',
        code: 'sk' as alpha2Code,
      },
      {
        id: 'language-slovenian-00021',
        language: 'Slowenisch',
        code: 'sl' as alpha2Code,
      },
      {
        id: 'language-cyprus-00022',
        language: 'Griechisch',
        code: 'cy' as alpha2Code,
      },
      {
        id: 'language-maltese-00023',
        language: 'Maltesisch',
        code: 'mt' as alpha2Code,
      },
      {
        id: 'language-croatian-00024',
        language: 'Kroatisch',
        code: 'hr' as alpha2Code,
      },
    ]),

    filterDaysList: Object.freeze([
      { name: 'Letzte 30 Tage', value: '30', label: 'Letzte 30 Tage' },
      { name: 'Letzte 60 Tage', value: '60', label: 'Letzte 60 Tage' },
      { name: 'Letzte 3 Monate', value: '90', label: 'Letzte 3 Monate' },
      { name: 'Letzte 4 Monate', value: '120', label: 'Letzte 4 Monate' },
      { name: 'Letzte 5 Monate', value: '150', label: 'Letzte 5 Monate' },
      { name: 'Letzte 6 Monate', value: '180', label: 'Letzte 6 Monate' },
      { name: 'Letzte 8 Monate', value: '240', label: 'Letzte 8 Monate' },
      { name: 'Letzte 10 Monate', value: '300', label: 'Letzte 10 Monate' },
      { name: 'Letztes 1 Jahr', value: '365', label: 'Letztes 1 Jahr' },
    ]),
    filterTypesList: Object.freeze([
      {
        name: 'Alle',
        value: 'Alle',
        label: 'Alle',
      },
      {
        name: 'Elektroschrott',
        value: 'Electrical Waste',
        label: 'Elektroschrott',
      },
      {
        name: 'Gefährliche Abfälle',
        value: 'Hazardous Waste',
        label: 'Gefährliche Abfälle',
      },
      {
        name: 'Wiederverwertbarer Abfall',
        value: 'Recyclable Waste',
        label: 'Wiederverwertbarer Abfall',
      },
      {
        name: 'Medizinischer/klinischer Abfall',
        value: 'Medical/Clinical Waste',
        label: 'Medizinischer/klinischer Abfall',
      },
      {
        name: 'Fester Hausmüll',
        value: 'Solid Household Waste',
        label: 'Fester Hausmüll',
      },
      {
        name: 'Müll',
        value: 'Garbage',
        label: 'Müll',
      },
      {
        name: 'Das Erdöl',
        value: 'Oil',
        label: 'Das Erdöl',
      },
    ]),
    messages: Object.freeze({
      dateOrderError: {
        message: 'Das Startdatum darf nicht nach dem Enddatum liegen.',
        heading: 'Datumsreihenfolgefehler',
      },
      futureDateError: {
        message: 'Das Datum darf nicht größer als das heutige Datum sein.',
        heading: 'Zukünftiger Datumsfehler',
      },
      noPasswordMatch: {
        message:
          'Passwort bestätigen stimmt nicht mit Passwort überein. Bitte überprüfen Sie Ihr Passwort und bestätigen Sie das Passwort.',
        heading: 'Passwörter stimmen nicht überein!',
      },
      noPasswordEnteredOnPasswordChange: {
        message:
          'Altes Passwort, Neues Passwort und Passwort bestätigen sind erforderlich.',
        heading: 'Erforderlicher Datenfehler!',
      },
      notAValidPasswordOnPasswordChange: {
        message:
          'Kein gültiges Passwort. Bitte überprüfen Sie die Passwortbedingungen, indem Sie auf das Info-Symbol in der Überschrift klicken',
        heading: 'Nicht gültiger Fehler!',
      },
      newPassEqualOldPass: {
        message:
          'Bitte geben Sie ein neues Passwort ein, das sich von Ihrem alten Passwort unterscheidet.',
        heading: 'Selbe Passwort!',
      },
      newPasswordContainsOldPassword: {
        message:
          'Bitte verwenden Sie nicht Ihr altes Passwort in Ihrem neuen Passwort, sondern versuchen Sie ein komplett anderes Passwort einzugeben.',
        heading: 'Alt in neuem Passwort!',
      },
      allRequired: {
        message:
          'Bitte überprüfen Sie das Formular und füllen Sie es aus, alle Daten werden für die Registrierung benötigt',
        heading: 'Alles Erforderlich!',
      },
      invalidEmailAddress: {
        message: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
        heading: 'Ungültige E-Mail-Adresse',
      },
      removedFromList: {
        message: 'Erfolgreich aus der Liste entfernt',
        heading: 'Gelöscht',
      },
      existingName: {
        message:
          'Ein ähnlicher Name mit dem eingegebenen Namen existiert in der Liste, möchten Sie denselben Namen zur Liste hinzufügen?',
        heading: 'Bestehender Name',
      },
      existingEmail: {
        message:
          'Dieselbe E-Mail-Adresse ist in der Liste vorhanden. Möchten Sie sie erneut hinzufügen?',
        heading: 'Bestehende E-Mail',
      },
      existingText: {
        message:
          'Der gleiche Text ist in der Liste vorhanden, möchten Sie ihn erneut hinzufügen?',
        heading: 'Vorhandener Text',
      },
      textCharError: {
        message:
          'Der eingegebene Text darf keine Sonderzeichen enthalten. Die Mindestlänge des Artikelnamens sollte 3 und die Höchstlänge höchstens 50 Zeichen betragen.',
        heading: 'Text prüfen',
      },
      nameCharError: {
        message:
          'Bitte geben Sie Vorname, Nachname ein, und Vor- oder Nachname darf keine Sonderzeichen enthalten. Die Mindestlänge sollte 4 und die maximale Länge höchstens 50 Zeichen betragen',
        heading: 'Überprüfen Sie den Namen',
      },
      invalidMessage: {
        message:
          'Die eingegebene Nachricht darf keine Sonderzeichen enthalten und die maximale Länge der Nachricht darf höchstens 1000 Zeichen betragen.',
        heading: 'Nachricht prüfen',
      },
      unacceptableMessage: {
        message:
          'Die Nachricht darf keine Sonderzeichen enthalten (außer Kommas, Punkte und Fragezeichen). Bitte verwenden Sie in Ihrer Nachricht keine Sonderzeichen.',
        heading: 'Unzulässige Nachricht',
      },

      oldDate: {
        heading: 'Altes Datum',
        message: 'Sie sind dabei, ein altes Datum einzugeben.',
      },
      messageSend: {
        heading: 'Bericht senden',
        message:
          'Der Bericht wurde erfolgreich an die E-Mail-Adressen gesendet.',
      },
      nextPage: {
        heading: 'Nächste Seite',
        message:
          'Groß! Bitte warten Sie, Sie werden auf die nächste Seite weitergeleitet',
      },
      notInServiceCountry: {
        heading: 'Vorerst nicht im Dienst',
        message:
          'Es tut uns leid, aber derzeit bedienen wir die Länder, die am Ende der Nachricht aufgeführt sind. Wir arbeiten hart daran, ganz Europa zu bedienen. Bitte wählen Sie ein anderes Land aus. Länder im Einsatz:',
      },
      notInServiceOrganizationType: {
        heading: 'Vorerst nicht im Dienst.',
        message: `Vielen Dank, dass Sie sich entschieden haben, unsere Dienste in Anspruch zu nehmen, aber leider bieten wir derzeit Dienste für aufgelistete Typen an. Bitte überprüfen Sie die Liste am Ende der Nachricht. Wir arbeiten hart daran, jede mögliche Organisation in Europa zu bedienen. Wir hoffen, Sie in Zukunft wiederzusehen. Organisationstypen im Service:`,
      },
      noDistrictSelected: {
        heading: 'Distriktauswahl ist erforderlich',
        message:
          'Es wurde kein Bezirk ausgewählt, bitte wählen Sie mindestens einen Bezirk aus',
      },

      redirectToLogin: {
        heading: 'Umleiten',
        message:
          'Sie werden innerhalb von 3 Sekunden zur Anmeldeseite weitergeleitet',
      },

      usernameCopied: {
        heading: 'Kopiert!',
        message: 'Benutzername in die Zwischenablage kopiert.',
      },
      locationRequired: {
        heading: 'Standort wäre hilfreich',
        message:
          'Für die Registrierung nehmen wir gerne Ihre Standortdaten entgegen.',
      },
      noProvinceSelected: {
        heading: 'Provinzauswahl ist erforderlich!',
        message:
          'Es wurde kein Bundesland ausgewählt, bitte wählen Sie mindestens ein Bundesland aus',
      },
      invalidName: {
        heading: 'Ungültiger Name',
        message:
          'Bitte geben Sie einen gültigen Namen ein, der Name darf nicht weniger als 2 Zeichen oder mehr als 60 Zeichen haben und darf auch keine Sonderzeichen enthalten.',
      },

      invalidSurname: {
        heading: 'Ungültiger Nachname',
        message:
          'Bitte geben Sie einen gültigen Nachnamen ein, der Nachname darf nicht weniger als 2 Zeichen oder mehr als 60 Zeichen haben und darf auch keine Sonderzeichen enthalten.',
      },
      invalidPassword: {
        heading: 'Ungültiges Passwort',
        message: '',
      },
      serverError: {
        heading: 'Serverfehler',
        message:
          'Ein unerwarteter Serverfehler ist aufgetreten. Wir werden es bald beheben. Wir bedauern dieses Problem.',
      },
      authSessionError: {
        heading: 'Autorisierungssitzungsfehler',
        message:
          'Sie sind weder für diesen Vorgang noch für Ihre eingeloggte Sitzung berechtigt. Sie werden auf die Anmeldeseite weitergeleitet. Bitte melden Sie sich erneut an!',
      },
      currentDate: {
        heading: 'Datum ist Heute',
        message:
          'Das eingegebene Datum ist das heutige Datum. wurde der Vorfall heute besucht?',
      },
      checkReportForm: {
        heading: 'Formularfehler melden',
        message:
          'Es wurde ein Fehler gefunden, bitte überprüfen Sie den Bericht von',
      },
      languageIsNotInUse: {
        heading: 'Momentan nicht in Gebrauch',
        message:
          'Es tut uns leid, aber die ausgewählte Sprache wird derzeit nicht verwendet. Wir arbeiten hart daran, alle EU-Sprachen aufzunehmen',
      },
      countryAndDistrictSelectionProblem: {
        heading: 'Auswahlproblem',
        message: 'Es gibt ein Problem mit der Länder- und Bezirksauswahl',
      },
      logout: {
        heading: 'Bitte abmelden',
        message:
          'Möchten Sie sich abmelden, bevor Sie die Seite verlassen? Es ist eine sehr gute Praxis, sich immer abzumelden.',
      },
    }),
    titleMessages: Object.freeze({
      oldPasswordTitle: 'Bitte geben Sie Ihr aktuelles Passwort ein.',
      newPasswordTitle:
        'Geben Sie ein neues Passwort ein, ein anderes Passwort als das alte, bevor Sie Ihr Passwort wählen, überprüfen Sie bitte die Passwortbedingungen, indem Sie auf das Info-Symbol klicken',
      confirmPassword:
        'Bitte geben Sie das von Ihnen gewählte Passwort für das neue Passwort ein, um die Eingabe zu bestätigen.',
      passwordConditions:
        'Klicken Sie hier, um die Passwortbedingungen anzuzeigen.',
      verificationCodeDescription: `Bitte überprüfen Sie Ihre E-Mail, Sie erhalten einen Bestätigungscode. Um Ihr Passwort zu aktualisieren, müssen Sie den Bestätigungscode eingeben.`,
      noIncident: 'Es wurden keine Vorfalldaten gefunden.',
      seeIncidentData: 'Klicken Sie hier, um Vorfalldaten anzuzeigen.',
      seeIncidentImages: 'Klicken Sie hier, um Bilder des Vorfalls anzuzeigen.',
      latitude:
        'Die Koordinate, die die Nord-Süd-Position des Ereignisses auf der Erdoberfläche angibt.',
      longitude:
        'Die Koordinate, die die Ost-West-Position des Ereignisses auf der Erdoberfläche angibt.',
      detectedAt:
        'The date that the current incident detected by satellite at the specified location (coordinates).',
      detectedBy:
        'Der Satellit hat den aktuellen Vorfall zum angegebenen Datum erkannt.',
      visited:
        'Es gibt an, ob der aktuelle Vorfall von einem Team oder einer Abteilung Ihrer Organisation besucht wurde.',
      reported:
        'Es gibt an, ob der aktuelle Vorfall einem Manager, Team oder einer Abteilung Ihrer Organisation gemeldet wurde.',
      cleaned:
        'Es gibt an, ob der aktuelle Vorfall von einem Team oder einer Abteilung Ihrer Organisation bereinigt wurde.',
      distance:
        'Es gibt die optimierte Entfernung des aktuellen Unfallortes zum registrierten Ort (Koordinaten) Ihrer Organisation an.',
      duration:
        'Es gibt die optimierte Fahrdauer (ohne Berücksichtigung des Verkehrs) zum Standort des aktuellen Vorfallsstandorts vom registrierten Standort Ihrer Organisation an.',
      toReportTitle:
        'MELDEN AN: Der Name der Person, die für diese Art von Vorfall in der Organisation hauptsächlich verantwortlich ist.',
      selectSingleDistrict: 'Wählen Sie Einzelner Zielbezirk aus',
      selectMultipleDistrict: 'Wählen Sie mehrere Zielbezirke aus',
      clickToCopy: 'Zum Kopieren klicken!',
      organizationType: 'Organisationstyp',
      goToPreviousForm: 'Gehen Sie zur vorherigen Formularseite',
      goToNextForm: 'Gehen Sie zur nächsten Formularseite',
      selectSingleTargetProvince: 'Wählen Sie Einzelne Zielprovinz aus',
      selectMultiProvinces: 'Wählen Sie mehrere Zielprovinzen aus',
      pageNotFound: 'Wir konnten die von Ihnen gesuchte Seite nicht finden',
      numberOfReports: 'Anzahl der Berichte',
      incidentReportsArchive: 'Archiv für Vorfallberichte',
      restoreSelectedFromArchive:
        'Ausgewählte Berichte aus dem Archiv wiederherstellen',
      sendSelectedToArchive: 'Ausgewählte Berichte zum Archiv senden',
      restoreFromArchive: 'Aus dem Archiv wiederherstellen',
      clickToSeeAllCharts: 'Klicken Sie hier, um alle Diagramme anzuzeigen',
      clickForFullScreenPresentation:
        'Klicken Sie hier für eine Vollbilddarstellung.',
      incidentWillbeRemoved:
        'Der Vorfall wird dauerhaft aus der Vorfallliste entfernt. Sind Sie sich bei diesem Löschvorgang sicher?',
      visitedByTitle:
        'Bitte geben Sie an, von wem dieser Vorfall besucht wurde (Name und Nachname)',
      visitedAtTitle: 'Bitte geben Sie an, wann dieser Vorfall besucht wurde',
      notesAboutVisitTitle: 'Optionale Hinweise zum Besuch',
      goToIncidentListBoard: 'Gehen Sie zum Board mit der Liste der Vorfälle',
      closeDirectionsBoard: 'Wegbeschreibungstafel schließen',
      showDirections: 'Wegbeschreibung anzeigen',
      incidentReportedOn: 'Vorfall gemeldet',
      clickToCheckAboutReportSummary:
        'Klicken Sie hier, um die Berichtszusammenfassung zu überprüfen',
      clickToReportIncident:
        'Klicken Sie hier, um den Vorfall den zuständigen Abteilungen oder Personen zu melden',
      clickToEnterVisitReportData:
        'Klicken Sie hier, um die Besuchsberichtsdaten einzugeben.',
      incidentVisitedOn: 'Vorfall besucht am',
      clickToCheckReportAboutVisit:
        'Klicken Sie hier, um den Bericht über den Besuch zu überprüfen.',
      addCleanedReportAboutIncident:
        'Wenn der Vorfall bereinigt wurde, klicken Sie auf , um einen Bericht über den Bereinigungsprozess hinzuzufügen.',
      incidentCleanedOn: 'Vorfall aufgeräumt',
      clickToCheckCleanedReport:
        'Klicken Sie hier, um den Bericht über den Bereinigungsvorgang zu überprüfen.',
      noIncidentTitle:
        'Wenn es keinen Vorfall gibt, können Sie ihn aus der Liste entfernen.',
      removeFromTheList:
        'Um aus der Liste zu entfernen, klicken Sie auf das Abzeichen',
      outboxForIncidentReports: 'Postausgang für Vorfallberichte',
      userSettings: 'Benutzereinstellungen',
      aboutPeriopsis: 'Über Periopsis',
      filterIncidents: 'Vorfälle filtern',
      last30Days: 'Letzte 30 Tage',
    }),
    // passwordConditions: Object.freeze([
    //   'Passwort sollte alphanumerisch sein',
    //   'Das Passwort muss mindestens einen Großbuchstaben enthalten',
    //   'Das Passwort muss mindestens einen Kleinbuchstaben enthalten',
    //   'Das Passwort muss mindestens eine Ziffer enthalten',
    //   'Passwort muss mindestens ein Sonderzeichen enthalten (@,$,!,& usw.)',
    //   'Passwort sollte mindestens 6 Zeichen und höchstens 25 Zeichen haben',
    // ]),

    headings: Object.freeze({
      changePassword: 'Passwort ändern',
      changeUsername: 'Benutzernamen ändern',
      verificationCode: 'Verifizierungs-Schlüssel',
      incidentDashBoardHeading: 'Dumping-Karte',
      generalInfo: 'Allgemeine Informationen',
      incidentImages: 'Vorfallbilder',
      detectedAt: 'Erkannt am',
      detectedBy: 'Entdeckt von',
      latitude: 'Breite',
      longitude: 'Längengrad',
      reported: 'Gemeldet',
      visited: 'Hat besucht',
      cleaned: 'Gereinigt',
      distance: 'Distanz',
      duration: 'Die Dauer',
      reportTo: 'Bericht an',
      reportedBy: 'Berichtet von',
      reportedAt: 'Gemeldet bei',
      archivedReport: 'Archivierter Bericht',
      emailedTo: 'E-Mail an',
      description: 'Die Beschreibung',
      sendMessage: 'Nachricht senden',
      report: 'Der Bericht',
      selectTargetCountry: 'Zielland auswählen',
      selectTargetDistrict: 'Wählen Sie den Zielbezirk aus',
      selectOrganizationType: 'Bitte wählen Sie Ihren Organisationstyp aus',
      selectTargetProvince: 'Wählen Sie die Zielprovinz aus',
      selectTargetProvinces: 'Wählen Sie Zielprovinzen aus',
      passwordConditions: 'Passwortbedingungen',
      notFound: 'Nicht gefunden',
      serverError: 'Serverfehler',
      archivedReportsOf: 'Archivierte Berichte von',
      weeklyStats: `Wöchentliche Statistiken nach Jahr`,
      annualActionsPieChart: 'Kreisdiagramm der jährlichen Aktionen',
      incidentsHeatMap: 'Vorfälle Heatmap',
      annualActions: 'Jährliche Aktionen',
      totals: 'Summen',
      totalDetected: 'Insgesamt erkannt',
      totalReported: 'Insgesamt gemeldet',
      totalVisited: 'Insgesamt besucht',
      totalCleaned: 'Total gereinigt',
      generalProfileSettings: 'Allgemeine Profileinstellungen',
      districtOverview: 'Bezirksübersicht',
      incidentVisitDetails: 'Besuchsdetails des Vorfalls',
      incidentVisitReport: 'Vorfall-Besuchsbericht',
      aboutVisit: 'Über Besuch',
      notes: 'Anmerkungen',
      incidentCleanedReport: 'Vorfallsbereinigter Bericht',
      aboutCleanOperation: 'Über sauberen Betrieb',
      emailAlreadyExists: 'E-Mail ist bereits vorhanden!',
      notAValidEmail: 'Keine gültige E-Mail!',
      tooManyRegistrationRequest: 'Zu viele Registrierungsanfragen von IP :',
      pleaseTryAgain120Hours: 'Bitte versuchen Sie es nach 120 Stunden erneut.',
      beforeFillingtheForm: 'Bitte vor dem Ausfüllen des Formulars auswählen',
      recentStatistics: `Aktuelle Statistiken (letzte 12 Wochen)`,
    }),

    labels: {
      loginUsername: 'Benutzername oder E-Mail-Adresse',
      username: 'Nutzername',
      login: 'Anmeldung',
      email: 'Email',
      name: 'Name',
      surname: 'Familien-oder Nachname',
      forgotPassword: 'Passwort vergessen?',
      signUp: 'Anmeldung',
      signIn: 'Anmelden',
      needAccount: 'Brauche ein Konto?',
      password: 'Passwort',
      newpassword: 'Neues Kennwort',
      oldpassword: 'Altes Passwort',
      confirmPassword: 'Kennwort bestätigen',
      confirmNewPassword: 'Bestätige neues Passwort',
      verificationCode: 'Verifizierungs-Schlüssel',
      save: 'Speichern',
      submit: 'Einreichen',
      loading: 'die Beladung',
      registering: 'Registrieren',
      yes: 'Ja',
      no: 'Nein',
      send: 'Senden',
      message: 'Nachricht',
      additionalNotes: 'Zusätzliche Bemerkungen',
      subject: 'Thema',
      remaining: 'übrig',
      to: 'Zu',
      attachCurrent: 'Aktuellen Vorfallbericht beifügen',
      attachFile: 'Eine Datei anhängen',
      bold: 'Fetter Text',
      italic: 'Kursiver Text',
      textColor: 'Textfarbe',
      fontSize: 'Schriftgröße',
      sendACopyToMyEmail: 'Senden Sie eine Kopie an meine E-Mail',
      nameOfAuthorizedPerson: 'Name der verbundenen (autorisierten) Person',
      selectCountry: 'Land auswählen',
      continue: 'Fortsetzen',
      multiple: 'Mehrere',
      single: 'Einzige',
      dear: 'Sehr geehrter',
      approve: 'Genehmigen',
      haveAccount: 'Sie haben bereits ein Konto?',
      total: 'Gesamt',
      report: 'Bericht',
      reports: 'Berichte',
      archivedReport: 'archivierter Bericht',
      archivedReports: 'archivierte Berichte',
      sendToArchive: 'An Archiv senden',
      reportOf: 'Bericht von',
      selectAll: 'Wählen Sie Alle',
      detectedAt: 'festgestellt bei',
      outbox: 'Postausgang',
      archive: 'Archiv',
      resend: 'Erneut senden',
      restore: 'Wiedergeben',
      dashboard: 'Armaturenbrett',
      detectedPerWeek: 'Erkannt pro Woche',
      cleanedPerWeek: 'Pro Woche gereinigt',
      visitedPerWeek: 'Besucht pro Woche',
      reportedPerWeek: 'Pro Woche gemeldet',
      country: 'das Land',
      registered: 'Eingetragen',
      loggedIn: 'Eingeloggt',
      filterByOrganization: 'Nach Organisation filtern',
      filterByDate: 'Vorfälle nach Datum filtern',
      days: 'Tage',
      range: 'Bereich',
      delete: 'Löschen',
      deleting: 'Löschen',
      visitedBy: 'Besucht von',
      visitedAt: 'Besucht bei',
      notesAboutVisit: 'Optional - Hinweise zum Besuch',
      signVisited: 'Zeichen besucht',
      error: 'Fehler!',
      createdBy: 'Erstellt von',
      createdAt: 'Hergestellt in',
      goToOutbox: 'Gehen Sie zum Postausgang',
      select: 'Wählen',
      close: 'Schließen',
      directions: 'Richtungen',
      Report: 'Bericht',
      reportSummary: 'Zusammenfassung',
      visited: 'Hat besucht',
      visitReport: 'Bericht besuchen',
      markCleaned: 'Gesäubert markieren',
      cleanedReport: 'Gereinigter Bericht',
      noIncident: 'Kein Vorfall',
      startedAt: 'Fing an bei',
      cleanedAt: 'Gereinigt bei',
      cleanOperationTook: 'Saubere Operation dauerte',
      noInfo: 'Keine Informationen',
      cleanedBy: 'Gereinigt von',
      equipmentUsed: 'Gebrauchte Ausrüstung',
      List: 'Liste',
      Map: 'Karte',
      All: 'Alles',
      noAction: 'Keine Aktion',
      guestSettings: 'Gasteinstellungen',
      incidentsList: 'Vorfälle',
    },

    descriptions: {
      targetCountrySelectionDescription:
        'Die Auswahl des Landes hilft uns, Provinzen und Bezirke zu filtern, und hilft Ihnen auch dabei, den Zielbezirk unter geringeren Optionen einfach auszuwählen.',
      targetDistrictSelectionDescription:
        'Die Auswahl des Distrikts hilft uns, die bestehende Liste der Organisationen zu filtern.',

      organizationSelectionDescription:
        'Um Ihnen einen besseren Service bieten zu können, benötigen wir Ihren Organisationstyp.',
      provinceSelectionDescription:
        'Die Auswahl der Provinz hilft uns, Bezirke zu filtern, und hilft Ihnen auch, den Zielbezirk unter den geringeren Optionen einfach auszuwählen.',
      registrationSuccessMessagePartI_registerSuccess:
        'Danke sehr; Sie haben Periopsis erfolgreich registriert.',
      registrationSuccessMessagePartII_temporaryUserName:
        'Bitte speichern Sie Ihren temporären Benutzernamen:',
      registrationSuccessMessagePartIII_authStepsExplanation: `Bitte überprüfen Sie die unten aufgeführten Schritte. Nach der Genehmigung werden Sie zur Anmeldeseite weitergeleitet, aber vor der Anmeldung haben Sie, wie in der folgenden Liste erwähnt, zwei weitere Schritte, um mit der Nutzung der Periopsis-Dienste zu beginnen.`,

      registrationSuccessListMessages: {
        checkYourEmail:
          'Bitte überprüfen Sie Ihre E-Mail-Adresse und bestätigen Sie Ihre E-Mail.',
        afterEmailConfirmation: `Nach der E-Mail-Bestätigung wird Ihr Konto von unserem Team überprüft und wenn alles in Ordnung ist, wird Ihr Konto genehmigt`,
        afterAdminApproval: `Nach der Genehmigung Ihres Kontos erhalten Sie einen einmaligen Anmeldeschlüssel, den Sie eingeben müssen, um Ihr Konto zu entsperren, und mit Ihrem Benutzernamen oder Ihrer E-Mail-Adresse und Ihrem Passwort können Sie Ihr Konto betreten`,
      },
      youHaveSuccessfullyVerifiedAccountEmail:
        'Sie haben Ihre Konto-E-Mail erfolgreich bestätigt. Sobald Sie eine Kontogenehmigungs-E-Mail vom Administrator erhalten, können Sie sich bei Ihrem Konto anmelden.',
      allReportsOlderthanSixMonths:
        'Alle Berichte, die älter als 6 Monate sind oder archiviert wurden, werden im Archiv gespeichert. Sie konnten einen gesuchten Bericht hier nicht finden, bitte sehen Sie im Archiv nach',
      slogan:
        'Lokalisieren Sie die Vorfälle und ergreifen Sie die erforderlichen Maßnahmen',
    },

    NoDataMessages: {
      noReportFound: 'Kein Bericht gefunden',
      noReportFoundForThisIncident:
        'Für diesen Vorfall wurde kein Bericht gefunden, alle Berichte wurden archiviert oder es wurde kein Bericht für diesen Vorfall gesendet.',
      noArchivedReportFound:
        'Für diesen Vorfall wurde kein archivierter Bericht gefunden, alle archivierten Berichte wurden wiederhergestellt oder es wurden keine Berichte an das Archiv gesendet.',

      noStatisticsData: 'Keine Statistikdaten gefunden',
      noAnnualStats: 'Keine Jahresstatistik gefunden',
      noUserData: 'Es wurden keine Benutzerdaten gefunden!',
      noVisitData: 'Keine Besuchsdaten für diesen Bericht gefunden',
      noReportSummary: 'Es wurde keine Berichtszusammenfassung gefunden.',
      allReportsArchived: 'Alle Berichte wurden archiviert',
      noCleanedData: 'Für diesen Bericht wurden keine sauberen Daten gefunden',
      noIncidentFound:
        'Unter den gegebenen Bedingungen wurde kein Vorfall festgestellt.',
    },
    menuLinks: {
      home: 'Startseite',
      projects: 'Projekte',
      about: 'Über',
      news: 'Nachrichten',
      contact: 'Kontakt',
      dashboard: 'Armaturenbrett',
      shrinkMenu: 'Menü verkleinern',
      expandMenu: 'Menü erweitern',
      statistics: 'Statistiken',
      notifications: 'Benachrichtigungen',
      outbox: 'Postausgang',
      user: 'Benutzer',
      settings: 'Die Festsetzung',
      logout: 'Ausloggen',
      team: 'Team',
    },
    homePage: {
      aboutHeading: 'Über uns',
      aboutFirstParagraph:
        'PERIOPSIS LTD ist ein innovatives Unternehmen, das auf Satellitenbildern und Luftaufnahmen basierende Analysedienste anbietet, die modernste Techniken der künstlichen Intelligenz und des maschinellen Sehens einsetzen.',
      aboutSecondParagraph:
        'Die Mission von PERIOPSIS ist es, sich in Europa als hochinnovatives Unternehmen zu etablieren, das sich mit der Nutzung neuer IT-Technologien (Satellitenbilder und Luftbildfotografie, KI, maschinelles und tiefes Lernen, Internet der Dinge) zum Zwecke der groß angelegten Umweltbeobachtung beschäftigt und Modellierung mit potenziellen Kunden aus der Industrie und der Handelswelt bis hin zu Regierungen und staatlichen Stellen, um gemeinsam die Umweltpolitik zu gestalten.',
      whatWeDo: 'Was machen wir?',
      wwDListElement1:
        'Analysedienste für Satellitenbilder und/oder Luftbildaufnahmen',
      wwDListElement2:
        'Einsatz modernster Techniken der künstlichen Intelligenz und des maschinellen Sehens.',
      wwDListElement3:
        'Nutzung neuer IT-Technologien (Satellitenbilder und Luftbildaufnahmen, KI, maschinelles und tiefes Lernen, Internet der Dinge) zum Zwecke der groß angelegten Umweltbeobachtung und -modellierung',
      wwDListElement4:
        'Gestaltung der Umweltpolitik gemeinsam mit allen relevanten Stakeholdern',
      meetTheTeam: 'Triff das Team',
      managingDirector: 'Der Geschäftsführer',
      operationsManager: 'Betriebsleiter',
      marketingManager: 'Marketing Manager',
      deepLearningScientist: 'Deep Learning Wissenschaftler',
      GISAnalyst: 'GIS Analyst',
      satelliteImageryAnalyst: 'Analyst für Satellitenbilder',
    },
  },

  footerLinks: Object.freeze([
    {
      linkId: 'footer-link-home-00001',
      label: 'Home',
      title: 'You will be forwarded to the top of home page.',
      id: '#home',
      onClick: () => {
        window.scrollTo(0, 0);
      },
      link: undefined,
    },
    {
      linkId: 'footer-link-about-00002',
      label: 'About',
      title: 'About Periopsis',
      id: '#about',
      onClick: undefined,
      link: undefined,
    },
    {
      linkId: 'footer-link-team-00003',
      label: 'Team',
      title: 'Periopsis Team Members',
      id: '#team',
      onClick: undefined,
      link: undefined,
    },
    {
      linkId: 'footer-link-contactus-00004',
      label: 'Contact Us',
      title: 'Contact Support Team of Periopsis',
      id: undefined,
      onClick: undefined,
      link: '/contact-us',
    },
    {
      linkId: 'footer-link-privacy-00005',
      label: 'Privacy',
      title: 'Privacy Terms of Periopsis',
      id: undefined,
      onClick: undefined,
      link: '/privacy_periopsis',
    },
    {
      linkId: 'footer-link-periopsis-00006',
      label: 'Periopsis',
      title: 'More Details About Periopsis and Periopsis Ltd',
      id: undefined,
      onClick: undefined,
      link: '/periopsis_details',
    },
    {
      linkId: 'footer-link-admin-login-00007',
      label: 'Login',
      title: 'Login to the Admin Page',
      id: undefined,
      onClick: () => {
        localStorage.clear();
      },
      link: '/admin',
    },
  ]),
});
