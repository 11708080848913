import { FaInfoCircle } from 'react-icons/fa';
import classes from './CheckboxItem.module.scss';
import { ChangeEvent, useState } from 'react';

interface IProps {
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode | string | JSX.Element | JSX.Element[];
  isSelected?: boolean;
}

const CheckboxItem = ({ label, onChange, children, isSelected }: IProps) => {
  const [show, setShow] = useState(false);

  const showInfoCardHandler = () => {
    setShow((prevState) => !prevState);
  };

  return (
    <label htmlFor={label} className={classes.CheckboxItem}>
      <input
        type='checkbox'
        id={label}
        value={label}
        onChange={onChange}
        checked={isSelected}
      />
      <span>{label}</span>
      {children ? (
        <button
          onMouseEnter={showInfoCardHandler}
          onMouseLeave={showInfoCardHandler}
        >
          <FaInfoCircle />
        </button>
      ) : null}
      {show && children ? (
        <div className={classes.InfoCard}>
          <h3>{label}</h3>
          {children}
        </div>
      ) : null}
    </label>
  );
};

export default CheckboxItem;
