import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  compareTwoInputDate,
  getDateFromInputEnteredDate,
} from '../../../../../../utils/componentUtilFns';
import { AppDispatch } from '../../../../../../app/store';
import {
  selectAllIncidentValues,
  setFromDate,
  setToDate,
} from '../../../../../../features/incidents/incidentsSlice';
import classes from './RangeFilter.module.scss';
import { useTranslation } from 'react-i18next';

function isValidDate(dateString: string) {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
}

const RangeFilter = () => {
  const { fromDate, toDate } = useSelector(selectAllIncidentValues);
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const storedFromDate = localStorage.getItem('from-date');
    const storedToDate = localStorage.getItem('to-date');
    let fromDateTimeStamp = 0;
    let toDateTimeStamp = 0;

    if (storedFromDate) {
      dispatch(setFromDate(storedFromDate));
      const dateValuesFromDate = getDateFromInputEnteredDate(storedFromDate);
      fromDateTimeStamp = dateValuesFromDate.inTime;
    }

    if (storedToDate) {
      const dateValuesToDate = getDateFromInputEnteredDate(storedToDate);
      toDateTimeStamp = dateValuesToDate.inTime;

      if (toDateTimeStamp > fromDateTimeStamp) {
        dispatch(setToDate(storedToDate));
      }
    }
  }, [dispatch]);

  const fromDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fromDate = e.target.value;
    const isValidFromDate = isValidDate(fromDate);

    if (isValidFromDate) {
      dispatch(setFromDate(fromDate));
      localStorage.setItem('from-date', fromDate);
    }
  };

  const toDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const toDate = e.target.value;
    const isValidToDate = isValidDate(toDate);

    if (isValidToDate) {
      dispatch(setToDate(toDate));
      localStorage.setItem('to-date', toDate);
    }
  };

  const validationResult = compareTwoInputDate(fromDate, toDate);
  const isValidFromDate = isValidDate(fromDate);
  const isValidToDate = isValidDate(toDate);

  return (
    <div className={classes.RangeSelectInputSection}>
      <label
        htmlFor='from-date'
        className={`${classes.DateSelectionContainer} ${
          validationResult.isFutureFromDate || !isValidFromDate
            ? classes.InvalidDate
            : ''
        }`}
      >
        {t('dashboard:From')}
        <input
          type='date'
          name='from-date'
          id='from-date'
          value={fromDate}
          onChange={fromDateHandler}
        />
      </label>
      <span className={classes.Separator}>-</span>
      <label
        htmlFor='to-date'
        className={`${classes.DateSelectionContainer} ${
          validationResult.isToDateLessThanFromDate || !isValidToDate
            ? classes.InvalidDate
            : ''
        }`}
      >
        {t('dashboard:To')}
        <input
          type='date'
          name='from-date'
          id='to-date'
          value={toDate}
          onChange={toDateHandler}
        />
      </label>
    </div>
  );
};

export default RangeFilter;
