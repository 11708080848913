import { Fragment, useEffect } from 'react';
import codeEnvelope from '../../../../../assets/images/2FactorAuth.png';
import AuthCodeFormDescriptionSection from '../AuthCodeFormDescriptionSection/AuthCodeFormDescriptionSection';
import Form from '../../../../UI/FormElements/Form/Form';
import Input from '../../../../UI/FormElements/Input/Input';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import useTimer from '../../../../../CustomHooks/useTimer';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import Button from '../../../../UI/FormElements/Button/Button';
import { useResend2FAMutation } from '../../../../../features/auth/authSlice';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import ResendProcess from '../../ResendProcess/ResendProcess';
import classes from './TwoFactorAuth.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSubmit: (e: React.FormEvent) => void;
  onAuthCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  authCodeValue: string;
  onResetEntry: () => void;
  isLoading: boolean;
  password: string;
  username: string;
}

const TwoFactorAuth = ({
  onSubmit,
  onAuthCodeChange,
  onResetEntry,
  authCodeValue,
  isLoading,
  password,
  username,
}: IProps) => {
  const [resend2FATwoCode, { isLoading: isResendLoading }] =
    useResend2FAMutation();
  const { t } = useTranslation();
  const { timerFormat, isTimerStarted, startTimer } = useTimer({
    duration: 180,
  });

  useEffect(() => {
    startTimer();
    // eslint-disable-next-line
  }, []);

  const resendTwoFACodeHandler = async () => {
    try {
      const res = await resend2FATwoCode({ password, username }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        startTimer();
        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('auth:two_factor_send_success')}
            heading={t('auth:2fa_resend')}
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    } finally {
      onResetEntry();
    }
  };

  const submitTwoFACodeHandler = (e: React.FormEvent) => {
    onSubmit(e);
  };

  return (
    <Fragment>
      <AuthCodeFormDescriptionSection
        image={codeEnvelope}
        heading={t('auth:two_factor_auth')}
        description={t('auth:verification_code_send_message')}
      />
      <Form onSubmit={submitTwoFACodeHandler} className={classes.LoginForm}>
        <div>
          <Input
            type='password'
            name='twoFACode'
            label={t('auth:2fa_code_label')}
            value={authCodeValue}
            onChange={onAuthCodeChange}
            hasShowPasswordButton
            disabled={isResendLoading || isLoading}
            autoComplete='password'
            title={t('auth:2fa_code_title')}
          />

          <ResendProcess
            isResending={isResendLoading}
            onResend={resendTwoFACodeHandler}
            timer={timerFormat}
            isTimerStarted={isTimerStarted}
          />
        </div>

        <ActionContainer className={classes.Actions}>
          {!isResendLoading && !isLoading ? (
            <Button
              btnClass={classes.LoginButton}
              type={!authCodeValue ? 'button' : 'submit'}
              isFake={!authCodeValue}
            >
              {t('translations:login')}
            </Button>
          ) : (
            <SimpleLoading />
          )}
        </ActionContainer>
      </Form>
    </Fragment>
  );
};

export default TwoFactorAuth;
