import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    partialBundledLanguages: true,
    fallbackLng: 'en',
    supportedLngs: ['en', 'el'],
    ns: [
      'translations',
      'dashboard',
      'incidentDashboard',
      'forms',
      'visitFormInfo',
      'reportFormInfo',
      'incidentVisitReport',
      'incidentCleanedForm',
      'cleanedFormInfo',
      'auth',
      'registration',
      'checkout',
      'forgotpass',
      'passwordupdate',
      'notifications',
      'statistics',
      'reporting',
      'profilesettings',
      'settings',
      'admin',
    ],
    backend: {
      //loadPath: `https://portal.periopsis.com/locales/{{lng}}/{{ns}}.json`, //for production
      loadPath:
        process.env.NODE_ENV === 'development'
          ? `http://localhost:3000/locales/{{lng}}/{{ns}}.json`
          : `https://portal.periopsis.com/locales/{{lng}}/{{ns}}.json`, //for development
    },
  });

export default i18n;
