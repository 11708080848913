import classes from './CodeView.module.scss';

interface IProps {
  codeArray: { code: string; id: string }[];
  timer: string;
}

const CodeView = ({ codeArray, timer }: IProps) => {
  const isEmpty = codeArray.every((item) => item.code === '');

  if (
    (codeArray && Array.isArray(codeArray) && codeArray.length === 0) ||
    !codeArray ||
    !Array.isArray(codeArray)
  ) {
    return null;
  }

  return (
    <div
      className={`${classes.CodeViewContainer} ${
        isEmpty ? classes.Centered : ''
      }`}
    >
      {!isEmpty ? (
        <div className={classes.CodeItems}>
          {codeArray.map((item, index) => {
            if (index < codeArray.length - 1) {
              return (
                <div key={item.id} className={classes.CodeItem}>
                  <span>{item.code.toUpperCase()}</span>
                  {item.code ? <span className={classes.CodeDot}></span> : null}
                </div>
              );
            } else {
              return (
                <div key={item.id} className={classes.CodeItem}>
                  <span>{item.code.toUpperCase()}</span>
                </div>
              );
            }
          })}
        </div>
      ) : null}
      <div>
        <span>{timer}</span>
      </div>
    </div>
  );
};

export default CodeView;
