import { Fragment } from 'react';
import { FaUndo } from 'react-icons/fa';
import { IoClose } from 'react-icons/io5';
import { BiEditAlt, BiLock } from 'react-icons/bi';
import classes from './NoteContainer.module.scss';

interface IProps {
  willEditNotes: boolean;
  onUndoEdit: () => void;
  onCloseUpdateNotes: () => void;
  onUpdateNotes: () => void;
  hasAuthToActions?: boolean;
  updatedNotes: string;
  isLoading?: boolean;
  children: React.ReactNode;
}

const NoteContainer = ({
  willEditNotes,
  onUndoEdit,
  hasAuthToActions,
  updatedNotes,
  onCloseUpdateNotes,
  onUpdateNotes,
  isLoading,
  children,
}: IProps) => {
  const editNotesIcon = willEditNotes ? (
    <div className={classes.EditNotesActions}>
      <FaUndo color='#d95c2d' size={18} onClick={onUndoEdit} />
      <IoClose
        onClick={hasAuthToActions ? onCloseUpdateNotes : () => {}}
        color='#d95c2d'
        size={25}
      />
    </div>
  ) : (
    <BiEditAlt
      color='#004c6c'
      onClick={hasAuthToActions ? onUpdateNotes : () => {}}
      style={{ cursor: 'pointer' }}
    />
  );

  return (
    <div
      className={`${classes.Section} ${
        willEditNotes ? classes.RemoveBorder : ''
      }`}
    >
      <div className={`${classes.NoteContainer}`}>
        {!willEditNotes ? (
          <p>{updatedNotes}</p>
        ) : (
          <Fragment>{children}</Fragment>
        )}
        {hasAuthToActions && !isLoading ? (
          editNotesIcon
        ) : (
          <BiLock color='#d95c2d' style={{ cursor: 'not-allowed' }} />
        )}
      </div>
    </div>
  );
};

export default NoteContainer;
