import classes from './NotificationsSectionContainer.module.scss';

interface IProps {
  children: React.ReactNode;
  headingContent?: React.ReactNode;
}

const NotificationsSectionContainer = ({
  children,
  headingContent,
}: IProps) => {
  return (
    <div className={classes.NotificationsListContainer}>
      {headingContent ? (
        <div className={classes.HeadingContainer}>{headingContent}</div>
      ) : null}

      {children}
    </div>
  );
};

export default NotificationsSectionContainer;
