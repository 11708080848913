import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { IContractIncident, IVisitor } from '../../../../../tsTypes/interfaces';
import Form from '../../../../UI/FormElements/Form/Form';
import Input from '../../../../UI/FormElements/Input/Input';
import classes from './IncidentVisitForm.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllAuthValues } from '../../../../../features/auth/authSlice';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import {
  formatDateForInputFormState,
  getDateFromInputEnteredDate,
  validateNameSurname,
  validateText,
} from '../../../../../utils/componentUtilFns';
import useFormEntry from '../../../../../CustomHooks/useFormEntry';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { nanoid } from '@reduxjs/toolkit';
import { confirmAlert } from 'react-confirm-alert';
import TextArea from '../../../../UI/FormElements/TextArea/TextArea';
import FormModal from '../../../../UI/FormModal/FormModal';
import VisitFormInformation from './VisitFormInformation/VisitFormInformation';
import { useMarkIncidentVisitedMutation } from '../../../../../features/incidents/incidentsSlice';
import AddVisitorsForm from '../AddVisitorsForm/AddVisitorsForm';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import ObservedIncidentType from '../ObservedIncidentType/ObservedIncidentType';
import { periopsisApi } from '../../../../../features/api/apiSlice';
import { AppDispatch } from '../../../../../app/store';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

interface IProps {
  selectedIncident: IContractIncident;
  onClose: () => void;
  showVisitForm: boolean;
}

const validateEnteredText = (text: string) => validateText(text, 3, 100);
const validateEnteredNotes = (text: string) => validateText(text, 0, 1500);

const IncidentVisitForm = ({
  selectedIncident,
  onClose,
  showVisitForm,
}: IProps) => {
  const [showFormInfo, setShowFormInfo] = useState(false);
  const [markIncidentVisited, { isLoading }] = useMarkIncidentVisitedMutation();
  let content: React.ReactNode | null = null;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const {
    value: name,
    isValid: isValidName,
    entryHandler: nameEntryHandler,
    clearEntry: clearName,
  } = useFormEntry(validateNameSurname, 'visitor-name', '', true);

  const {
    value: surname,
    isValid: isValidSurname,
    entryHandler: surnameEntryHandler,
    clearEntry: clearSurname,
  } = useFormEntry(validateNameSurname, 'visitor-surname', '', true);

  const {
    value: department,
    isValid: isValidDepartment,
    entryHandler: departmentEntryHandler,
    clearEntry: clearDepartment,
  } = useFormEntry(validateEnteredText, 'visitor-department', '', true);

  const {
    value: duty,
    isValid: isValidDuty,
    entryHandler: dutyEntryHandler,
    clearEntry: clearDuty,
  } = useFormEntry(validateEnteredText, 'visitor-duty', '', true);

  const {
    value: observedType,
    isValid: isValidObservedType,
    entryHandler: observedTypeEntryHandler,
    clearEntry: clearObservedType,
    setter: addObserveredType,
  } = useFormEntry(validateEnteredText, 'observed-type', '', true);

  const {
    value: visitNotes,
    isValid: isValidVisitNotes,
    entryHandler: visitNotesEntryHandler,
    clearEntry: clearVisitNotes,
  } = useFormEntry(validateEnteredNotes, 'visit-notes');

  const [visitors, setVisitors] = useState<IVisitor[]>([]);
  const [visitDate, setVisitDate] = useState('');
  const { user } = useSelector(selectAllAuthValues);
  let reportedBy = '';

  const setVisitDateToCurrentDate = useCallback(() => {
    const today = new Date();
    const dateValue = formatDateForInputFormState(today);
    setVisitDate(dateValue);
  }, []);

  useEffect(() => {
    const storedVisitors = localStorage.getItem('visitors-list');

    if (storedVisitors) {
      const parsedArray = JSON.parse(storedVisitors);
      setVisitors(parsedArray);
    }
  }, []);

  useEffect(() => {
    const storedVisitDate = localStorage.getItem('visit-date');

    if (storedVisitDate) {
      setVisitDate(storedVisitDate);
    } else {
      setVisitDateToCurrentDate();
    }
  }, [setVisitDateToCurrentDate]);

  if (!selectedIncident) {
    return null;
  }

  if (user) {
    reportedBy = `${user.name} ${user.surname}`;
  }

  const visitDateEntryHandler = (e: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('visit-date', e.target.value);
    setVisitDate(e.target.value);
  };

  const resetVisitorForm = () => {
    clearName();
    clearSurname();
    clearDepartment();
    clearDuty();
  };

  const resetVisitorFromHandler = () => {
    clearName();
    clearSurname();
    clearDepartment();
    clearDuty();
    clearObservedType();
    setVisitDateToCurrentDate();
    clearVisitNotes();
    setVisitors([]);
    localStorage.removeItem('visitors-list');
    localStorage.removeItem('periopsis-reg-visit-notes');
  };

  const addVisitorHandler = () => {
    if (!name && !surname && !department && !duty) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:visitors_details_error')}
          heading={t('forms:visitors_error_heading')}
        />
      );
    }

    if (!name) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:name_error_message')}
          heading={t('forms:name_error_heading')}
        />
      );
    }

    if (!surname) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:surname_error_message')}
          heading={t('forms:surname_error_heading')}
        />
      );
    }

    if (!department) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:department_error_message')}
          heading={t('forms:department_error_heading')}
        />
      );
    }

    if (!duty) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:duty_error_message')}
          heading={t('forms:duty_error_heading')}
        />
      );
    }

    if (visitors.length >= 25) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('forms:visitors_limit_error')}
          heading={t('forms:limit_error_heading')}
        />
      );
    }

    if (name && surname && department && duty) {
      const existingVisitor = visitors.find(
        (visitor) =>
          visitor.name.toLowerCase() === name.toLowerCase() &&
          visitor.surname.toLowerCase() === surname.toLowerCase()
      );

      if (existingVisitor) {
        return confirmAlert({
          title: t('forms:existing_visitor_heading'),
          message: `${t('forms:existing_visitor_message')} ${
            existingVisitor.name
          } ${existingVisitor.surname}. ${t('forms:message_end_question')}`,
          buttons: [
            {
              label: t('incidentDashboard:Yes'),
              onClick: () => {
                const newVisitor = {
                  name,
                  surname,
                  department,
                  duty,
                  id: nanoid(),
                };
                const storedData = [newVisitor, ...visitors];
                localStorage.setItem(
                  'visitors-list',
                  JSON.stringify(storedData)
                );
                setVisitors((prevState) => [newVisitor, ...prevState]);
                resetVisitorForm();
              },
            },
            {
              label: t('incidentDashboard:No'),
              onClick: () => resetVisitorForm(),
            },
          ],
        });
      }

      const newVisitor = { name, surname, department, duty, id: nanoid() };
      const storedData = [newVisitor, ...visitors];
      localStorage.setItem('visitors-list', JSON.stringify(storedData));
      setVisitors((prevState) => [newVisitor, ...prevState]);
      resetVisitorForm();
    }
  };

  const removeVisitorHandler = (id: string) => {
    const newList = visitors.filter((visitor) => visitor.id !== id);

    setVisitors(newList);
  };

  const closeVisitFormHandler = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
      resetVisitorFromHandler();
    }
  };

  const showVisitFormInformationHandler = () => {
    setShowFormInfo((prevState) => !prevState);
  };

  const submitVisitedHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (
        selectedIncident &&
        user &&
        typeof user === 'object' &&
        'id' in user &&
        user.id
      ) {
        let dateOfVisit: Date | null = null;
        let isFutureDate = false;

        if (visitDate) {
          const dateResults = getDateFromInputEnteredDate(visitDate);
          dateOfVisit = dateResults.dateValue;
          isFutureDate = dateResults.isFutureDate;
        }

        if (isFutureDate) {
          return toast.error(
            <ToastifyStyledMessage
              singleMessage={t('forms:future_date_error')}
              heading={t('forms:future_date_heading')}
            />
          );
        }

        if (visitors.length > 25) {
          return toast.error(
            <ToastifyStyledMessage
              singleMessage={t('forms:visitors_limit_error')}
              heading={t('forms:limit_error_heading')}
            />
          );
        }

        const reportData = {
          contractIncidentId: selectedIncident._id,
          contractId: selectedIncident.contractId,
          visitReportedBy: user.id,
          dateOfVisit: dateOfVisit ? dateOfVisit : new Date(),
          visitors,
          observedIncident: {
            typeId: observedType !== '' ? nanoid() : '',
            typeName: observedType,
          },
          notes: visitNotes,
          detectedType: selectedIncident.type,
        };

        const res = await markIncidentVisited(reportData).unwrap();

        if (
          res &&
          typeof res === 'object' &&
          'isSuccess' in res &&
          res.isSuccess
        ) {
          dispatch(periopsisApi.util.invalidateTags(['action-statistics']));
          resetVisitorFromHandler();
          closeVisitFormHandler();
        }
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const isFakeAddButton =
    !isValidName ||
    !isValidSurname ||
    !isValidDuty ||
    !isValidDepartment ||
    name === '' ||
    surname === '' ||
    duty === '' ||
    department === '';

  if (showFormInfo) {
    content = (
      <VisitFormInformation onClose={showVisitFormInformationHandler} />
    );
  } else {
    content = (
      <Form
        className={classes.VisitDetailsFrom}
        onSubmit={submitVisitedHandler}
      >
        {/* <Input
          type='text'
          value={selectedIncident._id}
          name='Contract_Incident_Id'
          disabled
          label='Contract Incident Id'
          readOnly
        />
         */}
        {/* <Input
          type='text'
          value={selectedIncident.contractId.substring(0, 30)}
          name='Contract_ID'
          disabled
          label='Contract ID'
          readOnly
        /> */}
        <Input
          type='text'
          value={`${t(
            `dashboard:${getTranslationKey(selectedIncident.type)}`
          )} ${
            selectedIncident.observedType.typeName
              ? `| ${selectedIncident.observedType.typeName}`
              : ''
          }`}
          name='Incident_Type'
          disabled
          label={t('incidentDashboard:Incident_Type')}
          readOnly
        />
        <Input
          type='text'
          value={reportedBy}
          name='reported_by'
          disabled
          label={t('forms:Reported_By')}
          readOnly
        />
        <Input
          type='date'
          value={visitDate}
          name='visit-date'
          label={t('forms:Date_of_Visit')}
          required
          isRequired
          onChange={visitDateEntryHandler}
        />
        <ObservedIncidentType
          value={observedType}
          isValid={isValidObservedType}
          onChange={observedTypeEntryHandler}
          onSelect={addObserveredType}
        />
        <TextArea
          id='visit-notes'
          name='visit-notes'
          label={t('forms:Notes_about_Observations')}
          maxLength={1500}
          title={t('forms:Notes_about_Observations')}
          value={visitNotes}
          invalid={!isValidVisitNotes}
          errorMessage={t('forms:notes_error_message')}
          onChange={visitNotesEntryHandler}
          showRemaining
        />

        <AddVisitorsForm
          handler={{
            nameEntryHandler,
            surnameEntryHandler,
            departmentEntryHandler,
            dutyEntryHandler,
            resetVisitorForm,
            addVisitorHandler,
            removeVisitorHandler,
          }}
          validators={{
            isValidName,
            isValidSurname,
            isValidDuty,
            isValidDepartment,
            isFakeAddButton,
          }}
          value={{ name, surname, department, duty }}
          visitors={visitors}
          isLoading={isLoading}
          heading={t('forms:Add_Visitors')}
          listHeading={t('forms:Added_Visitors')}
          information={t('forms:visitors_info')}
        />

        <ActionContainer
          className={`${classes.VisitorsFormActions} ${classes.MainActions}`}
        >
          {observedType && visitors.length > 0 && !isLoading ? (
            <StandardButton
              type='button'
              btnType='orange'
              label={t('forms:Reset_Form')}
              onClick={resetVisitorFromHandler}
            />
          ) : null}
          <StandardButton
            type='submit'
            btnType='primary'
            label={t('forms:Mark_Visited')}
            fakeButton={isLoading}
          />
        </ActionContainer>
      </Form>
    );
  }

  return (
    <FormModal
      show={showVisitForm}
      onClick={closeVisitFormHandler}
      heading={
        !showFormInfo
          ? t('forms:Incident_Visit_Details')
          : t('forms:About_Visit_Details_Form')
      }
      onShowInformation={showVisitFormInformationHandler}
      showInfo={showFormInfo}
    >
      {content}
    </FormModal>
  );
};

export default IncidentVisitForm;
