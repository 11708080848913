import { useTranslation } from 'react-i18next';
import { paymentType } from '../../../../../../../../tsTypes/types.components';
import { Currency } from '../../../../../../../../tsTypes/types.model';
import { fullPaymentCalculator } from '../../../../../../../../utils/fullPaymentCalculator';
import classes from './PaymentTypeTotal.module.scss';

interface IProps {
  paymentType: paymentType;
  currency: Currency;
  totalAmount: number;
  discountRateForFullPayment: number;
  packageDuration: number;
  VATRate: number;
}

const PaymentTypeTotal = ({
  paymentType,
  currency,
  discountRateForFullPayment,
  totalAmount,
  packageDuration,
  VATRate,
}: IProps) => {
  const { totalDiscountAmount, overallTotal } = fullPaymentCalculator({
    totalPricePerMonth: totalAmount,
    discountRateForFullPayment,
    VatRate: VATRate,
    packageDuration,
  });

  const { t } = useTranslation();

  if (paymentType === 'monthly-payment') {
    return (
      <div className={classes.TotalPaymentByPaymentType}>
        <h3>{t('checkout:total_payment')}</h3>{' '}
        <data>
          {currency}
          {(totalAmount * (1 + VATRate / 100)).toFixed(2)}/{t('auth:month')}
        </data>
      </div>
    );
  } else {
    return (
      <div>
        <div className={classes.TotalPaymentByPaymentType}>
          <h3>{t('checkout:full_payment_discount_rate')}</h3>{' '}
          <data>{discountRateForFullPayment.toFixed(2)}%</data>
        </div>
        <div className={classes.TotalPaymentByPaymentType}>
          <h3>{t('checkout:full_payment_discount')}</h3>{' '}
          <data>
            {currency}
            {totalDiscountAmount.toFixed(2)}
          </data>
        </div>
        <div className={classes.TotalPaymentByPaymentType}>
          <h3>{t('checkout:total_payment')}</h3>{' '}
          <data>
            {currency}
            {overallTotal.toFixed(2)}
          </data>
        </div>
      </div>
    );
  }
};

export default PaymentTypeTotal;
