import axios from 'axios';
import { frontendConstants } from '../../constants/constants';
import {
  IUsernameCheckQuery,
  IUserQuery,
  Session,
} from '../../tsTypes/types.components';

const API_URL = `${frontendConstants.backendHost}/${frontendConstants.apiVersion}/users`;

const checkUsernameExistance = async (
  usernameCheckData: IUsernameCheckQuery
) => {
  const res = await axios.post(
    `${API_URL}/checkusername`,
    { username: usernameCheckData.username },
    {
      headers: {
        Authorization: `Bearer ${usernameCheckData.token}`,
        'X-API-KEY': usernameCheckData.pToken,
      },
    }
  );

  return res.data;
};

/**
 *
 * @TODO : Create our own API for ip check and do not send request directly to ipify
 */
//Create new API at backend and to get user IP send request to that API
const getUserIP = async () => {
  const response = await axios.get(`${API_URL}/userIp`);

  return response.data;
};

const getLoggedInUser = async (session: Session) => {
  const response = await axios.get(`${API_URL}/user`, {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'X-API-KEY': session.pToken,
    },
  });

  return response.data;
};

const getUserById = async (userQuery: IUserQuery) => {
  const response = await axios.get(`${API_URL}/user/${userQuery.userId}`, {
    headers: {
      Authorization: `Bearer ${userQuery.token}`,
      'X-API-KEY': userQuery.pToken,
    },
  });

  if (response.status === 200) {
    const pToken = response.data.pToken;
    localStorage.setItem('pToken', pToken);
  }

  return response.data;
};

const getUserAuthorizationByContractId = async (contractId: string) => {
  const userData = localStorage.getItem('uData');
  const pToken = localStorage.getItem('pToken');
  let xapikey = '';
  let authKey = '';

  if (pToken) {
    xapikey = pToken;
  }

  if (userData) {
    const parsedData = JSON.parse(userData);
    authKey = parsedData.token;
  }

  const response = await axios.get(`${API_URL}/authorization/${contractId}`, {
    headers: {
      Authorization: `Bearer ${authKey}`,
      'X-API-KEY': xapikey,
    },
  });

  return response.data;
};

const authService = {
  getUserIP,
  getLoggedInUser,
  getUserById,
  checkUsernameExistance,
  getUserAuthorizationByContractId,
};

export default authService;
