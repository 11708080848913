import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../app/store';
import { selectAllAuthValues } from '../features/auth/authSlice';
import { selectAllUIValues, setLanguage } from '../features/UI/uiSlice';

const useLanguage = () => {
  const { user } = useSelector(selectAllAuthValues);
  const { language } = useSelector(selectAllUIValues);
  const dispatch = useDispatch<AppDispatch>();
  const isLanguageAssigned = useRef(false);

  let userPreferredLanguage: string | null = null;

  if (user && user.language) {
    userPreferredLanguage = user.language;
  }

  useEffect(() => {
    if (!isLanguageAssigned.current) {
      let storedLanguage = localStorage.getItem('language');

      if (userPreferredLanguage && userPreferredLanguage !== '') {
        localStorage.setItem('language', userPreferredLanguage);
        dispatch(setLanguage(userPreferredLanguage));
      }

      if (!userPreferredLanguage && !storedLanguage && !language) {
        localStorage.setItem('language', 'en');
        dispatch(setLanguage('en'));
      }

      if (!userPreferredLanguage && language) {
        localStorage.setItem('language', language);
        dispatch(setLanguage(language));
      }

      if (!userPreferredLanguage && storedLanguage) {
        localStorage.setItem('language', storedLanguage);
        dispatch(setLanguage(storedLanguage));
      }

      isLanguageAssigned.current = true;
    }
    // eslint-disable-next-line
  }, [userPreferredLanguage, language]);

  // language = localStorage.getItem('language');

  if (language) {
    return { language };
  } else {
    return { language: 'en' };
  }
};

export default useLanguage;
