import { IContractIncident } from '../../../../../../tsTypes/interfaces';
import ReportDataContainer from '../ReportDataContainer/ReportDataContainer';
import DataRowPDF from '../../../../../pdfComponents/UI/DataRowPDF/DataRowPDF';
import { Link, View } from '@react-pdf/renderer';
import getIncidentLocationLink from '../../../../../../utils/getIncidentLocationLink';
import { styles } from './IncidentDetails.style';

interface IProps {
  selectedIncident: IContractIncident;
  incidentType: string;
}

const IncidentDetailsPDF = ({ selectedIncident, incidentType }: IProps) => {
  return (
    <ReportDataContainer heading='Incident Details'>
      <DataRowPDF label='Incident Type' data={incidentType} />
      <DataRowPDF
        label='Detected At'
        data={new Date(selectedIncident.detectionDate).toLocaleDateString(
          'en',
          { year: 'numeric', month: 'short', day: '2-digit' }
        )}
      />
      <DataRowPDF
        label='Latitude'
        data={selectedIncident.coordinates[0].toFixed(5)}
      />
      <DataRowPDF
        label='Longitude'
        data={selectedIncident.coordinates[1].toFixed(5)}
      />
      <View style={styles.linkConatiner}>
        <Link
          src={`${getIncidentLocationLink(
            selectedIncident.coordinates[0],
            selectedIncident.coordinates[1]
          )}`}
          style={styles.link}
        >
          Incident Location on Map
        </Link>
      </View>
    </ReportDataContainer>
  );
};

export default IncidentDetailsPDF;
