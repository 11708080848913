import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllRegistrationFormValues } from '../features/registration/registrationSlice';

const useFlowData = () => {
  const [isIndividual, setIsIndividual] = useState(false);

  const { selectedOrganizationType } = useSelector(
    selectAllRegistrationFormValues
  );

  const title =
    selectedOrganizationType &&
    'title' in selectedOrganizationType &&
    selectedOrganizationType.title
      ? selectedOrganizationType.title
      : '';

  useEffect(() => {
    if (title) {
      const isIndividualReg = title === 'Individual';
      setIsIndividual(isIndividualReg);
    }
  }, [title]);

  const flowData = [
    {
      id: 'flow-10001',
      label: 'Organization Type',
      pageNumber: 1,
      title: 'Organization Type Selection',
    },
    {
      id: 'flow-20002',
      label: 'Country',
      pageNumber: 2,
      title: 'Service Country Selection',
    },
    {
      id: 'flow-30003',
      label: 'District',
      pageNumber: 3,
      title: 'Service District Selection',
    },
    {
      id: 'flow-40004',
      label: 'Service Type',
      pageNumber: 4,
      title: 'Service Type Selection',
    },
    {
      id: 'flow-50005',
      label: 'Packages',
      pageNumber: 5,
      title: 'Service Package Selection',
    },
    {
      id: 'flow-60006',
      label: isIndividual ? 'Areas' : 'Organization',
      pageNumber: 6,
      title: isIndividual
        ? 'Areas of Interest Selection'
        : 'Organization Selection',
    },
    {
      id: 'flow-70007',
      label: 'Summary',
      pageNumber: 7,
      title: 'Summary of all Selections',
    },
    {
      id: 'flow-80008',
      label: 'Registration',
      pageNumber: 8,
      title: 'Registration Form Section',
    },
    {
      id: 'flow-80009',
      label: 'Check out',
      pageNumber: 9,
      title: 'Check out | Payment Section',
    },
  ];

  return flowData;
};

export default useFlowData;
