import classes from './ContentContainer.module.scss';

interface IProps {
  children: React.ReactNode | JSX.Element[] | JSX.Element;
  gap?: string;
  className?: string;
}

const ContentContainer = ({ children, gap, className }: IProps) => {
  return (
    <section
      className={`${classes.Content} ${className ? className : ''}`}
      style={{ gap }}
    >
      {children}
    </section>
  );
};

export default ContentContainer;
