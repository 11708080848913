import { Fragment, useEffect, useRef, useState } from 'react';
import { IContractIncident } from '../../../../../tsTypes/interfaces';
import IncidentListItem from '../IncidentListItem/IncidentListItem';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import NoIncidentMessageContainer from '../../NoIncidentMessageContainer/NoIncidentMessageContainer';
import ListItemLoader from '../ListItemLoader/ListItemLoader';
import classes from './IncidentList.module.scss';

interface IProps {
  incidents: IContractIncident[];
  totalNumberOfIncidents: number;
}

const IncidentList = ({ incidents, totalNumberOfIncidents }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [loadedIncidents, setLoadedIncidents] = useState<IContractIncident[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const remaining = totalNumberOfIncidents - loadedIncidents.length;

  const numberOfIncidents =
    incidents !== undefined && Array.isArray(incidents) ? incidents.length : 0;

  useEffect(() => {
    if (incidents) {
      const initIncidents = [...incidents].slice(0, 8);
      setLoadedIncidents(initIncidents);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [incidents]);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      let timer: NodeJS.Timeout | null = null;

      const observer = new IntersectionObserver((entries) => {
        if (
          entries &&
          entries[0] &&
          entries[0].isIntersecting &&
          loadedIncidents.length < numberOfIncidents
        ) {
          setLoading(true);
          timer = setTimeout(() => {
            const nextIncidents = [...incidents].slice(
              loadedIncidents.length,
              loadedIncidents.length + 8
            );

            setLoadedIncidents((prevState) => [...prevState, ...nextIncidents]);
            setLoading(false);
          }, 1000);
        }
      });

      observer.observe(containerRef.current);

      return () => {
        observer.disconnect();
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [numberOfIncidents, loadedIncidents.length, incidents]);

  if (!incidents || !Array.isArray(incidents) || incidents.length === 0) {
    return (
      <NoIncidentMessageContainer>
        <NoDataFound
          message={
            "No incident has been found or service couldn't return an incident"
          }
        />
      </NoIncidentMessageContainer>
    );
  }

  const remainingLoaders =
    loading && totalNumberOfIncidents > loadedIncidents.length
      ? Math.min(8, incidents.length, remaining)
      : 0;

  return (
    <div className={classes.MainContainer} ref={containerRef}>
      <div className={classes.IncidentListContainer}>
        {loadedIncidents.map((incident, i) => {
          if (loadedIncidents.length - 1 === i) {
            return (
              <div
                key={incident._id}
                ref={containerRef}
                className={classes.ItemContainer}
              >
                <IncidentListItem incident={incident} />
              </div>
            );
          }

          return (
            <div key={incident._id} className={classes.ItemContainer}>
              <IncidentListItem incident={incident} />
            </div>
          );
        })}

        {loading ? (
          <Fragment>
            {Array.from({ length: remainingLoaders }).map((_, index) => (
              <ListItemLoader key={index} />
            ))}
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default IncidentList;

/***
 *
 */
