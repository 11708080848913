import { shortenText } from '../../../utils/componentUtilFns';
import classes from './Paragraph.module.scss';

interface IProps {
  paragraph: string;
  className?: string;
  style?: React.CSSProperties;
  noShortenMessage?: boolean;
  noEllipsis?: boolean;
}

const Paragraph = ({
  paragraph,
  className,
  style,
  noShortenMessage,
  noEllipsis,
}: IProps) => {
  let paragraphToUse = paragraph;
  let content: React.ReactNode = null;

  if (paragraph.split(' ').length === 1 && paragraph.length > 80) {
    paragraphToUse = `${shortenText(paragraph, 80)}`;
    content = <span>unnecessarily long word shortened</span>;
  }

  return (
    <div
      className={`${classes.ParagraphConatiner} ${className ? className : ''}`}
    >
      <p
        style={style}
        className={`${classes.Paragraph} ${
          noEllipsis ? classes.NoEllipsis : ''
        }`}
      >
        {paragraphToUse}
      </p>
      {!noShortenMessage && content}
    </div>
  );
};

export default Paragraph;
