import { View, Text } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './ReportDataContainer.style';

interface IProps {
  children: React.ReactNode;
  heading: string;
}

const ReportDataContainer = ({ children, heading }: IProps) => {
  return (
    <View style={styles.container}>
      <Text style={styles.heading}>{heading}</Text>
      {children}
    </View>
  );
};

export default ReportDataContainer;
