import { periopsisApi } from '../api/apiSlice';
import getHeaders from '../../utils/getHeaders';
import { Settings } from '../../tsTypes/enums';
import { IResponseData } from '../../tsTypes/types.model';

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getPageSettings: builder.query({
      query: (page: Settings) => {
        const headers = getHeaders();

        return {
          url: `/settings?page=${page}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{ settings: { [key: string]: boolean } }>
      ) => {
        const settings = responseData.data.settings;

        return settings;
      },
    }),
  }),
});

export const { useGetPageSettingsQuery } = extendedApiSlice;
