import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../../../../../pdfComponents/UI/style.constants';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3mm 0',
    padding: '4mm',
    border: `0.3mm solid ${colorConstants.lightGray}`,
    borderRadius: '3mm',
  },
  heading: {
    fontSize: '11pt',
    textTransform: 'uppercase',
    paddingBottom: '1mm',
    marginBottom: '1mm',
    color: colorConstants.periopsisMainColor,
    borderBottom: `0.5mm solid ${colorConstants.periopsisOrange}`,
  },
});
