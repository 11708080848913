import { Fragment } from 'react';
import oneTimeLogin from '../../../../../assets/images/OneTimeLoginKey.png';
import AuthCodeFormDescriptionSection from '../AuthCodeFormDescriptionSection/AuthCodeFormDescriptionSection';
import Form from '../../../../UI/FormElements/Form/Form';
import Input from '../../../../UI/FormElements/Input/Input';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../UI/FormElements/Button/Button';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import classes from './OneTimeLoginKey.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSubmit: (e: React.FormEvent) => void;
  onOneTimeLoginKeyEntry: (e: React.ChangeEvent<HTMLInputElement>) => void;
  oneTimeLoginKey: string;
  isLoading: boolean;
}

const OneTimeLoginKey = ({
  onSubmit,
  oneTimeLoginKey,
  onOneTimeLoginKeyEntry,
  isLoading,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <AuthCodeFormDescriptionSection
        image={oneTimeLogin}
        heading={t('auth:one_time_login_key')}
        description={t('auth:login_key_desc')}
      />
      <Form onSubmit={onSubmit} className={classes.LoginForm}>
        <div>
          <Input
            type='password'
            name='oneTimeLoginKey'
            label={t('auth:one_time_login_key')}
            value={oneTimeLoginKey}
            onChange={onOneTimeLoginKeyEntry}
            hasShowPasswordButton
            disabled={isLoading}
            autoComplete='password'
            title={t('auth:one_time_login_key_title')}
          />
        </div>

        <ActionContainer className={classes.Actions}>
          {!isLoading ? (
            <Button
              btnClass={classes.LoginButton}
              type={!oneTimeLoginKey ? 'button' : 'submit'}
              isFake={!oneTimeLoginKey}
            >
              {t('translations:login')}
            </Button>
          ) : (
            <SimpleLoading />
          )}
        </ActionContainer>
      </Form>
    </Fragment>
  );
};

export default OneTimeLoginKey;
