import { Document, View } from '@react-pdf/renderer';
import {
  IContractIncident,
  IVisitReportResponseData,
  IVisitor,
} from '../../../../../../tsTypes/interfaces';
import ReportLayout from '../../../../../pdfComponents/UI/ReportLayout/ReportLayout';
import NotFound from '../../../../../pdfComponents/UI/NotFound/NotFound';
import IncidentDetailsPDF from '../IncidentDetails/IncidentDetailsPDF';
import ReportDataContainer from '../ReportDataContainer/ReportDataContainer';
import DataRowPDF from '../../../../../pdfComponents/UI/DataRowPDF/DataRowPDF';
import ReportNote from '../ReportNote/ReportNote';
import VisitorsListItem from './VisitorsListItem/VisitorsListItem';
import divideArrayIntoChunks from '../../../../../../utils/divideArrayIntoChunks';

interface IProps {
  isVisited: boolean;
  shouldFetch: boolean;
  data?: IVisitReportResponseData;
  selectedIncident: IContractIncident;
}

const VisitReportPDF = ({
  isVisited,
  shouldFetch,
  selectedIncident,
  data,
}: IProps) => {
  if (!data || !shouldFetch || !isVisited) {
    return (
      <Document>
        <ReportLayout bgColor='main' reportHeading='Error'>
          <NotFound />
        </ReportLayout>
      </Document>
    );
  }

  let visitorsChunksList: IVisitor[][] = [];

  if (
    data &&
    typeof data === 'object' &&
    'visitors' in data &&
    data.visitors &&
    Array.isArray(data.visitors) &&
    data.visitors.length > 4
  ) {
    if (data.notes.length >= 600) {
      visitorsChunksList = divideArrayIntoChunks(data.visitors, 4, 30);
    } else {
      visitorsChunksList = divideArrayIntoChunks(data.visitors, 8, 30);
    }
  }

  if (visitorsChunksList.length > 0) {
    return (
      <Document>
        <ReportLayout reportHeading='Incident Visit Report'>
          <IncidentDetailsPDF
            selectedIncident={selectedIncident}
            incidentType={data.detectedType}
          />
          <ReportDataContainer heading='General Information'>
            <DataRowPDF
              label='Contract Id'
              data={selectedIncident.contractId}
            />
            <DataRowPDF
              label='Contract Incident Id'
              data={selectedIncident._id}
            />
            <DataRowPDF
              label='Report Created At'
              data={new Date(data.createdAt).toLocaleDateString('en', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              })}
            />
            <DataRowPDF
              label='Reporter Name'
              data={data.visitReportedBy.name}
            />
            <DataRowPDF
              label='Observed Incident Type'
              data={data.observedIncident}
            />
          </ReportDataContainer>
          <ReportDataContainer heading='Notes About Visit and Observations'>
            <ReportNote>{data.notes}</ReportNote>
          </ReportDataContainer>
          <ReportDataContainer heading='Incident Site Visitors'>
            {visitorsChunksList[0].map((visitor, index) => (
              <VisitorsListItem
                visitor={visitor}
                key={visitor.id}
                index={index}
              />
            ))}
          </ReportDataContainer>
        </ReportLayout>
        {visitorsChunksList.slice(1).map((chunk, index) => (
          <ReportLayout reportHeading='Incident Visit Report' key={index}>
            <ReportDataContainer heading='Incident Site Visitors'>
              {chunk.map((visitor, index) => (
                <VisitorsListItem
                  visitor={visitor}
                  key={visitor.id}
                  index={index + visitorsChunksList[0].length}
                />
              ))}
            </ReportDataContainer>
          </ReportLayout>
        ))}
      </Document>
    );
  }

  return (
    <Document>
      <ReportLayout reportHeading='Incident Visit Report'>
        <IncidentDetailsPDF
          selectedIncident={selectedIncident}
          incidentType={data.detectedType}
        />
        <ReportDataContainer heading='General Information'>
          <DataRowPDF label='Contract Id' data={selectedIncident.contractId} />
          <DataRowPDF
            label='Contract Incident Id'
            data={selectedIncident._id}
          />
          <DataRowPDF
            label='Report Created At'
            data={new Date(data.createdAt).toLocaleDateString('en', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })}
          />
          <DataRowPDF label='Reporter Name' data={data.visitReportedBy.name} />
          <DataRowPDF
            label='Observed Incident Type'
            data={data.observedIncident}
          />
        </ReportDataContainer>
        <ReportDataContainer heading='Notes About Visit and Observations'>
          <ReportNote>{data.notes}</ReportNote>
        </ReportDataContainer>
        <ReportDataContainer heading='Incident Site Visitors'>
          {data.visitors.map((visitor, index) => (
            <VisitorsListItem
              visitor={visitor}
              key={visitor.id}
              index={index}
            />
          ))}
        </ReportDataContainer>
      </ReportLayout>
      <ReportLayout reportHeading='Incident Visit Report'>
        <View>Hello World</View>
      </ReportLayout>
    </Document>
  );
};

export default VisitReportPDF;

/**
 * <Page size='A4' style={styles.page} wrap={false}>
      <View style={styles.main}>
        <LeftContent
          name={name}
          currency={currency}
          monthlyPrice={monthlyPrice}
          duration={duration}
          durationUnit={durationUnit}
          heading={heading}
          date={date}
          priceUnit={priceUnit}
        />
        <View style={styles.Right}>{children}</View>
      </View>
    </Page>
 */
