import { useEffect, forwardRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import classes from './PeriopsisCaptcha.module.scss';

interface IProps {
  show: boolean;
  onChange?: (token: string | null) => void;
  onExpired: () => void;
}

const ReCAPtcha = forwardRef(
  (
    { show, onChange, onExpired }: IProps,
    ref: React.ForwardedRef<ReCAPTCHA | null>
  ) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
      if (show) {
        window.recaptchaOptions = {
          removeOnUnmount: true,
        };
      }
    }, [show]);

    if (!show) {
      return null;
    }

    const recaptchaLoadHandler = () => {
      const timer = setTimeout(() => {
        setIsLoaded(true);
        clearTimeout(timer);
      }, 800);
    };

    return (
      <div className={classes.MainContainer}>
        <div
          className={`${classes.CaptchaContainer} ${
            show && isLoaded ? classes.Show : ''
          }`}
        >
          <ReCAPTCHA
            size='normal'
            sitekey='6LfexKYjAAAAAHZFvsTxFtKlZJLMOEkUwQNyp0Am'
            onChange={onChange}
            ref={ref}
            onExpired={onExpired}
            onError={(error) => console.warn(error)}
            asyncScriptOnLoad={recaptchaLoadHandler}
          />
        </div>
        {!isLoaded && show ? (
          <div className={classes.LoadingRecaptcha}>
            Loading Recaptcha Please Wait...
          </div>
        ) : null}
      </div>
    );
  }
);

export default ReCAPtcha;
