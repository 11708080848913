import classes from './CoordinatesBar.module.scss';

interface IProps {
  latitude: number;
  longitude: number;
  zoom: number;
}

const CoordinatesBar = ({ latitude, longitude, zoom }: IProps) => {
  return (
    <div className={classes.SideBar}>
      Longitude : {longitude.toFixed(4)} | Latitude : {latitude.toFixed(4)} |
      Zoom : {zoom.toFixed(2)}
    </div>
  );
};

export default CoordinatesBar;
