import React from 'react';
import classes from './ISORadioButton.module.scss';

interface IProps {
  options: {
    option1: string;
    option2: string;
  };
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  size?: number;
  style?: React.CSSProperties;
}

const IOSRadioButton = ({
  options,
  checked,
  onChange,
  size,
  style,
  id = 'name',
}: IProps) => {
  return (
    <div
      className={classes.IOSCheckBoxContainer}
      style={{ transform: `scale(${size ? size : 1})`, ...style }}
    >
      <input
        type='checkbox'
        id={id}
        name={id}
        checked={checked}
        onChange={onChange}
      />
      <label
        className='ios-checkbox'
        htmlFor={id}
        data-permit={options.option1}
        data-deny={options.option2}
      ></label>
    </div>
  );
};

export default IOSRadioButton;
