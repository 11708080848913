import { IContractIncident } from '../tsTypes/interfaces';

const getTypeFilteredIncidents = (data: {
  incidents: IContractIncident[];
  incidentTypeId: string;
}) => {
  const { incidents, incidentTypeId } = data;

  if (
    !incidents ||
    !Array.isArray(incidents) ||
    incidents.length === 0 ||
    !incidentTypeId ||
    incidentTypeId === ''
  ) {
    return incidents;
  }

  return incidents.filter((incident) => incident.typeId === incidentTypeId);
};

export default getTypeFilteredIncidents;
