const getHeaders = () => {
  const userData = localStorage.getItem('uData');
  const pToken = localStorage.getItem('pToken');
  const loginId = localStorage.getItem('loginId');
  let xapikey = '';
  let authKey = '';
  let uLoginId = '';

  if (pToken) {
    xapikey = pToken;
  }

  if (userData) {
    const parsedData = JSON.parse(userData);
    authKey = parsedData.token;
  }

  if (loginId) {
    uLoginId = loginId;
  }

  return {
    Authorization: `Bearer ${authKey}`,
    'X-API-KEY': xapikey,
    'x-login-id': uLoginId,
  };
};

export default getHeaders;
