const validateExpiryDate = (
  expiryMonth: number,
  expiryYear: number
): boolean => {
  const currentYear = new Date().getFullYear() % 100; // Extract the last two digits
  const currentMonth = new Date().getMonth() + 1; // January is 1, not 0

  if (expiryYear < currentYear) {
    // The card has expired
    return false;
  }

  if (expiryYear === currentYear && expiryMonth < currentMonth) {
    // The card has expired in the current year
    return false;
  }

  // The card is valid
  return true;
};

export default validateExpiryDate;
