import { Fragment, useLayoutEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { useGetActionCountsQuery } from '../../../../../features/statistics/statisticsSlice';
import classes from './ActionsDistribution.module.scss';
import ActionRowItem from '../ActionRowItem/ActionRowItem';
import { IncidentStatus } from '../../../../../tsTypes/enums';
import FetchingReports from '../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import { IActionStatProps } from '../../Statistics';
import ErrorControlComponent from '../../../../UI/ErrorControlComponent/ErrorControlComponent';
import { useTranslation } from 'react-i18next';

const ActionsDistribution = ({
  contractId,
  currentYear,
  isSelected,
  isYearsReady,
}: IActionStatProps) => {
  const { isLoading, isError, data, error } = useGetActionCountsQuery(
    { contractId, year: currentYear },
    { skip: !isSelected || !contractId }
  );

  const { t } = useTranslation();

  let counts: {
    totalCleaned: number;
    totalDetected: number;
    totalNoAction: number;
    totalReported: number;
    totalVisited: number;
  } = {
    totalCleaned: 0,
    totalDetected: 0,
    totalNoAction: 0,
    totalReported: 0,
    totalVisited: 0,
  };

  let dataSetData: number[] = [];

  useLayoutEffect(() => {
    Chart.register(ArcElement, Tooltip);
  }, []);
  let content: React.ReactNode | null = null;

  if (!isSelected || !isYearsReady) {
    return null;
  }

  if (isLoading) {
    content = (
      <FetchingReports message={t('statistics:fetching_action_statistics')} />
    );
  }

  if (isError) {
    content = (
      <ErrorControlComponent error={error}>
        <NoDataFound message={t('statistics:action_statistics_fetch_error')} />
      </ErrorControlComponent>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    !isLoading
  ) {
    counts = data.data.statistics.counts;

    dataSetData = [
      counts.totalReported,
      counts.totalVisited,
      counts.totalCleaned,
      counts.totalNoAction,
    ];
  }

  const openFilteredDashboardHandler = (status: IncidentStatus) => {
    // const fromDate = `${currentYear}-01-01`;
    // const toDate = `${currentYear}-12-31`;
    // const filterStatus = status;
  };

  const chartData = {
    labels: [
      t('statistics:total_reported'),
      t('statistics:total_visited'),
      t('statistics:total_cleaned'),
      t('statistics:no_action'),
    ],
    datasets: [
      {
        label: 'Actions',
        data: dataSetData,
        backgroundColor: ['#204b69', '#36738e', '#8bbbd6', '#bbdcef'],
        hoverOffset: 5,
      },
    ],
  };

  if (dataSetData && dataSetData.length > 0 && !isLoading && !isError) {
    content = (
      <div className={classes.StatisticsWrapper}>
        <div className={`${classes.ChartSection}`}>
          <Pie
            id='Annual-Actions'
            data={chartData}
            options={{
              animation: { duration: 1000, easing: 'linear' },
              responsive: true,
              borderColor: 'lightblue',
              radius: '100%',
              aspectRatio: 1,

              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    pointStyle: 'circle',
                    usePointStyle: true,
                  },
                },
              },
            }}
          />
        </div>

        <div className={classes.DataTableSection}>
          <ActionRowItem
            label={t('statistics:total_detected')}
            color='#204b69'
            count={counts.totalDetected}
            onClick={() => openFilteredDashboardHandler(IncidentStatus.all)}
          />
          <ActionRowItem
            label={t('statistics:total_reported')}
            color='#36738e'
            count={counts.totalReported}
            onClick={() =>
              openFilteredDashboardHandler(IncidentStatus.reported)
            }
          />
          <ActionRowItem
            label={t('statistics:total_visited')}
            color='#8bbbd6'
            count={counts.totalVisited}
            onClick={() => openFilteredDashboardHandler(IncidentStatus.visited)}
          />
          <ActionRowItem
            label={t('statistics:total_cleaned')}
            color='#bbdcef'
            count={counts.totalCleaned}
            onClick={() => openFilteredDashboardHandler(IncidentStatus.cleaned)}
          />
          <ActionRowItem
            label={t('statistics:no_action')}
            color='#fff'
            count={counts.totalNoAction}
            onClick={() =>
              openFilteredDashboardHandler(IncidentStatus.noaction)
            }
          />
        </div>
      </div>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default ActionsDistribution;
