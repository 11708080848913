import { View } from '@react-pdf/renderer';
import { SubserviceContainerStyle } from './SubserviceContainer.style';

interface IProps {
  children: React.ReactNode;
}

const SubserviceContainer = ({ children }: IProps) => {
  return <View style={SubserviceContainerStyle.container}>{children}</View>;
};

export default SubserviceContainer;
