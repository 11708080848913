import classes from './NotificationWindowContainer.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const NotificationWindowContainer = ({ children, className }: IProps) => {
  return (
    <div
      className={`${classes.NotificationWindowContainer} ${
        className ? className : ''
      }`}
    >
      {children}
    </div>
  );
};

export default NotificationWindowContainer;
