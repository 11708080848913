import { ChangeEvent } from 'react';
import { IContractUser } from '../../../../../tsTypes/interfaces';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import compareUsersLists from '../../../../../utils/ContractUsersUtils/compareUsersList';
import { useAssignUsersToContractMutation } from '../../../../../features/userAdmin/userAdminSlice';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContractUsersMessage from '../ContractUsersMessage/ContractUsersMessage';
import classes from './CurrentUsersListUpdated.module.scss';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import UserListItem from './UserListItem/UserListItem';
import { useTranslation } from 'react-i18next';

interface IProps {
  contractUsers: IContractUser[];
  currentUserId: string;
  onUpdate: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemove: (userId: string) => void;
  onBlock: (userId: string) => void;
  onUnblock: (userId: string) => void;
  storedContractUsers: IContractUser[];
  isUpdateable?: boolean;
  hasMessage?: boolean;
  remainingUsers: number;
  userLimit: number;
}

const CurrentUsersListUpdated = ({
  contractUsers,
  currentUserId,
  onUpdate,
  onRemove,
  onBlock,
  onUnblock,
  storedContractUsers,
  isUpdateable,
  hasMessage,
  remainingUsers,
  userLimit,
}: IProps) => {
  const [pageQuery] = useSearchParams();
  const contractId = pageQuery.get('contract');
  const [assignUsers, { isLoading }] = useAssignUsersToContractMutation();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const isUpdated = compareUsersLists({
    currentList: contractUsers,
    storedList: storedContractUsers,
  });

  const updateContractUsersHandler = async () => {
    try {
      if (contractUsers.length === 0) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('admin:not_allowed')}
            singleMessage={t('admin:not_allowed_message')}
          />
        );
      }

      if (contractUsers.length > userLimit) {
        const limitExceedMessage =
          language === 'el'
            ? `Έχετε φτάσει το μέγιστο όριο χρηστών για αυτό το πακέτο συμβολαίου (${userLimit}). Η ενημέρωση του συμβολαίου με ${contractUsers.length} χρήστες δεν επιτρέπεται.`
            : `You've reached the maximum user limit for this contract package (${userLimit}). Updating the contract with ${contractUsers.length} users is not allowed.`;

        return toast.error(
          <ToastifyStyledMessage
            heading={t('admin:limit_exceeded')}
            singleMessage={limitExceedMessage}
          />
        );
      }

      const adminUsers = contractUsers.filter(
        (user) => user.authorizations.hasAuthtoAdmin
      );

      if (adminUsers.length === 0) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('admin:admin_user_required')}
            singleMessage={t('admin:admin_user_required_message')}
          />
        );
      }

      const res = await assignUsers({
        users: contractUsers,
        contractId: contractId || '',
      }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('admin:update_success_message_contract_user')}
            heading={t('admin:update_success')}
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  return (
    <div className={classes.CurrentUsers}>
      <div className={classes.HeadingSection}>
        <h3>{t('admin:current_users_of_contract')}</h3>
        <div className={classes.RemainingUsers}>
          <span>{remainingUsers}</span>
        </div>
      </div>
      {hasMessage ? (
        <ContractUsersMessage numberOfContractUsers={contractUsers.length} />
      ) : null}
      <div className={classes.UserListContainer}>
        {contractUsers.map((user) => (
          <UserListItem
            key={user.userId}
            user={user}
            currentUserId={currentUserId}
            onUpdate={onUpdate}
            onRemove={onRemove}
            onBlock={onBlock}
            onUnblock={onUnblock}
            isUpdateable={isUpdateable}
          />
        ))}
        <div className={classes.CompleteUpdateContainer}>
          {isUpdated ? (
            <span className={classes.InfoMessage}>
              {t('admin:make_sure_to_update')}
            </span>
          ) : null}

          {isUpdateable ? (
            <div className={classes.TableAction}>
              <StandardButton
                label={t('auth:Update')}
                btnType='primary'
                type='button'
                fakeButton={
                  !isUpdated || contractUsers.length === 0 || isLoading
                }
                onClick={updateContractUsersHandler}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CurrentUsersListUpdated;

/**
 *   contractUsers={contractUsers}
          currentUserId={loggedInUserId}
          onUpdate={currentUsersAuthUpdateHandler}
          storedContractUsers={usersOfContractData.contractUsers}
          onRemove={removeUserHandler}
          onBlock={blockUserHandler}
          onUnblock={unblockUserHandler}
          hasMessage
          remainingUsers={remainingUsers}
          userLimit={usersOfContractData.userLimit}
 */
