import React from 'react';
import { createPortal } from 'react-dom';
import PointActionForm from './PointActionForm/PointActionForm';
import ModalContainer from '../ModalContainer/ModalContainer';

interface IProps {
  children: React.ReactNode;
  show: boolean;
  onClick?: () => void;
  onShowInformation?: () => void;
  showInfo?: boolean;
  heading?: string;
}

const FormModal = ({
  children,
  show,
  showInfo,
  onClick,
  heading,
  onShowInformation,
}: IProps) => {
  const closeFormHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  const Form = show ? (
    <ModalContainer show={show} onClick={onClick}>
      <PointActionForm
        heading={heading}
        onClose={closeFormHandler}
        onShowInformation={onShowInformation}
        showInfo={showInfo}
      >
        {children}
      </PointActionForm>
    </ModalContainer>
  ) : null;

  const portalTarget = document.getElementById('formModal')! as HTMLDivElement;

  return createPortal(Form, portalTarget);
};

export default FormModal;
