import { FormEvent, useState } from 'react';
import Form from '../../../../UI/FormElements/Form/Form';
import Input from '../../../../UI/FormElements/Input/Input';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import classes from './AddNewUserForm.module.scss';
import Phone from '../../../../UI/Phone/Phone';
import usePhoneEntry from '../../../../../CustomHooks/usePhoneEntry';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import useFormEntry from '../../../../../CustomHooks/useFormEntry';
import {
  validateEmail,
  validateNameSurname,
  validatePassword,
} from '../../../../../utils/componentUtilFns';

import PasswordConditions from '../../../../UI/PasswordConditions/PasswordConditions';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { useSearchParams } from 'react-router-dom';
import PasswordStrengthTest from '../../../LoginRegister/SignUp/SignUpParts/PasswordStrengthTest/PasswordStrengthTest';
import { decodePattern } from '../../../../../utils/phoneUtilityFunctions/createPattern';
import { IUser } from '../../../../../tsTypes/types.model';
import { ICountryPhoneData } from '../../../../../tsTypes/types.components';
import { useAddNewUserToOrganizationUsersMutation } from '../../../../../features/userAdmin/userAdminSlice';
import queryErrorCatch from '../../../../../utils/queryErrorCatch';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import { useTranslation } from 'react-i18next';

const validatePasswordEntry = (password: string) =>
  validatePassword(password, 6, 25);

const AddNewUserForm = () => {
  const [showConditionsBoard, setShowConditionsBoard] = useState(false);
  const [pageQuery] = useSearchParams();
  const contractId = pageQuery.get('contract');
  const [addNewOrganizationUser, { isLoading: isProcessingNewUser }] =
    useAddNewUserToOrganizationUsersMutation();
  const { t } = useTranslation();
  const {
    phoneNumber,
    countryData,
    countryCodeSelectHandler,
    phoneEntryHandler,
    clearPhoneData,
  } = usePhoneEntry({ localStorageName: 'organization-user-phone' });

  const {
    value: name,
    isValid: isValidName,
    entryHandler: nameEntryHandler,
    clearEntry: clearNameEntry,
  } = useFormEntry(validateNameSurname, 'name', 'organization-user-name', true);
  const {
    value: surname,
    isValid: isValidSurname,
    entryHandler: surnameEntryHandler,
    clearEntry: clearSurnameEntry,
  } = useFormEntry(
    validateNameSurname,
    'surname',
    'organization-user-surname',
    true
  );
  const {
    value: email,
    isValid: isValidEmail,
    entryHandler: emailEntryHandler,
    clearEntry: clearEmailEntry,
  } = useFormEntry(validateEmail, 'email', 'organization-user-email');
  const {
    value: password,
    isValid: isValidPassword,
    entryHandler: passwordEntryHandler,
    clearEntry: clearPasswordEntry,
  } = useFormEntry(validatePasswordEntry);

  const validateConfirmPassword = (confirmPasswordEntry: string) =>
    validatePasswordEntry(confirmPasswordEntry) &&
    confirmPasswordEntry === password;

  const {
    value: confirmPassword,
    isValid: isValidConfirmPassword,
    entryHandler: confirmPasswordEntryHandler,
    clearEntry: clearConfirmPasswordEntry,
  } = useFormEntry(validateConfirmPassword);

  const showPasswordConditionsBoardHandler = () => {
    setShowConditionsBoard(true);
  };

  const hidePasswordConditionsBoardHandler = () => {
    setShowConditionsBoard(false);
  };

  const submitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (name.trim().length === 0 || surname.trim().length === 0) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('admin:form_error_name_surname_message')}
            heading={t('admin:form_error')}
          />
        );
      }

      if (email.trim().length === 0) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('admin:form_error_email')}
            heading={t('admin:form_error')}
          />
        );
      }

      if (phoneNumber.trim().length === 0) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('admin:phone_number_error')}
            singleMessage={t('admin:phone_number_error_message')}
          />
        );
      }

      if (password.trim().length < 6 || confirmPassword.trim().length < 6) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('admin:password_error')}
            singleMessage={t('admin:password_error_message')}
          />
        );
      }

      // 1- Validate User Data
      if (!isValidPassword) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('admin:invalid_password_message')}
            heading={t('admin:password_error')}
          />
        );
      }

      if (!isValidConfirmPassword) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('admin:password_mismatch_message')}
            heading={t('admin:password_mismatch')}
          />
        );
      }

      if (!isValidEmail) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('admin:invalid_user_email_message')}
            heading={t('admin:invalid_email')}
          />
        );
      }

      if (!isValidName || !isValidSurname) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('admin:name_error')}
            singleMessage={t('admin:name_error_message')}
          />
        );
      }

      if (
        !phoneNumber ||
        phoneNumber.trim().length < 5 ||
        isNaN(Number(decodePattern(phoneNumber)))
      ) {
        return toast.error(
          <ToastifyStyledMessage
            heading={t('admin:phone_error')}
            singleMessage={t('admin:phone_error_message')}
          />
        );
      }

      let phoneData: ICountryPhoneData | null = null;

      if (phoneNumber && countryData) {
        phoneData = {
          code: countryData.countryCode,
          country: countryData.name,
          phoneNumber: decodePattern(phoneNumber),
        };
      }

      if (!contractId || !phoneData) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('admin:process_error_message')}
            heading={t('admin:process_error')}
          />
        );
      }

      const userData: Pick<
        IUser,
        | 'name'
        | 'surname'
        | 'email'
        | 'password'
        | 'confirmPassword'
        | 'userPhones'
      > = {
        name,
        surname,
        email,
        password,
        confirmPassword,
        userPhones: [phoneData],
      };

      //2- Submit Form Data to Backend and clear form for new user

      const res = await addNewOrganizationUser({
        user: userData,
        contractId,
      }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        clearNameEntry();
        clearSurnameEntry();
        clearEmailEntry();
        clearPasswordEntry();
        clearConfirmPasswordEntry();
        clearPhoneData();

        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('admin:user_added_success_message')}
            heading={t('admin:success')}
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const isFakeButton =
    name.trim().length === 0 ||
    surname.trim().length === 0 ||
    email.trim().length < 4 ||
    phoneNumber.trim().length < 3 ||
    password.trim().length < 6 ||
    confirmPassword.trim().length < 6 ||
    !isValidPassword ||
    !isValidConfirmPassword ||
    !isValidEmail ||
    !isValidSurname ||
    !isValidName;

  return (
    <div className={classes.OrganizationFormContainer}>
      <HeadingBar heading={t('admin:new_organization_user_form')} />
      <Form
        className={classes.OrganiationUserForm}
        onSubmit={submitFormHandler}
      >
        <Input
          name='name'
          label={t('translations:Name')}
          onChange={nameEntryHandler}
          value={name}
          isInvalid={!isValidName}
          isRequired
          disabled={isProcessingNewUser}
        />
        <Input
          name='surname'
          label={t('translations:Surname')}
          onChange={surnameEntryHandler}
          value={surname}
          isInvalid={!isValidSurname}
          isRequired
          disabled={isProcessingNewUser}
        />
        <Input
          name='email'
          label={t('admin:email_address')}
          onChange={emailEntryHandler}
          value={email}
          isInvalid={!isValidEmail}
          isRequired
          disabled={isProcessingNewUser}
        />
        <Phone
          onPhoneEntry={phoneEntryHandler}
          phoneNumber={phoneNumber}
          onCodeSelect={countryCodeSelectHandler}
          countryData={countryData}
          disabled={isProcessingNewUser}
        />
        <Input
          name='password'
          label={t('auth:password')}
          onChange={passwordEntryHandler}
          value={password}
          isInvalid={!isValidPassword}
          isRequired
          hasShowPasswordButton
          showInformationBoard={showConditionsBoard}
          onFocus={showPasswordConditionsBoardHandler}
          onBlur={hidePasswordConditionsBoardHandler}
          autoComplete='new-password'
          type='password'
          disabled={isProcessingNewUser}
        >
          <PasswordConditions />
        </Input>
        <Input
          name='confirm-password'
          label={t('registration:confirm_password')}
          hasShowPasswordButton
          onChange={confirmPasswordEntryHandler}
          value={confirmPassword}
          isInvalid={!isValidConfirmPassword}
          isRequired
          type='password'
          disabled={isProcessingNewUser}
        />
        <div className={classes.PasswordStrengthContainer}>
          {!isProcessingNewUser ? (
            <PasswordStrengthTest password={password} />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SimpleLoading />
            </div>
          )}
        </div>
        <div></div>
        <ActionContainer setup={{ justifyContent: 'flex-end' }}>
          <StandardButton
            label={t('admin:add_user')}
            btnType='primary'
            type='submit'
            fakeButton={isProcessingNewUser || isFakeButton}
          />
        </ActionContainer>
      </Form>
    </div>
  );
};

export default AddNewUserForm;
