import { FaRegFilePdf } from 'react-icons/fa';
import classes from './DownloadButton.module.scss';

interface IProps {
  url: string;
  fileName: string;
  onClick?: () => void;
  label: string;
  title: string;
}

const DownloadButton = ({ url, fileName, title, onClick, label }: IProps) => {
  return (
    <div className={classes.PDFButtonContainer}>
      <a
        href={url}
        download={fileName}
        className={classes.ReportDownload}
        title={title}
        onClick={onClick}
      >
        <FaRegFilePdf />
        {label}
      </a>
    </div>
  );
};

export default DownloadButton;
