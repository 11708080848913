export interface CVVValidationResult {
  isValid: boolean;
  cvvLength: number;
}

const validateCVV = (cvv: string, cardType: string): CVVValidationResult => {
  // Define regular expressions to match CVV lengths for different card types
  const cvvLengths: Record<string, { pattern: RegExp; length: number }> = {
    Visa: { pattern: /^[0-9]{3}$/, length: 3 },
    MasterCard: { pattern: /^[0-9]{3}$/, length: 3 },
    'American Express': { pattern: /^[0-9]{4}$/, length: 4 }, // Update pattern to match 4 digits
    Discover: { pattern: /^[0-9]{3}$/, length: 3 },
    // Add more card types and patterns as needed
  };

  // Check if the card type is recognized and if the CVV matches the expected length
  if (cvvLengths[cardType] && cvv.match(cvvLengths[cardType].pattern)) {
    return { isValid: true, cvvLength: cvvLengths[cardType].length };
  }

  return { isValid: false, cvvLength: 3 };
};

export default validateCVV;
