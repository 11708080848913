import { createPortal } from 'react-dom';
import Backdrop from '../../Backdrop/Backdrop';
import SideDrawer from '../SideDrawer';
import classes from './Menu.module.scss';
import { useSelector } from 'react-redux';
import { selectAllUIValues } from '../../../../features/UI/uiSlice';

interface IProps {
  onClick: () => void;
}

const Menu = ({ onClick }: IProps) => {
  const { showMobileMenu } = useSelector(selectAllUIValues);

  const sideDrawer = (
    <div className={classes.Menu}>
      <Backdrop onClick={onClick} show={showMobileMenu} />
      <SideDrawer />
    </div>
  );

  const portalTarget = document.getElementById('sidedrawer')! as HTMLDivElement;

  return createPortal(sideDrawer, portalTarget);
};

export default Menu;
