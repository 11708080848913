import { IHider } from '../../../tsTypes/types.components';
import classes from './Backdrop.module.scss';

const Backdrop = ({ onClick, show }: IHider) => {
  return (
    <div
      className={`${classes.Backdrop} ${show ? classes.Show : ''}`}
      onClick={onClick}
    />
  );
};

export default Backdrop;
