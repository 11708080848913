import React from 'react';
import { FiInfo } from 'react-icons/fi';
import classes from './DataRow.module.scss';

interface IProps {
  heading: string;
  data: string;
  style?: React.CSSProperties;
  title?: string;
  removeSeperator?: boolean;
  onClick?: () => void;
  className?: string;
}

const DataRow = ({
  heading,
  data,
  style,
  title,
  removeSeperator,
  onClick,
  className,
}: IProps) => {
  return (
    <div
      className={`${classes.DataRowContainer} ${className ? className : ''}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <p style={{ fontWeight: 600 }}>
        {title && <FiInfo title={title} />}
        {heading}
      </p>
      {!removeSeperator ? <span>:</span> : <span></span>}
      <p>{data}</p>
    </div>
  );
};

export default DataRow;
