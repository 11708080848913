import { useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { Coordinates } from '../../../tsTypes/types';

interface IProps {
  coordinates: Coordinates;
  zoomLevel?: number;
}

const FlyTo = ({ coordinates, zoomLevel }: IProps) => {
  const { current: map } = useMap();
  const hasCoords =
    coordinates && Array.isArray(coordinates) && coordinates.length === 2;
  const hasMap = map !== undefined;

  useEffect(() => {
    if (hasCoords && hasMap) {
      const latitude = coordinates[0];
      const longitude = coordinates[1];

      map.flyTo({ center: [longitude, latitude], zoom: zoomLevel || 12 });
    }

    // eslint-disable-next-line
  }, [hasCoords, hasMap, coordinates[0], coordinates[1]]);

  return <></>;
};

export default FlyTo;
