/**
 * Validates an organization name to ensure it meets certain criteria.
 *
 * A valid organization name:
 * - Has a length between 3 and 90 characters (inclusive) after trimming whitespace.
 * - Does not consist entirely of numeric characters.
 * - Does not consist entirely of the same character repeated.
 *
 * @param {string} organizationName - The organization name to be validated.
 * @returns {boolean} True if the organization name is valid, otherwise false.
 */
const validateOrganizationName = (organizationName: string) => {
  const nameLength = organizationName.trim().length;
  if (nameLength > 90 || nameLength < 3) {
    return false;
  }

  if (!isNaN(Number(organizationName))) {
    return false;
  }

  const charsOfName = organizationName.toLowerCase().split('');

  const allSameCharacters = charsOfName.every(
    (char) => charsOfName[0] === char
  );

  if (allSameCharacters) {
    return false;
  }

  return true;
};

export default validateOrganizationName;
