import { View, Text } from '@react-pdf/renderer';
import { ProformaSectionStyle } from './ProformaSection.style';

interface IProps {
  heading: string;
  children: React.ReactNode;
}

const ProformaSection = ({ heading, children }: IProps) => {
  return (
    <View style={ProformaSectionStyle.sectionContainer}>
      {heading ? (
        <View style={ProformaSectionStyle.sectionHeading}>
          <Text style={ProformaSectionStyle.heading}>{heading}</Text>
        </View>
      ) : null}
      <View style={ProformaSectionStyle.contentContainer}>{children}</View>
    </View>
  );
};

export default ProformaSection;
