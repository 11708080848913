import { ImNotification } from 'react-icons/im';
import classes from './NoDataFound.module.scss';

interface IProps {
  message: string;
  icon?: React.ReactNode;
}

const NoDataFound = ({ message, icon }: IProps) => {
  const iconToShow = icon ? icon : <ImNotification />;

  return (
    <div className={classes.NoDataContainer}>
      {iconToShow}
      <p>{message}</p>
    </div>
  );
};

export default NoDataFound;
