/**
 * Formats a duration in seconds into a string representation of minutes and seconds (MM:SS).
 *
 * @param {number} seconds - The duration in seconds to be formatted.
 *
 * @returns {string} - A string representation of the duration in the format 'MM:SS'.
 */
const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${minutes.toString().padStart(2, '0')}:${formattedSeconds}`;
};

export default formatTime;
