import SendPageHeader from './SendPageHeader/SendPageHeader';
import { useEffect, useState } from 'react';
import SendPageTabs from './SendPageTabs/SendPageTabs';
import { useGetSendReportsOfUserQuery } from '../../../features/report/reportsSlice';
import useSessionLogout from '../../../CustomHooks/useSessionLogout';
import NoDataFound from '../../UI/NoDataFound/NoDataFound';
import SendWindow from './SendWindow/SendWindow';
import classes from './SendPage.module.scss';
import ArchiveWindow from './ArchiveWindow/ArchiveWindow';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useGetContractsQuery } from '../../../features/incidents/incidentsSlice';
import FetchingReports from '../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoContract from '../../UI/NoContract/NoContract';
import { useGetPageSettingsQuery } from '../../../features/Settings/settingsSlice';
import { Settings } from '../../../tsTypes/enums';
import getUserContractsResult from '../../../utils/ContractUsersUtils/getUserContractsResult';
import { useTranslation } from 'react-i18next';

const SendPage = () => {
  const [selected, setSelected] = useState<'send' | 'archive'>('send');
  const [contractId, setContractId] = useState('');
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { isLoading: isLoadingContracts, data: userContractsData } =
    useGetContractsQuery('contracts');

  const { data: settings, isLoading: isLoadingSettings } =
    useGetPageSettingsQuery(Settings.sidedrawer, {
      skip: isLoadingContracts,
    });

  const { contractsTerminated, hasContracts } =
    getUserContractsResult(userContractsData);

  const { isLoading, isError, error, data } = useGetSendReportsOfUserQuery(
    { contractId, filter: 'count' },
    { skip: contractId === '' || isLoadingContracts || isLoadingSettings }
  );
  useSessionLogout({ isError, error });
  let numberOfReports = 0;
  let numberOfArchived =
    data !== undefined &&
    typeof data === 'object' &&
    'data' in data &&
    data.data &&
    typeof data.data === 'object' &&
    'archivedReports' in data.data
      ? data.data.archivedReports
      : 0;
  let numberOfReportedIncidents = 0;

  let hasReportLink = true;
  useEffect(() => {
    const contract = localStorage.getItem('current-contract');

    if (contract) {
      setContractId(contract);
    }
  }, []);

  useEffect(() => {
    if (numberOfArchived === 0 && !isLoading) {
      setSelected('send');
    }
  }, [numberOfArchived, isLoading]);

  const selectTabWindowHandler = (selected: 'send' | 'archive') => {
    setSelected(selected);
    setSearchParams({});
  };

  if (isLoadingContracts || isLoadingSettings) {
    return (
      <FetchingReports message={t('reporting:initializing_reports_data')} />
    );
  }

  if (settings && typeof settings === 'object' && 'hasReportLink' in settings) {
    hasReportLink = settings.hasReportLink;
  }

  if (!hasReportLink || !hasContracts) {
    return <Navigate to='/' />;
  }

  if (!hasContracts) {
    return <NoContract hasTerminated={contractsTerminated} />;
  }

  if (isError && !isLoading) {
    return (
      <div className={classes.SendPageContainer}>
        <SendPageHeader />
        <NoDataFound message={t('reporting:reports_fetch_error')} />
      </div>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'data' in data &&
    data.data &&
    typeof data.data === 'object' &&
    'archivedReports' in data.data &&
    'reports' in data.data &&
    typeof data.data.reports === 'number'
  ) {
    numberOfArchived = data.data.archivedReports;
    numberOfReports = data.data.reports;
    numberOfReportedIncidents = data.data.reportedIncidentsCount;
  }

  return (
    <div className={classes.SendPageContainer}>
      <SendPageHeader />
      <main className={classes.Main}>
        <SendPageTabs
          onSelect={selectTabWindowHandler}
          selected={selected}
          hasArchivedReports={numberOfArchived > 0}
        />

        <SendWindow
          isSelected={selected === 'send'}
          contractId={contractId}
          count={numberOfReports}
          isLoadingCounts={isLoading}
          countReportedIncidents={numberOfReportedIncidents}
        />
        <ArchiveWindow
          isSelected={selected === 'archive'}
          contractId={contractId}
          count={numberOfArchived}
          isLoadingCounts={isLoading}
        />
      </main>
    </div>
  );
};

export default SendPage;
