import { ChangeEvent } from 'react';
import { IQuestion, IUserAnswer } from '../../../../../../tsTypes/interfaces';
import AnswerItem from './AnswerItem/AnswerItem';
import classes from './QuestionItem.module.scss';

interface IProps {
  question: IQuestion;
  index: number;
  onQuestionAnswer: (userAnswer: IUserAnswer) => void;
  selectedAnswerId?: string;
  hasAuthToActions?: boolean;
}

const QuestionItem = ({
  question,
  index,
  onQuestionAnswer,
  selectedAnswerId,
  hasAuthToActions,
}: IProps) => {
  if (
    !question ||
    typeof question !== 'object' ||
    !('answers' in question) ||
    !Array.isArray(question.answers) ||
    question.answers.length === 0
  ) {
    return null;
  }

  const answerSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const userResponse = {
      question: question.question,
      answer: e.target.value,
      id: question.id,
      answerId: e.target.id,
    };

    onQuestionAnswer(userResponse);
  };

  return (
    <div className={classes.QuestionItemContainer}>
      <div className={classes.QuestionSection}>
        <span>{index + 1}</span>
        <p>{question.question}</p>
      </div>

      <div className={classes.AnswersContainer}>
        {question.answers.map((answer) => (
          <AnswerItem
            questionId={question.id}
            answer={answer.answer}
            onSelect={answerSelectHandler}
            key={answer.id}
            id={answer.id}
            isSelected={answer.id === selectedAnswerId}
            hasAuthToActions={hasAuthToActions}
          />
        ))}
      </div>
    </div>
  );
};

export default QuestionItem;
