import { frontendConstants } from '../constants/constants';

/**
 * Calculate the number of days from the current date to a given target date.
 *
 * @param {Date} targetDate - The target date to calculate the days until.
 * @returns {number} The number of days from the current date to the target date.
 *
 * @example
 * const targetDate = new Date("2023-12-31");
 * const daysRemaining = getDaysFromCurrentDateTo(targetDate);
 * console.log(`Days remaining until ${targetDate}: ${daysRemaining} days`);
 */
export const getDaysFromCurrentDateTo = (targetDate: Date) => {
  const givenDate = new Date(targetDate).getTime();
  const currentDate = new Date().getTime();

  const difference = Math.ceil(
    (currentDate - givenDate) / frontendConstants.oneDayInMilliseconds
  );

  return difference;
};
