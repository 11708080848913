import React from 'react';
import { IRadioButton } from '../../../tsTypes/types.components';
import RadioButton from '../FormElements/RadioButton/RadioButton';

interface IProps {
  radioButtonListSetup: {
    name: string;
    listElements: IRadioButton[];
    onChange: (value: string) => void;
    direction: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  };
  style?: React.CSSProperties;
  className?: string;
}

const RadioButtonList = ({
  radioButtonListSetup,
  style,
  className,
}: IProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: `${radioButtonListSetup.direction}`,
        ...style,
      }}
      className={className}
    >
      {radioButtonListSetup.listElements.map((listElement) => (
        <RadioButton
          name={radioButtonListSetup.name}
          label={listElement.label}
          id={listElement.id}
          key={listElement.id}
          value={listElement.value}
          checked={listElement.checked}
          onChange={() => radioButtonListSetup.onChange(listElement.value)}
        />
      ))}
    </div>
  );
};

export default RadioButtonList;
