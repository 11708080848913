import { View, Text } from '@react-pdf/renderer';
import Paragraph from '../Paragraph/Paragraph';
import { itemStyles } from './TermsItem.style';

interface IProps {
  heading: string;
  description: string;
}

const TermsItem = ({ heading, description }: IProps) => {
  return (
    <View style={itemStyles.container}>
      <View>
        <Text style={itemStyles.heading}>{heading}</Text>
      </View>
      <Paragraph paragraph={description} width='117mm' />
    </View>
  );
};

export default TermsItem;
