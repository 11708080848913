import React from 'react';
import { item } from '../../../../tsTypes/types.components';
import Chips from '../Chips/Chips';
import classes from './EmailChipsList.module.scss';

interface IProps {
  list: item[];
  onRemove: (listMemberId: string | number) => void;
  children: React.ReactNode;
}

const EmailChipsList = ({ list, onRemove, children }: IProps) => {
  return (
    <ul className={classes.ChipsListContainer}>
      {list.map((e) => (
        <Chips key={e._id} text={e.name} onClick={() => onRemove(e._id)} />
      ))}
      <li>{children}</li>
    </ul>
  );
};

export default EmailChipsList;
