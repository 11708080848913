//['Pending', 'Paid', 'Overdue']
export enum Status {
  Pending = 'Pending',
  Paid = 'Paid',
  Overdue = 'Overdue',
}

export enum Settings {
  IncidentDashboard = 'incident-dashboard',
  IncidentDashboardVisitForm = 'incident-dashboard-visited-window',
  IncidentDashboardReportForm = 'incident-dashboard-report-window',
  IncidentDashboardCleanedForm = 'incident-dashboard-cleaned-form-window',
  sidedrawer = 'side-drawer-menu',
  userAdminPage = 'user-admin-page',
}

export enum Maps {
  streetMap = 'mapbox://styles/mapbox/streets-v12',
  satelliteMap = 'mapbox://styles/mapbox/satellite-streets-v12',
  navigationMap = 'mapbox://styles/mapbox/light-v11',
}

export enum IncidentStatus {
  all = '',
  reported = 'reported',
  visited = 'visited',
  cleaned = 'cleaned',
  noaction = 'noaction',
}
