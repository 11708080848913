import { Fragment, useState } from 'react';
import SelectionContainer from '../../../UI/SelectionContainer/SelectionContainer';
import StatisticsMainContainer from '../StatisticsMainContainer/StatisticsMainContainer';
import { useGetContractYearsQuery } from '../../../../features/statistics/statisticsSlice';
import { IListObject } from '../../../../tsTypes/types.model';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import ActionsDistribution from './ActionsDistribution/ActionsDistribution';
import MonthlyActions from './MonthlyActions/MonthlyActions';
import useSessionLogout from '../../../../CustomHooks/useSessionLogout';
import MonthlyActionPercentages from './MonthlyActionPercentages/MonthlyActionPercentages';
import ActionsTableStats from './ActionsTableStats/ActionsTableStats';
import { IStatisticsProps } from '../Statistics';
import StatisticsViewSelect from '../StaticticsViewSelect/StatisticsViewSelect';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';

const ActionStatistics = ({
  isSelected,
  contractId,
  currentYear,
  selectYear,
}: IStatisticsProps) => {
  const [selectedActionStatistics, setSelectedActionStatistics] = useState<
    'distribution' | 'monthly' | 'percentage' | 'tables'
  >('distribution');
  let content: React.ReactNode | null = null;
  let years: IListObject[] = [];
  let hasIncidents = false;
  const { t } = useTranslation();

  const { isLoading, isError, error, data, isSuccess } =
    useGetContractYearsQuery(contractId, {
      skip: !isSelected,
    });

  const tableMainHeadings = {
    visited: t('statistics:visited_incidents_per_month'),
    reported: t('statistics:reported_incidents_per_month'),
    cleaned: t('statistics:cleaned_incidents_per_month'),
  };

  useSessionLogout({ isError, error });

  if (isLoading) {
    content = (
      <FetchingReports message={t('statistics:fetching_statistics_data')} />
    );
  }

  if (isError) {
    content = (
      <NoDataFound message={t('statistics:contract_years_fetch_error')} />
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'years' in data &&
    data.years &&
    Array.isArray(data.years) &&
    'hasIncidents' in data
  ) {
    years = [...data.years];
    hasIncidents = data.hasIncidents;
  }

  if (years.length === 0 && !isLoading && !hasIncidents) {
    content = (
      <NoDataFound
        icon={<FaFilterCircleXmark />}
        message={t('statistics:no_reported_incidents_no_statistics')}
      />
    );
  }

  if (years.length === 0 && !isLoading && hasIncidents) {
    content = (
      <NoDataFound message={t('statistics:contract_years_fetch_error')} />
    );
  }

  if (
    years &&
    years.length > 0 &&
    selectedActionStatistics === 'distribution'
  ) {
    content = (
      <ActionsDistribution
        contractId={contractId}
        currentYear={currentYear}
        isSelected
        isYearsReady={isSuccess}
      />
    );
  } else if (
    years &&
    years.length > 0 &&
    selectedActionStatistics === 'monthly'
  ) {
    content = (
      <MonthlyActions
        contractId={contractId}
        currentYear={currentYear}
        isSelected
        isYearsReady={isSuccess}
        mainHeadings={tableMainHeadings}
      />
    );
  } else if (
    years &&
    years.length > 0 &&
    selectedActionStatistics === 'percentage'
  ) {
    content = (
      <MonthlyActionPercentages
        contractId={contractId}
        currentYear={currentYear}
        isSelected
        isYearsReady={isSuccess}
        mainHeadings={tableMainHeadings}
      />
    );
  } else if (
    years &&
    years.length > 0 &&
    selectedActionStatistics === 'tables'
  ) {
    content = (
      <ActionsTableStats
        contractId={contractId}
        currentYear={currentYear}
        isSelected
        isYearsReady={isSuccess}
        mainHeadings={tableMainHeadings}
      />
    );
  }

  return (
    <SelectionContainer isSelected={isSelected}>
      <StatisticsMainContainer
        heading={t('statistics:annual_actions_statistics')}
        years={years}
        onSelect={selectYear}
        selected={currentYear.toString()}
        isLoading={isLoading}
      >
        <StatisticsViewSelect
          onSelect={setSelectedActionStatistics}
          selected={selectedActionStatistics}
          buttonLabels={[
            t('statistics:action_distributon'),
            t('statistics:monthly_action_statistics'),
            t('statistics:monthly_action_percentages'),
            t('statistics:action_tables'),
          ]}
          existingButtons={[true, true, true, true]}
        />
        <Fragment>{content}</Fragment>
      </StatisticsMainContainer>
    </SelectionContainer>
  );
};

export default ActionStatistics;
