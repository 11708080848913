import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AppDispatch } from '../app/store';
import { clearAuthData } from '../features/auth/authSlice';
import { clearOrganizationState } from '../features/organization/organizationSlice';
import { clearUIState } from '../features/UI/uiSlice';
import { clearPointState } from '../features/point/pointSlice';

const useLogout = () => {
  const dispatch = useDispatch<AppDispatch>();

  const logout = useCallback(() => {
    batch(() => {
      dispatch(clearPointState());
      dispatch(clearAuthData());
      dispatch(clearOrganizationState());
      dispatch(clearUIState());
    });
    localStorage.removeItem('uData');
    localStorage.removeItem('pToken');
    localStorage.removeItem('current');
    localStorage.clear();
    toast.clearWaitingQueue();
    window.location.replace('/login');
  }, [dispatch]);

  return [logout];
};

export default useLogout;
