import { memo } from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import {
  ICheckoutUserData,
  IRegistrationSuccessSettings,
} from '../../../../../../tsTypes/checkout.interfaces';
import SuccessSign from '../../../../../UI/ConfirmResult/SuccessSign/SuccessSign';
import classes from './RegistrationSuccessSection.module.scss';
import NoData from '../../../../../UI/NoData/NoData';
import DataRow from '../../../../../UI/DataRow/DataRow';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import RegistrationSuccessPDF from '../../../../../pdfComponents/RegistrationSuccess/RegistrationSuccessPDF';
import { usePDF } from '@react-pdf/renderer';
import { FaFilePdf } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface IProps {
  user: ICheckoutUserData;
  settings: IRegistrationSuccessSettings | null;
}

const RegistrationSuccessSection = ({ user, settings }: IProps) => {
  const doc = <RegistrationSuccessPDF user={user} />;
  const [instance, update] = usePDF({ document: doc });
  let pdfDoc: React.ReactNode | null = null;
  const hasPdfDownload =
    settings && typeof settings === 'object'
      ? settings.downloadableDetailsOption
      : true;

  const { t } = useTranslation();

  if (!user) {
    return (
      <ContentContainer>
        <NoData message={t('checkout:no_user_data_error')} />
      </ContentContainer>
    );
  }

  const copyToClipBoardHandler = (data: string, pinName: string) => {
    if (data && typeof data === 'string') {
      navigator.clipboard.writeText(data);
      toast.info(
        <ToastifyStyledMessage
          singleMessage={`${pinName} ${t('checkout:pin_copied_success')}`}
          heading={t('checkout:pin_code')}
        />,
        { autoClose: 1200 }
      );
    }
  };

  const updatePDFHandler = () => {
    update(doc);
  };

  const accountCreationDate = new Date(user.createdAt).toLocaleDateString(
    'en',
    {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }
  );

  const message = user.isEmailConfirmed
    ? `${t('checkout:account_create_success')}
    ${accountCreationDate}. ${t('checkout:account_details_message')}`
    : `${t('checkout:account_create_success')} ${accountCreationDate}. ${t(
        'checkout:account_details_message_2'
      )}`;

  if (instance && instance.url && user && hasPdfDownload) {
    pdfDoc = (
      <a
        href={instance.url}
        download={`Periopsis_${user.name}_${
          user.surname
        }_${new Date().getTime()}.pdf`}
        onClick={updatePDFHandler}
        title={t('checkout:download_registration_details')}
        className={classes.DownloadLink}
      >
        <FaFilePdf /> {t('checkout:download_details')}
      </a>
    );
  }

  return (
    <ContentContainer>
      <SuccessSign />
      <div className={classes.RegisteredUserData}>
        <div className={classes.MessageSection}>
          <p>
            {t('checkout:dear')} {user.name} {user.surname}
          </p>
          <p className={classes.Message}>{message}</p>
        </div>
        <ul className={classes.RegistrationData}>
          <li>
            <DataRow
              heading={t('translations:Name')}
              title={t('checkout:your_registered_first_name')}
              data={user.name}
              removeSeperator
            />
          </li>
          <li>
            <DataRow
              heading={t('translations:Surname')}
              title={t('checkout:your_registered_family_name')}
              data={user.surname}
              removeSeperator
            />
          </li>
          <li>
            <DataRow
              heading={t('auth:email')}
              title={t('checkout:your_registered_email_address')}
              data={user.email}
              removeSeperator
            />
          </li>
          <li>
            <DataRow
              heading={t('checkout:username')}
              title={t('checkout:auto_generated_username')}
              data={user.username}
              removeSeperator
            />
          </li>
          <li className={classes.PinSection}>
            <p className={classes.PinMessage}>
              {t('checkout:account_recovery_codes_desc')}
            </p>
            <DataRow
              heading={t('checkout:pin_one')}
              data={user.userPins.userPinOne}
              removeSeperator
              onClick={() =>
                copyToClipBoardHandler(user.userPins.userPinOne, 'Pin One')
              }
              className={classes.PinRow}
              title={t('checkout:to_copy_the_pin')}
            />
            <DataRow
              heading={t('checkout:pin_two')}
              data={user.userPins.userPinTwo}
              removeSeperator
              onClick={() =>
                copyToClipBoardHandler(user.userPins.userPinTwo, 'Pin Two')
              }
              className={classes.PinRow}
              title={t('checkout:to_copy_the_pin_2')}
            />
          </li>
        </ul>
        {hasPdfDownload ? (
          <div className={classes.DownloadSection}>{pdfDoc}</div>
        ) : null}
      </div>
    </ContentContainer>
  );
};

export default memo(RegistrationSuccessSection);
