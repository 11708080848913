import { useEffect } from 'react';
import useLogout from './useLogout';

const useToken = (setup?: { giveUserId?: boolean }) => {
  const [logout] = useLogout();
  let pToken = '';
  let token = '';
  let userId = '';
  const pageToken = localStorage.getItem('pToken');
  const userData = localStorage.getItem('uData');

  useEffect(() => {
    if (!pageToken || !userData) {
      logout();
    }
  }, [logout, pageToken, userData]);

  if (pageToken && userData) {
    const uData = JSON.parse(userData);
    pToken = pageToken;
    token = uData.token;
    userId = uData.id;
  }

  if (setup && setup.giveUserId) {
    return { token, pToken, userId };
  }

  return { token, pToken };
};

export default useToken;
