import { ChangeEvent, Fragment, useState } from 'react';
import { useGetMonthlyActionStatisticsQuery } from '../../../../../features/statistics/statisticsSlice';
import FetchingReports from '../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import TableContainer from '../../../../UI/Table/TableContainer/TableContainer';
import TableRow from '../../../../UI/Table/TableRow/TableRow';
import TableItemContainer from '../TableItemContainer/TableItemContainer';
import ActionOptions from '../ActionOptions/ActionOptions';
import classes from './ActionsTableStats.module.scss';
import { IActionStatProps } from '../../Statistics';
import ErrorControlComponent from '../../../../UI/ErrorControlComponent/ErrorControlComponent';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

const ActionsTableStats = ({
  isSelected,
  contractId,
  currentYear,
  isYearsReady,
  mainHeadings,
}: IActionStatProps) => {
  const { isLoading, isError, data, error } =
    useGetMonthlyActionStatisticsQuery(
      {
        contractId,
        year: currentYear,
      },
      { skip: !isSelected }
    );
  let content: React.ReactNode | null = null;

  const [selectedTable, setSelectedTable] = useState('visited');
  const { t } = useTranslation();

  const isVisitedTable = selectedTable === 'visited';
  const isReportedTable = selectedTable === 'reported';
  const isCleanedTable = selectedTable === 'cleaned';

  if (!isSelected || !isYearsReady) {
    return null;
  }

  if (isLoading) {
    content = (
      <FetchingReports message={t('statistics:fetching_monthly_statistics')} />
    );
  }

  if (isError) {
    content = (
      <ErrorControlComponent error={error}>
        <NoDataFound
          message={t('statistics:monthly_action_statistics_fetch_error')}
        />
      </ErrorControlComponent>
    );
  }

  if (
    !isError &&
    !isLoading &&
    data &&
    typeof data === 'object' &&
    'data' in data &&
    data.data &&
    typeof data.data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'chartData' in data.data &&
    data.data.chartData &&
    typeof data.data.chartData === 'object' &&
    'results' in data.data &&
    data.data.results &&
    typeof data.data.results === 'object'
  ) {
    const visitResult = data.data.results.visitResult as {
      month: string;
      total: number;
    }[];

    const reportResult = data.data.results.reportResult as {
      month: string;
      total: number;
    }[];

    const cleanResult = data.data.results.cleanResult as {
      month: string;
      total: number;
    }[];

    const visitPercent = data.data.results.visitPercentages as {
      month: string;
      percentageString: string;
    }[];

    const reportPercent = data.data.results.reportPercentages as {
      month: string;
      percentageString: string;
    }[];

    const cleanPercent = data.data.results.cleanPercentages as {
      month: string;
      percentageString: string;
    }[];

    const incidentCounts = data.data.results.incidentCounts as {
      month: string;
      total: number;
    }[];

    const visitHeadings = [
      t('statistics:month'),
      t('statistics:detected_incidents'),
      t('statistics:total_visited'),
      t('statistics:percentage'),
    ];

    const reportHeadings = [
      t('statistics:month'),
      t('statistics:detected_incidents'),
      t('statistics:total_reported'),
      t('statistics:percentage'),
    ];
    const cleanHeadings = [
      t('statistics:month'),
      t('statistics:detected_incidents'),
      t('statistics:total_cleaned'),
      t('statistics:percentage'),
    ];

    const visitData = visitResult.map((res, i) => [
      t(`statistics:${getTranslationKey(res.month)}`),
      incidentCounts[i].total.toString(),
      res.total.toString(),
      visitPercent[i].percentageString,
    ]);
    const reportData = reportResult.map((res, i) => [
      t(`statistics:${getTranslationKey(res.month)}`),
      incidentCounts[i].total.toString(),
      res.total.toString(),
      reportPercent[i].percentageString,
    ]);
    const cleanData = cleanResult.map((res, i) => [
      t(`statistics:${getTranslationKey(res.month)}`),
      incidentCounts[i].total.toString(),
      res.total.toString(),
      cleanPercent[i].percentageString,
    ]);

    const tableSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedTable(e.target.value);
    };

    content = (
      <div className={classes.DataTableSection}>
        <ActionOptions
          onSelect={tableSelectHandler}
          selected={{ isCleanedTable, isReportedTable, isVisitedTable }}
        />
        <TableItemContainer
          heading={mainHeadings ? mainHeadings.visited : ''}
          isVisible={isVisitedTable}
        >
          <TableContainer headings={visitHeadings}>
            {visitData.map((res, i) => (
              <TableRow key={i} rowData={res} />
            ))}
          </TableContainer>
        </TableItemContainer>

        <TableItemContainer
          heading={mainHeadings ? mainHeadings.reported : ''}
          isVisible={isReportedTable}
        >
          <TableContainer headings={reportHeadings}>
            {reportData.map((res, i) => (
              <TableRow key={i} rowData={res} />
            ))}
          </TableContainer>
        </TableItemContainer>
        <TableItemContainer
          heading={mainHeadings ? mainHeadings.cleaned : ''}
          isVisible={isCleanedTable}
        >
          <TableContainer headings={cleanHeadings}>
            {cleanData.map((res, i) => (
              <TableRow key={i} rowData={res} />
            ))}
          </TableContainer>
        </TableItemContainer>
      </div>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default ActionsTableStats;
