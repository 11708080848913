import { Fragment } from 'react';
import DataRow from '../../../../UI/DataRow/DataRow';
import { IContractIncident } from '../../../../../tsTypes/interfaces';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

interface IProps {
  selectedIncident: IContractIncident;
  showActions?: boolean;
}

const IncidentDetails = ({ selectedIncident, showActions }: IProps) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en';

  return (
    <Fragment>
      <DataRow
        data={
          selectedIncident.type.toLocaleLowerCase() ===
          'large site containing dumping'
            ? 'L.S.C Dumping'
            : `${t(`dashboard:${getTranslationKey(selectedIncident.type)}`)}`
        }
        heading={t('incidentDashboard:Incident_Type')}
        removeSeperator
      />
      <DataRow
        heading={t('incidentDashboard:Detected_At')}
        data={new Date(selectedIncident.detectionDate).toLocaleDateString(
          language,
          {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
          }
        )}
        removeSeperator
      />
      {showActions ? (
        <Fragment>
          <DataRow
            heading={t('dashboard:Visited')}
            data={
              selectedIncident.incidentActions.visit
                ? t('incidentDashboard:Yes')
                : t('incidentDashboard:No')
            }
            removeSeperator
          />
          <DataRow
            heading={t('dashboard:Reported')}
            data={
              selectedIncident.incidentActions.report.length > 0
                ? t('incidentDashboard:Yes')
                : t('incidentDashboard:No')
            }
            removeSeperator
          />
          <DataRow
            heading={t('dashboard:Cleaned')}
            data={
              selectedIncident.incidentActions.clean ||
              selectedIncident.isCleaned
                ? t('incidentDashboard:Yes')
                : t('incidentDashboard:No')
            }
            removeSeperator
          />

          {selectedIncident.cleanedDate &&
          typeof selectedIncident.cleanedDate === 'string' ? (
            <DataRow
              heading={t('incidentDashboard:Cleaned_At')}
              data={selectedIncident.cleanedDate}
              removeSeperator
            />
          ) : null}
        </Fragment>
      ) : null}
      <DataRow
        heading={t('translations:Latitude')}
        data={selectedIncident.coordinates[0].toFixed(5)}
        removeSeperator
      />
      <DataRow
        heading={t('translations:Longitude')}
        data={selectedIncident.coordinates[1].toFixed(5)}
        removeSeperator
      />
    </Fragment>
  );
};

export default IncidentDetails;
