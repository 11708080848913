import { Fragment, useState, memo, useEffect } from 'react';
import {
  ICheckoutContract,
  ICheckoutProforma,
  ICheckoutSubService,
  ICheckoutUserData,
  IProformaSettings,
} from '../../../../../../tsTypes/checkout.interfaces';
import DataRow from '../../../../../UI/DataRow/DataRow';
import NoData from '../../../../../UI/NoData/NoData';
import ContentContainer from '../ContentContainer/ContentContainer';
import OrderDataCard from './OrderDataCard/OrderDataCard';
import { IAreaOfInterest } from '../../../../../../tsTypes/types.components';
import ListContainer from './ListContainer/ListContainer';
import ProformaInvoicePDF from '../../../../../pdfComponents/ProformaInvoice/ProformaInvoicePDF';
import { usePDF } from '@react-pdf/renderer';
import { FaFilePdf } from 'react-icons/fa';
import getTotalPolygonPrice from '../../../../../../utils/getTotalPolygonPrice';
import AddressContent from '../AddressContent/AddressContent';
import classes from './OrderDetailsSection.module.scss';
import CustomerDetails from '../CustomerDetails/CustomerDetails';
import ProformaAndContract from '../../../../ProformaInvoiceView/ProformaAndContract/ProformaAndContract';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

interface IProps {
  proformaInvoice: ICheckoutProforma;
  contract: ICheckoutContract;
  user: ICheckoutUserData;
  settings: IProformaSettings | null;
  onAddressUpdate: () => void;
}

const OrderDetailsSection = ({
  proformaInvoice,
  contract,
  user,
  settings,
  onAddressUpdate,
}: IProps) => {
  const doc = (
    <ProformaInvoicePDF
      proformaInvoice={proformaInvoice}
      contract={contract}
      user={user}
    />
  );
  const [instance, update] = usePDF({ document: doc });
  const { t } = useTranslation();
  let pdfDoc: React.ReactNode | null = null;
  let hasSubservices = false;
  let isContractReady = false;
  let subServices: ICheckoutSubService[] = [];
  let districts: string[] = [];
  let polygons: IAreaOfInterest[] = [];
  let userPhone = '';

  const hasDownloadablePdf =
    settings && typeof settings === 'object'
      ? settings.downloadableProformaOption
      : true;

  const hasChangeBillingAdressOption =
    settings && typeof settings === 'object'
      ? settings.changeBillingAddressOption
      : true;

  const hasSettings =
    settings !== null &&
    typeof settings === 'object' &&
    'selectedPolygonsExpandListDefault' in settings &&
    'subservicesExpandListDefault' in settings;

  const [showSubservices, setShowSubservices] = useState(false);
  const [showPolygons, setShowPolygons] = useState(false);

  useEffect(() => {
    if (hasSettings) {
      const subserviceListStatus = settings.subservicesExpandListDefault;
      const polygonsListStatus = settings.selectedPolygonsExpandListDefault;

      setShowSubservices(subserviceListStatus);
      setShowPolygons(polygonsListStatus);
    }
    // eslint-disable-next-line
  }, [hasSettings]);

  const proformaInvoiceAddressId =
    proformaInvoice &&
    typeof proformaInvoice === 'object' &&
    'address' in proformaInvoice &&
    proformaInvoice.address
      ? proformaInvoice.address.addressId
      : '';

  useEffect(() => {
    if (proformaInvoiceAddressId && hasDownloadablePdf) {
      update(doc);
    }

    // eslint-disable-next-line
  }, [proformaInvoiceAddressId, hasDownloadablePdf]);

  if (!proformaInvoice || typeof proformaInvoice !== 'object') {
    return (
      <ContentContainer>
        <NoData message={t('checkout:no_invoice_data')} />
      </ContentContainer>
    );
  }

  if (!contract || typeof contract !== 'object') {
    return (
      <ContentContainer>
        <NoData message={t('checkout:no_contract_data')} />
      </ContentContainer>
    );
  }

  const showSubServicesHandler = () => {
    setShowSubservices((prevState) => !prevState);
  };

  const showPolygonsHandler = () => {
    setShowPolygons((prevState) => !prevState);
  };

  const updatePDFHandler = () => {
    update(doc);
  };

  const subservicesHeading = showSubservices
    ? t('checkout:selected_subservices')
    : t('checkout:subservices');

  if (
    proformaInvoice &&
    typeof proformaInvoice === 'object' &&
    'selectedPackage' in proformaInvoice &&
    proformaInvoice.selectedPackage &&
    typeof proformaInvoice.selectedPackage === 'object' &&
    'subServices' in proformaInvoice.selectedPackage &&
    Array.isArray(proformaInvoice.selectedPackage.subServices) &&
    proformaInvoice.selectedPackage.subServices.length > 0
  ) {
    hasSubservices = true;
    subServices = proformaInvoice.selectedPackage.subServices;
  }

  if (
    contract &&
    typeof contract === 'object' &&
    'polygons' in contract &&
    contract.polygons &&
    Array.isArray(contract.polygons) &&
    contract.polygons.length > 0 &&
    'selectedDistricts' in contract &&
    contract.selectedDistricts &&
    Array.isArray(contract.selectedDistricts) &&
    contract.selectedDistricts.length > 0
  ) {
    isContractReady = true;
    districts = contract.selectedDistricts;
    polygons = contract.polygons;
  }

  if (
    user &&
    typeof user === 'object' &&
    'userPhone' in user &&
    user.userPhone &&
    typeof user.userPhone === 'string'
  ) {
    userPhone = user.userPhone;
  }

  if (
    instance &&
    instance.url &&
    proformaInvoice &&
    contract &&
    hasDownloadablePdf
  ) {
    pdfDoc = (
      <a
        href={instance.url}
        download={`Periopsis_${
          proformaInvoice.proformaInvoiceNumber
        }_${new Date().getTime()}.pdf`}
        onClick={updatePDFHandler}
        title={t('checkout:download_proforma_invoice')}
        className={classes.DownloadLink}
      >
        <FaFilePdf /> {t('checkout:download_proforma_invoice')}
      </a>
    );
  }

  /**
   * @TODO : Move Address form to the Billing Address Form Component
   * @TODO : Create Add New Address API, address will be added to the addresses list of user
   * as soon as adding address, selected address should be new address and it should
   * directly open the customer details Customer Billing Address Window with new Billing Address
   * To do so, we will add new address to the addresses of the user, we will update the address
   * at Proforma Invoice.
   */

  return (
    <ContentContainer gap='2.3rem'>
      <ProformaAndContract
        proformaInvoice={proformaInvoice}
        contract={contract}
      />
      <OrderDataCard heading={t('checkout:selection_details')}>
        <DataRow
          heading={t('checkout:selected_service_type')}
          data={t(
            `dashboard:${getTranslationKey(proformaInvoice.serviceType)}`
          )}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:selected_package')}
          data={t(
            `auth:${getTranslationKey(
              proformaInvoice.selectedPackage.packageName
            )}`
          )}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:monthly_price')}
          data={`${proformaInvoice.selectedPackage.currency}${proformaInvoice.selectedPackage.monthlyPrice}${proformaInvoice.selectedPackage.priceUnit}`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:duration')}
          data={`${proformaInvoice.selectedPackage.duration} ${proformaInvoice.selectedPackage.durationUnit}`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:package_area_limit')}
          data={`${proformaInvoice.selectedPackage.areaLimit} ${proformaInvoice.selectedPackage.areaLimitUnit}`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:selected_total_area')}
          data={`${proformaInvoice.totalArea} ${proformaInvoice.areaUnit}`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:user_limit')}
          data={proformaInvoice.selectedPackage.userLimit.toString()}
          removeSeperator
        />

        {hasSubservices && subServices.length > 0 ? (
          <ListContainer
            heading={`${subservicesHeading} (${subServices.length} subservices selected)`}
            onExpand={showSubServicesHandler}
            isExpanded={showSubservices}
            expandMessage={t('checkout:expand_message')}
          >
            <ul
              className={`${classes.SubServiceListWrapper} ${
                showSubservices ? classes.Show : ''
              }`}
            >
              {subServices.map((subservice) => (
                <li key={subservice._id}>
                  <DataRow
                    heading={t('checkout:subservice')}
                    data={subservice.subServiceName}
                    removeSeperator
                  />
                  <DataRow
                    heading={t('checkout:price')}
                    data={`${subservice.currency}${subservice.unitPrice}/${t(
                      'auth:month'
                    )}`}
                    removeSeperator
                  />
                </li>
              ))}
            </ul>
          </ListContainer>
        ) : null}

        {isContractReady ? (
          <Fragment>
            <ListContainer heading={t('checkout:selected_districts')}>
              <div className={classes.SelectedDistricts}>
                {districts.map((district) => (
                  <span key={district}>
                    {t(`translations:${getTranslationKey(district)}`)}
                  </span>
                ))}
              </div>
            </ListContainer>

            <ListContainer
              heading={t(`checkout:selected_polygons`)}
              onExpand={showPolygonsHandler}
              isExpanded={showPolygons}
              expandMessage={t('checkout:polygons_expand_message')}
            >
              <ul
                className={`${classes.SubServiceListWrapper} ${
                  showPolygons ? classes.Show : ''
                }`}
              >
                {polygons.map((polygon) => (
                  <li key={polygon.mid}>
                    <DataRow
                      heading={t('checkout:polygon_id')}
                      data={polygon.mid}
                      removeSeperator
                    />

                    {polygon.polygonName ? (
                      <DataRow
                        heading={t('checkout:polygon_name')}
                        data={t(
                          `translations:${getTranslationKey(
                            polygon.polygonName
                          )}`
                        )}
                        removeSeperator
                      />
                    ) : null}
                    <DataRow
                      heading={t('checkout:area')}
                      data={`${polygon.area} ${polygon.areaUnit}`}
                      removeSeperator
                    />
                    <DataRow
                      heading={t('checkout:polygon_price')}
                      data={`${
                        proformaInvoice.selectedPackage.currency
                      }${getTotalPolygonPrice(
                        polygon.area,
                        proformaInvoice.selectedPackage.monthlyPrice
                      )}/${t('auth:month')}`}
                    />
                    <DataRow
                      heading={t('auth:district')}
                      data={t(
                        `translations:${getTranslationKey(polygon.district)}`
                      )}
                      removeSeperator
                    />
                    {polygon.province ? (
                      <DataRow
                        heading={t('checkout:province')}
                        data={polygon.province}
                        removeSeperator
                      />
                    ) : null}
                    <DataRow
                      heading={t('checkout:country')}
                      data={t(`auth:${getTranslationKey(polygon.country)}`)}
                      removeSeperator
                    />
                  </li>
                ))}
              </ul>
            </ListContainer>
          </Fragment>
        ) : null}
      </OrderDataCard>
      <OrderDataCard heading={t('checkout:customer_details')}>
        <Fragment>
          <CustomerDetails
            customerEmail={proformaInvoice.customerEmail}
            customerName={proformaInvoice.customerName}
            userPhone={userPhone}
          />
          <AddressContent
            proformaInvoice={proformaInvoice}
            user={user}
            onAddressUpdate={onAddressUpdate}
            isAddressUpdatable={hasChangeBillingAdressOption}
          />
        </Fragment>
      </OrderDataCard>
      <OrderDataCard heading={t('checkout:pricing_details')}>
        <DataRow
          heading={t('checkout:polygon_total_area_price')}
          data={`${proformaInvoice.selectedPackage.currency}${(
            proformaInvoice.totalAmount -
            proformaInvoice.selectedPackage.subTotal
          ).toFixed(2)}`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:subservices_price')}
          data={`${
            proformaInvoice.selectedPackage.currency
          }${proformaInvoice.selectedPackage.subTotal.toFixed(2)}`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:discount_rate')}
          data={`${proformaInvoice.discountRate.toFixed(2)}%`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:discount_amount')}
          data={`${
            proformaInvoice.selectedPackage.currency
          }${proformaInvoice.discountAmount.toFixed(2)}`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:vat_rate')}
          data={`${proformaInvoice.VATRate.toFixed(2)}%`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:vat_amount')}
          data={`${
            proformaInvoice.selectedPackage.currency
          }${proformaInvoice.VATAmount.toFixed(2)}`}
          removeSeperator
        />
        <DataRow
          heading={t('checkout:overall_total')}
          data={`${
            proformaInvoice.selectedPackage.currency
          }${proformaInvoice.overallTotal.toFixed(2)} / ${t('auth:month')}`}
          removeSeperator
          className={classes.OverallTotal}
        />
        <DataRow
          heading={t('checkout:service_total_amount')}
          data={`${proformaInvoice.selectedPackage.currency}${(
            (proformaInvoice.selectedPackage.duration / 30) *
            proformaInvoice.overallTotal
          ).toFixed(2)}`}
          className={`${classes.OverallTotal} ${classes.NoBorder}`}
          removeSeperator
        />
      </OrderDataCard>
      {hasDownloadablePdf ? (
        <div className={classes.DownloadSection}>{pdfDoc}</div>
      ) : null}
      <div className={classes.ValidityNote}>
        <p>{t('checkout:validity_note')}</p>
      </div>
    </ContentContainer>
  );
};

export default memo(OrderDetailsSection);
