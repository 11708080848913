import React, { Fragment } from 'react';
import DataRow from '../../../../../UI/DataRow/DataRow';
import { useTranslation } from 'react-i18next';

interface IProps {
  customerName: string;
  customerEmail: string;
  userPhone: string;
}

const CustomerDetails = ({
  customerName,
  customerEmail,
  userPhone,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <DataRow
        heading={t('checkout:name')}
        data={customerName}
        removeSeperator
      />
      <DataRow
        heading={t('checkout:email')}
        data={customerEmail}
        removeSeperator
      />
      <DataRow
        heading={t('checkout:phone_number')}
        data={userPhone}
        removeSeperator
      />
    </Fragment>
  );
};

export default CustomerDetails;
