import { ForwardedRef, Fragment, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import classes from './Button.module.scss';

interface IProps {
  children?: JSX.Element | JSX.Element[] | String;
  icon?: string | JSX.Element;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  isLink?: boolean;
  href?: string;
  style?: React.CSSProperties;
  btnClass?: string;
  removeOriginalClass?: boolean;
  isFake?: boolean;
  title?: string;
}

const Button = (
  {
    children,
    icon,
    onClick,
    type,
    isLink,
    href,
    style,
    btnClass,
    removeOriginalClass,
    isFake,
    title,
  }: IProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const buttonClass = `${
    !removeOriginalClass ? classes.Button : ''
  } ${btnClass}`;

  const buttonContent = (
    <Fragment>
      {/* <span className={classes.MovingBorder}></span>
      <span className={classes.MovingBorder}></span>
      <span className={classes.MovingBorder}></span>
      <span className={classes.MovingBorder}></span> */}
      <i>{icon}</i>
      {children}
    </Fragment>
  );

  if (isFake) {
    return (
      <div className={`${buttonClass} ${classes.Disabled}`} title={title}>
        <i>{icon}</i>
        {children}
      </div>
    );
  }

  if (isLink) {
    return (
      <Link
        to={href ? href : '/'}
        className={buttonClass}
        style={style}
        title={title}
      >
        {buttonContent}
      </Link>
    );
  }

  if (!isLink && href) {
    return (
      <a className={buttonClass} href={href} title={title}>
        {buttonContent}
      </a>
    );
  }

  return (
    <button
      onClick={onClick}
      className={buttonClass}
      type={type}
      style={style}
      ref={ref}
      title={title}
    >
      {buttonContent}
    </button>
  );
};

export default forwardRef(Button);
