import { Text } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './ReportNote.style';

interface IProps {
  children: React.ReactNode;
}

const ReportNote = ({ children }: IProps) => {
  return <Text style={styles.text}>{children}</Text>;
};

export default ReportNote;
