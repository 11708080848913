import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  selectAllAuthValues,
  useGetUserSettingsQuery,
  useUpdateGeneralSettingsMutation,
} from '../../../features/auth/authSlice';
import ColumnDataContainer from '../../UI/ColumnDataContainer/ColumnDataContainer';
import Form from '../../UI/FormElements/Form/Form';
import Input from '../../UI/FormElements/Input/Input';
import { AiOutlineSecurityScan } from 'react-icons/ai';
import {
  MdOutlineNotificationImportant,
  MdOutlineEditNotifications,
} from 'react-icons/md';
import { TbFileReport } from 'react-icons/tb';

import IOSRadioButton from '../../UI/FormElements/IOSRadioButton/IOSRadioButton';
import Setting from './Setting/Setting';
import ActionContainer from '../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../UI/FormElements/StandardButton/StandardButton';
import { selectAllUIValues } from '../../../features/UI/uiSlice';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import {
  IUpdateGeneralSettings,
  selectData,
} from '../../../tsTypes/types.components';
import { alpha2Code } from '../../../tsTypes/types.components';
import { frontendConstants } from '../../../constants/constants';
import useToken from '../../../CustomHooks/useToken';
import SimpleLoading from '../../UI/SimpleLoading/SimpleLoading';
import classes from './Settings.module.scss';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation();
  const TWO_FACTOR_AUTH_DESCRIPTION = t('settings:twofa_desc');
  const { language } = useSelector(selectAllUIValues);
  const [twoFA, setTwoFA] = useState('disabled');
  const [logoutReminderCheck, setLogoutReminder] = useState('enabled');
  const [logoutReminderDurationSetting, setLogoutReminderDuration] =
    useState('45');
  const [showImagesFirst, setShowImagesFirst] = useState<selectData>('images');
  const [loginReport, setLoginReport] = useState('disabled');
  const savedSettingsAssigned = useRef(false);
  const { token, pToken, userId } = useToken({ giveUserId: true });
  const iconSize = 21.5;

  const { data, isLoading: isLoadingSettings } = useGetUserSettingsQuery('');

  const [updateUserSettings, { isLoading }] =
    useUpdateGeneralSettingsMutation();

  const { user, isAuthenticated, hasToken } = useSelector(selectAllAuthValues);

  const isTwoFAEnabled = twoFA === 'enabled';
  const isLogoutReminderEnabled = logoutReminderCheck === 'enabled';
  const isLoginReportEnabled = loginReport === 'enabled';
  const userIdValue = userId ? userId : '';

  const need2FA = data?.need2FA;
  const shouldRemindLogout = data?.shouldRemindLogout;
  const shouldSendLoginReport = data?.shouldSendLoginReport;
  const remindLogoutDuration = data?.remindLogoutDuration;
  const showIncidentImages = data?.showIncidentImages;

  let content = (
    <div className={classes.LoadingContainer}>
      <SimpleLoading />
    </div>
  );

  //At start, saved user settings should be assigned
  useEffect(() => {
    if (
      need2FA !== undefined &&
      shouldRemindLogout !== undefined &&
      shouldSendLoginReport !== undefined &&
      remindLogoutDuration &&
      showIncidentImages !== undefined &&
      !savedSettingsAssigned.current
    ) {
      const user2FAPreferrence = need2FA ? 'enabled' : 'disabled';
      const userLogoutReminderPreferrence = shouldRemindLogout
        ? 'enabled'
        : 'disabled';
      const userSendLoginReportPreferrence = shouldSendLoginReport
        ? 'enabled'
        : 'disabled';

      let validRemindDuration =
        remindLogoutDuration > 1500
          ? remindLogoutDuration / 1000
          : remindLogoutDuration < 45
          ? 45
          : remindLogoutDuration;

      const userLogoutReminderPreferrenceDuration = validRemindDuration || 45;
      const userShowIncidentImages: selectData = showIncidentImages
        ? 'images'
        : 'data';

      setTwoFA(user2FAPreferrence);
      setLogoutReminder(userLogoutReminderPreferrence);
      setLogoutReminderDuration(
        userLogoutReminderPreferrenceDuration.toString()
      );
      setLoginReport(userSendLoginReportPreferrence);
      setShowImagesFirst(userShowIncidentImages);

      savedSettingsAssigned.current = true;
    }
  }, [
    need2FA,
    shouldRemindLogout,
    shouldSendLoginReport,
    remindLogoutDuration,
    showIncidentImages,
  ]);

  if (
    !user &&
    !isAuthenticated &&
    !hasToken &&
    !isLoading &&
    !isLoadingSettings
  ) {
    return <Navigate to={frontendConstants.pages.login} replace />;
  }

  const twoFASelectionHandler = () => {
    if (twoFA === 'disabled') {
      setTwoFA('enabled');
    } else {
      setTwoFA('disabled');
    }
  };

  const logoutReminderSelectionHandler = () => {
    if (logoutReminderCheck === 'disabled') {
      setLogoutReminder('enabled');
    } else {
      setLogoutReminder('disabled');
    }
  };

  const loginReportSelectionHandler = () => {
    if (loginReport === 'disabled') {
      setLoginReport('enabled');
    } else {
      setLoginReport('disabled');
    }
  };

  const logoutReminderDurationHanlder = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (Number(value) >= 45 && Number(value) <= 1500) {
      setLogoutReminderDuration(value);
    }
  };

  const settingsSubmitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const twoFAuthentication = twoFA === 'enabled' ? true : false;
      const logoutReminder = logoutReminderCheck === 'enabled' ? true : false;
      const logoutReminderDuration =
        Number(logoutReminderDurationSetting) || 45;
      const sendLoginReport = loginReport === 'enabled' ? true : false;
      const preferredLanguage = language.toString() as alpha2Code;
      const showIncidentImages = showImagesFirst === 'images';

      const domManipulationError = (
        message: string = t('settings:dom_values_manipulation_message')
      ) => {
        toast.error(
          <ToastifyStyledMessage
            singleMessage={message}
            heading={t('settings:dom_manipulation')}
          />
        );
        return;
      };

      if (
        typeof twoFAuthentication !== 'boolean' ||
        typeof logoutReminder !== 'boolean' ||
        (logoutReminderDuration &&
          typeof logoutReminderDuration !== 'number') ||
        typeof sendLoginReport !== 'boolean' ||
        (preferredLanguage && typeof preferredLanguage !== 'string') ||
        typeof showIncidentImages !== 'boolean'
      ) {
        return domManipulationError();
      }

      if (preferredLanguage && preferredLanguage.length !== 2) {
        return domManipulationError(t('settings:preferred_language_dom_error'));
      }

      if (
        logoutReminderDuration &&
        typeof logoutReminderDuration === 'number' &&
        (logoutReminderDuration > 1500 || logoutReminderDuration < 45)
      ) {
        return domManipulationError(t('settings:logout_duration_error'));
      }

      //Now we can dispatch the settings update!
      const updateData: IUpdateGeneralSettings = {
        userId: userIdValue,
        token,
        pToken,
        twoFAuthentication,
        logoutReminder,
        logoutReminderDuration,
        sendLoginReport,
        preferredLanguage,
        showIncidentImages,
      };

      const res = await updateUserSettings(updateData).unwrap();

      if (res && 'isSuccess' in res && res.isSuccess) {
        return toast.success(
          <ToastifyStyledMessage
            singleMessage={t('settings:settings_updated_successfully')}
            heading={t('settings:update_success')}
          />
        );
      }
    } catch (error) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('settings:update_error_message')}
          heading={t('settings:update_error')}
        />
      );
    }

    // dispatch(generalSettingsUpdate(updateData));
  };

  if (!isLoading && !isLoadingSettings) {
    content = (
      <Form onSubmit={settingsSubmitHandler}>
        <Setting
          label={t('settings:two_factor_authentication')}
          Icon={<AiOutlineSecurityScan size={iconSize} />}
          optionalText={TWO_FACTOR_AUTH_DESCRIPTION}
          style={{ gridTemplateColumns: '50px 1fr max-content' }}
        >
          <IOSRadioButton
            options={{ option1: 'Enabled', option2: 'Disabled' }}
            onChange={twoFASelectionHandler}
            size={0.53}
            id='twofactorauth'
            checked={isTwoFAEnabled}
          />
        </Setting>

        <Setting
          label={t('settings:logout_reminder')}
          Icon={<MdOutlineNotificationImportant size={iconSize} />}
          style={{ gridTemplateColumns: '50px 1fr max-content' }}
          optionalText={t('settings:logout_reminder_desc')}
        >
          <IOSRadioButton
            options={{ option1: 'Enabled', option2: 'Disabled' }}
            onChange={logoutReminderSelectionHandler}
            size={0.53}
            id='logoutreminder'
            checked={isLogoutReminderEnabled}
          />
        </Setting>
        {isLogoutReminderEnabled && (
          <Setting
            label={t('settings:logout_reminder_duration_label')}
            Icon={<MdOutlineEditNotifications size={iconSize} />}
            style={{ gridTemplateColumns: '50px 1fr max-content' }}
            optionalText={t('settings:logout_reminder_duration_desc')}
          >
            <Input
              type='number'
              label=''
              value={logoutReminderDurationSetting}
              onChange={logoutReminderDurationHanlder}
              name='logoutReminderDuration'
              style={{
                fontSize: '0.75rem',
                textAlign: 'center',
                border: 0,
              }}
            />
          </Setting>
        )}
        <Setting
          label={t('settings:send_login_report')}
          Icon={<TbFileReport size={iconSize} />}
          style={{ gridTemplateColumns: '50px 1fr max-content' }}
          optionalText={t('settings:send_login_report_desc')}
        >
          <IOSRadioButton
            options={{ option1: 'Enabled', option2: 'Disabled' }}
            onChange={loginReportSelectionHandler}
            size={0.53}
            id='sendLoginReport'
            checked={isLoginReportEnabled}
          />
        </Setting>
        {/* <Setting
      label='Preferred Language'
      Icon={<MdLanguage size={iconSize} />}
      style={{ gridTemplateColumns: '50px 1fr max-content' }}
      optionalText='By default, the language is English or the selected language while the user registering to the Periopsis, you may change the preferred language and hence each time, after login process, the site opens with the preferred langauge instead of the default ones'
    >
      <SelectLanguage />
    </Setting> */}

        <ActionContainer style={{ marginTop: '2rem' }}>
          <StandardButton label={t('profilesettings:save')} btnType='main' />
        </ActionContainer>
      </Form>
    );
  }

  return (
    <ColumnDataContainer heading={t('settings:settings')} isNarrow>
      {content}
    </ColumnDataContainer>
  );
};

export default Settings;
