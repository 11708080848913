import { useEffect, useState } from 'react';
import { frontendConstants } from '../constants/constants';
import useLogout from './useLogout';
import axios from 'axios';
import { IUser } from '../tsTypes/types.model';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../app/store';
import { loggedInUser } from '../features/auth/authSlice';

const useValidateAuth = () => {
  const [logout] = useLogout();
  const [isValidating, setIsValidating] = useState(false);

  const [authResult, setAuthResult] = useState<{
    isAuthenticated: boolean;
    hasToken: boolean;
    user: Omit<IUser, 'password'> | null;
  }>({ isAuthenticated: false, hasToken: false, user: null });

  const { isAuthenticated, hasToken, user } = authResult;
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const API_URL = `${frontendConstants.backendHost}/${frontendConstants.apiVersion}/users/validateToken`;

    const pageToken = localStorage.getItem('pToken');
    const uData = localStorage.getItem('uData');

    const validateToken = async (token: string, pToken: string) => {
      try {
        setIsValidating(true);
        const res = await axios.get(`${API_URL}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-API-KEY': pToken,
          },
        });

        dispatch(loggedInUser({ token, pToken }));

        if (res.status === 200) {
          return null;
        } else {
          logout();
        }
      } catch (error) {
        logout();
      } finally {
        setIsValidating(false);
      }
    };

    if (pageToken && uData) {
      const user = JSON.parse(uData) as Omit<IUser, 'password'>;
      const token = user.token;
      setAuthResult({ isAuthenticated: true, hasToken: true, user });

      const timer = setTimeout(async () => {
        await validateToken(token, pageToken);
        clearTimeout(timer);
      }, 400);
    }
    // eslint-disable-next-line
  }, [logout]);

  return { isAuthenticated, hasToken, user, isValidating };
};

export default useValidateAuth;
