import { FaRegCircleQuestion } from 'react-icons/fa6';
import Input from '../../../../UI/FormElements/Input/Input';
import classes from './ObservedIncidentType.module.scss';
import { ChangeEvent, Fragment, useState } from 'react';
import ObservedIncidentTypes from '../IncidentVisitForm/ObservedIncidentTypes/ObservedIncidentTypes';
import { useTranslation } from 'react-i18next';

interface IProps {
  value: string;
  isValid: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (observedType: string) => void;
}

const ObservedIncidentType = ({
  value,
  isValid,
  onChange,
  onSelect,
}: IProps) => {
  const [showObservedIncidentsList, setShowObservedIncidentsList] =
    useState(false);
  const [showFieldInfoBoard, setShowFieldInfoBoard] = useState(false);
  const { t } = useTranslation();

  const observedIncidentTypesListHandler = () => {
    setShowObservedIncidentsList((prevState) => !prevState);
  };

  return (
    <Fragment>
      <div className={classes.ObservedIncidentTypeField}>
        <Input
          type='text'
          value={value}
          name='observed-incident'
          label={t('forms:Observed_Incident_Type')}
          isInvalid={!isValid}
          onChange={onChange}
          showInformationBoard={showFieldInfoBoard}
          onFocus={() => setShowFieldInfoBoard(true)}
          onBlur={() => setShowFieldInfoBoard(false)}
        >
          <p>{t('forms:Observed_Incident_Info')}</p>
        </Input>
        <button onClick={observedIncidentTypesListHandler} type='button'>
          <FaRegCircleQuestion />
        </button>
      </div>
      <ObservedIncidentTypes
        onClose={observedIncidentTypesListHandler}
        show={showObservedIncidentsList}
        onSelect={onSelect}
      />
    </Fragment>
  );
};

export default ObservedIncidentType;
