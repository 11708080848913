import { useState, FormEvent, useEffect } from 'react';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
import Form from '../../../UI/FormElements/Form/Form';
import Input from '../../../UI/FormElements/Input/Input';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import HeadingBar from '../../../UI/HeadingBar/HeadingBar';
import Paragraph from '../../../UI/Paragraph/Paragraph';
import classes from './VerificationCodeForm.module.scss';
import FetchingReports from '../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import { useTranslation } from 'react-i18next';

interface IProps {
  onSubmit: (code: string) => void;
  isLoading: boolean;
  shouldClear: boolean;
}

const VerificationCodeForm = ({ onSubmit, isLoading, shouldClear }: IProps) => {
  const [verificationCode, setVerificationCode] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (shouldClear) {
      setVerificationCode('');
    }
  }, [shouldClear]);

  const verificationCodeEntryHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = e.target.value;

    setVerificationCode(code);
  };

  const submitCodeHandler = (e: FormEvent) => {
    e.preventDefault();

    /**
     * @TODO : Add validation for verification code
     */

    if (
      verificationCode.trim().length > 0 &&
      verificationCode.trim().length < 9
    ) {
      onSubmit(verificationCode);
    }
  };

  if (isLoading) {
    return <FetchingReports message={t('profilesettings:updating_data')} />;
  }

  return (
    <div className={classes.Wrapper}>
      <HeadingBar
        heading={t('profilesettings:verification_code')}
        headingSize={3}
      />
      <Paragraph
        paragraph={t('profilesettings:verification_code_desc')}
        style={{ marginBottom: '1rem' }}
      />
      <Form
        className={classes.VerificationFormContainer}
        onSubmit={submitCodeHandler}
      >
        <Input
          type='text'
          label={t('profilesettings:verification_code')}
          name='verification'
          value={verificationCode}
          onChange={verificationCodeEntryHandler}
        />
        <ActionContainer>
          <StandardButton
            type='submit'
            btnType='primary'
            label={t('translations:Submit')}
            style={{ width: '100%' }}
          />
        </ActionContainer>
      </Form>
    </div>
  );
};

export default VerificationCodeForm;
