import { useRef, useEffect, useState } from 'react';
import { ITimelineItem } from '../../../../../../tsTypes/interfaces';
import NavigationButtons from '../NavigationButtons/NavigationButtons';
import Logo from '../../../../../UI/Logo/Logo';
import { MdClose } from 'react-icons/md';
import classes from './FullScreenImageView.module.scss';
import Image from '../../../../../UI/Image/Image';
import IncidentAddress from '../../IncidentAddress/IncidentAddress';

interface IProps {
  timelineList: ITimelineItem[];
  currentIncident: string;
  onClose: () => void;
  show: boolean;
  incidentType?: string;
  address?: string;
}

const FullScreenImageView = ({
  timelineList,
  currentIncident,
  onClose,
  show,
  incidentType,
  address,
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageWidth, setImageWidth] = useState(55); // Default value is 55vw

  const currentIncidentImages = timelineList.filter(
    (item) => item.gridObjectId === currentIncident
  );

  useEffect(() => {
    // Function to update image width based on screen width
    const updateImageWidth = () => {
      const vw = Math.min(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      );
      const calculatedWidth = (55 / 100) * vw; // Assuming 55vw
      setImageWidth(calculatedWidth);
    };

    // Initial update
    updateImageWidth();

    // Attach event listener for window resize
    window.addEventListener('resize', updateImageWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateImageWidth);
    };
  }, []);

  useEffect(() => {
    // Find the index of the current incident image in the images array
    const currentIndex = currentIncidentImages.findIndex(
      (image) =>
        image.isDetectionDateImage && image.gridObjectId === currentIncident
    );

    if (containerRef.current && currentIndex !== -1) {
      // Calculate the scroll position to center the current incident image
      const centerScrollPosition =
        currentIndex * imageWidth -
        containerRef.current.clientWidth / 2 +
        imageWidth / 2;

      // Scroll to the calculated position
      containerRef.current.scrollTo({
        left: centerScrollPosition,
        behavior: 'smooth',
      });
    }
  }, [currentIncident, currentIncidentImages, imageWidth]);

  if (!show) {
    return null;
  }

  const scrollTimeline = (direction: 'left' | 'right') => {
    const container = containerRef.current;

    if (container) {
      const scrollAmount = direction === 'left' ? -imageWidth : imageWidth;
      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={classes.TimeLineFullScreen}>
      <div className={classes.LogoContainer}>
        <Logo />
      </div>
      <div className={classes.CloseButtonContainer}>
        <button
          className={classes.CloseButton}
          onClick={onClose}
          title='Close Fullscreen Image View'
        >
          <MdClose />
        </button>
      </div>
      {currentIncidentImages.length > 1 ? (
        <NavigationButtons onScroll={scrollTimeline} color='orange' />
      ) : null}
      <div className={classes.ImageContainer} ref={containerRef}>
        {currentIncidentImages.map((item, index) => (
          <div key={item.imageId} className={classes.ImageWrapper}>
            <div className={classes.ImageNumber}>
              {index + 1}/{currentIncidentImages.length}
            </div>
            <Image
              imagePath={`${item.path}`}
              alt={item.type}
              loading='lazy'
              title={`${item.type} | ${new Date(
                item.imageDate
              ).toLocaleDateString('el', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}`}
            />
            <div className={classes.DetectionDateImageMessage}>
              <div className={classes.DateType}>
                {incidentType ? <h2>{incidentType}</h2> : null}
                {address ? (
                  <IncidentAddress
                    address={address}
                    removeStreetText
                    style={{ color: '#fff' }}
                  />
                ) : null}
                <p>
                  {new Date(item.imageDate).toLocaleDateString('en', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                  })}
                </p>
              </div>
              {item.isDetectionDateImage ? (
                <p className={classes.DetectedDate}>Detected Date Image</p>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FullScreenImageView;
