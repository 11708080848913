import classes from './IncidentContentContainer.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const IncidentContentContainer = ({ children, className }: IProps) => {
  return (
    <div
      className={`${classes.IncidentContentContainer} ${
        className ? className : ''
      }`}
    >
      {children}
    </div>
  );
};

export default IncidentContentContainer;
