import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import classes from './PackageTypeSection.module.scss';

interface IProps {
  packageName: string;
  onClick: () => void;
  isCloseButton: boolean;
}

const PackageTypeSection = ({
  packageName,
  onClick,
  isCloseButton,
}: IProps) => {
  return (
    <div className={classes.PackageTypeSection}>
      <h3>{packageName}</h3>
      <button onClick={onClick}>
        {isCloseButton ? <AiOutlineCloseCircle /> : <AiOutlineInfoCircle />}
      </button>
    </div>
  );
};

export default PackageTypeSection;
