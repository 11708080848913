import { ChangeEvent, forwardRef, ForwardedRef } from 'react';
import classes from './CodeInput.module.scss';

interface IProps {
  id: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const CodeInput = forwardRef(
  (
    { id, value, onChange, title, onKeyUp }: IProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className={classes.CodeInputContainer}>
        <input
          type='text'
          value={value}
          onChange={onChange}
          id={id}
          title={title}
          ref={ref}
          onKeyUp={onKeyUp}
          max={1}
        />
      </div>
    );
  }
);

export default CodeInput;
