import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AppDispatch } from '../app/store';
import { changeAuthForm, resetAtAuth } from '../features/auth/authSlice';
import { resetRegistrationForm } from '../features/registration/registrationSlice';

const useClearRegistration = (additionalUseEffectFn?: () => void) => {
  const dispatch = useDispatch<AppDispatch>();
  const [clearingForm, setClearingForm] = useState(false);

  useEffect(() => {
    if (clearingForm) {
      const timer = setTimeout(() => {
        dispatch(changeAuthForm('login'));
        setClearingForm(false);

        if (
          additionalUseEffectFn &&
          typeof additionalUseEffectFn === 'function'
        ) {
          additionalUseEffectFn();
        }
      }, 900);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line
  }, [clearingForm]);

  const clearForm = (options?: {
    additionalClearProcessHandler?: () => void;
    shouldResetState?: boolean;
  }) => {
    toast.dismiss();
    localStorage.removeItem('auth-form');
    localStorage.removeItem('selected-Country');
    localStorage.removeItem('selected-district');
    localStorage.removeItem('selected-organization');
    localStorage.removeItem('currentPageNumber');
    localStorage.removeItem('selected-organization-type');
    localStorage.removeItem('selected-service-type');
    localStorage.removeItem('selected-package');
    localStorage.removeItem('sub-services');
    localStorage.removeItem('navigationNumbers');
    localStorage.removeItem('selected-subservices');
    localStorage.removeItem('invoice-details-approved');
    localStorage.removeItem('registration-phone-country-data');
    localStorage.removeItem('registration-phone');
    localStorage.removeItem('tregt');
    localStorage.removeItem('tuid');
    localStorage.removeItem('organization-has-existing-user');
    localStorage.removeItem('periopsis-registration-name');
    localStorage.removeItem('periopsis-registration-surname');
    localStorage.removeItem('periopsis-registration-email');
    localStorage.removeItem('registration-phone');
    localStorage.removeItem('sign-up-form-date');
    localStorage.removeItem('user-address');
    localStorage.removeItem('more-details');
    localStorage.removeItem('areas-of-interest-stored');
    localStorage.removeItem('organization-email-verification-id');
    localStorage.removeItem('current-district');
    dispatch(resetAtAuth());

    if (options && options.shouldResetState) {
      dispatch(resetRegistrationForm());
      setClearingForm(true);
    }

    if (
      options &&
      options.additionalClearProcessHandler &&
      typeof options.additionalClearProcessHandler === 'function'
    ) {
      options.additionalClearProcessHandler();
    }
  };

  return { clearForm };
};

export default useClearRegistration;
