import { Fragment, useState } from 'react';
import FormModal from '../../../../UI/FormModal/FormModal';
import { IContractIncident } from '../../../../../tsTypes/interfaces';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import ReportByMail from './ReportByMail/ReportByMail';
import ReportByWhatsapp from './ReportByWhatsapp/ReportByWhatsapp';
import IncidentMailFormInformation from './ReportByMail/IncidentMailFormInformation/IncidentMailFormInformation';
import { useGetPageSettingsQuery } from '../../../../../features/Settings/settingsSlice';
import { Settings } from '../../../../../tsTypes/enums';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import FetchingReports from './FetchingReports/FetchingReports';
import { useTranslation } from 'react-i18next';

interface IProps {
  showReportForm: boolean;
  onCloseForm: () => void;
  selectedIncident: IContractIncident;
}

const IncidentReportForm = ({
  showReportForm,
  onCloseForm,
  selectedIncident,
}: IProps) => {
  const [showFormInfo, setShowFormInfo] = useState(false);
  const [reportType, setReportType] = useState<'Mail' | 'Whatsapp'>('Mail');
  let content: React.ReactNode | null = null;
  const { t } = useTranslation();

  let reportByMail = false;
  let reportByWhatsapp = false;
  const {
    data: settings,
    isError: isSettingsError,
    isLoading: isLoadingSettings,
  } = useGetPageSettingsQuery(Settings.IncidentDashboardReportForm, {
    skip: !showReportForm,
  });

  const showReportFormInformationHandler = () => {
    setShowFormInfo((prevState) => !prevState);
  };

  const selectReportType = (selectedReportType: 'Mail' | 'Whatsapp') => {
    setReportType(selectedReportType);
  };

  if (
    settings &&
    typeof settings === 'object' &&
    'reportByMail' in settings &&
    'reportByWhatsapp' in settings
  ) {
    reportByMail = settings.reportByMail;
    reportByWhatsapp = settings.reportByWhatsapp;
  }

  if (isLoadingSettings) {
    content = <FetchingReports message={t('forms:fetching_reports')} />;
  }

  if (isSettingsError) {
    content = <NoDataFound message={t('forms:reports_fetch_error')} />;
  }

  if (
    showFormInfo &&
    reportType === 'Mail' &&
    !isSettingsError &&
    !isLoadingSettings &&
    reportByMail
  ) {
    content = (
      <IncidentMailFormInformation onClose={showReportFormInformationHandler} />
    );
  } else if (
    showFormInfo &&
    reportType === 'Whatsapp' &&
    !isSettingsError &&
    !isLoadingSettings &&
    reportByWhatsapp
  ) {
    content = <div>Whatsapp Form Information</div>;
  } else if (!isSettingsError && !isLoadingSettings) {
    content = (
      <Fragment>
        {showReportForm && reportByMail ? (
          <ReportByMail
            isSelected={reportType === 'Mail'}
            selectedIncident={selectedIncident}
            reportType={reportType}
            closeForm={onCloseForm}
          />
        ) : null}
        {showReportForm && reportByWhatsapp ? (
          <ReportByWhatsapp
            isSelected={reportType === 'Whatsapp'}
            reportType={reportType}
            selectedIncident={selectedIncident}
            closeForm={onCloseForm}
          />
        ) : null}
      </Fragment>
    );
  }

  return (
    <FormModal
      show={showReportForm}
      onClick={onCloseForm}
      heading={
        !showFormInfo
          ? t('forms:report_incident')
          : reportType === 'Mail'
          ? t('forms:About_Email_Report_Form')
          : t('forms:About_Whatsapp_Report_Form')
      }
      onShowInformation={showReportFormInformationHandler}
      showInfo={showFormInfo}
    >
      <div>
        {reportByMail && reportByWhatsapp ? (
          <StandardButton
            isTabButton
            label='Report By Mail'
            onClick={() => selectReportType('Mail')}
            btnType={reportType === 'Mail' ? 'orange' : 'primary'}
            type='button'
          />
        ) : null}
        {reportByWhatsapp && reportByMail ? (
          <StandardButton
            isTabButton
            label='Report By Whatsapp'
            onClick={() => selectReportType('Whatsapp')}
            btnType={reportType === 'Whatsapp' ? 'orange' : 'primary'}
            type='button'
          />
        ) : null}
      </div>
      {content}
    </FormModal>
  );
};

export default IncidentReportForm;
