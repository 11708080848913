import { Page, Text, View, Document } from '@react-pdf/renderer';
import { PackagePricingPageStyle } from './PackagePricing.style';
import { IPackage, IPackageSubService } from '../../../tsTypes/types.model';
import Li from '../UI/ListElement/Li';
import UL from '../UI/ListContainer/UL';
import SideHeading from '../UI/SideHeading/SideHeading';
import Paragraph from '../UI/Paragraph/Paragraph';
import SubserviceItem from '../UI/SubserviceItem/SubserviceItem';
import LeftContent from '../LeftContent/LeftContent';
import divideArrayIntoChunks from '../../../utils/divideArrayIntoChunks';
import TermsItem from '../UI/TermsItem/TermsItem';
import PageLayout from '../UI/PageLayout/PageLayout';
import { styleConstants } from '../UI/style.constants';

interface IProps {
  packageData: Omit<IPackage, 'isActivePackage' | 'serviceTypeId'>;
}
const PackagePricing = ({ packageData }: IProps) => {
  let chunksArray: IPackageSubService[][] = [];
  let chunksRemaining: IPackageSubService[][] = [];

  if (!packageData) {
    return (
      <Document>
        <PageLayout name='No Package'>
          <View>
            <Text>No Package Found</Text>
          </View>
        </PageLayout>
      </Document>
    );
  }

  const hasSubservices = packageData.packageDetails.subservices.length > 0;

  if (hasSubservices && packageData.packageDetails.subservices.length > 4) {
    chunksArray = divideArrayIntoChunks<IPackageSubService>(
      packageData.packageDetails.subservices
    );
  } else {
    chunksArray = [packageData.packageDetails.subservices];
  }

  const hasMultipleChunks = chunksArray.length > 1;
  const hasTermsAndConditions =
    packageData.packageDetails.termsAndConditions.length > 0;

  if (hasMultipleChunks) {
    chunksRemaining = [...chunksArray].slice(1);
  }

  return (
    <Document
      title='Package Pricing'
      author='Periopsis Ltd'
      subject='Periopsis Service Packages'
      creator='Periopsis Ltd'
    >
      <PageLayout
        name={packageData.packageName}
        currency={packageData.currency}
        monthlyPrice={packageData.monthlyPrice}
        duration={packageData.duration}
        durationUnit={packageData.durationUnit}
        heading={packageData.packageDetails.heading}
        priceUnit={packageData.priceUnit}
      >
        <View>
          <SideHeading heading='About Package' />
          <Paragraph
            paragraph={packageData.packageDetails.description}
            width={styleConstants.rightFullWidth}
          />
        </View>
        <View>
          <SideHeading
            heading='Package Features'
            marginTop={styleConstants.sideHeadingTopMargin}
          />
          <UL>
            {packageData.packageDetails.services.map((service) => (
              <Li item={service} key={service} bullet />
            ))}
          </UL>
        </View>
        {hasSubservices ? (
          <View>
            <SideHeading
              heading='Subservices'
              marginTop={styleConstants.sideHeadingTopMargin}
            />
            <UL>
              {chunksArray[0].map((subservice) => (
                <SubserviceItem
                  subservice={subservice}
                  key={subservice.subserviceId}
                />
              ))}
            </UL>
          </View>
        ) : null}
      </PageLayout>
      {hasMultipleChunks
        ? chunksRemaining.map((chunk, index) => (
            <Page
              size='A4'
              style={PackagePricingPageStyle.page}
              wrap={false}
              key={index}
            >
              <View style={PackagePricingPageStyle.main}>
                <LeftContent
                  name={packageData.packageName}
                  currency={packageData.currency}
                  monthlyPrice={packageData.monthlyPrice}
                  duration={packageData.duration}
                  durationUnit={packageData.durationUnit}
                  heading={packageData.packageDetails.heading}
                  priceUnit={packageData.priceUnit}
                />
                <View style={PackagePricingPageStyle.Right}>
                  <View>
                    <SideHeading
                      heading='Subservices'
                      marginTop={styleConstants.sideHeadingTopMargin}
                    />
                    <UL>
                      {chunk.map((subservice) => (
                        <SubserviceItem
                          subservice={subservice}
                          key={subservice.subserviceId}
                        />
                      ))}
                    </UL>
                  </View>
                </View>
              </View>
            </Page>
          ))
        : null}
      {hasTermsAndConditions ? (
        <PageLayout
          name={packageData.packageName}
          currency={packageData.currency}
          monthlyPrice={packageData.monthlyPrice}
          duration={packageData.duration}
          durationUnit={packageData.durationUnit}
          heading={packageData.packageDetails.heading}
          priceUnit={packageData.priceUnit}
        >
          <SideHeading heading='Terms and Conditions' />
          <UL>
            {packageData.packageDetails.termsAndConditions.map((terms) => (
              <TermsItem
                key={terms.id}
                heading={terms.heading}
                description={terms.description}
              />
            ))}
          </UL>
        </PageLayout>
      ) : null}
    </Document>
  );
};

export default PackagePricing;
