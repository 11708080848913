import { useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { toast } from 'react-toastify';
import { GiWorld } from 'react-icons/gi';
import { IRegistrationSelectionProps } from '../../../../../../tsTypes/types.components';

import SelectionContainer from '../SelectionContainer/SelectionContainer';

import {
  nextPage,
  selectAllRegistrationFormValues,
  setSelectedCountry,
  setProvinceExistance,
  useGetCountriesQuery,
  approveInvoiceDetails,
} from '../../../../../../features/registration/registrationSlice';
import SectionExplanation from '../SectionExplanation/SectionExplanation';

import { AppDispatch } from '../../../../../../app/store';

import SelectionForm from '../SelectionForm/SelectionForm';
import { ICountry } from '../../../../../../tsTypes/types.components';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import Dropdown from '../../../../../UI/FormElements/Dropdown/Dropdown';
import { IListObject } from '../../../../../../tsTypes/types.model';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import { catchError } from '../../../../../../utils/fetches';
import useDropDownSelection from '../../../../../../CustomHooks/useDropDownSelection';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../utils/getTranslationKey';

const CountrySelection = ({ pageNumber }: IRegistrationSelectionProps) => {
  const [showCheckMark, setShowCheckMark] = useState(false);
  let countriesToUse: IListObject[] = [];
  const { currentPageNumber } = useSelector(selectAllRegistrationFormValues);
  const localStorageName = 'selected-Country';
  const { t } = useTranslation();

  const {
    isError,
    isLoading,
    error,
    data: countries,
  } = useGetCountriesQuery('Active', { skip: currentPageNumber !== 2 });

  const {
    selected: selectedCountry,
    setSelectedLocally: setSelectedCountryLocally,
  } = useDropDownSelection({
    localStorageName,
  });

  const dispatch = useDispatch<AppDispatch>();

  if (countries) {
    countriesToUse = countries
      .filter((country) => country.isInService)
      .map((c) => ({
        title: t(`auth:${getTranslationKey(c.name)}`),
        value: c._id,
        id: c._id,
      }));
  }

  const countrySelectHandler = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selected = e.target.value;

    if (selected && !Array.isArray(selected) && countries) {
      const selectedCountryResult = countries.find(
        (cnt: ICountry) => cnt._id === selected
      );

      if (!selectedCountryResult) {
        return;
      }

      setSelectedCountryLocally(selectedCountryResult._id);

      localStorage.setItem(
        localStorageName,
        JSON.stringify(selectedCountryResult)
      );

      toast.success(
        <ToastifyStyledMessage
          singleMessage={t('auth:select_success_message')}
          heading={t('auth:next_page')}
        />,
        { autoClose: 1000 }
      );

      setShowCheckMark(true);
      localStorage.removeItem('invoice-details-approved');

      batch(() => {
        dispatch(setSelectedCountry(selectedCountryResult));
        dispatch(setProvinceExistance(selectedCountryResult.hasProvices));
        dispatch(approveInvoiceDetails(false));
        const timer = setTimeout(() => {
          dispatch(nextPage());
          toast.dismiss();
          clearTimeout(timer);
        }, 1600);
      });
    }
  };

  return (
    <SelectionContainer
      isInQueue={pageNumber ? pageNumber < currentPageNumber : false}
      isCompleted={pageNumber ? pageNumber > currentPageNumber : false}
    >
      <SectionExplanation
        heading={t('auth:Select_Target_Country')}
        description={t('auth:Select_Target_Country_Desc')}
        icon={<GiWorld size={25} />}
      />
      <PresentationWrapper
        isLoading={isLoading}
        isError={isError}
        error={catchError(error)}
        message='An Error Occured While Loading the Countries. Please try again later.'
      >
        <SelectionForm showCheckMark={showCheckMark}>
          <Dropdown
            name='country'
            defaultValue={t('auth:Select_Country')}
            optionsList={countriesToUse}
            value={selectedCountry}
            onSelect={countrySelectHandler}
          />
        </SelectionForm>
      </PresentationWrapper>
    </SelectionContainer>
  );
};

export default CountrySelection;
