import classes from './FormSectionContainer.module.scss';

interface IProps {
  heading?: string;
  description?: string;
  children: React.ReactNode;
}

const FormSectionContainer = ({ heading, description, children }: IProps) => {
  return (
    <div className={classes.FormSectionContainer}>
      {heading ? <h3>{heading}</h3> : null}
      {description ? (
        <p className={classes.AddressTypeSelectionNote}>{description}</p>
      ) : null}
      {children}
    </div>
  );
};

export default FormSectionContainer;
