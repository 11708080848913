import { BsFileMinusFill, BsFilePlusFill } from 'react-icons/bs';
import classes from './ListContainer.module.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  children: React.ReactNode | JSX.Element[] | JSX.Element;
  heading?: string;
  className?: string;
  onExpand?: () => void;
  isExpanded?: boolean;
  expandMessage?: string;
}

const ListContainer = ({
  children,
  heading,
  className,
  onExpand,
  isExpanded,
  expandMessage,
}: IProps) => {
  const shouldShowExpandButtons =
    onExpand !== undefined && typeof onExpand === 'function';
  const { t } = useTranslation();

  return (
    <div className={`${classes.List} ${className ? className : ''}`}>
      {heading ? (
        <div className={classes.HeadingSection}>
          <h3>{heading}</h3>
          {shouldShowExpandButtons ? (
            <Fragment>
              {!isExpanded ? (
                <span onClick={onExpand}>
                  <BsFilePlusFill title={t('checkout:click_to_expand_title')} />
                  ({t('checkout:click_to_expand')})
                </span>
              ) : (
                <span onClick={onExpand}>
                  <BsFileMinusFill title={t('checkout:click_to_close_title')} />
                  ({t('checkout:click_to_close')})
                </span>
              )}
            </Fragment>
          ) : null}
        </div>
      ) : null}
      {children}
      {!isExpanded ? (
        <div className={`${classes.List} ${classes.RemoveSpace}`}>
          <p>{expandMessage}</p>
        </div>
      ) : null}
    </div>
  );
};

export default ListContainer;
