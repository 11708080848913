import { useTranslation } from 'react-i18next';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../UI/FormElements/Button/Button';
import Form from '../../../../UI/FormElements/Form/Form';
import Input from '../../../../UI/FormElements/Input/Input';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import classes from './LoginForm.module.scss';

interface ILoginFormProps {
  onSubmit: (e: React.FormEvent) => void;
  credentials: {
    username: string;
    password: string;
  };
  onUserNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
}

const LoginForm = ({
  onSubmit,
  credentials,
  onUserNameChange,
  onPasswordChange,
  isLoading,
}: ILoginFormProps) => {
  const { t } = useTranslation();

  return (
    <Form className={classes.LoginForm} onSubmit={onSubmit}>
      <Input
        type='text'
        name='name'
        label={t('auth:username')}
        value={credentials.username}
        onChange={onUserNameChange}
        isRequired
        disabled={isLoading}
        autoComplete='email'
      />
      <Input
        type='password'
        name='password'
        label={t('auth:password')}
        value={credentials.password}
        onChange={onPasswordChange}
        isRequired
        hasShowPasswordButton
        disabled={isLoading}
        autoComplete='password'
      />

      <ActionContainer className={classes.LoginActionButton}>
        {!isLoading ? (
          <Button
            btnClass={classes.LoginButton}
            type='submit'
            isFake={
              !credentials.username ||
              credentials.password.length < 6 ||
              isLoading
            }
          >
            {t('auth:login')}
          </Button>
        ) : (
          <SimpleLoading />
        )}
      </ActionContainer>
    </Form>
  );
};

export default LoginForm;
