import { ChangeEvent, Fragment } from 'react';
import Input from '../../../../UI/FormElements/Input/Input';
import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import { IoPerson } from 'react-icons/io5';
import ActionContainer from '../../../../UI/FormElements/ActionContainer/ActionContainer';
import StandardButton from '../../../../UI/FormElements/StandardButton/StandardButton';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';
import VisitorsListItem from '../IncidentVisitForm/VisitorsListItem/VisitorsListItem';
import { IVisitor } from '../../../../../tsTypes/interfaces';
import BorderedSectionContainer from '../BorderedSectionContainer/BorderedSectionContainer';
import classes from './AddVisitorsForm.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  isLoading?: boolean;
  value: {
    name: string;
    surname: string;
    department: string;
    duty: string;
  };
  handler: {
    nameEntryHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    surnameEntryHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    departmentEntryHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    dutyEntryHandler: (e: ChangeEvent<HTMLInputElement>) => void;
    resetVisitorForm: () => void;
    addVisitorHandler: () => void;
    removeVisitorHandler: (id: string) => void;
  };
  visitors: IVisitor[];
  validators: {
    isValidName: boolean;
    isValidSurname: boolean;
    isValidDepartment: boolean;
    isValidDuty: boolean;
    isFakeAddButton: boolean;
  };
  heading: string;
  listHeading: string;
  information?: string;
  onAction?: () => void;
  isActionStart?: boolean;
  hasActionAuth?: boolean;
  actionStartLabel?: string;
}

const AddVisitorsForm = ({
  isLoading,
  value: { name, surname, department, duty },
  handler: {
    nameEntryHandler,
    surnameEntryHandler,
    departmentEntryHandler,
    dutyEntryHandler,
    resetVisitorForm,
    addVisitorHandler,
    removeVisitorHandler,
  },
  validators: {
    isValidName,
    isValidSurname,
    isValidDepartment,
    isValidDuty,
    isFakeAddButton,
  },
  visitors,
  heading,
  listHeading,
  information,
  onAction,
  isActionStart,
  hasActionAuth,
  actionStartLabel,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <BorderedSectionContainer
      heading={heading}
      information={information}
      onAction={onAction}
      isActionStart={isActionStart}
      hasActionAuth={hasActionAuth}
      actionStartLabel={actionStartLabel}
    >
      <div className={classes.VisitorDetailsEntryField}>
        {!isLoading ? (
          <Fragment>
            <div className={classes.InputFields}>
              <Input
                type='text'
                value={name}
                name='name'
                label={t('translations:Name')}
                isRequired
                onChange={nameEntryHandler}
                isInvalid={!isValidName}
                title={t('translations:enter_name')}
                autoComplete='given-name'
              />
              <Input
                type='text'
                value={surname}
                name='surname'
                label={t('translations:Surname')}
                isRequired
                onChange={surnameEntryHandler}
                isInvalid={!isValidSurname}
                title={t('translations:enter_surname')}
                autoComplete='family-name'
              />
            </div>
            <div className={classes.InputFields}>
              <Input
                type='text'
                value={department}
                name='department'
                label={t('translations:Department')}
                isRequired
                onChange={departmentEntryHandler}
                isInvalid={!isValidDepartment}
                title={t('translations:enter_department')}
                autoComplete='organization'
              />
              <Input
                type='text'
                value={duty}
                name='duty'
                label={t('translations:Duty')}
                isRequired
                onChange={dutyEntryHandler}
                isInvalid={!isValidDuty}
                title={t('translations:enter_duty')}
                autoComplete='organization-title'
              />
            </div>
            <ActionContainer className={classes.VisitorsFormActions}>
              <StandardButton
                type='button'
                btnType='orange'
                label={t('translations:Reset')}
                onClick={resetVisitorForm}
                title={t('translations:reset_employee_form')}
              />
              <StandardButton
                type='button'
                btnType='primary'
                label={t('translations:Add')}
                onClick={addVisitorHandler}
                fakeButton={isFakeAddButton}
                title={t('translations:add_employee')}
              />
            </ActionContainer>
          </Fragment>
        ) : (
          <div className={classes.LoadingContainer}>
            <SimpleLoading />
          </div>
        )}
        {visitors.length > 0 ? (
          <div className={classes.VisitorsList}>
            <HeadingBar
              heading={listHeading}
              headingSize={4}
              icon={<IoPerson />}
              className={classes.VisitorsHeading}
              headingType='orange'
            />
            <ul>
              {visitors.map((visitor, index) => (
                <VisitorsListItem
                  key={visitor.id}
                  visitorDetails={visitor}
                  orderNumber={index + 1}
                  onRemove={removeVisitorHandler}
                  isAuthorized
                />
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </BorderedSectionContainer>
  );
};

export default AddVisitorsForm;
