import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllRegistrationFormValues,
  setAreasOfInterestStored,
  setSelectedOrganization,
  useGetPolygonsQuery,
} from '../../../../../../features/registration/registrationSlice';
import { IPredefinedPolygon } from '../../../../../../tsTypes/types.model';
import { AppDispatch } from '../../../../../../app/store';
import { IRegistrationSelectionProps } from '../../../../../../tsTypes/types.components';
import { catchError } from '../../../../../../utils/fetches';
import SelectionContainer from '../SelectionContainer/SelectionContainer';
import SectionExplanation from '../SectionExplanation/SectionExplanation';
import { FaBuilding, FaMapMarkerAlt } from 'react-icons/fa';
import NewOrganizationForm from './NewOrganizationForm/NewOrganizationForm';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import SelectedOrganization from './NewOrganizationForm/SelectedOrganization/SelectedOrganization';
import { selectAllUIValues } from '../../../../../../features/UI/uiSlice';
import { TbPolygon } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

const OrganizationSelection = ({ pageNumber }: IRegistrationSelectionProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    currentPageNumber,
    selectedOrganizationType,
    selectedCountry,
    selectedDistrict,
  } = useSelector(selectAllRegistrationFormValues);
  const { isPolygonListPresentationAtAreaOfInterest } =
    useSelector(selectAllUIValues);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');

  let district: string = selectedDistrict ? selectedDistrict.name : '';
  let organizationType = selectedOrganizationType
    ? selectedOrganizationType.title
    : '';
  let country = selectedCountry ? selectedCountry.name : '';
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    error,
    data: organizationResponseData,
  } = useGetPolygonsQuery(
    { country, district, organizationType },
    {
      skip:
        currentPageNumber !== 6 ||
        country === '' ||
        district === '' ||
        organizationType === '',
    }
  );

  let polygons: IPredefinedPolygon[] = [];

  useEffect(() => {
    const storedSelectedOrganization = localStorage.getItem(
      'selected-organization'
    );

    const storedAreasOfInterest = localStorage.getItem(
      'areas-of-interest-stored'
    );

    if (storedSelectedOrganization) {
      const parsedData = JSON.parse(storedSelectedOrganization);

      const isIndividual = parsedData.organizationType === 'Individual';

      dispatch(setSelectedOrganization(parsedData));

      if (!isIndividual) {
        setSelectedOrganizationId(parsedData.id);
      }
    }

    if (storedAreasOfInterest) {
      const parsedData = JSON.parse(storedAreasOfInterest);

      dispatch(setAreasOfInterestStored(parsedData));
    }
    // eslint-disable-next-line
  }, []);

  if (
    organizationResponseData &&
    'polygons' in organizationResponseData &&
    Array.isArray(organizationResponseData.polygons)
  ) {
    polygons = organizationResponseData.polygons;
  }

  const isIndividual =
    selectedOrganizationType &&
    'title' in selectedOrganizationType &&
    selectedOrganizationType.title === 'Individual';

  return (
    <SelectionContainer
      isInQueue={pageNumber ? pageNumber < currentPageNumber : false}
      isCompleted={pageNumber ? pageNumber > currentPageNumber : false}
      isScrollable
    >
      <SectionExplanation
        heading={`${
          isIndividual
            ? t('auth:select_area_of_interest')
            : selectedOrganizationId
            ? t('auth:Organization_Details')
            : !isPolygonListPresentationAtAreaOfInterest
            ? t('auth:add_your_organization')
            : t('auth:all_selected_polygons')
        }`}
        description={
          isIndividual
            ? t('auth:which_areas')
            : selectedOrganizationId
            ? ''
            : !isPolygonListPresentationAtAreaOfInterest
            ? t('auth:organization_information')
            : ''
        }
        icon={
          isIndividual ? (
            <FaMapMarkerAlt size={25} />
          ) : isPolygonListPresentationAtAreaOfInterest ? (
            <TbPolygon size={25} />
          ) : (
            <FaBuilding size={25} />
          )
        }
      />
      <PresentationWrapper
        isLoading={isLoading}
        isError={isError}
        error={catchError(error)}
        message={t('auth:loading_polygons_error')}
      >
        {!selectedOrganizationId ? (
          <NewOrganizationForm
            predefinedPolygons={polygons}
            shouldSkip={false}
            setOrganizationId={(organizationId) =>
              setSelectedOrganizationId(organizationId)
            }
          />
        ) : (
          <SelectedOrganization
            selectedOrganizationId={selectedOrganizationId}
          />
        )}
      </PresentationWrapper>
    </SelectionContainer>
  );
};

export default OrganizationSelection;
