import React from 'react';
import classes from './ActionContainer.module.scss';

interface IProps {
  children: React.ReactNode;
  setup?: {
    flexDirection?: 'row' | 'column';
    alignItems?: 'flex-end' | 'flex-start' | 'stretch' | 'center';
    justifyContent?:
      | 'space-evenly'
      | 'space-around'
      | 'space-between'
      | 'center'
      | 'flex-end'
      | 'flex-start';
  };
  style?: React.CSSProperties;
  className?: string;
}

const ActionContainer = ({ children, setup, style, className }: IProps) => {
  let containerStyle;

  if (setup) {
    containerStyle = {
      flexDirection: setup.flexDirection ? setup.flexDirection : 'row',
      alignItems: setup.alignItems ? setup.alignItems : 'flex-start',
      justifyContent: setup.justifyContent
        ? setup.justifyContent
        : 'flex-start',
    };
  }

  return (
    <div
      className={`${classes.ActionContainer} ${className ? className : ''}`}
      style={{ ...containerStyle, ...style }}
    >
      {children}
    </div>
  );
};

export default ActionContainer;
