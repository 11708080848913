import classes from './RadioButtonDot.module.scss';

interface IProps {
  isSelected: boolean;
}

const RadioButtonDot = ({ isSelected }: IProps) => {
  return (
    <div
      className={`${classes.RadioButton} ${isSelected ? classes.Selected : ''}`}
    >
      <div className={classes.Dot}></div>
    </div>
  );
};

export default RadioButtonDot;
