import { useState } from 'react';
import StandardButton from '../../UI/FormElements/StandardButton/StandardButton';
import ActionStatistics from './ActionStatistics/ActionStatistics';
import DensityHeatmap from './DensityHeatmap/DensityHeatmap';
import IncidentStatistics from './IncidentStatistics/IncidentStatistics';
import useStatisticsYear from '../../../CustomHooks/useStatisticsYear';
import useCurrentContract from '../../../CustomHooks/useCurrentContract';
import { useGetContractsQuery } from '../../../features/incidents/incidentsSlice';
import FetchingReports from '../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../UI/NoDataFound/NoDataFound';
import { Navigate } from 'react-router-dom';
import { Settings } from '../../../tsTypes/enums';
import { useGetPageSettingsQuery } from '../../../features/Settings/settingsSlice';
import classes from './Statistics.module.scss';
import getUserContractsResult from '../../../utils/ContractUsersUtils/getUserContractsResult';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../utils/getTranslationKey';

enum StatisticPage {
  Actions = 'Actions',
  Incidents = 'Incidents',
  'Monthly' = 'Monthly',
  'Annual' = 'Annual Statistics',
  Heatmap = 'Heatmap',
}

export interface IActionStatProps {
  contractId: string;
  currentYear: number;
  isSelected: boolean;
  isYearsReady: boolean;
  mainHeadings?: { visited: string; reported: string; cleaned: string };
}

export interface IStatisticsProps {
  isSelected: boolean;
  contractId: string;
  currentYear: number;
  selectYear: (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
}

const Statistics = () => {
  const [statisticsPage, setStatisticsPage] = useState<StatisticPage>(
    StatisticPage.Actions
  );
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    data: userContractsData,
  } = useGetContractsQuery('contracts');

  const { data: settings, isLoading: isLoadingSettings } =
    useGetPageSettingsQuery(Settings.sidedrawer, {
      skip: isLoading,
    });

  const { hasContracts } = getUserContractsResult(userContractsData);

  let hasStatisticsLink = true;

  const contractId = useCurrentContract();
  const { currentYear, selectCurrentYear } = useStatisticsYear();

  if (isLoading || isLoadingSettings) {
    return (
      <FetchingReports message={t('statistics:initializing_statistics_data')} />
    );
  }

  if (isError) {
    return (
      <NoDataFound message={t('statistics:statistics_data_fetch_error')} />
    );
  }

  if (settings && !isLoading && !isLoadingSettings) {
    hasStatisticsLink = settings.hasStatisticsLink;
  }

  if (!hasContracts || !hasStatisticsLink) {
    return <Navigate to='/' />;
  }

  const selectStatisticsPageHandler = (selected: StatisticPage) => {
    setStatisticsPage(selected);
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.Actions}>
        <StandardButton
          type='button'
          btnType={
            statisticsPage === StatisticPage.Actions
              ? 'primary'
              : 'primary-light'
          }
          label={t(`statistics:${getTranslationKey(StatisticPage.Actions)}`)}
          isTabButton
          onClick={() => selectStatisticsPageHandler(StatisticPage.Actions)}
        />
        <StandardButton
          type='button'
          btnType={
            statisticsPage === StatisticPage.Incidents
              ? 'primary'
              : 'primary-light'
          }
          label={t(`statistics:${getTranslationKey(StatisticPage.Incidents)}`)}
          isTabButton
          onClick={() => selectStatisticsPageHandler(StatisticPage.Incidents)}
        />
        <StandardButton
          type='button'
          btnType={
            statisticsPage === StatisticPage.Heatmap
              ? 'primary'
              : 'primary-light'
          }
          label={t(`statistics:${getTranslationKey(StatisticPage.Heatmap)}`)}
          isTabButton
          onClick={() => selectStatisticsPageHandler(StatisticPage.Heatmap)}
        />
      </div>
      <ActionStatistics
        isSelected={statisticsPage === StatisticPage.Actions}
        contractId={contractId}
        currentYear={currentYear}
        selectYear={selectCurrentYear}
      />
      <IncidentStatistics
        isSelected={statisticsPage === StatisticPage.Incidents}
        contractId={contractId}
        currentYear={currentYear}
        selectYear={selectCurrentYear}
      />
      <DensityHeatmap
        isSelected={statisticsPage === StatisticPage.Heatmap}
        contractId={contractId}
        currentYear={currentYear}
        selectYear={selectCurrentYear}
      />
    </div>
  );
};

export default Statistics;
