import classes from './AuthCodeFormDescriptionSection.module.scss';

interface IProps {
  image: string;
  heading: string;
  description: string;
}

const AuthCodeFormDescriptionSection = ({
  image,
  heading,
  description,
}: IProps) => {
  return (
    <div className={classes.HeadingContainer}>
      <div className={classes.ImageContainer}>
        <img
          src={image}
          alt='an envelope with hidden code'
          loading='lazy'
          width={120}
          height={110}
        />
      </div>
      <div className={classes.TextSection}>
        <h2>{heading}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default AuthCodeFormDescriptionSection;
