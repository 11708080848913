import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../../UI/style.constants';

export const ProformaSectionStyle = StyleSheet.create({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `1pt solid ${colorConstants.periopsisMainColor}`,
    width: '142mm',
    padding: '8pt 4pt',
    position: 'relative',
    backgroundColor: '#f9f9f9',
    borderRadius: '10pt',
  },

  sectionHeading: {
    margin: '4pt 0',
  },

  heading: {
    padding: '3pt 8pt',
    fontSize: '4mm',
    fontWeight: 'extrabold',
    backgroundColor: colorConstants.periopsisMainColor,
    color: '#fff',
    borderRadius: '5pt',
    maxWidth: '40%',
  },

  contentContainer: {
    padding: '7pt 0',
  },
});
