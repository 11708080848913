const getMapHeight = (viewportWidth: number) => {
  if (viewportWidth >= 1920) {
    return 520;
  }

  if (viewportWidth >= 1366 && viewportWidth <= 1536) {
    return 420;
  }

  if (viewportWidth >= 1200 && viewportWidth < 1366) {
    return 400;
  }

  return 400;
};

export default getMapHeight;
