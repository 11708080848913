import classes from './AdminPageContainer.module.scss';

interface IProps {
  children: React.ReactNode;
}

const AdminPageContainer = ({ children }: IProps) => {
  return <div className={classes.AdminPageContainer}>{children}</div>;
};

export default AdminPageContainer;
