/**
 * Adds "https://" to the beginning of a website address if it is missing and a valid website address is provided.
 *
 * @param {string} website - The website address to modify.
 * @param {boolean} isValidWebsiteAddress - A boolean flag indicating whether the provided website address is valid.
 *
 * @returns {string} - The modified website address with "https://" added if missing, or the original address if it's already present
 *   or if the provided address is not considered valid.
 */
const addHttpsIfMissing = (website: string, isValidWebsiteAddress: boolean) => {
  // Check if the website already starts with "https://"
  if (!website.startsWith('https://') && isValidWebsiteAddress) {
    // If not, add it to the beginning
    website = 'https://' + website;
  }
  return website;
};

export default addHttpsIfMissing;
