import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { IOrganization } from '../../tsTypes/types.components';
import { periopsisApi } from '../api/apiSlice';
import getHeaders from '../../utils/getHeaders';
import { IResponseData } from '../../tsTypes/types.model';

export interface IOrganizationState {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string;
  httpCode: number | null;
  userOrganizations: IOrganization[] | null;
  currentOrganization: IOrganization | null;
  currentOrganizationId: string;
}

const initialState: IOrganizationState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
  httpCode: null,
  userOrganizations: null,
  currentOrganization: null,
  currentOrganizationId: '',
};

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationCoordinates: builder.query({
      query: (contractId: string) => {
        const headers = getHeaders();

        return {
          url: `/organization/coordinates/${contractId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          organization: {
            latitude: number;
            longitude: number;
            organization: string;
          };
        }>
      ) => {
        const organization = responseData.data.organization;

        return organization;
      },
      providesTags: ['organization-coordinates'],
    }),
    addOrganizationCoordinates: builder.mutation({
      query: (requestData: {
        contractId: string;
        latitude: number;
        longitude: number;
      }) => {
        const { contractId, latitude, longitude } = requestData;
        const headers = getHeaders();

        return {
          url: `/organization/coordinates/${contractId}`,
          method: 'PATCH',
          headers,
          body: { latitude, longitude },
        };
      },
      invalidatesTags: ['organization-coordinates'],
    }),
  }),
});

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setCurrentOrganization: (
      state,
      action: PayloadAction<{ id: string; organization: IOrganization }>
    ) => {
      state.currentOrganizationId = action.payload.id;
      state.currentOrganization = action.payload.organization;
    },

    setUserOrganizations: (state, action: PayloadAction<IOrganization[]>) => {
      state.userOrganizations = action.payload;
    },

    clearOrganizationState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
      state.httpCode = null;
      state.userOrganizations = null;
      state.currentOrganization = null;
      state.currentOrganizationId = '';
    },
  },
});

export const {
  useGetOrganizationCoordinatesQuery,
  useAddOrganizationCoordinatesMutation,
} = extendedApiSlice;

export const selectAllOrganizationValues = (state: RootState) =>
  state.organization;

export const {
  setCurrentOrganization,
  setUserOrganizations,
  clearOrganizationState,
} = organizationSlice.actions;

export default organizationSlice.reducer;
