const convertCurrencySignToWord = (
  currencySign: string,
  isSingular: boolean
) => {
  const currencySigns: { [key: string]: string } = {
    '€': isSingular ? 'Euro' : 'Euros',
    $: isSingular ? 'US Dollar' : 'US Dollars',
    '£': isSingular ? 'UK Pound' : 'UK Pounds',
  };

  const result = currencySigns[currencySign];

  if (!result) {
    const defaultResult = isSingular ? 'Euro' : 'Euros';
    return defaultResult;
  }

  return result;
};

export default convertCurrencySignToWord;
