import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  numberOfContractUsers: number;
}

const ContractUsersMessage = ({ numberOfContractUsers }: IProps) => {
  const { t } = useTranslation();

  if (numberOfContractUsers > 1) {
    return (
      <div>
        <p>{t('admin:contract_users_message_more_than_one_1')}</p>
        <p>{t('admin:contract_users_message_more_than_one_2')}</p>
        <p>{t('admin:contract_users_message_more_than_one_3')}</p>
      </div>
    );
  }

  return (
    <div>
      <p>{t('admin:contract_users_message_equal_one_1')}</p>
      <p>{t('admin:contract_users_message_equal_one_2')}</p>
    </div>
  );
};

export default ContractUsersMessage;
