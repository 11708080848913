import { Fragment } from 'react';

interface IProps {
  children: React.ReactNode;
  isSelected: boolean;
}

const SelectionContainer = ({ isSelected, children }: IProps) => {
  if (!isSelected) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default SelectionContainer;
