import ActionContainer from '../FormElements/ActionContainer/ActionContainer';
import StandardButton from '../FormElements/StandardButton/StandardButton';
import cogwheels from '../../../assets/images/HomePageImages/cogwheels.png';
import brokenchain from '../../../assets/images/HomePageImages/brokenchain.png';
import classes from './NoContract.module.scss';

interface IProps {
  hasTerminated: boolean;
}

const NoContract = ({ hasTerminated }: IProps) => {
  const image = hasTerminated ? brokenchain : cogwheels;

  return (
    <div className={classes.ServiceSelectSection}>
      <img src={image} alt='three cogwheels' width={75} height={75} />
      {!hasTerminated ? (
        <p>
          Welcome aboard! It looks like you're new here. To get started, simply
          click the button below to choose a service and kick off the contract
          initiation process. We're here to help make things easy for you!
        </p>
      ) : (
        <p>
          Welcome! If you've had a contract with us that has since terminated,
          thank you for your past business! If you're interested in starting a
          new contract or have any questions, feel free to reach out to us.
          We're always here to assist you.
        </p>
      )}
      <ActionContainer className={classes.Actions}>
        <StandardButton
          btnType='primary'
          label={hasTerminated ? 'Start Again' : 'Start'}
          href='/service-registration'
          className={classes.StartButton}
        />
      </ActionContainer>
    </div>
  );
};

export default NoContract;
