const ErrorFallback = () => {
  return (
    <div role='alert'>
      <h1>Something went wrong</h1>
      <pre>Unexpected error occured</pre>
      <button>Try again</button>
    </div>
  );
};

export default ErrorFallback;
