import Hamburger from '../Hamburger/Hamburger';
import Logo from '../Logo/Logo';
import UserBadge from '../UserBadge/UserBadge';
import NavHomeLinks from './NavHomeLinks/NavHomeLinks';
import UserBadgeMenu from '../UserBadge/UserBadgeMenu/UserBadgeMenu';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
  selectAllUIValues,
  setShowNotificationsMenu,
  setShowUserNavMenu,
} from '../../../features/UI/uiSlice';
import { AppDispatch } from '../../../app/store';
import NavbarNotification from './NavbarNotification/NavbarNotification';
import NavbarNotificationMenu from './NavbarNotificationMenu/NavbarNotificationMenu';
import useHideNavManus from '../../../CustomHooks/useHideNavMenus';
import classes from './Navbar.module.scss';

interface IProps {
  onClick?: () => void;
  show: boolean;
  hasLinks?: boolean;
  onGetId?: (id: string) => void;
}

const Navbar = ({ onClick, show, hasLinks, onGetId }: IProps) => {
  const { showNavUserMenu, showNavNotificationsMenu } =
    useSelector(selectAllUIValues);

  const dispatch = useDispatch<AppDispatch>();
  useHideNavManus();
  const showHideMenuHandler = (e: React.MouseEvent) => {
    e.stopPropagation();

    // dispatch(setShowUserNavMenu(false));
  };

  const showMenuHandler = () => {
    if (!showNavUserMenu) {
      batch(() => {
        dispatch(setShowUserNavMenu(true));
        dispatch(setShowNotificationsMenu(false));
      });
    } else {
      dispatch(setShowUserNavMenu(false));
    }
  };

  const showNotificationsMenuHandler = () => {
    if (!showNavNotificationsMenu) {
      batch(() => {
        dispatch(setShowNotificationsMenu(true));
        dispatch(setShowUserNavMenu(false));
      });
    } else {
      dispatch(setShowNotificationsMenu(false));
    }
  };

  return (
    <div className={classes.NavContainer}>
      <nav className={`${classes.Navbar}`}>
        <Logo size={9} />
        <div className={classes.MenuMobile}>
          <Hamburger onClick={onClick} show={show} />
        </div>
        <div className={classes.MenuLinks}>
          {hasLinks && <NavHomeLinks onClick={onGetId} />}
        </div>
        <div className={classes.MenuDesktop}>
          <NavbarNotification onClick={showNotificationsMenuHandler} />
          <UserBadge onClick={showMenuHandler} />
        </div>
      </nav>
      <div
        className={`${classes.UserBadgeMenuContainer} ${
          showNavUserMenu ? classes.show : ''
        }`}
      >
        <UserBadgeMenu onSelect={showHideMenuHandler} />
      </div>
      <div
        className={`${classes.NotificationsMenuContainer} ${
          showNavNotificationsMenu ? classes.show : ''
        }`}
      >
        <NavbarNotificationMenu />
      </div>
    </div>
  );
};

export default Navbar;
