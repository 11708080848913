import {
  Layer,
  LineLayer,
  // GeoJSONSourceRaw,
  Source,
  useMap,
} from 'react-map-gl';
import { LineLayout, LinePaint, GeoJSONSourceRaw } from 'mapbox-gl';

import { INullableLocation } from '../../../tsTypes/types.components';

interface IProps {
  directionColor?: string;
  lineWidth?: number;
  lineOpacity?: number;
  startLocation: INullableLocation;
  endLocation: INullableLocation;
  directions: any[];
}

const MapDirectionLayer = ({
  directionColor,
  lineWidth,
  lineOpacity,
  startLocation,
  endLocation,
  directions,
}: IProps) => {
  const map = useMap();

  const { latitude: latitudeStart, longitude: longitudeStart } = startLocation
    ? startLocation
    : { latitude: null, longitude: null };
  const { latitude: latitudeEnd, longitude: longitudeEnd } = endLocation
    ? endLocation
    : { latitude: null, longitude: null };

  if (
    !startLocation ||
    !endLocation ||
    !latitudeStart ||
    !latitudeEnd ||
    !longitudeStart ||
    !longitudeEnd
  ) {
    return null;
  }

  if (
    latitudeStart &&
    latitudeEnd &&
    longitudeStart &&
    longitudeEnd &&
    map &&
    map.current
  ) {
    map.current.fitBounds(
      [
        [longitudeStart, latitudeStart],
        [longitudeEnd, latitudeEnd],
      ],
      { zoom: 11 }
    );
  }

  const geojson = {
    type: 'Feature' as 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: directions,
    },
  } as any;

  const directionLayer: LineLayer = {
    id: 'route',
    type: 'line' as 'line',
    source: {
      type: 'geojson' as 'geojson',
      data: geojson,
    } as GeoJSONSourceRaw,
    layout: {
      'line-join': 'round',
      'line-cap': 'square',
      visibility: 'visible',
    } as LineLayout,
    paint: {
      'line-color': '#d95c2d',
      'line-width': 8,
      'line-opacity':
        lineOpacity && lineOpacity < 1 && lineOpacity > 0 ? lineOpacity : 0.65,
    } as LinePaint,
  };

  return (
    <Source type='geojson' data={geojson}>
      <Layer {...directionLayer} />
    </Source>
  );
};

export default MapDirectionLayer;
