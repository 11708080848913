import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { ImLocation2 } from 'react-icons/im';
import { FaDirections, FaHouseUser, FaCheckCircle } from 'react-icons/fa';
import { TbBuildingCommunity } from 'react-icons/tb';
import { ILocation, IStep } from '../../../tsTypes/types.components';
import {
  convertDistanceToKm,
  convertDuration,
  getUserLocation,
} from '../../../utils/componentUtilFns';
import DataRow from '../../UI/DataRow/DataRow';
import Input from '../../UI/FormElements/Input/Input';

import HeadingBar from '../../UI/HeadingBar/HeadingBar';
import classes from './MapDirectionsBoard.module.scss';
import StepsCard from './StepsCard/StepsCard';
import NoData from '../../UI/NoData/NoData';
import {
  useAddOrganizationCoordinatesMutation,
  useGetOrganizationCoordinatesQuery,
} from '../../../features/organization/organizationSlice';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import {
  loggedInUser,
  selectAllAuthValues,
  useAddCurrentCoordinatesToUserMutation,
} from '../../../features/auth/authSlice';
import { IPoint } from '../../../tsTypes/types.model';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useSearchParams } from 'react-router-dom';

import { FaLocationCrosshairs } from 'react-icons/fa6';
import { confirmAlert } from 'react-confirm-alert';
import queryErrorCatch from '../../../utils/queryErrorCatch';
import { AppDispatch } from '../../../app/store';

interface IProps {
  onClick?: () => void;
  show?: boolean;
  stepsData: {
    steps: IStep[];
    distance: number;
    duration: number;
  } | null;

  selectedIncident?: Pick<IPoint, 'Coordinates' | 'Type'> | null;
  onGetUserLocation: (userLocation: ILocation) => void;
  onGetCurrentOrganizationLocation: (
    currentOrganizationLocation: ILocation
  ) => void;
}

const MapDirectionsBoard = ({
  onClick,
  show,
  stepsData,
  selectedIncident,
  onGetUserLocation,
  onGetCurrentOrganizationLocation,
}: IProps) => {
  const [userLocation, setUserLocation] = useState<ILocation | null>(null);
  const [organizationLocation, setOrganizationLocation] =
    useState<ILocation | null>(null);
  const [isRegisteredUserLocation, setIsRegisteredUserLocation] =
    useState(false);
  const [searchParams] = useSearchParams();

  const contractId = searchParams.get('contractId');
  const dispatch = useDispatch<AppDispatch>();

  const initializedLocation = useRef(false);

  const [currentOrganization, setCurrentOrganization] = useState<{
    latitude: number;
    longitude: number;
    organizationName: string;
  } | null>();

  const { user } = useSelector(selectAllAuthValues);
  const { data: organizationDetails } = useGetOrganizationCoordinatesQuery(
    contractId || '',
    { skip: !show }
  );

  const [addCoordinates] = useAddOrganizationCoordinatesMutation();
  const [addUserCoords] = useAddCurrentCoordinatesToUserMutation();

  const hasUserLocation =
    user !== null &&
    'location' in user &&
    user.location &&
    typeof user.location === 'object' &&
    'location' in user.location &&
    user.location.location &&
    'latitude' in user.location.location &&
    user.location.location.latitude &&
    'longitude' in user.location.location &&
    user.location.location.longitude;
  const hasOrganizationLocation =
    organizationDetails !== undefined &&
    'latitude' in organizationDetails &&
    organizationDetails.latitude &&
    'longitude' in organizationDetails &&
    organizationDetails.longitude;

  //Initialize start location
  useEffect(() => {
    if (initializedLocation.current) return;

    if (hasOrganizationLocation) {
      setOrganizationLocation({
        latitude: organizationDetails.latitude,
        longitude: organizationDetails.longitude,
      });
      setCurrentOrganization({
        latitude: organizationDetails.latitude,
        longitude: organizationDetails.longitude,
        organizationName: organizationDetails.organization,
      });
      setIsRegisteredUserLocation(false);
    } else if (
      user &&
      user.location &&
      user.location.location &&
      user.location.location.latitude &&
      user.location.location.longitude
    ) {
      setUserLocation({
        latitude: Number(user.location.location.latitude),
        longitude: Number(user.location.location.longitude),
      });

      setIsRegisteredUserLocation(true);
    } else {
      //initialize to current location

      getUserLocation(setUserLocation);
      setIsRegisteredUserLocation(false);
    }

    initializedLocation.current = true;

    // eslint-disable-next-line
  }, []);

  //This will set the selected start location (organization location or user registered location or current location)
  useEffect(() => {
    if (userLocation && !organizationLocation) {
      onGetUserLocation(userLocation);
    } else if (organizationLocation) {
      onGetCurrentOrganizationLocation(organizationLocation);
    }

    // eslint-disable-next-line
  }, [userLocation, organizationLocation]);

  const getUserCurrentLocationHandler = () => {
    getUserLocation(setUserLocation);
    setOrganizationLocation(null);
    setIsRegisteredUserLocation(false);
  };

  const addUserCoordinates = async (latitude: number, longitude: number) => {
    try {
      const res = await addUserCoords({ latitude, longitude }).unwrap();
      const uData = localStorage.getItem('uData');
      const pToken = localStorage.getItem('pToken');

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess &&
        pToken &&
        uData
      ) {
        const parsedData = JSON.parse(uData);

        dispatch(loggedInUser({ pToken, token: parsedData.token }));
        return toast.success(
          <ToastifyStyledMessage
            heading='User Coordinates'
            singleMessage='Coordinates added successfully'
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const addUserCurrentCoordinatesToUserRegisteredCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (latitude && longitude) {
            addUserCoordinates(latitude, longitude);
          } else {
            return toast.error(
              <ToastifyStyledMessage
                heading='Not Processed'
                singleMessage='An unexpected error occurred while processing the coordinates. Please try again later. Additionally, ensure that you have granted the app permission to access your current coordinates.'
              />
            );
          }
        },
        (error: GeolocationPositionError) => {
          toast.info(`Unfortunately, we couldn't locate the coordinates.`);
        },
        { enableHighAccuracy: true, maximumAge: 0 }
      );
    }
  };

  const getUserRegisteredLocationHandler = () => {
    if (
      hasUserLocation &&
      user &&
      'location' in user &&
      user.location &&
      'location' in user.location &&
      user.location.location
    ) {
      setIsRegisteredUserLocation(true);
      setOrganizationLocation(null);
      setUserLocation({
        latitude: user.location.location.latitude,
        longitude: user.location.location.longitude,
      });
    } else if (user) {
      return confirmAlert({
        title: 'Add Coordinates',
        message: `Dear ${user.name} ${user.surname}, currently, we do not have any coordinates associated with your registration data. Would you like to add your current coordinates as your registration coordinates now?`,
        buttons: [
          {
            label: 'Yes',
            onClick: addUserCurrentCoordinatesToUserRegisteredCoordinates,
          },
          { label: 'No' },
        ],
      });
    } else {
      return toast.error(
        <ToastifyStyledMessage
          heading='Unavailable Location'
          singleMessage='Currently, we do not have your registered location data'
        />
      );
    }
  };

  const addOrganizationCoordinates = async (data: {
    latitude: number;
    longitude: number;
    contractId: string;
  }) => {
    try {
      const { latitude, longitude, contractId } = data;
      const res = await addCoordinates({
        latitude,
        longitude,
        contractId,
      }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'isSuccess' in res &&
        res.isSuccess
      ) {
        return toast.success(
          <ToastifyStyledMessage
            heading='Updated'
            singleMessage='Organization Coordinates Updated Successfully.'
          />
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const addUserCurrentCoordinatesToOrganizationCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          if (latitude && longitude && contractId) {
            addOrganizationCoordinates({ latitude, longitude, contractId });
          } else {
            return toast.error(
              <ToastifyStyledMessage
                heading='Not Processed'
                singleMessage='An unexpected error occurred while processing the coordinates. Please try again later. Additionally, ensure that you have granted the app permission to access your current coordinates.'
              />
            );
          }
        },
        (error: GeolocationPositionError) => {
          toast.info(`Unfortunately, we couldn't locate the coordinates.`);
        },
        { enableHighAccuracy: true, maximumAge: 0 }
      );
    }
  };

  const getOrganizationRegisteredLocationHandler = () => {
    if (hasOrganizationLocation) {
      setUserLocation(null);
      setIsRegisteredUserLocation(false);
      setCurrentOrganization({
        latitude: organizationDetails.latitude,
        longitude: organizationDetails.longitude,
        organizationName: organizationDetails.organization,
      });
      setOrganizationLocation({
        latitude: organizationDetails.latitude,
        longitude: organizationDetails.longitude,
      });
    } else if (user && contractId) {
      const currentContractAuth = user.userAuthByContract.find(
        (contract) => contract.contractId === contractId
      );

      if (
        currentContractAuth &&
        currentContractAuth.authorizations &&
        currentContractAuth.authorizations.hasAuthToActions
      ) {
        return confirmAlert({
          title: 'Add Coordinates',
          message:
            "We currently don't have the location coordinates for your organization. Would you like to update your organization's location with the current coordinates of your current location?",
          buttons: [
            {
              label: 'Yes',
              onClick: addUserCurrentCoordinatesToOrganizationCoordinates,
            },
            { label: 'No' },
          ],
        });
      }

      return toast.error(
        <ToastifyStyledMessage
          heading='Unavailable Location'
          singleMessage="We currently don't have the location coordinates for your organization."
        />
      );
    } else {
      return toast.error(
        <ToastifyStyledMessage
          heading='Unavailable Location'
          singleMessage="We currently don't have the location coordinates for your organization."
        />
      );
    }
  };

  return (
    <div
      className={`${classes.MapDirectionBoardContainer} ${
        show ? classes.Show : ''
      }`}
    >
      <HeadingBar
        heading='Directions'
        headingSize={3}
        icon={<FaDirections size={18} />}
      >
        <span className={classes.MobileCloseButton} onClick={onClick}>
          <AiOutlineCloseCircle />
        </span>
      </HeadingBar>
      <div className={classes.LocationEntrySection}>
        <div className={classes.LocationOptions}>
          <button
            className={`${classes.OptionButton}`}
            title='Click to get direction according to you current location.'
            onClick={getUserCurrentLocationHandler}
          >
            <FaLocationCrosshairs />
          </button>
          <button
            className={`${classes.OptionButton} ${
              !hasUserLocation ? classes.Unavailable : ''
            }`}
            title={
              hasUserLocation
                ? 'Click to get directions according to your registered coordinates'
                : `Currently, we do not have your coordinates. Click to add your coordinates if your current location matches your location coordinates.`
            }
            onClick={getUserRegisteredLocationHandler}
          >
            <FaHouseUser />
          </button>
          <button
            className={`${classes.OptionButton} ${
              !hasOrganizationLocation ? classes.Unavailable : ''
            }`}
            title={
              hasOrganizationLocation
                ? 'Click to get directions according to the organization location'
                : `Currently, we do not have your organization's coordinates. Click to add the current coordinates as your organization's coordinates if they accurately represent your organization's location on the map.`
            }
            onClick={getOrganizationRegisteredLocationHandler}
          >
            <TbBuildingCommunity />
          </button>
        </div>
        <div className={classes.LocationEntry}>
          <FaCheckCircle />
          <Input
            name='startloaction'
            value={
              userLocation && !isRegisteredUserLocation
                ? 'Your Current Location'
                : organizationLocation &&
                  currentOrganization &&
                  !isRegisteredUserLocation
                ? `${currentOrganization.organizationName}`
                : 'Registered User Location'
            }
            onChange={() => {}}
            style={{ color: 'lightgrey', fontSize: '0.8rem' }}
          />
        </div>

        <div className={classes.LocationEntry}>
          <ImLocation2 color='rgb(219, 25, 25)' size={15} />
          <Input
            name='endlocation'
            value={
              selectedIncident ? selectedIncident.Type : 'No Incident Selected'
            }
            onChange={() => {}}
            style={{ color: 'lightgrey', fontSize: '0.8rem' }}
          />
        </div>
      </div>

      <div className={classes.HideButton}>
        <button onClick={onClick}>
          {show ? <BiLeftArrow /> : <BiRightArrow />}
        </button>
      </div>
      {stepsData ? (
        <div className={classes.Directions}>
          <div className={classes.DataSummarySection}>
            <DataRow
              heading='Distance'
              data={convertDistanceToKm(stepsData.distance)}
            />
            <DataRow
              heading='Duration'
              data={convertDuration(stepsData.duration)}
            />
          </div>
          <div className={classes.DirectionSteps}>
            {stepsData.steps.map((step) => (
              <StepsCard step={step} key={step.id} />
            ))}
          </div>
        </div>
      ) : (
        <NoData message='No Incident selected' />
      )}
    </div>
  );
};

export default MapDirectionsBoard;
