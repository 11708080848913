import React from 'react';
import HeadingBar from '../HeadingBar/HeadingBar';
import classes from './NavbarMenuContainer.module.scss';

interface IProps {
  heading: string;
  children: React.ReactNode | JSX.Element | JSX.Element[];
  onSelect?: (e: React.MouseEvent) => void;
  style?: React.CSSProperties;
}

const NavbarManuContainer = ({
  heading,
  children,
  onSelect,
  style,
}: IProps) => {
  return (
    <div className={classes.Menu} style={style}>
      <HeadingBar heading={heading} headingSize={4} />
      <div className={classes.MenuOptions} onClick={onSelect}>
        {children}
      </div>
    </div>
  );
};

export default NavbarManuContainer;
