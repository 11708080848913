import React, { forwardRef, InputHTMLAttributes, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import classes from './Input.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  value?: string;
  styleLabel?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  isInvalid?: boolean;
  hasShowPasswordButton?: boolean;
  title?: string;
  showInformationBoard?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
}

const Input = (
  {
    name,
    label,
    value,
    styleLabel,
    onChange,
    isRequired,
    hasShowPasswordButton,
    isInvalid,
    title,
    children,
    showInformationBoard,
    disabled,
    ...rest
  }: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const [showPassword, setShowPassword] = useState(false);
  const labelStyle = value ? { fontSize: '11px', top: '-4px' } : {};

  const allProps = { ...rest };

  const showPasswordHandler = () => {
    if (disabled) {
      return;
    }

    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className={classes.MainContainer}>
      <div className={classes.formGroup} title={title}>
        <input
          {...allProps}
          name={name}
          id={name}
          className={`${classes.formInput} ${isInvalid ? classes.Invalid : ''}`}
          value={value}
          onChange={!disabled ? onChange : () => {}}
          type={!showPassword ? allProps.type : 'text'}
          ref={ref}
          disabled={disabled}
        />
        <label
          htmlFor={name}
          className={classes.formLabel}
          style={{
            ...labelStyle,
            ...styleLabel,
          }}
        >
          {label} {isRequired && <span className={classes.IsRequired}>*</span>}
        </label>
        {hasShowPasswordButton && (
          <div
            title={!showPassword ? 'Show Password' : 'Hide Password'}
            className={classes.ShowHidePassword}
            onClick={showPasswordHandler}
          >
            {!showPassword ? (
              <AiOutlineEye color='darkgray' />
            ) : (
              <AiOutlineEyeInvisible color='red' />
            )}
          </div>
        )}
      </div>
      {showInformationBoard ? (
        <div className={classes.InformationBoard}>{children}</div>
      ) : null}
    </div>
  );
};
export default forwardRef(Input);
