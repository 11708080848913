import classes from './DataCard.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const DataCard = ({ children, className }: IProps) => {
  return (
    <div
      className={`${classes.DataCardContainer} ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

export default DataCard;
