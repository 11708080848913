const isValidNumber = (enteredValue: string) => {
  const valFromPattern = enteredValue.split(' ').join('');

  if (valFromPattern === '') {
    return true;
  }

  if (valFromPattern !== '' && !isNaN(Number(valFromPattern))) {
    return true;
  }

  return false;
};

export default isValidNumber;
