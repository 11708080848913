import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  changeAuthForm,
  selectAllAuthValues,
} from '../../../features/auth/authSlice';
import SiteEntryContainer from '../../UI/EnterSite/SiteEntryContainer';
import SignIn from './SignIn/SignIn';
import SignUp from './SignUp/SignUp';
import { frontendConstants } from '../../../constants/constants';
import { currentForm } from '../../../tsTypes/types.components';
import { AppDispatch } from '../../../app/store';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../ErrorFallback/ErrorFallback';

const LoginRegister = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentForm, isAuthenticated } = useSelector(selectAllAuthValues);

  const isLogin = currentForm === 'login';

  //get current form from localstorage
  useEffect(() => {
    const storedCurrentForm = localStorage.getItem('auth-form');
    if (storedCurrentForm) {
      dispatch(changeAuthForm(storedCurrentForm as currentForm));
    }
    // eslint-disable-next-line
  }, [dispatch, currentForm]);

  useEffect(() => {
    if (isLogin) {
      toast.dismiss();
      toast.clearWaitingQueue();
    }
  }, [isLogin]);

  if (isAuthenticated) {
    return <Navigate to={frontendConstants.pages.dashboard} />;
  }

  let content = <SignIn />;

  if (!isLogin) {
    content = <SignUp />;
  }

  return (
    <SiteEntryContainer>
      <ErrorBoundary FallbackComponent={ErrorFallback}>{content}</ErrorBoundary>
    </SiteEntryContainer>
  );
};

export default LoginRegister;
