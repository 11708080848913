import { useState, useEffect, Fragment } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HeadingBar from '../../../UI/HeadingBar/HeadingBar';

import classes from './IncidentDashboard.module.scss';

import { IListObject } from '../../../../tsTypes/types.model';

import { confirmAlert } from 'react-confirm-alert';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { toast } from 'react-toastify';

import Loading from '../../../UI/Loading/Loading';
// import ProgressBar from '../../../UI/ProgressBar/ProgressBar';
import IncidentMap from '../IncidentMap/IncidentMap';
import { selectAllUIValues } from '../../../../features/UI/uiSlice';
import useQuery from '../../../../CustomHooks/useQuery';
import {
  useGetGridIncidentsQuery,
  useUpdateIncidentAsFalsePositiveMutation,
} from '../../../../features/incidents/incidentsSlice';
import NoDataFound from '../../../UI/NoDataFound/NoDataFound';
import ActionContainer from '../../../UI/FormElements/ActionContainer/ActionContainer';
// import { Coordinates } from '../../../../tsTypes/types';
import {
  IContractIncident,
  ITimelineItem,
  IUserAuth,
} from '../../../../tsTypes/interfaces';
import TimelineList from './TimelineList/TimelineList';
import Dropdown from '../../../UI/FormElements/Dropdown/Dropdown';
import StandardButton from '../../../UI/FormElements/StandardButton/StandardButton';
import IncidentVisitForm from './IncidentVisitForm/IncidentVisitForm';
import IncidentVisitReport from './IncidentVisitReport/IncidentVisitReport';
import queryErrorCatch from '../../../../utils/queryErrorCatch';
import IncidentReportForm from './IncidentReportFrom/IncidentReportForm';
import IncidentDetails from './IncidentDetails/IncidentDetails';
import AllIncidentReports from './AllIncidentReports/AllIncidentReports';
import IncidentCleanedForm from './IncidentCleanedForm/IncidentCleanedForm';
import { IoImages } from 'react-icons/io5';
import IncidentCleanedReport from './IncidentCleanedReport/IncidentCleanedReport';
import { capitalizeAllWords } from '../../../../utils/componentUtilFns';
import IncidentAddress from './IncidentAddress/IncidentAddress';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../utils/getTranslationKey';

const IncidentDashboard = () => {
  const location = useQuery();
  const { gridId } = useParams();
  const urlData = useLocation();
  const navigate = useNavigate();
  const [showBoard, setShowBoard] = useState(false);
  const [showFullScreenImage, setShowFullScreenImage] = useState(false);
  const [isVisitedForm, setIsVisitedForm] = useState(false);
  const [incidentFalsePositive, setIncidentFalsePositive] = useState(false);
  const [incidentRemoved, setIncidentRemoved] = useState(false);

  const [isReportForm, setIsReportForm] = useState(false);
  const [isCleanedForm, setIsCleanedForm] = useState(false);
  const [isVisitedReport, setIsVisitedReport] = useState(false);
  const [isCleanedReport, setIsCleanedReport] = useState(false);
  // const [isReportSummary, setIsReportSummary] = useState(false);
  const [isAllIncidentReports, setIsAllIncidentReports] = useState(false);
  const contractId = location.get('contractId');
  const latestIncident = location.get('latestIncident');
  const { isLoading, isError, data } = useGetGridIncidentsQuery({
    gridId: gridId?.toString() || '',
    gridObjectId: latestIncident || '',
    contractId: contractId || '',
  });
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en';

  const [
    updateIncidentAsFalsePositive,
    { isLoading: isFalsePositiveUpdateLoading },
  ] = useUpdateIncidentAsFalsePositiveMutation();

  const pathname = urlData.pathname;
  const { isExpanded } = useSelector(selectAllUIValues);

  let incidents: IContractIncident[] = [];
  let timeline: ITimelineItem[] = [];
  let userAuth: IUserAuth | null = null;
  let timelineList: React.ReactNode | null = null;
  let heading: React.ReactNode | string | null = null;
  let address: string = '';

  // let coordinates: Coordinates = [0, 0];
  // const showModal =
  //   isCleanedForm ||
  //   isVisitedForm ||
  //   isReportForm ||
  //   isVisitedReport ||
  //   isReportSummary ||
  //   isCleanedReport;

  useEffect(() => {
    const directionsBoardValue = localStorage.getItem('show-directions-board');

    if (directionsBoardValue) {
      const parsedValue = JSON.parse(directionsBoardValue);

      if (parsedValue.incident === latestIncident) {
        setShowBoard(parsedValue.show);
      }
    }
  }, [latestIncident]);

  if (isLoading) {
    return <Loading showBackDrop={false} />;
  }

  if (isError) {
    return (
      <div className={classes.IncidentsFetchProblem}>
        <NoDataFound message={t('incidentDashboard:noSpecificIncident')} />
        <ActionContainer className={classes.ActionLinkContainer}>
          <Link to='/' className={classes.BackLink}>
            {t('incidentDashboard:backToDashboard')}
          </Link>
        </ActionContainer>
      </div>
    );
  }

  if (
    !data ||
    typeof data !== 'object' ||
    !('incidents' in data) ||
    !('timeline' in data) ||
    !data.incidents ||
    !Array.isArray(data.incidents) ||
    data.incidents.length === 0 ||
    !data.timeline ||
    !Array.isArray(data.timeline) ||
    data.timeline.length === 0 ||
    !('userAuth' in data) ||
    !data.userAuth ||
    typeof data.userAuth !== 'object'
  ) {
    return (
      <div className={classes.IncidentsFetchProblem}>
        <NoDataFound message={t('incidentDashboard:noSpecificIncident')} />
        <ActionContainer className={classes.ActionLinkContainer}>
          <Link to='/' className={classes.BackLink}>
            {t('incidentDashboard:backToDashboard')}
          </Link>
        </ActionContainer>
      </div>
    );
  }

  incidents = data.incidents;
  timeline = data.timeline;

  if (data.userAuth && typeof data.userAuth === 'object') {
    userAuth = data.userAuth;
  }

  const selectedIncident = incidents.find(
    (incident) => incident.gridObjectId === latestIncident
  );

  if (!selectedIncident) {
    return (
      <div className={classes.IncidentsFetchProblem}>
        <NoDataFound message={t('incidentDashboard:noSpecificIncident')} />
        <ActionContainer className={classes.ActionLinkContainer}>
          <Link to='/' className={classes.BackLink}>
            {t('incidentDashboard:backToDashboard')}
          </Link>
        </ActionContainer>
      </div>
    );
  }

  if (
    selectedIncident &&
    'street' in selectedIncident &&
    selectedIncident.street
  ) {
    address = selectedIncident.street;
  }

  const selectCurrentIncident = (id: string) => {
    // Navigate to the current route with updated query parameters
    navigate({
      pathname,
      search: `?latestIncident=${id}&contractId=${contractId}`,
    });
  };

  const selectCurrentIncidentDropDown = (
    e:
      | {
          target: {
            name: string;
            value: string;
          };
        }
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const id = e.target.value;

    navigate({
      pathname,
      search: `?latestIncident=${id}&contractId=${contractId}`,
    });
  };

  const allIncidentsHandler = () => {
    navigate('/');
  };

  const showHideBoardOnButtonClickHandler = () => {
    if (!showBoard) {
      localStorage.setItem(
        'show-directions-board',
        JSON.stringify({ show: !showBoard, incident: latestIncident })
      );
    } else {
      localStorage.removeItem('show-directions-board');
    }
    setShowBoard((prevState) => !prevState);
  };

  const visitedFormHandler = () => {
    setIsVisitedForm(true);
  };

  const visitedReportHandler = () => {
    setIsVisitedReport(true);
  };

  const reportFormHandler = () => {
    setIsReportForm(true);
  };

  const allIncidentReportsHandler = () => {
    setIsAllIncidentReports(true);
  };

  const cleanedFormHandler = () => {
    setIsCleanedForm(true);
  };

  const cleanedReportHandler = () => {
    setIsCleanedReport(true);
  };

  const closeFormHandler = () => {
    setIsVisitedForm(false);
    setIsVisitedReport(false);
    setIsCleanedForm(false);
    setIsReportForm(false);
    // setIsReportSummary(false);
    setIsCleanedReport(false);
    setIsAllIncidentReports(false);

    localStorage.removeItem('periopsis-report-reportToName');
    localStorage.removeItem('periopsis-report-reportToDepartment');
    localStorage.removeItem('periopsis-report-report-message');
  };

  const falsePositiveUpdater = async () => {
    try {
      if (
        contractId &&
        selectedIncident &&
        typeof selectedIncident === 'object' &&
        '_id' in selectedIncident &&
        selectedIncident._id
      ) {
        setIncidentFalsePositive(true);

        const res = await updateIncidentAsFalsePositive({
          contractId,
          contractIncidentId: selectedIncident._id,
        }).unwrap();

        if (
          res &&
          typeof res === 'object' &&
          'isSuccess' in res &&
          res.isSuccess
        ) {
          toast.success(
            <ToastifyStyledMessage
              singleMessage={t('incidentDashboard:removed_message')}
              heading={t('incidentDashboard:removed_heading')}
            />
          );
          setIncidentRemoved(true);

          const timer = setTimeout(() => {
            clearTimeout(timer);
            return navigate('/');
          }, 8000);
        }
      }
    } catch (error) {
      return queryErrorCatch(error);
    } finally {
      setIncidentFalsePositive(false);
    }
  };

  const updateIncidentAsFalsePositiveHandler = () => {
    confirmAlert({
      title: t('incidentDashboard:incident_removal_heading'),
      message: t('incidentDashboard:incident_removal_message'),
      buttons: [
        {
          label: t('incidentDashboard:Yes'),
          onClick: falsePositiveUpdater,
        },
        { label: t('incidentDashboard:No'), onClick: () => {} },
      ],
    });
  };

  const fullScreenImageHandler = () => {
    setShowFullScreenImage((prevState) => !prevState);
  };

  if (
    timeline &&
    Array.isArray(timeline) &&
    timeline.length > 0 &&
    latestIncident
  ) {
    timelineList = (
      <TimelineList
        timelineList={timeline}
        currentIncident={latestIncident}
        onIncidentSelect={selectCurrentIncident}
        onFullScreenClose={fullScreenImageHandler}
        showFullScreen={showFullScreenImage}
        incidentType={
          selectedIncident
            ? t(`dashboard:${getTranslationKey(selectedIncident.type)}`)
            : ''
        }
        address={address}
      />
    );
  }

  if (incidents && Array.isArray(incidents) && incidents.length > 1) {
    const optionsList: IListObject[] = incidents.map((incident) => ({
      id: incident.gridObjectId,
      title: `${incident.type} (${new Date(
        incident.detectionDate
      ).toLocaleDateString(language, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      })})`,
      value: incident.gridObjectId,
    }));

    heading = (
      <Dropdown
        optionsList={optionsList}
        value={latestIncident || ''}
        defaultValue=''
        name={t('incidentDashboard:Grid_Incidents')}
        onSelect={selectCurrentIncidentDropDown}
      />
    );
  } else if (incidents && Array.isArray(incidents) && incidents.length === 1) {
    heading = (
      <Fragment>
        <h1>
          {capitalizeAllWords(
            t(`dashboard:${getTranslationKey(selectedIncident.type)}`)
          )}{' '}
          {incidentFalsePositive ? t('incidentDashboard:removing') : ''}
        </h1>
        <p>
          {new Date(selectedIncident.detectionDate).toLocaleDateString(
            language,
            {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }
          )}
        </p>
      </Fragment>
    );
  }

  const isCleaned = selectedIncident.incidentActions.clean !== null;
  const isReported = selectedIncident.incidentActions.report.length > 0;
  const isVisited = selectedIncident.incidentActions.visit !== null;

  const removalText = incidentFalsePositive
    ? t('incidentDashboard:removing_incident')
    : incidentRemoved
    ? t('incidentDashboard:incident_removed')
    : '';

  return (
    <Fragment>
      <div className={classes.IncidentDashboardContainer}>
        <div
          className={`${classes.LeftSide} ${isExpanded ? classes.Shrink : ''}`}
        >
          <div className={classes.HeadingContainer}>{heading}</div>
          <IncidentMap
            selectedIncident={selectedIncident}
            showDirectionsBoard={showBoard}
            onShowBoard={showHideBoardOnButtonClickHandler}
          />
          <div className={classes.TimelineUnderMap}>
            <IncidentAddress address={address} />
            <HeadingBar
              heading={t('incidentDashboard:Timeline')}
              style={{ marginTop: '0.8rem' }}
              buttonIcon={<IoImages />}
              onClick={fullScreenImageHandler}
              hasButton
              title={t('incidentDashboard:view_full_images')}
            />
            {timelineList}
          </div>
        </div>
        <div className={classes.RigthSide}>
          <div className={`${classes.HeadingContainer} ${classes.Centered}`}>
            <h1>{t('incidentDashboard:Incident_Details')}</h1>
          </div>
          {!incidentFalsePositive && !incidentRemoved ? (
            <div className={classes.DetailsActions}>
              <div className={classes.Details}>
                <IncidentDetails
                  selectedIncident={selectedIncident}
                  showActions
                />
              </div>
              <div className={classes.ActionsWrapper}>
                <div
                  className={`${classes.HeadingContainer} ${classes.Centered}`}
                >
                  <h1>{t('incidentDashboard:Incident_Actions')}</h1>
                </div>

                <div className={classes.ButtonsWrapper}>
                  {userAuth && userAuth.hasAuthToActions ? (
                    <div className={`${classes.Details} ${classes.Buttons}`}>
                      {isReported ? (
                        <StandardButton
                          label={t('incidentDashboard:view_all_reports')}
                          btnType='primary'
                          onClick={
                            isReported ? allIncidentReportsHandler : undefined
                          }
                          type='button'
                          fakeButton={isFalsePositiveUpdateLoading}
                        />
                      ) : null}
                      <StandardButton
                        label={
                          !isReported
                            ? t('incidentDashboard:Report_Incident')
                            : t('incidentDashboard:Report_Details')
                        }
                        btnType={!isReported ? 'primary' : 'primary-light-blue'}
                        type='button'
                        fakeButton={isFalsePositiveUpdateLoading}
                        onClick={reportFormHandler}
                      />
                      <StandardButton
                        label={
                          !isVisited
                            ? t('incidentDashboard:Mark_Visited')
                            : t('incidentDashboard:Visit_Report')
                        }
                        btnType={!isVisited ? 'primary' : 'primary-light-blue'}
                        onClick={
                          !isVisited ? visitedFormHandler : visitedReportHandler
                        }
                        type='button'
                        fakeButton={isFalsePositiveUpdateLoading}
                      />

                      <StandardButton
                        label={
                          !isCleaned
                            ? t('incidentDashboard:Mark_Cleaned')
                            : t('incidentDashboard:Cleaned_Report')
                        }
                        btnType={!isCleaned ? 'primary' : 'primary-light-blue'}
                        type='button'
                        fakeButton={isFalsePositiveUpdateLoading}
                        onClick={
                          !isCleaned ? cleanedFormHandler : cleanedReportHandler
                        }
                      />
                      <StandardButton
                        label={t('incidentDashboard:Mark_No_Incident')}
                        btnType='orange'
                        type='button'
                        onClick={updateIncidentAsFalsePositiveHandler}
                        fakeButton={isFalsePositiveUpdateLoading}
                      />
                    </div>
                  ) : null}
                  {userAuth &&
                  !userAuth.hasAuthToActions &&
                  (isVisited || isCleaned || isReported) ? (
                    <div className={`${classes.Details} ${classes.Buttons}`}>
                      {isReported ? (
                        <StandardButton
                          label={t('incidentDashboard:view_all_reports')}
                          btnType='primary'
                          onClick={
                            isReported
                              ? () => {
                                  console.log('open window');
                                }
                              : undefined
                          }
                          type='button'
                          fakeButton={isFalsePositiveUpdateLoading}
                        />
                      ) : null}
                      <StandardButton
                        label={t('incidentDashboard:Visit_Report')}
                        btnType='primary-light-blue'
                        onClick={isVisited ? visitedReportHandler : () => {}}
                        type='button'
                      />
                    </div>
                  ) : null}

                  <div className={`${classes.Details} ${classes.Buttons}`}>
                    <StandardButton
                      label={
                        showBoard
                          ? t('incidentDashboard:Close_Directions')
                          : t('incidentDashboard:Get_Directions')
                      }
                      btnType={showBoard ? 'orange' : 'primary'}
                      onClick={showHideBoardOnButtonClickHandler}
                    />
                    <StandardButton
                      label={t('incidentDashboard:View_Images')}
                      btnType='primary'
                      onClick={fullScreenImageHandler}
                    />
                    <StandardButton
                      label={t('incidentDashboard:Go_Dashboard')}
                      btnType='primary'
                      onClick={allIncidentsHandler}
                    />
                    {/* <StandardButton
                  label='Download PDF Report'
                  btnType='orange'
                  onClick={() => {}}
                /> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.RemovingIncident}>{removalText}</div>
          )}
        </div>
        <div className={classes.TimelineUnderActions}>
          <IncidentAddress address={address} />
          <HeadingBar
            heading={t('incidentDashboard:Timeline')}
            style={{ marginTop: '0.8rem' }}
            buttonIcon={<IoImages />}
            onClick={fullScreenImageHandler}
            hasButton
            title={t('incidentDashboard:view_full_images')}
          />
          {timelineList}
        </div>
      </div>
      {!isVisited ? (
        <IncidentVisitForm
          selectedIncident={selectedIncident}
          showVisitForm={isVisitedForm}
          onClose={closeFormHandler}
        />
      ) : null}
      {isReported ? (
        <AllIncidentReports
          showAllIncidentReports={isAllIncidentReports}
          contractId={contractId}
          contractIncidentId={selectedIncident._id}
          onClose={closeFormHandler}
        />
      ) : null}

      <IncidentReportForm
        selectedIncident={selectedIncident}
        showReportForm={isReportForm}
        onCloseForm={closeFormHandler}
      />

      <IncidentCleanedForm
        onCloseForm={closeFormHandler}
        showCleanedForm={isCleanedForm}
        selectedIncident={selectedIncident}
      />

      <IncidentCleanedReport
        show={isCleanedReport}
        onClose={closeFormHandler}
        contractId={contractId || ''}
        contractIncidentId={selectedIncident._id}
        isCleaned={isCleaned}
        selectedIncident={selectedIncident}
      />

      {contractId && latestIncident && isVisited ? (
        <IncidentVisitReport
          show={isVisitedReport}
          contractId={contractId}
          contractIncidentId={selectedIncident._id}
          isVisited={isVisited}
          onClose={closeFormHandler}
          selectedIncident={selectedIncident}
        />
      ) : null}
    </Fragment>
  );
};

export default IncidentDashboard;
