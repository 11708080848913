import { Fragment, useLayoutEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { useGetIncidentTypeCountsQuery } from '../../../../../features/statistics/statisticsSlice';
import { IActionStatProps } from '../../Statistics';
import FetchingReports from '../../../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../../../UI/NoDataFound/NoDataFound';
import ActionRowItem from '../../ActionStatistics/ActionRowItem/ActionRowItem';
import classes from './IncidentsTypeDistribution.module.scss';
import ErrorControlComponent from '../../../../UI/ErrorControlComponent/ErrorControlComponent';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../utils/getTranslationKey';

const IncidentsTypeDistribution = ({
  contractId,
  currentYear,
  isSelected,
}: IActionStatProps) => {
  const { isLoading, isError, data, error } = useGetIncidentTypeCountsQuery(
    { contractId, year: currentYear },
    { skip: !contractId || !isSelected }
  );
  let content: React.ReactNode | null = null;
  const { t } = useTranslation();
  // const [backgroundColor, setBackgroundColor] = useState<string[]>([]);

  useLayoutEffect(() => {
    if (currentYear) {
      Chart.register(ArcElement, Tooltip);
    }
  }, [currentYear]);

  if (isLoading) {
    content = (
      <FetchingReports
        message={t('statistics:fetching_incident_types_statistics')}
      />
    );
  }

  if (isError) {
    content = (
      <ErrorControlComponent error={error}>
        <NoDataFound
          message={t('statistics:incident_types_statistics_fetch_error')}
        />
      </ErrorControlComponent>
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'counts' in data &&
    data.counts &&
    Array.isArray(data.counts) &&
    data.counts.length > 0 &&
    'chartData' in data &&
    data.chartData
  ) {
    const chartData = data.chartData;
    const counts = data.counts;

    const bgColors = data.chartData.datasets[0].backgroundColor;

    console.log(data.chartData);

    content = (
      <div className={classes.StatisticsWrapper}>
        <div className={`${classes.ChartSection}`}>
          <Pie
            id='Annual-Actions'
            data={{
              labels: chartData.labels.map((label: string) =>
                t(`dashboard:${getTranslationKey(label)}`)
              ),
              datasets: [
                {
                  label: t(
                    `dashboard:${getTranslationKey(
                      chartData.datasets[0].label
                    )}`
                  ),
                  data: [...chartData.datasets[0].data],
                  backgroundColor: bgColors,
                },
              ],
            }}
            options={{
              animation: { duration: 1000, easing: 'linear' },
              responsive: true,
              borderColor: 'lightblue',
              radius: '100%',
              aspectRatio: 1,

              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    pointStyle: 'circle',
                    usePointStyle: true,
                  },
                },
              },
            }}
          />
        </div>

        <div className={classes.DataTableSection}>
          {counts.map((count) => (
            <ActionRowItem
              label={t(`dashboard:${getTranslationKey(count.type)}`)}
              color={count.color}
              count={count.total}
              onClick={() => {}}
              key={count.type}
            />
          ))}
        </div>
      </div>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default IncidentsTypeDistribution;
