import React from 'react';
import americanExpressCVV from '../../../../../../../../assets/cvvCards/AmexCVVCompressed.png';
import otherCards from '../../../../../../../../assets/cvvCards/cvvCompressed.png';
import ModalContainer from '../../../../../../../UI/ModalContainer/ModalContainer';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import classes from './CVVInfoModal.module.scss';

interface IProps {
  issuer: string;
  show: boolean;
  onClick: () => void;
}

const CVVInfoModal = ({ issuer, onClick, show }: IProps) => {
  let image = otherCards;

  if (issuer === 'American Express') {
    image = americanExpressCVV;
  }

  return (
    <ModalContainer show={show} onClick={onClick}>
      <div className={classes.CVVIntroContainer}>
        <div className={classes.HeadingSection}>
          <h2>Card Verification Value</h2>
          <button onClick={onClick}>
            <AiOutlineCloseCircle />
          </button>
        </div>
        <p>
          CVV, or Card Verification Value, is a three or four-digit security
          code on your credit or debit card. It's usually found on the back of
          the card (for Visa, Mastercard, and Discover) or on the front (for
          American Express). It helps ensure that you're the rightful owner of
          the card when making online or in-person purchases, adding an extra
          layer of security to your transactions.
        </p>
        <div className={classes.ImageContainer}>
          <img
            src={image}
            alt={`${issuer} CVV Code`}
            title='Card Verification Value'
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default CVVInfoModal;
