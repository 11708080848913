import { item } from '../tsTypes/types.components';
import { validateText } from './componentUtilFns';

const isValidListItem = (list: item[]) => {
  let isValid = true;

  list.forEach((item) => {
    if (!validateText(item.name, 3, 120) || !isNaN(Number(item.name))) {
      isValid = false;
    }
  });

  return isValid;
};

export default isValidListItem;
