import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import classes from './SelectionForm.module.scss';

interface IProps {
  children: React.ReactNode;
  showCheckMark: boolean;
  style?: React.CSSProperties;
}

const SelectionForm = ({ children, showCheckMark, style }: IProps) => {
  return (
    <div className={classes.SelectionForm} style={{ ...style }}>
      {children}
      {showCheckMark && <FaCheckCircle />}
    </div>
  );
};

export default SelectionForm;
