import classes from './SkeletonLoading.module.scss';

interface IProps {
  width: number | string;
  height: number | string;
}

const SkeletonLoading = ({ width, height }: IProps) => {
  return (
    <div
      className={classes.SkeletonLoadingContainer}
      style={{ height, width }}
    ></div>
  );
};

export default SkeletonLoading;
