import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../app/store';
import {
  setLoggedInUser,
  useGetUserIPQuery,
  useLoginMutation,
} from '../../../../features/auth/authSlice';
import FormTransitionSection from './FormTransitionSection/FormTransitionSection';
import LoginForm from './LoginForm/LoginForm';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import classes from './SignIn.module.scss';
import queryErrorCatch from '../../../../utils/queryErrorCatch';
import ResendVerificationLinkForm from './ResendVerificationLinkForm/ResendVerificationLinkForm';
import TwoFactorAuth from './TwoFactorAuth/TwoFactorAuth';
import OneTimeLoginKey from './OneTimeLoginKey/OneTimeLoginKey';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import LanguageSelection from '../LanguageSelection/LanguageSelection';
import { useTranslation } from 'react-i18next';

const SignIn = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [twoFactorAuthCode, setTwoFactorAuthCode] = useState('');
  const [oneTimeLoginKey, setOneTimeLoginKey] = useState('');
  const [loginProcessState, setLoginProcessState] = useState<{
    is2FAProcess: boolean;
    isConfirmationCodeProcess: boolean;
    isForgotPasswordProcess: boolean;
    isResendVerification: boolean;
  }>({
    is2FAProcess: false,
    isConfirmationCodeProcess: false,
    isForgotPasswordProcess: false,
    isResendVerification: false,
  });
  const {
    is2FAProcess,
    isConfirmationCodeProcess,
    isForgotPasswordProcess,
    isResendVerification,
  } = loginProcessState;
  const [loginUser, { isLoading: loginLoading }] = useLoginMutation();
  const { t } = useTranslation();

  const { data: userIp } = useGetUserIPQuery('');

  let authKeyForm: React.ReactNode = null;

  const dispatch = useDispatch<AppDispatch>();

  const resetValues = () => {
    setTwoFactorAuthCode('');
    setPassword('');
    setUsername('');
    setOneTimeLoginKey('');

    setLoginProcessState({
      is2FAProcess: false,
      isConfirmationCodeProcess: false,
      isForgotPasswordProcess: false,
      isResendVerification: false,
    });
  };

  const userNameEntryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const userPasswordEntryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const oneTimeLoginKeyEntryHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOneTimeLoginKey(e.target.value);
  };

  const twoFactorAuthCodeEntryHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTwoFactorAuthCode(e.target.value.trim().toLocaleUpperCase());
  };

  const loginSubmitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    //TODO : Add Invalid UserName and Invalid Password states for empty fields!!

    try {
      const isUsernameAndPasswordEntered =
        username.trim().length > 3 && password.trim().length >= 6;
      const userData = {
        username,
        password,
        userIp,
        oneTimeLoginKey,
        twoFactorAuthCode,
      };

      if (!isUsernameAndPasswordEntered) {
        return toast.error(
          <ToastifyStyledMessage
            singleMessage={t('auth:invalid_credentials_message')}
            heading={t('auth:invalid_credentials')}
          />
        );
      }

      const res = await loginUser({ credentials: userData }).unwrap();

      if (
        res &&
        typeof res === 'object' &&
        'is2FASend' in res &&
        'isConfirmationCodeProcess' in res &&
        'isResendVerification' in res &&
        'loggedInUser' in res &&
        !res.loggedInUser
      ) {
        setLoginProcessState({
          is2FAProcess: res.is2FASend,
          isConfirmationCodeProcess: res.isConfirmationCodeProcess,
          isForgotPasswordProcess: false,
          isResendVerification: res.isResendVerification,
        });
      } else if (
        res &&
        typeof res === 'object' &&
        'is2FASend' in res &&
        'isConfirmationCodeProcess' in res &&
        'isResendVerification' in res &&
        'loggedInUser' in res &&
        res.loggedInUser &&
        typeof res.loggedInUser === 'object'
      ) {
        resetValues();
        dispatch(
          setLoggedInUser({
            user: res.loggedInUser,
            isAuthenticated: true,
            hasToken: true,
          })
        );
      }
    } catch (error) {
      return queryErrorCatch(error);
    }
  };

  const forgotPasswordFormHandler = () => {
    if (loginLoading || is2FAProcess || isConfirmationCodeProcess) {
      return;
    }

    if (isForgotPasswordProcess) {
      setLoginProcessState({
        is2FAProcess: false,
        isConfirmationCodeProcess: false,
        isForgotPasswordProcess: false,
        isResendVerification: false,
      });
    } else {
      setLoginProcessState({
        is2FAProcess: false,
        isConfirmationCodeProcess: false,
        isForgotPasswordProcess: true,
        isResendVerification: false,
      });
    }
  };

  const reset2FAEntry = () => {
    setTwoFactorAuthCode('');
  };

  const accountApprovedHandler = () => {
    setLoginProcessState({
      is2FAProcess: false,
      isConfirmationCodeProcess: false,
      isForgotPasswordProcess: false,
      isResendVerification: false,
    });
  };

  if (isConfirmationCodeProcess) {
    authKeyForm = (
      <OneTimeLoginKey
        oneTimeLoginKey={oneTimeLoginKey}
        onOneTimeLoginKeyEntry={oneTimeLoginKeyEntryHandler}
        onSubmit={loginSubmitHandler}
        isLoading={loginLoading}
      />
    );
  }

  if (is2FAProcess) {
    authKeyForm = (
      <TwoFactorAuth
        onSubmit={loginSubmitHandler}
        authCodeValue={twoFactorAuthCode}
        onAuthCodeChange={twoFactorAuthCodeEntryHandler}
        onResetEntry={reset2FAEntry}
        password={password}
        username={username}
        isLoading={loginLoading}
      />
    );
  }

  if (isResendVerification) {
    return (
      <ResendVerificationLinkForm
        onAccountApproved={accountApprovedHandler}
        username={username}
        password={password}
      />
    );
  }

  if (isForgotPasswordProcess) {
    return (
      <div className={classes.LoginFormContainer}>
        <ForgotPassword onFormClose={forgotPasswordFormHandler} />
      </div>
    );
  }

  return (
    <div className={classes.LoginFormContainer}>
      {!isConfirmationCodeProcess &&
      !is2FAProcess &&
      !isForgotPasswordProcess ? (
        <LoginForm
          onSubmit={loginSubmitHandler}
          onUserNameChange={userNameEntryHandler}
          onPasswordChange={userPasswordEntryHandler}
          credentials={{ password, username }}
          isLoading={loginLoading}
        />
      ) : (
        authKeyForm
      )}
      <FormTransitionSection
        onForgotPassword={forgotPasswordFormHandler}
        disabled={loginLoading || is2FAProcess || isConfirmationCodeProcess}
        isHidden={is2FAProcess || isConfirmationCodeProcess}
      />
      <LanguageSelection />
    </div>
  );
};

export default SignIn;
