import { useDispatch } from 'react-redux';
import { changeAuthForm } from '../../../../../../features/auth/authSlice';
import classes from './HaveAccount.module.scss';
import { useTranslation } from 'react-i18next';

const HaveAccount = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formChangeHandler = () => {
    dispatch(changeAuthForm('login'));
  };

  return (
    <div className={classes.SignIn}>
      <p>
        {t('auth:have_an_account')}
        <button onClick={formChangeHandler}>{t('auth:sign_in')}</button>
      </p>
    </div>
  );
};

export default HaveAccount;
