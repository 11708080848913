import HeadingBar from '../../../../UI/HeadingBar/HeadingBar';
import classes from './DocumentListContainer.module.scss';

interface IProps {
  children: React.ReactNode;
  heading: string;
}

const DocumentListContainer = ({ children, heading }: IProps) => {
  return (
    <div className={classes.DocumentListWrapper}>
      <HeadingBar heading={heading} headingType='primary' headingSize={3} />
      <div className={classes.DocumentListContainer}> {children}</div>
    </div>
  );
};

export default DocumentListContainer;
