import { ChangeEvent, FormEvent, Fragment, useEffect, useState } from 'react';
import SelectionForm from '../../../../SelectionForm/SelectionForm';
import classes from './UpdateForm.module.scss';
import Input from '../../../../../../../../UI/FormElements/Input/Input';
import useFormEntry from '../../../../../../../../../CustomHooks/useFormEntry';
import validateOrganizationName from '../../../../../../../../../utils/validateOrganizationName';
import Form from '../../../../../../../../UI/FormElements/Form/Form';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  approveInvoiceDetails,
  nextPage,
  selectAllRegistrationFormValues,
  setAreasOfInterest,
  setSelectedOrganization,
  useGetPolygonsQuery,
  useUpdateOrganizationByIdMutation,
} from '../../../../../../../../../features/registration/registrationSlice';
import {
  companyEmailWebMatch,
  getHeaderByCode,
  validateWebsite,
} from '../../../../../../../../../utils/componentUtilFns';
import Phone from '../../../../../../../../UI/Phone/Phone';
import PresentationWrapper from '../../../../../../../../UI/PresentationWrapper/PresentationWrapper';
import Address from '../../../../../../../../UI/Address/Address';
import usePhoneEntry from '../../../../../../../../../CustomHooks/usePhoneEntry';
import useAddress from '../../../../../../../../../CustomHooks/useAddress';
import { catchError } from '../../../../../../../../../utils/fetches';
import {
  IAreaOfInterest,
  IOrganization,
} from '../../../../../../../../../tsTypes/types.components';
import ActionContainer from '../../../../../../../../UI/FormElements/ActionContainer/ActionContainer';
import Button from '../../../../../../../../UI/FormElements/Button/Button';
import AreasOfInterest from '../../AreasOfInterest/AreasOfInterest';
import {
  CountryAlfa2Code,
  IPredefinedPolygon,
} from '../../../../../../../../../tsTypes/types.model';
import RadioButton from '../../../../../../../../UI/FormElements/RadioButton/RadioButton';
import { decodePattern } from '../../../../../../../../../utils/phoneUtilityFunctions/createPattern';
import ToastifyStyledMessage from '../../../../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { toast } from 'react-toastify';
import CenterDiv from '../../../../../../../../UI/CenterDiv/CenterDiv';
import SimpleLoading from '../../../../../../../../UI/SimpleLoading/SimpleLoading';
import { AppDispatch } from '../../../../../../../../../app/store';
import validateVatByCountry from '../../../../../../../../../utils/validateVATByCountry';
import { useTranslation } from 'react-i18next';

interface IProps {
  shouldSkip: boolean;
  organization: IOrganization;
  onClose: () => void;
}

const UpdateForm = ({ shouldSkip, organization, onClose }: IProps) => {
  const [selectedArea, setSelectedArea] = useState<IAreaOfInterest[]>([]);
  const [selectedForm, setSelectedForm] = useState('form-update');
  const { t } = useTranslation();

  const [updateOrganization, { isLoading: isUpdatingOrganization }] =
    useUpdateOrganizationByIdMutation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    selectedOrganizationType,
    selectedDistrict,
    selectedProvince,
    selectedCountry,
    areasOfInterest,
  } = useSelector(selectAllRegistrationFormValues);

  const isCommunity =
    selectedOrganizationType &&
    'title' in selectedOrganizationType &&
    selectedOrganizationType.title === 'Community'
      ? true
      : false;

  const isMunicipality =
    selectedOrganizationType &&
    'title' in selectedOrganizationType &&
    selectedOrganizationType.title === 'Municipality'
      ? true
      : false;

  const validateWeb = (webUrl: string) => {
    if (isCommunity) {
      return true;
    } else {
      return validateWebsite(webUrl);
    }
  };

  const validateVAT = (vatNumber: string) => {
    const countryAlpha2Code =
      selectedCountry &&
      typeof selectedCountry === 'object' &&
      'alpha2Code' in selectedCountry &&
      selectedCountry.alpha2Code
        ? selectedCountry.alpha2Code
        : ('' as CountryAlfa2Code);

    return validateVatByCountry(vatNumber, countryAlpha2Code);
  };

  const {
    value: organizationName,
    entryHandler: organizationNameEntryHandler,
    isValid: isOrganizationNameValid,
    setter: setOrganizationName,
    clearEntry: clearOrganizatioName,
  } = useFormEntry(
    validateOrganizationName,
    'organization-name',
    'update',
    true
  );

  const {
    value: organizationWebsite,
    entryHandler: organizationWebsiteEntryHandler,
    isValid: isValidWebsite,
    setter: setOrganizationWebsite,
    clearEntry: clearWebsite,
  } = useFormEntry(validateWeb, 'organization-website', 'update');

  const {
    value: organizationVATNumber,
    entryHandler: organizationVATNumberEntryHandler,
    isValid: isValidVATNumber,
    setter: setVatNumber,
    clearEntry: clearVATNumber,
  } = useFormEntry(validateVAT, 'organization-vat', 'update');

  const {
    phoneNumber,
    countryData,
    countryCodeSelectHandler,
    phoneEntryHandler,
    setCountryData,
    clearPhoneData,
  } = usePhoneEntry({
    localStorageName: 'update-organization-registration-phone',
  });

  const { variableData, addressFunctions } = useAddress({
    userAddressLocalStorageName: 'update-organization-address',
    moreAddressDetailsLocalStorageName:
      'update-more-orgenization-address-details',
    shouldSkip,
  });

  const {
    countries,
    isFetchingCountries,
    isCountryFetchError,
    countryFetchError,
    userAddress,
    addressStructure,
    moreAddressDetails,
    isValidAddress,
    isFetchingAddressStructure,
    isAddressStructureFetchError,
    addressStructureError,
    activateButton,
    isValidAddressType,
  } = variableData;

  const {
    addressCountrySelectHandler,
    userAddressEntryHandler,
    moreAddressDetailsHandler,
    clearAddress,
    setMoreAddressDetails,
    addressTypeSelectHandler,
  } = addressFunctions;

  let polygons: IPredefinedPolygon[] = [];
  let district: string = selectedDistrict ? selectedDistrict.name : '';
  let organizationType = selectedOrganizationType
    ? selectedOrganizationType.title
    : '';
  let country = selectedCountry ? selectedCountry.name : '';

  const {
    isLoading,
    isError,
    error,
    data: organizationResponseData,
  } = useGetPolygonsQuery(
    { country, district, organizationType },
    {
      skip: shouldSkip,
    }
  );

  if (
    organizationResponseData &&
    'polygons' in organizationResponseData &&
    Array.isArray(organizationResponseData.polygons)
  ) {
    polygons = organizationResponseData.polygons;
  }

  useEffect(() => {
    if (organization && 'web' in organization && organization.web) {
      setOrganizationWebsite(organization.web);
    }

    if (
      organization &&
      'organizationName' in organization &&
      organization.organizationName
    ) {
      setOrganizationName(organization.organizationName);
    }

    if (
      organization &&
      'phoneCountry' in organization &&
      organization.phoneCountry
    ) {
      setCountryData(organization.phoneCountry);
    }

    if (
      organization &&
      'address' in organization &&
      organization.address &&
      'addressDetails' in organization.address &&
      organization.address.addressDetails &&
      organization.address.addressDetails.name
    ) {
      setMoreAddressDetails(true);
    }

    if (organization && 'vatNumber' in organization && organization.vatNumber) {
      setVatNumber(organization.vatNumber);
    }

    if (
      areasOfInterest &&
      Array.isArray(areasOfInterest) &&
      areasOfInterest.length > 0
    ) {
      setSelectedArea(areasOfInterest);
    }

    // eslint-disable-next-line
  }, [organization]);

  useEffect(() => {
    if (
      (isMunicipality || isCommunity) &&
      district &&
      areasOfInterest &&
      Array.isArray(areasOfInterest) &&
      areasOfInterest.length > 0
    ) {
      const oldDistrict = areasOfInterest[0].district;

      if (oldDistrict !== district) {
        setSelectedArea([]);
      }
    }
  }, [district, isCommunity, isMunicipality, organization, areasOfInterest]);

  let organizationEmail = '';

  if (organization && 'email' in organization && organization.email) {
    organizationEmail = organization.email;
  }

  const areaOfInterestSelectHandler = (areaOfInterestData: {
    district: string;
    mid: string;
    area: number;
    areaUnit: string;
    polygonName: string;
  }) => {
    const { district, mid, area, areaUnit, polygonName } = areaOfInterestData;
    let country =
      selectedCountry && 'name' in selectedCountry && selectedCountry.name
        ? selectedCountry.name
        : '';
    let province =
      selectedProvince && 'name' in selectedProvince && selectedProvince.name
        ? selectedProvince.name
        : '';

    if (isCommunity || isMunicipality) {
      setSelectedArea([
        { district, mid, province, country, area, areaUnit, polygonName },
      ] as IAreaOfInterest[]);

      localStorage.setItem(
        'update-selected-area-of-interest',
        JSON.stringify([
          { district, mid, province, country, area, areaUnit, polygonName },
        ])
      );
    } else {
      setSelectedArea((prevState) => {
        const mids = prevState.map((item) => item.mid);
        let updatedData: IAreaOfInterest[] = [];

        if (mids.includes(mid)) {
          updatedData = prevState.filter(
            (item) => item.mid !== mid
          ) as IAreaOfInterest[];
        } else {
          updatedData = [
            ...prevState,
            { district, mid, province, country, area, areaUnit, polygonName },
          ] as IAreaOfInterest[];
        }

        localStorage.setItem(
          'update-selected-area-of-interest',
          JSON.stringify(updatedData)
        );
        return updatedData;
      });
    }

    dispatch(
      setAreasOfInterest({
        district,
        mid,
        province,
        country,
        area,
        areaUnit,
        polygonName,
      })
    );
  };

  const updateFormSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedForm(e.target.value);
  };

  const clearForm = () => {
    clearAddress();
    clearOrganizatioName();
    clearPhoneData();
    clearWebsite();
    clearVATNumber();
    localStorage.removeItem('periopsis-update-organization-website');
    localStorage.removeItem('periopsis-update-organization-vat');
    localStorage.removeItem('update-organization-registration-phone');
    localStorage.removeItem('update-organization-address');
    localStorage.removeItem('periopsis-update-organization-name');
  };

  const updateOrganizationFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      if (
        !organizationName ||
        !selectedOrganizationType ||
        !countryData ||
        !selectedDistrict
      ) {
        return;
      }

      if (!organization || !organization.organizationId) {
        return;
      }

      const updatedOrganization: IOrganization = {
        organizationName,
        address: userAddress,
        email: organizationEmail,
        web: organizationWebsite,
        phone: decodePattern(phoneNumber),
        phoneCountry: {
          countryCode: countryData.countryCode,
          name: countryData.name,
          code: countryData.code,
          NSN: countryData.NSN,
        },
        organizationType,
        vatNumber: organizationVATNumber,
      };

      //we will send it as organizationData
      const response = await updateOrganization({
        organization: updatedOrganization,
        organizationId: organization.organizationId,
      }).unwrap();

      if (response && response.organizationName) {
        clearForm();
        localStorage.setItem('selected-organization', JSON.stringify(response));

        dispatch(approveInvoiceDetails(false));

        if (onClose && typeof onClose === 'function') {
          onClose();
        }

        batch(() => {
          dispatch(setSelectedOrganization(response));

          dispatch(approveInvoiceDetails(false));
          const timer = setTimeout(() => {
            dispatch(nextPage());
            toast.dismiss();
            clearTimeout(timer);
          }, 3000);
        });
      }
    } catch (error) {
      const err = catchError(error);
      const heading = getHeaderByCode(err.status);

      return toast.error(
        <ToastifyStyledMessage singleMessage={err.message} heading={heading} />,
        { autoClose: 25000 }
      );
    }
  };

  const isValidDivision = activateButton && activateButton.isValidDivision;
  const isValidPostalCode = activateButton && activateButton.isValidPostalCode;
  const isValidSettlement = activateButton && activateButton.isValidSettlement;
  const isValidStreetAddress =
    activateButton && activateButton.isValidStreetAddress;
  const isValidSubdivision =
    activateButton && activateButton.isValidSubdivision;

  const isFormUpdate = selectedForm === 'form-update';
  const areDomainsMatch = isCommunity
    ? true
    : companyEmailWebMatch(organizationEmail, organizationWebsite);

  const isAllValid =
    isOrganizationNameValid &&
    isValidWebsite &&
    isValidDivision &&
    isValidPostalCode &&
    isValidSettlement &&
    isValidVATNumber &&
    isValidStreetAddress &&
    isValidSubdivision &&
    selectedArea &&
    selectedArea.length > 0 &&
    isValidAddressType &&
    areDomainsMatch;

  return (
    <Fragment>
      {!areDomainsMatch ? (
        <div className={classes.DomainError}>
          <p>{t('auth:email_web_domain_error')}</p>
        </div>
      ) : null}
      <div className={classes.UpdateSectionSelection}>
        <RadioButton
          name='update-form-section'
          label={t('auth:polygon_update')}
          value='polygon-update'
          onChange={updateFormSelectHandler}
          checked={selectedForm === 'polygon-update'}
          id='polyon-update'
        />
        <RadioButton
          name='update-form-section'
          label={t('auth:form_update')}
          value='form-update'
          onChange={updateFormSelectHandler}
          checked={selectedForm === 'form-update'}
          id='form-update'
        />
      </div>
      <SelectionForm showCheckMark={false}>
        <Form
          className={classes.RegistrationForm}
          onSubmit={updateOrganizationFormSubmit}
        >
          {isFormUpdate ? (
            <Fragment>
              <Input
                name='organization-name'
                label={t('auth:organization_name')}
                type='text'
                value={organizationName}
                onChange={organizationNameEntryHandler}
                isInvalid={!isOrganizationNameValid}
                isRequired
              />
              <Input
                name='organization-email'
                label={t('auth:organization_email_address')}
                type='email'
                value={organizationEmail}
                onChange={() => {}}
                isInvalid={false}
                isRequired
                disabled
              />
              <Input
                name='organization-web'
                label={t('auth:organization_website')}
                type='url'
                value={organizationWebsite}
                onChange={organizationWebsiteEntryHandler}
                isInvalid={!isValidWebsite}
                isRequired={!isCommunity}
              />
              <Input
                name='organization-vat'
                label={t('auth:organization_vat_number')}
                type='text'
                value={organizationVATNumber}
                onChange={organizationVATNumberEntryHandler}
                isInvalid={!isValidVATNumber}
                isRequired
              />
              <div className={classes.DropdownsSection}>
                <Phone
                  onPhoneEntry={phoneEntryHandler}
                  phoneNumber={phoneNumber}
                  onCodeSelect={countryCodeSelectHandler}
                  countryData={countryData}
                  fetchSkipCondition={false}
                  disabled={false}
                />
                <PresentationWrapper
                  isLoading={isFetchingAddressStructure}
                  isError={isAddressStructureFetchError}
                  error={catchError(addressStructureError)}
                  message={t('auth:address_structure_fetch_error')}
                >
                  <Address
                    isRequired
                    selectedCountry={userAddress.countryDetails}
                    onAddressCountrySelect={addressCountrySelectHandler}
                    addressStructure={addressStructure}
                    onAddressEntry={userAddressEntryHandler}
                    address={userAddress}
                    onMoreAddressDetails={moreAddressDetailsHandler}
                    onAddressTypeSelect={addressTypeSelectHandler}
                    activateMoreAddressDetails={moreAddressDetails}
                    validations={isValidAddress}
                    disabled={false}
                    countries={countries}
                    isLoading={isFetchingCountries}
                    isError={isCountryFetchError}
                    error={countryFetchError}
                  />
                </PresentationWrapper>
              </div>
            </Fragment>
          ) : null}
          {!isFormUpdate ? (
            <PresentationWrapper
              isLoading={isLoading}
              isError={isError}
              error={catchError(error)}
              message={t('auth:polygon_fetch_error')}
            >
              <AreasOfInterest
                predefinedPolygons={polygons}
                onSelect={areaOfInterestSelectHandler}
                selected={selectedArea}
              />
            </PresentationWrapper>
          ) : null}
          {!isUpdatingOrganization ? (
            <ActionContainer className={classes.FormSubmitButtons}>
              <Button type='button' onClick={onClose}>
                {t('auth:close_form')}
              </Button>
              <Button btnClass={classes.ActionButton} isFake={!isAllValid}>
                {t('auth:update_organization_data')}
              </Button>
            </ActionContainer>
          ) : (
            <CenterDiv>
              <SimpleLoading />
            </CenterDiv>
          )}
        </Form>
      </SelectionForm>
    </Fragment>
  );
};

export default UpdateForm;
