import { useId } from 'react';
import classes from './CheckBox.module.scss';

interface IProps {
  onSelect: () => void;
  isSelected: boolean;
  label: string;
  className?: string;
  disabled?: boolean;
}

const CheckBox = ({
  onSelect,
  isSelected,
  label,
  className,
  disabled,
}: IProps) => {
  const id = useId();

  return (
    <label
      htmlFor={id}
      className={`${classes.CheckBoxContainer} ${className ? className : ''}`}
    >
      <input
        type='checkbox'
        name='subservice-option'
        id={id}
        onChange={!disabled ? onSelect : () => {}}
        checked={isSelected}
        disabled={disabled}
      />
      {label}
    </label>
  );
};

export default CheckBox;
