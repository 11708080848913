import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ColumnDataContainer from '../../../UI/ColumnDataContainer/ColumnDataContainer';
import Paragraph from '../../../UI/Paragraph/Paragraph';
import satallite from '../../../../assets/images/satallite.png';
import DataCard from '../../../UI/DataCard/DataCard';

import classes from './ConfirmError.module.scss';
import { frontendConstants } from '../../../../constants/constants';

const ConfirmError = () => {
  const [count, setCount] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (count > 0) {
      timer = setTimeout(() => {
        setCount((prevState) => prevState - 1);
      }, 1000);
    }

    if (count === 0) {
      navigate(frontendConstants.pages.login, { replace: true });
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [count]);

  return (
    <ColumnDataContainer heading='Account Confirmed Error' headingType='danger'>
      <DataCard className={classes.Content}>
        <div className={classes.ConfirmationErrorImageContainer}>
          <img src={satallite} alt='Account Email Confirmed Error' />
        </div>
        <Paragraph
          paragraph={`The email for your account confirmed before. The token is not valid anymore. If you cannot login to your account, 
      most probably your account has not been approved by admin yet. If you believe there is something wrong, please contact
      technical department of Periopsis. Now, you will be redirected to homepage within ${count} seconds`}
          style={{
            lineHeight: '2rem',
            fontWeight: 'bolder',
            fontSize: '1.2rem',
          }}
          className={classes.Message}
          noEllipsis
        />
      </DataCard>
    </ColumnDataContainer>
  );
};

export default ConfirmError;
