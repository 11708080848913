import { Fragment } from 'react';
import { IExistingReport } from '../../../../tsTypes/interfaces';
import MessageListItem from '../MessageListItem/MessageListItem';
import DataRow from '../../../UI/DataRow/DataRow';
import { formatDistance } from 'date-fns';
import classes from './MessageContent.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  selectedReport?: IExistingReport;
  onClose?: () => void;
}

const MessageContent = ({ selectedReport, onClose }: IProps) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  if (!selectedReport) {
    return null;
  }

  const message: Pick<IExistingReport, 'incidentType' | 'detectedAt' | 'id'> = {
    incidentType: selectedReport.incidentType,
    detectedAt: selectedReport.detectedAt,
    id: selectedReport.id,
  };

  const emailedTo =
    selectedReport.toList.length > 0 && selectedReport.reportType === 'Mail'
      ? selectedReport.toList[0].name
      : '';

  return (
    <Fragment>
      <MessageListItem message={message} hideButtons onClose={onClose} />
      <div className={classes.DetailsOfMessage}>
        <div className={classes.ReportDetails}>
          <DataRow
            heading={t('reporting:reported_to')}
            data={selectedReport.reportTo}
            removeSeperator
          />
          <DataRow
            heading={t('translations:Department')}
            data={selectedReport.department}
            removeSeperator
          />
          <DataRow
            heading={t('reporting:reported_at')}
            data={`${new Date(selectedReport.reportDate).toLocaleDateString(
              language,
              { year: 'numeric', month: '2-digit', day: '2-digit' }
            )} (${formatDistance(
              new Date(),
              new Date(selectedReport.createdAt),
              { addSuffix: true }
            )})`}
            removeSeperator
          />
          {emailedTo !== '' ? (
            <DataRow
              heading={t('reporting:emailed_to')}
              data={selectedReport.toList[0].name}
              removeSeperator
            />
          ) : null}
          <DataRow
            heading={t('reporting:report_type')}
            data={selectedReport.reportType}
            removeSeperator
          />
        </div>
        {selectedReport.message && selectedReport.message.length > 0 ? (
          <div className={classes.MessageSection}>
            <p>{selectedReport.message}</p>
            <div className={classes.LocationOfIncident}>
              <a
                href={selectedReport.incidentLocationLink}
                title={selectedReport.incidentType}
                target='_blank'
                rel='noreferrer'
              >
                {t('reporting:location_on_map')}
              </a>
            </div>
          </div>
        ) : (
          <div className={classes.NoAdditionalMessage}>
            <p>{t('reporting:no_info_provided_by_sender')}</p>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default MessageContent;
