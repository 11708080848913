import { Link } from 'react-router-dom';
import classes from './CustomNavLink.module.scss';

interface IProps {
  href?: string;
  title: string;
  icon?: JSX.Element;
  to?: string;
  onClick?: (id: string) => void;
  onNavLinkSelect?: () => void;
}

const NavLink = ({
  href,
  title,
  icon,
  to,
  onClick,
  onNavLinkSelect,
}: IProps) => {
  const navLinkClickHandler = () => {
    if (onClick) {
      onClick(`${href}`);
    }
  };

  if (to) {
    return (
      <div className={classes.NavLink} onClick={onNavLinkSelect}>
        <span className={classes.NavLink_IconContainer}>{icon}</span>
        <Link to={to} className={`${classes.LinkBlock}`}>
          <div>{title}</div>
          <div className={classes.LinkBlock_Underline} />
        </Link>
      </div>
    );
  }

  return (
    <div className={classes.NavLink} onClick={onNavLinkSelect}>
      <span className={classes.NavLink_IconContainer}>{icon}</span>
      <a
        href={`${href}`}
        className={`${classes.LinkBlock}`}
        onClick={navLinkClickHandler}
      >
        <div>
          <div>{title}</div>
          <div className={classes.LinkBlock_Underline} />
        </div>
      </a>
    </div>
  );
};

export default NavLink;
