import classes from './CheckoutCard.module.scss';

interface IProps {
  heading: string;
  children: React.ReactNode | JSX.Element[] | JSX.Element;
  hide?: boolean;
}

const CheckoutCard = ({ heading, children, hide }: IProps) => {
  if (hide) {
    return null;
  }

  return (
    <div className={classes.CheckoutCard}>
      <div className={classes.HeadingSection}>
        <h1>{heading}</h1>
      </div>
      {children}
    </div>
  );
};

export default CheckoutCard;
