import StandardButton from '../../UI/FormElements/StandardButton/StandardButton';
import { useSearchParams } from 'react-router-dom';
import classes from './UserAdminPage.module.scss';
import { useEffect } from 'react';
import Contracts from './Contracts/Contracts';
import { useGetContractsQuery } from '../../../features/incidents/incidentsSlice';
import FetchingReports from '../Dashboard/IncidentDashBoard/IncidentReportFrom/FetchingReports/FetchingReports';
import NoDataFound from '../../UI/NoDataFound/NoDataFound';
import NoContract from '../../UI/NoContract/NoContract';
import { useGetPageSettingsQuery } from '../../../features/Settings/settingsSlice';
import { Settings } from '../../../tsTypes/enums';
import InvoicesAndReceipts from './InvoicesAndReceipts/InvoicesAndReceipts';
import ContractUsers from './ContractUsers/ContractUsers';
import getUserContractsResult from '../../../utils/ContractUsersUtils/getUserContractsResult';
import { useTranslation } from 'react-i18next';

const UserAdminPage = () => {
  // const [page, setPage] = useState<'contracts' | 'users'>('contracts');
  const [pageQuery, setPageQuery] = useSearchParams();
  const {
    isLoading,
    isError,
    data: userContracts,
  } = useGetContractsQuery('contracts');
  const { t } = useTranslation();

  const pageValue = pageQuery.get('page');
  const page = pageValue || 'contracts';
  const contract = pageQuery.get('contract');

  const { hasContracts, contractsTerminated } =
    getUserContractsResult(userContracts);

  let hasContractsSection = false;
  let hasInvoicesSection = false;
  let hasUsersSection = false;

  const { data: settings, isLoading: isLoadingSettings } =
    useGetPageSettingsQuery(Settings.userAdminPage, {
      skip: isLoading,
    });

  useEffect(() => {
    if (!pageValue && hasContracts) {
      setPageQuery({ page: 'contracts' });
    }
    // eslint-disable-next-line
  }, [hasContracts]);

  if (isLoading || isLoadingSettings) {
    return <FetchingReports message={t('admin:fetching_contracts')} />;
  }

  if (isError) {
    return <NoDataFound message={t('admin:contracts_fetch_error')} />;
  }

  if (!hasContracts) {
    return <NoContract hasTerminated={contractsTerminated} />;
  }

  const selectPageHandler = (page: 'contracts' | 'users' | 'invoices') => {
    if (page && contract) {
      setPageQuery({ page, contract });
    }
  };

  if (
    settings &&
    typeof settings === 'object' &&
    'hasCancelButton' in settings &&
    'hasCompletePaymentButton' in settings &&
    'hasContractDetailsButton' in settings &&
    'hasExtendButton' in settings
  ) {
    hasContractsSection = settings.hasContractsSection;
    hasInvoicesSection = settings.hasInvoicesSection;
    hasUsersSection = settings.hasUsersSection;
  }

  return (
    <div className={classes.UserAdminPageContainer}>
      <div className={classes.Tabs}>
        {hasContractsSection ? (
          <StandardButton
            type='button'
            btnType={page === 'contracts' ? 'primary' : 'primary-light'}
            label={t('admin:contracts')}
            isTabButton
            onClick={() => selectPageHandler('contracts')}
          />
        ) : null}
        {hasUsersSection ? (
          <StandardButton
            type='button'
            btnType={page === 'users' ? 'primary' : 'primary-light'}
            label={t('admin:users')}
            isTabButton
            onClick={() => selectPageHandler('users')}
          />
        ) : null}
        {hasInvoicesSection ? (
          <StandardButton
            type='button'
            label={t('admin:invoice_receipt')}
            isTabButton
            btnType={page === 'invoices' ? 'primary' : 'primary-light'}
            onClick={() => selectPageHandler('invoices')}
          />
        ) : null}
      </div>
      {page === 'contracts' && hasContracts ? <Contracts /> : null}
      {page === 'users' && hasContracts ? <ContractUsers /> : null}
      {page === 'invoices' && hasContracts ? <InvoicesAndReceipts /> : null}
    </div>
  );
};

export default UserAdminPage;
