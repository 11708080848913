import { ImBlocked } from 'react-icons/im';
import NoDataFound from '../NoDataFound/NoDataFound';
import { Fragment } from 'react';

interface IProps {
  error: unknown;
  children: React.ReactNode;
}

const ErrorControlComponent = ({ error, children }: IProps) => {
  if (
    error &&
    typeof error === 'object' &&
    'status' in error &&
    error.status === 401 &&
    'data' in error &&
    error.data &&
    typeof error.data === 'object' &&
    'isBlockedUser' in error.data &&
    error.data.isBlockedUser
  ) {
    return (
      <NoDataFound
        message='You have been restricted from this contract, please get in touch with you organization Periopsis Administrator.'
        icon={<ImBlocked />}
      />
    );
  }

  if (
    error &&
    typeof error === 'object' &&
    'status' in error &&
    error.status === 401 &&
    'data' in error &&
    error.data &&
    typeof error.data === 'object' &&
    'isBlockedUser' in error.data &&
    !error.data.isBlockedUser
  ) {
    return (
      <NoDataFound message='It appears that your session has expired due to inactivity. Please refresh the page; if the issue is related to the session, you will be redirected to the login page. If the problem persists, kindly reach out to your administrator for further assistance.' />
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default ErrorControlComponent;
