import { useTranslation } from 'react-i18next';
import classes from './ResendButton.module.scss';

interface IProps {
  onResend: () => void;
  disabled: boolean;
}

const ResendButton = ({ onResend, disabled }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.ResendButtonContainer}>
      <button type='button' onClick={onResend} disabled={disabled}>
        {t('translations:resend')}
      </button>
    </div>
  );
};

export default ResendButton;
