const getTranslationKey = (text: string) => {
  if (!text) {
    return '';
  }

  const cleanedText = text.replace(/[.,]/g, ''); // Remove commas and dots
  const lowercaseWords = cleanedText
    .split(' ')
    .map((word) => word.toLowerCase());

  return lowercaseWords.join('_');
};

export default getTranslationKey;
