import { IPackageSubService } from '../../../../tsTypes/types.model';
import { View, Text } from '@react-pdf/renderer';
import { itemStyles } from './SubserviceItem.style';
import Paragraph from '../Paragraph/Paragraph';

interface IProps {
  subservice: IPackageSubService;
}

const SubserviceItem = ({ subservice }: IProps) => {
  return (
    <View style={itemStyles.container}>
      <View style={itemStyles.heading}>
        <Text>{subservice.subserviceName}</Text>
        <Text>
          {subservice.currency}
          {subservice.subserviceMonthlyPrice}/month
        </Text>
      </View>
      <Paragraph paragraph={subservice.subserviceDescription} width='117mm' />
    </View>
  );
};

export default SubserviceItem;
