import { Text, View } from '@react-pdf/renderer';
import { IVisitor } from '../../../../../../../tsTypes/interfaces';
import { styles } from './VisitorsListItem.style';

interface IProps {
  visitor: IVisitor;
  index: number;
}

const VisitorsListItem = ({ visitor, index }: IProps) => {
  return (
    <View style={styles.container}>
      <Text style={styles.listNumber}>{index + 1}</Text>
      <Text style={styles.text}>{visitor.name}</Text>
      <Text style={styles.text}>{visitor.surname}</Text>
      <Text style={styles.longText}>{visitor.department}</Text>
      <Text style={styles.longText}>{visitor.duty}</Text>
    </View>
  );
};

export default VisitorsListItem;
