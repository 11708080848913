import { ChangeEvent, Fragment, useState } from 'react';
import { useGetObservedIncidentTypesQuery } from '../../../../../../features/incidents/incidentsSlice';
import Backdrop from '../../../../../UI/Backdrop/Backdrop';
import Input from '../../../../../UI/FormElements/Input/Input';
import { GrClose } from 'react-icons/gr';
import { IIncidentType } from '../../../../../../tsTypes/interfaces';
import NoData from '../../../../../UI/NoData/NoData';
import SimpleLoading from '../../../../../UI/SimpleLoading/SimpleLoading';
import classes from './ObservedIncidentTypes.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
  show: boolean;
  onClose: () => void;
  onSelect: (observedType: string) => void;
}

const ObservedIncidentTypes = ({ show, onClose, onSelect }: IProps) => {
  const { isLoading, isSuccess, isError, data } =
    useGetObservedIncidentTypesQuery('', { skip: !show });
  const [searchValue, setSearchValue] = useState('');
  let listOfTypes: IIncidentType[] = [];
  let filteredTypes: IIncidentType[] = [];
  let listComponent: React.ReactNode | null = null;
  let showSearch = true;
  const { t } = useTranslation();

  if (!show) {
    return null;
  }

  if (isLoading) {
    listComponent = (
      <div className={classes.LoadingContainer}>
        <SimpleLoading />
      </div>
    );
  }

  if (isError) {
    listComponent = (
      <NoData message={t('forms:user_defined_incident_types_error')} />
    );
  }

  if (
    data &&
    typeof data === 'object' &&
    'isSuccess' in data &&
    data.isSuccess &&
    'data' in data &&
    data.data &&
    typeof data.data === 'object' &&
    'observedTypes' in data.data &&
    Array.isArray(data.data.observedTypes) &&
    isSuccess
  ) {
    listOfTypes = data.data.observedTypes;
  }

  const selectObservedTypeHandler = (observedType: string) => {
    onSelect(observedType);

    onClose();
  };

  if (listOfTypes.length > 0) {
    filteredTypes = listOfTypes.filter((typ) =>
      typ.incidentType.toLowerCase().includes(searchValue.toLowerCase())
    );
    listComponent = (
      <ul className={classes.ObservedIncidentTypesList}>
        {filteredTypes.map((typ) => (
          <li key={typ.incidentTypeId}>
            <button
              onClick={() => selectObservedTypeHandler(typ.incidentType)}
              type='button'
            >
              {typ.incidentType}
            </button>
          </li>
        ))}
      </ul>
    );
  }

  if (listOfTypes.length === 0 && !isError && !isLoading) {
    showSearch = false;
    listComponent = <NoData message={t('forms:no_defined_incident_types')} />;
  }

  const searchTypeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Fragment>
      <div className={classes.ObservedIncidentTypesContainer}>
        <div className={classes.HeadingSection}>
          <h3>{t('forms:user_defined_incident_types_heading')}</h3>
          <button onClick={onClose} type='button'>
            <GrClose />
          </button>
        </div>
        {showSearch ? (
          <p>{t('forms:user_defined_incident_types_info')}</p>
        ) : null}
        {showSearch ? (
          <div className={classes.DynamicSearchSection}>
            <Input
              name='search-type'
              type='search'
              label={t('forms:user_defined_search_label')}
              onChange={searchTypeHandler}
              value={searchValue}
            />
          </div>
        ) : null}
        {listComponent}
      </div>
      <Backdrop show={show} />
    </Fragment>
  );
};

export default ObservedIncidentTypes;
