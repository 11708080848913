import { useState, useEffect } from 'react';

const useCurrentContract = () => {
  const [contractId, setContractId] = useState('');

  useEffect(() => {
    const currentContract = localStorage.getItem('current-contract');

    if (currentContract) {
      setContractId(currentContract);
    } else {
      let timer = setTimeout(() => {
        const contractId = localStorage.getItem('current-contract');

        if (contractId) {
          setContractId(contractId);
        }
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return contractId;
};

export default useCurrentContract;
