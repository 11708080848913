import { StyleSheet } from '@react-pdf/renderer';
import { colorConstants } from '../UI/style.constants';

export const ProformaInvoicePageStyle = StyleSheet.create({
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2mm',
  },

  listDetailsNote: {
    width: '131mm',
    padding: '6pt 5pt 2pt 5pt',
    fontSize: '9pt',
    color: colorConstants.lightGray,
    letterSpacing: '1.1pt',
    lineHeight: '1.3',
  },

  addressContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '3pt',
    padding: '3pt 2pt',
    color: colorConstants.lightGray,
    fontSize: '9.5pt',
  },

  addressHeading: {
    fontWeight: 'extrabold',
    color: colorConstants.periopsisMainColor,
    fontSize: '11pt',
  },

  overallTotalContainer: {
    paddingTop: '2pt',
    marginTop: '2pt',
    borderTop: `1pt solid ${colorConstants.periopsisMainColor}`,
  },

  subservicesHeading: {
    padding: '5pt',
    marginBottom: '3pt',
    borderBottom: `1pt solid ${colorConstants.periopsisMainColor}`,
    fontSize: '14pt',
    color: colorConstants.periopsisMainColor,
  },
  polygonDataContainer: {
    paddingBottom: '3pt',
    marginBottom: '2pt',
    borderBottom: `1pt solid ${colorConstants.lightGray}`,
  },

  proformaNoteSection: {
    padding: '4pt 0',
    maxWidth: '131mm',
    fontSize: '9pt',
    color: colorConstants.lightGray,
    letterSpacing: '1.1pt',
    lineHeight: '1.3',
  },

  addressType: {
    padding: '2pt 0',
    fontSize: '9.5pt',
    color: colorConstants.lightGray,
  },

  vatNumber: {
    fontSize: '8pt',
  },
});
