import { Fragment, useState, useEffect } from 'react';
import SelectionContainer from '../SelectionContainer/SelectionContainer';
import { IRegistrationSelectionProps } from '../../../../../../tsTypes/types.components';
import { useSelector, useDispatch } from 'react-redux';
import {
  approveInvoiceDetails,
  nextPage,
  selectAllRegistrationFormValues,
  setSelectedPackageType,
  setSelectedSubservices,
  useGetServiceTypePackagesQuery,
} from '../../../../../../features/registration/registrationSlice';
import PackageCard from './PackageCard/PackageCard';
import classes from './PackageSelection.module.scss';
import SectionExplanation from '../SectionExplanation/SectionExplanation';
import { LuPackagePlus } from 'react-icons/lu';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import PresentationWrapper from '../../../../../UI/PresentationWrapper/PresentationWrapper';
import { catchError } from '../../../../../../utils/fetches';
import { AppDispatch } from '../../../../../../app/store';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import {
  CountryAlfa2Code,
  IPackage,
  IPackageSubService,
  term,
} from '../../../../../../tsTypes/types.model';
import TermsAndConditions from '../../../../../UI/TermsAndConditions/TermsAndConditions';
import Subservices from './Subservices/Subservices';
import CenterDiv from '../../../../../UI/CenterDiv/CenterDiv';
import SimpleLoading from '../../../../../UI/SimpleLoading/SimpleLoading';
import { useTranslation } from 'react-i18next';

/**
 *
 * @TODO : Retrieve packages corresponding to the selected service type from the database by utilizing its unique identifier.
 * It's important to note that in all scenarios, the "Free Tier" has a serviceType value of null. This implies that when filtering at the backend,
 * the system should also include the "Free Tier" as an option.
 * Alternatively, if necessary, we can create a separate "Free Tier" for each service type, each with its own set of service options.
 *
 * @TODO : Utilize media queries to ensure responsiveness across various viewport sizes. Currently, the design is suitable for 1600x900p and 1920x1080p resolutions.
 * However, at other resolutions, particularly starting from 1536x800p, issues arise. The card heights and container height become excessive, resulting in an unattractive design.
 * To address this, it is necessary to reduce margins and text sizes for optimal presentation.
 */

const PackageSelection = ({ pageNumber }: IRegistrationSelectionProps) => {
  const {
    currentPageNumber,
    selectedServiceType,
    selectedCountry,
    selectedOrganizationType,
  } = useSelector(selectAllRegistrationFormValues);
  const [orderNumber, setOrderNumber] = useState(1);
  const [leftNumber, setLeftNumber] = useState(0);
  const [selectedPackLocally, setSelectedPackLocally] = useState('');
  const [terms, setTerms] = useState<term[]>([]);
  const [subServices, setSubservices] = useState<IPackageSubService[]>([]);
  const [selectedSubservicesLocally, setSelectedSubservicesLocally] = useState<
    IPackageSubService[]
  >([]);
  const [descriptionId, setDescriptionId] = useState<string | null>(null);
  const [fetchServiceData, setFetchServiceData] = useState(false);
  const [dummyLoader, setDummyLoader] = useState(false);
  const { t } = useTranslation();

  let cardsList: React.ReactNode | null = null;
  let mobileCardsList: React.ReactNode | null = null;
  let serviceTypeId = selectedServiceType ? selectedServiceType.id : '';
  let organizationTypeId = selectedOrganizationType
    ? selectedOrganizationType.id
    : '';
  let country = selectedCountry ? selectedCountry.alpha2Code : '';
  const dispatch = useDispatch<AppDispatch>();
  const packHasSubservices = subServices.length > 0;
  const lengthOfSelectedSubservices = selectedSubservicesLocally.length;
  const isValidPageNumber = currentPageNumber === 5;

  const packageData = {
    serviceTypeId,
    organizationTypeId,
    country: country as CountryAlfa2Code,
  };

  const {
    isLoading,
    isError,
    error,
    data: packages,
  } = useGetServiceTypePackagesQuery(packageData, {
    skip: currentPageNumber !== 5 || !fetchServiceData,
  });

  useEffect(() => {
    const storedSelectedPackage = localStorage.getItem('selected-package');
    const storedSubServices = localStorage.getItem('sub-services');
    const storedNavigationNumbers = localStorage.getItem('navigationNumbers');
    const storedSelectedSubservices = localStorage.getItem(
      'selected-subservices'
    );

    if (storedSelectedPackage) {
      const parsedData = JSON.parse(storedSelectedPackage);
      setSelectedPackLocally(parsedData._id);
    }

    if (storedSubServices) {
      const parsedSubservices = JSON.parse(storedSubServices);
      setSubservices(parsedSubservices);
    }

    if (storedNavigationNumbers) {
      const parsedNavNumbers = JSON.parse(storedNavigationNumbers);
      setOrderNumber(parsedNavNumbers.orderNumber);
      setLeftNumber(parsedNavNumbers.leftNumber);
    }

    if (storedSelectedSubservices) {
      const parsedData = JSON.parse(storedSelectedSubservices);
      setSelectedSubservicesLocally(parsedData);
    }
    // eslint-disable-next-line
  }, []);

  //Delay fetch process for 3 seconds
  useEffect(() => {
    if (isValidPageNumber) {
      setDummyLoader(true);
      const timer = setTimeout(() => {
        setFetchServiceData(true);
        setDummyLoader(false);
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [isValidPageNumber]);

  //instead of running it at every array change, which will always change, we will rerun it according to length change
  //Which will prevent the rerun in every array generation
  useEffect(() => {
    dispatch(setSelectedSubservices(selectedSubservicesLocally));

    // eslint-disable-next-line
  }, [lengthOfSelectedSubservices]);

  if (isLoading || isError || error) {
    cardsList = (
      <div
        className={classes.CardContainer}
        style={{ width: 'max-content', margin: '0 auto' }}
      >
        <PresentationWrapper
          isError={isError}
          isLoading={isLoading}
          error={catchError(error)}
          message={t('auth:package_load_error')}
        >
          <div></div>
        </PresentationWrapper>
      </div>
    );

    mobileCardsList = cardsList;
  }

  if (dummyLoader) {
    return (
      <div
        className={classes.CardContainer}
        style={{ width: 'max-content', margin: '0 auto' }}
      >
        <CenterDiv>
          <SimpleLoading />
        </CenterDiv>
      </div>
    );
  }

  if (!packages && !isLoading && !isError) {
    return null;
  }
  /**
   * Advances the order and left numbers in a circular manner, shifting to the next package card.
   * If the current order number is 2, it wraps around to 0.
   * If the current left number is 2, it wraps around to 0.
   */
  const handleNextClick = () => {
    const newOrderNumber = (orderNumber + 1) % 3;
    const newLeftNumber = (leftNumber + 1) % 3;
    // Update the order and left numbers in the component state.
    setOrderNumber(newOrderNumber);
    setLeftNumber(newLeftNumber);
    localStorage.setItem(
      'navigationNumbers',
      JSON.stringify({ orderNumber: newOrderNumber, leftNumber: newLeftNumber })
    );

    setSelectedPackLocally('');
    localStorage.removeItem('selected-package');
  };

  /**
   * Moves back to the previous package card by adjusting the order and left numbers.
   * If the current order number is 0, it wraps around to 2.
   * If the current left number is 0, it wraps around to 2.
   */
  const handlePrevClick = () => {
    // Calculate the new order and left numbers using modular arithmetic.
    const newOrderNumber = (orderNumber + 2) % 3;
    const newLeftNumber = (leftNumber + 2) % 3;
    setOrderNumber(newOrderNumber);
    setLeftNumber(newLeftNumber);
    setSelectedPackLocally('');
    localStorage.setItem(
      'navigationNumbers',
      JSON.stringify({ orderNumber: newOrderNumber, leftNumber: newLeftNumber })
    );
    localStorage.removeItem('selected-package');
  };

  const getServicePackagePlanHandler = (
    pack: Omit<IPackage, 'isActivePackage' | 'serviceTypeId'>
  ) => {
    if (selectedPackLocally !== '') {
      setSelectedPackLocally('');
      localStorage.removeItem('selected-package');
      localStorage.removeItem('sub-services');
      localStorage.removeItem('selected-subservices');
      setSubservices([]);
      setSelectedSubservicesLocally([]);
      dispatch(setSelectedSubservices([]));
      dispatch(setSelectedPackageType(null));
      dispatch(approveInvoiceDetails(false));
      localStorage.removeItem('invoice-details-approved');
    } else {
      /**
       *   "areaLimit": 195000000000,
    "areaLimitUnit": "sqm",
       */

      const id = pack.packageId;
      const packageName = pack.packageName;
      const price = pack.monthlyPrice;
      const priceUnit = pack.priceUnit;
      const areaLimit = pack.areaLimit;
      const areaLimitUnit = pack.areaLimitUnit;
      const currency = pack.currency;
      const duration = pack.duration;
      const durationUnit = pack.durationUnit;
      const vatRate = pack.vatRate;
      const subServices = pack.packageDetails.subservices;
      const hasSubservices = subServices.length > 0;
      const discountAmount = pack.discounts.customDiscount;
      const discountAmountForFullPayment =
        pack.discounts.discountAmountForFullPayment;

      setSelectedPackLocally(id);
      localStorage.setItem(
        'selected-package',
        JSON.stringify({
          _id: id,
          name: packageName,
          price,
          priceUnit,
          areaLimit,
          areaLimitUnit,
          currency,
          duration,
          durationUnit,
          vatRate,
          discounts: {
            discountAmountForFullPayment,
            customDiscount: discountAmount,
          },
        })
      );
      dispatch(
        setSelectedPackageType({
          _id: id,
          name: packageName,
          price,
          priceUnit,
          areaLimit,
          areaLimitUnit,
          currency,
          duration,
          durationUnit,
          vatRate,
          discounts: {
            discountAmountForFullPayment,
            customDiscount: discountAmount,
          },
        })
      );

      if (hasSubservices) {
        setSubservices(subServices);
        localStorage.setItem('sub-services', JSON.stringify(subServices));
      }

      if (!hasSubservices) {
        toast.success(
          <ToastifyStyledMessage
            singleMessage={t('auth:select_success_message')}
            heading={t('auth:next_page')}
          />,
          { autoClose: 1000, containerId: 'packageSuccess' }
        );

        const timer = setTimeout(() => {
          dispatch(nextPage());
          toast.dismiss();
          clearTimeout(timer);
        }, 1600);
      }
    }
  };

  const termsAndConditionsOpenHandler = (terms: term[]) => {
    setTerms(terms);
  };

  const closeTermsAndConditionsHandler = () => {
    setTerms([]);
  };

  const showDescriptionHandler = (id: string) => {
    if (descriptionId === id) {
      setDescriptionId(null);
    } else {
      setDescriptionId(id);
    }
  };

  /*
  This function, `selectSubserviceHandler`, manages the selection and deselection of package subservices in the application. 
  It allows users to toggle the selection state of a subservice, ensuring that their choices are stored both locally and globally for consistency and 
  data persistence across sessions and page refreshes.

  @param selectedSubservice {IPackageSubService}: The subservice to be selected or deselected.
*/
  const selectSubserviceHandler = (selectedSubservice: IPackageSubService) => {
    const selectedId = selectedSubservice.subserviceId;
    dispatch(approveInvoiceDetails(false));
    localStorage.removeItem('invoice-details-approved');
    setSelectedSubservicesLocally((prevState) => {
      const isExist = prevState.find(
        (subservice) => subservice.subserviceId === selectedId
      );
      let updatedList: IPackageSubService[] = [];

      if (isExist) {
        updatedList = prevState.filter(
          (subservice) => subservice.subserviceId !== selectedId
        );
      } else {
        updatedList = [...prevState, selectedSubservice];
      }
      localStorage.setItem('selected-subservices', JSON.stringify(updatedList));

      return updatedList;
    });
  };

  const saveAndContinueHandler = () => {
    toast.success(
      <ToastifyStyledMessage
        singleMessage={t('auth:select_success_message')}
        heading={t('auth:next_page')}
      />,
      { autoClose: 1000, containerId: 'packageSuccess' }
    );
    setDescriptionId(null);

    const timer = setTimeout(() => {
      dispatch(nextPage());
      toast.dismiss();
      clearTimeout(timer);
    }, 1600);
  };

  if (packages && packages.length < 3 && !isLoading && !isError && !error) {
    cardsList = (
      <div className={classes.CardContainer}>
        {packages.map((pack) => (
          <PackageCard
            key={pack.packageId}
            packageData={pack}
            onGetPlan={() => getServicePackagePlanHandler(pack)}
            style={{
              boxShadow:
                pack.packageId === selectedPackLocally ? '0 0 10px green' : '',
            }}
            isSelected={selectedPackLocally === pack.packageId}
            onTermsOpen={() =>
              termsAndConditionsOpenHandler(
                pack.packageDetails.termsAndConditions
              )
            }
          />
        ))}
      </div>
    );
  } else if (
    packages &&
    packages.length === 3 &&
    !isLoading &&
    !isError &&
    !error
  ) {
    cardsList = (
      <div className={classes.CardContainer}>
        {packages.map((pack, index) => (
          <PackageCard
            key={pack.packageId}
            onGetPlan={() => getServicePackagePlanHandler(pack)}
            packageData={pack}
            isThreePackView
            isOnLeft={index === leftNumber}
            keepAtCenter={
              index === orderNumber &&
              (selectedPackLocally === '' || selectedPackLocally !== '') &&
              !packHasSubservices
            }
            hasSubservices={
              index === orderNumber &&
              selectedPackLocally !== '' &&
              packHasSubservices
            }
            isOpac={index !== orderNumber}
            isHidden={
              selectedPackLocally !== '' &&
              selectedPackLocally !== pack.packageId
            }
            style={{
              zIndex: index === orderNumber ? 5 : 0,

              boxShadow:
                index === orderNumber && selectedPackLocally === ''
                  ? '2px 2px 10px black'
                  : index === orderNumber &&
                    selectedPackLocally === pack.packageId
                  ? '0 0 0'
                  : 'none',
              border:
                selectedPackLocally === pack.packageId
                  ? '2px solid yellowgreen'
                  : undefined,
            }}
            isSelected={selectedPackLocally === pack.packageId}
            onTermsOpen={() =>
              termsAndConditionsOpenHandler(
                pack.packageDetails.termsAndConditions
              )
            }
          />
        ))}
        {selectedPackLocally !== '' && packHasSubservices ? (
          <Subservices
            subservices={subServices}
            onShowTerms={termsAndConditionsOpenHandler}
            onSubserviceSelect={selectSubserviceHandler}
            selectedSubservices={selectedSubservicesLocally}
            onSaveAndContinue={saveAndContinueHandler}
            descriptionId={descriptionId}
            onShowDescription={showDescriptionHandler}
          />
        ) : null}
        {selectedPackLocally === '' ? (
          <Fragment>
            <button
              onClick={handlePrevClick}
              className={`${classes.NavButton} ${classes.Prev}`}
            >
              <AiOutlineArrowLeft />
            </button>
            <button
              onClick={handleNextClick}
              className={`${classes.NavButton} ${classes.Next}`}
            >
              <AiOutlineArrowRight />
            </button>
          </Fragment>
        ) : null}
      </div>
    );
  }

  return (
    <Fragment>
      {terms.length === 0 ? (
        <SelectionContainer
          isInQueue={pageNumber ? pageNumber < currentPageNumber : false}
          isCompleted={pageNumber ? pageNumber > currentPageNumber : false}
          isScrollable
        >
          <SectionExplanation
            heading={t('auth:select_a_package')}
            description={t('auth:select_a_package_desc')}
            icon={<LuPackagePlus size={25} />}
          />
          {cardsList}
          {mobileCardsList}
        </SelectionContainer>
      ) : null}
      <TermsAndConditions
        terms={terms}
        show={terms.length > 0}
        onClose={closeTermsAndConditionsHandler}
      />
    </Fragment>
  );
};

export default PackageSelection;
