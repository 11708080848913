import classes from './SectionExplanation.module.scss';

interface IProps {
  heading?: string;
  description?: string;
  icon?: JSX.Element;
}

const SectionExplanation = ({ heading, description, icon }: IProps) => {
  if (!heading && !description) {
    return null;
  }

  return (
    <div className={classes.SectionExplanationContainer}>
      {heading && (
        <div className={classes.HeadingSection}>
          {icon ? icon : null}
          <h2>{heading}</h2>
        </div>
      )}
      {description && <p>{description}</p>}
    </div>
  );
};

export default SectionExplanation;
