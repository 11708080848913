import CustomNavLink from '../CustomNavLink/CustomNavLink';
import { AiOutlineDashboard } from 'react-icons/ai';
import { BiLogIn } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { selectAllAuthValues } from '../../../../features/auth/authSlice';
import { frontendConstants } from '../../../../constants/constants';
import classes from './NavHomeLinks.module.scss';

interface IProps {
  onClick?: (id: string) => void;
  onSelect?: () => void;
}

const NavHomeLinks = ({ onClick, onSelect }: IProps) => {
  const { isAuthenticated } = useSelector(selectAllAuthValues);

  const loginStorageCleanHandler = () => {
    window.localStorage.clear();
  };

  return (
    <div className={classes.NavLinksContainer}>
      <div className={classes.NavLinksContainer_Content}>
        <div className={classes.NavLinksContainer_ContentLinks}>
          {!isAuthenticated && (
            <CustomNavLink
              to={frontendConstants.pages.login}
              title='Login'
              icon={<BiLogIn />}
              onNavLinkSelect={onSelect}
              onClick={loginStorageCleanHandler}
            />
          )}
          {isAuthenticated && (
            <CustomNavLink
              to={frontendConstants.pages.dashboard}
              title='Dashboard'
              icon={<AiOutlineDashboard />}
              onNavLinkSelect={onSelect}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NavHomeLinks;
