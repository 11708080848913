import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MapProvider } from 'react-map-gl';
import './i18n.config/i18n';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SkeletonTheme baseColor='#787878' highlightColor='#9a9a9a'>
        <MapProvider>
          <App />
        </MapProvider>
        <ToastContainer
          position='top-right'
          theme='colored'
          autoClose={8000}
          draggable={false}
          pauseOnHover={true}
          limit={1}
        />
      </SkeletonTheme>
    </Provider>
  </React.StrictMode>
);
