import React, { Fragment } from 'react';
import { FaCircleChevronLeft, FaCircleChevronRight } from 'react-icons/fa6';
import classes from './NavigationButtons.module.scss';

interface IProps {
  onScroll: (direction: 'left' | 'right') => void;
  color?: 'primary' | 'orange';
}

const NavigationButtons = ({ onScroll, color }: IProps) => {
  return (
    <Fragment>
      <button
        className={`${classes.DirectionButton} ${classes.Left} ${
          color ? classes[color] : classes.primary
        }`}
        onClick={() => onScroll('left')}
      >
        <FaCircleChevronLeft />
      </button>
      <button
        className={`${classes.DirectionButton} ${classes.Right} ${
          color ? classes[color] : classes.primary
        }`}
        onClick={() => onScroll('right')}
      >
        <FaCircleChevronRight />
      </button>
    </Fragment>
  );
};

export default NavigationButtons;
