import { useState } from 'react';
import { reportType } from '../../../../../../tsTypes/types';
import {
  IContractIncident,
  IExistingReport,
} from '../../../../../../tsTypes/interfaces';
import ReportCommonContent from '../ReportCommonContent/ReportCommonContent';
import BorderedSectionContainer from '../../BorderedSectionContainer/BorderedSectionContainer';
import SelectionContainer from '../../../../../UI/SelectionContainer/SelectionContainer';

interface IProps {
  closeForm: () => void;
  isSelected: boolean;
  reportType: reportType;
  selectedIncident: IContractIncident;
}

const ReportByWhatsapp = ({
  isSelected,
  reportType,
  selectedIncident,
  closeForm,
}: IProps) => {
  const [exitingReports] = useState<IExistingReport[]>([]);

  const [currentMessage, setCurrentMessage] = useState(0);

  const currentMessageHandler = (direction: 'left' | 'right') => {
    if (direction === 'left' && currentMessage !== 0) {
      setCurrentMessage((prevState) => prevState - 1);
    } else if (
      direction === 'right' &&
      currentMessage !== exitingReports.length - 1
    ) {
      setCurrentMessage((prevState) => prevState + 1);
    }
  };

  const startNewReportHandler = () => {};

  const sendWhatsappReportHandler = async () => {};

  return (
    <SelectionContainer isSelected={isSelected}>
      <ReportCommonContent
        numberOfReports={0}
        selectedIncident={selectedIncident}
        currentReportIndex={0}
        onNavigation={currentMessageHandler}
        onStartNewReport={startNewReportHandler}
        onSend={sendWhatsappReportHandler}
        isNewReport
      >
        <BorderedSectionContainer heading='Whatsapp Report Details'>
          <div>Whatsapp Form</div>
        </BorderedSectionContainer>
      </ReportCommonContent>
    </SelectionContainer>
  );
};

export default ReportByWhatsapp;
