import { periopsisApi } from '../api/apiSlice';
import getHeaders from '../../utils/getHeaders';
import { IListObject, IResponseData } from '../../tsTypes/types.model';
import { IChartData, IUData } from '../../tsTypes/interfaces';

export const extendedApiSlice = periopsisApi.injectEndpoints({
  endpoints: (builder) => ({
    getActionCounts: builder.query({
      query: (requestData: { contractId: string; year: number }) => {
        const { contractId, year } = requestData;
        const headers = getHeaders();

        return {
          url: `/statistics/actions/${contractId}?year=${year}`,
          method: 'GET',
          headers,
        };
      },
      keepUnusedDataFor: 500,
      providesTags: ['action-statistics'],
    }),
    getContractYears: builder.query({
      query: (contractId: string) => {
        const headers = getHeaders();

        return {
          url: `/statistics/${contractId}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          years: IListObject[];
          uData: IUData;
          pToken: string;
          hasIncidents: boolean;
        }>
      ) => {
        const { years, uData, pToken, hasIncidents } = responseData.data;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);
        return { years, hasIncidents };
      },
      keepUnusedDataFor: 1500,
    }),
    getMonthlyActionStatistics: builder.query({
      query: (requestData: { contractId: string; year: number }) => {
        const { contractId, year } = requestData;
        const headers = getHeaders();

        return {
          url: `/statistics/actions/monthly/${contractId}?year=${year}`,
          method: 'GET',
          headers,
        };
      },
      keepUnusedDataFor: 500,
      providesTags: ['action-statistics'],
    }),
    getIncidentTypeCounts: builder.query({
      query: (requestData: { contractId: string; year: number }) => {
        const { contractId, year } = requestData;
        const headers = getHeaders();

        return {
          url: `/statistics/incidents/${contractId}?year=${year}`,
          method: 'GET',
          headers,
        };
      },
      transformResponse: (
        responseData: IResponseData<{
          counts: {
            total: number;
            month: string;
            type: string;
            color: string;
          }[];
          chartData: IChartData;
          uData: IUData;
          pToken: string;
        }>
      ) => {
        const { counts, chartData, uData, pToken } = responseData.data;

        localStorage.setItem('uData', JSON.stringify(uData));
        localStorage.setItem('pToken', pToken);

        return { counts, chartData };
      },
      providesTags: ['type-statistics'],
    }),
    getMonthlyIncidentsByType: builder.query({
      query: (requestData: { contractId: string; year: number }) => {
        const { contractId, year } = requestData;
        const headers = getHeaders();

        return {
          url: `/statistics/incidents/monthly/${contractId}?year=${year}`,
          method: 'GET',
          headers,
        };
      },
      providesTags: ['type-statistics'],
    }),
    getIncidentsGeoData: builder.query({
      query: (requestData: { contractId: string; year: number }) => {
        const { contractId, year } = requestData;
        const headers = getHeaders();

        return {
          url: `statistics/incidents/geometry-data/${contractId}?year=${year}`,
          method: 'GET',
          headers,
        };
      },
    }),
  }),
});

export const {
  useGetActionCountsQuery,
  useGetContractYearsQuery,
  useGetMonthlyActionStatisticsQuery,
  useGetIncidentTypeCountsQuery,
  useGetMonthlyIncidentsByTypeQuery,
  useGetIncidentsGeoDataQuery,
} = extendedApiSlice;
