import { CountryAlfa2Code } from '../tsTypes/types.model';
import { validateItalyVATNumber } from './CountryVATValidationAlgorithms/Italy';
import { validateFranceVATNumber } from './CountryVATValidationAlgorithms/france';

/**
 * @TODO : Create VAT Validation by Country
 * https://en.wikipedia.org/wiki/VAT_identification_number
 * https://anyapi.io/app/vat-number-validation-api
 */
const validateVatByCountry = (
  vatNumber: string,
  countryCode: CountryAlfa2Code
) => {
  switch (countryCode) {
    case CountryAlfa2Code.Austria:
      const isValidPrefixAU = isValidVATPrefix(vatNumber, 'atu');
      const isValidNumberPartAU = isValidVATNumberPart(vatNumber, 3);
      const isValidLength = vatNumber.length >= 11;

      return isValidPrefixAU && isValidLength && isValidNumberPartAU;

    case CountryAlfa2Code.Belgium:
      const isValidPrefixBE = isValidVATPrefix(vatNumber, 'be');
      const isValidLengthBE = vatNumber.length >= 11;

      return isValidPrefixBE && isValidLengthBE;

    case CountryAlfa2Code.Bulgaria:
      const isValidPrefixBG = isValidVATPrefix(vatNumber, 'bg');
      const isValidLengthBG = vatNumber.length >= 11;
      const isValidNumberPartBG = isValidVATNumberPart(vatNumber, 2);

      return isValidLengthBG && isValidPrefixBG && isValidNumberPartBG;

    case CountryAlfa2Code.Cyprus:
      const isValidPrefixCY = isValidVATPrefix(vatNumber, 'cy');
      const isValidLengthCY = vatNumber.length >= 11;

      return isValidLengthCY && isValidPrefixCY;

    case CountryAlfa2Code.Croatia:
      const isValidPrefixHR = isValidVATPrefix(vatNumber, 'hr');
      const isValidLengthHR = vatNumber.length >= 12;
      const isValidNumberPartHR = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefixHR && isValidLengthHR && isValidNumberPartHR;

    case CountryAlfa2Code.Czechia:
      const isValidPrefixCZ = isValidVATPrefix(vatNumber, 'cz');
      const isValidLengthCZ = vatNumber.length >= 10;

      return isValidPrefixCZ && isValidLengthCZ;

    case CountryAlfa2Code.Denmark:
      const isValidPrefixDK = isValidVATPrefix(vatNumber, 'dk');
      const isValidLengthDK = vatNumber.length >= 10;
      const isValidNumberPartDK = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefixDK && isValidLengthDK && isValidNumberPartDK;

    case CountryAlfa2Code.Estonia:
      const isValidPrefix = isValidVATPrefix(vatNumber, 'ee');
      const isValidLengthEE = vatNumber.length === 11;
      const isValidNumberPartEE = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefix && isValidLengthEE && isValidNumberPartEE;

    case CountryAlfa2Code.Finland:
      const isValidPrefixFI = isValidVATPrefix(vatNumber, 'fi');
      const isValidLengthFI = vatNumber.length >= 9;
      const isValidNumberPartFI = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefixFI && isValidLengthFI && isValidNumberPartFI;

    case CountryAlfa2Code.France:
      const isValidPrefixFR = isValidVATPrefix(vatNumber, 'fr');
      const isValidLengthFR = vatNumber.length >= 13;
      const isValidNumberPartFR = isValidVATNumberPart(vatNumber, 4);
      const isValidFranceVATNumber = validateFranceVATNumber(vatNumber);

      return (
        isValidPrefixFR &&
        isValidLengthFR &&
        isValidNumberPartFR &&
        isValidFranceVATNumber
      );

    case CountryAlfa2Code.Germany:
      const isValidPrefixDE = isValidVATPrefix(vatNumber, 'de');
      const isValidLengthDE = vatNumber.length >= 11;
      const isValidNumberPartDE = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefixDE && isValidLengthDE && isValidNumberPartDE;

    case CountryAlfa2Code.Greece:
      const isValidPrefixEL = isValidVATPrefix(vatNumber, 'el');
      const isValidLengthEL = vatNumber.length >= 11;
      const isValidNumberPartEL = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefixEL && isValidLengthEL && isValidNumberPartEL;

    case CountryAlfa2Code.Hungary:
      const isValidPrefixHU = isValidVATPrefix(vatNumber, 'hu');
      const isValidLengthHU = vatNumber.length >= 10;
      const isValidNumberPartHU = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefixHU && isValidLengthHU && isValidNumberPartHU;

    case CountryAlfa2Code.Ireland:
      const isValidPrefixIE = isValidVATPrefix(vatNumber, 'ie');
      const isValidLengthIE = vatNumber.length >= 9;

      return isValidPrefixIE && isValidLengthIE;

    case CountryAlfa2Code.Italy:
      const isValidPrefixIT = isValidVATPrefix(vatNumber, 'it');
      const isValidItalyVATNumber = validateItalyVATNumber(vatNumber);

      return isValidItalyVATNumber && isValidPrefixIT;

    case CountryAlfa2Code.Latvia:
      const isValidPrefixLV = isValidVATPrefix(vatNumber, 'lv');
      const isValidLengthLV = vatNumber.length >= 11;
      const isValidNumberPartLV = isValidVATNumberPart(vatNumber, 2);
      return isValidPrefixLV && isValidLengthLV && isValidNumberPartLV;

    case CountryAlfa2Code.Lithuania:
      const isValidPrefixLT = isValidVATPrefix(vatNumber, 'lt');
      const isValidLengthLT = vatNumber.length >= 9;
      return isValidPrefixLT && isValidLengthLT;

    case CountryAlfa2Code.Luxembourg:
      const isValidPrefixLU = isValidVATPrefix(vatNumber, 'lu');
      const isValidLengthLU = vatNumber.length === 10;
      const isValidNumberPartLU = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefixLU && isValidLengthLU && isValidNumberPartLU;

    case CountryAlfa2Code.Malta:
      const isValidPrefixMT = isValidVATPrefix(vatNumber, 'mt');
      const isValidLengthMT = vatNumber.length === 10;
      const isValidNumberPartMT = isValidVATNumberPart(vatNumber, 2);

      return isValidPrefixMT && isValidLengthMT && isValidNumberPartMT;

    case CountryAlfa2Code.Netherlands:
      const isValidPrefixNL = isValidVATPrefix(vatNumber, 'nl');
      const isValidLengthNL = vatNumber.length >= 14;

      return isValidPrefixNL && isValidLengthNL;

    default:
      return true;
  }
};

export default validateVatByCountry;

function isValidVATPrefix(vatNumber: string, prefix: string) {
  return vatNumber.toLowerCase().startsWith(prefix);
}

function isValidVATNumberPart(vatNumber: string, prefixLength: number) {
  return !isNaN(Number(vatNumber.substring(prefixLength)));
}
