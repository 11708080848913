import { useRef, useEffect } from 'react';
import classes from './PillOptionButton.module.scss';

interface IProps {
  options: { leftOption: string; rightOption: string };
  selected: string;
  onSelect: (optionId: string) => void;
  optionIds: {
    leftOptionId: string;
    rightOptionId: string;
  };
  titles?: { leftOptionTitle: string; rightOptionTitle: string };
}

const PillOptionButton = ({
  options,
  selected,
  onSelect,
  optionIds,
}: IProps) => {
  const { leftOption, rightOption } = options;
  const leftButtonRef = useRef<HTMLButtonElement>(null);
  const rightButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (
      leftButtonRef &&
      leftButtonRef.current &&
      rightButtonRef &&
      rightButtonRef.current
    ) {
      const leftButtonWidth = leftButtonRef.current.scrollWidth;
      const rightButtonWidth = rightButtonRef.current.scrollWidth;

      const maxWidth = Math.max(leftButtonWidth, rightButtonWidth);

      leftButtonRef.current.style.minWidth = `${maxWidth}px`;
      rightButtonRef.current.style.minWidth = `${maxWidth}px`;
    }
  }, []);

  const selectCardTypeHandler = (optionId: string) => {
    if (onSelect && typeof onSelect === 'function') {
      onSelect(optionId);
    }
  };

  return (
    <div className={classes.PillOptionContainer}>
      <button
        className={`${classes.OptionButton} ${
          selected === optionIds.leftOptionId ? classes.Selected : ''
        }`}
        ref={leftButtonRef}
        onClick={() => selectCardTypeHandler(optionIds.leftOptionId)}
        type='button'
      >
        {leftOption}
      </button>
      <button
        className={`${classes.OptionButton} ${
          selected === optionIds.rightOptionId ? classes.Selected : ''
        }`}
        ref={rightButtonRef}
        onClick={() => selectCardTypeHandler(optionIds.rightOptionId)}
        type='button'
      >
        {rightOption}
      </button>
    </div>
  );
};

export default PillOptionButton;
