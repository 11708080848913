import { useState } from 'react';
import SuccessSign from '../../../../../../UI/ConfirmResult/SuccessSign/SuccessSign';
import ErrorSign from '../../../../../../UI/ConfirmResult/ErrorSign/ErrorSign';
import Button from '../../../../../../UI/FormElements/Button/Button';
import { useParams } from 'react-router-dom';
import ActionContainer from '../../../../../../UI/FormElements/ActionContainer/ActionContainer';
import { confirmAlert } from 'react-confirm-alert';
import classes from './OrderCompletion.module.scss';
import { useClearRegistrationSuccessDataMutation } from '../../../../../../../features/checkout/checkoutSlice';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import { useTranslation } from 'react-i18next';

interface IProps {
  hasRedirectTimer?: boolean;
  messageArray: string[];
  greeting?: string;
  isError?: boolean;
  errorMessage?: string;
  redirectFunction?: () => void;
  duration?: number;
  isProcessing?: boolean;
  showTimer?: boolean;
}

const OrderCompletion = ({
  messageArray,
  greeting,
  isError,
  errorMessage,
}: IProps) => {
  const [lockedButtons, setLockedButtons] = useState(false);
  const { checkoutToken, registrationCode } = useParams();
  const [clearSuccessData] = useClearRegistrationSuccessDataMutation();
  const { t } = useTranslation();

  const closePageHandler = () => {
    // localStorage.clear();

    const closePage = async () => {
      try {
        if (checkoutToken && registrationCode) {
          setLockedButtons(true);
          const res = await clearSuccessData({
            checkoutToken,
            registrationCode,
          }).unwrap();

          if (
            res &&
            typeof res === 'object' &&
            'isSuccess' in res &&
            res.isSuccess
          ) {
            localStorage.clear();
            window.close();
          }
        }
      } catch (error) {
        toast.error(
          <ToastifyStyledMessage
            heading={t('checkout:close_page_error_heading')}
            singleMessage={t('checkout:close_page_error_message')}
          />
        );
      }
    };

    confirmAlert({
      title: t('checkout:close_periopsis_tab_heading'),
      message: t('checkout:close_periopsis_tab_message'),
      buttons: [
        { label: t('translations:Yes'), onClick: closePage },
        { label: t('translations:No'), onClick: () => {} },
      ],
    });
  };

  const returnLoginPage = async () => {
    try {
      if (checkoutToken && registrationCode) {
        setLockedButtons(true);
        const res = await clearSuccessData({
          checkoutToken,
          registrationCode,
        }).unwrap();

        if (
          res &&
          typeof res === 'object' &&
          'isSuccess' in res &&
          res.isSuccess
        ) {
          localStorage.clear();
          localStorage.setItem('auth-form', 'login');

          const timer = setTimeout(() => {
            localStorage.clear();
            // navigate('/login', { replace: true });
            window.location.replace('/login');
            clearTimeout(timer);
          }, 100);
        }
      }
    } catch (error) {
      toast.error(
        <ToastifyStyledMessage
          heading={t('checkout:close_page_error_heading')}
          singleMessage={t('checkout:close_page_error_message')}
        />
      );
    }
  };

  return (
    <div className={classes.OrderCompletionContainer}>
      {isError ? <ErrorSign /> : <SuccessSign />}
      {greeting ? <h2>{greeting}</h2> : null}
      {!isError ? (
        <div className={classes.Success}>
          {messageArray.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </div>
      ) : (
        <p className={classes.Error}>{errorMessage}</p>
      )}

      <ActionContainer className={classes.OrderCompletionActions}>
        <Button onClick={closePageHandler} isFake={lockedButtons}>
          {t('checkout:close_the_page')}
        </Button>
        <Button isFake={lockedButtons} onClick={returnLoginPage}>
          {t('checkout:return_to_login_page')}
        </Button>
      </ActionContainer>
    </div>
  );
};

export default OrderCompletion;
